import { Box, Button, Text, Divider } from '@ltvco/refresh-lib/theme';
import { StepProps } from './StepOne';
import { StepOptionButton } from './StepOptionButton';
import { languageName, translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';

export const StepTwo = ({
  backStep,
  nextStep,
  setSelectedOptions,
  selectedOptions,
  isOnboarding = true,
}: StepProps) => {
  const { language: nativeLanguage } = useLanguageContext();
  let language;

  if (isOnboarding) {
    language = languageName(
      Boolean(selectedOptions.native_language_id)
        ? selectedOptions.native_language_id
        : 6
    );
  } else {
    language = nativeLanguage;
  }

  const languages = translations.languages[language];
  const proficiencyText =
    selectedOptions.learning_language_id == 0
      ? translations.onBoarding.questions.proficiency[language].default
      : `${translations.onBoarding.questions.proficiency[language].custom} ${
          languages?.find(
            (language) => language.id === selectedOptions.learning_language_id
          )?.name
        }?`;

  return (
    <Box width="100%">
      <Box>
        <Text variant="h2">
          {translations.onBoarding.questions.learningLanguage[language]}
        </Text>
        {languages?.map((language) => (
          <StepOptionButton
            key={language.id}
            option={language}
            responseKey="learning_language_id"
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions!}
          />
        ))}
      </Box>
      {!isOnboarding && <Divider sx={{ marginY: { xs: 2, md: 3 } }} />}
      <Box pt={isOnboarding ? 4 : 0}>
        <Text variant="h2">{proficiencyText}</Text>
        {translations.levels[language].map((level, index) => (
          <StepOptionButton
            key={index}
            option={level}
            responseKey="level"
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions!}
          />
        ))}
      </Box>
      {!isOnboarding && <Divider sx={{ marginY: { xs: 2, md: 3 } }} />}
      {isOnboarding && (
        <Box pt={4}>
          <Button variant="outlined" onClick={backStep} sx={{ mr: 2 }}>
            {translations.buttons.back[language]}
          </Button>
          <Button
            className="language-continue"
            variant="contained"
            onClick={nextStep}
            disabled={
              selectedOptions.learning_language_id == 0 ||
              selectedOptions.level == ''
            }
          >
            {translations.buttons.continue[language]}
          </Button>
        </Box>
      )}
    </Box>
  );
};
