import { useQuery } from '@ltvco/refresh-lib/vendors';
import { enqueueSnackbar, useServiceToken } from '@ltvco/refresh-lib/v1';
import { BumpeeRequests } from 'api';

export const useUserStreak = () => {
  const { data: tokenData } = useServiceToken('bumpee');

  const results = useQuery({
    queryKey: ['user-streak'],
    queryFn: () => BumpeeRequests.getUserStreak(tokenData.token),
    enabled: Boolean(tokenData),
    onError: () => {
      enqueueSnackbar('Failed to get streak', { variant: 'error' });
    },
  });

  return { ...results };
};
