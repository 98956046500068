import {
  ZodiacSignProps,
  CompatibilityZodiacSignsProps,
  MenuSelectOptionProps,
} from './interfaces';
import {
  compatibilitySummaries,
  enhancedCompatibilitySummaries,
} from './compabilitySummaries';
import compatibilityImg from '../../images/ast-compatibility.svg';
import aries from '../../images/ast-aries.svg';
import taurus from '../../images/ast-taurus.svg';
import gemini from '../../images/ast-gemini.svg';
import cancer from '../../images/ast-cancer.svg';
import leo from '../../images/ast-leo.svg';
import virgo from '../../images/ast-virgo.svg';
import libra from '../../images/ast-libra.svg';
import scorpio from '../../images/ast-scorpio.svg';
import sagittarius from '../../images/ast-sagittarius.svg';
import capricorn from '../../images/ast-capricorn.svg';
import aquarius from '../../images/ast-aquarius.svg';
import pisces from '../../images/ast-pisces.svg';
import empty from '../../images/ast-empty.png';

import smallAries from '../../images/ast-small-aries.svg';
import smallTaurus from '../../images/ast-small-taurus.svg';
import smallGemini from '../../images/ast-small-gemini.svg';
import smallCancer from '../../images/ast-small-cancer.svg';
import smallLeo from '../../images/ast-small-leo.svg';
import smallVirgo from '../../images/ast-small-virgo.svg';
import smallLibra from '../../images/ast-small-libra.svg';
import smallScorpio from '../../images/ast-small-scorpio.svg';
import smallSagittarius from '../../images/ast-small-sagittarius.svg';
import smallCapricorn from '../../images/ast-small-capricorn.svg';
import smallAquarius from '../../images/ast-small-aquarius.svg';
import smallPisces from '../../images/ast-small-pisces.svg';
import smallAscendant from '../../images/ast-small-ascendant.svg';

export const ZodiacSignsUnselected = {
  value: 'default',
  display: 'Select a sign',
  image: empty,
  smallImage: empty,
  summary: '',
};

export const ZodiacSigns: ZodiacSignProps[] = [
  ZodiacSignsUnselected,
  {
    value: 'aquarius',
    display: 'Aquarius',
    dateRange: 'Jan 20th to Feb 18th',
    image: aquarius,
    smallImage: smallAquarius,
    summary:
      'Aquarians are known for their independent and progressive nature. They are often seen as innovative thinkers, highly intellectual, and deeply humanitarian, always striving for social change and equality.',
  },
  {
    value: 'aries',
    display: 'Aries',
    dateRange: 'Mar 21st to Apr 19th',
    image: aries,
    smallImage: smallAries,
    summary:
      'Aries individuals are known for their boldness, enthusiasm, and determination. They are natural leaders who thrive on challenges, often being adventurous, competitive, and full of energy, always ready to take on new ventures.',
  },
  {
    value: 'cancer',
    display: 'Cancer',
    dateRange: 'Jun 21st to Jul 22nd',
    image: cancer,
    smallImage: smallCancer,
    summary:
      'Cancerians are known for their nurturing and empathetic nature. They are highly intuitive, emotional, and protective of their loved ones, often exhibiting a strong sense of loyalty and devotion in their relationships.',
  },
  {
    value: 'capricorn',
    display: 'Capricorn',
    dateRange: 'Dec 22nd to Jan 19th',
    image: capricorn,
    smallImage: smallCapricorn,
    summary:
      'Capricorns are known for their ambition, discipline, and practicality. They are hardworking individuals with a strong sense of responsibility and perseverance, often striving for success and stability in all aspects of life.',
  },
  {
    value: 'gemini',
    display: 'Gemini',
    dateRange: 'May 21st to Jun 20th',
    image: gemini,
    smallImage: smallGemini,
    summary:
      'Geminis are known for their curiosity, versatility, and sociable nature. They are excellent communicators, adaptable, and intellectually curious, often displaying a dual personality that is both charming and complex.',
  },
  {
    value: 'leo',
    display: 'Leo',
    dateRange: 'Jul 23rd to Aug 22nd',
    image: leo,
    smallImage: smallLeo,
    summary:
      'Leos are known for their confidence, charisma, and leadership qualities. They are natural performers who enjoy being in the spotlight, often exhibiting generosity, warmth, and a strong sense of pride in their accomplishments.',
  },
  {
    value: 'libra',
    display: 'Libra',
    dateRange: 'Sep 23rd to Oct 22nd',
    image: libra,
    smallImage: smallLibra,
    summary:
      'Librans are known for their diplomacy, fairness, and harmony-seeking nature. They are skilled mediators who value peace and cooperation, often exhibiting a strong sense of justice and a desire for balance in all areas of life.',
  },
  {
    value: 'pisces',
    display: 'Pisces',
    dateRange: 'Feb 19th to Mar 20th',
    image: pisces,
    smallImage: smallPisces,
    summary:
      'Pisceans are known for their empathy, creativity, and dreamy nature. They are deeply intuitive, compassionate, and imaginative individuals, often displaying a strong connection to their emotions and the spiritual realm.',
  },
  {
    value: 'sagittarius',
    display: 'Sagittarius',
    dateRange: 'Nov 22nd to Dec 21st',
    image: sagittarius,
    smallImage: smallSagittarius,
    summary:
      'Sagittarians are known for their adventurous spirit, optimism, and philosophical outlook. They are seekers of truth and knowledge, often being open-minded, enthusiastic, and freedom-loving, always ready for new experiences.',
  },
  {
    value: 'scorpio',
    display: 'Scorpio',
    dateRange: 'Oct 23rd to Nov 21st',
    image: scorpio,
    smallImage: smallScorpio,
    summary:
      'Scorpios are known for their intensity, passion, and resourcefulness. They are deeply emotional and perceptive individuals, often exhibiting determination, loyalty, and a mysterious aura that draws others to them.',
  },
  {
    value: 'taurus',
    display: 'Taurus',
    dateRange: 'Apr 20th to May 20th',
    image: taurus,
    smallImage: smallTaurus,
    summary:
      'Taureans are known for their stability, reliability, and practicality. They are grounded individuals who value security and comfort, often exhibiting patience, loyalty, and a strong connection to nature and material pleasures.',
  },
  {
    value: 'virgo',
    display: 'Virgo',
    dateRange: 'Aug 23rd to Sep 22nd',
    image: virgo,
    smallImage: smallVirgo,
    summary:
      'Virgos are known for their analytical mind, attention to detail, and perfectionist tendencies. They are methodical and reliable individuals who excel in organization and problem-solving, often exhibiting a strong sense of duty and a desire for orderliness.',
  },
] as ZodiacSignProps[];

export const MenuOptions: { [key: string]: MenuSelectOptionProps } = {
  summary: {
    value: 'summary',
    display: 'Summary',
    summaryDisplay: 'Summary',
    category: 'Astrology',
    order: 0,
  },
  horoscope: {
    value: 'horoscope',
    display: 'Horoscope',
    summaryDisplay: 'Horoscope',
    category: 'Astrology',
    order: 1,
  },
  compatibility: {
    value: 'compatibility',
    display: 'Compatibility',
    summaryDisplay: 'Compatibility',
    category: 'Compatibility',
    startIconUrl: compatibilityImg,
    order: 3,
  },
  lifepath: {
    value: 'lifepath',
    display: 'Life Path',
    summaryDisplay: 'Numerology Lifepath',
    category: 'Numerology',
    order: 2,
  },
  personality: {
    value: 'personality',
    display: 'Personality Trait',
    summaryDisplay: 'Numerology Personality Trait',
    category: 'Numerology',
    order: 4,
  },
  ascendant: {
    value: 'ascendant',
    display: 'Rising/Ascendant',
    summaryDisplay: 'Rising Sign/Ascendant',
    category: 'Rising Sign',
    startIconUrl: smallAscendant,
    order: 5,
  },
};

export const GetCompatibilityZodiacSigns = (variation: boolean) => {
  const compatibilitySummariesToDisplay = variation
    ? enhancedCompatibilitySummaries
    : compatibilitySummaries;

  return {
    aquarius: {
      aquarius: {
        compatibility: 45,
        summary: compatibilitySummariesToDisplay.aquarius.aquarius,
      },
      aries: {
        compatibility: 78,
        summary: compatibilitySummariesToDisplay.aquarius.aries,
      },
      cancer: {
        compatibility: 27,
        summary: compatibilitySummariesToDisplay.aquarius.cancer,
      },
      capricorn: {
        compatibility: 68,
        summary: compatibilitySummariesToDisplay.aquarius.capricorn,
      },
      gemini: {
        compatibility: 85,
        summary: compatibilitySummariesToDisplay.aquarius.gemini,
      },
      leo: {
        compatibility: 68,
        summary: compatibilitySummariesToDisplay.aquarius.leo,
      },
      libra: {
        compatibility: 90,
        summary: compatibilitySummariesToDisplay.aquarius.libra,
      },
      pisces: {
        compatibility: 45,
        summary: compatibilitySummariesToDisplay.aquarius.pisces,
      },
      sagittarius: {
        compatibility: 90,
        summary: compatibilitySummariesToDisplay.aquarius.sagittarius,
      },
      scorpio: {
        compatibility: 73,
        summary: compatibilitySummariesToDisplay.aquarius.scorpio,
      },
      taurus: {
        compatibility: 58,
        summary: compatibilitySummariesToDisplay.aquarius.taurus,
      },
      virgo: {
        compatibility: 30,
        summary: compatibilitySummariesToDisplay.aquarius.virgo,
      },
    },
    aries: {
      aquarius: {
        compatibility: 78,
        summary: compatibilitySummariesToDisplay.aries.aquarius,
      },
      aries: {
        compatibility: 50,
        summary: compatibilitySummariesToDisplay.aries.aries,
      },
      cancer: {
        compatibility: 42,
        summary: compatibilitySummariesToDisplay.aries.cancer,
      },
      capricorn: {
        compatibility: 47,
        summary: compatibilitySummariesToDisplay.aries.capricorn,
      },
      gemini: {
        compatibility: 83,
        summary: compatibilitySummariesToDisplay.aries.gemini,
      },
      leo: {
        compatibility: 97,
        summary: compatibilitySummariesToDisplay.aries.leo,
      },
      libra: {
        compatibility: 85,
        summary: compatibilitySummariesToDisplay.aries.libra,
      },
      pisces: {
        compatibility: 67,
        summary: compatibilitySummariesToDisplay.aries.pisces,
      },
      sagittarius: {
        compatibility: 93,
        summary: compatibilitySummariesToDisplay.aries.sagittarius,
      },
      scorpio: {
        compatibility: 50,
        summary: compatibilitySummariesToDisplay.aries.scorpio,
      },
      taurus: {
        compatibility: 38,
        summary: compatibilitySummariesToDisplay.aries.taurus,
      },
      virgo: {
        compatibility: 63,
        summary: compatibilitySummariesToDisplay.aries.virgo,
      },
    },
    cancer: {
      aquarius: {
        compatibility: 27,
        summary: compatibilitySummariesToDisplay.cancer.aquarius,
      },
      aries: {
        compatibility: 42,
        summary: compatibilitySummariesToDisplay.cancer.aries,
      },
      cancer: {
        compatibility: 75,
        summary: compatibilitySummariesToDisplay.cancer.cancer,
      },
      capricorn: {
        compatibility: 83,
        summary: compatibilitySummariesToDisplay.cancer.capricorn,
      },
      gemini: {
        compatibility: 65,
        summary: compatibilitySummariesToDisplay.cancer.gemini,
      },
      leo: {
        compatibility: 35,
        summary: compatibilitySummariesToDisplay.cancer.leo,
      },
      libra: {
        compatibility: 43,
        summary: compatibilitySummariesToDisplay.cancer.libra,
      },
      pisces: {
        compatibility: 98,
        summary: compatibilitySummariesToDisplay.cancer.pisces,
      },
      sagittarius: {
        compatibility: 53,
        summary: compatibilitySummariesToDisplay.cancer.sagittarius,
      },
      scorpio: {
        compatibility: 94,
        summary: compatibilitySummariesToDisplay.cancer.scorpio,
      },
      taurus: {
        compatibility: 97,
        summary: compatibilitySummariesToDisplay.cancer.taurus,
      },
      virgo: {
        compatibility: 90,
        summary: compatibilitySummariesToDisplay.cancer.virgo,
      },
    },
    capricorn: {
      aquarius: {
        compatibility: 68,
        summary: compatibilitySummariesToDisplay.capricorn.aquarius,
      },
      aries: {
        compatibility: 47,
        summary: compatibilitySummariesToDisplay.capricorn.aries,
      },
      cancer: {
        compatibility: 83,
        summary: compatibilitySummariesToDisplay.capricorn.cancer,
      },
      capricorn: {
        compatibility: 75,
        summary: compatibilitySummariesToDisplay.capricorn.capricorn,
      },
      gemini: {
        compatibility: 68,
        summary: compatibilitySummariesToDisplay.capricorn.gemini,
      },
      leo: {
        compatibility: 35,
        summary: compatibilitySummariesToDisplay.capricorn.leo,
      },
      libra: {
        compatibility: 55,
        summary: compatibilitySummariesToDisplay.capricorn.libra,
      },
      pisces: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.capricorn.pisces,
      },
      sagittarius: {
        compatibility: 60,
        summary: compatibilitySummariesToDisplay.capricorn.sagittarius,
      },
      scorpio: {
        compatibility: 95,
        summary: compatibilitySummariesToDisplay.capricorn.scorpio,
      },
      taurus: {
        compatibility: 98,
        summary: compatibilitySummariesToDisplay.capricorn.taurus,
      },
      virgo: {
        compatibility: 95,
        summary: compatibilitySummariesToDisplay.capricorn.virgo,
      },
    },
    gemini: {
      aquarius: {
        compatibility: 85,
        summary: compatibilitySummariesToDisplay.gemini.aquarius,
      },
      aries: {
        compatibility: 83,
        summary: compatibilitySummariesToDisplay.gemini.aries,
      },
      cancer: {
        compatibility: 65,
        summary: compatibilitySummariesToDisplay.gemini.cancer,
      },
      capricorn: {
        compatibility: 68,
        summary: compatibilitySummariesToDisplay.gemini.capricorn,
      },
      gemini: {
        compatibility: 60,
        summary: compatibilitySummariesToDisplay.gemini.gemini,
      },
      leo: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.gemini.leo,
      },
      libra: {
        compatibility: 93,
        summary: compatibilitySummariesToDisplay.gemini.libra,
      },
      pisces: {
        compatibility: 53,
        summary: compatibilitySummariesToDisplay.gemini.pisces,
      },
      sagittarius: {
        compatibility: 60,
        summary: compatibilitySummariesToDisplay.gemini.sagittarius,
      },
      scorpio: {
        compatibility: 28,
        summary: compatibilitySummariesToDisplay.gemini.scorpio,
      },
      taurus: {
        compatibility: 33,
        summary: compatibilitySummariesToDisplay.gemini.taurus,
      },
      virgo: {
        compatibility: 68,
        summary: compatibilitySummariesToDisplay.gemini.virgo,
      },
    },
    leo: {
      aquarius: {
        compatibility: 68,
        summary: compatibilitySummariesToDisplay.leo.aquarius,
      },
      aries: {
        compatibility: 97,
        summary: compatibilitySummariesToDisplay.leo.aries,
      },
      cancer: {
        compatibility: 35,
        summary: compatibilitySummariesToDisplay.leo.cancer,
      },
      capricorn: {
        compatibility: 35,
        summary: compatibilitySummariesToDisplay.leo.capricorn,
      },
      gemini: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.leo.gemini,
      },
      leo: {
        compatibility: 45,
        summary: compatibilitySummariesToDisplay.leo.leo,
      },
      libra: {
        compatibility: 97,
        summary: compatibilitySummariesToDisplay.leo.libra,
      },
      pisces: {
        compatibility: 38,
        summary: compatibilitySummariesToDisplay.leo.pisces,
      },
      sagittarius: {
        compatibility: 93,
        summary: compatibilitySummariesToDisplay.leo.sagittarius,
      },
      scorpio: {
        compatibility: 58,
        summary: compatibilitySummariesToDisplay.leo.scorpio,
      },
      taurus: {
        compatibility: 73,
        summary: compatibilitySummariesToDisplay.leo.taurus,
      },
      virgo: {
        compatibility: 35,
        summary: compatibilitySummariesToDisplay.leo.virgo,
      },
    },
    libra: {
      aquarius: {
        compatibility: 90,
        summary: compatibilitySummariesToDisplay.libra.aquarius,
      },
      aries: {
        compatibility: 85,
        summary: compatibilitySummariesToDisplay.libra.aries,
      },
      cancer: {
        compatibility: 43,
        summary: compatibilitySummariesToDisplay.libra.cancer,
      },
      capricorn: {
        compatibility: 55,
        summary: compatibilitySummariesToDisplay.libra.capricorn,
      },
      gemini: {
        compatibility: 93,
        summary: compatibilitySummariesToDisplay.libra.gemini,
      },
      leo: {
        compatibility: 97,
        summary: compatibilitySummariesToDisplay.libra.leo,
      },
      libra: {
        compatibility: 75,
        summary: compatibilitySummariesToDisplay.libra.libra,
      },
      pisces: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.libra.pisces,
      },
      sagittarius: {
        compatibility: 73,
        summary: compatibilitySummariesToDisplay.libra.sagittarius,
      },
      scorpio: {
        compatibility: 35,
        summary: compatibilitySummariesToDisplay.libra.scorpio,
      },
      taurus: {
        compatibility: 65,
        summary: compatibilitySummariesToDisplay.libra.taurus,
      },
      virgo: {
        compatibility: 68,
        summary: compatibilitySummariesToDisplay.libra.virgo,
      },
    },
    pisces: {
      aquarius: {
        compatibility: 45,
        summary: compatibilitySummariesToDisplay.pisces.aquarius,
      },
      aries: {
        compatibility: 67,
        summary: compatibilitySummariesToDisplay.pisces.aries,
      },
      cancer: {
        compatibility: 98,
        summary: compatibilitySummariesToDisplay.pisces.cancer,
      },
      capricorn: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.pisces.capricorn,
      },
      gemini: {
        compatibility: 53,
        summary: compatibilitySummariesToDisplay.pisces.gemini,
      },
      leo: {
        compatibility: 38,
        summary: compatibilitySummariesToDisplay.pisces.leo,
      },
      libra: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.pisces.libra,
      },
      pisces: {
        compatibility: 60,
        summary: compatibilitySummariesToDisplay.pisces.pisces,
      },
      sagittarius: {
        compatibility: 63,
        summary: compatibilitySummariesToDisplay.pisces.sagittarius,
      },
      scorpio: {
        compatibility: 97,
        summary: compatibilitySummariesToDisplay.pisces.scorpio,
      },
      taurus: {
        compatibility: 85,
        summary: compatibilitySummariesToDisplay.pisces.taurus,
      },
      virgo: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.pisces.virgo,
      },
    },
    sagittarius: {
      aquarius: {
        compatibility: 90,
        summary: compatibilitySummariesToDisplay.sagittarius.aquarius,
      },
      aries: {
        compatibility: 93,
        summary: compatibilitySummariesToDisplay.sagittarius.aries,
      },
      cancer: {
        compatibility: 53,
        summary: compatibilitySummariesToDisplay.sagittarius.cancer,
      },
      capricorn: {
        compatibility: 60,
        summary: compatibilitySummariesToDisplay.sagittarius.capricorn,
      },
      gemini: {
        compatibility: 60,
        summary: compatibilitySummariesToDisplay.sagittarius.gemini,
      },
      leo: {
        compatibility: 93,
        summary: compatibilitySummariesToDisplay.sagittarius.leo,
      },
      libra: {
        compatibility: 73,
        summary: compatibilitySummariesToDisplay.sagittarius.libra,
      },
      pisces: {
        compatibility: 63,
        summary: compatibilitySummariesToDisplay.sagittarius.pisces,
      },
      sagittarius: {
        compatibility: 45,
        summary: compatibilitySummariesToDisplay.sagittarius.sagittarius,
      },
      scorpio: {
        compatibility: 28,
        summary: compatibilitySummariesToDisplay.sagittarius.scorpio,
      },
      taurus: {
        compatibility: 30,
        summary: compatibilitySummariesToDisplay.sagittarius.taurus,
      },
      virgo: {
        compatibility: 48,
        summary: compatibilitySummariesToDisplay.sagittarius.virgo,
      },
    },
    scorpio: {
      aquarius: {
        compatibility: 73,
        summary: compatibilitySummariesToDisplay.scorpio.aquarius,
      },
      aries: {
        compatibility: 50,
        summary: compatibilitySummariesToDisplay.scorpio.aries,
      },
      cancer: {
        compatibility: 94,
        summary: compatibilitySummariesToDisplay.scorpio.cancer,
      },
      capricorn: {
        compatibility: 95,
        summary: compatibilitySummariesToDisplay.scorpio.capricorn,
      },
      gemini: {
        compatibility: 28,
        summary: compatibilitySummariesToDisplay.scorpio.gemini,
      },
      leo: {
        compatibility: 58,
        summary: compatibilitySummariesToDisplay.scorpio.leo,
      },
      libra: {
        compatibility: 35,
        summary: compatibilitySummariesToDisplay.scorpio.libra,
      },
      pisces: {
        compatibility: 97,
        summary: compatibilitySummariesToDisplay.scorpio.pisces,
      },
      sagittarius: {
        compatibility: 28,
        summary: compatibilitySummariesToDisplay.scorpio.sagittarius,
      },
      scorpio: {
        compatibility: 80,
        summary: compatibilitySummariesToDisplay.scorpio.scorpio,
      },
      taurus: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.scorpio.taurus,
      },
      virgo: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.scorpio.virgo,
      },
    },
    taurus: {
      aquarius: {
        compatibility: 58,
        summary: compatibilitySummariesToDisplay.taurus.aquarius,
      },
      aries: {
        compatibility: 38,
        summary: compatibilitySummariesToDisplay.taurus.aries,
      },
      cancer: {
        compatibility: 97,
        summary: compatibilitySummariesToDisplay.taurus.cancer,
      },
      capricorn: {
        compatibility: 98,
        summary: compatibilitySummariesToDisplay.taurus.capricorn,
      },
      gemini: {
        compatibility: 33,
        summary: compatibilitySummariesToDisplay.taurus.gemini,
      },
      leo: {
        compatibility: 73,
        summary: compatibilitySummariesToDisplay.taurus.leo,
      },
      libra: {
        compatibility: 65,
        summary: compatibilitySummariesToDisplay.taurus.libra,
      },
      pisces: {
        compatibility: 85,
        summary: compatibilitySummariesToDisplay.taurus.pisces,
      },
      sagittarius: {
        compatibility: 30,
        summary: compatibilitySummariesToDisplay.taurus.sagittarius,
      },
      scorpio: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.taurus.scorpio,
      },
      taurus: {
        compatibility: 65,
        summary: compatibilitySummariesToDisplay.taurus.taurus,
      },
      virgo: {
        compatibility: 90,
        summary: compatibilitySummariesToDisplay.taurus.virgo,
      },
    },
    virgo: {
      aquarius: {
        compatibility: 30,
        summary: compatibilitySummariesToDisplay.virgo.aquarius,
      },
      aries: {
        compatibility: 63,
        summary: compatibilitySummariesToDisplay.virgo.aries,
      },
      cancer: {
        compatibility: 90,
        summary: compatibilitySummariesToDisplay.virgo.cancer,
      },
      capricorn: {
        compatibility: 95,
        summary: compatibilitySummariesToDisplay.virgo.capricorn,
      },
      gemini: {
        compatibility: 68,
        summary: compatibilitySummariesToDisplay.virgo.gemini,
      },
      leo: {
        compatibility: 35,
        summary: compatibilitySummariesToDisplay.virgo.leo,
      },
      libra: {
        compatibility: 68,
        summary: compatibilitySummariesToDisplay.virgo.libra,
      },
      pisces: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.virgo.pisces,
      },
      sagittarius: {
        compatibility: 48,
        summary: compatibilitySummariesToDisplay.virgo.sagittarius,
      },
      scorpio: {
        compatibility: 88,
        summary: compatibilitySummariesToDisplay.virgo.scorpio,
      },
      taurus: {
        compatibility: 90,
        summary: compatibilitySummariesToDisplay.virgo.taurus,
      },
      virgo: {
        compatibility: 65,
        summary: compatibilitySummariesToDisplay.virgo.virgo,
      },
    },
  } as CompatibilityZodiacSignsProps;
};
