import { OxfordHelpers } from './Helpers';
import { XmlHandler } from './XmlHandler';
import {
  authenticationCheckAnswersPath,
  authenticationPostAnswersPath,
  authenticationQuestionPath,
  oneTimePasscodeMCQPartialText,
  oneTimePasscodeUserInputPartialText,
} from './constants';

export type SelectedAnswer = {
  answer_id: string;
  question_id: string;
  user_input?: string;
};

export type AuthenticationQuestions = {
  errors?: string[];
  questions?: string;
  questionList?: Question[];
  in_progress?: boolean;
  update_user_profile?: boolean;
  next_attempt_date?: string;
  authenticated?: boolean;
};

type Answer = {
  answerText: string;
  answerId: string;
};

export type Question = {
  questionText: string;
  questionId: string;
  answerChoices: Answer[];
};

export class UseAuthentication {
  public static async getAuthenticationQuestionRequest(
    queryParams: { black_box_id?: string },
    token: string
  ) {
    return OxfordHelpers.poll({
      method: 'GET',
      url: authenticationQuestionPath,
      queryParams,
      token,
      jsonRequired: true,
    });
  }

  public static async postAuthenticationAnswersRequest(
    body: SelectedAnswer[],
    token: string
  ) {
    return OxfordHelpers.request({
      method: 'POST',
      url: authenticationPostAnswersPath,
      body: { custom_attributes: { answers: body } },
      token,
    });
  }

  public static async getAuthenticationCheckAnswersRequest(token: string) {
    return OxfordHelpers.poll({
      method: 'GET',
      url: authenticationCheckAnswersPath,
      token,
      jsonRequired: true,
    });
  }

  static _answersBuilder(fullAnswerChoices: NodeListOf<Element>) {
    let answerChoiceList: Answer[] = [];
    fullAnswerChoices.forEach((answerChoice) => {
      const answerText =
        answerChoice.querySelector('AnswerChoiceText')?.textContent;
      const answerId =
        answerChoice.querySelector('AnswerChoiceId')?.textContent;

      if (!(answerText && answerId)) {
        throw new Error(
          "Authentication questions's answers missing required attributes"
        );
      }

      if (answerText.includes(oneTimePasscodeMCQPartialText)) {
        return;
      }

      answerChoiceList.push({
        answerText,
        answerId,
      });
    });

    return answerChoiceList;
  }

  static questionsListMeta(rawQuestions: string) {
    const xmlDoc = XmlHandler().parseToXml(rawQuestions);

    let questionAnswerList: Question[] = [];
    const questions = xmlDoc.querySelectorAll('MultiChoiceQuestion');

    if (questions.length === 0)
      throw new Error('Authentication Questions not parsable, but present.');

    questions.forEach((question) => {
      const questionText =
        question?.querySelector('FullQuestionText')?.textContent;
      const questionId = question?.querySelector('QuestionId')?.textContent;
      const answerChoices = UseAuthentication._answersBuilder(
        question?.querySelectorAll('AnswerChoice')
      );

      if (!(questionText && questionId && answerChoices.length)) {
        throw new Error(
          'Authentication Question parsing missing required attributes'
        );
      }

      questionAnswerList.push({
        questionText,
        questionId,
        answerChoices,
      });
    });

    return questionAnswerList;
  }

  static isOneTimePasscodeQuestion(questionList: Question[] | undefined) {
    return (
      questionList?.length === 1 &&
      (questionList[0].questionText.includes(oneTimePasscodeMCQPartialText) ||
        questionList[0].questionText.includes(
          oneTimePasscodeUserInputPartialText
        ))
    );
  }

  static isOneTimePasscodeMCQ(questionText: string) {
    return questionText.includes(oneTimePasscodeMCQPartialText);
  }

  static isOneTimePasscodeUserInput(questionText: string) {
    return questionText.includes(oneTimePasscodeUserInputPartialText);
  }
}
