export const scoreModifiersAttributesIds: Record<string, string> = {
  DecreaseCreditBalance: 'amount',
  IncreaseCreditBalance: 'amount',
  IncreaseCreditCardLimit: 'amount',
  AllAccountsPastDue: 'NumberOfDays',
  OnTimePayment: 'NumberOfMonths',
  OneAccountPastDue: 'NumberOfDays',
  ObtainCreditCard: 'creditLimit',
  ObtainCreditCardAsAuthorizedUser: 'creditLimit',
  TransferCreditBalances: 'transferAmount',
  ApplyForCreditCard: 'numberOfInquiries',
  ObtainAutoLoan: 'creditLimit',
  ObtainMortgage: 'creditLimit',
  ObtainPersonalLoan: 'creditLimit',
};
