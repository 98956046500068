import { Box, Button, Text } from '@ltvco/refresh-lib/theme';
import { StepProps } from './StepOne';
import { StepOptionButton } from './StepOptionButton';
import { useBumpeeChat } from '../hooks/useBumpeeChat';
import { useState } from 'react';
import { TransitionChatModal } from '../ChatContainer/TransitionChatModal/TransitionChatModal';
import { useNavigate } from 'react-router-dom';
import { languageName, translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';

export const StepThree = ({
  backStep,
  setSelectedOptions,
  selectedOptions,
  isOnboarding = true,
}: StepProps) => {
  const { createChatMutation } = useBumpeeChat(0, false, true);
  const { language: nativeLanguage } = useLanguageContext();
  const redirect = useNavigate();
  const [TransitionChatModalOpen, setTransitionChatModalOpen] = useState(false);
  let language;

  if (isOnboarding) {
    language = languageName(
      Boolean(selectedOptions.native_language_id)
        ? selectedOptions.native_language_id
        : 6
    );
  } else {
    language = nativeLanguage;
  }

  const handleStartConversation = () => {
    setTransitionChatModalOpen(true);
  };

  const handleCloseTransitionChatModal = async () => {
    const response = await createChatMutation.mutateAsync(selectedOptions);
    redirect(`/dashboard/language/chat/${response?.chat_id}`);
  };

  return (
    <Box width="100%">
      {TransitionChatModalOpen && (
        <TransitionChatModal
          learningLanguage={selectedOptions.learning_language_id}
          nativeLanguage={languageName(selectedOptions.native_language_id)}
          setNewTransitionModalOpen={handleCloseTransitionChatModal}
        />
      )}
      <Box>
        <Text variant="h2">
          {translations.onBoarding.questions.topic[language]}
        </Text>
        {translations.topics[language]?.map((topic) => (
          <StepOptionButton
            key={topic.id}
            option={topic}
            responseKey="topic_id"
            setSelectedOptions={setSelectedOptions!}
            selectedOptions={selectedOptions}
          />
        ))}
      </Box>
      {isOnboarding && (
        <Box pt={4}>
          <Button variant="outlined" onClick={backStep} sx={{ mr: 2 }}>
            {translations.buttons.back[language]}
          </Button>
          <Button
            variant="contained"
            onClick={handleStartConversation}
            disabled={selectedOptions.topic_id === 0}
            className="language-continue"
          >
            {translations.buttons.start[language]}
          </Button>
        </Box>
      )}
    </Box>
  );
};
