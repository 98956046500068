import { Box, CustomIcons, Stack, Text, useTheme } from '@ltvco/refresh-lib/theme';
import { useMemo } from 'react';
import { filterAndSortReports } from 'routes/Dashboard/IdentityHub/utils';
import { ClaimedReportMonitorsContainer } from './ClaimedReportMonitorsContainer';
import { useReportMonitors } from '@ltvco/refresh-lib/v1';

export const ClaimedReportMonitorTiles = () => {
  const { currentMonitors } = useReportMonitors();

  const filteredAndSortedReports = useMemo(
    () => filterAndSortReports(currentMonitors || []),
    [currentMonitors]
  );

  const claimedPersonReports =
    filteredAndSortedReports['detailed_person_report'];
  const claimedEmailReports = filteredAndSortedReports['social_network_report'];
  const claimedPhoneReports = filteredAndSortedReports['reverse_phone_report'];
  const claimedSocialMediaReports = filteredAndSortedReports['username_report'];
  const claimedPropertyReports = filteredAndSortedReports['property_report'];
  const claimedVehicleReports = filteredAndSortedReports['vehicle_report'];

  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.background.paper }} padding={2.5}>
      <Stack direction="column" mb={2.5}>
        <Stack direction="row">
          <CustomIcons.IdMonitorIcon color="primary" />
          <Text variant="h3"> Personal Report Monitoring</Text>
        </Stack>

        <Text variant="body1">
          Monitor your reports for changes in your public records related to
          your name, properties, registered vehicles, phone numbers, email
          addresses, or social media accounts
        </Text>
      </Stack>

      <Stack direction={['column', 'row']} gap={2.5} flexWrap={'wrap'}>
        <ClaimedReportMonitorsContainer
          reportMonitors={claimedPersonReports}
          rawReportType={'detailed_person_report'}
        />
        <ClaimedReportMonitorsContainer
          reportMonitors={claimedPropertyReports}
          rawReportType={'property_report'}
        />
        <ClaimedReportMonitorsContainer
          reportMonitors={claimedPhoneReports}
          rawReportType={'reverse_phone_report'}
        />
        <ClaimedReportMonitorsContainer
          reportMonitors={claimedVehicleReports}
          rawReportType={'vehicle_report'}
        />
        <ClaimedReportMonitorsContainer
          reportMonitors={claimedEmailReports}
          rawReportType={'social_network_report'}
        />
        <ClaimedReportMonitorsContainer
          reportMonitors={claimedSocialMediaReports}
          rawReportType={'username_report'}
        />
      </Stack>
    </Box>
  );
};
