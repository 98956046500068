import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { request } from '@ltvco/refresh-lib/utils';
import { useQueryClient, useMutation } from '@ltvco/refresh-lib/vendors';
import { useContext } from 'react';

const SUBMIT_LEAD_FORM_URL = '/api/v5/custom_plan_leads';

interface LeadFormData {
  formData: {
    custom_plan_lead: Record<string, any>;
  };
}

export const useSubmitUpgradeLead = () => {
  const queryClient = useQueryClient();
  const { logError } = useContext(AppConfig);

  return useMutation({
    mutationFn: (formData: LeadFormData) => {
      const data = new URLSearchParams();
      Object.keys(formData.formData.custom_plan_lead).forEach((key) => {
        data.append(
          `custom_plan_lead[${key}]`,
          formData.formData.custom_plan_lead[key]
        );
      });

      return request(SUBMIT_LEAD_FORM_URL, {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
        body: data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['upgradeLeadForm'] });
    },
    onError: (error: Error) => {
      logError('Error submitting upgrade lead form', error);
    },
  });
};
