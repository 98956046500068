import { useState } from 'react';
import { ReportRouteProps } from './types';
import {
  getContactNavLinkData,
  getContactNavLinkDataV2,
} from 'navLinkData/contactNavLinkData';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import imgCompany from '../../images/img_company_placeholder.svg';
import { useContext } from 'react';
import {
  AddressHistorySection,
  EducationSection,
  EmailSection,
  ContactEmploymentSection,
  PhoneSection,
  ReportActionsWithDateUpdated,
  ReportNavigationMenu,
  type ReportNavigationMenuProps,
  SocialSection,
  ReportRating,
  //PossibleKeyContactSection,
  KeyContactSection,
} from '@ltvco/refresh-lib/v2';
import {
  type commonTypes,
  ContactOverviewSection,
  DebugMenu,
  NotesSection,
  openReportInNewTab,
  type Owner,
  type peopleTypes,
  ReportChangesOverview,
  ReportFactory,
  ReportLoading,
  ReportNavMobile,
  ReportNullState,
  type ReportOptions,
  useRemouladeReportSnapshot,
  useReport,
  useReportMonitors,
  useReportRedirect,
  type ContactCompany,
  type SerializedContactTeaserData,
  useSearchContact,
  type ContactSearchParams,
} from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Grid, Box, useTheme } from '@ltvco/refresh-lib/theme';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
  fetchJobTitle,
  nameize,
  DateUtil,
} from '@ltvco/refresh-lib/utils';

interface ContactReportProps extends ReportRouteProps {}

const reportType = 'contact';

export function ContactReportV2({ permalink }: ContactReportProps) {
  const { routingUtils, trackEvent } = useContext(AppConfig);
  const { redirectToSearchContactUrl } = useReportRedirect();
  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  const { reportIsMonitored } = useReportMonitors(permalink);

  const coreResult = useReport(permalink);
  const remouladeResult = useRemouladeReportSnapshot(permalink);

  const flattenedRemouladeResult = remouladeResult
    ? { ...remouladeResult, data: remouladeResult.data?.data }
    : remouladeResult;

  const shouldUseRemouladeSnapshot =
    !remouladeResult.isError && reportIsMonitored;

  const queryResult = (
    shouldUseRemouladeSnapshot ? flattenedRemouladeResult : coreResult
  ) as typeof coreResult;

  const theme = useTheme();

  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: true, // This will filter out data with confidence < 50
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };

  let report = null;
  let reportUpgraded: boolean = false;

  if (queryResult?.data) {
    report = ReportFactory.create(queryResult.data, 'contact');
    reportUpgraded = queryResult.data.meta.report_upgraded;
  }

  let owner = {} as Owner;
  let contactNavLinkData;
  let contactNavLinkDataV2;

  if (report) {
    owner = report.getOwner(reportOptions);
    document.title = `${report.contact} - BeenVerified`;
  }

  const {
    ownerName,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    addresses,
    identity,
    usernames,
  } = owner;

  jobs?.sort((a: peopleTypes.Job, b: peopleTypes.Job) => {
    const dateA = a.period?.start_date?.full
      ? new Date(a.period.start_date.full)
      : new Date(0);
    const dateB = b.period?.start_date?.full
      ? new Date(b.period.start_date.full)
      : new Date(0);

    const dateCompare = dateB.getTime() - dateA.getTime();
    if (dateCompare !== 0) {
      return dateCompare;
    }

    const companyA = a.company?.toLowerCase() || '';
    const companyB = b.company?.toLowerCase() || '';

    if (companyA == '' && companyB == '') {
      return 0;
    } else if (companyA == '') {
      return 1;
    } else if (companyB == '') {
      return -1;
    }

    return companyA.localeCompare(companyB);
  });

  const filterParsedAddresses = addresses
    ? addresses.filter((address) => address.parsed !== null)
    : [];

  let hasContactsByCompanyResults = false;
  let hasContactsByTitleResults = false;

  const job = jobs?.[0];
  const company = job ? job?.company : '';
  const title = job ? fetchJobTitle(job, '') : '';

  const aliases = identity
    ? identity.names.map((name) => nameize(name.full))
    : [];
  aliases.shift();

  if (title !== '') {
    hasContactsByTitleResults = true;
  }

  if (company !== '') {
    hasContactsByCompanyResults = true;
  }

  let {
    data: searchContactResultByCompany = [],
    isFetched: relatedByCompanyFetched,
  } = useSearchContact({
    contactSearchParams: {
      company: company,
    },
  });

  searchContactResultByCompany =
    searchContactResultByCompany as SerializedContactTeaserData;

  const contactsByCompany =
    searchContactResultByCompany?.contacts?.filter(
      (searchResult) => searchResult.id !== identity?.source_ids?.[0]
    ) || [];

  if (report) {
    contactNavLinkData = getContactNavLinkData(
      owner,
      contactsByCompany.length,
      relatedByCompanyFetched
    );
    contactNavLinkDataV2 = getContactNavLinkDataV2(owner);
  }

  let searchData: ContactSearchParams = {
    title: title,
  };

  let {
    data: searchContactResultByTitle = [],
    isFetched: relatedByTitleFetched,
  } = useSearchContact({ contactSearchParams: searchData });

  searchContactResultByTitle =
    searchContactResultByTitle as SerializedContactTeaserData;

  const contactsByTitle =
    searchContactResultByTitle?.contacts?.filter(
      (searchResult) => searchResult.id !== identity?.source_ids?.[0]
    ) || [];

  const getCompanyUrl = (company: string) =>
    routingUtils.searchContactUrl({ company: company });

  const handleCompanyClick = (company: string | ContactCompany) => {
    if (typeof company === 'string') {
      redirectToSearchContactUrl({ company });

      return;
    }

    redirectToSearchContactUrl({ company: company.name });
  };

  const trackSubSections = (subsection?: string) => {
    trackEvent(
      `related contacts_same ${subsection}`,
      'search',
      'contact search'
    );
  };

  const handleRelatedContactCompanyClick = (
    company: string | ContactCompany,
    subsection?: string
  ) => {
    if (typeof company === 'string') {
      trackSubSections(subsection);
      redirectToSearchContactUrl({ company });

      return;
    }

    trackSubSections(subsection);
    redirectToSearchContactUrl({ company: company.name });
  };

  const handleViewReport = (contactId: string, subsection?: string) => {
    trackSubSections(subsection);
    openReportInNewTab(
      {
        contact_id: contactId,
        searchType: 'contact',
      },
      constants.links.baseUrl
    );
  };
  const mostRecentAddress = (addresses: commonTypes.Addresses) => {
    let filteredAddresses = addresses.filter(
      (address) => address.parsed?.city || address.parsed?.state
    );
    return filteredAddresses?.[0];
  };

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={10} />;
  }

  if (zeroed) {
    return <ReportNullState />;
  }

  if (!report) return <ReportLoading menuItems={14} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const date = new DateUtil();
  const reportUpdateDate = report.data.meta?.updated_at
    ? date.parseDateFromString(
        report.data.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';

  return (
    <>
      <Grid container direction={'row'} columns={12} spacing={7}>
        <Grid
          item
          sm={12}
          md={4}
          lg={4}
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
          }}
        >
          {contactNavLinkDataV2 && (
            <ReportNavigationMenu
              reportType="Contact Report"
              headerTitle={ownerName}
              menuItems={
                contactNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
              }
            />
          )}
        </Grid>
        <Grid
          item
          sm={12}
          md={8}
          lg={8}
          marginTop={{ xs: 0, md: 0.5 }}
          sx={{
            '&.MuiGrid-item': {
              paddingTop: { xs: 3.75, md: 7 },
            },
          }}
        >
          {reportIsMonitored && contactNavLinkData && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={contactNavLinkData}
            />
          )}

          <>
            <Box
              sx={{
                height: 30,
                backgroundColor: '#f8f8f8',
                position: 'sticky',
                marginBottom: '-10px',
                marginX: -1,
                top: 52,
                zIndex: 10,
                display: {
                  xs: 'none',
                  sm: 'block',
                  md: 'block',
                  lg: 'block',
                },
              }}
            />

            <ReportActionsWithDateUpdated
              reportType={reportType}
              reportTitle="Contact"
              dateUpdated={reportUpdateDate}
              reportUpgraded={false}
            />
          </>

          <ContactOverviewSection
            personName={ownerName}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) => {
              handleSetReportOptions({
                showHighConfidenceDataOnly: value,
              });
            }}
            onCompanyClick={handleCompanyClick}
            getCompanyUrl={getCompanyUrl}
            contactOverviewItemsData={{
              job: jobs[0],
              address: mostRecentAddress(addresses),
              emails,
              phones,
              profiles,
              education: educations[0],
              identity: aliases,
            }}
          />

          <PhoneSection
            personName={ownerName}
            phoneList={phones}
            permalink={permalink}
          />

          <EmailSection
            personName={ownerName}
            emailList={emails}
            permalink={permalink}
          />

          <AddressHistorySection
            addresses={filterParsedAddresses}
            personName={ownerName}
            permalink={permalink}
            showCityAndStateAddress={true}
          />

          <SocialSection
            personName={ownerName}
            usernames={usernames}
            profiles={profiles}
            permalink={permalink}
          />

          <ContactEmploymentSection
            personName={ownerName}
            permalink={permalink}
            jobs={jobs}
          />

          <EducationSection
            personName={ownerName}
            educations={educations}
            permalink={permalink}
          />

          <KeyContactSection
            companyName={company || ''}
            personName={ownerName}
            hasResults={hasContactsByCompanyResults}
            isLoading={!relatedByCompanyFetched}
            contacts={contactsByCompany}
            showSingleCompany={true}
            onCompanyClick={handleRelatedContactCompanyClick}
            onViewReport={handleViewReport}
            sectionId="key-contacts-section"
            subsection="company"
          />

          <KeyContactSection
            personName={ownerName}
            hasResults={hasContactsByTitleResults}
            isLoading={!relatedByCompanyFetched}
            contacts={contactsByTitle}
            showSingleCompany={true}
            onCompanyClick={handleRelatedContactCompanyClick}
            onViewReport={handleViewReport}
            sectionId="key-contacts-similar-section"
            subsection="title"
          />

          <NotesSection permalink={permalink} />

          <ReportRating
            rating={report.data.meta?.rating || null}
            report_type={reportType}
          />
        </Grid>
      </Grid>
      {contactNavLinkData && (
        <ReportNavMobile navLinkData={contactNavLinkData} />
      )}
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
