import { Stack, Text, SwitchLabel,  Button } from '@ltvco/refresh-lib/theme';
import OneMillionIdentityTheftInsuranceLogo from 'images/1m-identity-theft-insurance.svg';
import TransUnionCreditAlertsLogo from 'images/transunion-credit-alerts.svg';
import EnhancedAlertsLogo from 'images/enhanced-alerts-logo.svg';
import InstantInquiryAlertsLogo from 'images/instant-inquiry-alerts-logo.svg';
import { StyledCard } from './components';
import { ArrowCircleRight } from '@mui/icons-material';

export const NotificationSettings: React.FC<{
  standardAlert: boolean;
  enhancedAlert: boolean;
  instantInquiryAlert: boolean;
  identityManagementAlert: boolean;
  setIdentityManagementAlert: (value: boolean) => void;
  setInstantInquiryAlert: (value: boolean) => void;
  setEnhancedAlert: (value: boolean) => void;
  setStandardAlert: (value: boolean) => void;
  openDisclosureModal: () => void;
}> = ({
  standardAlert,
  setStandardAlert,
  enhancedAlert,
  setEnhancedAlert,
  instantInquiryAlert,
  setInstantInquiryAlert,
  identityManagementAlert,
  setIdentityManagementAlert,
  openDisclosureModal,
}) => {
  return (
    <>
      <StyledCard justifyContent="space-between" className="first-block">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Stack flexDirection="row" gap={2}>
            <img
              src={OneMillionIdentityTheftInsuranceLogo}
              width={35}
              height={35}
              alt="identity theft insurance and restoration"
            />
            <Stack gap={1.5}>
              <Text
                variant="h6"
                data-testid="identityManagementAlert"
                data-test-checked={identityManagementAlert}
              >
                $1M Identity <br /> Theft Insurance & Restoration
              </Text>
              <Button
                endIcon={<ArrowCircleRight color="inherit" />}
                fullWidth
                size="large"
                sx={{
                  textAlign: 'left',
                  textDecoration: 'underline',
                  lineHeight: '19.2px',
                  padding: 0,
                  width: 'fit-content',
                }}
                onClick={() => openDisclosureModal()}
              >
                Disclosure
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <SwitchLabel
          label=""
          checked={identityManagementAlert}
          setChecked={() =>
            setIdentityManagementAlert(!identityManagementAlert)
          }
        />
      </StyledCard>

      <StyledCard justifyContent="space-between" className="second-block">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <img
            src={TransUnionCreditAlertsLogo}
            width={35}
            height={35}
            alt="TransUnion Credit Alerts"
          />
          <Text
            variant="h6"
            data-test-checked={standardAlert}
            data-testid="standardAlert"
          >
            TransUnion® <br />
            Credit Alerts
          </Text>
        </Stack>
        <SwitchLabel
          label=""
          checked={standardAlert}
          setChecked={() => setStandardAlert(!standardAlert)}
        />
      </StyledCard>

      <StyledCard justifyContent="space-between" className="fourth-block">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <img
            src={EnhancedAlertsLogo}
            width={35}
            height={35}
            alt="enhanced alerts"
          />
          <Text
            variant="h6"
            data-testid="enhancedAlert"
            data-test-checked={enhancedAlert}
          >
            Enhanced <br /> Alerts
          </Text>
        </Stack>
        <SwitchLabel
          label=""
          checked={enhancedAlert}
          setChecked={() => setEnhancedAlert(!enhancedAlert)}
        />
      </StyledCard>

      <StyledCard justifyContent="space-between" className="fifth-block">
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <img
            src={InstantInquiryAlertsLogo}
            width={35}
            height={35}
            alt="Instant Inquiry Alerts"
          />
          <Text
            variant="h6"
            data-testid="instantInquiryAlert"
            data-test-checked={instantInquiryAlert}
          >
            Instant <br /> Inquiry Alerts
          </Text>
        </Stack>

        <SwitchLabel
          label=""
          checked={instantInquiryAlert}
          setChecked={() => setInstantInquiryAlert(!instantInquiryAlert)}
        />
      </StyledCard>
    </>
  );
};
