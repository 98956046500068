import React from 'react';
import { Controller } from 'react-hook-form';
import { Input, Select, Stack,  Text } from '@ltvco/refresh-lib/theme';
import { SelectOption } from '@ltvco/refresh-lib/theme';
import { StyledErrorText, StyledInputLabel } from './components';
import { Warning, Info} from '@mui/icons-material';

interface IndividualFieldsProps {
  control: any;
  errors: any;
  reportsPerMonthOptions: SelectOption[];
  reportsPerMonth: string;
}

const IndividualFields: React.FC<IndividualFieldsProps> = ({
  control,
  errors,
  reportsPerMonthOptions,
  reportsPerMonth,
}) => {
  return (
    <>
      <StyledInputLabel htmlFor="email">Email Address*</StyledInputLabel>
      <Controller
        name="email"
        control={control}
        rules={{
          required: 'This field is required',
          pattern: {
            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            message:
              'Please enter a valid email address (e.g. name@example.com).',
          },
        }}
        render={({ field }) => (
          <Input
            fullWidth
            id="email"
            type="email"
            placeholder="Enter Email Address"
            {...field}
            error={Boolean(errors?.email)}
          />
        )}
      />
      {errors.email && (
        <StyledErrorText>
          <Warning fontSize="inherit" />
          {errors.email.message}
        </StyledErrorText>
      )}

      <StyledInputLabel htmlFor="reportsPerMonth">
        How many reports are you looking for per month?*
      </StyledInputLabel>
      <Controller
        name="reportsPerMonth"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Select
            id="reportsPerMonth"
            name="reportsPerMonth"
            value={field.value || ''}
            onOptionSelect={field.onChange}
            placeholder="Select number of reports"
            options={reportsPerMonthOptions}
          />
        )}
      />

      {reportsPerMonth === '+800' && (
        <Stack gap={1} direction="row">
          <Info color="warning" sx={{ fontSize: '1.2rem' }} />
          <Text variant="caption" pt={0.4} sx={{ fontSize: '13px' }}>
            A team member will reach out to discuss pricing
          </Text>
        </Stack>
      )}
    </>
  );
};

export default IndividualFields;
