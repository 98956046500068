import { useEffect, useState, useRef } from 'react';
import { useBumpeeStoryAudio } from './useBumpeeStoryAudio';

export const useAudioPlayer = (
  storyChatId: number | undefined,
  segmentIndex: number,
  playingAudioId: number | null,
  setPlayingAudioId: (segmentIndex: number | null) => void
) => {
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audioHasPlayed, setAudioHasPlayed] = useState<boolean>(false);
  const [audioIsPlaying, setAudioIsPlaying] = useState<boolean>(false);
  const [firstPlay, setFirstPlay] = useState<boolean>(true);
  const [audioHasEnded, setAudioHasEnded] = useState<boolean>(false);
  const [newAudioHasPlayed, setNewAudioHasPlayed] = useState<boolean>(false);
  const [awaitingPlayAfterClick, setAwaitingPlayAfterClick] =
    useState<boolean>(false);
  const { data: audioData, handleAudioClick } = useBumpeeStoryAudio(
    storyChatId!,
    segmentIndex
  );
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const sourceRef = useRef<HTMLSourceElement | null>(null);

  // Pause audio if another audio is playing
  useEffect(() => {
    if (playingAudioId !== segmentIndex) {
      audioRef.current?.pause();
      setNewAudioHasPlayed(true);
    }
  }, [playingAudioId]);

  // Play audio if another audio is playing
  useEffect(() => {
    if (audioIsPlaying && playingAudioId !== segmentIndex) {
      setPlayingAudioId(segmentIndex);
      setNewAudioHasPlayed(true);
    }
  }, [audioIsPlaying]);

  // If audio has been paused and another audio has not played make sure to set newAudioHasPlayed to false
  useEffect(() => {
    if (!audioIsPlaying && playingAudioId === segmentIndex) {
      setNewAudioHasPlayed(false);
    }
  }, [playingAudioId, audioIsPlaying]);

  // Builds audio URL from base64 string
  useEffect(() => {
    if (audioData && audioData.audio) {
      const binaryString = atob(audioData.audio);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }

      const blob = new Blob([bytes], { type: 'audio/mpeg' });
      const url = URL.createObjectURL(blob);
      setAudioUrl(url);

      // Cleanup URL.createObjectURL to avoid memory leaks
      return () => URL.revokeObjectURL(url);
    }
  }, [audioData, audioData?.audio]);

  // Load audio content to the audio element
  useEffect(() => {
    if (!firstPlay) return;
    if (audioRef.current && audioUrl && sourceRef.current) {
      sourceRef.current.src = audioUrl;
      sourceRef.current.type = 'audio/mpeg';
      audioRef?.current.load();
      if (awaitingPlayAfterClick) playAudioContent();
    }
  }, [audioUrl, sourceRef, audioRef, awaitingPlayAfterClick, firstPlay]);

  // Play audio content
  const playAudioContent = () => {
    setAudioHasPlayed(false);
    setAudioHasEnded(false);
    // If new audio has played, reload the audio element to start playing from the beginning
    if (newAudioHasPlayed) {
      audioRef?.current?.load();
    }
    audioRef?.current?.play();
    setAudioIsPlaying(true);
    setFirstPlay(false);
    setAwaitingPlayAfterClick(false);
  };

  // Play audio on button click
  const playAudio = () => {
    if (audioIsPlaying) {
      handlePause();
      return;
    }
    if (audioHasPlayed) {
      playAudioContent();
      return;
    }
    setAwaitingPlayAfterClick(true);
    handleAudioClick();
  };

  // Pause audio
  const handlePause = () => {
    setAudioHasPlayed(true);
    setAudioIsPlaying(false);
    setAwaitingPlayAfterClick(false);
    audioRef?.current?.pause();
  };

  // Handle audio ended
  const handleEnded = () => {
    setAudioIsPlaying(false);
    setAwaitingPlayAfterClick(false);
    setAudioHasEnded(true);
  };

  return {
    playAudio,
    audioRef,
    handlePause,
    sourceRef,
    audioIsPlaying,
    handleEnded,
    audioHasEnded,
  };
};
