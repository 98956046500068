import React from 'react';
import { Text, Button, Stack, Divider} from '@ltvco/refresh-lib/theme';
import { NotificationCard } from '../NotificationsAlertsSummaries/NotificationCard';
import { Notification } from '../Oxford/useNotifications/constants';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

type NotificationAlertsSummariesProps = {
  notifications: any;
};

export const NotificationAlertsSummaries = ({
  notifications,
}: NotificationAlertsSummariesProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="text"
        data-testid="back-button"
        onClick={() => navigate('/dashboard/id-monitor?escalated=true')}
        startIcon={<ArrowBack color="inherit" />}
        sx={{ textDecoration: 'underline', color: 'text.primary' }}
      >
        Back to ID Monitor
      </Button>
      <Stack
        alignItems={'flex-start'}
        justifyContent="space-between"
        flexDirection={['column', 'row']}
      >
        <Text variant="h1">Notifications</Text>
      </Stack>
      <Divider />
      {notifications.length === 0 && (
        <Text variant="h5" sx={{ mt: 2.5 }}>
          There are no notifications.
        </Text>
      )}
      {notifications.map((notification: Notification, index: Number) => (
        <React.Fragment key={`${notification.alertType}-${index}`}>
          <NotificationCard notification={notification} indexVal={index} />
        </React.Fragment>
      ))}
    </>
  );
};
