import { Button, InputLabel, styled, Text } from '@ltvco/refresh-lib/theme';

export const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  fontSize: '.875rem',
  marginBottom: theme.spacing(1),
}));

export const DisclaimerText = styled(Text)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '.75rem',
  marginTop: theme.spacing(1.5),
}));

export const StyledSubmit = styled(Button)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    maxWidth: 110,
  },
}));

export const StyledErrorText = styled(Text)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '.875rem',
  display: 'flex',
  gap: 1,
  marginTop: theme.spacing(0.5),
}));
