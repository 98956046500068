import { useContext } from 'react';
import { AppConstants, AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  type CreateAscendantParams,
  type CreateAscendantResponse,
  type CredentialsParams,
} from './interfaces';
import { astrology } from './api';
import { useQuery } from '@ltvco/refresh-lib/vendors';

export const baseQueryKey = 'astrology_ascendant';

export const useAscendant = (
  identifier: string,
  params: CreateAscendantParams | undefined,
  isEnabled: boolean
) => {
  const {
    keys: { astrologyUser, astrologyKey },
  } = useContext(AppConstants);

  const { logError } = useContext(AppConfig);
  const credentials = {
    user: astrologyUser,
    key: astrologyKey,
  } as CredentialsParams;

  const getAscendantLocalStorage = function (identifier: string) {
    const localData = localStorage.getItem(baseQueryKey);
    if (!localData || !identifier) return false;

    const parsedData = JSON.parse(localData);

    return parsedData?.[identifier];
  };

  const storeAscendantLocalStorage = function (
    ascendantResult: CreateAscendantResponse
  ) {
    const localData = localStorage.getItem(baseQueryKey);
    const parsedData = localData ? JSON.parse(localData) : {};

    parsedData[identifier] = ascendantResult;
    localStorage.setItem(baseQueryKey, JSON.stringify(parsedData));
  };

  const fetchAscendant = async (): Promise<CreateAscendantResponse> => {
    if (
      !params?.day ||
      !params?.month ||
      !params?.year ||
      !params?.hour ||
      !params?.min ||
      !params?.lat ||
      !params?.lon ||
      !params?.tzone ||
      !identifier
    ) {
      return Promise.resolve({} as CreateAscendantResponse);
    }

    const localData = getAscendantLocalStorage(identifier);
    if (localData) {
      return Promise.resolve(localData);
    }

    const newAscendant = await astrology.fetchAstrology({
      credentials,
      queryType: 'ascendant',
      params,
    });

    storeAscendantLocalStorage(newAscendant as CreateAscendantResponse);
    return Promise.resolve(newAscendant as CreateAscendantResponse);
  };

  const isLocalEnabled = isEnabled && !!identifier && !!params;

  const queryResult = useQuery({
    queryKey: [baseQueryKey, identifier],
    queryFn: fetchAscendant,
    enabled: isLocalEnabled,
    cacheTime: 0,
    onError: (error: Error) => {
      logError(`${baseQueryKey} generation failed`, error as Error);
    },
  });
  // useQuery would keep the initial isLoading state which is true if the query is enabled = false since the beggining
  // We return isLoading false if the query is not enabled but if enabled we return the real value
  const isLoading = isLocalEnabled ? queryResult.isLoading : false;

  return {
    ...queryResult,
    isLoading,
  };
};
