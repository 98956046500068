import {
  Button,
  styled,
  Card,
  Input,
  keyframes,
  Theme,
} from '@ltvco/refresh-lib/theme';
import { Circle } from '@mui/icons-material';
import { alpha } from '@mui/material';

export const StyledHintCard = styled(Card)({
  padding: 16,
  paddingBottom: 12,
  borderRadius: '10px',
  marginBottom: '10px',
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: '4px',
  maxWidth: '70%',
  width: '70%',
  boxShadow: 'none',
  height: 'auto',
});

export const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '16px',
  color: theme.palette.primary.contrastText,
  height: '32px',
  minWidth: '32px',
  width: '32px',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const StyledInputCard = styled(Card)(({ theme }) => ({
  borderRadius: 0,
  display: 'flex',
  height: '100px',
  justifyContent: 'space-between',
  marginBottom: 0,
  paddingLeft: 2,
  width: '100%',

  [theme.breakpoints.up('md')]: {
    height: '64px',
    alignItems: 'center',
  },
}));

export const StyledInput = styled(Input)({
  padding: 0,
  width: '100%',
  '.MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },

  '&.MuiTextField-root': {
    verticalAlign: 'baseline',
  },
});

export const MicrophoneButton = styled(Button)(({ theme }) => ({
  minWidth: 40,
  color: theme.palette.primary.light,

  '&:hover': {
    backgroundColor: 'inherit',
  },
}));

const pulse = (theme: Theme) => keyframes`
  0% { box-shadow: 0 0 0 0 ${alpha(theme.palette.error.main, 0.5)} }
  70% { box-shadow: 0 0 0 8px ${alpha(theme.palette.error.main, 0.0)} }
  100% { box-shadow: 0 0 0 0 ${alpha(theme.palette.error.main, 0.0)} }
`;

export const CircleIcon = styled(Circle)(({ theme }) => ({
  margin: 5,
  width: 10,
  height: 10,
  borderRadius: '50%',

  color: theme.palette.error.main,
  boxShadow: `0 0 0 ${alpha(theme.palette.error.main, 0.5)}`,
  animation: `${pulse(theme)} 1500ms ${
    theme.transitions.easing.easeOut
  } infinite`,
}));
