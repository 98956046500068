import React from 'react';
import { Controller } from 'react-hook-form';
import { Input, Select, SelectOption } from '@ltvco/refresh-lib/theme';
import { StyledInputLabel } from './components';

interface UseCaseFieldsProps {
  control: any;
  useCases: SelectOption[];
  selectedUseCase: string;
}

const UseCaseFields: React.FC<UseCaseFieldsProps> = ({
  control,
  useCases,
  selectedUseCase,
}) => {
  return (
    <>
      <StyledInputLabel htmlFor="useCase">
        Describe what you are looking for (optional)
      </StyledInputLabel>
      <Controller
        name="useCase"
        control={control}
        render={({ field }) => (
          <Select
            id="useCase"
            name="useCase"
            value={field.value || ''}
            onOptionSelect={field.onChange}
            placeholder="Select a use case"
            options={useCases}
          />
        )}
      />

      {selectedUseCase === 'Other' && (
        <Controller
          name="otherUseCase"
          control={control}
          render={({ field }) => (
            <Input
              fullWidth
              id="otherUseCase"
              type="text"
              placeholder="Specify your use case (optional)"
              {...field}
            />
          )}
        />
      )}
    </>
  );
};

export default UseCaseFields;
