import { Button, InputLabel, Link, Modal } from '@ltvco/refresh-lib/theme';
import { styled } from '@mui/material/styles';
import { Text } from '@ltvco/refresh-lib/theme';

export const StyledModal = styled(Modal)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 0,
  },
  '& .MuiIconButton-root': {
    right: theme.spacing(2),
    color: 'white',
    '& > svg': {
      fontSize: 22,
    },
  },
}));

export const StyledForm = styled('form')(({ theme }) => ({
  width: '75%',
  paddingTop: theme.spacing(2),
}));

export const StyledImage = styled('img')({
  height: '70px',
  width: 'auto',
  padding: 0,
});

export const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const StyledInputLabel = styled(InputLabel)({
  fontWeight: 'bold',
  color: 'inherit',
  whiteSpace: 'pre-wrap',
});

export const UnderlinedLink = styled(Link)({
  textDecoration: 'underline',
});

export const StyledErrorText = styled(Text)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '.875rem',
  display: 'flex',
  gap: 1,
  marginTop: theme.spacing(0.5),
}));
