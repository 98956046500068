import { Box, styled } from '@ltvco/refresh-lib/theme';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)({
  '&:hover h5': {
    textDecoration: 'underline',
  },
});

export const StyledRecentChatCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: '8px',
  cursor: 'pointer',
  color: theme.palette.text.primary,
  justifyContent: 'center',

  '&.selected': {
    backgroundColor: theme.palette.background.default,
  },
}));
