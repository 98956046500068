import { Text, Divider, Stack } from '@ltvco/refresh-lib/theme';

const IdentityTheftBenefits = () => {
  return (
    <>
      <div data-testid="theft-benefits-modal">
        <Text variant="h3">
          Identity Theft Expenses Insurance - Summary of Benefits
        </Text>
        <Divider sx={{ marginY: 2 }} />
        <Text variant="h3">Summary description of benefits</Text>
        <div>
          <p style={{ display: 'inline', fontWeight: '700' }}>
            Policy Number:{' '}
          </p>
          <p style={{ display: 'inline' }}>FN2306973</p>
        </div>
        <div>
          <p style={{ display: 'inline', fontWeight: '700' }}>Insurer: </p>
          <p style={{ display: 'inline' }}>
            Underwritten by certain Underwriters at Lloyd’s, London
          </p>
        </div>
        <div>
          <p style={{ display: 'inline', fontWeight: '700' }}>
            Master Policyholder:{' '}
          </p>
          <p style={{ display: 'inline' }}>
            Cyberscout Limited, Sontiq Inc. and all subsidiaries
          </p>
        </div>

        <p>
          This document summarizes the coverage available to insureds under the
          Policy. It is only a summary. Additional details, conditions, and
          exclusions are set forth in the Policy, a copy of which is available
          upon request. “We” and “us” refer to the Insurer.
        </p>
        <br />

        <Stack gap={1}>
          <div className="box">
            <Text variant="h4">Aggregate Limit of Insurance</Text>
            <p>
              <strong>
                The aggregate limit of your membership program is:
              </strong>
            </p>
            <ul className="list">
              <li>$1,000,000 per policy period.</li>
            </ul>
          </div>

          <div className="box">
            <Text variant="h4">Aggregate Limit of Insurance</Text>
            <ul className="list">
              <li>Lost Wages: $1,500 per week (8 weeks max.)</li>
              <li>Initial Legal Consultation: $1,000</li>
              <li>Travel Expense: $1,000</li>
              <li>Elder Care & Child Care: $1,000</li>
              <li>Certified Public Accountant Costs: $1,000</li>
            </ul>
          </div>
        </Stack>
        <p>Deductible: $0</p>

        <Text variant="h4">General description of what is insured</Text>
        <p>
          We shall pay the insured for{' '}
          <strong className="underline">loss</strong>, excess of any applicable
          deductible, resulting from
          <strong className="underline"> stolen identity events </strong> first
          discovered by the insured during the policy period and reported to us
          within 120 days of such first discovery of the stolen identity event.
        </p>

        <p>
          We shall reimburse the insured for loss, excess of any applicable
          deductible, resulting from
          <strong className="underline">
            {' '}
            unauthorized electronic fund transfers{' '}
          </strong>
          which (i) first occurs during the policy period; and (ii) is reported
          to us within 90 days of the discovery of such electronic fund
          transfer.
        </p>
      </div>
      <Divider sx={{ marginY: 2 }} />
      <div>
        <Text variant="h4">Selected Definitions</Text>
        <p>
          <strong>“Stolen Identity Event”</strong>
          means the fraudulent use of the insured’s personal identification,
          social security number, or any other method of identifying the
          insured. This includes, but is not limited to, the fraudulent use of
          the personal identity of the insured to establish credit accounts,
          secure loans, enter into contracts or commit crimes. All loss
          resulting from stolen identity event(s) and arising from the same,
          continuous, related or repeated acts shall be treated as arising out
          of a single stolen identity event occurring at the time of the first
          such stolen identity event. Stolen identity event shall not include
          the fraudulent use of the insured’s business name, trading name or any
          other method of identifying any business activity of the insured.
          Stolen identity event shall include “Medical identity theft” as
          defined in the Policy.
        </p>

        <p>
          <strong>“Unauthorized Electronic Fund Transfer” or “UEFT” </strong>
          means an electronic fund transfer from a insured’s account initiated
          by a person other than the insured without the actual authority to
          initiate the transfer and from which the insured receives no benefit.
          An unauthorized electric fund transfer does not include an electronic
          fund transfer initiated:
        </p>
        <p>
          (1) by a person who was furnished the access device to the to the
          insured’s account by the insured, unless the insured has notified the
          financial institution that transfers by such person are no longer
          authorized;
        </p>
        <p>
          (2) with fraudulent intent by the insured or any person acting in
          concert with the insured; or
        </p>
        <p>(3) by the financial institution or its employee.</p>
        <p>
          “Loss” means the reasonable and necessary
          <strong className="underline">
            {' '}
            costs, lost wages, legal defense fees and expenses{' '}
          </strong>
          incurred within twelve months of an insured’s discovery of a stolen
          identity event. The full definitions of “costs,” “lost wages,” and
          “legal defense fees and expenses” are set forth in the Policy.
        </p>

        <ul className="list">
          <li>
            <strong>“Costs” </strong>
            means the specific types of reasonable and necessary costs listed in
            the Policy that are incurred by the insured as a result of a stolen
            identity event.
          </li>
          <br />
          <li>
            <strong>“Lost Wages” </strong>
            means actual lost wages due to the temporary or permanent loss of
            employment as a result of a stolen identity event.
          </li>
          <br />
          <li>
            <strong>“Legal Defense Fees and Expenses” </strong>
            means the reasonable and necessary fees and expenses incurred by the
            insured with our consent for an attorney approved by us.
          </li>
        </ul>

        <p>
          <strong>“Stolen Funds Loss” </strong>
          means the principal amount, exclusive of interest, incurred by the
          insured and caused by an unauthorized electronic fund transfer. Stolen
          Funds Loss shall not include any amount for which the insured did not
          seek reimbursement from the financial institution which issued the
          access device and holds the account from which funds were stolen, and
          stolen funds loss shall not include any amount for which the insured
          received reimbursement from any source. Stolen Funds Loss also means
          fees and charges assessed against an insured by a financial
          institution as a direct result of an unauthorized electronic funds
          transfer.
        </p>
      </div>

      <Divider sx={{ marginY: 2 }} />

      <div>
        <Text variant="h4">
          Your responsibilities if a stolen identity event or loss occurs
        </Text>
        <p>
          <strong>If a stolen identity event occurs: </strong>
          Promptly, but no later than 120 days after first discovering the
          event, notify us by calling the Resolution Center at 877-432-7463.
          Follow our written instructions to mitigate potential loss.
        </p>
        <p style={{ marginBottom: 0 }}>
          <strong>a loss occurs:</strong>
        </p>
        <ul className="list">
          <li>
            Promptly notify us, submit to us the written proof of loss, and
            provide any other reasonable information or documentation that we
            may request.
          </li>
          <li>
            Take reasonable steps to mitigate the loss, including requesting a
            waiver of any applicable fees.
          </li>
          <li>File a report with the appropriate law enforcement authority.</li>
          <li>Provide assistance and cooperation we may require.</li>
        </ul>
        <p style={{ marginBottom: 0 }}>
          <strong>If a stolen funds loss occurs:</strong>
        </p>
        <ul className="list">
          <li>
            Take all reasonable steps to: (a) prevent further stolen funds loss
            after suffering an UEFT, including promptly contacting the financial
            institution which issued the access device and holds the account;
            and (b) obtain reimbursement from the financial institution. Provide
            us a complete description of the efforts to obtain reimbursement and
            stated reasons why full or partial reimbursement was not provided.
          </li>
          <li>
            Promptly notify us and provide us with detailed information
            regarding the stolen funds loss.
          </li>
          <li>
            Provide any reasonable information or documentation we may request,
            including, if requested, a sworn statement or affidavit within sixty
            days of our request.
          </li>
        </ul>
      </div>

      <Divider sx={{ marginY: 2 }} />

      <div>
        <Text variant="h4">
          Additional information about exclusions, insurance limits, and policy
          conditions
        </Text>
        <p>
          Loss arising from the following are not covered (see full list of
          exclusions in Policy):
        </p>
        <p style={{ marginBottom: 0 }}>
          <strong>a loss occurs:</strong>
        </p>
        <ul className="list">
          <li>
            Physical injury, sickness, disease, disability, shock, mental
            anguish, and mental injury.
          </li>
          <li>
            Voluntary disclosure of a code or other security information which
            can be used to gain access to the insured’s account using an access
            device to someone who subsequently contributes to the UEFT.
          </li>
          <li>
            An UEFT which a member of the insured’s family participated in,
            directed, or had prior knowledge.
          </li>
        </ul>

        <p>
          There is no coverage for any stolen identity event or UEFT occurring
          after the effective date and time of: (a) any expiration,
          cancellation, or nonrenewal of the Policy; or (b) any cancellation,
          termination, or expiration of the insured’s individual membership in
          the Cyberscout Limited, Sontiq Inc. and all subsidiaries membership
          program.
        </p>

        <p>
          The aggregate limit of insurance is the most we shall pay the insured
          for loss, excess of any applicable deductible, resulting from all
          stolen identity events and unauthorized electronic fund transfers
          combined first occurring during the policy period. The sublimits of
          insurance are part of, and not in addition to, the aggregate limit of
          insurance. In addition, ALL LEGAL DEFENSE FEES AND EXPENSES ARE PART
          OF, AND NOT IN ADDITION TO, THE AGGREGATE LIMIT OF INSURANCE FOR EACH
          INSURED.
        </p>

        <p>
          We shall be primary coverage over any other insurance coverage. In all
          events, we shall not pay more than our Limit of Insurance.
        </p>

        <p>
          Should the insured be enrolled in more than one membership program
          insured by us or any of our affiliates, subject to the applicable
          deductibles and limits of insurance of the insured under the
          applicable membership program, we will reimburse the insured under
          each membership program, but in no event shall the total amount
          reimbursed to the insured under all membership programs exceed the
          actual amount of loss
        </p>
      </div>
    </>
  );
};

export default IdentityTheftBenefits;
