import { useContext, useEffect } from 'react';
import {
  Button,
  CustomIcons,
  Link,
  Stack,
  Text,
} from '@ltvco/refresh-lib/theme';
import idMonitorLock from '../../../images/id-monitor-lock.svg';
import { useSession } from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

export const CheckEmail: React.FC = () => {
  const {
    session: { account },
  } = useSession();
  const { trackEvent, trackEventGA4 } = useContext(AppConfig);

  const email = account?.account.user_info.email;
  const isEnrolled =
    account?.account?.user_settings?.credit_hub_enrollment_completed;

  useEffect(() => {
    if (Boolean(isEnrolled)) {
      trackEvent(
        'return session escalation',
        'navigation trigger',
        'id monitor'
      );
    } else {
      trackEvent('new session escalation', 'trigger', 'id monitor enrollment');
      trackEventGA4({
        event_name: 'id_monitor_enrollment',
        step: 'new_session_escalation',
      });
    }
  }, [isEnrolled]);

  return (
    <>
      <img src={idMonitorLock} alt="id-monitor-lock" />
      <Text variant="h1" width={['100%', '75%']} marginY={[2, 2.5]}>
        Check {email} for a link to confirm your identity.
      </Text>
      <Stack
        width={['100%', '65%']}
        alignItems="center"
        textAlign="center"
        marginBottom={[2, 2.5]}
      >
        <Text variant="body1">
          To access ID Monitor, please confirm that you are the account holder
          by checking your email and following the link provided.
        </Text>
        <Text variant="body1" marginY={[2, 2.5]}>
          Please make sure to check your spam folder if you do not see the
          verification email in your inbox.{' '}
        </Text>
        <Text variant="body1">
          You can attempt to verify again in 20 minutes.
        </Text>
      </Stack>
      <Link href="https://gmail.com" target="_blank">
        <Button
          variant="outlined"
          sx={{
            maxWidth: 172,
            paddingY: 1,
            whiteSpace: 'nowrap',
            border: '1px solid black',
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <CustomIcons.GmailIcon />
            <Stack direction="row" spacing={0.8125} alignItems="center">
              <Text
                variant="h5"
                sx={{ margin: 0, textDecoration: 'none', color: 'black' }}
              >
                Open in Gmail
              </Text>
            </Stack>
          </Stack>
        </Button>
      </Link>
    </>
  );
};
