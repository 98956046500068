import React from 'react';
import { Box, Text, styled } from '@ltvco/refresh-lib/theme';

const StyledContentBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.25),
  maxHeight: '360px',
  lineHeight: '22px',
  overflowX: 'hidden',
  overflowY: 'scroll',
}));

export interface AstrologyNumerologyProps {
  data: string[] | undefined;
  children?: React.ReactNode;
  // `content` is the prop that will be passed back to the children component
  content?: string;
}

export const AstrologyNumerology = ({
  data,
  children,
}: AstrologyNumerologyProps) => {
  return (
    <>
      {children
        ? React.cloneElement(children as React.ReactElement, {
            content: data?.join('\n\n'),
          })
        : data?.map((content: string, index: number) => (
            <StyledContentBox key={index}>
              <Text sx={{ lineHeight: '22px' }} mb={1.25}>
                {content}
              </Text>
            </StyledContentBox>
          ))}
    </>
  );
};

export const withAstrologyNumerology = <P extends AstrologyNumerologyProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P & AstrologyNumerologyProps) => (
    <AstrologyNumerology data={props.data}>
      <WrappedComponent {...props} />
    </AstrologyNumerology>
  );
};
