import {
  AnyObject,
  notificationSettingsNamespace,
  identityManagementNamespace,
  notificationDisplayNamespace,
  headers,
} from './constants';
import { request } from '@ltvco/refresh-lib/utils';

export const fetchSettings = async (token: string) => {
  return await request(notificationSettingsNamespace, {
    method: 'GET',
    headers: headers(token),
  });
};

export const fetchIdentityManagement = async (token: string) => {
  return fetch(identityManagementNamespace, {
    method: 'GET',
    headers: headers(token),
  });
};

export const updateSettings = async ({ token, newSettings }: AnyObject) => {
  return await request(notificationSettingsNamespace, {
    method: 'PUT',
    body: JSON.stringify(newSettings),
    headers: headers(token),
  });
};

export const updateIdentityManagement = async ({ token }: AnyObject) => {
  return await request(identityManagementNamespace, {
    method: 'PUT',
    headers: headers(token),
  });
};

export const patchNotificationsDisplay = async (token: string) => {
  return await request(notificationDisplayNamespace, {
    method: 'PATCH',
    headers: headers(token),
  });
};
