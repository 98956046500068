import { Button, Stack } from '@ltvco/refresh-lib/theme';
import { SecurityFooter } from '../SecurityFooter';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const OneTimePasscodeNavigation: React.FC<{
  isOneTimePasscodeMCQ: boolean;
  isNextDisabled: boolean;
  handleSubmit: () => void;
}> = ({ isOneTimePasscodeMCQ, isNextDisabled, handleSubmit }) => {
  return (
    <>
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Button
          variant={'contained'}
          id={isOneTimePasscodeMCQ ? 'next' : 'submit'}
          key={isOneTimePasscodeMCQ ? 'next' : 'submit'}
          disabled={isNextDisabled}
          sx={{
            width: ['100%', '172px'],
          }}
          onClick={handleSubmit}
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            {isOneTimePasscodeMCQ ? 'Next' : 'Submit'}
            {isOneTimePasscodeMCQ && (
              <ArrowForwardIosIcon
                fontSize="inherit"
                sx={{ marginLeft: [0.5] }}
              />
            )}
          </Stack>
        </Button>

        <SecurityFooter />
      </Stack>
    </>
  );
};
