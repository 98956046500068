import { Card, styled } from '@ltvco/refresh-lib/theme';

const baseStyles: React.CSSProperties = {
  padding: '12px 16px',
  borderRadius: '10px',
  marginBottom: '10px',
  display: 'flex',
  flexWrap: 'wrap',
  margin: 3,
  marginRight: 8,
  maxWidth: '70%',
  width: 'fit-content',
  boxShadow: 'none',
  justifyContent: 'flex-start',
};

export const LeftBubble = styled(Card)({
  ...baseStyles,
  backgroundColor: '#F2F2F4',
});

export const RightBubble = styled(Card)({
  ...baseStyles,
  backgroundColor: '#4A3B8F',
  color: '#FFFFFF',
  justifyContent: 'flex-end',
});
