import { useContext, useEffect, useState } from 'react';
import {
  AuthenticationQuestions,
  SelectedAnswer,
  UseAuthentication,
} from './useAuthentication';
import useNotifications from './useNotifications/useNotifications';

import { UserProfile, UserProfileForm, UseUserProfile } from './useUserProfile';
import { OxfordError } from './Helpers';
import { sessionEscalationURL } from './constants';
import { useOneTimePasscodeContext } from './OneTimePasscodeContext';
import { UseCreditData, parseCreditData } from './useCreditData/index';
import {
  ScoreSimulatorType,
  useScoreSimulator,
} from './useScoreSimulator/useScoreSimulator';
import { XmlHandler } from './XmlHandler';
import {
  CreditNavigatorPostType,
  UseCreditNavigator,
} from './useCreditNavigator';
import {
  SettingData,
  SettingProps,
  updateSetting,
  useServiceToken,
  useSession,
  useSessionEscalation,
} from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useMutation, UseQueryResult, useQuery } from '@ltvco/refresh-lib/vendors';

export const useOxford = () => {
  const [sentOneTimePasscodeCount, setSentOneTimePasscodeCount] = useState(0);

  const [enabledUserProfileQuery, setEnabledUserProfileQuery] =
    useState<boolean>(false);

  const { logError } = useContext(AppConfig);
  const {
    session: { account },
    setSession,
  } = useSession();

  const userSettingQuery = useMutation<SettingData, Error, SettingProps>({
    mutationFn: updateSetting,

    onSuccess: () => {
      // NOTE: Because this won't be null at this point of the flow
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      account!.account.user_settings.credit_hub_enrollment_completed = true;
      setSession({ account });
    },
    onError: (error) => {
      logError('User Setting Update', error);
    },
  });

  const email = account?.account.user_info.email;

  const { checkSessionEscalation } = useSessionEscalation();
  const { refetch: fetchCheckSessionEscalation } = checkSessionEscalation;

  const { blackBoxId } = useOneTimePasscodeContext();

  let { data: tokenData, isError: isServiceTokenError } = useServiceToken(
    'oxford',
    sessionEscalationURL
  );

  // Only check session escalation if the user is cleared for the next step,
  //  to avoid letting enabled queries run the check by accident.
  useEffect(() => {
    if (isServiceTokenError && enabledUserProfileQuery) {
      fetchCheckSessionEscalation();
    }
  }, [isServiceTokenError, fetchCheckSessionEscalation]);

  const getUserProfile: UseQueryResult<UserProfile | undefined> = useQuery({
    queryKey: ['oxford', 'userProfile'],
    queryFn: async () => {
      const response = await UseUserProfile.getUserProfileRequest(
        tokenData!.token
      )
        .then((data) => {
          if (data?.user_profile?.authenticated) {
            getCreditData.refetch();
          } else {
            getAuthenticationQuestions.refetch();
          }

          return data.user_profile;
        })
        .catch((error: Error) =>
          _handleError(error, ['oxford', 'userProfile'])
        );

      if (response instanceof Error) {
        throw response;
      }

      return response;
    },

    retry: false,
    enabled: !!tokenData?.token && enabledUserProfileQuery,
  });

  const postUserProfile = useMutation({
    mutationFn: async (postAttributes: UserProfileForm) => {
      postAttributes.black_box_id = blackBoxId;
      const response = UseUserProfile.postUserProfileRequest(
        postAttributes,
        email,
        tokenData!.token
      )
        .then(() => {
          return getUserProfile.refetch();
        })
        .catch((error) => _handleError(error, ['oxford userProfile post']));

      if (response instanceof OxfordError) {
        throw response;
      }

      return response;
    },
  });

  const patchUserProfile = useMutation({
    mutationFn: async (postAttributes: UserProfileForm) => {
      postAttributes.black_box_id = blackBoxId;
      const response = await UseUserProfile.patchUserProfileRequest(
        postAttributes,
        email,
        tokenData!.token
      )
        .then(() => {
          return getUserProfile.refetch();
        })
        .catch((error) => _handleError(error, ['oxford userProfile patch']));

      if (response instanceof Error) {
        throw response;
      }

      return response;
    },
  });

  const getAuthenticationQuestions: UseQueryResult<
    AuthenticationQuestions | undefined
  > = useQuery(
    ['oxford', 'authentication', 'questions'],
    async ({ queryKey }) => {
      const response = await UseAuthentication.getAuthenticationQuestionRequest(
        { black_box_id: blackBoxId },
        tokenData!.token
      )
        .then((data: AuthenticationQuestions) => {
          if (data.questions) {
            data.questionList = UseAuthentication.questionsListMeta(
              data?.questions
            );
          }
          return data;
        })
        .catch((error) => _handleError(error, queryKey));

      if (response instanceof Error) throw response;

      return response;
    },
    {
      enabled: false,
      retry: false,
    }
  );

  const postAuthenticationAnswers = useMutation({
    mutationFn: async (postAttributes: SelectedAnswer[]) => {
      const response = await UseAuthentication.postAuthenticationAnswersRequest(
        postAttributes,
        tokenData!.token
      )
        .then(() => {
          getAuthenticationQuestions.remove();
          getAuthenticationCheckAnswers.refetch();
        })
        .catch((error) =>
          _handleError(error, ['oxford authentication answers post'])
        );

      if (response instanceof Error) {
        throw response;
      }

      return response;
    },
  });

  const getAuthenticationCheckAnswers = useQuery({
    queryKey: ['oxford', 'authentication', 'checkAnswers'],
    queryFn: async ({ queryKey }) => {
      const response =
        await UseAuthentication.getAuthenticationCheckAnswersRequest(
          tokenData!.token
        )
          .then((data: AuthenticationQuestions) => {
            setSentOneTimePasscodeCount(sentOneTimePasscodeCount + 1);

            if (data.questions) {
              data.questionList = UseAuthentication.questionsListMeta(
                data?.questions
              );
            } else if (data?.authenticated) {
              getCreditData.refetch();
            }

            return data;
          })
          .catch((error) => _handleError(error, queryKey));

      if (response instanceof Error) throw response;

      return response;
    },

    enabled: false,
    retry: false,
  });

  const getCreditData = useQuery({
    queryKey: ['oxford', 'creditData'],
    queryFn: async ({ queryKey }) => {
      const response = await UseCreditData.getCreditDataRequest(
        tokenData!.token
      )
        .then((data) => {
          const vantageScore = data?.vantage_score;

          if (data?.errors || ['1', '4'].includes(vantageScore)) {
            return new Error('Credit data error');
          }

          if (!account?.account.user_settings.credit_hub_enrollment_completed) {
            userSettingQuery.mutate({
              setting: 'credit_hub_enrollment_completed', // enrollment is complete
              state: true,
            });
          }

          return parseCreditData(data);
        })
        .catch((error) => _handleError(error, queryKey));

      if (response instanceof Error) throw response;

      return response;
    },

    enabled: false,
    retry: false,
  });

  const postScoreSimulator = useMutation(
    async (postAttributes: ScoreSimulatorType) => {
      const response = await useScoreSimulator
        .postScoreSimulatorRequest(postAttributes, tokenData!.token)
        .then(() => getScoreSimulator.refetch())
        .catch((error) =>
          _handleError(error, ['oxford authentication answers post'])
        );

      if (response instanceof Error) {
        throw response;
      }

      return response;
    }
  );

  const getScoreSimulator = useQuery(
    ['oxford', 'scoreSimulator'],
    async ({ queryKey }) => {
      const response = await useScoreSimulator
        .getScoreSimulatorRequest(tokenData!.token)
        .then((data) => {
          if (data?.errors) {
            return new Error('Score Simulator error');
          }

          const scoreSimulatorXml = XmlHandler().parseToXml(
            data.score_simulator
          );
          const score = scoreSimulatorXml?.querySelector('score')?.textContent;

          if (score) {
            return { score };
          }

          return new Error('Score simulator score missing');
        })
        .catch((error) => _handleError(error, queryKey));

      if (response instanceof Error) throw response;

      return response;
    },
    {
      enabled: false,
    }
  );

  const postCreditNavigator = useMutation(
    async (postAttributes: CreditNavigatorPostType) => {
      const response = await UseCreditNavigator.postCreditNavigator(
        postAttributes,
        tokenData!.token
      )
        .then(() => getCreditNavigator.refetch())
        .catch((error) => _handleError(error, ['oxford creditNavigator post']));

      if (response instanceof Error) {
        throw response;
      }

      return response;
    }
  );

  const getCreditNavigator = useQuery(
    ['oxford', 'creditNavigator'],
    async ({ queryKey }) => {
      const response = await UseCreditNavigator.getCreditNavigator(
        tokenData!.token
      )
        .then((data) => {
          if (data?.errors) {
            return new Error('Credit Navigator error');
          }

          const creditNavigatorXml = UseCreditNavigator.parseResponse(
            data.credit_navigator
          );

          return creditNavigatorXml;
        })
        .catch((error) => _handleError(error, queryKey));

      if (response instanceof Error) throw response;

      return response;
    },
    {
      enabled: false,
    }
  );

  // If user is not authorized, then recheck the session escalation, otherwise log the current error.
  const _handleError = (error: Error, queryKey: string[]) => {
    if (error instanceof OxfordError) {
      if (error.response.status === 401) {
        checkSessionEscalation.refetch();
      }
    } else {
      logError(`Error with ${queryKey.join(' ')} network call`, error as Error);
    }

    return error;
  };

  const isLoading =
    getUserProfile.isFetching ||
    getAuthenticationQuestions.isFetching ||
    getAuthenticationCheckAnswers.isFetching ||
    getCreditData.isFetching;
  const isOxfordError =
    getAuthenticationQuestions.isError ||
    getAuthenticationCheckAnswers.isError ||
    getCreditData.isError;

  return {
    getUserProfile,
    setEnabledUserProfileQuery,
    enabledUserProfileQuery,
    patchUserProfile,
    postUserProfile,
    getAuthenticationQuestions,
    postAuthenticationAnswers,
    getAuthenticationCheckAnswers,
    getCreditData,
    postScoreSimulator,
    getScoreSimulator,
    isLoading,
    isOxfordError,
    useNotifications,
    sentOneTimePasscodeCount,
    setSentOneTimePasscodeCount,
    postCreditNavigator,
    getCreditNavigator,
  };
};
