import { Stack, Box, Skeleton, Text, Divider } from '@ltvco/refresh-lib/theme';
import { Chat } from 'api';
import {
  languageID,
  levelID,
  topicID,
  translations,
} from 'components/Language/constants';
import { flagEmoji } from 'components/Language/flagEmoji';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { TopicIcon } from 'components/Language/TopicIcon/TopicIcon';
import { StyledLink, StyledRecentChatCard } from './components';

export function RecentChatCard({
  chat,
  isLoading,
  isSelected,
}: {
  chat: Chat;
  isLoading?: boolean;
  isSelected?: boolean;
}) {
  const { language } = useLanguageContext();
  const levelId = levelID(chat.level.toLowerCase());
  const languageId = languageID(chat.learning_language.toLowerCase());
  const topicId = topicID(chat.topic.toLowerCase());

  if (isLoading) {
    return (
      <StyledRecentChatCard justifyContent={'center'}>
        <Stack alignItems="center" justifyContent={'center'}>
          <Skeleton width={48} height={48} />
          <Skeleton width={48} height={24} />
          <Skeleton width={96} height={24} />
        </Stack>
      </StyledRecentChatCard>
    );
  }

  return (
    <StyledLink to={`/dashboard/language/chat/${chat.chat_id}`}>
      <Box mt={2} mb={isSelected ? 2 : 0}>
        <Divider />
      </Box>
      <StyledRecentChatCard className={isSelected ? 'selected' : ''}>
        <Stack alignItems="center">
          <TopicIcon
            topic={topicID(chat.topic.toLowerCase())}
            dashboard={false}
          />
          <Text variant="h5">
            {
              translations.topics[language].find(
                (topic) => topic.id === topicId
              )?.name
            }
          </Text>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Text color="primary.light" variant="body2">
              {
                translations.levels[language].find(
                  (level) => level.id === levelId
                )?.name
              }{' '}
              {flagEmoji(languageId)}
            </Text>
          </Box>
        </Stack>
      </StyledRecentChatCard>
    </StyledLink>
  );
}
