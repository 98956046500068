import {
  Button,
  Divider,
  Grid,
  Modal,
  Stack,
  Text,
  styled,
} from '@ltvco/refresh-lib/theme';
import { useState, useContext } from 'react';
import IdMonitorContactBureauIcon from 'images/id-monitor-contact-bureau-icon.svg';
import { useNavigate } from 'react-router-dom';
import { HeadsetMic, Smartphone, Email, ArrowCircleRight } from '@mui/icons-material';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

const ContactNumber = '(833) 570-3005';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const StyledGridItem = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  justifyItems: 'center',
  alignItems: 'center',
  padding: theme.spacing(2.5),
  display: 'grid',
  textAlign: 'center',
  mb: theme.spacing(2, 0),
}));

const StyledHeadsetIcon = styled(HeadsetMic)(({ theme }) => ({
  color: theme.palette.listingPins?.contrastText,
  height: '36px',
  width: '30px',
  marginBottom: '10px',
}));

const StyledSmartPhone = styled(Smartphone)(({ theme }) => ({
  color: theme.palette.success?.dark,
}));

const StyledSmartEmail = styled(Email)(({ theme }) => ({
  color: theme.palette.success?.dark,
}));

export const ContactFooter = () => {
  const [renderSummaryOfBenefitsModal, setRenderSummaryOfBenefitsModal] =
    useState(false);
  const { trackEvent } = useContext(AppConfig);

  const navigate = useNavigate();

  return (
    <StyledGridContainer container>
      <StyledGridItem item xs={12} sm={5.7}>
        <img
          src={IdMonitorContactBureauIcon}
          alt="Contact Bureau Icon"
          width="43px"
          height="36px"
        />
        <Text variant="h3" whiteSpace={'pre-wrap'} mt={2} mb={1}>
          Visit Credit Bureau{'\n'}Contact Page
        </Text>
        <Text variant="body1" mb={2}>
          Visit for all questions or issues related to your personal credit
          record
        </Text>
        <Button
          variant="contained"
          onClick={() => {
            trackEvent(
              'credit bureau contact',
              'navigation click',
              'id monitor'
            );
            navigate('/dashboard/id-monitor/credit-bureau-contacts');
          }}
        >
          Visit Contact Page
        </Button>
      </StyledGridItem>
      <Grid item sm={0.6} />
      <StyledGridItem item xs={12} sm={5.7}>
        <StyledHeadsetIcon />
        <Text variant="h3" marginTop={1.5} marginBottom={0}>
          Contact our Certified
        </Text>
        <Text variant="h3" marginTop={0} marginBottom={2}>
          Protection Experts:
        </Text>
        <Stack rowGap={1}>
          <Stack direction="row" alignContent={'center'}>
            <StyledSmartPhone fontSize="small" />
            <Text variant="body1" marginLeft={1.5}>
              {ContactNumber}
            </Text>
          </Stack>
          <Stack direction="row" alignContent={'center'}>
            <StyledSmartEmail fontSize="small" />
            <Text variant="body1" marginLeft={1.5}>
              support@beenverified.com
            </Text>
          </Stack>
        </Stack>
        <Divider sx={{ marginY: 1, width: '100%' }} />
        <Button
          endIcon={<ArrowCircleRight color="inherit" />}
          size="large"
          sx={{
            textAlign: 'left',
            textDecoration: 'underline',
            lineHeight: '19.2px',
            padding: 0,
            width: 'fit-content',
          }}
          onClick={() => setRenderSummaryOfBenefitsModal(true)}
        >
          About Identity Theft Restoration
        </Button>
      </StyledGridItem>
      <Modal
        hasCloseIcon
        open={renderSummaryOfBenefitsModal}
        onClose={() => setRenderSummaryOfBenefitsModal(false)}
      >
        <Text
          variant="body1"
          id="identity-theft-restoration-text"
          paddingRight={1.25}
        >
          Complete identity theft restoration services from Certified Protection
          Experts available 24 hours, 7 days a week. Specialists don't only
          assist with identity restoration; they can save you literally hundreds
          of hours by completing all the paperwork, making calls, and doing all
          the heavy lifting to make sure your identity is restored.
        </Text>
      </Modal>
    </StyledGridContainer>
  );
};
