import { Stack, Text,  Tooltip, IconButton } from '@ltvco/refresh-lib/theme';
import { StyledCard, StyledImg } from '../components';
import { Dispatch, SetStateAction } from 'react';
import { translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { Achievement } from 'components/Language/AchievementContext';
import { InfoOutlined } from '@mui/icons-material';

interface AchievementCardProps {
  badge: Achievement;
  setAchievementSelected: Dispatch<SetStateAction<Achievement>>;
  handleToggleStakedModal: () => void;
}

export const AchievementCard = ({
  badge,
  setAchievementSelected,
  handleToggleStakedModal,
}: AchievementCardProps) => {
  const handleSelectAchievement = () => {
    setAchievementSelected(badge);
    handleToggleStakedModal();
  };

  const { language } = useLanguageContext();

  return (
    <StyledCard disabled={badge.disabled!} variant="outlined">
      <Tooltip enterTouchDelay={300} title={badge.tooltip}>
        <IconButton
          onClick={handleSelectAchievement}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <InfoOutlined
            fontSize="small"
            sx={{
              color: 'barChart.dark',
            }}
          />
        </IconButton>
      </Tooltip>
      <Stack
        alignItems="center"
        direction="column"
        px={{ xs: 0.25, md: 2 }}
        py={1}
        height="146px"
        justifyContent="space-evenly"
      >
        <StyledImg
          src={badge.image}
          alt={badge.name}
          height="60px"
          width="60px"
        />
        <Stack alignItems="center">
          <Text
            variant="h3"
            fontSize="0.875rem"
            color="primary"
            textAlign="center"
            mb={0}
          >
            {badge.name}
          </Text>
          {badge.date && (
            <Text
              textAlign="center"
              fontSize={{ xs: '0.7rem', sm: '0.75rem' }}
              mt={0.25}
              sx={{ color: 'secondary.contrastText' }}
            >
              {translations.general.earned[language]} {badge.date}
            </Text>
          )}
        </Stack>
      </Stack>
    </StyledCard>
  );
};
