import { Box, Stepper, Step} from '@ltvco/refresh-lib/theme';
import { useState } from 'react';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepCircle } from './components';
import { Check } from '@mui/icons-material';

interface OnBoardingProps {
  setOnBoardingOpen: (value: boolean) => void;
}

export interface SelectedOptions {
  learning_language_id: number;
  native_language_id?: number;
  level: string;
  topic_id: number;
}

export const OnBoarding = ({ setOnBoardingOpen }: OnBoardingProps) => {
  const [selectedOptions, setSelectedOptions] = useState({
    native_language_id: 0,
    learning_language_id: 0,
    level: '',
    topic_id: 0,
  });
  const [stepNumber, setStepNumber] = useState(0);

  const steps = [
    {
      className:
        stepNumber > 0 && stepNumber <= 2
          ? 'completed'
          : stepNumber <= 2
          ? 'current'
          : '',
      content:
        stepNumber > 0 && stepNumber <= 2 ? (
          <Check fontSize="small" />
        ) : (
          1
        ),
    },
    {
      className:
        stepNumber > 1 && stepNumber <= 2
          ? 'completed'
          : stepNumber > 0 && stepNumber <= 2
          ? 'current'
          : '',
      content:
        stepNumber > 1 && stepNumber <= 2 ? (
          <Check fontSize="small" />
        ) : (
          2
        ),
    },
    {
      className: stepNumber === 2 ? 'current' : '',
      content: 3,
    },
  ].map((step, index) => (
    <StepCircle key={index} className={step.className}>
      {step.content}
    </StepCircle>
  ));
  const nextStep = () => {
    if (stepNumber < steps.length - 1) {
      setStepNumber(stepNumber + 1);
    } else {
      setOnBoardingOpen(false);
    }
  };

  const backStep = () => {
    if (stepNumber > 0) {
      setStepNumber(stepNumber - 1);
    }
  };

  const stepsContent = [
    <StepOne
      nextStep={nextStep}
      setSelectedOptions={setSelectedOptions}
      selectedOptions={selectedOptions}
    />,
    <StepTwo
      nextStep={nextStep}
      backStep={backStep}
      setSelectedOptions={setSelectedOptions}
      selectedOptions={selectedOptions}
      isOnboarding
    />,
    <StepThree
      nextStep={nextStep}
      backStep={backStep}
      setSelectedOptions={setSelectedOptions}
      selectedOptions={selectedOptions}
      isOnboarding
    />,
  ];

  return (
    <Box>
      <Stepper>
        {steps.map((label, index) => (
          <Step key={index} active={index === stepNumber}>
            {label}
          </Step>
        ))}
      </Stepper>
      <Box pt={4}>{stepsContent[stepNumber]}</Box>
    </Box>
  );
};
