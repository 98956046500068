import {
  styled,
  Box,
  Stack,
  Text,
} from '@ltvco/refresh-lib/theme';
import { BetaPill } from '../BetaPill';
import { LanguageSettings } from './LanguageSettings/LanguageSettings';
import { Feedback } from '../Feedback/Feedback';
import { translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { Public } from '@mui/icons-material';

const TextWithIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 0,

  svg: {
    marginRight: theme.spacing(1),
  },
}));

export const LanguageHeader = () => {
  const { isMedium } = useScreenSize();
  const { language } = useLanguageContext();

  return (
    <Stack
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={{ xs: 'baseline', md: 'center' }}
      pb={2}
    >
      <Stack
        alignItems={{ xs: 'flex-start', md: 'center' }}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <TextWithIcon>
          <Public fontSize="large" />
          <Text variant="h1" mb={0}>
            {translations.dashboard.header.title[language]}
          </Text>
        </TextWithIcon>
        <BetaPill customStyle={{ mt: 0.8, ml: { xs: 6, md: 2 } }} />
      </Stack>
      <Stack direction="row" justifyContent="center" alignItems="center">
        {!isMedium && <Feedback />}
        <LanguageSettings />
      </Stack>
    </Stack>
  );
};
