import {
  AppConfig,
  AppConstants,
  AppRequests,
  createAppConfig,
} from '@ltvco/refresh-lib/ctx';
import { styled } from '@ltvco/refresh-lib/theme';
import { appRequests } from 'appRequests';
import {
  constants,
  sideMenuItemsForNewDesign,
  internationalSideMenuItems,
} from 'appConstants';
import {
  useLocation,
  useNavigate,
  useSearchParams,
  Link,
} from 'react-router-dom';
import { AlertProvider, type SideMenuItem } from '@ltvco/refresh-lib/v1';
import { useMemo } from 'react';
import { ResponseError } from '@ltvco/refresh-lib/utils';

const MenuItem = styled(Link)<{ ga4EventType?: string }>(
  ({ theme, ga4EventType }) => ({
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize * 1.125,
    display: 'flex',
    alignItems: 'center',
    svg: {
      marginRight: theme.spacing(1),
    },
    width: '100%',
  })
);

export function LibContextWrapper({
  children,
  trackEventGA4,
  trackEvent,
  logError,
  handleLogoutClick,
  isInInternationalLanguageTest,
  isInInternationalVinTest,
  isInOwnerSearchVariation,
  isInSingleReportVariation,
  isInAstrologyStandaloneVariation,
  isInRenameSidebarItemsVariation,
}: {
  children: React.ReactNode | React.ReactNode[];
  trackEventGA4: (eventToTrack: object, eventName?: string) => void;
  trackEvent: (category: string, action: string, label?: string) => void;
  logError: (
    context: string,
    error: Error | ResponseError,
    url: string | undefined
  ) => void;
  handleLogoutClick: () => void;
  isInInternationalLanguageTest: boolean;
  isInInternationalVinTest: boolean;
  isInOwnerSearchVariation: boolean;
  isInSingleReportVariation: boolean;
  isInAstrologyStandaloneVariation: boolean;
  isInRenameSidebarItemsVariation: boolean;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const defaultConfig = createAppConfig();

  function flattenMenuItems(menuItems: SideMenuItem[]): SideMenuItem[] {
    return menuItems.flatMap(({ childItems, ...item }) => [
      item,
      ...(childItems?.length ? flattenMenuItems(childItems) : []),
    ]);
  }

  const flatMenu = flattenMenuItems(
    constants.config.sideMenuItems.filter((item) => {
      if (item.name === 'importList') {
        return false;
      }
      if (isInSingleReportVariation && item.isLocked) {
        return false;
      }
      if (!isInOwnerSearchVariation && item.name === 'owner_search') {
        return false;
      }
      if (!isInAstrologyStandaloneVariation && item.name === 'astrology') {
        return false;
      }
      return true;
    })
  );

  const menuItems = flatMenu.map((item) => {
    return (
      <MenuItem
        to={item.route}
        data-cy={`menu_${item['data-cy']}`}
        ga4EventType={item.ga4EventType}
      >
        {item.icon} {item.text}
      </MenuItem>
    );
  });

  const languageSideMenuItems = ['personal-growth', 'my-profile'];
  const memoizedItems = useMemo(() => {
    return sideMenuItemsForNewDesign(
      isInRenameSidebarItemsVariation,
      isInAstrologyStandaloneVariation
    );
  }, [isInRenameSidebarItemsVariation, isInAstrologyStandaloneVariation]);

  let filterSideMenuItems;

  if (isInInternationalVinTest) {
    filterSideMenuItems = internationalSideMenuItems;
  } else if (isInInternationalLanguageTest) {
    filterSideMenuItems = internationalSideMenuItems.filter((item) =>
      languageSideMenuItems.includes(item.name)
    );
  } else {
    filterSideMenuItems = memoizedItems;
  }

  function trackListhub(eventName: string, listingKey: string) {
    const listhubFn = (window as any).lh;
    if (listhubFn && typeof listhubFn !== 'undefined') {
      if (eventName && listingKey) {
        listhubFn('submit', eventName || null, { lkey: listingKey || null });
      }
    }
  }

  const appConfig = {
    ...defaultConfig,
    routingUtils: {
      ...defaultConfig.routingUtils,
      navigate: navigate,
      location: location,
      useSearchParams,
    },
    logError: logError,
    trackEvent: trackEvent,
    trackEventGA4: trackEventGA4,
    trackListhub: trackListhub,
  };

  const appConstants = {
    ...constants,
    config: {
      ...constants.config,
      menuItems: [
        ...menuItems,
        <MenuItem
          to="#"
          onClick={() => handleLogoutClick()}
          data-cy="menu_log_out_btn"
        >
          Log Out
        </MenuItem>,
      ],
      sideMenuItems: [
        ...filterSideMenuItems,
        {
          route: '',
          icon: '',
          text: 'Logout',
          name: 'logout',
          state: '',
          'data-cy': 'menu_log_out_btn',
          action: handleLogoutClick,
        },
      ],
    },
  };

  return (
    <AlertProvider maxSnack={3} preventDuplicate autoHideDuration={5000}>
      <AppRequests.Provider value={appRequests}>
        <AppConfig.Provider value={appConfig as any}>
          <AppConstants.Provider value={appConstants}>
            {children}
          </AppConstants.Provider>
        </AppConfig.Provider>
      </AppRequests.Provider>
    </AlertProvider>
  );
}
