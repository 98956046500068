import { useState } from 'react';
import { Stack, Modal, Text, Divider, Link } from '@ltvco/refresh-lib/theme';
import IdentityTheftBenefits from './IdentityTheftBenefits';
import useNotificationSettings from '../Oxford/useNotificationSettings';
import IdTheftInsuranceDisclaimer from './IdTheftInsuranceDisclaimer';
import DataContainer from './DataContainer';
import { LoadingDots } from '@ltvco/refresh-lib/v1';

const modalStyles = {
  '.underline': {
    textDecoration: 'underline',
  },
  '.list': {
    paddingLeft: [3],
    marginTop: 0,
  },
  '.box': {
    padding: 2,
    border: '1px solid #E2E2E2',
  },
};

export const ProtectionTools: React.FC<{}> = () => {
  const [renderBenefitsModal, setRenderBenefitsModal] = useState(false);
  const [
    renderAboutIDTheftInsuranceModal,
    setRenderAboutIDTheftInsuranceModal,
  ] = useState(false);
  const [renderDisclosureModal, setRenderDisclosureModal] = useState(false);
  const { data, isLoading } = useNotificationSettings();

  const navigateToBenefitsModal = () => {
    setRenderAboutIDTheftInsuranceModal(false);
    setRenderBenefitsModal(true);
    setRenderDisclosureModal(false);
  };

  const navigateToDisclosureModal = () => {
    setRenderAboutIDTheftInsuranceModal(false);
    setRenderBenefitsModal(false);
    setRenderDisclosureModal(true);
  };

  if (isLoading) {
    return (
      <Stack
        textAlign="left"
        sx={{
          position: 'relative',
          width: '100%',
          gap: 2,
        }}
      >
        <Text variant="h3" fontSize={24}>
          Protection Tools
        </Text>
        <Text
          variant="body1"
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: '70%',
            marginBottom: 2,
          }}
        >
          Select your identity theft and credit protection tools to ensure your
          identity and credit are being monitored and safeguarded.
        </Text>
        <LoadingDots count={7} />
      </Stack>
    );
  }

  return (
    <>
      {data?.notificationSettings && data?.idManagement && (
        <DataContainer
          setRenderBenefitsModal={setRenderBenefitsModal}
          setRenderAboutIDTheftInsuranceModal={
            setRenderAboutIDTheftInsuranceModal
          }
          setRenderDisclosureModal={setRenderDisclosureModal}
          notificationSettings={data?.notificationSettings}
          idManagement={data?.idManagement}
        />
      )}
      {renderAboutIDTheftInsuranceModal && (
        <Modal
          hasCloseIcon
          sx={modalStyles}
          open={renderAboutIDTheftInsuranceModal}
          onClose={() => setRenderAboutIDTheftInsuranceModal(false)}
        >
          <Text variant="h3">About Identity Theft Insurance</Text>
          <Divider sx={{ marginY: 2 }} />
          <Text variant="body1">
            Identity theft insurance guarantees that you recover certain
            out-of-pocket expenses and lost wages if your identity is stolen.{' '}
          </Text>
          <Stack mt={1.5} gap={1.5}>
            <Link
              onClick={() => navigateToDisclosureModal()}
              sx={{ cursor: 'pointer' }}
            >
              Read Disclosure.
            </Link>
            <Link
              onClick={() => navigateToBenefitsModal()}
              sx={{ cursor: 'pointer' }}
            >
              Read summary of benefits.
            </Link>
          </Stack>
        </Modal>
      )}
      {renderBenefitsModal && (
        <Modal
          hasCloseIcon
          sx={modalStyles}
          open={renderBenefitsModal}
          onClose={() => setRenderBenefitsModal(false)}
        >
          <IdentityTheftBenefits />
        </Modal>
      )}
      {renderDisclosureModal && (
        <Modal
          hasCloseIcon
          sx={modalStyles}
          open={renderDisclosureModal}
          onClose={() => setRenderDisclosureModal(false)}
        >
          <IdTheftInsuranceDisclaimer
            navigateToBenefitsModal={navigateToBenefitsModal}
          />
        </Modal>
      )}
    </>
  );
};
