import { Card, Stack, Button, styled } from '@ltvco/refresh-lib/theme';

export const StyledSettingsCard = styled(Card)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  border: '1px solid var(--divider, #E2E2E2)',
  flexDirection: 'column',
  gap: 2.5,
  backgroundColor: theme.palette.background.default,
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    width: '31%',
    height: '293px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '31.5%',
    height: '233px',
  },
  '&:hover': {
    boxShadow: `0px 3px 3px -2px rgba(0, 0, 0, 0.20), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)`,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  paddingX: 1,
  fontSize: '14px',
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2.5),
  },
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  gap: theme.spacing(2.5),
  flexWrap: 'wrap',
}));
