import {
  EmailSection as EmailSectionV2,
  PhoneSection as PhoneSectionV2,
  AddressHistorySection as AddressHistorySectionV2,
  EducationSection as EducationSectionV2,
  JobsSection as JobsSectionV2,
  SocialSection as SocialSectionV2,
  ReportActionsWithDateUpdated,
  ReportRating as ReportRatingV2,
  NotesSection as NotesSectionV2,
  RelatedReportsSection as RelatedReportsSectionV2,
  AssetsSection as AssetsSectionV2,
  PotentialOwners as PotentialOwnersV2,
  ReportNavigationMenu,
  ReportNavigationMobileMenu,
  ReportNavigationMenuProps,
  NavLinkDataV2,
} from '@ltvco/refresh-lib/v2';
import type { ReportRouteProps } from './types';
import {
  getEmailNavLinkDataV2,
  getEmailNavLinkData,
} from 'navLinkData/emailNavLinkData';
import { useState, useContext, useEffect } from 'react';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import imgIdCard from '../../images/img_idCard.svg';
import {
  ContactReportSectionBanner,
  DebugMenu,
  EmailFraudCheckSection,
  EmailOverview,
  Monitoring,
  EmailReport as EmailReportType,
  ReportChangesOverview,
  ReportClaiming,
  ReportFactory,
  ReportLoading,
  ReportMonitor,
  ReportNullState,
  ReportOptions,
  useRemouladeReportSnapshot,
  useReport,
  useReportMonitors,
  useReportRedirect,
  useSession,
} from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
  DateUtil,
} from '@ltvco/refresh-lib/utils';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';

interface EmailReportProps extends ReportRouteProps {}

const reportType = 'email';

export function EmailReport({ permalink }: EmailReportProps) {
  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  const { currentMonitors, reportIsMonitored } = useReportMonitors(permalink);

  const coreResult = useReport(permalink, reportIsMonitored);
  const remouladeResult = useRemouladeReportSnapshot(permalink);
  const flattenedRemouladeResult = remouladeResult
    ? { ...remouladeResult, data: remouladeResult.data?.data }
    : remouladeResult;

  const {
    session: { account },
  } = useSession();
  const { redirectToSearchContactUrl } = useReportRedirect();
  const { trackEvent } = useContext(AppConfig);
  const isInReportChange = useFeatureIsOn('OAR-1183');
  const currentUserInfo = account?.account?.user_info;
  const shouldUseRemouladeSnapshot =
    !remouladeResult.isError && (reportIsMonitored || isInReportChange);
  const queryResult = (
    shouldUseRemouladeSnapshot ? flattenedRemouladeResult : coreResult
  ) as typeof coreResult;

  const zeroed = isZeroed(queryResult);
  const { hash, search } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: true,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };
  const [activeMonitor, setActiveMonitor] = useState(false);
  const [activeClaiming, setActiveClaiming] = useState(false);

  useEffect(() => {
    setActiveClaiming(
      currentMonitors.some((monitor: ReportMonitor) => {
        return monitor.permalink === permalink && monitor.is_claimed;
      })
    );
  }, [currentMonitors, permalink]);

  const [reportUpdateDate, setUpdateDate] = useState<string | undefined>();
  const [report, setReport] = useState<EmailReportType | null>(null);
  const date = new DateUtil();

  useEffect(() => {
    if (queryResult.isSuccess && queryResult.data) {
      // The format of queryResult from REMO has an extra data key.
      if (queryResult?.data?.entities)
        setReport(ReportFactory.create(queryResult?.data, 'email'));
      else setReport(ReportFactory.create(queryResult?.data.data, 'email'));
    }
  }, [queryResult.data, queryResult.isSuccess, reportOptions]);

  useEffect(() => {
    const remoUpdateDate = currentMonitors?.find(
      (monitor) => monitor.permalink === permalink
    )?.updated_at;
    const coreUpdateDate = report?.data?.meta?.updated_at;

    if (reportIsMonitored && remoUpdateDate)
      setUpdateDate(date.parseDateFromString(remoUpdateDate, 'yyyy-MM-dd'));
    else if (coreUpdateDate)
      setUpdateDate(
        date.parseDateFromString(
          coreUpdateDate,
          'yyyy-MM-dd',
          'yyyy-MM-dd HH:mm:ss ZZZ'
        )
      );
  }, [
    report?.data?.meta?.updated_at,
    currentMonitors,
    permalink,
    reportIsMonitored,
  ]);

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={10} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  if (!report) return <ReportLoading menuItems={10} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const showReportClaiming =
    currentUserInfo?.email?.toLowerCase() ===
    report?.emailAddress?.toLowerCase();

  const MonitoringComponent = showReportClaiming ? (
    <ReportClaiming
      permalink={permalink}
      reportType={reportType}
      reportIsMonitoredAndClaimed={activeClaiming}
    />
  ) : (
    <Monitoring permalink={permalink} reportType={reportType} />
  );

  const owner = report.getOwner(reportOptions);
  const {
    ownerName,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    usernames,
    addresses,
    ownedAssets,
    images,
    userIds,
    relatives,
  } = owner;

  const { supportingEmail } = report;

  const { fraud_score, deliverability, recent_abuse, is_leaked } =
    supportingEmail || {};

  const emailNavLinkDataV2 = getEmailNavLinkDataV2(
    report?.data.people?.length || 0,
    owner
  );

  const emailNavLinkData = getEmailNavLinkData(
    report?.data.people?.length || 0,
    owner
  );

  const queryParams = new URLSearchParams(search);
  const fallbackEmail = queryParams.get('email') || '';
  const lowerCaseEmailAddress =
    report?.emailAddress?.toLowerCase() ?? fallbackEmail;
  document.title = `${lowerCaseEmailAddress} - BeenVerified`;

  const handleOnBeginSearch = () => {
    trackEvent('email report promo', 'navigation click', 'contact search');
    redirectToSearchContactUrl({});
  };

  return (
    <Box>
      <Stack direction="row">
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
        >
          <ReportNavigationMenu
            reportType="Email Report"
            headerTitle={lowerCaseEmailAddress}
            menuItems={
              emailNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
            }
          />
        </Box>

        <Box marginTop={0}>
          {activeMonitor && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={emailNavLinkData}
            />
          )}

          <>
            <Box
              sx={{
                height: 30,
                backgroundColor: '#f8f8f8',
                position: 'sticky',
                marginBottom: '-10px',
                marginX: -1,
                top: 52,
                zIndex: 10,
                display: {
                  xs: 'none',
                  sm: 'block',
                  md: 'block',
                  lg: 'block',
                },
              }}
            />
            <ReportActionsWithDateUpdated
              reportType={reportType}
              reportTitle="Email"
              dateUpdated={reportUpdateDate}
              permalink={isInReportChange ? permalink : undefined}
              navLinkData={
                isInReportChange
                  ? (emailNavLinkDataV2 as unknown as NavLinkDataV2[])
                  : []
              }
              reportUpgraded={false}
            />
          </>

          <EmailOverview
            reportType={reportType}
            email={lowerCaseEmailAddress}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) => {
              handleSetReportOptions({
                showHighConfidenceDataOnly: value,
              });
            }}
            personName={ownerName || lowerCaseEmailAddress}
            emailOverviewItemsData={{
              ownerName,
              emails,
              jobs,
              educations,
              phones,
              profiles,
              usernames,
              userIds,
              peopleCount: report.data.people?.length,
            }}
            Monitoring={MonitoringComponent}
          />
          <EmailFraudCheckSection
            fraudScore={fraud_score}
            deliverability={deliverability}
            abusiveBehavior={recent_abuse}
            leaked={is_leaked}
            showEmailFraudScan={supportingEmail != undefined}
            emailAddress={lowerCaseEmailAddress}
            showReportCTA
          />
          <PotentialOwnersV2
            potentialOwners={report.data.people || []}
            potentialOwnerIndex={reportOptions.potentialOwnerIndex}
            setPotentialOwnerIndex={(index: number) => {
              handleSetReportOptions({ potentialOwnerIndex: index });
            }}
            personName={ownerName || lowerCaseEmailAddress}
            permalink={permalink}
          />

          <PhoneSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            phoneList={phones}
            permalink={permalink}
          />
          <EmailSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            emailList={emails}
            permalink={permalink}
          />
          <AddressHistorySectionV2
            addresses={addresses}
            personName={ownerName || lowerCaseEmailAddress}
            permalink={permalink}
          />
          <SocialSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />
          <JobsSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            jobs={jobs}
            permalink={permalink}
          />
          <ContactReportSectionBanner
            image={imgIdCard}
            onBeginSearchClick={handleOnBeginSearch}
          />
          <EducationSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            educations={educations}
            permalink={permalink}
          />
          <AssetsSectionV2
            personName={ownerName || lowerCaseEmailAddress}
            ownedAssets={ownedAssets}
            permalink={permalink}
          />
          <NotesSectionV2 permalink={permalink} />
          <RelatedReportsSectionV2
            reportType="email"
            personName={ownerName || lowerCaseEmailAddress}
            potentialOwners={report?.data?.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
          />
          <ReportRatingV2
            report_type={reportType}
            rating={report.data.meta?.rating || null}
          />
        </Box>
      </Stack>
      <ReportNavigationMobileMenu
        reportType="Email Report"
        headerTitle={lowerCaseEmailAddress}
        menuItems={emailNavLinkDataV2 as ReportNavigationMenuProps['menuItems']}
      />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </Box>
  );
}
