import { useContext } from 'react';
import { parseNotification } from './utils';
import { patchNotificationsDisplay } from '../utils';
import { OxfordHelpers } from '../Helpers';
import { notificationSummaryNamespace } from '../constants';
import { useServiceToken } from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQuery } from '@ltvco/refresh-lib/vendors';

const useNotifications = () => {
  const { data: tokenData, isLoading: isTokenLoading } = useServiceToken(
    'oxford',
    '/service_token/session_escalation_token'
  );
  const { logError } = useContext(AppConfig);

  const {
    data: notificationsData,
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['oxford', 'notifications_summary'],
    queryFn: async () => {
      return await OxfordHelpers.poll({
        method: 'GET',
        url: notificationSummaryNamespace,
        token: tokenData?.token,
        jsonRequired: true,
      })
        .then(async (response) => parseNotification(response))
        .catch((error: Error) => {
          logError('Error fetching notification summary', error as Error);
        });
    },

    enabled: !!tokenData?.token,
    retry: 3,
  });

  const markAllNotificationsAsDisplayed = () =>
    patchNotificationsDisplay(tokenData?.token);

  return {
    data:
      notificationsData && !isLoading
        ? {
            notifications: notificationsData,
          }
        : undefined,
    isLoading: isTokenLoading || isFetching,
    isError: isError,
    markAllNotificationsAsDisplayed,
    refetchNotifications: refetch,
  };
};

export default useNotifications;
