import { Box, useTheme, styled } from '@ltvco/refresh-lib/theme';
import { FavoriteBorderOutlined, RoofingOutlined, LocalAirportOutlined, BusinessCenterOutlined, EmojiEventsOutlined, TheaterComedyOutlined, QuestionAnswerOutlined } from '@mui/icons-material';

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'currentTopicStyle' && prop !== 'dashboard',
})<{ dashboard?: boolean; currentTopicStyle: any }>(
  ({ dashboard, currentTopicStyle }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: dashboard ? 'absolute' : 'relative',
    zIndex: 1,
    padding: 2,
    left: dashboard ? '-66px' : 0,
    top: dashboard ? '-64px' : 0,
    width: dashboard ? '134px' : '48px',
    height: dashboard ? '124px' : '48px',
    background: currentTopicStyle.offColor,
    borderRadius: '50%',
  })
);

export const TopicIcon = ({
  topic,
  dashboard = true,
}: {
  topic: number;
  dashboard?: boolean;
}) => {
  const theme = useTheme();

  const baseStyles = {
    position: dashboard ? 'absolute' : 'relative',
    bottom: dashboard ? 20 : 0,
    right: dashboard ? 26 : 0,
  };

  const topicStyles = {
    1: {
      Icon: (
        <FavoriteBorderOutlined
          sx={{
            ...baseStyles,
            color: 'rgba(220, 0, 21, 1)',
          }}
        />
      ),
      iconColor: theme.palette.error.main,
      offColor: theme.palette.error.light,
    },
    2: {
      Icon: (
        <RoofingOutlined
          sx={{
            ...baseStyles,
            color: 'rgba(99, 138, 58, 1)',
          }}
        />
      ),
      offColor: 'rgba(230, 240, 219, 1)',
    },
    3: {
      Icon: (
        <LocalAirportOutlined
          sx={{
            ...baseStyles,
            color: 'rgba(97, 97, 97, 0.9)',
          }}
        />
      ),
      offColor: 'rgba(245, 245, 245, 1)',
    },
    4: {
      Icon: (
        <BusinessCenterOutlined
          sx={{
            ...baseStyles,
            color: 'rgba(13, 101, 141, 1)',
          }}
        />
      ),
      offColor: 'rgba(229, 246, 253, 1)',
    },
    5: {
      Icon: (
        <EmojiEventsOutlined
          sx={{
            ...baseStyles,
            color: 'rgba(255, 180, 0, 1)',
          }}
        />
      ),
      offColor: 'rgba(255, 244, 229, 1)',
    },
    6: {
      Icon: (
        <TheaterComedyOutlined
          sx={{
            ...baseStyles,
            color: 'rgba(74, 59, 143, 1)',
          }}
        />
      ),
      offColor: 'rgba(237, 235, 255, 1)',
    },
    7: {
      Icon: (
        <QuestionAnswerOutlined
          sx={{
            ...baseStyles,
            color: 'rgba(74, 59, 143, 1)',
          }}
        />
      ),
      offColor: 'rgba(237, 235, 255, 1)',
    },
  };

  const currentTopicStyle = topicStyles[topic as keyof typeof topicStyles];

  return (
    <StyledBox dashboard={dashboard} currentTopicStyle={currentTopicStyle}>
      {currentTopicStyle.Icon}
    </StyledBox>
  );
};
