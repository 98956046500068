import { Box, Card, Text,  Stack, Button } from '@ltvco/refresh-lib/theme';
import { useParams } from 'react-router-dom';
import { MuiMarkdown, getOverrides } from 'mui-markdown';
import { translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { useBumpeeQuizChat } from 'components/Language/hooks/useBumpeeQuizChat';
import { CheckCircle } from '@mui/icons-material';

interface QuizCompletedProps {
  message: {
    content: string;
  };
}
export const QuizCompleted = ({ message }: QuizCompletedProps) => {
  const { language } = useLanguageContext();
  const { quizChatId } = useParams();
  const { retakeQuiz } = useBumpeeQuizChat(quizChatId ?? '');
  const overrides = {
    ...getOverrides({}),
    h1: {
      component: Text,
      props: { variant: 'h1' },
    },
    h2: {
      component: Text,
      props: { variant: 'h2' },
    },
    h3: {
      component: Text,
      props: { variant: 'h3' },
    },
    h4: {
      component: Text,
      props: { variant: 'h4' },
    },
    h5: {
      component: Text,
      props: { variant: 'h5' },
    },
    h6: {
      component: Text,
      props: { variant: 'h6' },
    },
    p: {
      component: Text,
      props: { variant: 'body1' },
    },
  };

  const retakeQuizHandler = () => {
    if (!quizChatId) return;

    retakeQuiz.mutate(Number(quizChatId));
  };

  return (
    <Card variant="outlined" sx={{ maxWidth: '70%', mt: 3 }}>
      <Box display="flex">
        <CheckCircle sx={{ color: '#81B44C', mx: 2 }} />
        <Stack spacing={2}>
          <MuiMarkdown overrides={overrides}>{message.content}</MuiMarkdown>
          <Button
            onClick={retakeQuizHandler}
            variant="outlined"
            sx={{ width: 'fit-content' }}
          >
            {translations.buttons.retakeQuiz[language]}
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};
