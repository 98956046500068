import { Box, Stack, Text } from '@ltvco/refresh-lib/theme';
import { IconText, StyledImage, ZodiacSignSelect } from './components';
import { ZodiacSigns, ZodiacSignsUnselected } from '../../constants';
import emptyBackground from '../../../../images/ast-numerology.svg';
import { useMemo } from 'react';
import { ExpandMore } from '@mui/icons-material';

interface AstrologyIconProps {
  image?: string;
  sign?: string;
  number?: string;
  bottomText?: string;
  selectValue?: string;
  enableSelect?: boolean;
  onSelectChange?: (e: any) => void;
  standaloneVersion?: boolean;
}

export const AstrologyIcon = ({
  sign,
  image,
  number,
  bottomText,
  enableSelect,
  selectValue,
  onSelectChange,
  standaloneVersion,
}: AstrologyIconProps) => {
  const activeSign = ZodiacSigns.find(
    (zodiacSign) => zodiacSign.value === sign?.toLowerCase()
  );

  const isNumberVariant = !!number;
  const numerologyBg = isNumberVariant && emptyBackground;

  const formattedZodiacSigns = useMemo(() => {
    return ZodiacSigns.filter((sign) =>
      selectValue === 'default' || selectValue ? sign.value !== 'default' : true
    ).map((zodiacSign) => ({
      ...zodiacSign,
      display: `${zodiacSign.display} (${zodiacSign.dateRange})`,
    }));
  }, [selectValue]);

  const activeSignDisplay = standaloneVersion ? (
    <>
      {activeSign?.display}
      <Text
        variant="subtitle2"
        color={(theme) => theme.palette.text.secondary}
        ml={0.5}
      >
        (you)
      </Text>
    </>
  ) : (
    activeSign?.display
  );

  return (
    <Stack
      textAlign="center"
      direction="column"
      alignItems="center"
      minHeight={standaloneVersion ? '' : '118px'}
      minWidth="132px"
    >
      <Box position="relative">
        <StyledImage
          src={activeSign?.image || numerologyBg || image}
          alt="Astrology Icon"
        />
        {isNumberVariant && (
          <Text
            fontSize="2.625rem"
            fontWeight="bold"
            position="absolute"
            margin="auto"
            top="13px"
            color="primary"
            display="flex"
            justifyContent="space-around"
            width="100%"
          >
            {number}
          </Text>
        )}
      </Box>
      {!enableSelect && (
        <IconText variant="h5">{activeSignDisplay || bottomText}</IconText>
      )}
      {enableSelect && (
        <ZodiacSignSelect
          id="astrology-compatability-select"
          value={selectValue || ZodiacSignsUnselected.value}
          options={formattedZodiacSigns}
          onChange={onSelectChange}
          renderValue={(value: unknown) => {
            const selectedSign = ZodiacSigns.find(
              (zodiacSign) => zodiacSign.value === value
            );
            return selectedSign?.display || '';
          }}
          IconComponent={ExpandMore}
          MenuProps={{
            PaperProps: { sx: { maxHeight: '211px' } },
          }}
        />
      )}
    </Stack>
  );
};
