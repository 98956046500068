import { Select, styled } from '@ltvco/refresh-lib/theme';

export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  textAlign: 'left',
  width: '100%',
  maxWidth: '175px',

  '&.Mui-focused, &:hover': {
    borderColor: theme.palette.primary.contrastText,
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.contrastText,
    },
  },

  '.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.contrastText,
  },

  svg: {
    color: theme.palette.primary.contrastText,
  },
}));
