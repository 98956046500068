import { unformatPhone } from '@ltvco/refresh-lib/utils';
import { OxfordHelpers } from './Helpers';
import { userProfilePath } from './constants';

export type UserProfileForm = {
  first_name: string;
  last_name: string;
  address: string;
  apt?: string;
  city: string;
  state: string;
  zip: string;
  tokenized_ssn: string;
  tokenized_ssn_confirmation: string;
  phone_number: string;
  tokenized_dob: string;
  terms_and_conditions: boolean;
  black_box_id?: string;
};

export type UserProfile = {
  id: number;
  user_code: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  first_name: string;
  last_name: string;
  authenticated: boolean;
  active: boolean;
  last_attempt_date?: string;
  created_at: string;
  updated_at: string;
  phone_number: string;
  brand_unique_name: string;
};

export class UseUserProfile {
  public static async getUserProfileRequest(token: string) {
    return OxfordHelpers.poll({
      method: 'GET',
      url: userProfilePath,
      token,
      jsonRequired: true,
    });
  }

  public static async postUserProfileRequest(
    body: UserProfileForm,
    email: string | undefined,
    token: string
  ) {
    return OxfordHelpers.request({
      method: 'POST',
      url: userProfilePath,
      body: UseUserProfile._transformUserProfileData(body, email),
      token,
    });
  }

  public static async patchUserProfileRequest(
    body: UserProfileForm,
    email: string | undefined,
    token: string
  ) {
    return OxfordHelpers.request({
      method: 'PUT',
      url: userProfilePath,
      body: UseUserProfile._transformUserProfileData(body, email),
      token,
    });
  }

  static _transformUserProfileData(
    data: UserProfileForm,
    email: string | undefined
  ) {
    let transformedData = { ...data, email };
    transformedData.address = `${data.address} ${data.apt}`;
    transformedData.phone_number = unformatPhone(data.phone_number);
    if (transformedData.phone_number[0] === '1') {
      transformedData.phone_number = transformedData.phone_number.substring(1);
    }

    return transformedData;
  }
}
