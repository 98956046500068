import { useQuery } from '@ltvco/refresh-lib/vendors';
import { useServiceToken } from '@ltvco/refresh-lib/v1';
import { BumpeeRequests } from 'api';
import { useEffect, useState } from 'react';

interface TranslateContentInterface {
  translated: boolean;
  content: string;
  open: boolean;
  shouldTranslate: boolean;
}

export const useBumpeeTranslation = (chatId: number, messageId: number) => {
  const [translateContent, setTranslateContent] =
    useState<TranslateContentInterface>({
      translated: false,
      content: '',
      open: false,
      shouldTranslate: false,
    });

  const { data: tokenData } = useServiceToken('bumpee');

  const results = useQuery({
    queryKey: [`bumpeeGO-message-${messageId}-translation`],
    queryFn: () => {
      return BumpeeRequests.getMessageTranslation(
        tokenData.token,
        chatId,
        messageId
      );
    },
    enabled: translateContent.shouldTranslate && Boolean(tokenData?.token),
    onSuccess: (data: any) => {
      setTranslateContent((prevState) => ({
        ...prevState,
        translated: true,
        content: data.translated_message,
        shouldTranslate: true,
      }));
    },
  });

  const handleTranslateClick = () => {
    setTranslateContent((prevState) => ({
      ...prevState,
      shouldTranslate: true,
      open: !prevState.open,
    }));
  };

  useEffect(() => {
    if (results.isLoading) {
      setTranslateContent((prevState) => ({
        ...prevState,
        content: '',
      }));
    }
  }, [results.isLoading]);

  return { ...results, translateContent, handleTranslateClick };
};
