import { Stack } from '@ltvco/refresh-lib/theme';
import {
  type AstrologyCompatibilityProps,
  withAstrologyCompatibility,
  CompatibilityHeader,
} from 'components/Astrology';
import { AstrologyStandaloneSectionTextField } from '../components';

const SummaryContent = ({ content }: AstrologyCompatibilityProps) => {
  return <AstrologyStandaloneSectionTextField content={content} rows={5} />;
};

const AstrologyStandaloneCompatibilityContent =
  withAstrologyCompatibility(SummaryContent);

interface AstrologyStandaloneCompatibilityProps {
  zodiacSign: string;
  compatibilitySign: string;
  setCompatibilitySign: (sign: string) => void;
}

export const AstrologyStandaloneCompatibility = ({
  zodiacSign,
  compatibilitySign,
  setCompatibilitySign,
}: AstrologyStandaloneCompatibilityProps) => {
  return (
    <Stack p={1.5}>
      <CompatibilityHeader
        value={zodiacSign}
        selectValue={compatibilitySign}
        onChangeCallback={(sign) => setCompatibilitySign(sign)}
        standaloneVersion
      />
      <AstrologyStandaloneCompatibilityContent
        zodiacSign={zodiacSign}
        compatibilitySign={compatibilitySign}
      />
    </Stack>
  );
};
