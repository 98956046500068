import { Box, Stack } from '@ltvco/refresh-lib/theme';
import { useState, useEffect, useContext } from 'react';
import { ChatHeader } from '../ChatContainer/ChatHeader/ChatHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { languageID, translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';
import { LessonSidebar } from '../LessonChatContainer/LessonSidebar/LessonSidebar';
import { useBumpeeStoryChat } from '../hooks/useBumpeeStoryChat';
import { useBumpeeStoryChats } from '../hooks/useBumpeeStoryChats';
import { CurrentChat } from '../ChatContainer/CurrentChat/CurrentChat';
import { useAchievementEventWatcher } from '../hooks/useAchievementEventWatcher';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useScreenSize } from '@ltvco/refresh-lib/utils';

interface Story {
  id: number;
  title: string;
  storyChatId?: number;
  assignmentType: string;
}

export const StoryChatContainer = () => {
  useAchievementEventWatcher();
  const [stories, setStories] = useState<Story[]>([]);

  const navigate = useNavigate();
  const { trackEventGA4 } = useContext(AppConfig);
  const { isMobile } = useScreenSize();
  const { storyChatId } = useParams();
  const { data: storyChatData } = useBumpeeStoryChat(storyChatId);
  const { data: userStoryChats, createStoryChatMutation } =
    useBumpeeStoryChats();
  const { language, lessonLearningLanguage } = useLanguageContext();

  const chatId = storyChatData?.chat_id;
  const languageLessonName =
    storyChatData?.chat?.learning_language?.toLowerCase();
  const activeStoryId = storyChatData?.story_id;

  useEffect(() => {
    if (!userStoryChats) return;

    const stories =
      translations.dashboard.assignments.story.storyList[language];

    const modifiedLessons = stories?.map((story, index) => {
      const storyChat = userStoryChats?.story_chats.find(
        (userStory) =>
          userStory.story_id === story.id &&
          userStory.learning_language_id ===
            languageID(languageLessonName ?? '')
      );

      return {
        ...story,
        assignmentType: 'story',
        title: `${translations.dashboard.assignmentCardTitles[language]} ${
          index + 1
        }: ${story.title}`,
        storyChatId: storyChat?.id,
        status: storyChat?.completed
          ? 'completed'
          : storyChat?.chat_id
          ? 'incomplete'
          : 'new',
      };
    });

    setStories(modifiedLessons);
  }, [language, languageLessonName, userStoryChats, storyChatId]);

  const redirectToStory = (storyId: number) => {
    const story = stories.find((story) => story.id === storyId);
    if (!story) return;

    if (story.storyChatId) {
      navigate(`/dashboard/language/story/${story.storyChatId}`);
      return;
    }

    const storyChatParams = {
      lesson_id: story.id,
      learning_language_id: parseInt(lessonLearningLanguage.value),
    };

    createStoryChatMutation.mutate(storyChatParams);

    trackEventGA4({
      event_name: 'Language Tutor Engagement',
      type: 'story',
      language: languageLessonName,
      action: 'start',
    });
  };

  return (
    <Box
      display="flex"
      width="100%"
      height={isMobile ? '90vh' : '80vh'}
      ml={0}
      pt={{ xs: 0, md: 1 }}
      px={{ xs: 0, md: 1.5 }}
    >
      {!isMobile && (
        <LessonSidebar
          assignments={stories}
          activeAssignmentId={activeStoryId}
          redirectToAssignment={redirectToStory}
        />
      )}

      <Stack width="100%">
        <ChatHeader
          assignmentTopic={
            translations.dashboard.assignments.story.storyList[language].find(
              (story) => story.id === storyChatData?.story_id
            )?.title
          }
          assignments={stories}
          activeAssignmentId={activeStoryId}
          redirectToAssignment={redirectToStory}
          isStoryChat={true}
        />
        <CurrentChat
          externalChatId={chatId}
          isStoryChat
          lessonCompleted={storyChatData?.completed}
          storyChatId={Number(storyChatId)}
          disableWordTranslations
          conversation={storyChatData?.conversation}
        />
      </Stack>
    </Box>
  );
};
