import { Box, styled, Text } from '@ltvco/refresh-lib/theme';
import type { Reward } from '@ltvco/refresh-lib/v1';

const MemberPerkImage = styled('img')({
  width: 'min-content',
  height: '15px',
});

const MemberPerkContainerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: '1px solid #E2E2E2',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  justifyContent: 'space-between',
  width: '185px',
  height: '124px',
  cursor: 'pointer',
  ':hover': {
    border: `1px solid ${theme.palette.progress?.main}`,
    boxShadow: `0px 0px 5px 0px ${theme.palette.progress?.main}`,
  },
}));

type MemberPerkContainerProps = {
  reward: Reward;
  cardClick: () => void;
};

export const MemberPerkContainer = ({
  reward,
  cardClick,
}: MemberPerkContainerProps) => {
  return (
    <MemberPerkContainerBox onClick={cardClick}>
      <MemberPerkImage src={reward.logo} />
      <Text textAlign="center">{reward.collapsed.title}</Text>
      <Text color={(theme) => theme.palette.progress?.main}>
        {reward.collapsed.cta}
      </Text>
    </MemberPerkContainerBox>
  );
};
