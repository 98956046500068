import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQuery } from '@ltvco/refresh-lib/vendors';
import { CloudCannonRequests } from 'api';
import { useContext } from 'react';

export const useCloudCannon = () => {
  const { logError } = useContext(AppConfig);

  const result = useQuery({
    queryKey: ['cloudcannon'],
    queryFn: CloudCannonRequests.getAchievementsRequest,
    onError: (error) => {
      logError('Error fetching achievement images', error as Error);
    },
  });

  return { ...result };
};
