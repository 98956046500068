import { Button, Stack, styled } from '@ltvco/refresh-lib/theme';

export const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: 'white',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignSelf: 'flex-end',
  marginTop: 'auto',
  padding: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(5),
  },
}));

export const StyledCloseButton = styled(Button)({
  position: 'absolute',
  right: 0,
  top: 2,
  zIndex: 2,
  marginTop: 1,
});

export const StyledStackGradient = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  background:
    'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(250,243,255,1) 100%)',
  paddingTop: theme.spacing(4),
  width: '100%',
}));
