import { useQueryClient } from '@ltvco/refresh-lib/vendors';
import { GetQuizChatResponse, GetStoryChatResponse } from 'api';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAchievementEventCoordinator } from './useAchievementEventCoordinator';

const BASE_QUERY_KEY = 'bumpeeGO';

export function useAchievementEventWatcher() {
  const {
    handleQuizBasedAchievementEvent,
    handleAudioStoryBasedAchievementEvent,
  } = useAchievementEventCoordinator();
  const { quizChatId, storyChatId } = useParams();
  const [isCurrentlyTakingQuiz, setIsCurrentlyTakingQuiz] = useState(false);
  const [isCurrentlyTakingStory, setIsCurrentlyTakingStory] = useState(false);
  const queryClient = useQueryClient();
  const quizData = queryClient.getQueryData([
    `${BASE_QUERY_KEY}-quiz-chat-${quizChatId}`,
  ]) as GetQuizChatResponse;

  const storyData = queryClient.getQueryData([
    `${BASE_QUERY_KEY}-story-chat-${storyChatId}`,
  ]) as GetStoryChatResponse;

  // Handles completing a quiz
  useEffect(() => {
    if (!quizData) return;

    if (quizData?.status === 'incomplete' || quizData?.status === 'new') {
      setIsCurrentlyTakingQuiz(true);
    }

    if (quizData?.status === 'completed' && isCurrentlyTakingQuiz) {
      handleQuizBasedAchievementEvent({
        event: 'complete',
        category: 'quiz',
        metaData: {
          score_actual: quizData?.score?.correct || 0,
          score_max: quizData?.score?.total || 0,
        },
      });
      setIsCurrentlyTakingQuiz(false);
    }
  }, [quizData?.status, isCurrentlyTakingQuiz]);

  // Handles completing an audio story
  useEffect(() => {
    if (!storyData) return;

    if (!storyData?.completed) {
      setIsCurrentlyTakingStory(true);
    }

    if (storyData?.completed && isCurrentlyTakingStory) {
      handleAudioStoryBasedAchievementEvent({
        event: 'complete',
        category: 'audio story',
      });
      setIsCurrentlyTakingStory(false);
    }
  }, [storyData?.completed, isCurrentlyTakingStory]);
}
