import { Text } from '@ltvco/refresh-lib/theme';

type HeaderConstantsType = {
  '1': {
    title: string;
    body: any;
  };
  '2': {
    title: string;
    body: string;
  };
};

const HeaderConstants: HeaderConstantsType = {
  '1': {
    title: 'Personal Information',
    body: (
      <>
        To access ID Monitor, please provide your personal information used on
        your credit record. Information and protection tools are provided
        through our credit bureau partner, TransUnion®. Enrolling with ID
        Monitor will{' '}
        <Text component="span" fontWeight="bold">
          not
        </Text>{' '}
        impact your credit score and is used only for accessing your information
        and protecting your identity and credit.
      </>
    ),
  },
  '2': {
    title: 'Identity Authentication',
    body: 'Your information will be encrypted and sent directly to TransUnion® strictly for the purpose of confirming your identity. Any data you access and identity protection features or services you opt in to will be based on this confirmed identity.',
  },
};

export const Header: React.FC<{
  isFormStepOne: boolean;
}> = ({ isFormStepOne }) => {
  const formStep = isFormStepOne ? '1' : '2';

  return (
    <>
      <Text variant="h6" fontWeight="normal">
        Step {formStep}/2
      </Text>
      <Text variant="h1">{HeaderConstants[formStep].title}</Text>
      <Text variant="body1">{HeaderConstants[formStep].body}</Text>
    </>
  );
};
