import React from 'react';
import { Stack, Text, Card, styled } from '@ltvco/refresh-lib/theme';
import { useOxford } from '../Oxford/Oxford';
import { getMonthsAbbreviations } from './utils';
import { DataContainer } from './DataContainer';
import { CreditScoreHistoryProps } from './constants';
import { CreditSummary } from './CreditSummary';

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
}));

export const CreditScoreHistory: React.FC = () => {
  const { getCreditData } = useOxford();

  if (!getCreditData.data?.creditScoreHistory) return null;

  const monthsAbbreviations: unknown[] = getMonthsAbbreviations(
    getCreditData.data.creditScoreHistory
  );

  const castedMonthsAbbreviations = monthsAbbreviations as string[];

  const dataReady = monthsAbbreviations.length && getCreditData.data;
  const creditScoreHistory: CreditScoreHistoryProps[] =
    getCreditData.data.creditScoreHistory?.map((item) => ({
      score: item.score !== null ? parseInt(item.score, 10) : 0,
      date: item.date,
    })) || [];

  return (
    <StyledStack p={2.5}>
      <Text variant="h2">Credit Score & History</Text>
      <Text variant="body1" mb={2}>
        The VantageScore 3.0® credit score is an alternative credit score model
        to FICO that is jointly developed by all three national Credit Reporting
        Companies. The VantageScore model may provide people with a credit score
        using just one month of credit history, where FICO requires six months
        of account data.
      </Text>
      <Card>
        {dataReady && (
          <DataContainer
            monthsAbbreviations={castedMonthsAbbreviations}
            creditScoreHistory={creditScoreHistory}
            lastUpdatedDate={getCreditData.data.lastUpdatedDate}
            creditScore={getCreditData.data.vantageScore}
          />
        )}
      </Card>
      <StyledCard>
        <CreditSummary />
      </StyledCard>
    </StyledStack>
  );
};

export default CreditScoreHistory;
