import { createContext, ReactNode, useContext, useState } from 'react';
import {
  AchievementsTrackerItem,
  AchievementsTrackerModal,
} from './Gamification/AchievementsTrackerModal/AchievementsTrackerModal';
import { pluralizeString } from '@ltvco/refresh-lib/utils';
import { useAchievementEventWatcher } from './hooks/useAchievementEventWatcher';
import { useAchievementsData } from './hooks/useAchievementsData';
import { translations } from './constants';
import { useLanguageContext } from './LanguageContext';

interface AchievementProviderProps {
  children?: ReactNode;
}

export type Achievement = {
  image: string;
  date: string | undefined;
  disabled: boolean;
  id: string;
  name: string;
  tooltip: string;
  title?: string;
  description?: string;
};

type NewAchievement = {
  title: string;
  description: string;
};

type AchievementContextState = {
  achievements: Achievement[];
  newAchievementModalOpen: boolean;
  handleNewAchievementModalOpen: (newAchievements: NewAchievement[]) => void;
  handleNewAchievementModalClose: () => void;
  recentlyEarnedAchievement?: Achievement | null;
};

const AchievementContext = createContext({
  achievements: [],
  newAchievementModalOpen: false,
  handleNewAchievementModalOpen: () => {},
  handleNewAchievementModalClose: () => {},
  recentlyEarnedAchievement: null,
} as AchievementContextState);

export const AchievementProvider = ({ children }: AchievementProviderProps) => {
  // Used to listen for quiz and story completion events
  useAchievementEventWatcher();
  const { language } = useLanguageContext();

  const { achievements, recentlyEarnedAchievement } = useAchievementsData();
  const [newAchievements, setNewAchievements] = useState<
    AchievementsTrackerItem[]
  >([]);
  const [newAchievementModalOpen, setNewAchievementModalOpen] = useState(false);

  const { newAchievementEarned, newTranslation, earned, achievement } =
    translations.general;

  const handleNewAchievementModalOpen = (newAchievements: NewAchievement[]) => {
    const newAchievementsFullData = newAchievements.map((newAchievement) => {
      const achievement = achievements.find(
        (ach) => ach.id === newAchievement.title
      );

      const achievementTranslation = translations.badges[language].find(
        (translatedAchievement) =>
          translatedAchievement.id === newAchievement.title
      );
      return {
        title: achievement?.name ?? '',
        description: achievementTranslation?.tooltip ?? '',
        image: achievement?.image ?? '',
      };
    });
    setNewAchievements((prevState) => [
      ...prevState,
      ...newAchievementsFullData,
    ]);
    setNewAchievementModalOpen(true);
  };

  const handleNewAchievementModalClose = () => {
    setNewAchievementModalOpen(false);
    setNewAchievements([]);
  };

  const values = {
    achievements,
    newAchievementModalOpen,
    recentlyEarnedAchievement,
    handleNewAchievementModalOpen,
    handleNewAchievementModalClose,
  };

  return (
    <AchievementContext.Provider value={values}>
      {children}
      {Boolean(newAchievements) && newAchievementModalOpen && (
        <AchievementsTrackerModal
          customTitle={
            Boolean(newAchievements.length > 1)
              ? `${newAchievements.length} ${
                  newTranslation[language]
                } ${pluralizeString(
                  achievement[language],
                  newAchievements.length
                )} ${earned[language]}`
              : newAchievementEarned[language]
          }
          handleOnClose={handleNewAchievementModalClose}
          items={newAchievements!}
          newAchievements={true}
        />
      )}
    </AchievementContext.Provider>
  );
};

export const useAchievementContext = () => {
  return useContext(AchievementContext);
};
