import { useState, useEffect } from 'react';
import { useQuery } from '@ltvco/refresh-lib/vendors';
import { BumpeeRequests, Hint } from 'api';
import { useAchievementEventCoordinator } from './useAchievementEventCoordinator';
import { useServiceToken } from '@ltvco/refresh-lib/v1';

const baseQueryKey = 'bumpeeGO';

interface HintsDataInterface {
  open: boolean;
  hints: Hint[];
  isLoading: boolean;
  shouldFetchHint: boolean;
}

export function useBumpeeHint(chatId?: number, messageID?: number) {
  const { handleUserActionBasedAchievementEvent } =
    useAchievementEventCoordinator();
  const [hintData, setHintData] = useState<HintsDataInterface>({
    open: false,
    hints: [],
    isLoading: false,
    shouldFetchHint: false,
  });
  const { data: tokenData } = useServiceToken('bumpee');

  useEffect(() => {
    setHintData({
      open: false,
      hints: [],
      isLoading: false,
      shouldFetchHint: false,
    });
  }, [chatId, messageID]);

  const results = useQuery({
    queryKey: [`${baseQueryKey}-hint-${messageID}`],
    queryFn: () => BumpeeRequests.getHint(tokenData.token, chatId, messageID),
    enabled: hintData.shouldFetchHint && Boolean(tokenData?.token),
    onSuccess: (data) => {
      handleUserActionBasedAchievementEvent({
        event: 'click',
        category: 'hint',
      });
      setHintData((prevState) => ({
        ...prevState,
        hints: data.hints,
        isLoading: false,
      }));
    },
  });

  useEffect(() => {
    if (results.isFetching) {
      setHintData((prevState) => ({
        ...prevState,
        isLoading: results.isFetching,
      }));
    }
  }, [results.isFetching]);

  const fetchHint = () => {
    setHintData((prevState) => ({
      ...prevState,
      shouldFetchHint: true,
      open: true,
    }));
  };

  const clearHints = () => {
    setHintData({
      open: false,
      hints: [],
      isLoading: false,
      shouldFetchHint: false,
    });
  };

  return {
    fetchHint,
    hintData,
    clearHints,
    ...results,
  };
}
