import { BumpeeRequests, CreateUserRequirementParams } from 'api';
import { useContext } from 'react';
import { useAchievementContext } from '../AchievementContext';
import { useServiceToken } from '@ltvco/refresh-lib/v1';
import { useMutation, useQueryClient } from '@ltvco/refresh-lib/vendors';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

export const useCreateRequirement = () => {
  const queryClient = useQueryClient();
  const { data: tokenData } = useServiceToken('bumpee');
  const { logError } = useContext(AppConfig);
  const { handleNewAchievementModalOpen } = useAchievementContext();

  const createUserRequirementMutation = useMutation({
    mutationFn: (params: CreateUserRequirementParams) => {
      return BumpeeRequests.createUserRequirement(tokenData.token, params);
    },
    onSuccess: (data) => {
      if (data?.new_achievements && data.new_achievements.length > 0) {
        handleNewAchievementModalOpen(data.new_achievements);
      }

      queryClient.invalidateQueries(['user-achievements']);
    },
    onError: (error: Error) => {
      logError('Failed to create user requirements', error as Error);
    },
  });

  return { createUserRequirementMutation };
};
