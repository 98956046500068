import { Stack, Box } from '@ltvco/refresh-lib/theme';

import {
  PhoneSection as PhoneSectionV2,
  EmailSection as EmailSectionV2,
  AddressHistorySection as AddressHistorySectionV2,
  SocialSection as SocialSectionV2,
  JobsSection as JobsSectionV2,
  EducationSection as EducationSectionV2,
  ReportRating as ReportRatingV2,
  RelatedReportsSection as RelatedReportsSectionV2,
  NotesSection as NotesSectionV2,
  PotentialOwners as PotentialOwnersV2,
  ReportNavigationMenu,
  ReportNavigationMobileMenu,
  ReportNavigationMenuProps,
  UsernameReportOverview,
} from '@ltvco/refresh-lib/v2';

import { getSocialNavLinkData } from 'navLinkData/socialNavLinkData';
import { useState, useContext } from 'react';
import type { ReportRouteProps } from './types';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import imgIdCard from '../../images/img_idCard.svg';
import { ReportActionsWithDateUpdated } from '@ltvco/refresh-lib/v2';
import {
  ContactReportSectionBanner,
  DebugMenu,
  ReportFactory,
  ReportLoading,
  ReportNullState,
  ReportOptions,
  useReport,
  useReportRedirect,
} from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
  DateUtil,
} from '@ltvco/refresh-lib/utils';

interface SocialReportProps extends ReportRouteProps {}

const reportType = 'username';

export function SocialReport({ permalink }: SocialReportProps) {
  const { redirectToSearchContactUrl } = useReportRedirect();
  const { trackEvent } = useContext(AppConfig);
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: true,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={9} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  const report = ReportFactory.create(queryResult?.data, 'username');

  if (!report) return <ReportLoading menuItems={9} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const owner = report.getOwner(reportOptions);
  const {
    ownerName,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    usernames,
    addresses,
    images,
    relatives,
  } = owner;

  const socialNavLinkData = getSocialNavLinkData(
    owner,
    report?.data?.people?.length ?? 0
  );
  const date = new DateUtil();
  const reportUpdateDate = report.data.meta?.updated_at
    ? date.parseDateFromString(
        report.data.meta?.updated_at,
        'yyyy-MM-dd',
        'yyyy-MM-dd HH:mm:ss ZZZ'
      )
    : '';
  document.title = `${report.username} - BeenVerified`;

  const handleOnBeginSearch = () => {
    trackEvent('username report promo', 'navigation click', 'contact search');
    redirectToSearchContactUrl({});
  };

  return (
    <Box>
      <Stack direction="row">
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
        >
          <ReportNavigationMenu
            reportType="Social Report"
            headerTitle={ownerName || report.username}
            menuItems={
              socialNavLinkData as ReportNavigationMenuProps['menuItems']
            }
          />
        </Box>
        <Box marginTop={0}>
          <Box
            sx={{
              height: 30,
              backgroundColor: '#f8f8f8',
              position: 'sticky',
              marginBottom: '-10px',
              marginX: -1,
              top: 52,
              zIndex: 10,
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'block',
              },
            }}
          />

          <ReportActionsWithDateUpdated
            reportType={reportType}
            reportTitle="Username"
            dateUpdated={reportUpdateDate}
            reportUpgraded={false}
          />

          <UsernameReportOverview
            dateUpdated={reportUpdateDate}
            reportType={reportType}
            username={report.username}
            showHighConfidenceToggle
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) =>
              handleSetReportOptions({ showHighConfidenceDataOnly: value })
            }
          />

          <Box mt={2.5}>
            <PotentialOwnersV2
              potentialOwners={report.data.people || []}
              potentialOwnerIndex={reportOptions.potentialOwnerIndex}
              setPotentialOwnerIndex={(index: number) => {
                handleSetReportOptions({ potentialOwnerIndex: index });
              }}
              personName={ownerName || report.username}
              permalink={permalink}
            />
          </Box>

          <PhoneSectionV2
            personName={ownerName || report.username}
            phoneList={phones}
            permalink={permalink}
          />
          <EmailSectionV2
            personName={ownerName || report.username}
            emailList={emails}
            permalink={permalink}
          />
          <AddressHistorySectionV2
            addresses={addresses}
            personName={ownerName || report.username}
            permalink={permalink}
          />
          <SocialSectionV2
            personName={ownerName || report.username}
            profiles={profiles}
            usernames={usernames}
            permalink={permalink}
          />
          <JobsSectionV2
            personName={ownerName || report.username}
            jobs={jobs}
            permalink={permalink}
          />
          <ContactReportSectionBanner
            image={imgIdCard}
            onBeginSearchClick={handleOnBeginSearch}
          />
          <EducationSectionV2
            personName={ownerName || report.username}
            educations={educations}
            permalink={permalink}
          />

          <NotesSectionV2 permalink={permalink} />
          <RelatedReportsSectionV2
            reportType="username"
            personName={ownerName || report.username}
            potentialOwners={report.data.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
          />
          <ReportRatingV2
            rating={report.data.meta?.rating || null}
            report_type={reportType}
          />
        </Box>
      </Stack>
      <ReportNavigationMobileMenu
        reportType="Social Report"
        headerTitle={ownerName || report.username}
        menuItems={socialNavLinkData as ReportNavigationMenuProps['menuItems']}
      />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </Box>
  );
}
