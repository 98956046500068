import { Carousel, Stack, Text } from '@ltvco/refresh-lib/theme';
import { StyledCarousel, StyledImg } from '../components';
import { useRef } from 'react';
import { AchievementsTrackerItem } from '../AchievementsTrackerModal/AchievementsTrackerModal';
import { BasicSwiperCarouselPagination } from '@ltvco/refresh-lib/v1';

interface AchievementsCarouselProps {
  items: AchievementsTrackerItem[];
}

export const AchievementsCarousel = ({ items }: AchievementsCarouselProps) => {
  const carouselConfig = {
    slidesPerView: 1,
    autoHeight: true,
    watchSlidesProgress: true,
  };
  const childRef = useRef<HTMLDivElement>(null);
  const carouselContent = items?.flatMap((item) => {
    return (
      <Stack width="100%" alignItems="center">
        <StyledImg height="7.5rem" src={item.image} width="7.5rem" />
        <Text variant="h4" textAlign="center">
          {item.title}
        </Text>
        <Text textAlign="center" mx={1}>
          {item.description}
        </Text>
      </Stack>
    );
  });

  return (
    <StyledCarousel>
      <Carousel
        config={carouselConfig}
        elements={carouselContent}
        slideStyles={{ maxWidth: '100%' }}
        customPagination={
          <BasicSwiperCarouselPagination
            ref={childRef}
            numberOfImages={carouselContent.length}
            customContainerStyle={{
              marginTop: 2.25,
              marginBottom: 1,
              display: items.length > 1 ? 'flex' : 'none',
            }}
            customButtonsStyle={{
              border: '1px solid rgba(74, 59, 143, 0.5)',
              color: 'primary.main',
              margin: 2,
              minWidth: '40px',
            }}
          />
        }
      />
    </StyledCarousel>
  );
};
