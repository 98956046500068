import {
  Button,
  Box,
  Modal,
  Text,
  Stack,
  Skeleton,
  Tooltip,
  styled,
  TextArea,
  useTheme,
} from '@ltvco/refresh-lib/theme';
import { useBumpeeTranslations } from 'components/Language/hooks/useBumpeeTranslations';
import { useState, KeyboardEvent, useEffect } from 'react';
import {
  StyledCloseButton,
  StyledStackGradient,
} from '../NewChatModal/components';
import { StyledCard, StyledTextArea } from './components';
import { StyledDrawer } from '../LanguageHeader/LanguageSettings/LanguageSettings';
import { useBumpeeChat } from '../hooks/useBumpeeChat';
import { useLanguageContext } from '../LanguageContext';
import { languageID, translations } from '../constants';
import { useForm } from '@ltvco/refresh-lib/vendors';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { Close, Translate, MoreVertOutlined, ContentCopyOutlined } from '@mui/icons-material';
interface TranslatorModalProps {
  setTranslatorModalOpen: (value: boolean) => void;
  chatId: number;
}

const Textarea = styled(TextArea)(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  fontSize: '1rem',
  padding: '8px 12px',
  border: 'none',
  height: '100%',
  overflow: 'auto',
  borderRadius: '0',

  '&:focus-visible': {
    outline: 0,
  },

  '&:disabled': {
    backgroundColor: 'transparent',
  },
}));

export const TranslatorModal = ({
  setTranslatorModalOpen,
  chatId,
}: TranslatorModalProps) => {
  const theme = useTheme();
  const { register, getValues } = useForm({
    mode: 'onSubmit',
  });
  const { isMobile, isMedium } = useScreenSize();
  const [textToTranslate, setTextToTranslate] = useState('');
  const { translation, handleTranslateClick, isLoading } =
    useBumpeeTranslations(chatId, textToTranslate);
  const [showTranslationComponent, setShowTranslationComponent] =
    useState(false);
  const [translatedText, setTranslatedText] = useState('');
  const [copied, setCopied] = useState(false);
  const { data: chat } = useBumpeeChat(chatId);
  const { language } = useLanguageContext();
  const Container = isMobile ? StyledDrawer : Modal;
  const nativeLanguageId = languageID(language);
  const learningLanguageId = languageID(
    chat?.learning_language.toLowerCase() || 'english'
  );
  const title = `${translations.translatorModal.title[language].start} ${
    translations.languages[language].find(
      (item) => item.id === nativeLanguageId
    )?.name
  } ${translations.translatorModal.title[language].preposition} ${
    translations.languages[language].find(
      (item) => item.id === learningLanguageId
    )?.name
  }`;

  const tooltip =
    copied || isMedium ? (
      <Text>{translations.translatorModal.tooltip[language].copied}</Text>
    ) : (
      <Text alignItems="center">
        {translations.translatorModal.tooltip[language].default}
      </Text>
    );

  const handleOnClose = () => {
    setTranslatorModalOpen(false);
  };

  const handleTranslate = () => {
    const currentText = getValues('currentText');

    if (!currentText) {
      return;
    }

    setShowTranslationComponent(true);
    setTextToTranslate(getValues('currentText'));
    handleTranslateClick();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      handleTranslate();
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(translation.content);
    setCopied(true);
  };

  useEffect(() => {
    setCopied(false);
    setTranslatedText(translation.content);
  }, [translation.content]);

  return (
    <Container
      open
      onClose={handleOnClose}
      {...(isMobile && { anchor: 'bottom' })}
      {...(!isMobile && { hasCloseIcon: true })}
      sx={{
        '.MuiPaper-root': {
          paddingTop: 0,
        },
        '.MuiDialogContent-root': {
          padding: 0,
        },
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        height="523px"
        position="relative"
      >
        {isMobile && (
          <StyledCloseButton onClick={handleOnClose}>
            <Close />
          </StyledCloseButton>
        )}
        <StyledStackGradient mb={3}>
          <Translate fontSize="large" sx={{ color: theme.palette.primary.main}} />
          <Text variant="h2" mt={1}>
            {title}
          </Text>
        </StyledStackGradient>
        <Stack alignItems="center" p={2} pt={0} width="100%">
          <Box width="100%">
            <form>
              <StyledTextArea
                maxRows={4}
                aria-label="text to translate"
                {...register('currentText', {
                  required: true,
                })}
                autoComplete="off"
                id="currentText"
                key="currentText"
                placeholder={translations.translatorModal.placeholder[language]}
                onKeyDown={handleKeyDown}
                disabled={translation.shouldTranslate}
              />
            </form>
          </Box>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleTranslate}
            disabled={translation.shouldTranslate}
          >
            <Translate fontSize="small" sx={{ color: theme.palette.primary.main}} />
            {translations.buttons.translate[language]}
          </Button>
          {showTranslationComponent && (
            <Stack alignItems="center" width="100%">
              <MoreVertOutlined
                fontSize="large"
                sx={{ color: theme.palette.primary.main, my: 1.25}}
              />
              <StyledCard variant="outlined">
                <Stack
                  alignItems="flex-start"
                  justifyContent="space-between"
                  flexDirection="row"
                  width="100%"
                  p={0}
                  pt={1.25}
                  pb={1.25}
                  sx={{ backgroundColor: '#FAFAFA' }}
                >
                  <Text variant="h4" m={0} ml={2} color="primary.light">
                    {translations.chatUI.translation[language]}
                  </Text>
                  <Tooltip enterTouchDelay={isMedium ? 0 : 700} title={tooltip}>
                    <Button
                      variant="outlined"
                      onClick={copyToClipboard}
                      disabled={copied || translation.shouldTranslate}
                      sx={{ mr: 2, fontSize: '0.75rem' }}
                    >
                      <ContentCopyOutlined
                        fontSize="small"
                        color={copied ? 'secondary' : 'primary'}
                        sx={{ mr: 1 }}
                      />
                      {translations.buttons.copy[language]}
                    </Button>
                  </Tooltip>
                </Stack>
                <Stack width="100%">
                  {translation.shouldTranslate || isLoading ? (
                    <Stack pt={0} mr={2} ml={2}>
                      <Skeleton
                        variant="text"
                        height={28}
                        width="100%"
                        sx={{ pr: 2 }}
                      />
                      <Skeleton variant="text" height={28} width="60%" />
                    </Stack>
                  ) : (
                    translation.content && (
                      <StyledTextArea
                        {...register('translatedText', {
                          required: true,
                        })}
                        maxRows={4}
                        aria-label="translated text"
                        value={translatedText}
                        disabled
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                        }}
                      />
                    )
                  )}
                </Stack>
              </StyledCard>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};
