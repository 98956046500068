import { useContext } from 'react';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { Button, Input, Text } from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  StyledDatePicker,
  StyledPickersDay,
  StyledForm,
  StyledInputLabel,
} from './components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useForm, SubmitHandler } from 'react-hook-form';
import { astrologyFormSchema } from 'utils/schemaForms';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserProps } from '../../interfaces';
import { getAstrologyUser, storeAstrologyUser } from '../../utils';
import { useSession } from '@ltvco/refresh-lib/v1';

type FormFields = {
  name: string;
  dob: DateTime | null;
};

interface AstrologyOnboardingFormProps {
  onSubmitCallback: (astrologyUser: UserProps | null) => void;
  standaloneVersion?: boolean;
}

export const AstrologyOnboardingForm = ({
  onSubmitCallback,
  standaloneVersion,
}: AstrologyOnboardingFormProps) => {
  const { trackEvent, logError, trackEventGA4 } = useContext(AppConfig);
  const {
    session: { account },
  } = useSession();

  const { first_name: userFirstName, last_name: userLastName } =
    account?.account.user_info || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = useForm<FormFields>({
    mode: 'onSubmit',
    resolver: yupResolver(astrologyFormSchema),
    defaultValues: {
      name:
        userFirstName && userLastName ? `${userFirstName} ${userLastName}` : '',
      dob: null,
    },
  });

  const onDateChange = (value: unknown) => {
    clearErrors('dob');
    if (value instanceof DateTime && value) {
      setValue('dob', value);
    }
  };

  const onSubmit: SubmitHandler<FormFields> = (data) => {
    trackEventGA4({
      event_name: 'feature_engagement',
      type: 'astrology_insights',
      interacted_from: 'home',
    });
    trackEvent('engagement click/submit', 'astrology', 'astrology component');

    const dobValue = getValues('dob') as DateTime;
    storeAstrologyUser(data.name, dobValue);
    const astrologyUser = getAstrologyUser(logError);
    onSubmitCallback(astrologyUser);
  };

  return (
    <StyledForm
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        bgcolor: standaloneVersion ? 'background.paper' : 'primary.light',
      }}
    >
      <StyledInputLabel htmlFor="name">Full Name</StyledInputLabel>
      <Input
        id="name"
        placeholder="Enter first and last name"
        fullWidth
        {...register('name')}
        error={Boolean(errors.name?.message)}
      />
      <Text variant="body2" color="error" mb={2}>
        {errors.name?.message}
      </Text>
      <StyledInputLabel htmlFor="dob">Date of Birth</StyledInputLabel>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <StyledDatePicker
          disableFuture
          openTo="day"
          views={['year', 'month', 'day']}
          slots={{
            openPickerIcon: CalendarToday,
            day: StyledPickersDay,
          }}
          {...register('dob')}
          onChange={onDateChange}
        />
        <Text variant="body2" color="error" mb={2}>
          {errors.dob?.message}
        </Text>
      </LocalizationProvider>
      <Button
        variant="contained"
        type="submit"
        size="small"
        color="success"
        fullWidth
        sx={{ px: 2, py: 0.75 }}
      >
        Get Started
      </Button>
    </StyledForm>
  );
};
