import { DateTime } from 'luxon';
import IdentityCreditAlertIcon from 'images/id-monitor-identity-credit-alert.svg';
import OneMIcon from 'images/id-monitor-1m.svg';
import DarkWebScanIcon from 'images/id-monitor-dark-web-scan.svg';
import CreditScoreTrackerIcon from 'images/id-monitor-credit-score-tracker.svg';
import CreditScoreAnalysisIcon from 'images/id-monitor-credit-score-analysis.svg';
import UnclaimedMoneyIcon from 'images/id-monitor-unclaimed-money.svg';

export type Setting = {
  title: string;
  description: string;
  altIcon: string;
  icon: string;
  btnText: string;
  activateMonitorTab: boolean;
  enrolledBtnText?: string;
  enrolled?: boolean;
  enrolledText?: string;
  enrolledDescription?: string;
  notEnrolledText?: string;
  navigateTo?: string;
  gaCategory: string;
};

export type UserData = {
  credit_navigator_used: boolean;
  credit_simulator_used: boolean;
  identity_management: boolean;
  enhanced_alert_enabled: boolean;
  instant_alert_enabled: boolean;
  standard_alert_enabled: boolean;
};

export const buildSettings = (
  dataSet: UserData,
  darkWebUpdatedAt: string,
  isEnrolled: boolean
) => {
  let activeCount = 0;
  [
    'standard_alert_enabled',
    'enhanced_alert_enabled',
    'instant_alert_enabled',
  ].forEach((item) => {
    if (dataSet[item as keyof UserData] === true) activeCount++;
  });
  let parsedDate = '';
  if (!!darkWebUpdatedAt) {
    const normalizedDate = darkWebUpdatedAt.substring(0, 10);
    parsedDate = DateTime.fromISO(normalizedDate).toFormat('MM/dd');
  }

  const settings: Setting[] = [
    {
      title: 'Identity & Credit Alert Subscriptions',
      description: 'Set up your identity and credit record activity alerts',
      icon: IdentityCreditAlertIcon,
      altIcon: 'Alerts',
      btnText: 'See your alerts',
      activateMonitorTab: true,
      enrolledBtnText: 'Check your alerts',
      enrolled: activeCount > 0,
      enrolledText: 'Enrolled',
      enrolledDescription: `${activeCount} of 3 subscriptions active`,
      notEnrolledText: '0 of 3 Active',
      gaCategory: 'id subscriptions',
    },
    {
      title: '$1M Identity Theft Insurance',
      description:
        'Protect your identity with identity theft insurance and restoration services',
      icon: OneMIcon,
      altIcon: 'Theft Insurance protection',
      btnText: 'Protect your identity',
      activateMonitorTab: true,
      enrolledBtnText: 'Learn more',
      enrolled: !!dataSet.identity_management,
      enrolledText: 'Enrolled',
      enrolledDescription:
        'You’re opted in to $1M Identity Theft Insurance and Restoration',
      notEnrolledText: 'Not Enrolled',
      gaCategory: 'id insurance',
    },
    {
      title: 'Dark Web Scan',
      description:
        'See if your information may have been leaked in a data breach',
      icon: DarkWebScanIcon,
      altIcon: 'Dark Web Scan',
      btnText: 'Scan your email',
      activateMonitorTab: false,
      enrolledBtnText: 'Scan your email again',
      enrolled: !!darkWebUpdatedAt,
      navigateTo: '/dashboard/dark-web',
      enrolledText: `Scanned on ${parsedDate}`,
      enrolledDescription:
        'See if your information may have been leaked in a data breach',
      notEnrolledText: 'Not Scanned',
      gaCategory: 'dark web scan',
    },
    {
      title: 'Credit Score Tracker',
      description: 'Keep track of your monthly credit score.',
      icon: CreditScoreTrackerIcon,
      altIcon: 'Credit Score Tracker',
      btnText: 'Start tracking your score',
      activateMonitorTab: true,
      enrolledBtnText: "Track your score's changes",
      enrolled: isEnrolled,
      enrolledDescription:
        'Your financial institutions regularly update your credit record',
      enrolledText: 'Enrolled',
      notEnrolledText: 'Not Enrolled',
      gaCategory: 'credit tracker',
    },
    {
      title: 'Credit Score Analysis',
      description:
        'Run a simulation or analysis before your next financial decision',
      icon: CreditScoreAnalysisIcon,
      altIcon: 'Credit Score Analysis',
      btnText: 'Run simulations & analysis',
      activateMonitorTab: true,
      enrolledBtnText: 'Run simulations & analysis',
      enrolled: isEnrolled,
      enrolledText: 'Enrolled',
      enrolledDescription:
        'Run a simulation or analysis before your next financial decision',
      notEnrolledText: 'Not Enrolled',
      gaCategory: 'credit analysis',
    },
    {
      title: 'Unclaimed Money',
      description: 'See if you may be owed unclaimed money',
      icon: UnclaimedMoneyIcon,
      altIcon: 'Unclaimed Money',
      btnText: 'Check unclaimed money',
      activateMonitorTab: false,
      enrolledBtnText: 'Check unclaimed money',
      navigateTo: '/dashboard/unclaimed-money',
      gaCategory: 'unclaimed money',
    },
  ];
  return settings;
};
