import {
  Box,
  Button,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Divider,
  Text,
  Drawer,
  Skeleton,
  styled,
  useTheme
} from '@ltvco/refresh-lib/theme';
import { useBumpeeChat } from 'components/Language/hooks/useBumpeeChat';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { flagEmoji } from 'components/Language/flagEmoji';
import imgBevProfile from '../../../../images/img-bev-profile.svg';
import { Feedback } from 'components/Language/Feedback/Feedback';
import { FeedbackModal } from 'components/Language/FeedbackModal/FeedbackModal';
import {
  languageID,
  levelID,
  topicID,
  translations,
} from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { AssignmentDrawer } from './AssignmentDrawer/AssignmentDrawer';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { ChevronLeft, MoreHoriz, DeleteOutlined, ThumbUpAltOutlined, List } from '@mui/icons-material';

const StyledMobileDrawer = styled(Drawer)({
  '.MuiPaper-root': {
    paddingTop: '20px',
    paddingBottom: '50px',
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px',
  },
});

interface ChatHeaderProps {
  assignmentTopic?: string;
  assignments?: {
    id: number;
    assignmentType: string;
    title: string;
    status?: string;
  }[];
  activeAssignmentId?: number;
  redirectToAssignment?: (assignmentId: number) => void;
  isQuizChat?: boolean;
  isLessonChat?: boolean;
  isStoryChat?: boolean;
}

export function ChatHeader({
  assignmentTopic,
  assignments,
  activeAssignmentId,
  redirectToAssignment,
  isQuizChat = false,
  isLessonChat = false,
  isStoryChat = false,
}: ChatHeaderProps) {
  const theme = useTheme();
  const chatId = Number(useParams().chatId);
  const redirect = useNavigate();
  const { data: chat, isLoading } = useBumpeeChat(chatId);
  const { isMobile, isMedium } = useScreenSize();
  const { deleteChatMutation } = useBumpeeChat(chatId);
  const { isDesktop } = useScreenSize();
  const [feedbackModalStatus, setFeedbackModalStatus] = useState(false);
  const { language } = useLanguageContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [showLessonDrawer, setShowLessonDrawer] = useState(false);
  const toggleLessonDrawer = () => setShowLessonDrawer(!showLessonDrawer);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFeedbackModalOpen = () => {
    setFeedbackModalStatus(true);
  };
  const handleFeedbackModalClose = () => {
    setFeedbackModalStatus(false);
  };

  const levelId = levelID(chat?.level.toLowerCase() || '');
  const languageId = languageID(chat?.learning_language.toLowerCase() || '');
  const topicId = topicID(chat?.topic.toLowerCase() || '');

  const chatSecondaryString = (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Text variant="body2" color="primary.light">
        {
          translations.levels[language].find((level) => level.id === levelId)
            ?.name
        }
        {' | '}
        {
          translations.languages[language].find(
            (language) => language.id === languageId
          )?.name
        }
      </Text>
      <Text variant="body2" color="primary.light">
        <Text fontSize="20px" ml={1}>
          {flagEmoji(languageID(chat?.learning_language.toLowerCase() || ''))}
        </Text>
      </Text>
    </Box>
  );

  const handleBackClick = () => {
    redirect('/dashboard/language');
  };

  const handleDeleteClick = () => {
    deleteChatMutation.mutate(chatId);
    redirect('/dashboard/language');
    handleClose();
  };

  const handleLessonChange = () => {
    handleClose();
    toggleLessonDrawer();
  };

  const displayTopicName = () => {
    const possibleHeaders: { [key: string]: string } = {
      lesson: translations.dashboard.assignments.lesson.header[language],
      quiz: translations.dashboard.assignments.quiz.header[language],
      story: translations.dashboard.assignments.story.header[language],
    };
    if (assignmentTopic) {
      return (
        assignments &&
        assignments[0]?.assignmentType &&
        possibleHeaders[assignments[0]?.assignmentType]
      );
    }
    return translations.topics[language].find((topic) => topic.id === topicId)
      ?.name;
  };

  const shouldShowSkeleton = isLoading && !assignmentTopic;
  const inLessonChat = assignmentTopic ? true : false;

  const showElipsisMore = () => {
    if (!inLessonChat) {
      return true;
    }

    if (inLessonChat && isMedium) {
      return true;
    }

    return false;
  };

  return (
    <Box>
      <Box
        p={isMobile ? 0 : 2}
        pt={{ xs: 0, md: 1.5 }}
        pb={1.5}
        mb={{ xs: 0.5, md: 0 }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Button onClick={handleBackClick} sx={{ p: 0 }}>
            <ChevronLeft sx={{ mr: 1, color: theme.palette.primary.main }} />
            <img src={imgBevProfile} />
            {!isMobile && (
              <Text variant="h3" mb={0} p={1}>
                Beverly
              </Text>
            )}
          </Button>
        </Box>
        <Stack alignItems="center">
          <Text variant="h5" mb={0.5}>
            {shouldShowSkeleton ? (
              <Skeleton variant="text" width={100} />
            ) : (
              displayTopicName()
            )}
          </Text>
          <Text>
            {shouldShowSkeleton ? (
              <Skeleton variant="text" width={200} />
            ) : (
              assignmentTopic || chatSecondaryString
            )}
          </Text>
        </Stack>
        <Box>
          {shouldShowSkeleton ? (
            <Skeleton variant="text" width={64} height={40} />
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center">
              {isDesktop && <Feedback />}
              {showElipsisMore() && (
                <IconButton id="chat-settings" onClick={handleClick}>
                  <MoreHoriz sx={{ color: theme.palette.primary.main }} />
                </IconButton>
              )}
            </Stack>
          )}

          {isMobile ? (
            <StyledMobileDrawer
              open={open}
              anchor="bottom"
              onClose={handleClose}
            >
              {inLessonChat ? (
                <MenuItem onClick={handleLessonChange}>
                  <List sx={{ color: theme.palette.primary.main }} />
                  {isQuizChat && (
                    <Text ml={1}>
                      {translations.buttons.changeQuiz[language]}
                    </Text>
                  )}
                  {isLessonChat && (
                    <Text ml={1}>
                      {translations.buttons.changeLesson[language]}
                    </Text>
                  )}
                  {isStoryChat && (
                    <Text ml={1}>
                      {translations.buttons.changeStory[language]}
                    </Text>
                  )}
                </MenuItem>
              ) : (
                <MenuItem onClick={handleDeleteClick}>
                  <DeleteOutlined sx={{ color: theme.palette.primary.main }} />
                  <Text ml={1}>{translations.buttons.remove[language]}</Text>
                </MenuItem>
              )}
              <MenuItem onClick={handleFeedbackModalOpen}>
                <ThumbUpAltOutlined sx={{ color: theme.palette.primary.main }} />
                <Text ml={1}>
                  {translations.dashboard.giveFeedback.title[language]}
                </Text>
              </MenuItem>
            </StyledMobileDrawer>
          ) : (
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
              {!inLessonChat && (
                <MenuItem onClick={handleDeleteClick}>
                  <DeleteOutlined sx={{ color: theme.palette.primary.main }} />
                  <Text ml={1}>{translations.buttons.remove[language]}</Text>
                </MenuItem>
              )}
              {!isDesktop && (
                <MenuItem onClick={handleFeedbackModalOpen}>
                  <ThumbUpAltOutlined sx={{ color: theme.palette.primary.main }} />
                  <Text ml={1}>
                    {translations.dashboard.giveFeedback.title[language]}
                  </Text>
                </MenuItem>
              )}
            </Menu>
          )}
        </Box>
      </Box>
      <Divider sx={{ ml: { xs: -2, sm: 0 }, mr: { xs: -2, md: -1.5 } }} />
      <FeedbackModal
        open={feedbackModalStatus}
        handleClose={handleFeedbackModalClose}
      />
      {isMobile && Boolean(assignments?.length) && (
        <Drawer
          anchor={'bottom'}
          open={showLessonDrawer}
          onClose={toggleLessonDrawer}
        >
          <AssignmentDrawer
            assignments={assignments!}
            activeAssignmentId={activeAssignmentId!}
            toggleDrawer={toggleLessonDrawer}
            redirectToLesson={redirectToAssignment}
            isQuizChat={isQuizChat}
            isLessonChat={isLessonChat}
            isStoryChat={isStoryChat}
          />
        </Drawer>
      )}
    </Box>
  );
}
