import { Stack, styled } from '@ltvco/refresh-lib/theme';

export const AstrologyErrorContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(4.5),
  width: 'fit-content',
  margin: 'auto',

  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-start',
  },
}));
