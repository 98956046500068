import ascendantImg from '../../../images/ast-ascendant.svg';
import { useContext } from 'react';
import { Stack } from '@ltvco/refresh-lib/theme';
import { ResetButton } from '../components';
import { MenuOptions } from '../constants';
import { type MenuSelectOptionProps, type UserProps } from '../interfaces';
import { MenuSelect } from './MenuSelect/MenuSelect';
import { UserInfo } from './UserInfo/UserInfo';
import { AstrologyIcon } from './AstrologyIcon/AstrologyIcon';
import { CompatibilityHeader } from './Compatibility/CompatibilityHeader';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Settings } from '@mui/icons-material';

interface HeaderProps {
  menuOptions: { [key: string]: MenuSelectOptionProps };
  astrologyUser: UserProps | null;
  activeHeaderMenuOption: MenuSelectOptionProps;
  setActiveHeaderMenuOption: (option: MenuSelectOptionProps) => void;
  resetAstrologyUser: () => void;
  zodiacSign: string;
  compatibilitySign: string;
  setCompatibilitySign: (sign: string) => void;
  lifepathNumber: string;
  personalityNumber: string;
  ascendantSign?: string;
  disableResetCta?: boolean;
}

export const AstrologyHeader = ({
  menuOptions,
  astrologyUser,
  activeHeaderMenuOption,
  setActiveHeaderMenuOption,
  resetAstrologyUser,
  zodiacSign,
  compatibilitySign,
  setCompatibilitySign,
  lifepathNumber,
  personalityNumber,
  ascendantSign,
  disableResetCta,
}: HeaderProps) => {
  const { trackEvent } = useContext(AppConfig);
  const isCompatibilityActive =
    activeHeaderMenuOption.value === MenuOptions.compatibility.value;

  const isLifepathActive =
    activeHeaderMenuOption.value === MenuOptions.lifepath.value;
  const isPersonalityActive =
    activeHeaderMenuOption.value === MenuOptions.personality.value;
  const isNumerologyActive = isLifepathActive || isPersonalityActive;
  const isAscendantActive =
    activeHeaderMenuOption.value === MenuOptions.ascendant.value;

  const isOtherActive =
    !isCompatibilityActive && !isNumerologyActive && !isAscendantActive;

  const activeNumerologyNumber = isPersonalityActive
    ? personalityNumber
    : lifepathNumber;

  function localResetAstrologyUser() {
    trackEvent('feature reset', 'engagement click', 'astrology');
    resetAstrologyUser();
  }

  return (
    <>
      <Stack
        zIndex="1"
        direction="row"
        justifyContent="space-between"
        gap={1}
        minHeight={40}
      >
        {!disableResetCta && (
          <ResetButton
            startIcon={<Settings />}
            onClick={localResetAstrologyUser}
          >
            Reset
          </ResetButton>
        )}
        <MenuSelect
          variant={disableResetCta ? 'solid' : ''}
          enabledMenuOptions={menuOptions}
          onChangeCallback={setActiveHeaderMenuOption}
          value={activeHeaderMenuOption.value}
        />
      </Stack>
      {isOtherActive && (
        <Stack justifyContent="space-between" direction="row" mt={2} zIndex="1">
          <UserInfo
            title={activeHeaderMenuOption.category}
            name={astrologyUser?.name || ''}
            dob={astrologyUser?.dob || ''}
          />
          <AstrologyIcon sign={zodiacSign} />
        </Stack>
      )}
      {isNumerologyActive && (
        <Stack justifyContent="space-between" direction="row" mt={2} zIndex="1">
          <UserInfo
            title={activeHeaderMenuOption.category}
            name={astrologyUser?.name || ''}
            dob={astrologyUser?.dob || ''}
          />
          <AstrologyIcon
            bottomText={activeHeaderMenuOption.display}
            number={activeNumerologyNumber}
          />
        </Stack>
      )}
      {isCompatibilityActive && (
        <CompatibilityHeader
          value={zodiacSign}
          selectValue={compatibilitySign}
          onChangeCallback={(sign) => setCompatibilitySign(sign)}
        />
      )}
      {isAscendantActive && (
        <Stack justifyContent="space-between" direction="row" mt={2} zIndex="1">
          <UserInfo
            title={activeHeaderMenuOption.category}
            name={astrologyUser?.name || ''}
            dob={astrologyUser?.dob || ''}
          />
          {ascendantSign ? (
            <AstrologyIcon sign={ascendantSign} bottomText="Ascendant" />
          ) : (
            <AstrologyIcon image={ascendantImg} />
          )}
        </Stack>
      )}
    </>
  );
};
