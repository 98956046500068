import { useQuery } from '@ltvco/refresh-lib/vendors';
import { enqueueSnackbar, useServiceToken } from '@ltvco/refresh-lib/v1';
import { BumpeeRequests } from 'api';

const baseQueryKey = 'bumpeeGO';

export const useBumpeeStoryChat = (storyChatId: string | undefined) => {
  const { data: tokenData } = useServiceToken('bumpee');

  const results = useQuery({
    queryKey: [`${baseQueryKey}-story-chat-${storyChatId}`],
    queryFn: () => BumpeeRequests.getStoryChat(tokenData.token, storyChatId!),
    enabled: Boolean(tokenData) && Boolean(storyChatId),
    onError: (error) => {
      enqueueSnackbar('Failed to get story', { variant: 'error' });
    },
  });

  return { ...results };
};
