import { Text, Stack } from '@ltvco/refresh-lib/theme';
import {
  MainContainer,
  HeaderContainer,
  UserInfo,
  AstrologyIcon,
  AstrologyHoroscope,
  type ZodiacSignProps,
  type UserProps,
} from 'components/Astrology';
import { AstrologyStandaloneHeader } from '../components';
import {
  ResetAstrologyUserButton,
  StyledBox,
  StyledContentBox,
} from './components';

interface AstrologyStandaloneHoroscopeProps {
  astrologyUser?: UserProps | null;
  zodiacSign?: string;
  zodiacSignData?: ZodiacSignProps | undefined;
  yesterdayContent: object;
  todayContent: string;
  tomorrowContent: object;
  monthlyContent: string[];
  resetAstrologyUser: () => void;
}
export const AstrologyStandaloneHoroscope = ({
  astrologyUser,
  zodiacSign,
  zodiacSignData,
  yesterdayContent,
  todayContent,
  tomorrowContent,
  monthlyContent,
  resetAstrologyUser,
}: AstrologyStandaloneHoroscopeProps) => {
  return (
    <MainContainer p={0} sx={{ borderRadius: '8px' }}>
      <HeaderContainer
        p={2.5}
        pt={2.75}
        sx={{
          '&::before, &::after': {
            borderRadius: '8px',
          },
        }}
      >
        <ResetAstrologyUserButton onClick={resetAstrologyUser} />
        <Stack justifyContent="space-between" direction="row" mt={2} zIndex="1">
          <UserInfo
            title={<AstrologyStandaloneHeader />}
            name={astrologyUser?.name || ''}
            dob={astrologyUser?.dob || ''}
            standaloneVersion={true}
          />
          <AstrologyIcon sign={zodiacSign} />
        </Stack>
      </HeaderContainer>
      <StyledBox padding={2.5} pt={1} sx={{ borderRadius: '8px' }}>
        <Text mb={'23px'} ml={'20px'} mr={'20px'}>
          {zodiacSignData?.summary}
        </Text>
        <StyledContentBox>
          <h2 style={{ paddingLeft: '20px' }}>Your Horoscope</h2>
          <AstrologyHoroscope
            yesterdayContent={yesterdayContent}
            todayContent={todayContent}
            tomorrowContent={tomorrowContent}
            monthlyContent={monthlyContent}
            standaloneVersion={true}
          />
        </StyledContentBox>
      </StyledBox>
    </MainContainer>
  );
};
