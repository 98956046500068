import {
  Button,
  Input,
  Stack,
  Text,
  Select,
  type SelectOption,
} from '@ltvco/refresh-lib/theme';
import {
  addFormText,
  formPlaceHolder,
  maxMonitors,
  socialMediaNetworkOptions,
} from './utils';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { VehicleReportTabsForm } from './VehicleReportTabsForm';
import {
  enqueueSnackbar,
  RapidsInput,
  RawReportType,
  useClaimingSuccess,
  useClaimReportMutation,
  useReportMonitors,
} from '@ltvco/refresh-lib/v1';
import { formatPhone, rawToValidReportType, unformatPhone, validationsByReportType, ValidReportTypes } from '@ltvco/refresh-lib/utils';
import { Close, AddCircle } from '@mui/icons-material';

export type CustomResponseData = {
  permalink: string;
};

type FormData = {
  [key in ValidReportTypes]: string;
};

type PropertyFormData = Record<string, string>;

export const AddReportMonitorForm: React.FC<{
  defaultOpen: boolean;
  rawReportType: RawReportType;
  navigateToReport: (permalink: string) => void;
}> = ({ rawReportType, defaultOpen, navigateToReport }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [hasShowSnackbar, setHasShowSnackbar] = useState(false);
  const { currentMonitors } = useReportMonitors();

  useEffect(() => {
    setIsFormOpen(defaultOpen);
  }, [defaultOpen]);

  const validReportType = rawToValidReportType(rawReportType);

  const yupObject = validationsByReportType[rawReportType as RawReportType];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData & PropertyFormData>({
    resolver: yupResolver(yupObject),
  });

  const isUsernameReport = rawReportType === 'username_report';
  const inputContainerAlignItems = [
    'reverse_phone_report',
    'social_network_report',
  ].includes(rawReportType)
    ? 'center'
    : 'flex-start';

  const hasError = !!errors?.[validReportType];

  const onReportSuccess = useClaimingSuccess();

  const claimReportMutation = useClaimReportMutation();

  const onSubmit: SubmitHandler<FormData & PropertyFormData> = async (data) => {
    setHasShowSnackbar(false);
    if (validReportType === 'phone' && data[validReportType]) {
      const unformattedPhone = unformatPhone(data[validReportType] ?? '');
      const newData = { phone_number: unformattedPhone };
      claimReportMutation.mutate({
        reportType: validReportType,
        input: newData,
      });
      return;
    }
    await claimReportMutation.mutate({
      reportType: validReportType,
      input: data,
    });
    setIsFormOpen(false);
  };

  useEffect(() => {
    if (hasShowSnackbar) return;
    displaySnackbar();
  }, [claimReportMutation]);

  const displaySnackbar = () => {
    if (claimReportMutation.isSuccess) {
      const monitorsLength = currentMonitors.length || 0;
      const remainingMonitors = maxMonitors - monitorsLength;
      enqueueSnackbar(
        `Monitor was enabled. You have ${remainingMonitors} monitors remaining.`,
        {
          variant: 'success',
        }
      );
      setHasShowSnackbar(true);
    }
  };

  const handlePropertyFormSubmit = (data: PropertyFormData) => {
    const address = `${data['street_line']} ${data['secondary']}`.trim();

    data['address'] = address;

    setValue('address', address);
    setValue('city', data['city']);
    setValue('state', data['state']);

    onSubmit(data as FormData);
  };

  const closeIcon = ({ margins }: { margins?: boolean }) => {
    return (
      !defaultOpen && (
        <Close
          onClick={() => setIsFormOpen(false)}
          sx={{
            cursor: 'pointer',
            ...(margins && { ml: [1.5], mt: [0, 0.75] }),
          }}
        />
      )
    );
  };

  const addButton = () => (
    <Button
      variant="contained"
      color="success"
      startIcon={<AddCircle />}
      onClick={handleSubmit(onSubmit)}
      sx={{
        ...(!isUsernameReport && { marginX: [1.5] }),
        paddingX: [2.75],
        height: '40px',
      }}
    >
      Add
    </Button>
  );

  const renderInputField = () => {
    if (rawReportType === 'reverse_phone_report') {
      return (
        <>
          <Input
            {...register(validReportType, {
              required: true,
              onChange: (e) => {
                const phone = unformatPhone(e.target.value);
                setValue(validReportType, formatPhone(phone));
              },
            })}
            sx={{ width: '100%' }}
            placeholder={formPlaceHolder[rawReportType as RawReportType]}
            error={hasError}
            type="tel"
            inputProps={{ maxLength: 14 }}
          />
          {addButton()}
          {closeIcon({})}
        </>
      );
    } else if (rawReportType === 'vehicle_report') {
      return (
        <VehicleReportTabsForm
          closeIcon={closeIcon}
          onReportSuccess={onReportSuccess}
        />
      );
    } else if (rawReportType === 'property_report') {
      return (
        <>
          <RapidsInput
            parentFormSubmitHandler={(data) =>
              handlePropertyFormSubmit(data as unknown as PropertyFormData)
            }
            buttonText="Add"
            inputOptions={{
              inputWidth: { md: '80%' },
              customPx: 0,
              hideInputLabel: true,
              customSpacing: 0,
              rowGap: 1,
              alignment: 'space-between',
              buttonStartIcon: <AddCircle />,
            }}
          />
          {closeIcon({ margins: true })}
        </>
      );
    } else if (isUsernameReport) {
      return (
        <>
          <Stack direction={['column', 'row']} gap={1.5} width="100%">
            <Input
              {...register(validReportType, {
                required: true,
              })}
              fullWidth
              placeholder={formPlaceHolder[rawReportType as RawReportType]}
              error={hasError}
              helperText={errors[validReportType]?.message}
              type="text"
            />
            <Select
              id="social-media-network-select"
              sx={{ width: ['100%', '50%'] }}
              options={socialMediaNetworkOptions() as SelectOption[]}
              defaultValue="all"
            />
            {addButton()}
          </Stack>
          {closeIcon({ margins: true })}
        </>
      );
    } else {
      return (
        <>
          <Input
            {...register(validReportType, {
              required: true,
            })}
            sx={{ width: '100%' }}
            placeholder={formPlaceHolder[rawReportType as RawReportType]}
            error={hasError}
            type="text"
          />
          {addButton()}
          {closeIcon({})}
        </>
      );
    }
  };

  const form = () => {
    return (
      <>
        <Stack direction="column">
          <Stack
            direction="row"
            alignItems={['center', inputContainerAlignItems]}
          >
            {renderInputField()}
          </Stack>
          {hasError && !isUsernameReport && (
            <Text ml={1} mt={0.5} color={'error'}>
              {errors[validReportType]?.message}
            </Text>
          )}
        </Stack>
      </>
    );
  };

  return (
    <>
      {isFormOpen ? (
        form()
      ) : (
        <Button
          color="success"
          startIcon={<AddCircle />}
          sx={{
            justifyContent: 'flex-start',
            textDecoration: 'underline',
          }}
          onClick={() => setIsFormOpen(true)}
        >
          Add another {addFormText[rawReportType as RawReportType]}
        </Button>
      )}
    </>
  );
};
