import { useQuery } from '@ltvco/refresh-lib/vendors';
import { useServiceToken } from '@ltvco/refresh-lib/v1';
import { BumpeeRequests } from 'api';

const baseQueryKey = 'bumpeeGO';

export function useBumpeeChats() {
  const { data: tokenData } = useServiceToken('bumpee');

  const results = useQuery({
    queryKey: [`${baseQueryKey}-chats`],
    queryFn: () => BumpeeRequests.getChats(tokenData?.token),
    enabled: Boolean(tokenData?.token),
  });

  return { ...results };
}
