import {
  Stack,
  Box,
  Button,
  Card,
} from '@ltvco/refresh-lib/theme';
import { useEffect, useRef, useState, KeyboardEvent } from 'react';
import { ChatBubble } from '../ChatBubble/ChatBubble';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useBumpeeMessages } from 'components/Language/hooks/useBumpeeMessages';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useBumpeeHint } from 'components/Language/hooks/useBumpeeHint';
import {
  CircleIcon,
  MicrophoneButton,
  StyledButton,
  StyledInput,
  StyledInputCard,
} from './components';
import { languageNameToIetfLanguageCode } from './constants';
import { InView } from 'react-intersection-observer';
import { HintsCard } from './HintsCard/HintsCard';
import { translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { TranslatorModal } from 'components/Language/TranslatorModal/TranslatorModal';
import LessonCompleted from './LessonCompleted/LessonCompleted';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { useBumpeeChat } from 'components/Language/hooks/useBumpeeChat';
import { AssignmentIntro } from './AssignmentIntro/AssignmentIntro';
import { QuizCompleted } from './QuizCompleted/QuizCompleted';
import { Message, StoryChatConversation } from 'api';
import { ChatTextLoading } from '@ltvco/refresh-lib/v1';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { useQueryClient } from '@ltvco/refresh-lib/vendors';
import { StopCircle, Mic, ArrowForward } from '@mui/icons-material';

interface CurrentChatProps {
  externalChatId?: number;
  lessonChatId?: number;
  isLessonChat?: boolean;
  lessonCompleted?: boolean;
  shouldCompleteLesson?: boolean;
  completeLesson?: () => void;
  isQuizChat?: boolean;
  quizChatId?: number;
  assignmentTopic?: string;
  currentQuestionId?: number;
  quizCompleted?: boolean;
  isStoryChat?: boolean;
  storyChatId?: number;
  disableWordTranslations?: boolean;
  conversation?: StoryChatConversation;
}

export function CurrentChat({
  externalChatId,
  lessonChatId,
  isLessonChat = false,
  isQuizChat = false,
  lessonCompleted = false,
  completeLesson,
  shouldCompleteLesson,
  assignmentTopic,
  quizChatId,
  currentQuestionId,
  quizCompleted,
  disableWordTranslations,
  isStoryChat = false,
  storyChatId,
  conversation,
}: CurrentChatProps) {
  const { language } = useLanguageContext();
  const redirect = useNavigate();
  const { isMobile } = useScreenSize();
  const { register, handleSubmit, reset, setValue, getValues } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const location = useLocation();
  const queryClient = useQueryClient();

  const isAssignmentChat = isLessonChat || isQuizChat || isStoryChat;

  // Refs
  const chatContainerRef = useRef<HTMLDivElement>(null);

  // States
  const [playingAudioId, setPlayingAudioId] = useState<number | null>(null);
  const [beginAssignmentClicked, setBeginAssignmentClicked] = useState(false);
  const [fetchNextPage, setFetchNextPage] = useState(false);
  const [lastMessageId, setLastMessageId] = useState(0);
  const [previousLastMessageId, setPreviousLastMessageId] = useState(0);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [previousScrollHeight, setPreviousScrollHeight] = useState(0);
  const [previousScrollTop, setPreviousScrollTop] = useState(0);
  const [isHintOpen, setIsHintOpen] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
  const [languageCode, setLanguageCode] = useState<string>('en-US');
  const [messageBeforeListening, setMessageBeforeListening] =
    useState<string>('');
  const { transcript, listening } = useSpeechRecognition();
  const [showFurtherContent, setShowFurtherContent] = useState(false);
  // Data
  const paramsChatId = useParams().chatId;
  const chatId = externalChatId || Number(paramsChatId);
  const { data: chat } = useBumpeeChat(chatId);

  const {
    data: messagesResponse,
    isLoading,
    isError,
    createMessageMutation,
    shouldFetchNextPage,
    isFetched,
    isFetching,
    hasNextPage,
  } = useBumpeeMessages(
    chatId,
    lessonChatId,
    shouldCompleteLesson,
    quizChatId,
    storyChatId
  );

  const allMessages = messagesResponse?.pages
    ?.flatMap((page: { messages: any; }) => page.messages)
    .reverse();

  const findLastAssistantMessageId = () => {
    const filteredMessages = allMessages?.filter(
      (message: { role: string; }) => message.role === 'assistant'
    );
    return filteredMessages?.[filteredMessages?.length - 1]?.id || 0;
  };

  const newMessageLength = allMessages?.length || 0;

  const { fetchHint, hintData, clearHints } = useBumpeeHint(
    chatId,
    findLastAssistantMessageId()
  );

  useEffect(() => {
    const assignmentChat = isLessonChat || isQuizChat || isStoryChat;

    if (!assignmentChat) {
      setShowFurtherContent(true);
      return;
    }

    if (
      (assignmentChat && beginAssignmentClicked) ||
      (allMessages && allMessages?.length > 2)
    ) {
      if (beginAssignmentClicked === false) setBeginAssignmentClicked(true);
      setShowFurtherContent(true);
      return;
    }

    setShowFurtherContent(false);
  }, [
    isLessonChat,
    isQuizChat,
    isStoryChat,
    allMessages,
    beginAssignmentClicked,
  ]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['bumpeeGO-story-chat-audio'],
      exact: false,
    });
  }, [location]);

  const isLessonCompleted = async () => {
    if (!isLessonChat) return;
    if (isLessonChat && lessonCompleted) return true;
    // random number between 3 and 8
    const randomNumber = Math.floor(Math.random() * 6) + 3;

    if (isLessonChat && allMessages) {
      const userMessages = allMessages.filter(
        (message: { role: string; }) => message.role === 'user'
      );
      if (userMessages.length > randomNumber) {
        completeLesson && completeLesson();
      }
    }
  };

  useEffect(() => {
    setBeginAssignmentClicked(false);
  }, [lessonChatId, quizChatId]);

  useEffect(() => {
    if (lessonCompleted) return;
    isLessonCompleted();
  }, [allMessages]);

  useEffect(() => {
    if (isError) {
      redirect('/dashboard/Language');
    }
  }, [isError]);

  useEffect(() => {
    if (!hasScrolledToBottom && chatContainerRef.current) {
      setPreviousScrollHeight(chatContainerRef.current.scrollHeight);
      setPreviousScrollTop(chatContainerRef.current.scrollTop);
    }
  }, [allMessages]);

  useEffect(() => {
    // Don't trigger this when user sends a message or the ai responds
    if (
      (isNewMessage ||
        lastMessageId === 0 ||
        lastMessageId === previousLastMessageId) &&
      !fetchNextPage
    )
      return;
    if (chatContainerRef.current) {
      // Save the current scroll positions before new data loads
      setPreviousScrollHeight(chatContainerRef.current.scrollHeight);
      setPreviousScrollTop(chatContainerRef.current.scrollTop);
    }

    const handleNewMessages = () => {
      if (chatContainerRef.current) {
        // Adjust the scroll position after new data is loaded
        const currentScrollHeight = chatContainerRef.current.scrollHeight;
        const scrollHeightDifference =
          currentScrollHeight - previousScrollHeight;
        chatContainerRef.current.scrollTop =
          previousScrollTop + scrollHeightDifference;
      }
    };

    if (newMessageLength > 0) {
      handleNewMessages();
      setFetchNextPage(false);
    }
  }, [newMessageLength]);

  // Scroll to bottom when hint is opened
  useEffect(() => {
    if (hintData.hints.length > 0 || isHintOpen) {
      setTimeout(() => {
        chatContainerRef.current?.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 100);
    }
  }, [hintData.hints, isHintOpen]);

  // UseEffect
  useEffect(() => {
    if (hasScrolledToBottom) return;
    setLastMessageId(messagesResponse?.pages[0]?.messages[0]?.id || 0);
    if (isFetched) {
      setTimeout(() => {
        if (!chatContainerRef.current) return;
        const scrollHeight = chatContainerRef?.current?.scrollHeight;
        chatContainerRef.current.scrollTop = scrollHeight;
        setHasScrolledToBottom(true);
      }, 500);
    }
  }, [isFetched, hasScrolledToBottom]);

  // When chatId changes, close the hint
  useEffect(() => {
    setIsHintOpen(false);
  }, [chatId]);

  // Ensure that the voice recognition is listening for the correct target language

  useEffect(() => {
    const learningLanguage = chat?.learning_language;
    if (learningLanguage) {
      setLanguageCode(languageNameToIetfLanguageCode[learningLanguage]);
    }
  }, [chat?.learning_language]);

  // Write to the text input

  useEffect(() => {
    if (listening) {
      const { currentMessage } = getValues();
      setMessageBeforeListening(currentMessage);
    } else {
      setMessageBeforeListening(
        `${[messageBeforeListening, transcript].join(' ')}`
      );
    }
  }, [listening]);

  useEffect(() => {
    if (!transcript) return;
    setValue(
      'currentMessage',
      `${[messageBeforeListening, transcript].join(' ')}`
    );
  }, [transcript]);

  // Functions

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 300);
    setIsNewMessage(false);
    setPreviousLastMessageId(lastMessageId);
  };

  const handleGenerateHintClick = () => {
    if (isHintOpen) {
      clearHints();
      setIsHintOpen(false);
    } else {
      setIsHintOpen(true);
      fetchHint();
    }
  };

  const handleTranslatorClick = () => {
    setIsTranslationModalOpen(true);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      handleSubmit(addMessage as SubmitHandler<FieldValues>)();
    }
  };

  const addMessage = (value: { currentMessage: string }) => {
    clearHints();
    setIsHintOpen(false);
    setIsNewMessage(true);
    scrollToBottom();
    createMessageMutation.mutate(
      {
        chat_id: chatId,
        content: value.currentMessage.trim(),
        currentQuestionId,
      },
      {
        onSuccess: () => {
          reset();
          setTimeout(() => {
            scrollToBottom();
          }, 200);
        },
      }
    );
  };

  const handleInView = () => {
    shouldFetchNextPage();
    setFetchNextPage(true);
  };

  const handleHintClick = (hintText: string) => {
    setValue('currentMessage', hintText);
  };

  const handleMicrophoneStart = () => {
    SpeechRecognition.startListening({
      language: languageCode,
    });
  };

  const handleMicrophoneStop = () => {
    SpeechRecognition.stopListening();
  };

  const handleMicrophoneButtonClick = () => {
    listening ? handleMicrophoneStop() : handleMicrophoneStart();
  };

  const awaitingBumpee =
    allMessages && allMessages[newMessageLength - 1]?.role !== 'assistant';

  const getSegmentIndex = (messageIndex: number) => {
    return isStoryChat
      ? allMessages
          ?.map((_: any, index: any) => index)
          .filter((_: any, index: number) => (index - 1) % 3 === 0)
          .indexOf(messageIndex)
      : undefined;
  };

  return (
    <Stack
      position="relative"
      height="100%"
      ml={{ xs: 0, md: 1 }}
      sx={{ overflowY: 'auto' }}
    >
      {isTranslationModalOpen && (
        <TranslatorModal
          setTranslatorModalOpen={setIsTranslationModalOpen}
          chatId={chatId}
        />
      )}
      <Stack
        ref={chatContainerRef}
        sx={{
          overflowX: 'hidden',
          overflowY: 'auto',
          height: '100%',
          maxHeight: isMobile ? '100vh' : '80vh',
        }}
      >
        {(isLoading || isFetching) && (
          <Card
            variant="outlined"
            sx={{
              margin: 2,
              width: 'fit-content',
              overflow: 'visible',
            }}
          >
            <Box display="flex">
              <ChatTextLoading />
            </Box>
          </Card>
        )}
        {allMessages &&
          newMessageLength > 0 &&
          !isLoading &&
          hasNextPage &&
          hasScrolledToBottom && (
            <InView
              as="div"
              threshold={1}
              onChange={(inView) => {
                if (inView) {
                  handleInView();
                }
              }}
            />
          )}
        <Stack mb={10}>
          {allMessages?.map((message: Message, index: number) => {
            if ((isLessonChat || isQuizChat || isStoryChat) && index === 0) {
              return (
                <AssignmentIntro
                  key={`Intro-${message.id}`}
                  message={message}
                  beginAssignmentClicked={beginAssignmentClicked}
                  setBeginAssignmentClicked={setBeginAssignmentClicked}
                  isQuizChat={isQuizChat}
                  isStoryChat={isStoryChat}
                  assignmentTopic={assignmentTopic}
                />
              );
            }
            if (
              index === allMessages.length - 1 &&
              isQuizChat &&
              quizCompleted
            ) {
              return (
                <QuizCompleted key={`Intro-${message.id}`} message={message} />
              );
            }
            if (showFurtherContent) {
              return (
                <ChatBubble
                  key={`message-${message.id}`}
                  message={message}
                  isQuizChat={isQuizChat}
                  isLessonChat={isLessonChat}
                  isStoryChat={isStoryChat}
                  segmentIndex={getSegmentIndex(index)}
                  storyChatId={storyChatId}
                  disableWordTranslations={disableWordTranslations}
                  conversation={conversation}
                  playingAudioId={playingAudioId}
                  setPlayingAudioId={setPlayingAudioId}
                />
              );
            }
            return null;
          })}
          {lessonCompleted && <LessonCompleted />}
          {awaitingBumpee && (
            <Card
              variant="outlined"
              sx={{
                margin: 2,
                padding: 0.5,
                width: 'fit-content',
                overflow: 'visible',
              }}
            >
              <Box display="flex">
                <ChatTextLoading />
              </Box>
            </Card>
          )}
        </Stack>
        {hintData.open && (
          <HintsCard
            hintData={hintData}
            handleHintClick={handleHintClick}
            chatId={chatId}
          />
        )}
      </Stack>
      <Box position="sticky" bottom={0} zIndex={1} width="100%" p={0} mb={1}>
        {!isAssignmentChat && (
          <>
            <Button
              color="primary"
              variant="outlined"
              sx={{ mb: 2 }}
              onClick={handleGenerateHintClick}
              disabled={isHintOpen || awaitingBumpee}
            >
              {translations.buttons.hint[language]}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              sx={{ mb: 2, ml: 1 }}
              onClick={handleTranslatorClick}
            >
              {translations.buttons.translator[language]}
            </Button>
          </>
        )}
        <StyledInputCard variant="outlined">
          <Box overflow="auto" width="calc(100% - 4rem)">
            <form
              onSubmit={handleSubmit(addMessage as SubmitHandler<FieldValues>)}
            >
              <StyledInput
                autoComplete="off"
                {...register('currentMessage', {
                  required: true,
                  validate: (value) => value.trim().length > 0,
                })}
                type="text"
                id="currentMessage"
                key="currentMessage"
                placeholder={translations.chatUI.inputPlaceholder[language]}
                multiline={true}
                onKeyDown={handleKeyDown}
                disabled={
                  !showFurtherContent ||
                  lessonCompleted ||
                  quizCompleted ||
                  listening
                }
              />
            </form>
          </Box>
          <Stack flexDirection={'row'} alignItems={'center'}>
            {!isAssignmentChat && (
              <MicrophoneButton
                disableRipple
                onClick={handleMicrophoneButtonClick}
              >
                <Box sx={{ position: 'relative', display: 'flex' }}>
                  {listening ? (
                    <>
                      <StopCircle />
                      <CircleIcon
                        sx={{ position: 'absolute', top: -10, right: -10 }}
                      />
                    </>
                  ) : (
                    <Mic />
                  )}
                </Box>
              </MicrophoneButton>
            )}
            <StyledButton
              onClick={handleSubmit(addMessage as SubmitHandler<FieldValues>)}
            >
              <ArrowForward fontSize="small" />
            </StyledButton>
          </Stack>
        </StyledInputCard>
      </Box>
    </Stack>
  );
}
