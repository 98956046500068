import { styled, Card, TextArea } from '@ltvco/refresh-lib/theme';

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 0,
  display: 'flex',
  height: '100px',
  justifyContent: 'space-between',
  marginBottom: 0,
  padding: 0,
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: '560px',
  },

  '&.MuiPaper-root': {
    borderRadius: 0,
    height: '150px',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

export const StyledTextArea = styled(TextArea)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1.5),
  width: '100%',
  border: 'none',

  '> div': {
    alignItems: 'start',
    borderRadius: 0,
    height: '100px',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
  },

  [theme.breakpoints.up('md')]: {
    width: '560px',
  },

  '&.MuiPaper-root': {
    height: '88px',
    borderRadius: 0,
  },

  '&.translated-text': {
    marginBottom: 0,

    '&.MuiPaper-root': {
      height: '150px',
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
}));
