import { Divider, Stack, Text, styled, useTheme } from '@ltvco/refresh-lib/theme';
import { useOxford } from '../Oxford/Oxford';
import {
  CreditSummaryConstants,
  leftSummaryItems,
  rightSummaryItems,
} from './utils';
import { LoadingDots } from '@ltvco/refresh-lib/v1';

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  columnGap: theme.spacing(2),
}));

export const CreditSummary = () => {
  const { getCreditData } = useOxford();
  const creditReportSummary = getCreditData?.data?.creditReportSummary;

  const theme = useTheme();

  const valueToDisplay = (value: string | undefined) => {
    if (value && value !== '%') return value;
    return '-';
  };

  const displayAlternateBackgroundCards = (
    summaryItems: { text: string; value?: string }[]
  ) => {
    const summaryItemsList = summaryItems.map((item, index) => {
      return (
        <Stack
          direction="row"
          justifyContent={'space-between'}
          p={1.5}
          sx={{
            ...(index % 2 === 0 && {
              backgroundColor: theme.palette.background.default,
            }),
          }}
          key={`${summaryItems.length}+${index}`}
          gap={[1.5, 0]}
        >
          <Text variant="body1" fontWeight="bold">
            {item.text}
          </Text>
          <Text variant="body1" textAlign="right">
            {valueToDisplay(item?.value)}
          </Text>
        </Stack>
      );
    });

    return (
      <Stack direction={'column'} width="100%">
        {summaryItemsList}
      </Stack>
    );
  };

  const content = () => {
    if (!!creditReportSummary) {
      return (
        <StyledStack direction={['column', 'row']}>
          {displayAlternateBackgroundCards(
            leftSummaryItems(creditReportSummary)
          )}
          <Divider orientation="vertical" flexItem />
          {displayAlternateBackgroundCards(
            rightSummaryItems(creditReportSummary)
          )}
        </StyledStack>
      );
    }

    return (
      <Stack alignItems="center" justifyContent="center">
        <LoadingDots count={4} />
      </Stack>
    );
  };

  return (
    <>
      <Stack direction="row" alignItems={'center'} gap={1.5}>
        <Text variant="h4" mb={0}>
          Your Credit Report Summary
        </Text>
      </Stack>
      <Divider sx={{ marginY: [2] }} />
      <Text variant="body1">{CreditSummaryConstants.title}</Text>
      {content()}
      <Text display="block" variant="caption" textAlign={'right'} my={2.5}>
        Data source: TransUnion®
      </Text>
      <Text display="block" variant="caption" textAlign={'center'}>
        {CreditSummaryConstants.disclaimer}
      </Text>
    </>
  );
};
