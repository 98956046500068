import { useContext, useEffect, useState } from 'react';
import { Box, Grid, Text } from '@ltvco/refresh-lib/theme';
import { languageName, translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { useNavigate } from 'react-router-dom';
import { AssignmentCard } from '../AssignmentCard/AssignmentCard';
import { useBumpeeStoryChats } from 'components/Language/hooks/useBumpeeStoryChats';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

export const StoriesContainer = () => {
  const [stories, setStories] = useState<
    {
      id: number;
      title: string;
      status: string;
      storyId?: number;
    }[]
  >([]);

  const redirect = useNavigate();
  const { trackEventGA4 } = useContext(AppConfig);
  const { language, lessonLearningLanguage } = useLanguageContext();
  const { data: userStoryChats, createStoryChatMutation } =
    useBumpeeStoryChats();

  useEffect(() => {
    const stories =
      translations.dashboard.assignments.story.storyList[language];

    const modifiedLessons = stories?.map((story, index) => {
      const userStory = userStoryChats?.story_chats.find(
        (userStory: { story_id: number; learning_language_id: number; }) =>
          userStory.story_id === story.id &&
          userStory.learning_language_id ===
            parseInt(lessonLearningLanguage.value)
      );

      return {
        ...story,
        title: `${translations.dashboard.assignmentCardTitles[language]} ${
          index + 1
        }: ${story.title}`,
        storyId: userStory?.id,
        status: userStory?.completed
          ? 'completed'
          : userStory?.chat_id
          ? 'incomplete'
          : 'new',
      };
    });

    setStories(modifiedLessons);
  }, [language, userStoryChats, lessonLearningLanguage.value]);

  const redirectToStory = (storyId: number) => {
    const story = stories.find((story) => story.id === storyId);
    if (!story) return;

    if (story.storyId) {
      return redirect(`/dashboard/language/story/${story.storyId}`);
    }

    const storyChatParams = {
      lesson_id: story.id,
      learning_language_id: parseInt(lessonLearningLanguage.value),
    };

    createStoryChatMutation.mutate(storyChatParams);

    trackEventGA4({
      event_name: 'Language Tutor Engagement',
      type: 'story',
      language: languageName(parseInt(lessonLearningLanguage.value)),
      action: 'start',
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Text variant="h3" color="primary.main">
        {translations.dashboard.assignments.story.header[language]}
      </Text>
      <Grid container rowSpacing={2} columnSpacing={{ sm: 3 }}>
        {stories?.map((story) => (
          <AssignmentCard
            key={story.id}
            assignment={story}
            handleRedirectToAssignment={redirectToStory}
          />
        ))}
      </Grid>
    </Box>
  );
};
