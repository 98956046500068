import {
  BaseNumerologyQueryKeys,
  type MenuSelectOptionProps,
  type UserProps,
  type LogErrorProps,
  type UserPropsExtra,
  type UserBirthLocation,
  type UserBirthTime,
} from './interfaces';
import { baseQueryKey as baseAscendantQueryKey } from './useAscendant';
import { MenuOptions } from './constants';
import { DateUtil } from '@ltvco/refresh-lib/utils';
import { DateTime } from 'luxon';

const dobFormat = (date: DateTime) => {
  const dateUtil = new DateUtil();
  return dateUtil.parseDateFromString(date.toString() || '', 'L/dd/yyyy') || '';
};

export const storeAstrologyUser = (name: string, dob: DateTime) => {
  const formattedDob = dobFormat(dob);
  const dateSplitted = formattedDob.split('/');

  localStorage.setItem(
    'astrology_user',
    JSON.stringify({
      name,
      dob: formattedDob,
      date: dateSplitted[1],
      month: dateSplitted[0],
      year: dateSplitted[2],
    })
  );
};

export const storeExtraAstrologyUserData = ({
  hour,
  minute,
  city_state,
  country,
  lat,
  lon,
  timezone_offset,
}: UserBirthLocation & UserBirthTime) => {
  const storedData = localStorage.getItem('astrology_user');
  if (!storedData) return null;

  const user: UserPropsExtra = JSON.parse(storedData);
  user.time = { hour, minute };
  user.location = {
    city_state,
    country,
    lat,
    lon,
    timezone_offset,
  };

  localStorage.setItem('astrology_user', JSON.stringify(user));
};

export const getAstrologyUser = (
  logError: LogErrorProps
): UserPropsExtra | null => {
  const storedData = localStorage.getItem('astrology_user');
  if (!storedData) return null;

  try {
    const user: UserProps | UserPropsExtra = JSON.parse(storedData);
    // There may be users who have the values 24 and 60 in their local storage, which causes errors.
    // Validations were created to prevent new users from adding these values. To ensure that existing users are not affected,
    //  the following code was added:
    if ('time' in user && user.time) {
      const { hour, minute } = user.time;

      if (Number(hour) === 24 || Number(minute) === 60) {
        delete user.time;
        delete user.location;
        localStorage.setItem('astrology_user', JSON.stringify(user));
      }
    }
    return user;
  } catch (error) {
    logError(
      'Error parsing astrology user data from localStorage:',
      error as Error
    );
    return null;
  }
};

export const deleteExtraAstrologyUserLocalData = (logError: LogErrorProps) => {
  const storedData = localStorage.getItem('astrology_user');
  if (!storedData) return null;

  try {
    const user: UserPropsExtra = JSON.parse(storedData);
    delete user.time;
    delete user.location;

    localStorage.setItem('astrology_user', JSON.stringify(user));
  } catch (error) {
    logError(
      'Error removing astrology ascendant user data from localStorage:',
      error as Error
    );
    return null;
  }
};

export const resetAstrologyUserLocalStorage = () => {
  localStorage.removeItem('astrology_user');
};

const deleteLifepathData = (identifier: string, logError: LogErrorProps) => {
  localStorage.removeItem('astrology_user');

  const storedData = localStorage.getItem(BaseNumerologyQueryKeys.lifepath);
  if (!storedData) return null;

  try {
    const data = JSON.parse(storedData);
    delete data[identifier];

    localStorage.setItem(
      BaseNumerologyQueryKeys.lifepath,
      JSON.stringify(data)
    );
  } catch (error) {
    logError(
      'Error parsing astrology lifepath data from localStorage:',
      error as Error
    );
    return null;
  }
};

const deletePersonalityData = (identifier: string, logError: LogErrorProps) => {
  localStorage.removeItem('astrology_user');

  const storedData = localStorage.getItem(BaseNumerologyQueryKeys.personality);
  if (!storedData) return null;

  try {
    const data = JSON.parse(storedData);
    delete data[identifier];

    localStorage.setItem(
      BaseNumerologyQueryKeys.personality,
      JSON.stringify(data)
    );
  } catch (error) {
    logError(
      'Error parsing astrology lifepath data from localStorage:',
      error as Error
    );
    return null;
  }
};

export const deleteNumerologyLocalStorageData = (
  identifier: string,
  logError: LogErrorProps
) => {
  deleteLifepathData(identifier, logError);
  deletePersonalityData(identifier, logError);
};

export const deleteAscendantLocalStorageData = (
  identifier: string,
  logError: LogErrorProps
) => {
  const storedData = localStorage.getItem(baseAscendantQueryKey);
  if (!storedData) return null;

  try {
    const data = JSON.parse(storedData);
    delete data[identifier];

    localStorage.setItem(baseAscendantQueryKey, JSON.stringify(data));
  } catch (error) {
    logError(
      'Error removing astrology ascendant data from localStorage:',
      error as Error
    );
    return null;
  }
};

export const getZodiacSign = (user: UserProps) => {
  if (!user) return '';

  if (user.sign) return user.sign;

  const date = new Date(user.dob || '');
  const month = date.getMonth();
  const day = date.getDate();

  const signs = [
    day <= 20 ? 'Capricorn' : 'Aquarius',
    day <= 18 ? 'Aquarius' : 'Pisces',
    day <= 20 ? 'Pisces' : 'Aries',
    day <= 19 ? 'Aries' : 'Taurus',
    day <= 20 ? 'Taurus' : 'Gemini',
    day <= 20 ? 'Gemini' : 'Cancer',
    day <= 22 ? 'Cancer' : 'Leo',
    day <= 22 ? 'Leo' : 'Virgo',
    day <= 22 ? 'Virgo' : 'Libra',
    day <= 22 ? 'Libra' : 'Scorpio',
    day <= 21 ? 'Scorpio' : 'Sagittarius',
    day <= 21 ? 'Sagittarius' : 'Capricorn',
  ];

  return signs[month];
};

export const getAvailableMenuConfig = (
  disableOptions: string[],
  options?: { [key: string]: MenuSelectOptionProps }
) => {
  const localOptions = options || MenuOptions;

  return Object.keys(localOptions).reduce((acc, key) => {
    if (!disableOptions.includes(key)) {
      return { ...acc, [key]: localOptions[key] };
    }
    return acc;
  }, {});
};
