import { useState } from 'react';
import { StyledStack } from './components';
import { AchievementsModal } from '../AchievementsModal/AchievementsModal';
import { AchievementsInfo } from './AchievementsInfo/AchievementsInfo';
import { StreaksInfo } from './StreaksInfo/StreaksInfo';

export const GamificationOverview = () => {
  const [isAchievementsModalOpen, setIsAchievementsModalOpen] = useState(false);

  const handleToggleAchievementsModal = () => {
    setIsAchievementsModalOpen(!isAchievementsModalOpen);
  };

  return (
    <StyledStack mt={4} flexDirection={{ xs: 'column', md: 'row' }}>
      <AchievementsInfo
        handleToggleAchievementsModal={handleToggleAchievementsModal}
      />
      <StreaksInfo />
      <AchievementsModal
        open={isAchievementsModalOpen}
        handleOnClose={handleToggleAchievementsModal}
      />
    </StyledStack>
  );
};
