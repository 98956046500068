import {
  Stack,
  Text,
  useTheme,
} from '@ltvco/refresh-lib/theme';
import { useState, useContext } from 'react';
import {
  SerializedReport,
  addMonitorText,
  claimMonitorText,
  containerIcon,
  containerTitleText,
  displayName,
} from './utils';
import {
  DisplayTextStack,
  DisplayTextWithEllipses,
  MonitoringOverviewContainer,
  MonitoringOverviewContainerButton,
} from './Components';
import { ManageReportMonitorsModal } from './ManageReportMonitorsModal';
import { gaCategoryMap } from './constants';
import {
  mapReportTypesToReportParams,
  ReportMonitor,
  useReportRedirect,
  type RawReportType,
} from '@ltvco/refresh-lib/v1';
import { capitalizeString, serializeClaimedReports, useScreenSize, ValidReportTypes } from '@ltvco/refresh-lib/utils';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { ChevronRight, AddCircle } from '@mui/icons-material';

// Requires both reportMonitors and rawReportType because
//  reportMonitors can be null and we still need to populate the form correctly.
export const ClaimedReportMonitorsContainer: React.FC<{
  reportMonitors?: ReportMonitor[];
  rawReportType: RawReportType;
}> = ({ reportMonitors, rawReportType }) => {
  const [openModal, setOpenModal] = useState(false);
  const { trackEvent } = useContext(AppConfig);

  const isDetailedPersonReport = rawReportType === 'detailed_person_report';
  const serializedReports: SerializedReport[] = reportMonitors
    ? serializeClaimedReports(reportMonitors, rawReportType)
    : [];
  const monitorsCount = serializedReports?.length ?? 0;
  const { isMobile, isMedium } = useScreenSize();

  const { redirectToReportUrl } = useReportRedirect();

  const navigateToReport = (permalink: string) => {
    const mappedReportType = mapReportTypesToReportParams[
      rawReportType as keyof typeof mapReportTypesToReportParams
    ] as ValidReportTypes;

    if (mappedReportType) {
      redirectToReportUrl(mappedReportType, permalink);
    } else {
      console.error('No report type found for', rawReportType);
    }
  };

  const containerButtons = () => {
    if (isDetailedPersonReport) {
      if (monitorsCount > 0) {
        return (
          <MonitoringOverviewContainerButton
            variant="outlined"
            color="success"
            fullWidth={isMedium}
            onClick={() => navigateToReport(serializedReports[0].permalink)}
          >
            Check Report
          </MonitoringOverviewContainerButton>
        );
      } else {
        return (
          <MonitoringOverviewContainerButton
            variant={'contained'}
            color="success"
            fullWidth={isMedium}
            endIcon={<ChevronRight />}
          >
            Set up now
          </MonitoringOverviewContainerButton>
        );
      }
    }

    return (
      <MonitoringOverviewContainerButton
        variant={monitorsCount > 0 ? 'outlined' : 'contained'}
        color="success"
        fullWidth={isMedium}
        endIcon={<AddCircle />}
      >
        {monitorsCount > 0
          ? addMonitorText(rawReportType)
          : claimMonitorText(rawReportType)}
      </MonitoringOverviewContainerButton>
    );
  };

  const theme = useTheme();

  const reportName = () => {
    if (isDetailedPersonReport) {
      return `${capitalizeString(serializedReports[0].name || '')}, ${
        serializedReports[0].location
      }`;
    }

    return displayName[rawReportType](serializedReports[0]);
  };

  const displayFirstReportName = () => {
    if (monitorsCount > 1 && !isDetailedPersonReport) {
      return (
        <>
          <DisplayTextWithEllipses>
            {displayName[rawReportType](serializedReports[0])}
          </DisplayTextWithEllipses>
          <Text
            sx={{
              color: theme.palette.primary.light,
              whiteSpace: 'nowrap',
            }}
            ml={1}
            variant="body1"
          >
            (+{monitorsCount - 1} more)
          </Text>
        </>
      );
    } else {
      return (
        <>
          <DisplayTextWithEllipses
            sx={{
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {monitorsCount > 0 ? reportName() : 'Not claimed'}
          </DisplayTextWithEllipses>
          <Text whiteSpace="pre"> </Text>
        </>
      );
    }
  };

  return (
    <>
      <MonitoringOverviewContainer
        direction="column"
        onClick={() => {
          const gaCategory =
            gaCategoryMap[rawReportType as keyof typeof gaCategoryMap];

          trackEvent(gaCategory, 'navigation click', 'id monitor settings');

          setOpenModal(true);
        }}
      >
        <Stack direction="row" justifyContent="space-between" mb={[2, 0]}>
          <Stack direction="row">
            <img
              src={containerIcon[rawReportType]}
              width="36px"
              height="39px"
              alt="name-monitoring-icon"
            />
            <Stack direction="column" ml={2} sx={{}}>
              <Text variant="h6" sx={{ whiteSpace: 'pre-wrap' }} mb={1}>
                {containerTitleText(rawReportType, !isMobile)}
              </Text>
            </Stack>
          </Stack>
        </Stack>

        <DisplayTextStack direction="row" ml={6.5}>
          {displayFirstReportName()}
        </DisplayTextStack>

        {containerButtons()}
      </MonitoringOverviewContainer>
      <ManageReportMonitorsModal
        serializedReports={serializedReports}
        rawReportType={rawReportType}
        openModal={openModal}
        setOpenModal={setOpenModal}
        navigateToReport={navigateToReport}
      />
    </>
  );
};
