import { Text } from '@ltvco/refresh-lib/theme';
import { MuiMarkdown, getOverrides } from 'mui-markdown';

interface MarkdownProps {
  content: string;
}

export function Markdown({ content }: MarkdownProps) {
  const overrides = {
    ...getOverrides({}),
    h1: {
      component: Text,
      props: { variant: 'h1' },
    },
    h2: {
      component: Text,
      props: { variant: 'h2' },
    },
    h3: {
      component: Text,
      props: { variant: 'h3' },
    },
    h4: {
      component: Text,
      props: { variant: 'h4' },
    },
    h5: {
      component: Text,
      props: { variant: 'h5' },
    },
    h6: {
      component: Text,
      props: { variant: 'h6' },
    },
    p: {
      component: Text,
      props: { variant: 'body1' },
    },
  };

  return <MuiMarkdown overrides={overrides}>{content}</MuiMarkdown>;
}
