import React from 'react';
import { ProtectionTools } from '../ProtectionTools/ProtectionTools';
import { useOxford } from '../Oxford/Oxford';
import { Stack } from '@ltvco/refresh-lib/theme';
import { ContactFooter } from '../ContactFooter/ContactFooter';
import { CreditScoreHistory } from '../CreditScoreHistory/CreditScoreHistory';
import CreditFreeze from '../CreditFreeze/CreditFreeze';
import { CreditAnalysisContainer } from '../CreditAnalysis/CreditAnalysisContainer';

const AuthenticatedContent: React.FC = () => {
  const { data } = useOxford().useNotifications();

  return (
    <Stack direction="column" gap={2.5}>
      <ProtectionTools />
      <CreditScoreHistory />
      <CreditAnalysisContainer />
      <CreditFreeze />
      <ContactFooter />
    </Stack>
  );
};

export default AuthenticatedContent;
