import { Text, Box } from '@ltvco/refresh-lib/theme';

interface IdTheftInsuranceDisclaimerProps {
  navigateToBenefitsModal: () => void;
}

const IdTheftInsuranceDisclaimer = ({
  navigateToBenefitsModal,
}: IdTheftInsuranceDisclaimerProps) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Text variant="body2">
        For residents of states other than the State of New York, the expense
        reimbursement insurance benefit for members is underwritten by certain
        Underwriters at Lloyd’s, under a master group policy issued in the name
        of Cyberscout Limited, Sontiq Inc. and all subsidiaries thereof, for the
        benefit of members. Claims will be reviewed by the insurer in accordance
        with the terms and conditions of the master group policy. A summary of
        the terms of coverage are set forth{'  '}
        <a
          href="#"
          onClick={() => navigateToBenefitsModal()}
          className="underline"
        >
          here
        </a>
      </Text>
      <br />
      <Text variant="body2">
        For residents of the State of New York, the Master Policies of Personal
        Internet Identity Coverage have been issued to: TransUnion (the "Master
        Policy Holder"), under Policy Numbers: 16452600, respectively
        underwritten by insurance company subsidiaries or affiliates of American
        International Group, Inc. A summary of the terms of coverage are set
        forth{'  '}
        <a
          href="#"
          onClick={() => navigateToBenefitsModal()}
          className="underline"
        >
          here
        </a>
      </Text>
      <br />
      <Text variant="body2">
        This summary description of benefits does not state all the terms,
        conditions, and exclusions of the master policies. Your benefits will be
        subject to all of the terms, conditions, and exclusions of the master
        policies, even if they are not mentioned in this summary. A complete
        copy of the policies will be provided upon request. Claims will be
        reviewed by the respective insurer in accordance with the terms and
        conditions of the master group policies. The description provided here
        is a summary and is for informational purposes only.
      </Text>
      <br />
      <Text variant="h4" className="underline">
        General Information
      </Text>
      <Text variant="body2">
        Should you have any questions regarding the membership program provided
        by the Master Policyholder or wish to view a complete copy of the master
        policy, please call the customer service number at (833) 570-3005
        located in your membership materials.
      </Text>
      <br />
      <Text variant="h4" className="underline">
        Filing a Claim
      </Text>
      <Text variant="body2">
        If you have any questions regarding the identity theft insurance
        coverage or wish to file a claim under the master policies, please
        contact the insurer at 1-866-IDHelp2 (1-866-434-3572).
      </Text>
    </Box>
  );
};

export default IdTheftInsuranceDisclaimer;
