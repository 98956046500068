import { Button, Modal, Stack, Text, styled } from '@ltvco/refresh-lib/theme';

export const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(0.5),
  border: '1px solid var(--divider, #E2E2E2)',
  cursor: 'pointer',
}));

export const MonitoringOverviewContainer = styled(StyledStack)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    width: `calc(50% - ${theme.spacing(2)})`,
  },
  '&:hover': {
    boxShadow:
      '0px 3px 3px -2px rgba(0, 0, 0, 0.20), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)',
  },
}));

export const MonitoringOverviewContainerButton = styled(Button)(
  ({ theme }) => ({
    height: '36px',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '42%',
      right: theme.spacing(2),
      position: 'absolute',
      maxWidth: '170px',
    },
  })
);

export const EditMonitorModal = styled(Modal)(({ theme }) => ({
  overflowX: 'hidden',
  '.MuiDialogTitle-root': {
    display: 'flex',
    padding: `0 ${theme.spacing(3)}`,
    margin: `${theme.spacing(2.5)} 0 0 0`,
  },
  '.edit-modal': {
    overflowX: 'hidden',
    maxWidth: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '600px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1000px',
    },
  },
  '.MuiDialogContent-root': {
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: theme.spacing(2.5),
  },
}));

export const DisplayTextStack = styled(Stack)(({ theme }) => ({
  marginTop: '-33px',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    marginTop: '0px',
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: '0px',
  },
}));

export const DisplayTextWithEllipses = styled(Text)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  OTextOverflow: 'ellipsis',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
}));
