import {
  Button,
  Modal,
  Stack,
  Text
} from '@ltvco/refresh-lib/theme';
import IdMonitorError from 'images/id-monitor-error.svg';
import { useNavigate } from 'react-router-dom';
import { AuthenticationQuestions } from '../Oxford/useAuthentication';
import { useContext, useEffect } from 'react';
import { useQueryClient } from '@ltvco/refresh-lib/vendors';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

interface ErrorConst {
  title: string;
  body: any;
  warning?: any;
  action: string;
}

type ErrorType =
  | 'retryUserProfile'
  | 'failedUserProfile'
  | 'authenticationInProgress'
  | 'generic'
  | 'oneTimePasscodeAuthenticationError'
  | 'authenticationAnswersIncorrect';

const ERROR_CONST: Record<ErrorType, ErrorConst> = {
  retryUserProfile: {
    title: 'The information you provided may be incorrect',
    body: "Please re-enter your information to access ID Monitor and make sure it's correct.",
    warning:
      'A second failed attempt will restrict you from using ID Monitor in the future.',
    action: 'Re-enter information',
  },
  failedUserProfile: {
    title: 'The information you submitted was incorrect',
    body: 'You are restricted from using this feature permanently because of two failed attempts.',
    warning: (
      <>
        You may access your credit data directly at{' '}
        <a
          href="https://www.transunion.com/"
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          https://www.transunion.com/
        </a>
      </>
    ),
    action: 'Back to dashboard',
  },
  authenticationInProgress: {
    title: 'Your Knowledge Based Authentication Answers are Incorrect',
    body: 'Please re-submit your answers to access the ID Monitor.',
    warning:
      'A second failed attempt will restrict you from using ID Monitor for a month.',
    action: 'Restart Authentication',
  },
  authenticationAnswersIncorrect: {
    title: 'Your authentication answers were incorrect',
    body: 'Please retry accessing ID Monitor in a month.',
    warning: (
      <>
        You may access your credit data directly at{' '}
        <a
          href="https://www.transunion.com/"
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          https://www.transunion.com/
        </a>
      </>
    ),
    action: 'Back to Dashboard',
  },
  oneTimePasscodeAuthenticationError: {
    title: 'Your authentication answers were incorrect',
    body: 'Please retry accessing ID Monitor in a month.',
    warning: (
      <>
        You may access your credit data directly at{' '}
        <a
          href="https://www.transunion.com/"
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          https://www.transunion.com/
        </a>
      </>
    ),
    action: 'Back to Dashboard',
  },
  generic: {
    title: "We've experienced an error gathering your data",
    body: (
      <>
        Please refresh this page or contact support at{' '}
        <a
          href="mailto: support@beenverified.com"
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: 'bold', textDecoration: 'underline' }}
        >
          support@beenverified.com
        </a>
      </>
    ),
    action: 'Refresh Page',
  },
};

export const ErrorModal: React.FC<{
  type: ErrorType;
}> = ({ type }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { trackEvent, trackEventGA4 } = useContext(AppConfig);

  useEffect(() => {
    if (type === 'failedUserProfile' || type === 'retryUserProfile') {
      trackEvent('personal info', 'error', 'id monitor enrollment');
      trackEventGA4({
        event_name: 'id_monitor_enrollment_error',
        type: 'personal_info_error',
      });
    }
    if (type === 'authenticationAnswersIncorrect') {
      trackEvent('kba', 'error', 'id monitor enrollment');
      trackEventGA4({
        event_name: 'id_monitor_enrollment_error',
        type: 'kba_error',
      });
    }
    if (type === 'oneTimePasscodeAuthenticationError') {
      trackEvent('otp', 'error', 'id monitor enrollment');
      trackEventGA4({
        event_name: 'id_monitor_enrollment_error',
        type: 'otp_error',
      });
    }
  }, [type]);

  const onclose = () => {
    const actions = {
      retryUserProfile: () => {
        queryClient.resetQueries({
          queryKey: ['oxford', 'authentication', 'questions'],
        });
      },
      authenticationInProgress: () => {
        const modifyData = (oldData?: AuthenticationQuestions) => {
          return { ...oldData, in_progress: false };
        };

        const getAuthenticationQuestionsQuery = queryClient.getQueryState([
          'oxford',
          'authentication',
          'questions',
        ]);
        const authenticationQuestionsData =
          getAuthenticationQuestionsQuery?.data as AuthenticationQuestions;
        if (authenticationQuestionsData?.in_progress === true) {
          queryClient.setQueryData(
            ['oxford', 'authentication', 'questions'],
            (oldData?: AuthenticationQuestions) => modifyData(oldData)
          );
        } else {
          queryClient.resetQueries({
            queryKey: ['oxford', 'authentication', 'questions'],
          });
          queryClient.setQueryData(
            ['oxford', 'authentication', 'checkAnswers'],
            (oldData?: AuthenticationQuestions) => modifyData(oldData)
          );
        }
      },
      oneTimePasscodeAuthenticationError: () => {
        navigate('/dashboard');
      },
      authenticationAnswersIncorrect: () => {
        navigate('/dashboard');
      },
      generic: () => {
        navigate(0);
      },
      failedUserProfile: () => {
        navigate('/dashboard');
      },
      default: () => {
        navigate('/dashboard');
      },
    };

    const action = actions[type] || actions.default;

    action();
  };

  const selectedError = ERROR_CONST[type as keyof typeof ERROR_CONST];

  return (
    <>
      <Modal title="" open={true} hasCloseIcon={false}>
        <Stack alignItems="center" textAlign="center" marginY={2.5}>
          <img
            src={IdMonitorError}
            width="55"
            height="52"
            alt="id-monitor-error"
          />
          <Text variant="h1" marginTop={2.5}>
            {selectedError.title}
          </Text>
          {selectedError.body && (
            <Text variant="body1">{selectedError.body}</Text>
          )}
          {selectedError.warning && (
            <Text variant="body1" marginTop={1.5} sx={{ color: '#DC0015' }}>
              {selectedError.warning}
            </Text>
          )}
          <Button
            onClick={() => onclose()}
            variant="contained"
            sx={{ marginTop: [2.5] }}
          >
            {selectedError.action}
          </Button>
        </Stack>
      </Modal>
    </>
  );
};
