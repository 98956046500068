import { useContext, useEffect, useState, useMemo } from 'react';
import {
  PhoneSection as PhoneSectionV2,
  EmailSection as EmailSectionV2,
  AddressHistorySection as AddressHistorySectionV2,
  SocialSection as SocialSectionV2,
  JobsSection as JobsSectionV2,
  EducationSection as EducationSectionV2,
  ReportRating as ReportRatingV2,
  RelatedReportsSection as RelatedReportsSectionV2,
  NotesSection as NotesSectionV2,
  AssetsSection as AssetsSectionV2,
  PotentialOwners as PotentialOwnersV2,
  ReportNavigationMenu,
  ReportNavigationMobileMenu,
  ReportNavigationMenuProps,
  NavLinkDataV2,
} from '@ltvco/refresh-lib/v2';
import type { ReportRouteProps } from './types';
import {
  getPhoneNavLinkData,
  getPhoneNavLinkDataReorderedPhoneReportVariation,
} from 'navLinkData/phoneNavLinkData';
import { constants } from 'appConstants';
import { useLocation } from 'react-router-dom';
import questionableCallerGifUrl from '../../images/caller_questionable.gif';
import telemarketerCallerGifUrl from '../../images/caller_telemarketer.gif';
import legitCallerGifUrl from '../../images/caller_legit.gif';
import imgIdCard from '../../images/img_idCard.svg';
import { MobileDownloadCTA } from 'components/MobileDownloadCTA/MobileDownloadCTA';
import { ReportActionsWithDateUpdated } from '@ltvco/refresh-lib/v2';
import {
  CommentsSection,
  commonTypes,
  ContactReportSectionBanner,
  DebugMenu,
  Monitoring,
  NeighborSpoofing,
  NuisanceCaller,
  PhoneFraudScanSection,
  PhoneOverview,
  PotentialSpam,
  ReportChangesOverview,
  ReportClaiming,
  ReportFactory,
  ReportLoading,
  ReportMonitor,
  ReportNullState,
  ReportOptions,
  SearchVolume,
  usePhoneSearchData,
  useRemouladeReportSnapshot,
  useReport,
  useReportMonitors,
  useReportRedirect,
  useSession,
  PhoneReport as PhoneReportType,
  useCreateRemouladeReportSnapshot,
  useRecentReports,
} from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
  unformatPhone,
  isNeighborSpoofed,
  formatPhone,
  DateUtil,
} from '@ltvco/refresh-lib/utils';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { isLessThanOneDayAgo } from 'utils/isLessThanOneDayAgo';

interface PhoneReportProps extends ReportRouteProps {}

const reportType = 'phone';

export function PhoneReport({ permalink }: PhoneReportProps) {
  const {
    session: { account },
  } = useSession();
  const { redirectToSearchContactUrl } = useReportRedirect();
  const { trackEvent, logError } = useContext(AppConfig);

  // TODO: all this monitored report logic needs a refactor.
  // There is no point in refetching a report when a user toggles monitoring, so we use the initial value.
  const { currentMonitors, reportIsMonitored } = useReportMonitors(permalink);
  const [neighborSpoofed, setIsNeighborSpoofed] = useState(false);
  const isInReportChange = useFeatureIsOn('OAR-1183');
  const isInStaggeredPhoneUpdateVariation = useFeatureIsOn('OAR-1352');
  const createRemouladeReportSnapshot = useCreateRemouladeReportSnapshot();
  const { data: bbqPhoneData } = useRecentReports({
    report_type: 'reverse_phone_report',
  });

  const phoneReports = useMemo(
    () => bbqPhoneData?.reports ?? [],
    [bbqPhoneData?.reports]
  );

  const coreResult = useReport(permalink, reportIsMonitored);
  const remouladeResult = useRemouladeReportSnapshot(permalink);
  const flattenedRemouladeResult = remouladeResult
    ? { ...remouladeResult, data: remouladeResult.data?.data }
    : remouladeResult;

  const currentUserInfo = account?.account?.user_info;

  const shouldUseRemouladeSnapshot =
    !remouladeResult.isError && (reportIsMonitored || isInReportChange);
  const queryResult = (
    shouldUseRemouladeSnapshot ? flattenedRemouladeResult : coreResult
  ) as typeof coreResult;

  const zeroed = isZeroed(queryResult);
  const { hash, search } = useLocation();
  useScrollToSectionOnNavigate(hash, queryResult);

  const hideHighConfidenceSwitch = useFeatureIsOn('RFRSH-2523');
  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    showHighConfidenceDataOnly: hideHighConfidenceSwitch ? true : false,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });
  const handleSetReportOptions = (newOptions: Partial<ReportOptions>) => {
    setReportOptions({ ...reportOptions, ...newOptions });
  };
  const [activeMonitor, setActiveMonitor] = useState(false);
  const [activeClaiming, setActiveClaiming] = useState(false);
  const { data: phoneSearchData } = usePhoneSearchData(
    logError,
    queryResult?.data?.meta?.search_data?.phone_number
  );

  const isInHideAllCtas = useFeatureIsOn('RFRSH-2722');

  useEffect(() => {
    setActiveClaiming(
      currentMonitors?.some((monitor: ReportMonitor) => {
        return monitor.permalink === permalink && monitor.is_claimed;
      })
    );
  }, [currentMonitors, permalink]);

  const [reportUpdateDate, setUpdateDate] = useState<string | undefined>();
  const [report, setReport] = useState<PhoneReportType | null>(null);
  const date = new DateUtil();

  const hidePersonSection =
    isInStaggeredPhoneUpdateVariation &&
    isLessThanOneDayAgo(currentUserInfo?.join_date);

  useEffect(() => {
    // Only run the request to remoulade if this report is the user's only phone report, or "first" report
    if (!isInStaggeredPhoneUpdateVariation || phoneReports.length != 1) return;

    createRemouladeReportSnapshot.mutate({
      permalink,
      update_type: 'phone',
    });
  }, [isInStaggeredPhoneUpdateVariation, phoneReports]);

  useEffect(() => {
    if (queryResult.isSuccess && queryResult.data) {
      // The format of queryResult from REMO has an extra data key.
      if (queryResult?.data?.entities)
        setReport(ReportFactory.create(queryResult?.data, 'phone'));
      else setReport(ReportFactory.create(queryResult?.data.data, 'phone'));
    }
  }, [queryResult.data, queryResult.isSuccess, reportOptions]);

  useEffect(() => {
    const remoUpdateDate = currentMonitors?.find(
      (monitor) => monitor.permalink === permalink
    )?.updated_at;
    const coreUpdateDate = report?.data?.meta?.updated_at;

    if (reportIsMonitored && remoUpdateDate)
      setUpdateDate(date.parseDateFromString(remoUpdateDate, 'yyyy-MM-dd'));
    else if (coreUpdateDate)
      setUpdateDate(
        date.parseDateFromString(
          coreUpdateDate,
          'yyyy-MM-dd',
          'yyyy-MM-dd HH:mm:ss ZZZ'
        )
      );

    if (report) {
      const spoofed = isNeighborSpoofed(
        report?.data,
        account?.account?.user_info.phone_number
      );
      setIsNeighborSpoofed(spoofed);
    }
  }, [
    report?.data?.meta?.updated_at,
    currentMonitors,
    permalink,
    reportIsMonitored,
  ]);

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={15} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  const showReportClaiming =
    unformatPhone(currentUserInfo?.phone_number || '') === report?.phone;

  const MonitoringComponent = showReportClaiming ? (
    <ReportClaiming
      permalink={permalink}
      reportType={reportType}
      reportIsMonitoredAndClaimed={activeClaiming}
    />
  ) : (
    <Monitoring permalink={permalink} reportType={reportType} />
  );

  if (!report) return <ReportLoading menuItems={15} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const owner = report.getOwner(reportOptions);

  const {
    ownerName,
    emails,
    educations,
    jobs,
    profiles,
    usernames,
    phones,
    addresses,
    ownedAssets,
    relatives,
  } = owner;

  const { risk, connectivity, is_valid } = report.supportingPhone || {};
  const {
    fraud_score,
    is_prepaid,
    is_voip,
    is_risky,
    is_recent_abuse,
    is_do_not_call,
  } = risk || {};

  const is_active = connectivity?.is_active;

  const showPhoneFraudScan =
    report.supportingPhone != null && risk != null && connectivity != null;

  const queryParams = new URLSearchParams(search);
  const fallbackPhone = queryParams.get('phone') || '';
  const phoneNumber = formatPhone(report.phone) ?? formatPhone(fallbackPhone);

  const otherPhones = phones.filter(
    (phone: commonTypes.Phone) => phone.number !== fallbackPhone
  );

  const business = report?.data?.rawData?.entities?.businesses[0];
  const businessName = business?.names[0]?.name ?? '';

  let potentialOwnersNumber = 0;

  if (report.data.people.length > 0) {
    potentialOwnersNumber = report.data.people.length;
  } else if (businessName) {
    potentialOwnersNumber = 1;
  }

  const { main_line_type, num_searches, spam_score } = report.data;
  const searchCount = phoneSearchData?.total_volume || num_searches || 0;

  const phoneNavLinkDataGetter =
    getPhoneNavLinkDataReorderedPhoneReportVariation;

  const phoneNavLinkDataV2 = phoneNavLinkDataGetter(owner, {
    potentialOwnersLength: potentialOwnersNumber,
    searchVolumeLength: searchCount > 0 ? 1 : 0,
    commentsLength: report.data.comments?.length ?? 0,
    neighborSpoofingLength: neighborSpoofed ? 1 : 0,
    spamReportsCount: report.data.spam_reports_count ?? 0,
  });

  const phoneNavLinkData = getPhoneNavLinkData(owner, {
    potentialOwnersLength: potentialOwnersNumber,
    searchVolumeLength: searchCount > 0 ? 1 : 0,
    commentsLength: report.data.comments?.length ?? 0,
    neighborSpoofingLength: neighborSpoofed ? 1 : 0,
    spamReportsCount: report.data.spam_reports_count ?? 0,
  });

  const comments = report.data.comments || [];
  const carrier = report.data.meta?.search_data?.carrier;

  document.title = `${formatPhone(phoneNumber)} - BeenVerified`;

  const handleOnBeginSearch = () => {
    trackEvent('phone report promo', 'navigation click', 'contact search');
    redirectToSearchContactUrl({});
  };

  return (
    <Box>
      <Stack direction="row">
        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
        >
          <ReportNavigationMenu
            reportType="Phone Report"
            headerTitle={formatPhone(phoneNumber)}
            menuItems={
              phoneNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
            }
          />
        </Box>
        <Box marginTop={0}>
          {activeMonitor && (
            <ReportChangesOverview
              permalink={permalink}
              navLinkData={phoneNavLinkData}
            />
          )}

          <Box
            sx={{
              height: 30,
              backgroundColor: '#f8f8f8',
              position: 'sticky',
              marginBottom: '-10px',
              marginX: -1,
              top: 52,
              zIndex: 10,
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'block',
              },
            }}
          />

          <ReportActionsWithDateUpdated
            reportType={reportType}
            reportTitle="Phone"
            dateUpdated={reportUpdateDate}
            permalink={isInReportChange ? permalink : undefined}
            navLinkData={
              isInReportChange
                ? (phoneNavLinkDataV2 as unknown as NavLinkDataV2[])
                : []
            }
            reportUpgraded={false}
          />

          {potentialOwnersNumber === 0 && <PotentialSpam />}
          <PhoneOverview
            reportType={reportType}
            phone={formatPhone(phoneNumber)}
            personName={ownerName || businessName}
            neighborSpoofed={neighborSpoofed}
            phoneOverviewItemsData={{
              comments,
              mainLineType: main_line_type,
              numSearches: searchCount,
              spamScore: spam_score,
              carrier,
              peopleCount: potentialOwnersNumber,
            }}
            Monitoring={MonitoringComponent}
            showHighConfidenceToggle={!hideHighConfidenceSwitch}
            showHighConfidenceDataOnly={
              reportOptions.showHighConfidenceDataOnly
            }
            setShowHighConfidenceDataOnly={(value: boolean) => {
              handleSetReportOptions({ showHighConfidenceDataOnly: value });
            }}
            showBlockNumberSection
          />

          <>
            <PotentialOwnersV2
              potentialOwners={report.data.people || []}
              potentialOwnerIndex={reportOptions.potentialOwnerIndex}
              setPotentialOwnerIndex={(index: number) => {
                handleSetReportOptions({ potentialOwnerIndex: index });
              }}
              personName={ownerName || report.data.formatted_number}
              permalink={permalink}
              business={business}
              showHighConfidenceDataOnly={
                reportOptions.showHighConfidenceDataOnly
              }
              setShowHighConfidenceDataOnly={(value: boolean) => {
                handleSetReportOptions({ showHighConfidenceDataOnly: value });
              }}
              hidePersonSection={hidePersonSection}
            />
            <PhoneSectionV2
              personName={ownerName || report.data.formatted_number}
              phoneList={otherPhones}
              permalink={permalink}
              alternateTitle="Other Phone Numbers"
            />
            <EmailSectionV2
              personName={ownerName || report.data.formatted_number}
              emailList={emails}
              permalink={permalink}
            />
            <AddressHistorySectionV2
              addresses={addresses}
              personName={ownerName || report.data.formatted_number}
              permalink={permalink}
            />
            <SocialSectionV2
              personName={ownerName || report.data.formatted_number}
              profiles={profiles}
              usernames={usernames}
              permalink={permalink}
            />
            <JobsSectionV2
              personName={ownerName || report.data.formatted_number}
              jobs={jobs}
              permalink={permalink}
            />
            <EducationSectionV2
              personName={ownerName || report.data.formatted_number}
              educations={educations}
              permalink={permalink}
            />
            <AssetsSectionV2
              personName={ownerName || report.data.formatted_number}
              ownedAssets={ownedAssets}
              permalink={permalink}
            />
          </>

          <PhoneFraudScanSection
            fraudScore={fraud_score}
            validStatus={is_valid}
            prepaid={is_prepaid}
            voip={is_voip}
            risky={is_risky}
            recentAbuse={is_recent_abuse}
            active={is_active}
            doNotCall={is_do_not_call}
            phoneNumber={phoneNumber}
            showPhoneFraudScan={showPhoneFraudScan}
            showReportCTA
          />
          <NeighborSpoofing phoneReportData={report.data} />
          <SearchVolume searchCount={searchCount} />
          <NuisanceCaller
            reportData={report.data}
            callerSourceMap={{
              telemarketer: telemarketerCallerGifUrl,
              questionable: questionableCallerGifUrl,
              legit: legitCallerGifUrl,
            }}
          />
          <CommentsSection reportData={report.data} permalink={permalink} />

          {!isInHideAllCtas && (
            <ContactReportSectionBanner
              image={imgIdCard}
              onBeginSearchClick={handleOnBeginSearch}
            />
          )}

          <NotesSectionV2 permalink={permalink} />
          <RelatedReportsSectionV2
            reportType="phone"
            personName={ownerName || report.data.formatted_number}
            potentialOwners={report.data.people}
            relatives={relatives}
            addresses={addresses}
            phones={phones}
            emails={emails}
            usernames={usernames}
            hidePersonSection={hidePersonSection}
          />
          {!isInHideAllCtas && <MobileDownloadCTA />}
          <ReportRatingV2
            rating={report.data.meta?.rating || null}
            report_type={reportType}
          />
        </Box>
      </Stack>
      <ReportNavigationMobileMenu
        reportType="Phone Report"
        headerTitle={formatPhone(phoneNumber)}
        menuItems={phoneNavLinkDataV2 as ReportNavigationMenuProps['menuItems']}
      />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </Box>
  );
}
