import {
  IconButton,

  SelectChangeEvent,
  Stack,
  Text,
} from '@ltvco/refresh-lib/theme';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { DrawerItem } from './DrawerItem/DrawerItem';
import { translations } from 'components/Language/constants';
import { Close } from '@mui/icons-material';

interface LanguageDrawerProps {
  handleLanguageDropDown: (event: SelectChangeEvent) => void;
  languageOptions: { value: string; display: string }[];
  toggleDrawer: () => void;
}

export const LanguageDrawer = ({
  handleLanguageDropDown,
  languageOptions,
  toggleDrawer,
}: LanguageDrawerProps) => {
  const { language } = useLanguageContext();

  return (
    <Stack px={2} py={3}>
      <Stack
        p={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text variant="h5" mb={0}>
          {translations.dashboard.assignments.lesson.header[language]}
        </Text>
        <IconButton disableRipple onClick={toggleDrawer}>
          <Close />
        </IconButton>
      </Stack>
      {languageOptions.map((language) => (
        <DrawerItem
          key={language.value}
          language={language}
          handleLanguageDropDown={handleLanguageDropDown}
          toggleDrawer={toggleDrawer}
        />
      ))}
    </Stack>
  );
};
