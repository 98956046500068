import { Stack, styled } from '@ltvco/refresh-lib/theme';

export const StyledStack = styled(Stack)(({ theme }) => ({
  height: 'auto',
  columnGap: '20px',

  '& > *': {
    width: '100%',
    maxWidth: '100%',

    [theme.breakpoints.up('md')]: {
      width: 'calc(calc(100% - 50px) / 2)',
      maxWidth: 'calc(calc(100% - 50px) / 2)',
    },

    [theme.breakpoints.down('md')]: {
      width: '100% ',
      maxWidth: '100%',
    },
  },
}));
