import {
  Box,
  Button,
  Divider,
  Stack,
  styled,
  Text,
} from '@ltvco/refresh-lib/theme';
import { Translate, VolumeUpOutlined } from '@mui/icons-material';
import { Hint } from 'api';
import { useBumpeeHintAudio } from 'components/Language/hooks/useBumpeeHintAudio';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { useEffect, useRef, useState } from 'react';

const StyledText = styled(Text)({
  color: 'rgba(0, 0, 0, 0.87)',

  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const StyledTranslation = styled(Text)({
  color: 'rgba(0, 0, 0, 0.6)',
});

const StyledStack = styled(Stack)({
  button: {
    minWidth: '18px',
    padding: '12px',
  },
});

const StyledButton = styled(Button)({
  padding: '12px',
});

interface HintCardProps {
  chatId: number;
  hint: Hint;
  index: number;
  onClick: (hintText: string) => void;
}

export const HintCard = ({ chatId, hint, index, onClick }: HintCardProps) => {
  const { currentAudioRef, setCurrentAudioRef, language } =
    useLanguageContext();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { data: audioData, handleAudioClick } = useBumpeeHintAudio(
    chatId,
    hint.text
  );
  const [showHint, setShowHint] = useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [shouldAutoplay, setShouldAutoplay] = useState<boolean>(false);

  useEffect(() => {
    if (audioData && audioData.audio) {
      const binaryString = atob(audioData.audio);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }

      const blob = new Blob([bytes], { type: 'audio/mpeg' });
      const url = URL.createObjectURL(blob);
      setAudioUrl(url);

      // Cleanup URL.createObjectURL to avoid memory leaks
      return () => URL.revokeObjectURL(url);
    }
  }, [audioData]);

  const handleHintClick = () => {
    onClick(hint.text);
  };

  const showTranslation = () => {
    setShowHint(!showHint);
  };

  const onAudioClick = () => {
    if (currentAudioRef && currentAudioRef.current) {
      currentAudioRef.current.pause();
      currentAudioRef.current.currentTime = 0;
    }
    setCurrentAudioRef(audioRef);

    if (!audioUrl) {
      setShouldAutoplay(true);
      handleAudioClick();
    } else if (audioRef && audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <Box key={`hint-${index}`} paddingBottom={1.5}>
      <StyledText m={1} mb={1.5} mt={0} onClick={handleHintClick}>
        "{hint.text}"
      </StyledText>
      {showHint && (
        <StyledTranslation m={1}>"{hint.translated_text}"</StyledTranslation>
      )}
      <StyledStack flexDirection={'row'} justifyContent={'flex-end'}>
        <Button onClick={onAudioClick}>
          <VolumeUpOutlined
            fontSize="small"
            sx={{ color: 'primary.light' }}
          />
          {audioUrl && (
            <audio autoPlay={shouldAutoplay} ref={audioRef}>
              <source src={audioUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          )}
        </Button>
        <Button onClick={showTranslation}>
          <Translate fontSize="small" sx={{ color: 'primary.light' }} />
        </Button>
      </StyledStack>
      {index < 2 && <Divider />}
    </Box>
  );
};
