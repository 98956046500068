import { useEffect, useState } from 'react';
import {
  Button,
  styled,
  Modal,
  Stack,
  Select,
  SelectOption,
  Text,
  Drawer,
  MenuItem,
  useTheme,
} from '@ltvco/refresh-lib/theme';
import { useBumpeeUsers } from '../../hooks/useBumpeeUsers';
import { StyledUnderlineButton } from 'components/Language/OnBoarding/components';
import { FeedbackModal } from 'components/Language/FeedbackModal/FeedbackModal';
import { languageID, translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { Settings, SettingsOutlined, ThumbUpAltOutlined } from '@mui/icons-material';

const SettingsButton = styled(Button)({
  height: 30,
  fontSize: 12,
  padding: '0 10px 0 6px',
});

const SettingsIcon = styled(Settings)({
  marginRight: 6,
});

export const StyledDrawer = styled(Drawer)({
  '.MuiPaper-root': {
    paddingTop: '36px',
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px',
    height: 'calc(100vh - 38px)',
  },
});

const StyledMobileDrawer = styled(Drawer)({
  '.MuiPaper-root': {
    paddingTop: '20px',
    paddingBottom: '50px',
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px',
  },
});

export const LanguageSettings = () => {
  const theme = useTheme();
  const { isMedium } = useScreenSize();
  const { updateUserMutation } = useBumpeeUsers();
  const { language } = useLanguageContext();

  const [languageOptions, setLanguageOptions] = useState<SelectOption[]>([]);
  const [nativeLanguage, setNativeLanguage] = useState<number>();
  const [languageSettingsModalStatus, setLanguageSettingsModalStatus] =
    useState(false);
  const [mobileDrawerStatus, setMobileDrawerStatus] = useState(false);
  const [languageDrawerStatus, setLanguageDrawerStatus] = useState(false);
  const [feedbackModalStatus, setFeedbackModalStatus] = useState(false);

  useEffect(() => {
    const transformedOptions = translations.languages[language].map(
      (language) => ({
        value: language.id.toString(),
        display: language.name,
      })
    );
    const languageValue = Number(
      transformedOptions.find(
        (option) => parseInt(option.value) === languageID(language)
      )?.value
    );

    setLanguageOptions(transformedOptions);
    setNativeLanguage(languageValue);
  }, [language]);

  const handleOnSelect = (value: string) => {
    setNativeLanguage(
      Number(languageOptions.find((option) => option.value === value)?.value)
    );
  };

  const handleResponsiveSetting = () => {
    if (isMedium) {
      handleMobileDrawerOpen();
    } else {
      handleLanguageModalOpen();
    }
  };

  const handleLanguageModalOpen = () => {
    setLanguageSettingsModalStatus(true);
  };

  const handleLanguageModalClose = () => {
    setLanguageSettingsModalStatus(false);
  };

  const handleMobileDrawerOpen = () => {
    setMobileDrawerStatus(true);
  };

  const handleMobileDrawerClose = () => {
    setMobileDrawerStatus(false);
  };

  const handleMobileLanguageSetting = () => {
    handleMobileDrawerClose();
    setLanguageDrawerStatus(true);
  };

  const handleLanguageDrawerClose = () => {
    setLanguageDrawerStatus(false);
  };

  const handleFeedbackModalOpen = () => {
    handleMobileDrawerClose();
    setFeedbackModalStatus(true);
  };

  const handleFeedbackModalClose = () => {
    setFeedbackModalStatus(false);
  };

  const submit = () => {
    if (nativeLanguage) {
      updateUserMutation.mutate({
        native_language_id: nativeLanguage.toString(),
      });
    }
    handleLanguageModalClose();
    handleLanguageDrawerClose();
  };

  return (
    <>
      <SettingsButton
        variant={isMedium ? undefined : 'outlined'}
        color="primary"
        onClick={handleResponsiveSetting}
        disableRipple
      >
        {isMedium ? (
          <Settings sx={{ color: theme.palette.primary.main}} />
        ) : (
          <>
            <SettingsIcon fontSize="small" sx={{ color: theme.palette.primary.main}} />
            {translations.buttons.settings[language]}
          </>
        )}
      </SettingsButton>

      {isMedium && (
        <>
          <StyledMobileDrawer
            anchor="bottom"
            open={mobileDrawerStatus}
            onClose={handleMobileDrawerClose}
            sx={{ pt: 2, pb: 6.25 }}
          >
            <MenuItem onClick={handleMobileLanguageSetting}>
              <Settings sx={{ color: theme.palette.primary.main}} />
              <Text ml={1}>{translations.buttons.settings[language]}</Text>
            </MenuItem>
            <MenuItem onClick={handleFeedbackModalOpen}>
              <ThumbUpAltOutlined sx={{ color: theme.palette.primary.main}} />
              <Text ml={1}>
                {translations.dashboard.giveFeedback.title[language]}
              </Text>
            </MenuItem>
          </StyledMobileDrawer>
          <StyledDrawer
            anchor="bottom"
            open={languageDrawerStatus}
            onClose={handleLanguageDrawerClose}
          >
            <Stack
              justifyContent={'flex-start'}
              alignItems={'center'}
              px={2}
              height={'100%'}
            >
              <Stack justifyContent={'center'} alignItems={'center'}>
                <SettingsOutlined sx={{ color: theme.palette.primary.main}} fontSize="large" />
                <Text variant="h2" mb="42px">
                  {translations.dashboard.languageSettings.title[language]}
                </Text>
              </Stack>
              <Stack
                width={'100%'}
                justifyContent={'space-between'}
                height={'100%'}
                pb={6.5}
              >
                <Stack pb={2}>
                  <Stack>
                    <Stack flexDirection={'column'} mb={3} p={0}>
                      <Text fontWeight={700} mb={1}>
                        {
                          translations.dashboard.languageSettings
                            .nativeLanguage[language]
                        }
                      </Text>
                    </Stack>
                    <Select
                      options={languageOptions}
                      id="random"
                      onOptionSelect={handleOnSelect}
                      value={nativeLanguage || ''}
                      sx={{ width: '160px' }}
                    />
                  </Stack>
                </Stack>
                <Stack
                  width={'100%'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignSelf={'flex-end'}
                >
                  <StyledUnderlineButton onClick={handleLanguageDrawerClose}>
                    {translations.buttons.cancel[language]}
                  </StyledUnderlineButton>
                  <Button
                    onClick={submit}
                    className="language-continue"
                    variant="contained"
                    sx={{ width: '167px' }}
                  >
                    {translations.buttons.save[language]}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </StyledDrawer>
        </>
      )}
      <Modal
        open={languageSettingsModalStatus}
        onClose={handleLanguageModalClose}
      >
        <Stack justifyContent={'center'} alignItems={'center'}>
          <SettingsOutlined sx={{ color: theme.palette.primary.main}} fontSize="large" />
          <Text variant="h2">
            {translations.dashboard.languageSettings.title[language]}
          </Text>
          <Stack mb={2}>
            <Stack
              flexDirection={'row'}
              width={500}
              justifyContent="space-between"
            >
              <Stack flexDirection={'column'} mb={3} p={0}>
                <Text fontWeight={700}>
                  {
                    translations.dashboard.languageSettings.nativeLanguage[
                      language
                    ]
                  }
                </Text>
              </Stack>
              <Select
                options={languageOptions}
                id="random"
                onOptionSelect={handleOnSelect}
                value={nativeLanguage || ''}
                sx={{ width: '160px' }}
              />
            </Stack>
          </Stack>
          <Stack
            width={'100%'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <StyledUnderlineButton onClick={handleLanguageModalClose}>
              {translations.buttons.cancel[language]}
            </StyledUnderlineButton>
            <Button
              onClick={submit}
              className="language-continue"
              variant="contained"
              sx={{ width: '142px' }}
            >
              {translations.buttons.save[language]}
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <FeedbackModal
        open={feedbackModalStatus}
        handleClose={handleFeedbackModalClose}
      />
    </>
  );
};
