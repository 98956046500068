/* Horoscope */
export enum BaseHoroscopeQueryKeys {
  yesterday = 'horoscopes_yesterday',
  today = 'horoscopes_today',
  tomorrow = 'horoscopes_tomorrow',
  monthly = 'horoscopes_monthly',
}

export const HoroscopesSigns = [
  'aries',
  'taurus',
  'gemini',
  'cancer',
  'leo',
  'virgo',
  'libra',
  'scorpio',
  'sagittarius',
  'capricorn',
  'aquarius',
  'pisces',
] as const;

export interface CreateHoroscopesResponse {
  status: Boolean;
  sun_sign: string;
  prediction_date?: string;
  prediction_month?: string;
  prediction: string[] | string;
  requested_date?: string;
}

/* Numerology */
export enum BaseNumerologyQueryKeys {
  lifepath = 'numerology_lifepath',
  personality = 'numerology_personality',
}

export interface CreateNumerologyResponse {
  name?: string;
  birth_date?: string;
  lifepath_number: number;
  personality_number: number;
  report: string[];
  expression_number: number;
  soul_urge_number: number;
  subconscious_self_number: number;
  challenge_numbers: number[];
  requested_date: string;
}

/* Ascendant */
export interface CreateAscendantResponse {
  ascendant: string;
  report: string;
}

export interface CreateAscendantParams {
  day: number;
  month: number;
  year: number;
  hour: number;
  min: number;
  lat: number;
  lon: number;
  tzone: number;
}

/* General Interfaces */
export interface FetchAstrologyProps {
  credentials: CredentialsParams;
  queryType:
    | keyof typeof BaseHoroscopeQueryKeys
    | keyof typeof BaseNumerologyQueryKeys
    | 'ascendant';
  params:
    | CreateHoroscopeParams
    | CreateNumerologyParams
    | CreateAscendantParams;
}

export interface CreateNumerologyParams {
  date: number;
  month: number;
  year: number;
  full_name: string;
}

export interface CreateHoroscopeParams {
  sign?: (typeof HoroscopesSigns)[number];
}

export interface AstrologyInterface {
  fetchAstrology: (
    props: FetchAstrologyProps
  ) => Promise<
    | CreateHoroscopesResponse
    | CreateNumerologyResponse
    | CreateAscendantResponse
  >;
}

export interface CredentialsParams {
  user: string;
  key: string;
}

export interface UserProps {
  name: string;
  dob?: string;
  date?: string;
  month?: string;
  year?: string;
  sign?: string;
}

export interface UserBirthLocation {
  city_state: string;
  country: string;
  lat: number;
  lon: number;
  timezone_offset: string;
}

export interface UserBirthTime {
  hour: number;
  minute: number;
}

export interface UserPropsExtra extends UserProps {
  location?: UserBirthLocation;
  time?: UserBirthTime;
}

export interface ZodiacSignProps {
  value: string;
  display: string;
  dateRange: string;
  image: string;
  smallImage: string;
  summary: string;
}

export interface MenuSelectOptionProps {
  value: string;
  category: string;
  display: string;
  summaryDisplay: string;
  startIconUrl?: string | undefined;
  order: number;
}

export interface CompatibilityZodiacSignProps {
  compatibility: number;
  summary: string;
}

export interface CompatibilityZodiacSignOptionProps {
  [key: string]: CompatibilityZodiacSignProps;
}

export interface CompatibilityZodiacSignsProps {
  [key: string]: CompatibilityZodiacSignOptionProps;
}

export type LogErrorProps = (message: string, error: Error) => void;
