import { Box, Tab, TabsNavigation, Text, styled } from '@ltvco/refresh-lib/theme';
import { useState } from 'react';
import { CreditAnalysisTabs } from './constants';

const StyledTab = styled(Tab)(({ theme }) => ({
  width: '185px',
  borderBottom: 'none',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export const CreditAnalysisContainer = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <StyledBox p={2.5}>
      <Text variant={'h3'} mb={2.5}>
        Credit Score Analysis
      </Text>

      <TabsNavigation
        sx={{ borderBottom: 'solid' }}
        value={activeTab}
        scrollButtons={false}
        onTabChanged={(activeTab) => {
          setActiveTab(activeTab);
        }}
        tab={StyledTab}
        tabs={CreditAnalysisTabs()}
      />
    </StyledBox>
  );
};
