import { Button, Modal, Stack, Text } from '@ltvco/refresh-lib/theme';
import { StyledStackGradient } from 'components/Language/NewChatModal/components';
import { StyledImg } from '../components';
import { AchievementsCarousel } from '../AchievementsCarousel/AchievementsCarousel';
import { translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';

export type AchievementsTrackerItem = {
  title: string;
  description: string;
  image?: string;
  additionalInfo?: string | null;
  customIcon?: JSX.Element;
  disabled?: boolean;
};
interface AchievementsTrackerProps {
  customTitle?: string;
  items: AchievementsTrackerItem[];
  handleOnClose: () => void;
  newAchievements?: boolean;
}

export const AchievementsTrackerModal = ({
  handleOnClose,
  items,
  customTitle,
  newAchievements,
}: AchievementsTrackerProps) => {
  const firstItem = items[0];
  const { title, description, image, additionalInfo, customIcon, disabled } =
    firstItem;

  console.log('description', description);

  const { language } = useLanguageContext();

  return (
    <Modal
      open
      hasCloseIcon
      onClose={handleOnClose}
      sx={{
        '.MuiDialog-paper': {
          width: '360px',
        },
        '.MuiDialogContent-root': {
          padding: '0',
        },
      }}
    >
      <Stack alignItems="center" height="100%">
        {newAchievements ? (
          <Stack width="100%" alignItems="center" height="100%" gap={2}>
            <StyledStackGradient direction="column" alignItems="center">
              <Text variant="h3" mt={1}>
                {customTitle}
              </Text>
            </StyledStackGradient>
            <AchievementsCarousel items={items} />
            <Button
              variant="outlined"
              onClick={handleOnClose}
              sx={{ width: '90%', mt: 'auto', mb: '2rem' }}
            >
              {translations.buttons.backToLearning[language]}
            </Button>
          </Stack>
        ) : Boolean(customIcon) ? (
          <>
            <StyledStackGradient direction="column" alignItems="center">
              {Boolean(customIcon) && customIcon}
            </StyledStackGradient>
            <Text variant="h3" mt={Boolean(customIcon) ? 1 : 4}>
              {title}
            </Text>
            <Text
              textAlign="center"
              mt={Boolean(customIcon) ? 2 : 0}
              mx={1}
              mb={additionalInfo === undefined && image === undefined ? 2 : 0}
            >
              {description}
            </Text>
          </>
        ) : (
          <>
            <StyledStackGradient direction="column" alignItems="center">
              <Text variant="h3" mt={4}>
                {title}
              </Text>
              <Text textAlign="center" mt={Boolean(customIcon) ? 2 : 0} mx={1}>
                {description}
              </Text>
            </StyledStackGradient>
            <StyledImg
              src={image}
              alt={title}
              height="120px"
              width="120px"
              sx={{
                mt: 1,
                opacity: disabled ? 0.5 : 1,
              }}
            />
          </>
        )}
        {additionalInfo && !disabled && (
          <Text
            textAlign="center"
            variant="body2"
            fontWeight="bold"
            pb={2}
            sx={{ color: 'secondary.contrastText' }}
          >
            {translations.general.earned[language]} {additionalInfo}
          </Text>
        )}
      </Stack>
    </Modal>
  );
};
