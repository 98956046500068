import { useState, useContext } from 'react';
import { Card, styled } from '@ltvco/refresh-lib/theme';
import { Astrology, UserProps } from 'components/Astrology';
import { AstrologyHeaderLight } from '../AstrologyHeaders/AstrologyHeaderLight';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

const CardStyled = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 50%)',
  display: 'flex',
  flexDirection: 'column',

  '.ast-header-light': {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },

    '.image': {
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'start',
      },
    },
    '.description': {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
  },

  '.ast-error-container': {
    alignItems: 'start',
  },

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

interface AstrologyBannerProps {
  user: UserProps;
  identifier: string;
  disableOptions?: string[];
  disableResetCta?: boolean;
}

export const AstrologyBanner = ({
  user,
  identifier,
  disableOptions,
  disableResetCta,
}: AstrologyBannerProps) => {
  const { trackEvent, trackEventGA4 } = useContext(AppConfig);
  const [isDisplayingAstrologyContent, setIsDisplayingAstrologyContent] =
    useState(false);

  const handleCtaClick = () => {
    setIsDisplayingAstrologyContent(true);
    trackEvent('engagement click/submit', 'astrology', 'astrology component');

    trackEventGA4({
      event_name: 'report_element_engagement',
      type: 'astrology',
      interacted_from: 'banner',
      report_type: 'person',
    });
  };

  return (
    <CardStyled
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        mt: 2.5,
        border: '1px solid #E6E7E9',
        boxShadow: 'unset',
      })}
    >
      {!isDisplayingAstrologyContent && (
        <AstrologyHeaderLight enableViewCTA onCtaClick={handleCtaClick} />
      )}
      {isDisplayingAstrologyContent && (
        <Astrology
          user={user}
          identifier={identifier}
          disableOptions={disableOptions}
          disableResetCta={disableResetCta}
        />
      )}
    </CardStyled>
  );
};
