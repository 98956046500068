import { useContext, useEffect, useState } from 'react';
import { Box } from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { AstrologyOnboarding } from './AstrologyOnboarding/AstrologyOnboarding';
import { AstrologyHeader } from './AstrologyHeaders';
import {
  type MenuSelectOptionProps,
  type UserProps,
  type UserPropsExtra,
} from './interfaces';
import { MenuOptions, ZodiacSigns, ZodiacSignsUnselected } from './constants';
import {
  getAstrologyUser,
  getZodiacSign,
  resetAstrologyUserLocalStorage,
  deleteNumerologyLocalStorageData,
  deleteAscendantLocalStorageData,
  deleteExtraAstrologyUserLocalData,
  getAvailableMenuConfig,
} from './utils';
import { MainContainer, HeaderContainer } from './components';
import { AstrologySummary } from './AstrologySummary/AstrologySummary';
import { AstrologyHoroscope } from './AstrologyHoroscope/AstrologyHoroscope';
import { AstrologyCompatibility } from './AstrologyCompatibility/AstrologyCompatibility';
import { AstrologyNumerology } from './AstrologyNumerology/AstrologyNumerology';
import { AstrologyAscendant } from './AstrologyAscendent/AstrologyAscendant';
import { AstrologyError } from './AstrologyError/AstrologyError';
import { useHoroscope } from './useHoroscope';
import { useNumerology } from './useNumerology';
import { AstrologyLoading } from './AstrologyLoading/AstrologyLoading';
import { useAscendant } from './useAscendant';
import {
  AstrologyStandalone,
  AstrologyStandaloneHeader,
  AstrologyStandaloneOnboarding,
} from 'components/AstrologyStandalone';

interface AstrologyProps {
  user?: UserProps;
  /* identifier is used to identify the user in the database it should be a permalink for reports or a memberId for user accounts */
  identifier: string;
  disableResetCta?: boolean;
  enableOnboarding?: boolean;
  disableOptions?: string[];
  standaloneVersion?: boolean;
}

export const Astrology = ({
  user,
  identifier,
  enableOnboarding,
  disableOptions = [],
  disableResetCta,
  standaloneVersion,
}: AstrologyProps) => {
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const { logError } = useContext(AppConfig);

  const availableMenuOptions = getAvailableMenuConfig(disableOptions);

  const [astrologyUser, setAstrologyUser] = useState<UserPropsExtra | null>(
    user || null
  );
  const [activeHeaderMenuOption, setActiveHeaderMenuOption] =
    useState<MenuSelectOptionProps>(MenuOptions.summary);
  const [compatibilitySign, setCompatibilitySign] = useState<string>(
    ZodiacSignsUnselected.value
  );

  const zodiacSign = astrologyUser ? getZodiacSign(astrologyUser) : '';
  const zodiacSignData = ZodiacSigns.find(
    (sign) => sign.value === zodiacSign.toLowerCase()
  );

  let numerologyDobData;
  let astrologyUserDetailedBirthData;

  if (astrologyUser && enableOnboarding) {
    const {
      date = '',
      month = '',
      year = '',
      name,
      location,
      time,
    } = astrologyUser;
    const parsedDate = parseInt(date);
    const parsedMonth = parseInt(month);
    const parsedYear = parseInt(year);

    numerologyDobData = {
      date: parsedDate,
      month: parsedMonth,
      year: parsedYear,
      full_name: name,
    };

    if (location && time) {
      const { lat = 0, lon = 0, timezone_offset = '0' } = location;
      const { hour = 0, minute = 0 } = time;

      astrologyUserDetailedBirthData = {
        day: parsedDate,
        month: parsedMonth,
        year: parsedYear,
        hour,
        min: minute,
        lat,
        lon,
        tzone: parseFloat(timezone_offset),
      };
    }
  }

  const {
    data: yesterdayHoroscopeData,
    isError: isYesterdayHoroscopeError,
    isLoading: isYesterdayHoroscopeLoading,
  } = useHoroscope('yesterday', zodiacSign, !!zodiacSign);

  const {
    data: todayHoroscopeData,
    isError: isTodayHoroscopeError,
    isLoading: isTodayHoroscopeLoading,
  } = useHoroscope('today', zodiacSign, !!zodiacSign);

  const {
    data: tomorrowHoroscopeData,
    isError: isTomorrowHoroscopeError,
    isLoading: isTomorrowHoroscopeLoading,
  } = useHoroscope('tomorrow', zodiacSign, !!zodiacSign);

  const {
    data: monthlyHoroscopeData,
    isError: isMonthlyHoroscopeError,
    isLoading: isMonthlyHoroscopeLoading,
  } = useHoroscope('monthly', zodiacSign, !!zodiacSign);

  const {
    data: personalityNumberData,
    isError: isPersonalityNumberError,
    isLoading: isPersonalityNumberLoading,
  } = useNumerology(
    'personality',
    identifier,
    numerologyDobData,
    !!numerologyDobData && Boolean(enableOnboarding)
  );

  const {
    data: lifepathNumberData,
    isError: isLifepathNumberError,
    isLoading: isLifepathNumberLoading,
  } = useNumerology(
    'lifepath',
    identifier,
    numerologyDobData,
    !!numerologyDobData && Boolean(enableOnboarding)
  );

  const {
    data: ascendantData,
    isError: isAscendantError,
    isLoading: isAscendantLoading,
    remove: removeAscendantQueryData,
  } = useAscendant(
    identifier,
    astrologyUserDetailedBirthData,
    !!astrologyUserDetailedBirthData && Boolean(enableOnboarding)
  );

  useEffect(() => {
    if (!user && enableOnboarding) {
      const astrologyUser = getAstrologyUser(logError);
      setAstrologyUser(astrologyUser);
    }
    activateFakeLoading();
  }, []);

  const setAstrologyUserCallback = (astrologyUser: UserPropsExtra | null) => {
    activateFakeLoading();
    setAstrologyUser(astrologyUser);
  };

  const isLoading =
    isYesterdayHoroscopeLoading ||
    isTodayHoroscopeLoading ||
    isTomorrowHoroscopeLoading ||
    isMonthlyHoroscopeLoading ||
    isPersonalityNumberLoading ||
    isLifepathNumberLoading ||
    isAscendantLoading ||
    isFakeLoading;

  const hasErrors =
    isYesterdayHoroscopeError ||
    isTodayHoroscopeError ||
    isTomorrowHoroscopeError ||
    isMonthlyHoroscopeError ||
    isPersonalityNumberError ||
    isAscendantError ||
    isLifepathNumberError;

  if (hasErrors) {
    return (
      <MainContainer>
        <AstrologyError />
      </MainContainer>
    );
  }

  if (isLoading && !hasErrors) {
    return standaloneVersion ? (
      <>
        <AstrologyStandaloneHeader />
        <AstrologyLoading standaloneVersion={standaloneVersion} />
      </>
    ) : (
      <MainContainer>
        <AstrologyLoading />
      </MainContainer>
    );
  }

  if (!astrologyUser && enableOnboarding) {
    return standaloneVersion ? (
      <AstrologyStandaloneOnboarding
        onSubmitCallback={setAstrologyUserCallback}
      />
    ) : (
      <MainContainer display="flex" justifyContent="center" alignItems="center">
        <AstrologyOnboarding onSubmitCallback={setAstrologyUserCallback} />
      </MainContainer>
    );
  }

  function activateFakeLoading() {
    setIsFakeLoading(true);
    setTimeout(() => {
      setIsFakeLoading(false);
    }, 2000);
  }

  const removeAscendantLocalData = () => {
    deleteExtraAstrologyUserLocalData(logError);
    deleteAscendantLocalStorageData(identifier, logError);
    removeAscendantQueryData();
  };

  function resetAstrologyUserCallback() {
    deleteNumerologyLocalStorageData(identifier, logError);
    removeAscendantLocalData();
    resetAstrologyUserLocalStorage();
    setAstrologyUser(null);
    setActiveHeaderMenuOption(MenuOptions.summary);
    setCompatibilitySign(ZodiacSignsUnselected.value);
  }

  const resetAscendantData = () => {
    removeAscendantLocalData();
    const newAstrologyUser = getAstrologyUser(logError);
    setAstrologyUser(newAstrologyUser);
  };

  function onSummaryOptionClick(option: MenuSelectOptionProps) {
    setActiveHeaderMenuOption(option);
  }

  function getActiveMenuContent() {
    if (activeHeaderMenuOption.value === MenuOptions.horoscope.value) {
      return (
        <AstrologyHoroscope
          yesterdayContent={yesterdayHoroscopeData?.prediction as object}
          todayContent={todayHoroscopeData?.prediction as string}
          tomorrowContent={tomorrowHoroscopeData?.prediction as object}
          monthlyContent={monthlyHoroscopeData?.prediction as string[]}
        />
      );
    } else if (
      activeHeaderMenuOption.value === MenuOptions.compatibility.value
    ) {
      return (
        <AstrologyCompatibility
          zodiacSign={zodiacSign}
          compatibilitySign={compatibilitySign}
        />
      );
    } else if (activeHeaderMenuOption.value === MenuOptions.personality.value) {
      return <AstrologyNumerology data={personalityNumberData?.report} />;
    } else if (activeHeaderMenuOption.value === MenuOptions.lifepath.value) {
      return <AstrologyNumerology data={lifepathNumberData?.report} />;
    } else if (activeHeaderMenuOption.value === MenuOptions.ascendant.value) {
      return (
        <AstrologyAscendant
          date={astrologyUser?.date}
          month={astrologyUser?.month}
          year={astrologyUser?.year}
          ascendantResult={ascendantData}
          setAstrologyUserCallback={setAstrologyUserCallback}
        />
      );
    }

    return (
      <AstrologySummary
        options={availableMenuOptions}
        zodiacSignData={zodiacSignData}
        onOptionClick={onSummaryOptionClick}
        lifepathNumber={lifepathNumberData?.lifepath_number.toString() || ''}
        personalityNumber={
          personalityNumberData?.personality_number.toString() || ''
        }
      />
    );
  }

  return standaloneVersion ? (
    <AstrologyStandalone
      zodiacSign={zodiacSign}
      compatibilitySign={compatibilitySign}
      birthDate={astrologyUser?.date}
      birthMonth={astrologyUser?.month}
      birthYear={astrologyUser?.year}
      ascendantResult={ascendantData}
      ascendantSign={ascendantData?.ascendant}
      setAstrologyUserCallback={setAstrologyUserCallback}
      resetAstrologyUser={resetAstrologyUserCallback}
      setCompatibilitySign={setCompatibilitySign}
      yesterdayContent={yesterdayHoroscopeData?.prediction as object}
      todayContent={todayHoroscopeData?.prediction as string}
      tomorrowContent={tomorrowHoroscopeData?.prediction as object}
      monthlyContent={monthlyHoroscopeData?.prediction as string[]}
      astrologyUser={astrologyUser}
      zodiacSignData={zodiacSignData}
      menuOptions={availableMenuOptions}
      activeHeaderMenuOption={activeHeaderMenuOption}
      setActiveHeaderMenuOption={setActiveHeaderMenuOption}
      personalityNumberData={personalityNumberData}
      lifepathNumberData={lifepathNumberData}
      resetAscendantData={resetAscendantData}
    />
  ) : (
    <MainContainer p={0}>
      <HeaderContainer p={2.5} pt={2.75}>
        <AstrologyHeader
          menuOptions={availableMenuOptions}
          astrologyUser={astrologyUser}
          activeHeaderMenuOption={activeHeaderMenuOption}
          setActiveHeaderMenuOption={setActiveHeaderMenuOption}
          resetAstrologyUser={resetAstrologyUserCallback}
          zodiacSign={zodiacSign}
          compatibilitySign={compatibilitySign}
          setCompatibilitySign={setCompatibilitySign}
          ascendantSign={ascendantData?.ascendant}
          lifepathNumber={lifepathNumberData?.lifepath_number.toString() || ''}
          personalityNumber={
            personalityNumberData?.personality_number.toString() || ''
          }
          disableResetCta={disableResetCta}
        />
      </HeaderContainer>
      <Box padding={2.5} pt={1}>
        {getActiveMenuContent()}
      </Box>
    </MainContainer>
  );
};
