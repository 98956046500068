import { BumpeeRequests } from 'api';
import { useLanguageContext } from '../LanguageContext';
import { useContext } from 'react';
import { translations } from '../constants';
import { enqueueSnackbar, useServiceToken } from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQueryClient, useQuery, useMutation } from '@ltvco/refresh-lib/vendors';

const baseQueryKey = 'bumpeeGO';

export const useBumpeeQuizChat = (quizId: string) => {
  const queryClient = useQueryClient();
  const { logError } = useContext(AppConfig);
  const { data: tokenData } = useServiceToken('bumpee');
  const { language, toggleLoadingLessonModal } = useLanguageContext();

  const results = useQuery({
    queryKey: [`${baseQueryKey}-quiz-chat-${quizId}`],
    queryFn: () => BumpeeRequests.getQuizChat(tokenData.token, quizId),
    enabled: Boolean(tokenData) && Boolean(quizId),
    onError: (error) => {
      enqueueSnackbar('Failed to get quiz', { variant: 'error' });
    },
  });

  const retakeQuiz = useMutation({
    mutationFn: (quizChatId: number) => {
      return BumpeeRequests.retakeQuiz(tokenData.token, quizChatId);
    },
    onMutate: () => toggleLoadingLessonModal(),
    onSuccess: (data) => {
      queryClient.invalidateQueries([`${baseQueryKey}-quiz-chats`]);
      queryClient.invalidateQueries([`${baseQueryKey}-quiz-chat-${quizId}`]);
      queryClient.invalidateQueries([
        `${baseQueryKey}-chat-${data?.quiz_chat?.chat_id}-messages`,
      ]);
    },
    onError: (error: Error) => {
      enqueueSnackbar(translations.errors.quiz[language], {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Failed to create a quiz', error as Error);
    },
    onSettled: () => toggleLoadingLessonModal(),
  });

  return { ...results, retakeQuiz };
};
