import {  Stack, SxProps, styled } from '@ltvco/refresh-lib/theme';
import { translations } from './constants';
import { useLanguageContext } from './LanguageContext';
import { ScienceOutlined } from '@mui/icons-material';

export const StyledPill = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.primary.main,
  height: '24px',
  fontSize: '0.875rem',
  borderRadius: '14px',
  flexDirection: 'row',
  justifyContent: 'center',
  marginLeft: theme.spacing(1.5),
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',

  '.MuiButton-startIcon': {
    marginRight: theme.spacing(0.5),

    '.MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },

  '&.Mui-disabled': {
    color: theme.palette.primary.main,
  },
}));

interface BetaPillProps {
  customStyle?: SxProps;
}

export const BetaPill = ({ customStyle }: BetaPillProps) => {
  const { language } = useLanguageContext();

  return (
    <StyledPill color="primary" sx={customStyle}>
      <ScienceOutlined sx={{ fontSize: '0.875rem', mr: 0.5 }} />
      {translations.dashboard.header.iconText[language]}
    </StyledPill>
  );
};
