import { Stack } from '@ltvco/refresh-lib/theme';
import {
  AstrologyNumerologyProps,
  withAstrologyNumerology,
  AstrologyIcon,
  MenuSelectOptionProps,
} from 'components/Astrology';
import { AstrologyStandaloneSectionTextField } from './components';

const SummaryContent = ({ content }: AstrologyNumerologyProps) => {
  return <AstrologyStandaloneSectionTextField content={content} rows={6} />;
};

const AstrologyStandaloneNumerologyContent =
  withAstrologyNumerology(SummaryContent);

export interface NumerologyData {
  report?: string[];
  personality_number?: number;
  lifepath_number?: number;
}

interface AstrologyStandaloneNumerologyProps {
  menuOptions: { [key: string]: MenuSelectOptionProps };
  activeHeaderMenuOption: MenuSelectOptionProps;
  personalityNumberData?: NumerologyData;
  lifepathNumberData?: NumerologyData;
}

export const AstrologyStandaloneNumerology = ({
  menuOptions,
  activeHeaderMenuOption,
  personalityNumberData,
  lifepathNumberData,
}: AstrologyStandaloneNumerologyProps) => {
  const filteredMenuOptions = {
    lifepath: menuOptions.lifepath,
    personality: menuOptions.personality,
  };
  const currentActiveOption = [
    filteredMenuOptions.lifepath.value,
    filteredMenuOptions.personality.value,
  ].includes(activeHeaderMenuOption.value)
    ? activeHeaderMenuOption
    : filteredMenuOptions.lifepath;
  const isPersonalityActive =
    activeHeaderMenuOption.value === filteredMenuOptions.personality.value;
  const activeNumerologyNumber = isPersonalityActive
    ? personalityNumberData?.personality_number?.toString() ?? ''
    : lifepathNumberData?.lifepath_number?.toString() ?? '';
  return (
    <Stack gap={2} p={1.5} mt={2} spacing={2}>
      <AstrologyIcon
        bottomText={`${currentActiveOption.display} Number ${activeNumerologyNumber}`}
        number={activeNumerologyNumber}
      />
      {currentActiveOption.value === filteredMenuOptions.lifepath.value && (
        <AstrologyStandaloneNumerologyContent
          data={lifepathNumberData?.report}
        />
      )}
      {currentActiveOption.value === filteredMenuOptions.personality.value && (
        <AstrologyStandaloneNumerologyContent
          data={personalityNumberData?.report}
        />
      )}
    </Stack>
  );
};
