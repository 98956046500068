import {
  Text,
  Stack,
  Button,
  Box,
  Divider,
  styled,
} from '@ltvco/refresh-lib/theme';
import { Message, StoryChatConversation } from 'api/requests/Bumpee/interfaces';
import { LeftBubble, RightBubble } from './components';
import { useBumpeeTranslation } from 'components/Language/hooks/useBumpeeTranslation';
import { useBumpeeMessageAudio } from 'components/Language/hooks/useBumpeeMessageAudio';
import { ClickableWord } from './ClickableWord/ClickableWord';
import { translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { useEffect, useState, useRef } from 'react';
import { ConversationAudioBubble } from '../ConversationAudioBubble/ConversationAudioBubble';
import { Markdown } from '../Markdown/Markdown';
import { ChatTextLoading } from '@ltvco/refresh-lib/v1';
import { Translate, VolumeUpOutlined } from '@mui/icons-material';

const StyledStack = styled(Stack)({
  button: {
    minWidth: '18px',
    padding: '6px',
  },
});

interface ChatBubbleProps {
  message: Message;
  isLessonChat?: boolean;
  isStoryChat?: boolean;
  isQuizChat?: boolean;
  disableWordTranslations?: boolean;
  segmentIndex?: number;
  storyChatId?: number;
  conversation?: StoryChatConversation;
  playingAudioId: number | null;
  setPlayingAudioId: (segmentIndex: number | null) => void;
}

export function ChatBubble({
  message,
  isLessonChat = false,
  isQuizChat = false,
  disableWordTranslations = false,
  segmentIndex,
  storyChatId,
  isStoryChat,
  conversation,
  playingAudioId,
  setPlayingAudioId,
}: ChatBubbleProps) {
  const { currentAudioRef, setCurrentAudioRef, language } =
    useLanguageContext();
  const { role, content } = message;
  const {
    translateContent,
    handleTranslateClick,
    isLoading: isLoadingTranslation,
  } = useBumpeeTranslation(message.chat_id, message.id);
  const { data: audioData, handleAudioClick } = useBumpeeMessageAudio(
    message.chat_id,
    message.id
  );
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [shouldAutoplay, setShouldAutoplay] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioData && audioData.audio) {
      const binaryString = atob(audioData.audio);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }

      const blob = new Blob([bytes], { type: 'audio/mpeg' });
      const url = URL.createObjectURL(blob);
      setAudioUrl(url);

      // Cleanup URL.createObjectURL to avoid memory leaks
      return () => URL.revokeObjectURL(url);
    }
  }, [audioData]);

  const onAudioClick = () => {
    if (currentAudioRef && currentAudioRef.current) {
      currentAudioRef.current.pause();
      currentAudioRef.current.currentTime = 0;
    }
    setCurrentAudioRef(audioRef);

    if (!audioUrl) {
      setShouldAutoplay(true);
      handleAudioClick();
    } else if (audioRef && audioRef.current) {
      audioRef.current.play();
    }
  };

  const shouldShowTranslate = !isLessonChat && !isStoryChat && !isQuizChat;

  const hasValidsegmentIndex =
    Number.isInteger(segmentIndex) && segmentIndex! > -1;

  return (
    <Stack id={`message-${message.id}`}>
      {role === 'user' ? (
        <Box width="100%" m={1} display="flex" justifyContent="flex-end">
          <RightBubble>{content}</RightBubble>
        </Box>
      ) : (
        <Box width="100%" m={1}>
          {hasValidsegmentIndex && conversation ? (
            <ConversationAudioBubble
              key={`message-${message.id}`}
              message={message}
              storyChatId={storyChatId}
              segmentIndex={segmentIndex!}
              conversation={conversation}
              playingAudioId={playingAudioId}
              setPlayingAudioId={setPlayingAudioId}
            />
          ) : (
            <LeftBubble id={message.id.toString()}>
              <Stack width="100%">
                {isQuizChat ? (
                  <Markdown content={content} />
                ) : (
                  <Box mb={1} display="flex" flexWrap="wrap">
                    {disableWordTranslations
                      ? content
                      : content.split(' ').map((word, index) => {
                          return (
                            <ClickableWord
                              key={`word-${index}`}
                              word={word}
                              index={index}
                              chatId={message.chat_id}
                              messageId={message.id}
                              isLessonChat={isLessonChat}
                            />
                          );
                        })}
                  </Box>
                )}

                {translateContent.open && (
                  <Box>
                    <Box mb={1}>
                      <Divider />
                    </Box>
                    {isLoadingTranslation ? (
                      <ChatTextLoading />
                    ) : (
                      <>
                        <Text
                          variant="body2"
                          mb={0.5}
                          sx={{ color: '#8B88AA' }}
                        >
                          {translations.chatUI.translation[language]}
                        </Text>
                        <Text
                          variant="body2"
                          sx={{ color: '#00000099', textAlign: 'left' }}
                        >
                          {translateContent.content}
                        </Text>
                      </>
                    )}
                  </Box>
                )}
                {shouldShowTranslate && (
                  <StyledStack
                    flexDirection={'row'}
                    justifyContent={'flex-end'}
                  >
                    <Button onClick={onAudioClick}>
                      <VolumeUpOutlined
                        fontSize="small"
                        sx={{ color: 'primary.light' }}
                      />
                      {audioUrl && (
                        <audio autoPlay={shouldAutoplay} ref={audioRef}>
                          <source src={audioUrl} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      )}
                    </Button>

                    <Button onClick={handleTranslateClick}>
                      <Translate
                        fontSize="small"
                        sx={{ color: 'primary.light' }}
                      />
                    </Button>
                  </StyledStack>
                )}
              </Stack>
            </LeftBubble>
          )}
        </Box>
      )}
    </Stack>
  );
}
