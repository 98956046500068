import {
  Button,
  Modal,
  Text,
  Stack,
  useTheme,
} from '@ltvco/refresh-lib/theme';
import { StepTwo } from '../OnBoarding/StepTwo';
import { StepThree } from '../OnBoarding/StepThree';
import { StyledUnderlineButton } from '../OnBoarding/components';
import { StyledDrawer } from '../LanguageHeader/LanguageSettings/LanguageSettings';
import {
  StyledCloseButton,
  StyledStack,
  StyledStackGradient,
} from './components';
import { SelectedOptions } from '../OnBoarding/OnBoarding';
import { translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { Close, AddCommentOutlined } from '@mui/icons-material';

interface NewChatModalProps {
  setNewChatModalOpen: (value: boolean) => void;
  selectedOptions: SelectedOptions;
  setSelectedOptions: (value: SelectedOptions) => void;
  handleOnClose: () => void;
  handleStartConversation: () => void;
}

export const NewChatModal = ({
  setSelectedOptions,
  selectedOptions,
  handleOnClose,
  handleStartConversation,
}: NewChatModalProps) => {
  const theme = useTheme();
  const { isMobile } = useScreenSize();
  const ChatContainer = isMobile ? StyledDrawer : Modal;
  const { language } = useLanguageContext();

  return (
    <Stack pt={0}>
      <ChatContainer
        open
        onClose={handleOnClose}
        {...(isMobile && { anchor: 'bottom' })}
        {...(!isMobile && { hasCloseIcon: true })}
        sx={{
          '.MuiPaper-root': {
            paddingTop: 0,
          },
          '.MuiDialogContent-root': {
            padding: 0,
          },
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="flex-start"
          height="100%"
          position="relative"
        >
          {isMobile && (
            <StyledCloseButton onClick={handleOnClose}>
              <Close />
            </StyledCloseButton>
          )}
          <StyledStackGradient>
            <AddCommentOutlined fontSize="large" sx={{ color: theme.palette.primary.main}} />
            <Text variant="h2" mt={1}>
              {translations.buttons.create[language]}
            </Text>
          </StyledStackGradient>
          <Stack alignItems="center" p={2} pt={0}>
            <StepTwo
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
              isOnboarding={false}
            />
            <StepThree
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
              isOnboarding={false}
            />
          </Stack>
          <StyledStack>
            <StyledUnderlineButton onClick={handleOnClose}>
              {translations.buttons.cancel[language]}
            </StyledUnderlineButton>
            <Button
              className="language-continue"
              variant="contained"
              onClick={handleStartConversation}
              disabled={
                selectedOptions.learning_language_id === 0 ||
                selectedOptions.level === '' ||
                selectedOptions.topic_id === 0
              }
            >
              {translations.buttons.start[language]}
            </Button>
          </StyledStack>
        </Stack>
      </ChatContainer>
    </Stack>
  );
};
