import {
  useSessionEscalation,
  useSession,
  useServiceToken,
} from '@ltvco/refresh-lib/v1';
import { GettingStarted } from './GettingStarted/GettingStarted';
import { useEffect, useState } from 'react';
import { useQueryParams } from 'utils/useQueryParams';
import { useOxford } from './Oxford/Oxford';
import { SignUpForm } from './SignUp/SignUpForm';
import { ErrorModal } from './ErrorModal/ErrorModal';
import { Loading } from './Loading';
import { KnowledgeBasedAuthentication } from './KnowledgeBasedAuthentication/KnowledgeBasedAuthentication';
import { sessionEscalationURL } from './Oxford/constants';
import AuthenticatedContent from './AuthenticatedContent';
import { UseAuthentication } from './Oxford/useAuthentication';
import { useOneTimePasscodeContext } from './Oxford/OneTimePasscodeContext';

export const IdMonitorContainer = () => {
  document.title = 'ID Monitor - BeenVerified';

  const [sentOneTimePasscodeCount, setSentOneTimePasscodeCount] = useState(0);

  const escalatedParam = useQueryParams().get('escalated');

  const { checkEmail, sessionAuthorized, checkSessionEscalation } =
    useSessionEscalation();
  const { refetch: getSessionEscalation } = checkSessionEscalation;

  const {
    getUserProfile,
    getAuthenticationQuestions,
    getAuthenticationCheckAnswers,
    setEnabledUserProfileQuery,
    isLoading: isOxfordLoading,
    isOxfordError,
    enabledUserProfileQuery,
  } = useOxford();

  const { data: userProfileData } = getUserProfile;
  const { data: authenticationQuestionsData } = getAuthenticationQuestions;
  const { data: authenticationCheckAnswersData } =
    getAuthenticationCheckAnswers;
  const authenticationResponse =
    authenticationQuestionsData ?? authenticationCheckAnswersData;

  const {
    session: { account },
  } = useSession();

  const { blackBoxId } = useOneTimePasscodeContext();

  const isEnrolled =
    account?.account.user_settings.credit_hub_enrollment_completed;

  const { refetch: refetchServiceToken, data: tokenData } = useServiceToken(
    'oxford',
    sessionEscalationURL
  );

  useEffect(() => {
    if ((isEnrolled || escalatedParam === 'true') && !checkEmail) {
      getSessionEscalation();
    }
  }, [escalatedParam, isEnrolled, checkEmail, getSessionEscalation]);

  useEffect(() => {
    if (sessionAuthorized && !tokenData) {
      refetchServiceToken();
    }
  }, [sessionAuthorized, refetchServiceToken, tokenData]);

  useEffect(() => {
    if (tokenData?.token && blackBoxId !== undefined && sessionAuthorized) {
      setEnabledUserProfileQuery(true);
    }
  }, [tokenData, setEnabledUserProfileQuery, blackBoxId, sessionAuthorized]);

  const increaseSentOneTimePasscodeCount = () => {
    setSentOneTimePasscodeCount(sentOneTimePasscodeCount + 1);
  };

  const display = () => {
    if (sessionAuthorized && enabledUserProfileQuery) {
      if (isOxfordError) {
        return <ErrorModal type={'generic'} />;
      }

      if (isOxfordLoading || blackBoxId === undefined) {
        return <Loading />;
      }

      if (userProfileData !== undefined) {
        if (
          [
            userProfileData?.authenticated,
            authenticationResponse?.authenticated,
          ].includes(true)
        ) {
          return <AuthenticatedContent />;
        } else if (authenticationResponse?.next_attempt_date) {
          if (sentOneTimePasscodeCount > 0) {
            return <ErrorModal type={'oneTimePasscodeAuthenticationError'} />;
          } else {
            return <ErrorModal type={'authenticationAnswersIncorrect'} />;
          }
        } else if (authenticationResponse?.update_user_profile === true) {
          return <ErrorModal type={'retryUserProfile'} />;
        } else if (authenticationResponse?.update_user_profile === false) {
          return <ErrorModal type={'failedUserProfile'} />;
        } else if (
          authenticationResponse?.in_progress === true &&
          !UseAuthentication.isOneTimePasscodeQuestion(
            authenticationResponse?.questionList
          )
        ) {
          return <ErrorModal type={'authenticationInProgress'} />;
        } else if (authenticationResponse?.questionList) {
          return (
            <KnowledgeBasedAuthentication
              sentOneTimePasscodeCount={sentOneTimePasscodeCount}
              increaseSentOneTimePasscodeCount={
                increaseSentOneTimePasscodeCount
              }
            />
          );
        }
      }

      return <SignUpForm />;
    } else {
      return <GettingStarted />;
    }
  };

  return <>{display()}</>;
};
