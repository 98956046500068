import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { Box, styled } from '@ltvco/refresh-lib/theme';
import { useLocation } from 'react-router-dom';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { useBannerLogic } from '@ltvco/refresh-lib/v2';

const AppWrapper = styled('div')(({ theme }) => ({
  margin: '70px 8px 0 8px',
  backgroundColor: theme.palette.background.default,
  paddingBottom: theme.spacing(0.25),
  [theme.breakpoints.up('md')]: {
    margin: '55px 0 0 0',
  },
}));

export function AppContainer({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const location = useLocation();
  const { isDesktop } = useScreenSize();
  const { isUserInTest } = useBannerLogic();
  const isOnReportView = location.pathname.includes('/report/');

  const wrapperId =
    (useFeatureIsOn('RTRN-68') || isUserInTest) && isDesktop
      ? 'app-container'
      : undefined;

  return (
    <AppWrapper
      id={wrapperId}
      sx={{
        marginX: isOnReportView ? 0 : null,
        marginTop: isOnReportView ? '70px' : '80px',
      }}
    >
      <Box>{children}</Box>
    </AppWrapper>
  );
}
