import { useQuery } from '@ltvco/refresh-lib/vendors';
import { useServiceToken } from '@ltvco/refresh-lib/v1';
import { BumpeeRequests } from 'api';
import { useEffect, useState } from 'react';

interface TranslationInterface {
  content: string;
  shouldTranslate: boolean;
}

export const useBumpeeTranslations = (chatId: number, text: string) => {
  const { data: tokenData } = useServiceToken('bumpee');
  const [translation, setTranslation] = useState<TranslationInterface>({
    content: '',
    shouldTranslate: false,
  });

  const results = useQuery({
    queryKey: [`bumpeeGO-translations-${chatId}`],
    queryFn: () => {
      return BumpeeRequests.getTextTranslation(tokenData?.token, text, chatId);
    },
    enabled: translation.shouldTranslate && Boolean(tokenData?.token),
    onSuccess: (data: any) => {
      setTranslation((prevState) => ({
        ...prevState,
        content: data.translation,
        shouldTranslate: false,
      }));
    },
  });

  const handleTranslateClick = () => {
    setTranslation((prevState) => ({
      ...prevState,
      shouldTranslate: true,
    }));
  };

  useEffect(() => {
    if (results.isLoading) {
      setTranslation((prevState) => ({
        ...prevState,
        content: '',
      }));
    }
  }, [results.isLoading]);

  return { ...results, translation, handleTranslateClick };
};
