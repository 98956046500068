import { Stack } from '@ltvco/refresh-lib/theme';
import idMonitorNortonlogo from 'images/id-monitor-norton-logo.svg';
import idMonitorSoc2Logo from 'images/id-monitor-soc2-logo.svg';
import idMonitorSecureServer from 'images/id-monitor-secure-server.svg';

export const SecurityFooter = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.5}
      marginTop={1.5}
      sx={{
        justifyContent: ['center', 'flex-end'],
      }}
    >
      <img src={idMonitorSoc2Logo} alt="soc2-logo" height="34px" width="34px" />
      <img
        src={idMonitorNortonlogo}
        alt="norton-logo"
        height="27px"
        width="58px"
      />
      <img
        src={idMonitorSecureServer}
        alt="secure-server"
        width="73px"
        height="24px"
      />
    </Stack>
  );
};
