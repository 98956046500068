import { Button, Stack, styled } from '@ltvco/refresh-lib/theme';

export const ViewAstrologyButton = styled(Button)(({ theme }) => ({
  minWidth: 'max-content',
  flexGrow: 1,
  maxHeight: '36px',
  margin: 'auto',

  width: '100%',

  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    maxWidth: '172px',
    width: 'inherit',
  },
}));

export const StyledImg = styled('img')(({ theme }) => ({
  marginBottom: '0.5rem',
  alignSelf: 'center',

  '&.left-variant': {
    alignSelf: 'left',
  },

  [theme.breakpoints.up('sm')]: {
    alignSelf: 'start',
  },
}));

export const StyledDescription = styled(Stack)(({ theme }) => ({
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    textAlign: 'left',
  },
}));
