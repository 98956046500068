import { Modal, styled } from '@ltvco/refresh-lib/theme';

export const StyledModal = styled(Modal)({
  '.MuiDialog-paper': {
    margin: 0,
    maxWidth: '100%',
    maxHeight: '100%',
    width: '100%',
    height: '100%',
  },

  '.MuiDialogContent-root': {
    display: 'flex',
    justifyContent: 'center',
  },
});
