import { Language, Level, Topic } from 'api';
import imgEnglishBeverly from 'images/img-english-beverly.svg';
import imgFrenchBeverly from 'images/img-french-beverly.svg';
import imgGermanBeverly from 'images/img-german-beverly.svg';
import imgItalianBeverly from 'images/img-italian-beverly.svg';
import imgPortugueseBeverly from 'images/img-portuguese-beverly.svg';
import imgSpanishBeverly from 'images/img-spanish-beverly.svg';

type Translations = {
  languages: {
    [key: string]: Language[];
  };
  topics: {
    [key: string]: Topic[];
  };
  levels: {
    [key: string]: Level[];
  };
  buttons: Buttons;
  avatar: {
    [key: string]: string;
  };
  transitionChatModal: TransitionChatModal;
  transitionAssignmentModal: TransitionAssignmentModal;
  translatorModal: TranslatorModal;
  onBoarding: onBoarding;
  dashboard: {
    header: Header;
    giveFeedback: Feedback;
    languageSettings: Settings;
    chats: Chats;
    assignments: {
      lesson: {
        header: Item;
        selectLabel: Item;
        lessonList: {
          [key: string]: {
            id: number;
            title: string;
            completed: boolean;
            started: boolean;
          }[];
        };
      };
      quiz: {
        header: Item;
        quizList: {
          [key: string]: {
            id: number;
            title: string;
          }[];
        };
      };
      story: {
        header: Item;
        storyList: {
          [key: string]: {
            id: number;
            title: string;
            completed: boolean;
            started: boolean;
          }[];
        };
      };
    };
    assignmentCardTitles: Item;
  };
  chatUI: {
    recentChats: Item;
    hint: Item;
    translation: Item;
    inputPlaceholder: Item;
    lessons: {
      header: Item;
      introduction: Item;
      completedCard: CompletedCard;
    };
    quiz: {
      introduction: Item;
    };
    story: {
      introduction: Item;
    };
  };
  errors: {
    quiz: Item;
  };
  badges: {
    [key: string]: {
      id: string;
      name: string;
      tooltip: string;
    }[];
  };
  streaks: {
    currentStreak: Item;
    bestStreak: Item;
    tooltip: Item;
    modalTitle: Item;
  };
  achievements: {
    modal: {
      title: Item;
      subtitle: Item;
    };
    dashboardCard: {
      title: {
        [key: string]: AchievementLanguageTitle;
      };
    };
  };
  general: {
    newTranslation: Item;
    achievement: Item;
    earned: Item;
    newAchievementEarned: Item;
  };
};

type AchievementLanguageTitle = {
  first: string;
  latest: string;
};

type TransitionAssignmentModal = {
  title: Item;
};

type Header = {
  title: Item;
  subtitle: Item;
  description: Item;
  iconText: Item;
};

type Feedback = {
  title: Item;
  question: Item;
  options: ItemArray;
  thanks: Item;
  request: Item;
};

type Settings = {
  title: Item;
  nativeLanguage: Item;
};

type Chats = {
  title: Item;
  emptyState: {
    title: Item;
    description: Item;
  };
};

type TransitionChatModal = {
  greeting: Item;
  description: {
    [key: string]: Item;
  };
};

type TranslatorModal = {
  title: {
    [key: string]: Item;
  };
  placeholder: Item;
  tooltip: {
    [key: string]: Item;
  };
};

type onBoarding = {
  title: Item;
  description: Item;
  questions: {
    learningLanguage: Item;
    proficiency: {
      [key: string]: Item;
    };
    topic: Item;
  };
};

type CompletedCard = {
  heading: Item;
  subHeading: Item;
  buttonText: Item;
};

type Buttons = {
  create: Item;
  remove: Item;
  back: Item;
  continue: Item;
  cancel: Item;
  start: Item;
  save: Item;
  settings: Item;
  hint: Item;
  translate: Item;
  begin: Item;
  copy: Item;
  translator: Item;
  beginLesson: Item;
  beginQuiz: Item;
  retakeQuiz: Item;
  changeLesson: Item;
  changeQuiz: Item;
  changeStory: Item;
  playConversationSegment: Item;
  viewAchievements: Item;
  backToLearning: Item;
};

type Item = {
  [key: string]: string;
};

type ItemArray = {
  [key: string]: string[];
};

export const languageID = (language: string) => {
  const languageIDs = {
    french: 1,
    german: 2,
    italian: 3,
    portuguese: 4,
    spanish: 5,
    english: 6,
  };

  return languageIDs[language as keyof typeof languageIDs];
};

export const languages = [
  { id: 1, display: 'French', value: 'french' },
  { id: 2, display: 'German', value: 'german' },
  { id: 3, display: 'Italian', value: 'italian' },
  { id: 4, display: 'Portuguese', value: 'portuguese' },
  { id: 5, display: 'Spanish', value: 'spanish' },
  { id: 6, display: 'English', value: 'english' },
];

export const languageName = (language = 6) => {
  const languageNames = {
    1: 'french',
    2: 'german',
    3: 'italian',
    4: 'portuguese',
    5: 'spanish',
    6: 'english',
  };

  return languageNames[language as keyof typeof languageNames];
};

export const topicID = (topic: string) => {
  const topicIDs = {
    dating: 1,
    family: 2,
    travel: 3,
    career: 4,
    sports: 5,
    entertainment: 6,
    general: 7,
  };

  return topicIDs[topic as keyof typeof topicIDs];
};

export const topicName = (topic = 1) => {
  const topicNames = {
    1: 'Dating',
    2: 'Family',
    3: 'Travel',
    4: 'Career',
    5: 'Sports',
    6: 'Entertainment',
    7: 'General',
  };

  return topicNames[topic as keyof typeof topicNames];
};

export const levelID = (level: string) => {
  const levelIDs = {
    beginner: 1,
    intermediate: 2,
    advanced: 3,
  };

  return levelIDs[level as keyof typeof levelIDs];
};

export const levelName = (level = 1) => {
  const levelNames = {
    1: 'Beginner',
    2: 'Intermediate',
    3: 'Advanced',
  };

  return levelNames[level as keyof typeof levelNames];
};

export const translations: Translations = {
  languages: {
    english: [
      { id: 1, name: 'French' },
      { id: 2, name: 'German' },
      { id: 3, name: 'Italian' },
      { id: 4, name: 'Portuguese' },
      { id: 5, name: 'Spanish' },
      { id: 6, name: 'English' },
    ],
    french: [
      { id: 1, name: 'Français' },
      { id: 2, name: 'Allemand' },
      { id: 3, name: 'Italien' },
      { id: 4, name: 'Portugais' },
      { id: 5, name: 'Espagnol' },
      { id: 6, name: 'Anglais' },
    ],
    german: [
      { id: 1, name: 'Französisch' },
      { id: 2, name: 'Deutsch' },
      { id: 3, name: 'Italienisch' },
      { id: 4, name: 'Portugiesisch' },
      { id: 5, name: 'Spanisch' },
      { id: 6, name: 'Englisch' },
    ],
    italian: [
      { id: 1, name: 'Francese' },
      { id: 2, name: 'Tedesco' },
      { id: 3, name: 'Italiano' },
      { id: 4, name: 'Portoghese' },
      { id: 5, name: 'Spagnolo' },
      { id: 6, name: 'Inglese' },
    ],
    portuguese: [
      { id: 1, name: 'Francês' },
      { id: 2, name: 'Alemão' },
      { id: 3, name: 'Italiano' },
      { id: 4, name: 'Português' },
      { id: 5, name: 'Espanhol' },
      { id: 6, name: 'Inglês' },
    ],
    spanish: [
      { id: 1, name: 'Francés' },
      { id: 2, name: 'Alemán' },
      { id: 3, name: 'Italiano' },
      { id: 4, name: 'Portugués' },
      { id: 5, name: 'Español' },
      { id: 6, name: 'Inglés' },
    ],
  },
  topics: {
    english: [
      {
        id: 1,
        name: 'Dating',
      },
      {
        id: 2,
        name: 'Family',
      },
      {
        id: 3,
        name: 'Travel',
      },
      {
        id: 4,
        name: 'Career',
      },
      {
        id: 5,
        name: 'Sports',
      },
      {
        id: 6,
        name: 'Entertainment',
      },
      {
        id: 7,
        name: 'General',
      },
    ],
    french: [
      {
        id: 1,
        name: 'Rendez-vous',
      },
      {
        id: 2,
        name: 'Famille',
      },
      {
        id: 3,
        name: 'Voyage',
      },
      {
        id: 4,
        name: 'Carrière',
      },
      {
        id: 5,
        name: 'Sports',
      },
      {
        id: 6,
        name: 'Divertissement',
      },
      {
        id: 7,
        name: 'Général',
      },
    ],
    german: [
      {
        id: 1,
        name: 'Dating',
      },
      {
        id: 2,
        name: 'Familie',
      },
      {
        id: 3,
        name: 'Reisen',
      },
      {
        id: 4,
        name: 'Karriere',
      },
      {
        id: 5,
        name: 'Sport',
      },
      {
        id: 6,
        name: 'Unterhaltung',
      },
      {
        id: 7,
        name: 'Allgemein',
      },
    ],
    italian: [
      {
        id: 1,
        name: 'Appuntamenti',
      },
      {
        id: 2,
        name: 'Famiglia',
      },
      {
        id: 3,
        name: 'Viaggi',
      },
      {
        id: 4,
        name: 'Carriera',
      },
      {
        id: 5,
        name: 'Sport',
      },
      {
        id: 6,
        name: 'Intrattenimento',
      },
      {
        id: 7,
        name: 'Generale',
      },
    ],
    portuguese: [
      {
        id: 1,
        name: 'Encontros',
      },
      {
        id: 2,
        name: 'Família',
      },
      {
        id: 3,
        name: 'Viagem',
      },
      {
        id: 4,
        name: 'Carreira',
      },
      {
        id: 5,
        name: 'Esportes',
      },
      {
        id: 6,
        name: 'Entretenimento',
      },
      {
        id: 7,
        name: 'Geral',
      },
    ],
    spanish: [
      {
        id: 1,
        name: 'Citas',
      },
      {
        id: 2,
        name: 'Familia',
      },
      {
        id: 3,
        name: 'Viajes',
      },
      {
        id: 4,
        name: 'Carrera',
      },
      {
        id: 5,
        name: 'Deportes',
      },
      {
        id: 6,
        name: 'Entretenimiento',
      },
      {
        id: 7,
        name: 'General',
      },
    ],
  },
  levels: {
    english: [
      { id: 1, name: 'Beginner' },
      { id: 2, name: 'Intermediate' },
      { id: 3, name: 'Advanced' },
    ],
    french: [
      { id: 1, name: 'Débutant' },
      { id: 2, name: 'Intermédiaire' },
      { id: 3, name: 'Avancé' },
    ],
    german: [
      { id: 1, name: 'Anfänger' },
      { id: 2, name: 'Fortgeschritten' },
      { id: 3, name: 'Experte' },
    ],
    italian: [
      { id: 1, name: 'Principiante' },
      { id: 2, name: 'Intermedio' },
      { id: 3, name: 'Avanzato' },
    ],
    portuguese: [
      { id: 1, name: 'Iniciante' },
      { id: 2, name: 'Intermediário' },
      { id: 3, name: 'Avançado' },
    ],
    spanish: [
      { id: 1, name: 'Principiante' },
      { id: 2, name: 'Intermedio' },
      { id: 3, name: 'Avanzado' },
    ],
  },
  buttons: {
    create: {
      english: 'New Chat',
      french: 'Nouveau chat',
      german: 'Neuer Chat',
      italian: 'Nuova chat',
      portuguese: 'Nova conversa',
      spanish: 'Nuevo chat',
    },
    remove: {
      english: 'Remove Chat',
      french: 'Supprimer la discussion',
      german: 'Chat entfernen',
      italian: 'Rimuovi chat',
      portuguese: 'Remover conversa',
      spanish: 'Eliminar chat',
    },
    cancel: {
      english: 'Cancel',
      french: 'Annuler',
      german: 'Stornieren',
      italian: 'Annulla',
      portuguese: 'Cancelar',
      spanish: 'Cancelar',
    },
    save: {
      english: 'Save Changes',
      french: 'Enregistrer',
      german: 'Speichern',
      italian: 'Salva',
      portuguese: 'Salvar',
      spanish: 'Guardar',
    },
    back: {
      english: 'Back',
      french: 'Retour',
      german: 'Zurück',
      italian: 'Indietro',
      portuguese: 'Voltar',
      spanish: 'Atrás',
    },
    continue: {
      english: 'Continue',
      french: 'Continuer',
      german: 'Fortsetzen',
      italian: 'Continua',
      portuguese: 'Continuar',
      spanish: 'Continuar',
    },
    start: {
      english: 'Start Conversation',
      french: 'Commencer la conversation',
      german: 'Gespräch beginnen',
      italian: 'Inizia la conversazione',
      portuguese: 'Iniciar conversa',
      spanish: 'Iniciar conversación',
    },
    settings: {
      english: 'Language Settings',
      french: 'Paramètres de langue',
      german: 'Spracheinstellungen',
      italian: 'Impostazioni lingua',
      portuguese: 'Configurações de idioma',
      spanish: 'Configuración de idioma',
    },
    hint: {
      english: 'Give Hint',
      french: 'Donner un indice',
      german: 'Tipp geben',
      italian: 'Dai suggerimento',
      portuguese: 'Dar dica',
      spanish: 'Dar pista',
    },
    translate: {
      english: 'Translate',
      french: 'Traduire',
      german: 'Übersetzen',
      italian: 'Traduci',
      portuguese: 'Traduzir',
      spanish: 'Traducir',
    },
    begin: {
      english: "Let's Begin!",
      french: 'Commençons!',
      german: 'Lass uns beginnen!',
      italian: 'Cominciamo!',
      portuguese: 'Vamos começar!',
      spanish: '¡Empecemos!',
    },
    copy: {
      english: 'Copy text',
      french: 'Copier le texte',
      german: 'Text kopieren',
      italian: 'Copia testo',
      portuguese: 'Copiar texto',
      spanish: 'Copiar texto',
    },
    translator: {
      english: 'Translator',
      french: 'Traducteur',
      german: 'Übersetzer',
      italian: 'Traduttore',
      portuguese: 'Tradutor',
      spanish: 'Traductor',
    },
    beginLesson: {
      english: 'Begin Lesson',
      french: 'Commencer la leçon',
      german: 'Lektion beginnen',
      italian: 'Inizia la lezione',
      portuguese: 'Começar a lição',
      spanish: 'Comenzar la lección',
    },
    beginQuiz: {
      english: 'Begin Quiz',
      french: 'Commencer le quiz',
      german: 'Quiz beginnen',
      italian: 'Inizia il quiz',
      portuguese: 'Começar o quiz',
      spanish: 'Comenzar la prueba',
    },
    retakeQuiz: {
      english: 'Retake Quiz',
      french: 'Refaire le quiz',
      german: 'Quiz wiederholen',
      italian: 'Rifai il quiz',
      portuguese: 'Refazer o quiz',
      spanish: 'Volver a hacer el cuestionario',
    },
    changeLesson: {
      english: 'Change Lesson',
      french: 'Changer de leçon',
      german: 'Lektion ändern',
      italian: 'Cambia lezione',
      portuguese: 'Mudar de lição',
      spanish: 'Cambiar lección',
    },
    changeQuiz: {
      english: 'Change Quiz',
      french: 'Changer de quiz',
      german: 'Quiz ändern',
      italian: 'Cambia quiz',
      portuguese: 'Mudar de quiz',
      spanish: 'Cambiar cuestionario',
    },
    changeStory: {
      english: 'Change Story',
      french: "Changer l'histoire",
      german: 'Geschichte ändern',
      italian: 'Cambia storia',
      portuguese: 'Mudar história',
      spanish: 'Cambiar historia',
    },
    playConversationSegment: {
      english: 'Play scenario',
      french: 'Jouer le scénario',
      german: 'Spiele das Szenario',
      italian: 'Riprodurre lo scenario',
      portuguese: 'Interpretar o cenário',
      spanish: 'Jugar el escenario',
    },
    viewAchievements: {
      english: 'View Achievements',
      french: 'Voir les réalisations',
      german: 'Erfolge anzeigen',
      italian: 'Visualizza i risultati',
      portuguese: 'Ver conquistas',
      spanish: 'Ver logros',
    },
    backToLearning: {
      english: 'Back to Learning',
      french: "Retour à l'apprentissage",
      german: 'Zurück zum Lernen',
      italian: "Torna all'apprendimento",
      portuguese: 'Voltar ao aprendizado',
      spanish: 'Volver al aprendizaje',
    },
  },
  avatar: {
    english: imgEnglishBeverly,
    french: imgFrenchBeverly,
    german: imgGermanBeverly,
    italian: imgItalianBeverly,
    portuguese: imgPortugueseBeverly,
    spanish: imgSpanishBeverly,
  },
  transitionAssignmentModal: {
    title: {
      english: 'Creating content...',
      french: 'Création de contenu...',
      german: 'Inhalt wird erstellt...',
      italian: 'Creazione di contenuti...',
      portuguese: 'Criando conteúdo...',
      spanish: 'Creando contenido...',
    },
  },
  transitionChatModal: {
    greeting: {
      english: "Hey, I'm Beverly!",
      french: 'Salut, je suis Beverly!',
      german: 'Hallo, ich bin Beverly!',
      italian: 'Ciao, sono Beverly!',
      portuguese: 'Oi, eu sou Beverly!',
      spanish: '¡Hola, soy Beverly!',
    },
    description: {
      english: { start: "Let's practice", end: 'together!' },
      french: { start: 'Pratiquons', end: 'ensemble!' },
      german: { start: 'Lass uns üben', end: 'zusammen!' },
      italian: { start: 'Pratichiamo insieme', end: '!' },
      portuguese: { start: 'Vamos praticar', end: 'juntos!' },
      spanish: { start: '¡Practiquemos', end: 'juntos!' },
    },
  },
  translatorModal: {
    title: {
      english: {
        start: 'Translate: ',
        preposition: 'to',
      },
      french: {
        start: 'Traduire: ',
        preposition: 'à',
      },
      german: {
        start: 'Übersetzen: ',
        preposition: 'zu',
      },
      italian: {
        start: 'Traduci: ',
        preposition: 'a',
      },
      portuguese: {
        start: 'Traduzir: ',
        preposition: 'para',
      },
      spanish: {
        start: 'Traducir: ',
        preposition: 'a',
      },
    },
    placeholder: {
      english: 'How do I say?',
      french: 'Comment dire?',
      german: 'Wie sage ich?',
      italian: 'Come dico?',
      portuguese: 'Como eu digo?',
      spanish: '¿Cómo digo?',
    },
    tooltip: {
      english: {
        default: 'Click to copy.',
        copied: 'Copied to clipboard!',
      },
      french: {
        default: 'Cliquez pour copier.',
        copied: 'Copié dans le presse-papiers!',
      },
      german: {
        default: 'Klicken zum Kopieren.',
        copied: 'In die Zwischenablage kopiert!',
      },
      italian: {
        default: 'Clicca per copiare.',
        copied: 'Copiato negli appunti!',
      },
      portuguese: {
        default: 'Clique para copiar.',
        copied: 'Copiado para a área de transferência!',
      },
      spanish: {
        default: 'Haz clic para copiar.',
        copied: 'Copiado al portapapeles!',
      },
    },
  },
  onBoarding: {
    title: {
      english: 'Meet Your New Language Practice Partner!',
      french: 'Rencontrez votre nouveau partenaire de pratique linguistique!',
      german: 'Treffen Sie Ihren neuen Sprachübungspartner!',
      italian: 'Incontra il tuo nuovo partner di pratica linguistica!',
      portuguese: 'Conheça seu novo parceiro de prática de idiomas!',
      spanish: '¡Conoce a tu nuevo compañero de práctica de idiomas!',
    },
    description: {
      english:
        "Let's get started! Please tell us your native language so we can personalize your experience and chat just the way you like.",
      french:
        'Commençons! Veuillez nous indiquer votre langue maternelle afin que nous puissions personnaliser votre expérience et discuter comme vous le souhaitez.',
      german:
        'Lass uns anfangen! Bitte sagen Sie uns Ihre Muttersprache, damit wir Ihre Erfahrung personalisieren und chatten können, wie Sie es möchten.',
      italian:
        'Cominciamo! Per favore, dicci la tua lingua madre così possiamo personalizzare la tua esperienza e chattare come preferisci.',
      portuguese:
        'Vamos começar! Por favor, nos diga sua língua materna para que possamos personalizar sua experiência e conversar do jeito que você gosta.',
      spanish:
        '¡Empecemos! Por favor, dinos tu idioma nativo para que podamos personalizar tu experiencia y chatear como te guste.',
    },
    questions: {
      learningLanguage: {
        english: 'What language do you want to learn?',
        french: 'Quelle langue voulez-vous apprendre?',
        german: 'Welche Sprache möchten Sie lernen?',
        italian: 'Che lingua vuoi imparare?',
        portuguese: 'Que idioma você quer aprender?',
        spanish: '¿Qué idioma quieres aprender?',
      },
      proficiency: {
        english: {
          default: 'How would you rate your skill in that language?',
          custom: 'How would you rate your',
        },
        french: {
          default:
            'Comment évalueriez-vous votre compétence dans cette langue?',
          custom: 'Comment évalueriez-vous votre',
        },
        german: {
          default:
            'Wie würden Sie Ihre Fähigkeiten in dieser Sprache bewerten?',
          custom: 'Wie beurteilst du dein',
        },
        italian: {
          default: 'Come valuteresti la tua competenza in quella lingua?',
          custom: 'Come valuteresti il tuo',
        },
        portuguese: {
          default: 'Como você avaliaria suas habilidades nesse idioma?',
          custom: 'Como você avaliaria seu',
        },
        spanish: {
          default: '¿Cómo calificarías tus habilidades en ese idioma?',
          custom: '¿Cómo calificarías tu',
        },
      },
      topic: {
        english: 'Select Topic',
        french: 'Sélectionner un sujet',
        german: 'Thema auswählen',
        italian: 'Seleziona argomento',
        portuguese: 'Selecione um tópico',
        spanish: 'Seleccionar tema',
      },
    },
  },
  dashboard: {
    header: {
      title: {
        english: 'Language Tutor',
        french: 'Tuteur de langue',
        german: 'Sprachlehrer',
        italian: 'Tutor di lingua',
        portuguese: 'Tutor de idiomas',
        spanish: 'Tutor de idiomas',
      },
      subtitle: {
        english: "Hi, I'm Beverly, your AI tutor.",
        french: 'Salut, je suis Beverly, votre tuteur IA.',
        german: 'Hallo, ich bin Beverly, Ihr KI-Tutor.',
        italian: 'Ciao, sono Beverly, il tuo tutor IA.',
        portuguese: 'Olá, eu sou Beverly, seu tutor de IA.',
        spanish: 'Hola, soy Beverly, tu tutor de IA.',
      },
      description: {
        english: 'Lets have a conversation and learn a new language together!',
        french: 'Discutons et apprenons une nouvelle langue ensemble!',
        german:
          'Lassen Sie uns sprechen und zusammen eine neue Sprache lernen!',
        italian: 'Parliamo e impariamo una nuova lingua insieme!',
        portuguese: 'Vamos conversar e aprender um novo idioma juntos!',
        spanish: '¡Hablemos y aprendamos un nuevo idioma juntos!',
      },
      iconText: {
        english: 'Beta',
        french: 'Bêta',
        german: 'Beta',
        italian: 'Beta',
        portuguese: 'Beta',
        spanish: 'Beta',
      },
    },
    giveFeedback: {
      title: {
        english: 'Give Feedback',
        french: 'Donner un retour',
        german: 'Feedback geben',
        italian: 'Dare un feedback',
        portuguese: 'Dar feedback',
        spanish: 'Dar opinión',
      },
      question: {
        english: 'How would you rate this experience?',
        french: 'Comment évalueriez-vous cette expérience?',
        german: 'Wie würden Sie diese Erfahrung bewerten?',
        italian: 'Come valuteresti questa esperienza?',
        portuguese: 'Como você avaliaria essa experiência?',
        spanish: '¿Cómo calificarías esta experiencia?',
      },
      options: {
        english: ['Poor', 'Excellent'],
        french: ['Mauvais', 'Excellent'],
        german: ['Schlecht', 'Ausgezeichnet'],
        italian: ['Scadente', 'Eccellente'],
        portuguese: ['Pobre', 'Excelente'],
        spanish: ['Mala', 'Excelente'],
      },
      thanks: {
        english: 'Thank you for your feedback!',
        french: 'Merci pour votre retour!',
        german: 'Danke für Ihre Rückmeldung!',
        italian: 'Grazie per il tuo feedback!',
        portuguese: 'Obrigado pelo seu feedback!',
        spanish: '¡Gracias por tus comentarios!',
      },
      request: {
        english: 'Share your thoughts',
        french: 'Partagez vos pensées',
        german: 'Teilen Sie Ihre Gedanken',
        italian: 'Condividi i tuoi pensieri',
        portuguese: 'Compartilhe seus pensamentos',
        spanish: 'Comparte tu opinión',
      },
    },
    languageSettings: {
      title: {
        english: 'Settings',
        french: 'Paramètres',
        german: 'Einstellungen',
        italian: 'Impostazioni',
        portuguese: 'Configurações',
        spanish: 'Configuración',
      },
      nativeLanguage: {
        english: 'Native Language',
        french: 'Langue maternelle',
        german: 'Muttersprache',
        italian: 'Lingua madre',
        portuguese: 'Língua materna',
        spanish: 'Idioma nativo',
      },
    },
    chats: {
      title: {
        english: 'Our Chats',
        french: 'Nos discussions',
        german: 'Unsere Chats',
        italian: 'Le nostre chat',
        portuguese: 'Nossas conversas',
        spanish: 'Nuestros chats',
      },
      emptyState: {
        title: {
          english: 'No chats started yet!',
          french: 'Aucune discussion commencée pour le moment!',
          german: 'Noch keine Chats gestartet!',
          italian: 'Ancora nessuna chat avviata!',
          portuguese: 'Nenhuma conversa iniciada ainda!',
          spanish: '¡Aún no se han iniciado chats!',
        },
        description: {
          english: 'Begin a new chat to get started.',
          french: 'Commencez une nouvelle discussion pour commencer.',
          german: 'Beginnen Sie einen neuen Chat, um loszulegen.',
          italian: 'Inizia una nuova chat per iniziare.',
          portuguese: 'Comece uma nova conversa para começar.',
          spanish: 'Inicie un nuevo chat para comenzar.',
        },
      },
    },
    assignments: {
      lesson: {
        header: {
          english: 'Lessons',
          french: 'Leçons',
          german: 'Lektionen',
          italian: 'Lezioni',
          portuguese: 'Lições',
          spanish: 'Lecciones',
        },
        selectLabel: {
          english: 'What language do you want to learn?',
          french: 'Quelle langue voulez-vous apprendre?',
          german: 'Welche Sprache möchten Sie lernen?',
          italian: 'Che lingua vuoi imparare?',
          portuguese: 'Que idioma você quer aprender?',
          spanish: '¿Qué idioma quieres aprender?',
        },
        lessonList: {
          english: [
            {
              id: 1,
              title: 'Basic Phrases and Greetings',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Introductions and Basic Questions',
              completed: false,
              started: false,
            },
            {
              id: 3,
              title: 'Numbers and Basic Counting',
              completed: false,
              started: false,
            },
            {
              id: 4,
              title: 'Common Verbs and Verb Conjugations',
              completed: false,
              started: false,
            },
            {
              id: 5,
              title: 'Basic Sentence Structure and Nouns',
              completed: false,
              started: false,
            },
            {
              id: 6,
              title: 'Introduction to Colors',
              completed: false,
              started: false,
            },
            {
              id: 7,
              title: 'Past Tense Verb Conjugations',
              completed: false,
              started: false,
            },
            {
              id: 8,
              title: 'Future Tense Verb Conjugations',
              completed: false,
              started: false,
            },
          ],
          french: [
            {
              id: 1,
              title: 'Phrases de base et salutations',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Présentations et questions de base',
              completed: false,
              started: false,
            },
            {
              id: 3,
              title: 'Nombres et comptage de base',
              completed: false,
              started: false,
            },
            {
              id: 4,
              title: 'Verbes courants et conjugaisons verbales',
              completed: false,
              started: false,
            },
            {
              id: 5,
              title: 'Structure de phrase de base et noms',
              completed: false,
              started: false,
            },
            {
              id: 6,
              title: 'Introduction aux couleurs',
              completed: false,
              started: false,
            },
            {
              id: 7,
              title: 'Conjugaisons de verbes au passé',
              completed: false,
              started: false,
            },
            {
              id: 8,
              title: 'Conjugaisons de verbes au futur',
              completed: false,
              started: false,
            },
          ],
          german: [
            {
              id: 1,
              title: 'Grundlegende Phrasen und Begrüßungen',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Vorstellungen und Grundfragen',
              completed: false,
              started: false,
            },
            {
              id: 3,
              title: 'Zahlen und grundlegendes Zählen',
              completed: false,
              started: false,
            },
            {
              id: 4,
              title: 'Gemeinsame Verben und Verbkonjugationen',
              completed: false,
              started: false,
            },
            {
              id: 5,
              title: 'Grundlegende Satzstruktur und Nomen',
              completed: false,
              started: false,
            },
            {
              id: 6,
              title: 'Einführung in Farben',
              completed: false,
              started: false,
            },
            {
              id: 7,
              title: 'Vergangenheitsformen von Verben',
              completed: false,
              started: false,
            },
            {
              id: 8,
              title: 'Zukunftsformen von Verben',
              completed: false,
              started: false,
            },
          ],
          italian: [
            {
              id: 1,
              title: 'Frasi di base e saluti',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Presentazioni e domande di base',
              completed: false,
              started: false,
            },
            {
              id: 3,
              title: 'Numeri e conteggio di base',
              completed: false,
              started: false,
            },
            {
              id: 4,
              title: 'Verbi comuni e coniugazioni verbali',
              completed: false,
              started: false,
            },
            {
              id: 5,
              title: 'Struttura di base della frase e nomi',
              completed: false,
              started: false,
            },
            {
              id: 6,
              title: 'Introduzione ai colori',
              completed: false,
              started: false,
            },
            {
              id: 7,
              title: 'Coniugazioni verbali al passato',
              completed: false,
              started: false,
            },
            {
              id: 8,
              title: 'Coniugazioni verbali al futuro',
              completed: false,
              started: false,
            },
          ],
          portuguese: [
            {
              id: 1,
              title: 'Frases básicas e cumprimentos',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Apresentações e perguntas básicas',
              completed: false,
              started: false,
            },
            {
              id: 3,
              title: 'Números e contagem básica',
              completed: false,
              started: false,
            },
            {
              id: 4,
              title: 'Verbos comuns e conjugações verbais',
              completed: false,
              started: false,
            },
            {
              id: 5,
              title: 'Estructura básica de oraciones y sustantivos',
              completed: false,
              started: false,
            },
            {
              id: 6,
              title: 'Introdução às cores',
              completed: false,
              started: false,
            },
            {
              id: 7,
              title: 'Conjugações verbais no passado',
              completed: false,
              started: false,
            },
            {
              id: 8,
              title: 'Conjugações verbais no futuro',
              completed: false,
              started: false,
            },
          ],
          spanish: [
            {
              id: 1,
              title: 'Frases básicas y saludos',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Presentaciones y preguntas básicas',
              completed: false,
              started: false,
            },
            {
              id: 3,
              title: 'Números y conteo básico',
              completed: false,
              started: false,
            },
            {
              id: 4,
              title: 'Verbos comunes y conjugaciones verbales',
              completed: false,
              started: false,
            },
            {
              id: 5,
              title: 'Estructura básica de oraciones y sustantivos',
              completed: false,
              started: false,
            },
            {
              id: 6,
              title: 'Introducción a los colores',
              completed: false,
              started: false,
            },
            {
              id: 7,
              title: 'Conjugaciones verbales en pasado',
              completed: false,
              started: false,
            },
            {
              id: 8,
              title: ' Conjugaciones verbales en futuro',
              completed: false,
              started: false,
            },
          ],
        },
      },
      quiz: {
        header: {
          english: 'Quizzes',
          french: 'Quiz',
          german: 'Quiz',
          italian: 'Quiz',
          portuguese: 'Quiz',
          spanish: 'Quiz',
        },
        quizList: {
          english: [
            {
              id: 1,
              title: 'Basic Phrases and Greetings',
            },
            {
              id: 2,
              title: 'Introductions and Basic Questions',
            },
            {
              id: 3,
              title: 'Numbers and Basic Counting',
            },
            {
              id: 4,
              title: 'Common Verbs and Verb Conjugations',
            },
            {
              id: 5,
              title: 'Basic Sentence Structure and Nouns',
            },
            {
              id: 6,
              title: 'Introduction to Colors',
            },
          ],
          french: [
            {
              id: 1,
              title: 'Phrases de base et salutations',
            },
            {
              id: 2,
              title: 'Présentations et questions de base',
            },
            {
              id: 3,
              title: 'Nombres et comptage de base',
            },
            {
              id: 4,
              title: 'Verbes courants et conjugaisons verbales',
            },
            {
              id: 5,
              title: 'Structure de phrase de base et noms',
            },
            {
              id: 6,
              title: 'Introduction aux couleurs',
            },
          ],
          german: [
            {
              id: 1,
              title: 'Grundlegende Phrasen und Begrüßungen',
            },
            {
              id: 2,
              title: 'Vorstellungen und Grundfragen',
            },
            {
              id: 3,
              title: 'Zahlen und grundlegendes Zählen',
            },
            {
              id: 4,
              title: 'Gemeinsame Verben und Verbkonjugationen',
            },
            {
              id: 5,
              title: 'Grundlegende Satzstruktur und Nomen',
            },
            {
              id: 6,
              title: 'Einführung in Farben',
            },
          ],
          italian: [
            {
              id: 1,
              title: 'Frasi di base e saluti',
            },
            {
              id: 2,
              title: 'Presentazioni e domande di base',
            },
            {
              id: 3,
              title: 'Numeri e conteggio di base',
            },
            {
              id: 4,
              title: 'Verbi comuni e coniugazioni verbali',
            },
            {
              id: 5,
              title: 'Struttura di base della frase e nomi',
            },
            {
              id: 6,
              title: 'Introduzione ai colori',
            },
          ],
          portuguese: [
            {
              id: 1,
              title: 'Frases básicas e cumprimentos',
            },
            {
              id: 2,
              title: 'Apresentações e perguntas básicas',
            },
            {
              id: 3,
              title: 'Números e contagem básica',
            },
            {
              id: 4,
              title: 'Verbos comuns e conjugações verbais',
            },
            {
              id: 5,
              title: 'Estructura básica de oraciones y sustantivos',
            },
            {
              id: 6,
              title: 'Introdução às cores',
            },
          ],
          spanish: [
            {
              id: 1,
              title: 'Frases básicas y saludos',
            },
            {
              id: 2,
              title: 'Presentaciones y preguntas básicas',
            },
            {
              id: 3,
              title: 'Números y conteo básico',
            },
            {
              id: 4,
              title: 'Verbos comunes y conjugaciones verbales',
            },
            {
              id: 5,
              title: 'Estructura básica de oraciones y sustantivos',
            },
            {
              id: 6,
              title: 'Introducción a los colores',
            },
          ],
        },
      },
      story: {
        header: {
          english: 'Stories',
          french: 'Histoires',
          german: 'Geschichten',
          italian: 'Storie',
          portuguese: 'Histórias',
          spanish: 'Historias',
        },
        storyList: {
          english: [
            {
              id: 1,
              title: 'Eating at a Restaurant',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Shopping for Clothes',
              completed: false,
              started: false,
            },
          ],
          french: [
            {
              id: 1,
              title: 'Manger au restaurant',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Acheter des vêtements',
              completed: false,
              started: false,
            },
          ],
          german: [
            {
              id: 1,
              title: 'Essen im Restaurant',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Kleidung einkaufen',
              completed: false,
              started: false,
            },
          ],
          italian: [
            {
              id: 1,
              title: 'Mangiare al ristorante',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Fare shopping di vestiti',
              completed: false,
              started: false,
            },
          ],
          portuguese: [
            {
              id: 1,
              title: 'Comer em um restaurante',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Compras de roupas',
              completed: false,
              started: false,
            },
          ],
          spanish: [
            {
              id: 1,
              title: 'Comer en un restaurante',
              completed: false,
              started: false,
            },
            {
              id: 2,
              title: 'Compras de ropa',
              completed: false,
              started: false,
            },
          ],
        },
      },
    },
    assignmentCardTitles: {
      english: 'Story',
      french: 'Histoire',
      german: 'Geschichte',
      italian: 'Storia',
      portuguese: 'História',
      spanish: 'Historia',
    },
  },
  chatUI: {
    recentChats: {
      english: 'Recent Chats',
      french: 'Discussions récentes',
      german: 'Letzte Chats',
      italian: 'Chat recenti',
      portuguese: 'Conversas recentes',
      spanish: 'Chats recientes',
    },
    hint: {
      english: 'Your Hint',
      french: 'Votre indice',
      german: 'Ihr Tipp',
      italian: 'Il tuo suggerimento',
      portuguese: 'Sua dica',
      spanish: 'Tu pista',
    },
    inputPlaceholder: {
      english: 'Saying something...',
      french: 'Dire quelque chose...',
      german: 'Etwas sagen...',
      italian: 'Dire qualcosa...',
      portuguese: 'Dizendo algo...',
      spanish: 'Diciendo algo...',
    },
    translation: {
      english: 'Translation',
      french: 'Traduction',
      german: 'Übersetzung',
      italian: 'Traduzione',
      portuguese: 'Tradução',
      spanish: 'Traducción',
    },
    lessons: {
      header: {
        english: 'Lesson',
        french: 'Leçon',
        german: 'Lektion',
        italian: 'Lezione',
        portuguese: 'Lição',
        spanish: 'Lección',
      },
      introduction: {
        english: 'Introduction of lesson',
        french: "Introduction de l'exercice",
        german: 'Einführung in die Lektion',
        italian: 'Introduzione della lezione',
        portuguese: 'Introdução da lição',
        spanish: 'Introducción de la lección',
      },
      completedCard: {
        heading: {
          english: 'Assignment Completed',
          spanish: 'Tarea completada',
          french: 'Devoir terminé',
          german: 'Aufgabe abgeschlossen',
          italian: 'Compito completato',
          portuguese: 'Tarefa concluída',
        },
        subHeading: {
          english: "Nice job! You've completed this assignment.",
          spanish: '¡Buen trabajo! Has completado esta tarea.',
          french: 'Bon travail! Vous avez terminé cet exercice.',
          german: 'Gute Arbeit! Sie haben diese Aufgabe abgeschlossen.',
          italian: 'Bel lavoro! Hai completato questo compito.',
          portuguese: 'Bom trabalho! Você concluiu esta tarefa.',
        },
        buttonText: {
          english: 'Retake Assignment',
          spanish: 'Volver a hacer la tarea',
          french: "Refaire l'exercice",
          german: 'Aufgabe wiederholen',
          italian: 'Rifai il compito',
          portuguese: 'Refazer tarefa',
        },
      },
    },
    quiz: {
      introduction: {
        english: 'Test Your Skills',
        french: 'Testez vos compétences',
        german: 'Teste deine Fähigkeiten',
        italian: 'Testa le tue abilità',
        portuguese: 'Teste suas habilidades',
        spanish: 'Pon a prueba tus habilidades',
      },
    },
    story: {
      introduction: {
        english: 'Welcome to your Audio Story Lesson',
        french: "Bienvenue à votre leçon d'histoire audio",
        german: 'Willkommen zu Ihrer Audio-Story-Lektion',
        italian: 'Benvenuti alla lezione di storie audio',
        portuguese: 'Bem-vindo à sua aula de história em áudio',
        spanish: 'Bienvenido a tu lección de historias en audio',
      },
    },
  },
  errors: {
    quiz: {
      english: 'Failed to create a quiz. Please try again.',
      french: 'Échec de la création du quiz. Veuillez réessayer.',
      german:
        'Quiz konnte nicht erstellt werden. Bitte versuchen Sie es erneut.',
      italian: 'Impossibile creare un quiz. Si prega di riprovare.',
      portuguese: 'Falha ao criar um quiz. Por favor, tente novamente.',
      spanish: 'Error al crear un cuestionario. Por favor, inténtelo de nuevo.',
    },
  },
  badges: {
    french: [
      {
        id: 'sharing_is_caring',
        name: "Partager, c'est s'occuper",
        tooltip: 'Laissez un avis pour la première fois.',
      },
      {
        id: 'bilingual',
        name: 'Bilingue',
        tooltip:
          'Changez votre langue maternelle pour une autre langue que le français.',
      },
      {
        id: 'multi_lingual',
        name: 'Multilingue',
        tooltip: 'Commencez une discussion ou une leçon dans une autre langue.',
      },
      {
        id: 'first_100',
        name: 'Premier 100%',
        tooltip: 'Obtenez 100% à un quiz.',
      },
      {
        id: 'first_steps',
        name: 'Premiers pas',
        tooltip: 'Complétez votre première leçon.',
      },
      {
        id: 'studious',
        name: 'Studieux',
        tooltip: 'Complétez la moitié des leçons.',
      },
      {
        id: 'love_to_learn',
        name: "Amoureux de l'apprentissage",
        tooltip: 'Complétez toutes les leçons.',
      },
      {
        id: 'ready_to_learn',
        name: 'Prêt à apprendre!',
        tooltip: 'Commencez votre première leçon.',
      },
      {
        id: 'go_team',
        name: 'Go Équipe!',
        tooltip: 'Commencez un chat avec un sujet "Sports".',
      },
      {
        id: 'fam',
        name: 'Famille',
        tooltip: 'Commencez un chat avec un sujet "Famille".',
      },
      {
        id: 'challenger',
        name: 'Défieur',
        tooltip: 'Commencez un chat intermédiaire.',
      },
      {
        id: 'learner',
        name: 'Apprenant',
        tooltip: 'Obtenez 1/5 à un quiz.',
      },
      {
        id: 'lights_camera_action',
        name: 'Lumières, caméra, action!',
        tooltip: 'Commencez un chat avec un sujet "Divertissement".',
      },
      {
        id: 'live_love_travel',
        name: 'Vivre. Aimer. Voyager.',
        tooltip: 'Commencez un chat avec un sujet "Voyage".',
      },
      {
        id: 'fluency',
        name: 'Fluence',
        tooltip: 'Commencez un chat expert.',
      },
      {
        id: 'first_words',
        name: 'Premiers mots',
        tooltip: 'Premier message de chat.',
      },
      {
        id: 'great_listener',
        name: 'Bon auditeur',
        tooltip: 'Complétez votre première histoire audio.',
      },
      {
        id: 'career_ladder',
        name: 'Échelle de carrière',
        tooltip: 'Commencez un chat avec un sujet "Carrière".',
      },
      {
        id: 'bonds',
        name: 'Liens',
        tooltip: 'Commencez un chat avec un sujet "Relation".',
      },
      {
        id: 'aspiring_learner',
        name: 'Apprenti apprenant',
        tooltip: 'Commencez un chat débutant.',
      },
    ],
    german: [
      {
        id: 'sharing_is_caring',
        name: 'Teilen ist fürsorglich',
        tooltip: 'Hinterlassen Sie zum ersten Mal Feedback.',
      },
      {
        id: 'bilingual',
        name: 'Zweisprachig',
        tooltip:
          'Ändern Sie Ihre Muttersprache in eine andere Sprache als Deutsch.',
      },
      {
        id: 'multi_lingual',
        name: 'Mehrsprachig',
        tooltip:
          'Beginnen Sie ein Gespräch oder eine Lektion in einer anderen Sprache.',
      },
      {
        id: 'first_100',
        name: 'Erste 100%',
        tooltip: 'Erreichen Sie 100% in einem Quiz.',
      },
      {
        id: 'first_steps',
        name: 'Erste Schritte',
        tooltip: 'Schließen Sie Ihre erste Lektion ab.',
      },
      {
        id: 'studious',
        name: 'Fleißig',
        tooltip: 'Schließen Sie die Hälfte der Lektionen ab.',
      },
      {
        id: 'love_to_learn',
        name: 'Lernen lieben',
        tooltip: 'Schließen Sie alle Lektionen ab.',
      },
      {
        id: 'ready_to_learn',
        name: 'Bereit zu lernen!',
        tooltip: 'Beginnen Sie Ihre erste Lektion.',
      },
      {
        id: 'go_team',
        name: 'Go Team!',
        tooltip: 'Starten Sie einen Chat mit einem "Sport"-Thema.',
      },
      {
        id: 'fam',
        name: 'Familie',
        tooltip: 'Starten Sie einen Chat mit einem "Familien"-Thema.',
      },
      {
        id: 'challenger',
        name: 'Herausforderer',
        tooltip: 'Starten Sie einen mittleren Chat.',
      },
      {
        id: 'learner',
        name: 'Lerner',
        tooltip: 'Erreichen Sie 1/5 in einem Quiz.',
      },
      {
        id: 'lights_camera_action',
        name: 'Licht, Kamera, Aktion!',
        tooltip: 'Starten Sie einen Chat mit einem "Unterhaltungsthema".',
      },
      {
        id: 'live_love_travel',
        name: 'Leben. Lieben. Reisen.',
        tooltip: 'Starten Sie einen Chat mit einem "Reisen"-Thema.',
      },
      {
        id: 'fluency',
        name: 'Fließend',
        tooltip: 'Starten Sie einen Experten-Chat.',
      },
      {
        id: 'first_words',
        name: 'Erste Worte',
        tooltip: 'Erste Chat-Nachricht.',
      },
      {
        id: 'great_listener',
        name: 'Guter Zuhörer',
        tooltip: 'Beenden Sie Ihre erste Audio-Geschichte.',
      },
      {
        id: 'career_ladder',
        name: 'Karriereleiter',
        tooltip: 'Starten Sie einen Chat mit einem "Karriere"-Thema.',
      },
      {
        id: 'bonds',
        name: 'Bindungen',
        tooltip: 'Starten Sie einen Chat mit einem "Beziehung"-Thema.',
      },
      {
        id: 'aspiring_learner',
        name: 'Angehender Lerner',
        tooltip: 'Starten Sie einen Anfänger-Chat.',
      },
    ],
    italian: [
      {
        id: 'sharing_is_caring',
        name: 'Condividere è prendersi cura',
        tooltip: 'Lascia un feedback per la prima volta.',
      },
      {
        id: 'bilingual',
        name: 'Bilingue',
        tooltip:
          "Cambia la tua lingua madre in qualcosa di diverso dall'italiano.",
      },
      {
        id: 'multi_lingual',
        name: 'Multilingue',
        tooltip: "Inizia una chat o una lezione in un'altra lingua.",
      },
      {
        id: 'first_100',
        name: 'Primo 100%',
        tooltip: 'Ottieni il 100% in un quiz.',
      },
      {
        id: 'first_steps',
        name: 'Primi passi',
        tooltip: 'Completa la tua prima lezione.',
      },
      {
        id: 'studious',
        name: 'Studioso',
        tooltip: 'Completa metà delle lezioni.',
      },
      {
        id: 'love_to_learn',
        name: 'Amare imparare',
        tooltip: 'Completa tutte le lezioni.',
      },
      {
        id: 'ready_to_learn',
        name: 'Pronto per imparare!',
        tooltip: 'Inizia la tua prima lezione.',
      },
      {
        id: 'go_team',
        name: 'Vai squadra!',
        tooltip: 'Inizia una chat con un tema "Sport".',
      },
      {
        id: 'fam',
        name: 'Famiglia',
        tooltip: 'Inizia una chat con un tema "Famiglia".',
      },
      {
        id: 'challenger',
        name: 'Sfida',
        tooltip: 'Inizia una chat intermedia.',
      },
      {
        id: 'learner',
        name: 'Studente',
        tooltip: 'Ottieni 1/5 in un quiz.',
      },
      {
        id: 'lights_camera_action',
        name: 'Luci, camera, azione!',
        tooltip: 'Inizia una chat con un tema "Intrattenimento".',
      },
      {
        id: 'live_love_travel',
        name: 'Vivi. Ama. Viaggia.',
        tooltip: 'Inizia una chat con un tema "Viaggi".',
      },
      {
        id: 'fluency',
        name: 'Fluenza',
        tooltip: 'Inizia una chat esperta.',
      },
      {
        id: 'first_words',
        name: 'Prime parole',
        tooltip: 'Primo messaggio di chat.',
      },
      {
        id: 'great_listener',
        name: 'Grande ascoltatore',
        tooltip: 'Completa la tua prima storia audio.',
      },
      {
        id: 'career_ladder',
        name: 'Scaletta di carriera',
        tooltip: 'Inizia una chat con un tema "Carriera".',
      },
      {
        id: 'bonds',
        name: 'Legami',
        tooltip: 'Inizia una chat con un tema "Relazione".',
      },
      {
        id: 'aspiring_learner',
        name: 'Aspirante studente',
        tooltip: 'Inizia una chat per principianti.',
      },
    ],
    portuguese: [
      {
        id: 'sharing_is_caring',
        name: 'Compartilhar é cuidar',
        tooltip: 'Deixe um feedback pela primeira vez.',
      },
      {
        id: 'bilingual',
        name: 'Bilíngue',
        tooltip: 'Mude seu idioma nativo para algo diferente do português.',
      },
      {
        id: 'multi_lingual',
        name: 'Multilíngue',
        tooltip: 'Inicie um bate-papo ou aula em outro idioma.',
      },
      {
        id: 'first_100',
        name: 'Primeiros 100%',
        tooltip: 'Obtenha 100% em um questionário.',
      },
      {
        id: 'first_steps',
        name: 'Primeiros passos',
        tooltip: 'Conclua sua primeira lição.',
      },
      {
        id: 'studious',
        name: 'Estudioso',
        tooltip: 'Conclua metade das lições.',
      },
      {
        id: 'love_to_learn',
        name: 'Amor por aprender',
        tooltip: 'Conclua todas as lições.',
      },
      {
        id: 'ready_to_learn',
        name: 'Pronto para aprender!',
        tooltip: 'Inicie sua primeira lição.',
      },
      {
        id: 'go_team',
        name: 'Vamos, equipe!',
        tooltip: 'Inicie um bate-papo com um tema de "Esportes".',
      },
      {
        id: 'fam',
        name: 'Família',
        tooltip: 'Inicie um bate-papo com um tema de "Família".',
      },
      {
        id: 'challenger',
        name: 'Desafiante',
        tooltip: 'Inicie um bate-papo intermediário.',
      },
      {
        id: 'learner',
        name: 'Aprendiz',
        tooltip: 'Obtenha 1/5 em um questionário.',
      },
      {
        id: 'lights_camera_action',
        name: 'Luzes, câmera, ação!',
        tooltip: 'Inicie um bate-papo com um tema de "Entretenimento".',
      },
      {
        id: 'live_love_travel',
        name: 'Viva. Ame. Viaje.',
        tooltip: 'Inicie um bate-papo com um tema de "Viagem".',
      },
      {
        id: 'fluency',
        name: 'Fluência',
        tooltip: 'Inicie um bate-papo de nível especialista.',
      },
      {
        id: 'first_words',
        name: 'Primeiras palavras',
        tooltip: 'Primeira mensagem de bate-papo.',
      },
      {
        id: 'great_listener',
        name: 'Ótimo ouvinte',
        tooltip: 'Complete sua primeira história em áudio.',
      },
      {
        id: 'career_ladder',
        name: 'Escada de Carreira',
        tooltip: 'Inicie um bate-papo com um tema de "Carreira".',
      },
      {
        id: 'bonds',
        name: 'Laços',
        tooltip: 'Inicie um bate-papo com um tema de "Relacionamento".',
      },
      {
        id: 'aspiring_learner',
        name: 'Aprendiz aspirante',
        tooltip: 'Inicie um bate-papo para iniciantes.',
      },
    ],
    spanish: [
      {
        id: 'sharing_is_caring',
        name: 'Compartir es cuidar',
        tooltip: 'Deja un comentario por primera vez.',
      },
      {
        id: 'bilingual',
        name: 'Bilingüe',
        tooltip: 'Cambia tu idioma nativo a algo que no sea español.',
      },
      {
        id: 'multi_lingual',
        name: 'Multilingüe',
        tooltip: 'Inicia un chat o lección en otro idioma.',
      },
      {
        id: 'first_100',
        name: 'Primer 100%',
        tooltip: 'Obtén 100% en un cuestionario.',
      },
      {
        id: 'first_steps',
        name: 'Primeros pasos',
        tooltip: 'Completa tu primera lección.',
      },
      {
        id: 'studious',
        name: 'Estudioso',
        tooltip: 'Completa la mitad de las lecciones.',
      },
      {
        id: 'love_to_learn',
        name: 'Amor por aprender',
        tooltip: 'Completa todas las lecciones.',
      },
      {
        id: 'ready_to_learn',
        name: '¡Listo para aprender!',
        tooltip: 'Comienza tu primera lección.',
      },
      {
        id: 'go_team',
        name: '¡Vamos equipo!',
        tooltip: 'Inicia un chat con un tema "Deportes".',
      },
      {
        id: 'fam',
        name: 'Familia',
        tooltip: 'Inicia un chat con un tema "Familia".',
      },
      {
        id: 'challenger',
        name: 'Desafiante',
        tooltip: 'Inicia un chat intermedio.',
      },
      {
        id: 'learner',
        name: 'Aprendiz',
        tooltip: 'Obtén 1/5 en un cuestionario.',
      },
      {
        id: 'lights_camera_action',
        name: '¡Luces, cámara, acción!',
        tooltip: 'Inicia un chat con un tema "Entretenimiento".',
      },
      {
        id: 'live_love_travel',
        name: 'Vive. Ama. Viaja.',
        tooltip: 'Inicia un chat con un tema "Viajes".',
      },
      {
        id: 'fluency',
        name: 'Fluidez',
        tooltip: 'Inicia un chat experto.',
      },
      {
        id: 'first_words',
        name: 'Primeras palabras',
        tooltip: 'Primer mensaje en el chat.',
      },
      {
        id: 'great_listener',
        name: 'Gran oyente',
        tooltip: 'Completa tu primera historia de audio.',
      },
      {
        id: 'career_ladder',
        name: 'Escalera de carrera',
        tooltip: 'Inicia un chat con un tema "Carrera".',
      },
      {
        id: 'bonds',
        name: 'Vínculos',
        tooltip: 'Inicia un chat con un tema "Relaciones".',
      },
      {
        id: 'aspiring_learner',
        name: 'Aprendiz aspirante',
        tooltip: 'Inicia un chat principiante.',
      },
    ],
    english: [
      {
        id: 'sharing_is_caring',
        name: 'Sharing is Caring',
        tooltip: 'Leave feedback for the first time.',
      },
      {
        id: 'bilingual',
        name: 'Bilingual',
        tooltip: 'Change your native language to something other than English.',
      },
      {
        id: 'multi_lingual',
        name: 'Multilingual',
        tooltip: 'Start a chat or lesson in another language.',
      },
      {
        id: 'first_100',
        name: 'First 100%',
        tooltip: 'Achieve 100% on a quiz.',
      },
      {
        id: 'first_steps',
        name: 'First Steps',
        tooltip: 'Complete your first lesson.',
      },
      {
        id: 'studious',
        name: 'Studious',
        tooltip: 'Complete half of the lessons.',
      },
      {
        id: 'love_to_learn',
        name: 'Love to Learn',
        tooltip: 'Complete all lessons.',
      },
      {
        id: 'ready_to_learn',
        name: 'Ready to Learn!',
        tooltip: 'Start your first lesson.',
      },
      {
        id: 'go_team',
        name: 'Go Team!',
        tooltip: 'Start a chat with a "Sports" topic.',
      },
      {
        id: 'fam',
        name: 'Family',
        tooltip: 'Start a chat with a "Family" topic.',
      },
      {
        id: 'challenger',
        name: 'Challenger',
        tooltip: 'Start an intermediate chat.',
      },
      {
        id: 'learner',
        name: 'Learner',
        tooltip: 'Get 1/5 on a quiz.',
      },
      {
        id: 'lights_camera_action',
        name: 'Lights, Camera, Action!',
        tooltip: 'Start a chat with an "Entertainment" topic.',
      },
      {
        id: 'live_love_travel',
        name: 'Live. Love. Travel.',
        tooltip: 'Start a chat with a "Travel" topic.',
      },
      {
        id: 'fluency',
        name: 'Fluency',
        tooltip: 'Start an expert chat.',
      },
      {
        id: 'first_words',
        name: 'First Words',
        tooltip: 'First chat message.',
      },
      {
        id: 'great_listener',
        name: 'Great Listener',
        tooltip: 'Complete your first audio story.',
      },
      {
        id: 'career_ladder',
        name: 'Career Ladder',
        tooltip: 'Start a chat with a "Career" topic.',
      },
      {
        id: 'bonds',
        name: 'Bonds',
        tooltip: 'Start a chat with a "Relationship" topic.',
      },
      {
        id: 'aspiring_learner',
        name: 'Aspiring Learner',
        tooltip: 'Start a beginner chat.',
      },
    ],
  },
  streaks: {
    currentStreak: {
      english: 'Current Streak',
      spanish: 'Racha actual',
      german: 'Aktuelle Serie',
      french: 'Séquence actuelle',
      italian: 'Serie attuale',
      portuguese: 'Sequência atual',
    },
    bestStreak: {
      english: 'Best Streak',
      spanish: 'Mejor racha',
      german: 'Beste Serie',
      french: 'Meilleure séquence',
      italian: 'Migliore serie',
      portuguese: 'Melhor sequência',
    },
    tooltip: {
      english:
        'Earn a streak by sending 10 messages a day or completing a lesson.',
      spanish:
        'Gana una racha enviando 10 mensajes al día o completando una lección.',
      german:
        'Verdienen Sie einen Streak, indem Sie 10 Nachrichten pro Tag senden oder eine Lektion abschließen.',
      french:
        'Gagnez une séquence en envoyant 10 messages par jour ou en terminant une leçon.',
      italian:
        'Ottieni una serie di successi inviando 10 messaggi al giorno o completando una lezione.',
      portuguese:
        'Ganhe uma sequência enviando 10 mensagens por dia ou completando uma lição.',
    },
    modalTitle: {
      english: 'Get A Streak',
      spanish: 'Consigue una racha',
      german: 'Holen Sie sich einen Streak',
      french: 'Obtenez une séquence',
      italian: 'Ottieni una serie',
      portuguese: 'Obtenha uma sequência',
    },
  },
  achievements: {
    modal: {
      title: {
        english: 'Achievements',
        spanish: 'Logros',
        german: 'Erfolge',
        french: 'Réalisations',
        italian: 'Raggiungimenti',
        portuguese: 'Conquistas',
      },
      subtitle: {
        english: 'Earn badges while you build your language skills',
        spanish:
          'Gana insignias mientras desarrollas tus habilidades lingüísticas',
        german:
          'Verdienen Sie Abzeichen, während Sie Ihre Sprachkenntnisse verbessern',
        french:
          'Gagnez des badges tout en développant vos compétences linguistiques',
        italian:
          'Guadagna distintivi mentre sviluppi le tue abilità linguistiche',
        portuguese:
          'Ganhe distintivos enquanto desenvolve suas habilidades linguísticas',
      },
    },
    dashboardCard: {
      title: {
        english: {
          latest: 'Latest Badge Earned',
          first: 'Earn Your First',
        },
        spanish: {
          latest: 'Última insignia ganada',
          first: 'Gana tu primera',
        },
        german: {
          latest: 'Neuestes Abzeichen verdient',
          first: 'Verdienen Sie Ihr erstes',
        },
        french: {
          latest: 'Dernier badge gagné',
          first: 'Gagnez votre premier',
        },
        italian: {
          latest: 'Ultimo badge guadagnato',
          first: 'Guadagna il tuo primo',
        },
        portuguese: {
          latest: 'Último distintivo ganho',
          first: 'Ganhe o seu primeiro',
        },
      },
    },
  },
  general: {
    newTranslation: {
      english: 'New',
      spanish: 'Nuevo',
      german: 'Neu',
      french: 'Nouveau',
      italian: 'Nuovo',
      portuguese: 'Novo',
    },
    achievement: {
      english: 'Achievement',
      spanish: 'Logro',
      german: 'Leistung',
      french: 'Réalisations',
      italian: 'Raggiungimenti',
      portuguese: 'Conquistas',
    },
    earned: {
      english: 'Earned',
      spanish: 'Ganado',
      french: 'Gagné',
      italian: 'Guadagnato',
      german: 'Verdient',
      portuguese: 'Ganho',
    },
    newAchievementEarned: {
      english: 'New Achievement Earned',
      spanish: 'Nuevo logro ganado',
      german: 'Neue Leistung verdient',
      french: 'Nouvelle réalisation gagnée',
      italian: 'Nuovo raggiungimento guadagnato',
      portuguese: 'Nova conquista ganha',
    },
  },
};
