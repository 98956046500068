import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { AssignmentIcon } from 'components/Language/AssignmentIcon/AssignmentIcon';

interface AssignmentDrawerItemProps {
  assignment: {
    id: number;
    title: string;
    status?: string;
    assignmentType: string;
  };
  activeLesson: boolean;
  redirectToLesson: (lessonId: number) => void;
}

export const AssignmentDrawerItem = ({
  assignment,
  activeLesson,
  redirectToLesson,
}: AssignmentDrawerItemProps) => {
  const handleRedirectToLesson = () => {
    redirectToLesson(assignment.id);
  };
  return (
    <Stack
      direction="row"
      alignItems="center"
      py={1}
      px={1.25}
      sx={{
        cursor: 'pointer',
        backgroundColor: activeLesson ? '#00000005' : 'transparent',
      }}
      onClick={handleRedirectToLesson}
    >
      <AssignmentIcon status={assignment.status || ''} />
      <Text variant="body1" sx={{ ml: 2 }}>
        {assignment.title}
      </Text>
    </Stack>
  );
};
