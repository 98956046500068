import { Stack, Text } from '@ltvco/refresh-lib/theme';
interface AstrologyUserInfoProps {
  title: JSX.Element | string;
  name: string;
  dob: string;
  standaloneVersion?: boolean;
}

export const UserInfo = ({
  title,
  name,
  dob,
  standaloneVersion,
}: AstrologyUserInfoProps) => {
  return (
    <Stack direction="column">
      <Text variant="h2" mb={0.5}>
        {title}
      </Text>
      <Stack direction={standaloneVersion ? 'row' : 'column'}>
        <Text {...(standaloneVersion && { variant: 'h5' })} mb={0.5} mr={0.5}>
          {name}
        </Text>
        <Text
          variant="h6"
          mb={0.5}
          fontWeight="normal"
          {...(standaloneVersion && {
            marginInline: 'auto',
            marginTop: 'auto',
          })}
        >
          {dob}
        </Text>
      </Stack>
    </Stack>
  );
};
