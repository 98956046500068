import { MenuSelectOptionProps } from 'components/Astrology';
import { useContext } from 'react';
import { SelectChangeEvent, Stack } from '@ltvco/refresh-lib/theme';
import { StyledSelect } from './components';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

interface AstrologyStandaloneMenuSelectProps {
  menuOptions: { [key: string]: MenuSelectOptionProps };
  activeHeaderMenuOption: MenuSelectOptionProps;
  setActiveHeaderMenuOption: (option: MenuSelectOptionProps) => void;
}

export const AstrologyStandaloneMenuSelect = ({
  menuOptions,
  activeHeaderMenuOption,
  setActiveHeaderMenuOption,
}: AstrologyStandaloneMenuSelectProps) => {
  const { trackEvent } = useContext(AppConfig);

  const localOnChangeCallback = (event: SelectChangeEvent) => {
    trackEvent(
      'dropdown menu',
      `engagement click ${event.target.value}`,
      'Standalone Astrology'
    );
    setActiveHeaderMenuOption(
      menuOptions[event.target.value as keyof typeof menuOptions]
    );
  };

  const filteredMenuOptions = {
    lifepath: menuOptions.lifepath,
    personality: menuOptions.personality,
  };
  const currentActiveOption = [
    filteredMenuOptions.lifepath.value,
    filteredMenuOptions.personality.value,
  ].includes(activeHeaderMenuOption.value)
    ? activeHeaderMenuOption
    : filteredMenuOptions.lifepath;

  return (
    <Stack width="100%" maxWidth="160px">
      <StyledSelect
        id="astrology-menu-select"
        value={currentActiveOption.value}
        options={Object.keys(filteredMenuOptions).map((optionKey: string) => {
          const option =
            filteredMenuOptions[optionKey as keyof typeof filteredMenuOptions];
          const displayText =
            optionKey === 'lifepath'
              ? `${option.display} Number`
              : option.display;
          return { value: option.value, display: displayText };
        })}
        autoWidth
        onChange={localOnChangeCallback}
      />
    </Stack>
  );
};
