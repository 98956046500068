import { Button, Stack } from '@ltvco/refresh-lib/theme';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SecurityFooter } from './SecurityFooter';

export const Navigation: React.FC<{
  isFirstOption: boolean;
  isLastOption: boolean;
  isForwardDisabled?: boolean;
  onNext: () => void;
  onBack: () => void;
  onSubmit: () => void;
}> = ({
  isFirstOption,
  isLastOption,
  isForwardDisabled,
  onNext,
  onBack,
  onSubmit,
}) => {
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        useFlexGap
        justifyContent={'space-between'}
        columnGap={[2, 0]}
      >
        <Button
          id="back"
          key="back"
          variant={'outlined'}
          sx={{
            width: ['100%', '172px'],
            visibility: !isFirstOption ? 'initial' : 'hidden',
          }}
          onClick={() => onBack()}
        >
          <ArrowBackIosIcon fontSize="inherit" />
          Back
        </Button>
        <Button
          variant={'contained'}
          id="next"
          key="next"
          disabled={isForwardDisabled}
          sx={{
            width: ['100%', '172px'],
            display: !isLastOption ? 'block' : 'none',
          }}
          onClick={async () => onNext()}
        >
          <Stack direction="row" alignItems="center" justifyContent="center">
            Next
            <ArrowForwardIosIcon
              fontSize="inherit"
              sx={{ marginLeft: [0.5] }}
            />
          </Stack>
        </Button>
        <Button
          variant={'contained'}
          key="submit"
          id="submit"
          disabled={isForwardDisabled}
          sx={{
            width: ['100%', '172px'],
            display: isLastOption ? 'block' : 'none',
          }}
          onClick={async () => onSubmit()}
        >
          Submit
        </Button>
      </Stack>
      <SecurityFooter />
    </Stack>
  );
};
