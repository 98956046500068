import {
  styled,
  Box,
  Text,
  Stack,
  Divider,
} from '@ltvco/refresh-lib/theme';
import { useEffect, useState } from 'react';
import { OnBoarding } from '../OnBoarding/OnBoarding';
import { NewChatModal } from '../NewChatModal/NewChatModal';
import { ChatsList } from './ChatsList';
import { LanguageHeader } from '../LanguageHeader/LanguageHeader';
import { useBumpeeUsers } from '../hooks/useBumpeeUsers';
import { useBumpeeChats } from '../hooks/useBumpeeChats';
import { TransitionChatModal } from '../ChatContainer/TransitionChatModal/TransitionChatModal';
import { useNavigate } from 'react-router-dom';
import { useBumpeeChat } from '../hooks/useBumpeeChat';
import { languageID, translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';
import { SplashPage } from './SplashPage/SplashPage';
import { AssignmentsContainer } from './AssignmentsContainer/AssignmentsContainer';
import { GamificationOverview } from '../Gamification/GamificationOverview/GamificationOverview';
import { useScreenSize } from '@ltvco/refresh-lib/utils';

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100vw',
  marginLeft: '-0.875rem',

  [theme.breakpoints.up('md')]: {
    width: 'calc(100% + 3.5rem)',
    marginLeft: '-1.75rem',
  },
}));

export const LanguageDashboard = () => {
  const { data: user, isLoading: isLoadingUser } = useBumpeeUsers();
  const { data: chatsResponse, isLoading: isLoadingChats } = useBumpeeChats();
  const { createChatMutation } = useBumpeeChat();
  const [onBoardingOpen, setOnBoardingOpen] = useState(false);
  const [shouldShowSplashPage, setShouldShowSplashPage] = useState(false);
  const [newChatModalOpen, setNewChatModalOpen] = useState(false);
  const [isTransitionChatModalOpen, setIsTransitionChatModalOpen] =
    useState(false);
  const { isMobile } = useScreenSize();
  const redirect = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState({
    learning_language_id: 0,
    level: '',
    topic_id: 0,
  });
  const { language } = useLanguageContext();

  useEffect(() => {
    setShouldShowSplashPage(!Boolean(user?.user_native_language));
  }, [user]);

  useEffect(() => {
    document.title = 'Language - BeenVerified';
  }, []);

  if (isLoadingUser) {
    return <div></div>;
  }

  const handleOnClickAddNewChat = () => {
    setNewChatModalOpen(true);
  };

  const handleGetStarted = () => {
    setOnBoardingOpen(true);
    setShouldShowSplashPage(false);
  };

  const handleOnCloseNewChatModal = () => {
    setNewChatModalOpen(false);
  };

  const handleCreateChat = async () => {
    const response = await createChatMutation.mutateAsync(selectedOptions);

    redirect(`/dashboard/language/chat/${response?.chat_id}`);
  };

  const handleCloseTransitionChatModal = async () => {
    handleCreateChat();
    setIsTransitionChatModalOpen(false);
  };

  const handleStartConversation = () => {
    if (
      chatsResponse?.chats.find(
        (chat: { learning_language: string; }) =>
          languageID(chat.learning_language.toLowerCase()) ===
          selectedOptions.learning_language_id
      )
    ) {
      handleCreateChat();
      handleOnCloseNewChatModal();
    } else {
      setNewChatModalOpen(false);
      setIsTransitionChatModalOpen(true);
    }
  };

  return (
    <>
      {newChatModalOpen && (
        <NewChatModal
          setNewChatModalOpen={setNewChatModalOpen}
          selectedOptions={selectedOptions}
          handleOnClose={handleOnCloseNewChatModal}
          setSelectedOptions={setSelectedOptions}
          handleStartConversation={handleStartConversation}
        />
      )}
      {isTransitionChatModalOpen && (
        <TransitionChatModal
          learningLanguage={selectedOptions.learning_language_id}
          nativeLanguage={language}
          setNewTransitionModalOpen={handleCloseTransitionChatModal}
        />
      )}
      {shouldShowSplashPage && (
        <SplashPage handleGetStarted={handleGetStarted} />
      )}
      {onBoardingOpen && <OnBoarding setOnBoardingOpen={setOnBoardingOpen} />}
      {!onBoardingOpen && !shouldShowSplashPage && (
        <Stack flexDirection="column" height={'100%'}>
          <LanguageHeader />
          <Box>
            <Stack
              flexDirection={{ sm: 'column', md: 'row' }}
              alignItems="center"
              textAlign={{ xs: 'center', md: 'start' }}
            >
              {!isMobile && (
                <img src={translations.avatar[language]} alt="Hi beverly" />
              )}
              <Box ml={2} mb={{ xs: 4, md: 0 }}>
                <Text variant="h3">
                  {translations.dashboard.header.subtitle[language]}
                </Text>
                <Text variant="body1">
                  {translations.dashboard.header.description[language]}
                </Text>
              </Box>
              {isMobile && (
                <img src={translations.avatar[language]} alt="Hi beverly" />
              )}
            </Stack>
            <StyledDivider />
          </Box>
          <GamificationOverview />
          <Stack flexDirection="column" flex={1}>
            <ChatsList
              handleOnClickAddNewChat={handleOnClickAddNewChat}
              chatsList={chatsResponse?.chats}
              isLoading={isLoadingChats}
            />
            <AssignmentsContainer />
          </Stack>
        </Stack>
      )}
    </>
  );
};
