import { Box, Text } from '@ltvco/refresh-lib/theme';
import { AstrologyAscendantOnboarding } from './AstrologyAscendantOnboarding/AstrologyAscendantOnboarding';
import {
  type CreateAscendantResponse,
  type UserPropsExtra,
} from '../interfaces';

interface AstrologyAscendantProps {
  date?: string;
  month?: string;
  year?: string;
  ascendantResult?: CreateAscendantResponse;
  setAstrologyUserCallback: (astrologyUser: UserPropsExtra | null) => void;
}

export const AstrologyAscendant: React.FC<AstrologyAscendantProps> = ({
  date,
  month,
  year,
  ascendantResult,
  setAstrologyUserCallback,
}) => {
  return (
    <Box>
      {!ascendantResult || Object.keys(ascendantResult).length === 0 ? (
        <AstrologyAscendantOnboarding
          date={date}
          month={month}
          year={year}
          onSubmitCallback={setAstrologyUserCallback}
        />
      ) : (
        <Text>{ascendantResult.report}</Text>
      )}
    </Box>
  );
};
