import {
  Box,
  Button,
  Modal,
  Rating,
  Stack,
  Text,
  styled,
} from '@ltvco/refresh-lib/theme';
import { useContext, useState } from 'react';
import { useLanguageContext } from '../LanguageContext';
import { translations } from '../constants';
import { useAchievementEventCoordinator } from '../hooks/useAchievementEventCoordinator';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Close, ThumbUpAltOutlined, CheckCircle, ChatBubbleOutline, StarBorder, StarRate } from '@mui/icons-material';

interface FeedbackModalProps {
  open: boolean;
  handleClose: () => void;
}

const StyledModal = styled(Modal)(({ theme }) => ({
  '& .MuiPaper-root': {
    background: 'linear-gradient(0deg, #FFF 15.31%, #FAF3FF 100%)',
  },
}));

const googleFormUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSfhfh41Ssa8JSky8h90f1WxyybJx0CjzqDumhh4ofmoZIO8yQ/viewform';

export const FeedbackModal = ({ open, handleClose }: FeedbackModalProps) => {
  const { handleAchievementEvent } = useAchievementEventCoordinator();
  const [ratingValue, setRatingValue] = useState(0);
  const [hasFeedback, setHasFeedback] = useState(Boolean(ratingValue));
  const { language } = useLanguageContext();
  const { trackEventGA4 } = useContext(AppConfig);

  const handleRatingClick = (
    _event: React.SyntheticEvent,
    newValue: number | null
  ) => {
    handleAchievementEvent({
      event: 'create',
      category: 'feedback',
    });
    setRatingValue(newValue ?? 0);
    setHasFeedback(true);
    trackEventGA4({
      event_name: 'rating_click',
      type: 'language tutor',
      value: newValue,
    });
  };

  const redirectToGoogleForm = () => {
    window.open(googleFormUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <StyledModal open={open} onClose={handleClose}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          width: { xs: '100%', md: '272px' },
          height: { xs: '100%', md: '274px' },
        }}
      >
        <Close
          sx={{
            fontSize: '24px',
            alignSelf: 'flex-end',
            cursor: 'pointer',
            color: 'primary.main',
          }}
          onClick={handleClose}
        />
        <ThumbUpAltOutlined
          sx={{ fontSize: '40px', color: 'primary.main', mb: 1 }}
        />
        <Text variant="h4" mb={5.375}>
          {translations.dashboard.giveFeedback.title[language]}
        </Text>
        <Text variant="h5" mb={0} textAlign="center">
          {translations.dashboard.giveFeedback.question[language]}
        </Text>
        {hasFeedback ? (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            mt={2.25}
          >
            <Stack direction="row">
              <Box color={'#4CAF50'}>
                <CheckCircle
                  sx={{ fontSize: '20px', color: 'inherit', mr: 1 }}
                />
              </Box>
              <Text variant="h6">
                {translations.dashboard.giveFeedback.thanks[language]}
              </Text>
            </Stack>
            <Button
              variant="outlined"
              startIcon={<ChatBubbleOutline />}
              onClick={redirectToGoogleForm}
            >
              {translations.dashboard.giveFeedback.request[language]}
            </Button>
          </Stack>
        ) : (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            mt={1.25}
          >
            <Rating
              value={ratingValue}
              onChange={handleRatingClick}
              emptyIcon={
                <Box fontSize={'35px'}>
                  <StarBorder fontSize="inherit" />
                </Box>
              }
              icon={
                <Box color="#FFB400" fontSize={'35px'}>
                  <StarRate fontSize="inherit" color="inherit" />
                </Box>
              }
            />
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              width={'172px'}
              sx={{ position: 'relative' }}
            >
              <Text variant="body2" color={'primary.light'}>
                {translations.dashboard.giveFeedback.options[language][0]}
              </Text>
              <Text
                variant="body2"
                color={'primary.light'}
                sx={{ position: 'absolute', right: -15 }}
              >
                {translations.dashboard.giveFeedback.options[language][1]}
              </Text>
            </Stack>
          </Stack>
        )}
      </Stack>
    </StyledModal>
  );
};
