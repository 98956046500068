import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { AstrologyIcon } from '../AstrologyIcon/AstrologyIcon';

interface CompatibilityHeaderProps {
  value: string;
  selectValue: string;
  onChangeCallback: (sign: string) => void;
  standaloneVersion?: boolean;
}

export const CompatibilityHeader = ({
  value,
  selectValue,
  onChangeCallback,
  standaloneVersion,
}: CompatibilityHeaderProps) => {
  function onSelectChange(event: any) {
    onChangeCallback(event.target.value);
  }

  return (
    <Stack
      justifyContent="center"
      flexDirection="row"
      mt={2}
      zIndex="1"
      alignItems="center"
    >
      <AstrologyIcon sign={value} standaloneVersion={standaloneVersion} />
      <Text variant="h2">+</Text>
      <AstrologyIcon
        sign={selectValue}
        selectValue={selectValue}
        enableSelect
        onSelectChange={onSelectChange}
      />
    </Stack>
  );
};
