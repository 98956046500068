import { Box, keyframes, styled } from '@ltvco/refresh-lib/theme';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled(Box)(({ theme }) => ({
  border: `8px solid ${theme.palette.primary.light}`,
  borderTop: `8px solid ${
    theme.palette.progress?.main || theme.palette.primary.dark
  }`,
  borderRadius: '50%',
  height: '60px',
  width: '60px',
  animation: `${spin} 2s linear infinite`,
}));
