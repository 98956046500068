import {
  Box,
  Button,
  Select,
  Text,
  Stack,
  SelectOption,
} from '@ltvco/refresh-lib/theme';
import { useEffect, useState } from 'react';
import { SelectedOptions } from './OnBoarding';
import { languageName, translations } from '../constants';

export interface StepProps {
  isOnboarding?: boolean;
  nextStep?: () => void;
  backStep?: () => void;
  setSelectedOptions: (prevState: any) => void;
  selectedOptions: SelectedOptions;
  checkIfStepIsComplete?: () => boolean;
  nativeLanguage?: string;
}

export const StepOne = ({
  nextStep,
  setSelectedOptions,
  selectedOptions,
}: StepProps) => {
  const [options, setOptions] = useState<SelectOption[]>([]);
  const language = languageName(
    Boolean(selectedOptions.native_language_id)
      ? selectedOptions.native_language_id
      : 6
  );
  const languages = translations.languages[language];

  useEffect(() => {
    if (languages) {
      const transformedOptions = languages.map((item) => ({
        value: item.id.toString(),
        display: item.name,
      }));
      setOptions(transformedOptions);
    }
  }, [languages]);

  const handleOnClickSelect = (value: string) => {
    setSelectedOptions((prevState: any) => ({
      ...prevState,
      native_language_id: parseInt(value),
    }));
  };

  const handleOnClickContinue = () => {
    if (!nextStep) return;

    nextStep();
  };

  return (
    <Box>
      <Stack width={{ xs: '100%', md: '50%' }}>
        <Text variant="h2">{translations.onBoarding.title[language]}</Text>

        <Text variant="body1" sx={{ color: 'text.main' }}>
          {
            translations.onBoarding.description[
              languageName(
                Boolean(selectedOptions.native_language_id)
                  ? selectedOptions.native_language_id
                  : 6
              )
            ]
          }
        </Text>
      </Stack>
      <Stack width="220px" mt={2}>
        <Text variant="h5" mb={0.5}>
          {translations.dashboard.languageSettings.nativeLanguage[language]}
        </Text>
        <Select
          options={options}
          id="random"
          onOptionSelect={handleOnClickSelect}
          value={selectedOptions?.native_language_id || ''}
        />
      </Stack>
      <Box pt={4}>
        <Button
          className="language-continue"
          variant="contained"
          onClick={handleOnClickContinue}
          disabled={selectedOptions.native_language_id == 0}
        >
          {translations.buttons.continue[language]}
        </Button>
      </Box>
    </Box>
  );
};
