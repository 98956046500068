export const useCases = [
  {
    name: 'Lead Generation',
    display: 'Lead Generation',
    value: 'Lead Generation',
    constantValue: 'LEAD_GENERATION',
  },
  {
    name: 'Address Verification',
    display: 'Address Verification',
    value: 'Address Verification',
    constantValue: 'ADDRESS_VERIFICATION',
  },
  {
    name: 'Fraud Detection',
    display: 'Fraud Detection',
    value: 'Fraud Detection',
    constantValue: 'FRAUD_DETECTION',
  },
  {
    name: 'Data Enrichment',
    display: 'Data Enrichment',
    value: 'Data Enrichment',
    constantValue: 'DATA_ENRICHMENT',
  },
  {
    name: 'Other',
    display: 'Other',
    value: 'Other',
    constantValue: 'OTHER',
  },
];

export const reportsPerMonthOptions = [
  { name: '200', display: '200', value: '200' },
  { name: '400', display: '400', value: '400' },
  { name: '800', display: '800', value: '800' },
  { name: '800+', display: '800+', value: '800+' },
];
