import { Stack, styled } from '@ltvco/refresh-lib/theme';
import { Summaries } from './Summaries';
import { CheckEmail } from './CheckEmail';
import { Loading } from '../Loading';
import { useSessionEscalation, useSession } from '@ltvco/refresh-lib/v1';

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  alignItems: 'center',
  textAlign: 'center',
  padding: theme.spacing(2, 2.5),
}));

export const GettingStarted = () => {
  const { checkEmail } = useSessionEscalation();

  const {
    session: { account },
  } = useSession();

  const isEnrolled =
    account?.account?.user_settings?.credit_hub_enrollment_completed;

  const display = () => {
    if (checkEmail) {
      return <CheckEmail />;
    }

    if (Boolean(isEnrolled)) {
      return <Loading />;
    }

    return <Summaries />;
  };

  return <StyledStack>{display()}</StyledStack>;
};
