import {
  PaymentsSelectionModal,
  RadioPaymentsModal,
  RecyclingModal,
} from '@ltvco/refresh-lib/payments';
import { Stack, Theme, Button, Text } from '@ltvco/refresh-lib/theme';
import { useSession, useSnackbar, SnackbarKey } from '@ltvco/refresh-lib/v1';
import { useEffect, useState } from 'react';

export const Recycling = ({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) => {
  const {
    session: { account },
  } = useSession();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackBarId, setSnackBarId] = useState<SnackbarKey>();
  const [openRecyclingModal, setOpenRecyclingModal] = useState<boolean>(false);
  const [openPaymentsSelectionModal, setOpenPaymentsSelectionModal] =
    useState<boolean>(false);
  const [openPaymentsUpdateModal, setOpenPaymentsUpdateModal] =
    useState<boolean>(false);

  const toggleRecyclingModal = () =>
    setOpenRecyclingModal((prevState) => !prevState);

  const togglePaymentsSelectionModal = () =>
    setOpenPaymentsSelectionModal((prevState) => !prevState);

  const togglePaymentsUpdateModal = () => {
    setOpenPaymentsUpdateModal(!openPaymentsUpdateModal);
    if (!openPaymentsSelectionModal) {
      togglePaymentsSelectionModal();
    }
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    const openSnackBar = () => {
      const snackbarID = enqueueSnackbar(
        <Text>
          <b>Payment Method Update Required</b> <br /> Please update your
          payment method on file to continue accessing
        </Text>,
        {
          variant: 'error',
          hideIconVariant: true,
          persist: true,
          action: (
            <Stack gap={1} flexDirection={'row'}>
              <Button
                onClick={() => {
                  snackbarID && closeSnackbar(snackbarID);
                }}
                size="small"
                variant="outlined"
                sx={(theme: Theme) => ({
                  fontsize: theme.spacing(0.75),
                  maxHeight: '40px',
                  backgroundColor: `${theme.palette.success.main} !important`,
                  color: `${theme.palette.success.contrastText} !important`,
                  borderColor: `${theme.palette.success.main} !important`,
                  '&:hover': {
                    backgroundColor: `${theme.palette.success.main} !important`,
                    borderColor: `${theme.palette.success.contrastText} !important`,
                  },
                })}
              >
                Close
              </Button>
              <Button
                onClick={togglePaymentsSelectionModal}
                variant="outlined"
                size="small"
                sx={(theme: Theme) => ({
                  fontsize: theme.spacing(0.75),
                  maxHeight: '40px',
                  color: `${theme.palette.error.main} !important`,
                  borderColor: 'white',
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: `${theme.palette.error.main}`,
                    borderColor: 'black',
                  },
                })}
              >
                Update Billing Info
              </Button>
            </Stack>
          ),
        }
      );
      return snackbarID;
    };

    const hasUserInRecycling = account?.account.user_info?.user_in_recycling;
    if (hasUserInRecycling === undefined) return;
    const urlParams = new URLSearchParams(window.location.search);
    if (
      hasUserInRecycling &&
      (urlParams.get('bvppcanc') ||
        urlParams.get('bvpp') ||
        urlParams.get('token'))
    ) {
      const snackBarId = openSnackBar();
      setSnackBarId(snackBarId);
      return;
    }

    if (hasUserInRecycling && !snackBarId) {
      setOpenRecyclingModal(true);
      const snackBarId = openSnackBar();
      setSnackBarId(snackBarId);
      return;
    }
    if (!hasUserInRecycling && snackBarId) {
      setOpenRecyclingModal(false);
      closeSnackbar(snackBarId);
    }
  }, [account, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const handleOpenModal = () => {
      toggleRecyclingModal();
    };

    const handleOpenSnackBar = (event: CustomEvent) => {
      enqueueSnackbar(event.detail.message, {
        variant: event.detail.variant,
      });
    };

    window.addEventListener('openRecyclingModal', handleOpenModal);
    window.addEventListener(
      'openRecyclingFeedbackSnackbar',
      handleOpenSnackBar as EventListener
    );

    return () => {
      window.removeEventListener('openRecyclingModal', handleOpenModal);
      window.removeEventListener(
        'openRecyclingFeedbackSnackbar',
        handleOpenSnackBar as EventListener
      );
    };
  }, [isAuthenticated]);

  function handleAddPaymentMethod() {
    setOpenPaymentsSelectionModal(false);
    setOpenPaymentsUpdateModal(true);
  }

  return (
    <>
      {isAuthenticated ? (
        <>
          <RecyclingModal
            open={openRecyclingModal}
            onClose={toggleRecyclingModal}
            openUpdatePayment={togglePaymentsSelectionModal}
          />

          <PaymentsSelectionModal
            open={openPaymentsSelectionModal}
            onClose={togglePaymentsSelectionModal}
            onAddPaymentMethod={handleAddPaymentMethod}
            cta="Use This Payment Method"
            isRecycling={true}
          />
          <RadioPaymentsModal
            isOpen={openPaymentsUpdateModal}
            onCloseHandle={togglePaymentsUpdateModal}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
