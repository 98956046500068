import { Stack, Box, Text } from '@ltvco/refresh-lib/theme';
import { useBumpeeChats } from '../../hooks/useBumpeeChats';
import { useNavigate, useParams } from 'react-router-dom';
import { RecentChatCard } from './RecentChatCard/RecentChatCard';
import {
  NewChatButton,
  NewChatIcon,
} from 'components/Language/Dashboard/ChatsList';
import { useState } from 'react';
import { NewChatModal } from 'components/Language/NewChatModal/NewChatModal';
import { useBumpeeChat } from 'components/Language/hooks/useBumpeeChat';
import { TransitionChatModal } from '../TransitionChatModal/TransitionChatModal';
import { languageID, translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { Chat } from 'api';

export function QuickChatSideBar() {
  const chatId = Number(useParams().chatId);
  const { language } = useLanguageContext();
  const { data: recentChatsResponse, isLoading } = useBumpeeChats();
  const { createChatMutation } = useBumpeeChat();
  const { data: chatsResponse } = useBumpeeChats();
  const [newChatModalOpen, setNewChatModalOpen] = useState(false);
  const [isTransitionChatModalOpen, setIsTransitionChatModalOpen] =
    useState(false);
  const redirect = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState({
    learning_language_id: 0,
    level: '',
    topic_id: 0,
  });

  //TODO: fix ts errors
  const currentChat = recentChatsResponse?.chats?.find(
    (chat: { chat_id: number; }) => chat.chat_id === chatId
  );
  const filteredRecentChats = recentChatsResponse?.chats
    .filter((chat: { chat_id: number; }) => chat.chat_id !== chatId)
    .slice(-3);

  const buildChatCards = () => {
    if (!recentChatsResponse?.chats || !currentChat) return null;
    return filteredRecentChats?.map((chat: Chat) => {
      return (
        <RecentChatCard
          key={`chat-card-${chat.chat_id}`}
          chat={chat}
          isLoading={isLoading}
        />
      );
    });
  };

  const handleOnClickAddNewChat = () => {
    setNewChatModalOpen(true);
  };

  const handleOnCloseNewChatModal = () => {
    setNewChatModalOpen(false);
  };

  const handleCreateChat = async () => {
    const response = await createChatMutation.mutateAsync(selectedOptions);
    redirect(`/dashboard/language/chat/${response?.chat_id}`);
    setSelectedOptions({
      learning_language_id: 0,
      level: '',
      topic_id: 0,
    });
  };

  const handleStartConversation = () => {
    if (
      chatsResponse?.chats.find(
        (chat: { learning_language: string; }) =>
          languageID(chat.learning_language.toLowerCase()) ===
          selectedOptions.learning_language_id
      )
    ) {
      handleCreateChat();
      handleOnCloseNewChatModal();
    } else {
      setNewChatModalOpen(false);
      setIsTransitionChatModalOpen(true);
    }
  };

  const handleCloseTransitionChatModal = async () => {
    handleCreateChat();
    setIsTransitionChatModalOpen(false);
  };

  return (
    <Box width="174px" pr={2} borderRight="1px solid #E2E2E2">
      {newChatModalOpen && (
        <NewChatModal
          setNewChatModalOpen={setNewChatModalOpen}
          selectedOptions={selectedOptions}
          handleOnClose={handleOnCloseNewChatModal}
          setSelectedOptions={setSelectedOptions}
          handleStartConversation={handleStartConversation}
        />
      )}
      {isTransitionChatModalOpen && (
        <TransitionChatModal
          learningLanguage={selectedOptions.learning_language_id}
          nativeLanguage={language}
          setNewTransitionModalOpen={handleCloseTransitionChatModal}
        />
      )}
      <Stack alignItems="center" height={'100%'} mt={2}>
        <Text variant="body2" mb={2.25}>
          {translations.chatUI.recentChats[language]}
        </Text>
        <NewChatButton
          variant="outlined"
          color="primary"
          onClick={handleOnClickAddNewChat}
        >
          <NewChatIcon fontSize="small" />
          {translations.buttons.create[language]}
        </NewChatButton>
        <Stack>
          <Box>
            {currentChat && (
              <RecentChatCard
                chat={currentChat}
                isLoading={isLoading}
                isSelected={true}
              />
            )}
          </Box>
          {buildChatCards()}
        </Stack>
      </Stack>
    </Box>
  );
}
