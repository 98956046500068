import React from 'react';
import { Controller } from 'react-hook-form';
import { Input} from '@ltvco/refresh-lib/theme';
import { StyledErrorText, StyledInputLabel } from './components';
import { Warning } from '@mui/icons-material';

interface CompanyFieldsProps {
  control: any;
  errors: any;
}

const CompanyFields: React.FC<CompanyFieldsProps> = ({ control, errors }) => {
  return (
    <>
      <StyledInputLabel htmlFor="companyName">Company Name*</StyledInputLabel>
      <Controller
        name="companyName"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            fullWidth
            id="companyName"
            type="text"
            placeholder="Company"
            {...field}
          />
        )}
      />

      <StyledInputLabel htmlFor="companyUrl">Company URL*</StyledInputLabel>
      <Controller
        name="companyUrl"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            fullWidth
            id="companyUrl"
            type="url"
            placeholder="https://"
            {...field}
          />
        )}
      />

      <StyledInputLabel htmlFor="businessEmail">
        Business Email Address*
      </StyledInputLabel>
      <Controller
        name="businessEmail"
        control={control}
        rules={{
          required: 'This field is required',
          pattern: {
            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            message:
              'Please enter a valid email address (e.g. name@example.com).',
          },
        }}
        render={({ field }) => (
          <Input
            fullWidth
            id="businessEmail"
            type="email"
            placeholder="Enter Business Email Address"
            {...field}
            error={Boolean(errors?.businessEmail)}
          />
        )}
      />
      {errors.businessEmail && (
        <StyledErrorText>
          <Warning fontSize="inherit" />
          {errors.businessEmail.message}
        </StyledErrorText>
      )}
    </>
  );
};

export default CompanyFields;
