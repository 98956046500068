import {
  languageName,
  topicName,
  translations,
} from 'components/Language/constants';
import {
  CreateUserRequirementParams,
  GetLessonChatsResponse,
  LessonChatResponse,
} from 'api';
import { useCreateRequirement } from './useCreateRequirement';
import { useQueryClient } from '@ltvco/refresh-lib/vendors';
import { capitalizeString } from '@ltvco/refresh-lib/utils';

const BASE_QUERY_KEY = 'bumpeeGO';

interface MetaData {
  [key: string]: string | number;
}
interface AchievementEventObject {
  event: string;
  category: string;
  metaData?: MetaData;
}

const convertEvent = (obj: AchievementEventObject) => {
  const meta_data: MetaData = {};

  if (obj.metaData) {
    if (obj.metaData?.topicId) {
      meta_data.chat_topic = topicName(
        parseInt(obj.metaData.topicId as string)
      );
    }
    if (obj.metaData.level) {
      meta_data.level = obj.metaData.level;
    }
  }

  return {
    event: obj.event,
    category: obj.category,
    meta_data: { ...obj.metaData, ...meta_data },
  } as CreateUserRequirementParams;
};

export const useAchievementEventCoordinator = () => {
  const queryClient = useQueryClient();
  const { createUserRequirementMutation } = useCreateRequirement();

  const lessonChatsData = queryClient.getQueryData([
    `${BASE_QUERY_KEY}-lessons-chats`,
  ]) as GetLessonChatsResponse;

  const currentLessonTopicTotalLength =
    translations.dashboard.assignments.lesson.lessonList.english.length;

  const groupByLearningLanguage = () => {
    return lessonChatsData?.lesson_chats?.reduce(
      (acc: { [key: number]: any[] }, obj) => {
        const key = obj.learning_language_id;
        if (!acc[key]) {
          acc[key] = [];
        }

        if (obj.completed) {
          acc[key].push(obj);
        }

        return acc;
      },
      {} as { [key: number]: any[] }
    );
  };

  const handleAchievementEvent = (obj: AchievementEventObject) => {
    const objectToPost = obj.metaData ? convertEvent(obj) : obj;

    createUserRequirementMutation.mutate(objectToPost);
  };

  const handleLessonAchievementEvent = (lessonChatId: number) => {
    const currentLesson = queryClient.getQueryData([
      `${BASE_QUERY_KEY}-lesson-chat-${lessonChatId}`,
    ]) as LessonChatResponse;
    if (!currentLesson) return;

    const groupedByLearningLanguage = groupByLearningLanguage();
    const currentLanguageCompletedLessonLength =
      groupedByLearningLanguage[currentLesson.lesson_chat.learning_language_id]
        .length + 1;

    handleAchievementEvent({
      event: 'complete',
      category: 'lesson',
      metaData: {
        lessons_completed: currentLanguageCompletedLessonLength,
        lessons_total: currentLessonTopicTotalLength,
        language: capitalizeString(
          languageName(currentLesson.lesson_chat.learning_language_id)
        ),
        lesson: currentLesson.lesson_chat.lesson_topic,
      },
    });
  };

  const handleUserActionBasedAchievementEvent = (
    obj: AchievementEventObject
  ) => {
    handleAchievementEvent(obj);
  };

  const handleAudioStoryBasedAchievementEvent = (
    obj: AchievementEventObject
  ) => {
    handleAchievementEvent(obj);
  };

  const handleQuizBasedAchievementEvent = (obj: AchievementEventObject) => {
    handleAchievementEvent(obj);
  };

  return {
    handleAchievementEvent,
    handleLessonAchievementEvent,
    handleUserActionBasedAchievementEvent,
    handleQuizBasedAchievementEvent,
    handleAudioStoryBasedAchievementEvent,
  };
};
