import {
  styled,
  Box,
  Divider,
  Stack,
  Text,
  Card,
  Tooltip,
  IconButton,
  useTheme,
} from '@ltvco/refresh-lib/theme';
import { useState } from 'react';
import { AchievementsTrackerModal } from '../../AchievementsTrackerModal/AchievementsTrackerModal';
import { useUserStreak } from 'components/Language/hooks/useUserStreak';
import { translations } from '../../../constants';
import { useLanguageContext } from '../../../LanguageContext';
import { ScienceOutlined, InfoOutlined, LocalFireDepartmentRounded } from '@mui/icons-material';

const CornerBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '0.125rem 0.75rem',
  background: theme.palette.secondary.main,
  borderBottomRightRadius: 8,
}));

export const StreaksInfo = () => {
  const theme = useTheme();
  const { language } = useLanguageContext();
  const { data: userStreak } = useUserStreak();
  const [isStreakModalOpen, setIsStreakModalOpen] = useState(false);

  const streaksInfo = {
    currentStreak: userStreak?.streak?.number_of_days || 0,
    longestStreak: userStreak?.streak?.longest_streak || 0,
    active: userStreak?.streak?.active || false,
  };
  const handleToggleStreakModal = () => {
    setIsStreakModalOpen(!isStreakModalOpen);
  };

  const pluralizeDay = (numberOfDays: number) => {
    switch (language) {
      case 'english': {
        return numberOfDays === 0 || numberOfDays > 1 ? 'Days' : 'Day';
      }
      case 'french': {
        return numberOfDays > 1 ? 'Jours' : 'Jour';
      }
      case 'spanish': {
        return numberOfDays > 1 ? 'Días' : 'Día';
      }
      case 'german': {
        return numberOfDays > 1 ? 'Tage' : 'Tag';
      }
      case 'italian': {
        return numberOfDays > 1 ? 'Giorni' : 'Giorno';
      }
      case 'portuguese': {
        return numberOfDays > 1 ? 'Dias' : 'Dia';
      }
    }
  };

  return (
    <Card variant="outlined" sx={{ position: 'relative', pl: 0, pt: 3.5 }}>
      <CornerBox display={'flex'} alignItems={'center'}>
        <ScienceOutlined fontSize="small" sx={{ color: theme.palette.primary.main}} />
        <Text ml={0.5}>Beta</Text>
      </CornerBox>
      <Tooltip
        enterTouchDelay={300}
        title={translations.streaks.tooltip[language]}
      >
        <IconButton
          onClick={handleToggleStreakModal}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <InfoOutlined
            fontSize="small"
            sx={{
              color: 'barChart.dark',
            }}
          />
        </IconButton>
      </Tooltip>
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Stack display="flex" flexDirection="row">
          <LocalFireDepartmentRounded
            sx={{
              fontSize: { xs: '3.15rem', md: '3.75rem' },
              mt: { xs: 0.5, md: 0 },
              mr: 1,
              color: '#EA7825',
              opacity: 1,
            }}
          />
          <Stack>
            <Text variant="h5" sx={{ color: 'secondary.contrastText' }}>
              {translations.streaks.currentStreak[language]}
            </Text>
            <Text variant="h3" sx={{ color: 'secondary.contrastText' }}>
              {`${streaksInfo.currentStreak} ${pluralizeDay(
                streaksInfo.currentStreak
              )}`}
            </Text>
          </Stack>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack>
          <Text variant="h5" sx={{ color: 'secondary.contrastText' }}>
            {translations.streaks.bestStreak[language]}
          </Text>
          <Text variant="h3" sx={{ color: 'secondary.contrastText' }}>
            {`${streaksInfo.longestStreak} ${pluralizeDay(
              streaksInfo.longestStreak
            )}`}
          </Text>
        </Stack>
        {isStreakModalOpen && (
          <AchievementsTrackerModal
            items={[
              {
                title: translations.streaks.modalTitle[language],
                description: translations.streaks.tooltip[language],
                customIcon: (
                  <LocalFireDepartmentRounded
                    sx={{
                      fontSize: '3.75rem',
                      mt: 4,
                      color: '#EA7825',
                    }}
                  />
                ),
              },
            ]}
            handleOnClose={handleToggleStreakModal}
          />
        )}
      </Stack>
    </Card>
  );
};
