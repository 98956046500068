import { useState, useContext } from 'react';
import { creditBureauList, options } from './constants';
import CreditFreezeLogo from 'images/credit-freeze-logo.svg';
import {
  Text,
  Button,
  Select,
  Stack,
  useTheme,
} from '@ltvco/refresh-lib/theme';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

const CreditFreeze = () => {
  const [selectedFreeze, setSelectedFreeze] = useState(creditBureauList[0]);
  const { trackEvent } = useContext(AppConfig);

  const openActiveFreeze = () => {
    trackEvent('credit freeze', 'engagement click', 'id monitor');
    window.open(selectedFreeze.url, '_blank');
  };

  const theme = useTheme();

  return (
    <Stack
      gap={2}
      textAlign="center"
      // my={[0, 2.5]}
      padding={2.5}
      sx={{ backgroundColor: theme.palette.background.paper }}
    >
      <img
        height={36}
        width={51.5}
        src={CreditFreezeLogo}
        alt="Credit Freeze Logo"
        style={{ margin: 'auto' }}
      />
      <Stack>
        <Text variant="h2">Credit Freeze</Text>
        <Text variant="body1">
          Prevent credit fraud by freezing access to your information with the
          three credit bureaus.
          <br />
          If your personal information has been compromised, it’s a good idea to
          freeze until it’s resolved.
        </Text>
      </Stack>
      <Text variant="body2" textAlign={['left', 'center']}>
        Contact {selectedFreeze.name} by phone at
        <strong> {selectedFreeze.phone} </strong>
        or Freeze Account Below
      </Text>
      <Stack gap={[2, 1.5]} flexDirection={['column', 'row']}>
        <Select
          id="credit-freeze-select"
          data-testid="credit-freeze-select"
          value={selectedFreeze.name}
          options={options}
          sx={{
            textAlign: 'left',
            svg: {
              color: 'text.primary',
            },
          }}
          onChange={(e) =>
            setSelectedFreeze(
              creditBureauList.find((item) => item.name === e.target.value)!
            )
          }
        />

        <Button
          variant="contained"
          onClick={() => openActiveFreeze()}
          sx={{
            width: ['100%', '172px'],
            minWidth: ['100%', '172px'],
          }}
        >
          Freeze
        </Button>
      </Stack>
    </Stack>
  );
};

export default CreditFreeze;
