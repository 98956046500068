import {  Stack } from '@ltvco/refresh-lib/theme';
import { AstrologyStandaloneCompatibility } from './AstrologyStandaloneCompatibility/AstrologyStandaloneCompatibility';
import { AstrologyStandaloneAscendant } from './AstrologyStandaloneAscendant/AstrologyStandaloneAscendant';
import { AstrologyStandaloneHoroscope } from './AstrologyStandaloneHoroscope/AstrologyStandaloneHoroscope';
import { AstrologyStandaloneSectionContainer, ResetButton } from './components';
import {
  type CreateAscendantResponse,
  type UserProps,
  type UserPropsExtra,
  type ZodiacSignProps,
  type MenuSelectOptionProps,
} from 'components/Astrology';
import { NumerologyData } from './AstrologyStandaloneNumerology';
import { AstrologyStandaloneMenuSelect } from './AstrologyStandaloneHeaders/MenuSelect/AstrologyStandaloneMenuSelect';
import { AstrologyStandaloneNumerology } from './AstrologyStandaloneNumerology';
import { ZodiacSignsUnselected } from 'components/Astrology/constants';
import { Settings } from '@mui/icons-material';

interface AstrologyStandaloneProps {
  zodiacSign: string;
  compatibilitySign: string;
  birthDate?: string;
  birthMonth?: string;
  birthYear?: string;
  ascendantResult?: CreateAscendantResponse;
  ascendantSign?: string;
  resetAstrologyUser: () => void;
  setCompatibilitySign: (sign: string) => void;
  yesterdayContent: object;
  todayContent: string;
  tomorrowContent: object;
  monthlyContent: string[];
  astrologyUser?: UserProps | null;
  activeHeaderMenuOption: MenuSelectOptionProps;
  zodiacSignData?: ZodiacSignProps | undefined;
  menuOptions: { [key: string]: MenuSelectOptionProps };
  setActiveHeaderMenuOption: (option: MenuSelectOptionProps) => void;
  personalityNumberData?: NumerologyData;
  lifepathNumberData?: NumerologyData;
  setAstrologyUserCallback: (astrologyUser: UserPropsExtra | null) => void;
  resetAscendantData: () => void;
}

export const AstrologyStandalone = ({
  zodiacSign,
  compatibilitySign,
  birthDate,
  birthMonth,
  birthYear,
  ascendantResult,
  ascendantSign,
  resetAstrologyUser,
  setCompatibilitySign,
  yesterdayContent,
  todayContent,
  tomorrowContent,
  monthlyContent,
  astrologyUser,
  zodiacSignData,
  menuOptions,
  activeHeaderMenuOption,
  setActiveHeaderMenuOption,
  personalityNumberData,
  lifepathNumberData,
  setAstrologyUserCallback,
  resetAscendantData,
}: AstrologyStandaloneProps) => {
  const resetCompatibilitySign = () => {
    setCompatibilitySign(ZodiacSignsUnselected.value);
  };
  return (
    <Stack gap={2}>
      <AstrologyStandaloneHoroscope
        astrologyUser={astrologyUser}
        zodiacSign={zodiacSign}
        zodiacSignData={zodiacSignData}
        yesterdayContent={yesterdayContent}
        todayContent={todayContent}
        tomorrowContent={tomorrowContent}
        monthlyContent={monthlyContent}
        resetAstrologyUser={resetAstrologyUser}
      />
      <Stack
        gap={2}
        direction="row"
        flexWrap="wrap"
        minHeight="394px"
        maxHeight="max-content"
      >
        <AstrologyStandaloneSectionContainer
          header="Compatibility"
          headerAction={
            <ResetButton
              startIcon={<Settings />}
              onClick={resetCompatibilitySign}
            >
              Reset
            </ResetButton>
          }
          customStyles={{ flex: '1 1 377px' }}
        >
          <AstrologyStandaloneCompatibility
            zodiacSign={zodiacSign}
            compatibilitySign={compatibilitySign}
            setCompatibilitySign={setCompatibilitySign}
          />
        </AstrologyStandaloneSectionContainer>
        <AstrologyStandaloneSectionContainer
          header="Numerology"
          customStyles={{ flex: '1 1 377px' }}
          headerAction={
            <AstrologyStandaloneMenuSelect
              menuOptions={menuOptions}
              activeHeaderMenuOption={activeHeaderMenuOption}
              setActiveHeaderMenuOption={setActiveHeaderMenuOption}
            />
          }
        >
          <AstrologyStandaloneNumerology
            menuOptions={menuOptions}
            activeHeaderMenuOption={activeHeaderMenuOption}
            personalityNumberData={personalityNumberData}
            lifepathNumberData={lifepathNumberData}
          />
        </AstrologyStandaloneSectionContainer>
      </Stack>
      <AstrologyStandaloneSectionContainer
        header="Rising Sign (Ascendant)"
        headerAction={
          !ascendantResult || Object.keys(ascendantResult).length === 0 ? (
            <></>
          ) : (
            <ResetButton
              startIcon={<Settings />}
              onClick={resetAscendantData}
            >
              Reset
            </ResetButton>
          )
        }
      >
        <AstrologyStandaloneAscendant
          date={birthDate}
          month={birthMonth}
          year={birthYear}
          ascendantResult={ascendantResult}
          ascendantSign={ascendantSign}
          setAstrologyUserCallback={setAstrologyUserCallback}
        />
      </AstrologyStandaloneSectionContainer>
    </Stack>
  );
};
