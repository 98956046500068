import {
  type SxProps,
  styled,
  Stack,
  Text,
  Box,
  Button,
  TextArea,
} from '@ltvco/refresh-lib/theme';
import { FlareRounded } from '@mui/icons-material';

export const AstrologyStandaloneHeader = () => {
  return (
    <Stack direction="row" alignItems="center" gap={1.5}>
      <FlareRounded />
      <Text variant="h2" fontSize="1.875rem" m={0}>
        Astrology
      </Text>
    </Stack>
  );
};

export const AstrologyStandaloneSectionContainer = ({
  header,
  children,
  headerAction,
  customStyles,
}: {
  header: string;
  children: JSX.Element;
  headerAction?: JSX.Element;
  customStyles?: SxProps;
}) => {
  return (
    <Box
      border="1px solid #E2E2E2"
      borderRadius="8px"
      overflow="hidden"
      bgcolor="#e2dfff66"
      sx={customStyles}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        p={2}
        height="100%"
        maxHeight="58px"
        borderBottom="1px solid #E2E2E2"
        bgcolor={(theme) => theme.palette.background.paper}
      >
        <Text variant="h4" fontSize="1.25rem" m={0}>
          {header}
        </Text>
        {headerAction}
      </Stack>
      {children}
    </Box>
  );
};

export const AstrologyStandaloneSectionTextField = ({
  content,
  rows = 5,
  fullWidth,
}: {
  content?: string;
  rows?: number;
  fullWidth?: boolean;
}) => (
  <TextArea
    value={content}
    inputProps={{
      readOnly: true,
    }}
    rows={rows}
    fullWidth={fullWidth}
  />
);

export const ResetButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '0.75rem',
  maxWidth: '85px',
  padding: 0,
  '.MuiButton-startIcon': {
    marginRight: theme.spacing(1),
    marginLeft: 0,
    fontSize: '1rem',
  },
}));
