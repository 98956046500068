import {
  Box,
  Button,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Text,
  useTheme,
  CreditScoreMeter,
  Theme,
} from '@ltvco/refresh-lib/theme';
import { useEffect, useState, useContext } from 'react';
import { useOxford } from '../Oxford/Oxford';
import { AppConfig } from '@ltvco/refresh-lib/ctx'; // Adjust the import path as necessary
import { useQueryClient } from '@ltvco/refresh-lib/vendors';
import { CreditNavigatorConstants } from './constants';
import { Circle, Notifications } from '@mui/icons-material';

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2.5),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  border: '1px solid #E2E2E2',
}));

const StyledInput = styled(Input)(({ theme }: { theme: Theme }) => ({
  '& .MuiInputBase-root': {
    height: '70px',
    backgroundColor: theme.palette.background.default,
  },
  width: '138px',
  position: 'absolute',
  top: '73px',
  right: '84px',
  caretColor: 'transparent',
  WebkitUserSelect: 'none' /* Safari */,
  MsUserSelect: 'none' /* IE 10 and IE 11 */,
  userSelect: 'none' /* Standard syntax */,
}));

export const CreditNavigator = () => {
  const theme = useTheme();

  const [desiredScore, setDesiredScore] = useState<string>('');

  const { trackEvent } = useContext(AppConfig);

  const { postCreditNavigator, getCreditNavigator, getCreditData } =
    useOxford();

  const vantageScore = getCreditData?.data?.vantageScore || 0;

  const getCreditNavigatorData = getCreditNavigator?.data;

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.resetQueries({ queryKey: ['oxford', 'creditNavigator'] });
  }, [queryClient]);

  const isLoading =
    getCreditNavigator?.isFetching || postCreditNavigator?.isLoading;

  const belowOrEqualToOriginal = vantageScore >= parseInt(desiredScore);
  const aboveMax = parseInt(desiredScore) > 850;

  const navigateDisabled =
    isLoading || belowOrEqualToOriginal || aboveMax || desiredScore === '';

  const chartScore = () => {
    if (!!desiredScore && !belowOrEqualToOriginal && !aboveMax) {
      return parseInt(desiredScore);
    }

    return undefined;
  };

  const handleSubmit = () => {
    trackEvent('credit navigator', 'engagement click', 'id monitor');
    postCreditNavigator?.mutateAsync({
      score_change_value: desiredScore,
      original_score: vantageScore,
    });
  };

  const bulletedContent = (title: string, content: string[]) => {
    return (
      <Stack
        p={2}
        mb={2.5}
        borderRadius={1}
        sx={{
          backgroundColor: theme.palette.background.default,
          border: '1px solid #E2E2E2',
        }}
      >
        <Text variant="h4">{title}</Text>
        <List>
          {content.map((text: string, index: number) => (
            <ListItem key={`${title}-${index}`}>
              <ListItemIcon>
                {
                  <Circle
                    sx={{ height: '.5rem', width: '.5rem', fill: 'black' }}
                  />
                }
              </ListItemIcon>
              <ListItemText>
                <Text variant="body1">{text}</Text>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Stack>
    );
  };

  const content = () => {
    if (!!getCreditNavigatorData) {
      return (
        <>
          {bulletedContent('Factors', getCreditNavigatorData?.factors)}
          {bulletedContent('Can Do', getCreditNavigatorData?.canDo)}

          <Text variant="caption">
            {CreditNavigatorConstants.result_caption}
          </Text>
          <Text variant="caption" display={'block'} mt={1.5}>
            Data source: TransUnion®
          </Text>
        </>
      );
    } else {
      return (
        <StyledStack direction="row">
          <Notifications
            fontSize="large"
            sx={{ marginRight: 2, color: theme.palette.primary.main }}
          />
          <Text variant={'body1'}>
            {CreditNavigatorConstants.recommendation_body}
          </Text>
        </StyledStack>
      );
    }
  };

  return (
    <Box padding={2.5}>
      <Text variant={'h4'}>Credit Navigator</Text>

      <Text variant={'body1'}>{CreditNavigatorConstants.description}</Text>

      <Stack
        direction="column"
        justifyItems={'center'}
        alignItems={'center'}
        marginTop={2.5}
        marginBottom={4}
      >
        <Box sx={{ position: 'relative' }}>
          <CreditScoreMeter score={chartScore()} />
          <StyledInput
            InputProps={{
              sx: { fontSize: '64px' },
            }}
            value={desiredScore}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === 'Backspace') {
                setDesiredScore(desiredScore.slice(0, -1));
              }

              if (event.key.match(/^[0-9]/) === null) {
                return;
              }

              if (desiredScore.length === 3) {
                setDesiredScore(desiredScore.slice(0, -1) + event.key);
              } else {
                setDesiredScore(desiredScore + event.key);
              }
            }}
            error={belowOrEqualToOriginal || aboveMax}
          />
        </Box>

        <Text
          variant={'body1'}
          mt={2.5}
          mb={1.5}
          whiteSpace="pre-wrap"
          textAlign="center"
        >
          Please enter a desired
          <br />
          credit score above
        </Text>
        <Button
          variant="contained"
          disabled={navigateDisabled}
          onClick={handleSubmit}
          sx={{
            width: ['100%', '172px'],
          }}
        >
          Navigate
        </Button>
      </Stack>

      {content()}
    </Box>
  );
};
