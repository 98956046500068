// An IETF language tag (more specifically, an IETF BCP 47 language tag), is a standardized code that is used to identify human languages. There are a number of rules, but the basic use for our purposes is a two-letter lower case base language identifier ("es" for Spanish) and a two-letter capitalized country code ("MX" for Mexico), separated by a hyphen. For example, `fr-FR` would denote the standardized French dialect spoken in France while `fr-CA` would denote French Canadian, or Québecois French. Again, for our purposes, we use a very limited set of IETF language codes, as follows:

export const languageNameToIetfLanguageCode: {
  [key: string]: string;
} = {
  English: 'en-US',
  French: 'fr-FR',
  Spanish: 'es-ES',
  German: 'de-DE',
  Italian: 'it-IT',
  Portuguese: 'pt-BR',
};
