import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { AssignmentIcon } from 'components/Language/AssignmentIcon/AssignmentIcon';

interface LessonSideCardProps {
  assignment: {
    id: number;
    title: string;
    status?: string;
    lessonChatId?: number;
    quizChatId?: number;
    assignmentType: string;
  };
  activeAssignment: boolean;
  redirectToAssignment: (assignmentId: number) => void;
}

export const LessonSideCard = ({
  assignment,
  activeAssignment,
  redirectToAssignment,
}: LessonSideCardProps) => {
  const isLesson = assignment.assignmentType === 'lesson';
  const isQuiz = assignment.assignmentType === 'quiz';
  const isStory = assignment.assignmentType === 'story';

  const handleRedirectToLesson = () => {
    redirectToAssignment(assignment.id);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      py={1}
      px={1.25}
      sx={{
        cursor: 'pointer',
        backgroundColor: activeAssignment ? '#00000005' : 'transparent',
      }}
      onClick={handleRedirectToLesson}
    >
      <AssignmentIcon status={assignment.status || ''} />
      <Text variant="body1" sx={{ ml: 2 }}>
        {assignment.title}
      </Text>
    </Stack>
  );
};
