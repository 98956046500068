import { useContext, useEffect, useState } from 'react';
import { Box, Grid } from '@ltvco/refresh-lib/theme';
import { languageName, translations } from 'components/Language/constants';
import { useBumpeeLessonsChats } from 'components/Language/hooks/useBumpeeLessonsChats';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { useNavigate } from 'react-router-dom';
import { AssignmentCard } from '../AssignmentCard/AssignmentCard';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

interface Lesson {
  id: number;
  title: string;
  status: string;
  lessonChatId: number | undefined;
}

export const LessonsContainer = () => {
  const [lessons, setLessons] = useState<Lesson[]>([]);

  const redirect = useNavigate();
  const { trackEventGA4 } = useContext(AppConfig);
  const { language, lessonLearningLanguage } = useLanguageContext();
  const { data: userLessonsChats, createChatLessonMutation } =
    useBumpeeLessonsChats();

  useEffect(() => {
    const lessons =
      translations.dashboard.assignments.lesson.lessonList[language];

    const modifiedLessons = lessons?.map((lesson) => {
      const lessonChat = userLessonsChats?.lesson_chats.find(
        (userLesson: { lesson_id: number; learning_language_id: number; }) =>
          userLesson.lesson_id === lesson.id &&
          userLesson.learning_language_id ===
            parseInt(lessonLearningLanguage.value)
      );

      return {
        ...lesson,
        status: lessonChat?.completed
          ? 'completed'
          : lessonChat
          ? 'incomplete'
          : 'new',
        lessonChatId: lessonChat?.id,
      };
    });

    setLessons(modifiedLessons);
  }, [language, userLessonsChats, lessonLearningLanguage.value]);

  const redirectToLesson = (lessonId: number) => {
    const lesson = lessons.find((lesson) => lesson.id === lessonId);
    if (!lesson) return;

    if (lesson.lessonChatId) {
      return redirect(`/dashboard/language/lesson/${lesson.lessonChatId}`);
    }

    const lessonChatParams = {
      lesson_id: lesson.id,
      learning_language_id: parseInt(lessonLearningLanguage.value),
    };
    createChatLessonMutation.mutate(lessonChatParams);

    trackEventGA4({
      event_name: 'Language Tutor Engagement',
      type: 'lesson',
      language: languageName(parseInt(lessonLearningLanguage.value)),
      action: 'start',
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={2} columnSpacing={{ sm: 3 }}>
        {lessons?.map((lesson) => (
          <AssignmentCard
            key={lesson.id}
            assignment={lesson}
            handleRedirectToAssignment={redirectToLesson}
          />
        ))}
      </Grid>
    </Box>
  );
};
