import { Box, Stack } from '@ltvco/refresh-lib/theme';
import { UserProps } from '../interfaces';
import { AstrologyHeaderLight } from '../AstrologyHeaders/AstrologyHeaderLight';
import { AstrologyOnboardingForm } from './AstrologyOnboardingForm/AstrologyOnboardingForm';

interface AstrologyOnboardingProps {
  onSubmitCallback: (astrologyUser: UserProps | null) => void;
}

export const AstrologyOnboarding = ({
  onSubmitCallback,
}: AstrologyOnboardingProps) => {
  return (
    <Stack
      alignItems="center"
      p={4.5}
      direction={{ xs: 'column', md: 'row' }}
      width="100%"
    >
      <Box minWidth="50%" px={{ sx: 0, md: 8 }}>
        <AstrologyHeaderLight />
      </Box>
      <AstrologyOnboardingForm onSubmitCallback={onSubmitCallback} />
    </Stack>
  );
};
