import { maxPoll, oxfordNamespace } from './constants';

export class OxfordError extends Error {
  response: Response;
  passthrough: boolean = true;
  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}

export class OxfordHelpers {
  public static async poll({
    method,
    url,
    body,
    queryParams,
    token,
    jsonRequired = false,
  }: {
    method: string;
    url: string;
    body?: object;
    queryParams?: Record<string, string>;
    token: string;
    jsonRequired: boolean;
  }) {
    const oxfordRequest = () =>
      OxfordHelpers.request({
        method,
        url,
        body,
        queryParams,
        token,
        jsonRequired: false,
      });

    let result = await oxfordRequest();
    if (result instanceof OxfordError) {
      return Promise.reject(result);
    }

    let pollAttempt = 0;

    while (result?.status === 202 && pollAttempt < maxPoll) {
      await OxfordHelpers.wait();
      result = await oxfordRequest();

      if (result instanceof OxfordError) {
        return Promise.reject(result);
      }

      pollAttempt += 1;
    }

    if (result?.status === 202) {
      return Promise.reject(new Error('Max Polling reached'));
    }

    if (jsonRequired) {
      const data = await result.json();
      return data;
    }

    return result;
  }

  public static wait(ms = 3000) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  public static async request({
    method,
    url,
    body,
    queryParams,
    token,
    jsonRequired = false,
  }: {
    method: string;
    url: string;
    body?: object;
    queryParams?: Record<string, string>;
    token: string;
    jsonRequired?: boolean;
  }) {
    let constructedUrl = `${oxfordNamespace}/${url}`;

    if (queryParams) {
      constructedUrl += `?${new URLSearchParams(queryParams).toString()}`;
    }

    return await fetch(constructedUrl, {
      method,
      body: body && JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      if ([4, 5].includes(Math.floor(response.status / 100))) {
        return OxfordHelpers._requestErrorHandler(response);
      }

      if (jsonRequired) {
        return response.json();
      }

      return response;
    });
  }

  static _requestErrorHandler(response: Response): Promise<Error> {
    const error = new OxfordError(
      `Request Error ${response.statusText}`,
      response
    );

    return Promise.reject(error);
  }
}
