import { Box, Stack } from '@ltvco/refresh-lib/theme';
import { CurrentChat } from './CurrentChat/CurrentChat';
import { QuickChatSideBar } from './QuickChatSideBar/QuickChatSideBar';
import { ChatHeader } from './ChatHeader/ChatHeader';
import { useScreenSize } from '@ltvco/refresh-lib/utils';

export const ChatContainer = () => {
  const { isMobile } = useScreenSize();

  return (
    <Box
      display="flex"
      width="100%"
      height={isMobile ? '90vh' : '80vh'}
      ml={0}
      pt={{ xs: 0, md: 1 }}
      px={{ xs: 0, md: 1.5 }}
    >
      {!isMobile && <QuickChatSideBar />}
      <Stack width="100%">
        <ChatHeader />
        <CurrentChat />
      </Stack>
    </Box>
  );
};
