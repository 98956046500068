import { Button, Stack, Text } from '@ltvco/refresh-lib/theme';
import { StyledStack } from './Components';
import { containerIcon } from './utils';
import { useAccount, useReportRedirect } from '@ltvco/refresh-lib/v1';
import { AddCircle } from '@mui/icons-material';

export const PersonCTACard = () => {
  const { data } = useAccount(true);
  const currentUserInfo = data?.account?.user_info;
  const { redirectToSearchPersonUrlv2 } = useReportRedirect();

  const onClickPersonCTA = () => {
    const personInfo = {
      fName: currentUserInfo?.first_name || '',
      lName: currentUserInfo?.last_name || '',
      mName: '',
      age: '',
      city: '',
      state: '',
    };

    redirectToSearchPersonUrlv2(personInfo);
  };

  return (
    <StyledStack
      direction={['column', 'row']}
      justifyItems={'center'}
      alignItems={'center'}
      justifyContent="space-between"
      gap={[2.5, 0]}
    >
      <Stack
        direction={['column', 'row']}
        justifyItems={'center'}
        alignItems={'center'}
        gap={2.5}
      >
        <img
          src={containerIcon['detailed_person_report']}
          width="52px"
          height="56px"
          alt="name-monitoring-icon"
        />
        <Text variant="h4" mb={0}>
          Search for yourself
        </Text>
      </Stack>
      <Button
        variant="contained"
        color="success"
        startIcon={<AddCircle />}
        onClick={onClickPersonCTA}
      >
        Add Report
      </Button>
    </StyledStack>
  );
};
