import { Modal, CircularProgress, Box, Text } from '@ltvco/refresh-lib/theme';
import { translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';

export const LoadingLessonModal = () => {
  const { loadingLessonModalOpen, language } = useLanguageContext();
  const title = translations.transitionAssignmentModal.title[language];

  return (
    <Modal
      open={loadingLessonModalOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ width: '320px' }}
      >
        <CircularProgress sx={{ mb: 4, mt: 5 }} />
        <Text variant="h3" mb={5}>
          {title}
        </Text>
      </Box>
    </Modal>
  );
};
