import { Box, Button, Stack, styled } from '@ltvco/refresh-lib/theme';

export const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.25),
  marginBottom: theme.spacing(1),
  justifyContent: 'flex-start',
  color: 'rgb(0, 0, 0)',
  flex: '0 0 49%',

  '.MuiButton-startIcon': {
    color: theme.palette.primary.main,
  },

  '.MuiButton-endIcon': {
    marginLeft: 'auto',
    '>*:nth-of-type(1)': {
      fontSize: '1.5rem',
    },
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
  },
}));

export const IconBox = styled(Box)`
  width: 32px;
  height: 32px;
  background-color: rgba(240, 238, 249, 1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(1),
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}));
