export interface AnyObject {
  [key: string]: any;
}

export const oxfordNamespace = '/oxford';
export const notificationSettingsNamespace = `${oxfordNamespace}/notification_settings`;
export const identityManagementNamespace = `${oxfordNamespace}/identity_management`;
export const userProfilePath = 'user_profile';
export const authenticationQuestionPath = 'authentication/questions';
export const authenticationPostAnswersPath = 'authentication/answers';
export const authenticationCheckAnswersPath = 'authentication/check_answers';
export const oneTimePasscodeMCQPartialText =
  'Please select your preferred method of Authentication?';
export const OneTimePasscodeMCQPartialAnswerText = 'Security Questions';
export const oneTimePasscodeUserInputPartialText =
  'Enter the passcode you received';
export const creditDataPath = 'credit_data';
export const scoreSimulatorPath = 'score_simulator';
export const creditNavigatorPath = 'credit_navigator';
export const sessionEscalationURL = '/service_token/session_escalation_token';
export const maxPoll = 10;
export const notificationSummaryNamespace = 'notifications';
export const notificationDisplayNamespace = `${oxfordNamespace}/${notificationSummaryNamespace}/mark_all_as_displayed`;

export const headers = (token: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
});
