import { Box, styled } from '@ltvco/refresh-lib/theme';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)({
  '&:hover h3': {
    textDecoration: 'underline',
  },
});

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  marginBottom: theme.spacing(2),

  '&:hover': {
    borderRadius: '4px',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
  },
}));
