import React, { useEffect, useState } from 'react';
import { Stack, Text } from '@ltvco/refresh-lib/theme';
import {
  CreditScoreHistoryProps,
  triangleUpStyle,
  triangleDownStyle,
} from './constants';

type ScoreDiffContentProps = {
  creditScoreHistory: CreditScoreHistoryProps[];
};

export const ScoreDiffContent: React.FC<ScoreDiffContentProps> = ({
  creditScoreHistory,
}) => {
  const [scoreDifference, setScoreDifference] = useState<number>(0);
  const [isUp, setIsUp] = useState<boolean>(false);

  useEffect(() => {
    if (creditScoreHistory.length > 1) {
      const lastScore = creditScoreHistory[0].score;
      const previousScore = creditScoreHistory[1].score;
      setScoreDifference(lastScore - previousScore);
      setIsUp(lastScore < previousScore);
    }
  }, [creditScoreHistory]);

  if (scoreDifference === 0) return null;

  return (
    <Stack direction={'row'} alignItems={'center'} mt={1}>
      <div style={isUp ? triangleUpStyle : triangleDownStyle} />
      <Text variant="body1">
        <strong>{scoreDifference} points</strong>
      </Text>
    </Stack>
  );
};
