import { IconButton,  Stack, Text } from '@ltvco/refresh-lib/theme';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { translations } from 'components/Language/constants';
import { AssignmentDrawerItem } from './AssignmentDrawerItem/AssignmentDrawerItem';
import { Close } from '@mui/icons-material';

interface AssignmentDrawerProps {
  assignments: {
    id: number;
    assignmentType: string;
    title: string;
    status?: string;
  }[];
  activeAssignmentId: number;
  toggleDrawer: () => void;
  redirectToLesson?: (lessonId: number) => void;
  isQuizChat?: boolean;
  isLessonChat?: boolean;
  isStoryChat?: boolean;
}
export const AssignmentDrawer = ({
  assignments,
  activeAssignmentId,
  toggleDrawer,
  redirectToLesson,
  isQuizChat,
  isLessonChat,
  isStoryChat,
}: AssignmentDrawerProps) => {
  const { language } = useLanguageContext();

  const handleLessonRedirect = (lessonId: number) => {
    if (redirectToLesson) {
      redirectToLesson(lessonId);
      toggleDrawer();
    }
  };

  return (
    <Stack px={2} py={3}>
      <Stack
        p={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {isQuizChat && (
          <Text variant="h5" mb={0}>
            {translations.dashboard.assignments.quiz.header[language]}
          </Text>
        )}
        {isLessonChat && (
          <Text variant="h5" mb={0}>
            {translations.dashboard.assignments.lesson.header[language]}
          </Text>
        )}
        {isStoryChat && (
          <Text variant="h5" mb={0}>
            {translations.dashboard.assignments.story.header[language]}
          </Text>
        )}
        <IconButton disableRipple onClick={toggleDrawer}>
          <Close />
        </IconButton>
      </Stack>
      {assignments.map((assignment) => (
        <AssignmentDrawerItem
          key={assignment.id}
          assignment={assignment}
          activeLesson={activeAssignmentId == assignment.id}
          redirectToLesson={handleLessonRedirect}
        />
      ))}
    </Stack>
  );
};
