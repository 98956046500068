import { useEffect, useState, useContext } from 'react';
import { Navigation } from '../Navigation';
import { useOxford } from '../Oxford/Oxford';
import { Header } from './Header';
import {
  Box,
  Button,
  Divider,
  Stack,
  Text,
  styled,
} from '@ltvco/refresh-lib/theme';
import { Loading } from '../Loading';
import {
  Question,
  SelectedAnswer,
  UseAuthentication,
} from '../Oxford/useAuthentication';
import { ErrorModal } from '../ErrorModal/ErrorModal';
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion';
import { OneTimePasscodeNavigation } from './OneTimePasscodeNavigation';
import { OneTimePasscode } from './OneTimePasscode';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2.5),
}));

export const KnowledgeBasedAuthentication: React.FC<{
  sentOneTimePasscodeCount: number;
  increaseSentOneTimePasscodeCount: () => void;
}> = ({ sentOneTimePasscodeCount, increaseSentOneTimePasscodeCount }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [selectedAnswersList, setSelectedAnswersList] = useState<
    SelectedAnswer[]
  >([]);
  const [questionList, setQuestionList] = useState<Question[]>([]);
  const [userPasscode, setUserPasscode] = useState<(string | number)[]>([
    '',
    '',
    '',
    '',
    '',
  ]);
  const { trackEvent, trackEventGA4 } = useContext(AppConfig);

  const {
    getAuthenticationQuestions,
    postAuthenticationAnswers,
    getAuthenticationCheckAnswers,
  } = useOxford();

  const { data: authenticationQuestionsData } = getAuthenticationQuestions;
  const { data: checkAnswersData } = getAuthenticationCheckAnswers;
  const currentQuestion = questionList![currentQuestionIndex];

  const isOneTimePasscode =
    UseAuthentication.isOneTimePasscodeQuestion(questionList);
  const isOneTimePasscodeMCQ = UseAuthentication.isOneTimePasscodeMCQ(
    questionList?.[0]?.questionText || ''
  );
  const isOneTimePasscodeUserInput =
    UseAuthentication.isOneTimePasscodeUserInput(
      questionList?.[0]?.questionText || ''
    );

  useEffect(() => {
    if (!questionList.length) return;

    const category = isOneTimePasscode ? 'otp' : 'kba';
    trackEvent(category, 'render', 'id monitor enrollment');
    trackEventGA4({
      event_name: 'id_monitor_enrollment',
      step: `${category}_render`,
    });
  }, [questionList, isOneTimePasscode]);

  useEffect(() => {
    let tempQuestionList =
      checkAnswersData?.questionList ||
      authenticationQuestionsData?.questionList ||
      [];

    if (UseAuthentication.isOneTimePasscodeQuestion(tempQuestionList)) {
      tempQuestionList[0].answerChoices =
        tempQuestionList[0].answerChoices.filter(
          (answer) => !answer.answerText.includes('Security')
        );
    }

    setQuestionList(tempQuestionList);
  }, [authenticationQuestionsData, checkAnswersData]);

  useEffect(() => {
    setSelectedAnswersList(
      questionList.map((question: Question) => {
        return {
          question_id: question.questionId,
          answer_id: '',
        };
      })
    );
  }, [questionList]);

  useEffect(() => {
    if (userPasscode.join('').length === 0) return;

    const answer: SelectedAnswer = {
      question_id: currentQuestion.questionId,
      answer_id: currentQuestion.answerChoices[0].answerId,
      user_input: userPasscode.join(''),
    };

    setSelectedAnswersList([answer]);
  }, [
    userPasscode,
    currentQuestion?.answerChoices,
    currentQuestion?.questionId,
  ]);

  const selectHeaderType = () => {
    if (sentOneTimePasscodeCount > 0) return 'repeatOneTimePasscodeUserInput';
    if (isOneTimePasscodeMCQ) return 'oneTimePasscodeMCQ';
    if (isOneTimePasscodeUserInput) return 'oneTimePasscodeUserInput';

    return 'genericMCQ';
  };

  const handleAnswerSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const answer: SelectedAnswer = {
      question_id: currentQuestion.questionId,
      answer_id: event.target.value,
    };

    const modifiedSelectedAnswersList = [...selectedAnswersList];
    modifiedSelectedAnswersList[currentQuestionIndex] = answer;

    setSelectedAnswersList(modifiedSelectedAnswersList);
  };

  const handleOneTimePasscodeInput = (
    index: number,
    value: string | number
  ) => {
    const modifiedUserPasscode = [...userPasscode];
    modifiedUserPasscode[index] = value;

    setUserPasscode(modifiedUserPasscode);
  };

  const handleResetOneTimePasscode = () => {
    const answer: SelectedAnswer = {
      question_id: currentQuestion.questionId,
      answer_id: currentQuestion.answerChoices[0].answerId,
      user_input: 'NEWPIN',
    };

    setSelectedAnswersList([answer]);
  };

  const handleNext = () => {
    trackEvent('kba', 'navigation click', 'id monitor enrollment');
    trackEventGA4({
      event_name: 'id_monitor_enrollment',
      step: 'kba_navigation_click',
    });
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleBack = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleSubmit = () => {
    if (
      isOneTimePasscodeUserInput &&
      selectedAnswersList[currentQuestionIndex].user_input !== 'NEWPIN'
    ) {
      increaseSentOneTimePasscodeCount();
    }
    // isOneTimePasscodeMCQ will be true only when the user is selecting between
    // text code or phone call, thats' when we want to track this otp navigation click
    if (isOneTimePasscodeMCQ) {
      trackEvent('otp', 'navigation click', 'id monitor enrollment');
      trackEventGA4({
        event_name: 'id_monitor_enrollment',
        step: 'otp_navigation_click',
      });
    } else {
      // If we arrive here, means we are submitting kba or otp
      const category = isOneTimePasscode ? 'otp' : 'kba';
      trackEvent(category, 'submit', 'id monitor enrollment');
      trackEventGA4({
        event_name: 'id_monitor_enrollment',
        step: `${category}_submit`,
      });
    }

    postAuthenticationAnswers.mutate(selectedAnswersList);
  };

  useEffect(() => {
    if (isOneTimePasscodeUserInput) {
      if (selectedAnswersList[currentQuestionIndex]?.user_input === 'NEWPIN') {
        handleSubmit();
      }
    }
  }, [selectedAnswersList, currentQuestionIndex, isOneTimePasscodeUserInput]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentQuestionIndex]);

  const displayNavigation = () => {
    const isForwardDisabled = ['', undefined].includes(
      selectedAnswersList[currentQuestionIndex]?.answer_id
    );

    const isOneTimePasscodeSubmitDisabled =
      (selectedAnswersList[0]?.user_input?.length || 0) < 5;

    if (isOneTimePasscode) {
      if (isOneTimePasscodeMCQ) {
        return (
          <OneTimePasscodeNavigation
            isOneTimePasscodeMCQ={isOneTimePasscodeMCQ}
            handleSubmit={handleSubmit}
            isNextDisabled={isForwardDisabled}
          />
        );
      } else {
        return (
          <OneTimePasscodeNavigation
            isOneTimePasscodeMCQ={isOneTimePasscodeMCQ}
            handleSubmit={handleSubmit}
            isNextDisabled={isOneTimePasscodeSubmitDisabled}
          />
        );
      }
    } else {
      return (
        <Navigation
          isFirstOption={currentQuestionIndex === 0}
          isLastOption={currentQuestionIndex === questionList!.length - 1}
          isForwardDisabled={isForwardDisabled}
          onNext={handleNext}
          onBack={handleBack}
          onSubmit={handleSubmit}
        />
      );
    }
  };

  const displayQuestion = () => {
    const selectedAnswer = selectedAnswersList[currentQuestionIndex].answer_id;

    if (isOneTimePasscodeUserInput) {
      return (
        <>
          <Stack
            flexDirection={'row'}
            justifyContent={'center'}
            gap={1}
            marginBottom={2.5}
          >
            <OneTimePasscode
              userPasscode={userPasscode}
              handleOneTimePasscodeInput={handleOneTimePasscodeInput}
            />
          </Stack>
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Text variant={'body1'}>Didn't receive the verification code?</Text>
            <Button id="reset-passcode" onClick={handleResetOneTimePasscode}>
              Request another passcode or PIN
            </Button>
            <Text variant={'body2'}>
              You may request 2 or more new passcodes
            </Text>
          </Stack>
        </>
      );
    } else if (isOneTimePasscodeMCQ) {
      return (
        <MultipleChoiceQuestion
          question={currentQuestion}
          selectedAnswer={selectedAnswer}
          isOneTimePasscodeMCQ={isOneTimePasscodeMCQ}
          handleAnswerSelect={handleAnswerSelect}
        />
      );
    } else {
      return (
        <>
          <Text variant="h2">{currentQuestion.questionText}</Text>
          <MultipleChoiceQuestion
            question={currentQuestion}
            selectedAnswer={selectedAnswer}
            isOneTimePasscodeMCQ={isOneTimePasscodeMCQ}
            handleAnswerSelect={handleAnswerSelect}
          />
        </>
      );
    }
  };

  if (postAuthenticationAnswers.isLoading) {
    return <Loading />;
  }

  if (postAuthenticationAnswers.isError) {
    return <ErrorModal type="generic" />;
  }

  return (
    <StyledBox>
      <Header type={selectHeaderType()} />
      <Divider sx={{ width: '100%', marginY: [2.5] }} />

      <Stack sx={{ alignItems: isOneTimePasscodeMCQ ? 'center' : 'initial' }}>
        {selectedAnswersList.length > 0 && displayQuestion()}
      </Stack>

      <Divider
        sx={{ width: '100%', marginBottom: [2, 2.5], marginTop: [2.5] }}
      />

      {displayNavigation()}
    </StyledBox>
  );
};
