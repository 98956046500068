import { Box, Button, Stack, styled } from '@ltvco/refresh-lib/theme';

export const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: theme.palette.secondary.light,
  minHeight: '360px',
}));

export const ResetButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '0.75rem',
  maxWidth: '85px',
  padding: 0,
  '.MuiButton-startIcon': {
    marginRight: theme.spacing(0.5),
    fontSize: '1rem',
  },
}));

export const HeaderContainer = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  color: theme.palette.primary.contrastText,
  flexDirection: 'column',

  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },

  '&::before': {
    backgroundColor: theme.palette.primary.main,
    clipPath: 'ellipse(74% 56% at 34% 20%)',
  },
}));
