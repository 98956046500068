import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  Select,
  Stack,
  Text,
  Tooltip,
  CreditScoreMeter,
  useMediaQuery,
  useTheme,
  styled,
} from '@ltvco/refresh-lib/theme';
import InfoIcon from '@mui/icons-material/Info';
import {
  ScoreSimulatorConstants,
  ScoreSimulatorDefaultState,
} from './constants';
import type { ScoreSimulatorType } from '../Oxford/useScoreSimulator/useScoreSimulator';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { scoreSimulatorSchema } from 'utils/schemaForms';
import { useOxford } from '../Oxford/Oxford';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useEffect, useRef, useState, useContext } from 'react';
import { commaFormatCurrency } from '@ltvco/refresh-lib/utils';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQueryClient } from '@ltvco/refresh-lib/vendors';

const StyledText = styled(Text)(({ theme }) => ({
  paddingRight: '8px',
  [theme.breakpoints.up('sm')]: {
    paddingRight: '9px',
  },
}));

export const ScoreSimulator = () => {
  const scoreSimulatorTitleRef = useRef<HTMLHeadElement>(null);

  const [payOffAllCreditCards, setPayOffAllCreditCards] = useState(false);
  const [closeOldestCreditCard, setCloseOldestCreditCard] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { trackEvent } = useContext(AppConfig);

  const { getScoreSimulator, postScoreSimulator, getCreditData } = useOxford();

  const currentVantageScore = getCreditData?.data?.vantageScore;

  const { isLoading: isPostScoreSimulatorLoading } = postScoreSimulator;

  const { data: scoreSimulatorData, isFetching: isScoreSimulatorFetching } =
    getScoreSimulator;

  const simulatedScore = parseInt(scoreSimulatorData?.score || '') || undefined;

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.resetQueries({ queryKey: ['oxford', 'scoreSimulator'] });
  }, [queryClient]);

  useEffect(() => {
    getScoreSimulator.remove();
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
    trigger,
  } = useForm<ScoreSimulatorType>({
    mode: 'onSubmit',
    resolver: yupResolver(scoreSimulatorSchema),
    defaultValues: ScoreSimulatorDefaultState,
  });

  const atleastOneInputFilled = () => {
    for (const property in getValues()) {
      if (
        ![undefined, '', '$0', false].includes(
          getValues(property as keyof ScoreSimulatorType)
        )
      )
        return true;
    }

    for (const checkmark in [payOffAllCreditCards, closeOldestCreditCard]) {
      if (checkmark) return true;
    }

    return false;
  };

  const rangeSelect = (maxRange: number) => {
    let range = [];
    for (let i = 1; i <= maxRange; i++) {
      range.push({ value: `${i}`, display: `${i}` });
    }

    return range;
  };

  const days = [
    { value: '30', display: '30' },
    { value: '60', display: '60' },
    { value: '90', display: '90' },
  ];

  const moneyPlaceholder = 'Min=$1,Max=$999,999,999';

  const handleCurrency = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: keyof ScoreSimulatorType
  ) => {
    const strippedValue = event.target.value.replace(/[^0-9]/g, '');

    if (strippedValue.length === 0 || !Boolean(strippedValue)) {
      setValue(id, undefined);
      return;
    }

    setValue(id, commaFormatCurrency(parseInt(strippedValue), true));
    trigger(id, { shouldFocus: true });
  };

  const handleInput = (
    value: string | boolean,
    id: keyof ScoreSimulatorType
  ) => {
    setValue(id, value);
    trigger(id);

    return value;
  };

  const scrollToTop = () => {
    if (scoreSimulatorTitleRef && scoreSimulatorTitleRef.current) {
      setTimeout(
        () =>
          scoreSimulatorTitleRef.current!.scrollIntoView({
            behavior: 'smooth',
          }),
        0
      );
    }
  };

  const onSubmit: SubmitHandler<ScoreSimulatorType> = async (data) => {
    scrollToTop();
    trackEvent('score simulator', 'engagement click', 'id monitor');
    postScoreSimulator.mutate(data);
  };

  const onReset = () => {
    scrollToTop();

    reset(ScoreSimulatorDefaultState);
    setPayOffAllCreditCards(false);
    setCloseOldestCreditCard(false);

    if (scoreSimulatorData) {
      getScoreSimulator.remove();
    }
  };

  const isInputDisabled =
    isScoreSimulatorFetching || !!simulatedScore || isPostScoreSimulatorLoading;

  const footer = () => {
    const errorsPresent = Object.keys(errors)?.length > 0;

    const isSubmitDisabled =
      errorsPresent || !atleastOneInputFilled() || isInputDisabled;
    const isResetDisabled =
      !atleastOneInputFilled() ||
      isScoreSimulatorFetching ||
      isPostScoreSimulatorLoading;

    if (isMobile) {
      return (
        <>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              disabled={isSubmitDisabled}
              onClick={handleSubmit(onSubmit)}
            >
              Simulate
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="text"
              onClick={() => onReset()}
              fullWidth
              disabled={isResetDisabled}
              sx={{ textDecorationLine: 'underline' }}
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Text variant="caption" sx={{ fontSize: '12px' }}>
              Data source: TransUnion®
            </Text>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Text variant="caption" sx={{ fontSize: '12px' }}>
                Data source: TransUnion®
              </Text>

              <Stack direction="row" gap={2}>
                <Button
                  variant="text"
                  onClick={() => onReset()}
                  disabled={isResetDisabled}
                  sx={{ textDecorationLine: 'underline' }}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  disabled={isSubmitDisabled}
                  onClick={handleSubmit(onSubmit)}
                >
                  Simulate
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </>
      );
    }
  };

  const creditScoreMeters = () => {
    if (isMobile) {
      if (scoreSimulatorData) {
        return (
          <Grid item xs={12}>
            <CreditScoreMeter score={simulatedScore} />
          </Grid>
        );
      } else {
        return (
          <Grid item xs={12}>
            <CreditScoreMeter score={currentVantageScore} />
          </Grid>
        );
      }
    } else {
      return (
        <>
          <Grid item xs={5}>
            <CreditScoreMeter score={currentVantageScore} />
          </Grid>
          <Grid item xs={2}>
            <ArrowRightAltIcon sx={{ fontSize: '7rem' }} />
          </Grid>
          <Grid item xs={5}>
            <CreditScoreMeter score={simulatedScore} />
          </Grid>
        </>
      );
    }
  };

  return (
    <Box padding={2.5}>
      <Text variant={'h4'} ref={scoreSimulatorTitleRef}>
        Simulator
      </Text>
      <Text variant="body1" marginBottom={2.5}>
        {ScoreSimulatorConstants.description}
      </Text>

      <Grid container justifyItems="center" alignItems="center">
        {creditScoreMeters()}
      </Grid>

      <Grid container rowGap={2.5} marginTop={[2, 4]}>
        <Grid item gap={1.5} alignItems={'center'} xs={12} display={'flex'}>
          <Text variant={'h4'} margin={0}>
            Factors and Actions
          </Text>
          <Tooltip
            title={ScoreSimulatorConstants.factors_and_actions_hint}
            placement={isMobile ? 'bottom' : 'right-start'}
            enterTouchDelay={0}
          >
            <InfoIcon />
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <Text variant="h4">Change Credit Card Balance Limits</Text>
        </Grid>

        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Increase Credit Balance</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Input
            {...register('IncreaseCreditBalance', {
              onChange: (event) => {
                handleCurrency(event, 'IncreaseCreditBalance');
              },
            })}
            id="IncreaseCreditBalance"
            placeholder={moneyPlaceholder}
            disabled={isInputDisabled}
            error={Boolean(errors.IncreaseCreditBalance?.message)}
            helperText={errors.IncreaseCreditBalance?.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={0} sm={0.31} />
        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Increase Credit Limit</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Input
            {...register('IncreaseCreditCardLimit', {
              onChange: (event) => {
                handleCurrency(event, 'IncreaseCreditCardLimit');
              },
            })}
            id="IncreaseCreditCardLimit"
            placeholder={moneyPlaceholder}
            disabled={isInputDisabled}
            error={Boolean(errors.IncreaseCreditCardLimit?.message)}
            helperText={errors.IncreaseCreditCardLimit?.message}
            fullWidth
          />
        </Grid>

        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Decrease Credit Balance</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Input
            {...register('DecreaseCreditBalance', {
              onChange: (event) => {
                handleCurrency(event, 'DecreaseCreditBalance');
              },
            })}
            id="DecreaseCreditBalance"
            placeholder={moneyPlaceholder}
            disabled={isInputDisabled}
            error={Boolean(errors.DecreaseCreditBalance?.message)}
            helperText={errors.DecreaseCreditBalance?.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={0} sm={0.31} />
        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Pay Off All Credit Cards</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045} mt={[-1.25, -1.875]}>
          <FormControlLabel
            sx={{ display: 'block' }}
            control={
              <Checkbox
                disabled={isInputDisabled}
                checked={payOffAllCreditCards}
                {...register('PayOffAllCreditCards', {
                  onChange: () => {
                    handleInput(!payOffAllCreditCards, 'PayOffAllCreditCards');
                    setPayOffAllCreditCards(!payOffAllCreditCards);
                  },
                })}
              />
            }
            label={<></>}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Text variant="h4">Change Payment History</Text>
        </Grid>

        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">On-Time Payments</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Select
            {...register('OnTimePayment', {
              onChange: (event) =>
                handleInput(event.target.value, 'OnTimePayment'),
            })}
            id="OnTimePayment"
            value={getValues('OnTimePayment')}
            disabled={isInputDisabled}
            placeholder="Number of Months"
            options={rangeSelect(24)}
          />
        </Grid>
        <Grid item xs={0} sm={0.31} />
        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">All Account(s) Past Due</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Select
            {...register('AllAccountsPastDue', {
              onChange: (event) =>
                handleInput(event.target.value, 'AllAccountsPastDue'),
            })}
            id="AllAccountsPastDue"
            value={getValues('AllAccountsPastDue')}
            disabled={isInputDisabled}
            placeholder="Number of Days"
            options={days}
          />
        </Grid>

        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">One Account Past Due</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Select
            {...register('OneAccountPastDue', {
              onChange: (event) =>
                handleInput(event.target.value, 'OneAccountPastDue'),
            })}
            id="OneAccountPastDue"
            value={getValues('OneAccountPastDue')}
            disabled={isInputDisabled}
            placeholder="Number of Days"
            options={days}
          />
        </Grid>
        <Grid item xs={0} sm={0.31} />

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Text variant="h4">Open or Close Card</Text>
        </Grid>

        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Close Oldest Credit Card</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045} mt={[-1.25, -1.875]}>
          <FormControlLabel
            sx={{ display: 'block' }}
            control={
              <Checkbox
                disabled={isInputDisabled}
                checked={closeOldestCreditCard}
                {...register('CloseOldestCreditCard', {
                  onChange: (event) => {
                    handleInput(
                      !closeOldestCreditCard,
                      'CloseOldestCreditCard'
                    );
                    setCloseOldestCreditCard(!closeOldestCreditCard);
                  },
                })}
              />
            }
            label={<></>}
          />
        </Grid>
        <Grid item xs={0} sm={0.31} />
        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Obtain Credit Card</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Input
            {...register('ObtainCreditCard', {
              onChange: (event) => {
                handleCurrency(event, 'ObtainCreditCard');
              },
            })}
            id="ObtainCreditCard"
            placeholder={moneyPlaceholder}
            disabled={isInputDisabled}
            error={Boolean(errors.ObtainCreditCard?.message)}
            helperText={errors.ObtainCreditCard?.message}
            fullWidth
          />
        </Grid>

        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Transfer Balance</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Input
            {...register('TransferCreditBalances', {
              onChange: (event) => {
                handleCurrency(event, 'TransferCreditBalances');
              },
            })}
            id="TransferCreditBalances"
            placeholder={moneyPlaceholder}
            disabled={isInputDisabled}
            error={Boolean(errors.TransferCreditBalances?.message)}
            helperText={errors.TransferCreditBalances?.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={0} sm={0.31} />
        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Obtain Card as Authorized User</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Input
            {...register('ObtainCreditCardAsAuthorizedUser', {
              onChange: (event) => {
                handleCurrency(event, 'ObtainCreditCardAsAuthorizedUser');
              },
            })}
            id="ObtainCreditCardAsAuthorizedUser"
            placeholder={moneyPlaceholder}
            disabled={isInputDisabled}
            error={Boolean(errors.ObtainCreditCardAsAuthorizedUser?.message)}
            helperText={errors.ObtainCreditCardAsAuthorizedUser?.message}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Text variant="h4">Open or Close Account</Text>
        </Grid>

        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Apply for Credit Card</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Select
            {...register('ApplyForCreditCard', {
              onChange: (event) =>
                handleInput(event.target.value, 'ApplyForCreditCard'),
            })}
            id="ApplyForCreditCard"
            value={getValues('ApplyForCreditCard')}
            disabled={isInputDisabled}
            placeholder="Number of Inquiries"
            options={rangeSelect(10)}
          />
        </Grid>
        <Grid item xs={0} sm={0.31} />
        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Obtain Auto Loan</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Input
            {...register('ObtainAutoLoan', {
              onChange: (event) => {
                handleCurrency(event, 'ObtainAutoLoan');
              },
            })}
            id="ObtainAutoLoan"
            placeholder={moneyPlaceholder}
            disabled={isInputDisabled}
            error={Boolean(errors.ObtainAutoLoan?.message)}
            helperText={errors.ObtainAutoLoan?.message}
            fullWidth
          />
        </Grid>

        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Obtain Personal Loan</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Input
            {...register('ObtainPersonalLoan', {
              onChange: (event) => {
                handleCurrency(event, 'ObtainPersonalLoan');
              },
            })}
            id="ObtainPersonalLoan"
            placeholder={moneyPlaceholder}
            disabled={isInputDisabled}
            error={Boolean(errors.ObtainPersonalLoan?.message)}
            helperText={errors.ObtainPersonalLoan?.message}
            fullWidth
          />
        </Grid>
        <Grid item xs={0} sm={0.31} />
        <Grid item xs={4.5} sm={1.8}>
          <StyledText variant="h6">Obtain Mortage</StyledText>
        </Grid>
        <Grid item xs={7.5} sm={4.045}>
          <Input
            {...register('ObtainMortgage', {
              onChange: (event) => {
                handleCurrency(event, 'ObtainMortgage');
              },
            })}
            id="ObtainMortgage"
            placeholder={moneyPlaceholder}
            disabled={isInputDisabled}
            error={Boolean(errors.ObtainMortgage?.message)}
            helperText={errors.ObtainMortgage?.message}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {footer()}
      </Grid>
    </Box>
  );
};
