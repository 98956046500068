import React from 'react';
import { Text, Stack, Card, Pill} from '@ltvco/refresh-lib/theme';
import {
  ParsedAlertDetail,
  Notification,
} from '../Oxford/useNotifications/constants';
import { Notifications } from '@mui/icons-material';

interface NotificationCardProps {
  notification: Notification;
  indexVal: Number;
}

export const NotificationCard = ({
  notification,
  indexVal,
}: NotificationCardProps) => {
  return (
    <Card
      data-testid="notification-card"
      sx={{
        backgroundColor: '#f8f8f8',
        padding: '32px 16px 16px',
        position: 'relative',
        marginTop: 4.5,
        overflow: 'visible',
      }}
    >
      <Stack alignItems="flex-start" gap={1.25}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Text variant="h4" textAlign="left" marginBottom={0}>
            {notification.alertType}
          </Text>
        </Stack>
        <Text variant="body1">{notification.alertDescription}</Text>
        <Card
          sx={{
            width: '100%',
            marginBottom: 0,
          }}
        >
          {notification.alertDetails.map(
            (detail: ParsedAlertDetail, index: number) => (
              <React.Fragment
                key={`${notification.alertType}-${detail.title}-${index}`}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    backgroundColor: index % 2 === 1 ? '' : '#f8f8f8',
                    padding: 1.5,
                    '& > *': {
                      width: '50%',
                    },
                  }}
                >
                  <Text variant="body1" paddingRight={0.5}>
                    <strong>{detail.title}</strong>
                  </Text>
                  {detail.title.includes('Address') &&
                  typeof detail.value === 'string' ? (
                    <Text
                      variant="body1"
                      textAlign={'left'}
                      dangerouslySetInnerHTML={{ __html: detail.value }}
                      paddingLeft={0.5}
                    />
                  ) : (
                    <Text variant="body1" textAlign={'left'} paddingLeft={0.5}>
                      {detail.vantageScoreCodeToFactors
                        ? detail.vantageScoreCodeToFactors.map(
                            (factor, index) => (
                              <React.Fragment key={`${factor.factor}-${index}`}>
                                {factor.factor}
                                <br />
                                <br />
                                {factor.explain}
                              </React.Fragment>
                            )
                          )
                        : detail.value
                        ? detail.value
                        : '-'}
                    </Text>
                  )}
                </Stack>
              </React.Fragment>
            )
          )}
        </Card>
      </Stack>
      {!notification.displayed && (
        <Pill
          data-testid="new-info-pill"
          icon={<Notifications color="inherit" fontSize="small" />}
          label="NEW INFO"
          sx={{
            position: 'absolute',
            right: '20px',
            top: '-16px',
            backgroundColor: 'black',
            color: 'white',
          }}
        />
      )}
    </Card>
  );
};
