import { Box, TabsNavigation, Text, styled } from '@ltvco/refresh-lib/theme';
import { type TabData } from '@ltvco/refresh-lib/theme';

const customTabStyles = {
  sx: {
    textDecoration: 'none',
    padding: 0,
    margin: '0 10px',
    minHeight: '20px',
    fontWeight: 'bold',

    '&[data-active-tab="true"]': {
      borderBottom: 'none',
      padding: 0,
      margin: '0 10px',
    },
  },
};

const StyledTabsBox = styled(Box)`
  .MuiTabs-root {
    min-height: 35px;
  }
`;

const StyledContentBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: `0 ${theme.spacing(3.5)} ${theme.spacing(2.5)} ${theme.spacing(
    2.5
  )}`,
  maxHeight: '295px',
  lineHeight: '22px',
  overflowX: 'hidden',
  overflowY: 'scroll',
}));

interface AstrolgyHoroscopeProps {
  yesterdayContent: object | undefined;
  todayContent: string | undefined;
  tomorrowContent: object | undefined;
  monthlyContent: string[] | undefined;
  standaloneVersion?: boolean;
}

export const AstrologyHoroscope = ({
  yesterdayContent,
  todayContent,
  tomorrowContent,
  monthlyContent,
  standaloneVersion,
}: AstrolgyHoroscopeProps) => {
  const concatenateContentString = (content: object) => {
    if (!content) return '';

    return Object.values(content)
      .filter((value) => typeof value === 'string')
      .join(' ');
  };

  const concatenatedYesterdayContent = concatenateContentString(
    yesterdayContent || {}
  );

  const concatenatedTomorrowContent = concatenateContentString(
    tomorrowContent || {}
  );

  const tabs = [
    {
      label: 'Yesterday',
      content: (
        <StyledContentBox>{concatenatedYesterdayContent}</StyledContentBox>
      ),
    },
    {
      label: 'Today',
      content: <StyledContentBox>{todayContent}</StyledContentBox>,
    },
    {
      label: 'Tomorrow',
      content: (
        <StyledContentBox>{concatenatedTomorrowContent}</StyledContentBox>
      ),
    },
    {
      label: 'Monthly',
      content: (
        <StyledContentBox>
          {monthlyContent?.map((content: string, index: number) => (
            <Text key={index} sx={{ lineHeight: '22px' }}>
              {content}
            </Text>
          ))}
        </StyledContentBox>
      ),
    },
  ].filter(Boolean);

  return (
    <TabsNavigation
      tabs={tabs as TabData[]}
      tabProps={customTabStyles}
      container={StyledTabsBox}
      sx={{
        marginInline: '20px',
        minHeight: '35px',
        borderBottom: standaloneVersion ? `1px solid #E2E2E2` : 'none',
      }}
    />
  );
};
