import { useContext } from 'react';
import { AppConstants, AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  BaseNumerologyQueryKeys,
  CreateNumerologyParams,
  CreateNumerologyResponse,
  CredentialsParams,
} from './interfaces';
import { astrology } from './api';
import { useQuery } from '@ltvco/refresh-lib/vendors';

export const useNumerology = (
  queryType: keyof typeof BaseNumerologyQueryKeys,
  identifier: string,
  params: CreateNumerologyParams | undefined,
  isEnabled: boolean
) => {
  const baseQueryKey = BaseNumerologyQueryKeys[queryType];
  const {
    keys: { astrologyUser, astrologyKey },
  } = useContext(AppConstants);

  const { logError } = useContext(AppConfig);
  const credentials = {
    user: astrologyUser,
    key: astrologyKey,
  } as CredentialsParams;

  const localDate = new Date(
    `${params?.month}-${params?.date}-${params?.year}`
  );

  const getNumerologyLocalStorage = function (identifier: string) {
    const localData = localStorage.getItem(baseQueryKey);
    if (!localData || !identifier) return false;

    const parsedData = JSON.parse(localData);

    return parsedData?.[identifier];
  };

  const storeNumerologyLocalStorage = function (
    numerology: CreateNumerologyResponse
  ) {
    const localData = localStorage.getItem(baseQueryKey);
    const parsedData = localData ? JSON.parse(localData) : {};

    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    numerology.requested_date = `${month}-${day}-${year}`;

    // Not store unnecesary data to improve security
    numerology?.name && delete numerology.name;
    numerology?.birth_date && delete numerology.birth_date;

    parsedData[identifier] = numerology;
    localStorage.setItem(baseQueryKey, JSON.stringify(parsedData));
  };

  function isDateDifferenceHighEnough(date1: Date, date2: Date) {
    const month1 = date1.getMonth();
    const year1 = date1.getFullYear();
    const month2 = date2.getMonth();
    const day2 = date2.getDate();
    const year2 = date2.getFullYear();

    // Calculate difference in years, handling negative differences
    let yearDiff = year2 - year1;
    if (yearDiff < 0) {
      yearDiff = 0; // Handle cases where date2 is in a previous year
    }

    // Calculate month difference considering year difference
    let monthDiff = month2 - month1 + yearDiff * 12;

    // Adjust for days if dates are in the same month but different years
    if (monthDiff === 0 && yearDiff > 0) {
      // Check if date2 is in the same year after date1's month has ended
      if (day2 > new Date(year1, month1 + 1, 0).getDate()) {
        monthDiff += 1;
      }
    }

    // Check if the difference in months is 3 or higher
    return monthDiff >= 3;
  }

  function isExpiredData(numerology: CreateNumerologyResponse) {
    if (!numerology.requested_date) return true;

    const numerologyDate = new Date(numerology.requested_date);
    const today = new Date();

    return isDateDifferenceHighEnough(numerologyDate, today);
  }

  const fetchNumerology = async (): Promise<CreateNumerologyResponse> => {
    if (!localDate || !params?.full_name || !identifier)
      return Promise.resolve({} as CreateNumerologyResponse);

    const localData = getNumerologyLocalStorage(identifier);
    if (localData && !isExpiredData(localData)) {
      return Promise.resolve(localData);
    }

    const newNumerology = await astrology.fetchAstrology({
      credentials,
      queryType,
      params,
    });

    storeNumerologyLocalStorage(newNumerology as CreateNumerologyResponse);
    return Promise.resolve(newNumerology as CreateNumerologyResponse);
  };

  const isLocalEnabled = isEnabled && !!identifier && !!params;

  const queryResult = useQuery({
    queryKey: [baseQueryKey],
    queryFn: fetchNumerology,
    enabled: isLocalEnabled,
    cacheTime: 0,
    onError: (error: Error) => {
      logError(`${baseQueryKey} generation failed`, error as Error);
    },
  });
  // useQuery would keep the initial isLoading state which is true if the query is enabled = false since the beggining
  // We return isLoading false if the query is not enabled but if enabled we return the real value
  const isLoading = isLocalEnabled ? queryResult.isLoading : false;

  return {
    ...queryResult,
    isLoading,
  };
};
