import { useContext } from 'react';
import {
  fetchSettings,
  updateSettings,
  updateIdentityManagement,
} from './utils';
import { OxfordHelpers } from './Helpers';
import { useServiceToken } from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQueryClient, useQuery, useMutation } from '@ltvco/refresh-lib/vendors';

const useNotificationSettings = () => {
  const { data: tokenData, isLoading: isTokenLoading } = useServiceToken(
    'oxford',
    '/service_token/session_escalation_token'
  );
  const { logError } = useContext(AppConfig);
  const queryClient = useQueryClient();

  const {
    data: notificationSettingsData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['oxford', 'notification_settings'],
    queryFn: () => fetchSettings(tokenData?.token),

    enabled: !!tokenData?.token,
    onError: (error) => {
      logError('Error fetching notification settings', error as Error);
    },
  });

  const {
    data: idManagementData,
    isLoading: idManagementIsLoading,
    isError: idManagementIsError,
  } = useQuery({
    queryKey: ['oxford', 'identity_management'],
    queryFn: async () => {
      return await OxfordHelpers.poll({
        method: 'GET',
        url: 'identity_management',
        token: tokenData?.token,
        jsonRequired: true,
      }).catch((error: Error) => error);
    },
    enabled: !!tokenData?.token,
    retry: 3,
  });

  const updateIdentityManagementMutation = useMutation({
    mutationFn: updateIdentityManagement,

    onSuccess: () => {
      queryClient.resetQueries(['oxford', 'identity_management']);
    },
  });

  const updateIdentityManagementData = async () => {
    try {
      await updateIdentityManagementMutation.mutateAsync({
        token: tokenData?.token,
      });
    } catch (error) {
      logError('Error updating identity management settings', error as Error);
    }
  };

  const updateSettingsMutation = useMutation({
    mutationFn: updateSettings,
    onSuccess: () => {
      queryClient.invalidateQueries(['oxford', 'notification_settings']);
    },
  });

  const updateSettingsData = async (newSettings: object) => {
    try {
      await updateSettingsMutation.mutateAsync({
        token: tokenData?.token,
        newSettings,
      });
    } catch (error) {
      logError('Error updating notification settings', error as Error);
    }
  };

  const isLoadingData = isLoading || isTokenLoading || idManagementIsLoading;

  return {
    data:
      notificationSettingsData && idManagementData && !isLoadingData
        ? {
            notificationSettings: notificationSettingsData,
            idManagement: idManagementData,
          }
        : undefined,
    isLoading: isLoadingData,
    isError: isError || idManagementIsError,
    updateSettings: updateSettingsData,
    updateIdentityManagement: updateIdentityManagementData,
  };
};

export default useNotificationSettings;
