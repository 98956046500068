import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@ltvco/refresh-lib/theme';
import { Question } from '../Oxford/useAuthentication';

export const MultipleChoiceQuestion: React.FC<{
  question: Question;
  selectedAnswer?: string;
  isOneTimePasscodeMCQ: boolean;
  handleAnswerSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
  question,
  selectedAnswer,
  isOneTimePasscodeMCQ,
  handleAnswerSelect,
}) => {
  const displayAnswers = () => {
    return question.answerChoices.map((answer) => {
      return (
        <FormControlLabel
          key={answer.answerId}
          value={answer.answerId}
          control={<Radio />}
          label={answer.answerText}
        />
      );
    });
  };

  return (
    <FormControl sx={{ width: isOneTimePasscodeMCQ ? '45%' : '100%' }}>
      <RadioGroup value={selectedAnswer || null} onChange={handleAnswerSelect}>
        {displayAnswers()}
      </RadioGroup>
    </FormControl>
  );
};
