import {
  Box,
  IconButton,
  Card,

  Text,
  Menu,
  MenuItem,
} from '@ltvco/refresh-lib/theme';
import { useState } from 'react';

import { useBumpeeChat } from '../hooks/useBumpeeChat';
import { TopicIcon } from '../TopicIcon/TopicIcon';
import { languageID, levelID, topicID, translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';
import { StyledBox, StyledLink } from './components';
import { MoreHorizRounded, Delete } from '@mui/icons-material';

interface ChatCardProps {
  level: string;
  learningLanguage: string;
  topic: string;
  id: number;
}

export const ChatCard = ({
  level,
  learningLanguage,
  topic,
  id,
}: ChatCardProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { deleteChatMutation } = useBumpeeChat();
  const { language } = useLanguageContext();
  const open = Boolean(anchorEl);
  const menuId = open ? 'simple-popover' : undefined;
  const levelId = levelID(level.toLowerCase());
  const languageId = languageID(learningLanguage.toLowerCase());
  const topicId = topicID(topic.toLowerCase());

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteChat = () => {
    deleteChatMutation.mutate(id);
    handleClose();
  };

  return (
    <StyledBox>
      <StyledLink to={`/dashboard/language/chat/${id}`}>
        <Card variant="outlined" sx={{ position: 'sticky', mb: 0 }}>
          <Box
            display="flex"
            sx={{ overflow: 'hidden', borderRadius: '4px' }}
            height={'132px'}
          >
            <TopicIcon topic={topicId} />
            <Box pl={2.5} width="100%" m="auto">
              <Text variant="h3">
                {
                  translations.topics[language].find(
                    (topic) => topic.id === topicId
                  )?.name
                }
              </Text>
              <Text variant="body1">
                {
                  translations.levels[language].find(
                    (level) => level.id === levelId
                  )?.name
                }
                {' | '}
                {
                  translations.languages[language].find(
                    (language) => language.id === languageId
                  )?.name
                }
              </Text>
            </Box>
          </Box>
        </Card>
      </StyledLink>
      <Box right={0} top={0} position="absolute" p={2}>
        <IconButton onClick={handleClick}>
          <MoreHorizRounded />
        </IconButton>
        <Menu id={menuId} open={open} anchorEl={anchorEl} onClose={handleClose}>
          <MenuItem onClick={deleteChat}>
            <Delete /> {translations.buttons.remove[language]}
          </MenuItem>
        </Menu>
      </Box>
    </StyledBox>
  );
};
