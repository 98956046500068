type CreditBureauList = CreditBureauOption[];

export type CreditBureauOption = {
  name: string;
  url: string;
  phone: string;
};

export const creditBureauList: CreditBureauList = [
  {
    name: 'TransUnion',
    url: 'https://transunion.com/credit-freeze ',
    phone: '(800) 909-8872',
  },
  {
    name: 'Experian',
    url: 'https://www.experian.com/freeze/center.html',
    phone: '(888) 397-3742',
  },
  {
    name: 'Equifax',
    url: 'https://www.equifax.com/personal/credit-report-services/credit-freeze/',
    phone: '(800) 349-9960',
  },
];

export const options = creditBureauList.map((answer: CreditBureauOption) => ({
  value: answer.name,
  display: answer.name,
}));
