import { Button} from '@ltvco/refresh-lib/theme';
import { FeedbackModal } from '../FeedbackModal/FeedbackModal';
import { useState } from 'react';
import { translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';
import { ThumbUpAltOutlined } from '@mui/icons-material';

export const Feedback = () => {
  const { language } = useLanguageContext();
  const [showFeedBackModal, setShowFeedBackModal] = useState(false);

  const handleOpenModal = () => {
    setShowFeedBackModal(true);
  };

  const handleClose = () => {
    setShowFeedBackModal(false);
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        startIcon={<ThumbUpAltOutlined />}
        sx={{
          mr: 2,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        disableTouchRipple
        onClick={handleOpenModal}
      >
        {translations.dashboard.giveFeedback.title[language]}
      </Button>
      <FeedbackModal open={showFeedBackModal} handleClose={handleClose} />
    </>
  );
};
