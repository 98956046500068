import { useQuery } from '@ltvco/refresh-lib/vendors';
import { BumpeeRequests } from 'api';
import { useBumpeeUsers } from './useBumpeeUsers';
import { enqueueSnackbar, useServiceToken } from '@ltvco/refresh-lib/v1';

export const useUserAchievements = () => {
  const { data: user } = useBumpeeUsers();
  const { data: tokenData } = useServiceToken('bumpee');

  const results = useQuery({
    queryKey: ['user-achievements'],
    queryFn: () => BumpeeRequests.getUserAchievements(tokenData.token),
    enabled: Boolean(tokenData) && Boolean(user?.user_native_language),
    onError: () => {
      enqueueSnackbar('Failed to get achievements', { variant: 'error' });
    },
  });

  return { ...results };
};
