import { useContext } from 'react';
import { StyledSelect } from './components';
import { MenuSelectOptionProps } from '../../interfaces';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { SelectChangeEvent } from '@ltvco/refresh-lib/theme';
import { ExpandMore } from '@mui/icons-material';

export const MenuSelect = ({
  enabledMenuOptions,
  onChangeCallback,
  value,
  variant,
}: {
  enabledMenuOptions: { [key: string]: MenuSelectOptionProps };
  onChangeCallback: (option: MenuSelectOptionProps) => void;
  value: string;
  variant?: string;
}) => {
  const { trackEvent } = useContext(AppConfig);

  const localOnChangeCallback = (event: SelectChangeEvent) => {
    trackEvent(
      'dropdown menu',
      `engagement click ${event.target.value}`,
      'astrology'
    );
    onChangeCallback(
      enabledMenuOptions[event.target.value as keyof typeof enabledMenuOptions]
    );
  };
  return (
    <StyledSelect
      className={variant}
      id="astrology-menu-select"
      value={value}
      IconComponent={ExpandMore}
      options={Object.keys(enabledMenuOptions).map((optionKey: string) => {
        const option =
          enabledMenuOptions[optionKey as keyof typeof enabledMenuOptions];
        return { value: option.value, display: option.display };
      })}
      onChange={localOnChangeCallback}
    />
  );
};
