type AlertDetail = string;

type AlertDetailsMap = {
  [key: string]: AlertDetail;
};

export type Notification = {
  alertType: string;
  alertDescription: string;
  alertDetails: ParsedAlertDetail[];
  displayed: boolean;
};

export type ParsedAlertDetail = {
  title: string;
  value?: string;
  vantageScoreCodeToFactors?: ParsedVantageScoreCodeToFactors[];
};

type ParsedVantageScoreCodeToFactors = {
  factor: string;
  explain: string;
};

export const ALERT_DETAILS: AlertDetailsMap = {
  NewInquiry: 'A new inquiry was placed on the consumer’s credit file',
  NewTrade: 'A new account was opened in the consumer’s name',
  DelinquentAccount:
    'The consumer did not pay a creditor on time and the account is now 30, 60, or 90 (or more) days late, or delinquent',
  ImprovedTrade: 'An account went from a derogatory status to current',
  TradeBankrupt:
    'The consumer filed for bankruptcy and listed an account as one that the member could not pay',
  NewPublic: 'A new public record is added to the consumer’s credit file',
  NewAddress:
    'An account in the consumer’s name shows a different address than what’s on the credit file',
  NewEmployment: 'A new employer appears on the consumer’s credit file',
  FraudStatement:
    'Alerts the consumer that a fraud alert has been placed on the credit report',
  AuthorizedUserTrades:
    'A consumer was added as an authorized user on a credit card',
  BankCardGoesOverCreditLimit:
    'The balance on a Bank card exceeds the credit limit',
  ClosedTradeLines: 'An account is closed',
  LostStolenCard: 'Bank and/or Retail card was indicated as lost or stolen',
  NewAutoLease: 'A new auto lease is opened',
  'NewCharge-off':
    'An account changes from delinquent status to a charge off status',
  NewForeclosure:
    'An increase in the number of foreclosures or new foreclosure is reported',
  NumberofCollectionsExcludingMedical:
    'A new, non-medical collection is reported',
  IncreaseinCollectionBalances:
    'An increase in the balance of all collections, including medical',
  DecreaseinCollectionBalances:
    'A decrease in the balance of all collections, including medical',
  PaidCollections: 'A collection is fully paid',
  PaidTradelines: 'An account is fully paid',
  RetailCardGoesOverCreditLimit:
    'The balance on a Retail card exceeds the credit limit',
  VantageScoreDecrease: 'VantageScore 3.0 decrease by 10 points or more',
  VantageScoreIncrease: 'VantageScore 3.0 increase by 10 points or more',
};

export type CreditNodeNames = {
  IncreaseinCollectionBalances: string;
  DecreaseinCollectionBalances: string;
  NumberofCollectionsExcludingMedical: string;
  'NewCharge-off': string;
  VantageScoreDecrease: string;
  VantageScoreIncrease: string;
  NumberofCollections: string;
  CountofPaidCollection: string;
  DatePaidforMostRecentPaidCollection: string;
  BalanceofMostRecentBankCard: string;
  CountofBankCardOverLimit: string;
  CreditLimitofMostRecentBankCard: string;
  DateVerifiedofMostRecentBankCard: string;
  BalanceofMostRecentRetailCard: string;
  CountofRetailCardOverLimit: string;
  CreditLimitofMostRecentRetailCard: string;
  DateVerifiedofMostRecentRetailCard: string;
  ClosedDateofMostRecentlyClosedTrade: string;
  CountofClosedTradeLine: string;
  CreditLimitofMostRecentlyClosedTradeLine: string;
  DateReportedofMostRecentlyClosedTrade: string;
  CountofPaidTradeLine: string;
  CreditLimitofMostRecentlyPaidTradeLine: string;
  DatePaidofMostRecentlyPaidTradeLine: string;
  DateReportedofMostRecentlyPaidTradeLine: string;
  AccountNumberofMostRecentCard: string;
  ClosedDateofMostRecentCard: string;
  NumberofForeclosures: string;
  NewChargeoffDate: string;
  NumberofNewChargeoff: string;
  RecentChargeoffBalance: string;
  SubscriberAddres: string;
  NegativeScoreFactor1: string;
  NegativeScoreFactor2: string;
  NegativeScoreFactor3: string;
  NegativeScoreFactor4: string;
  PositiveScoreFactor1: string;
  PositiveScoreFactor2: string;
  PositiveScoreFactor3: string;
  PositiveScoreFactor4: string;
};

export const creditNodeNames: CreditNodeNames = {
  IncreaseinCollectionBalances: 'Increase in Collection Balances',
  DecreaseinCollectionBalances: 'Decrease in Collection Balances',
  NumberofCollectionsExcludingMedical:
    'Number of Collections Excluding Medical',
  'NewCharge-off': 'New Charge-off',
  VantageScoreDecrease: 'VantageScore® Decrease',
  VantageScoreIncrease: 'VantageScore® Increase',
  NumberofCollections: 'Number of Collections',
  CountofPaidCollection: 'Count of PaidCollection',
  DatePaidforMostRecentPaidCollection:
    'Date Paid for Most Recent Paid Collection',
  BalanceofMostRecentBankCard: 'Balance of Most Recent Bank Card',
  CountofBankCardOverLimit: 'Count of Bank Card Over Limit',
  CreditLimitofMostRecentBankCard: 'Credit Limit of Most Recent Bank Card',
  DateVerifiedofMostRecentBankCard: 'Date Verified of Most Recent Bank Card',
  BalanceofMostRecentRetailCard: 'Balance of Most Recent Retail Card',
  CountofRetailCardOverLimit: 'Count of Retail Card Over Limit',
  CreditLimitofMostRecentRetailCard: 'Credit Limit of Most Recent Retail Card',
  DateVerifiedofMostRecentRetailCard:
    'Date Verified of Most Recent Retail Card',
  ClosedDateofMostRecentlyClosedTrade:
    'Closed Date of Most Recently Closed Trade',
  CountofClosedTradeLine: 'Count of Closed Trade Line',
  CreditLimitofMostRecentlyClosedTradeLine:
    'Credit Limit of Most Recently Closed Trade Line',
  DateReportedofMostRecentlyClosedTrade:
    'Date Reported of Most Recently Closed Trade',
  CountofPaidTradeLine: 'Count of Paid Trade Line',
  CreditLimitofMostRecentlyPaidTradeLine:
    'Credit Limit of Most Recently Paid Trade Line',
  DatePaidofMostRecentlyPaidTradeLine:
    'Date Paid of MostRecently Paid Trade Line',
  DateReportedofMostRecentlyPaidTradeLine:
    'Date Reported of Most Recently Paid Trade Line',
  AccountNumberofMostRecentCard: 'Account Number of Most Recent Card',
  ClosedDateofMostRecentCard: 'Closed Date of Most Recent Card',
  NumberofForeclosures: 'Number of Foreclosures',
  NewChargeoffDate: 'New Charge-off Date',
  NumberofNewChargeoff: 'Number of New Charge-off',
  RecentChargeoffBalance: 'Recent Charge-off Balance',
  SubscriberAddres: 'Subscriber Address',
  NegativeScoreFactor1: 'Negative Score Factor 1',
  NegativeScoreFactor2: 'Negative Score Factor 2',
  NegativeScoreFactor3: 'Negative Score Factor 3',
  NegativeScoreFactor4: 'Negative Score Factor 4',
  PositiveScoreFactor1: 'Positive Score Factor 1',
  PositiveScoreFactor2: 'Positive Score Factor 2',
  PositiveScoreFactor3: 'Positive Score Factor 3',
  PositiveScoreFactor4: 'Positive Score Factor 4',
};

export type NodeName = keyof CreditNodeNames;

export type FactorInfo = {
  factor: string;
  explain: string;
};

export type VantageScoreCodeToFactors = {
  [key: string]: FactorInfo;
};

export const vantageScoreCodeToFactors: VantageScoreCodeToFactors = {
  4: {
    factor:
      'The balances on your accounts are too high compared to loan amounts',
    explain:
      'The balances on your accounts are high compared to the original loan amounts, lowering your score.',
  },
  5: {
    factor: 'Too many of the delinquencies on your accounts are recent',
    explain:
      'A delinquency is a payment that was made 30 or more days late.  Too many late payments occurred recently on some of your accounts.  Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  6: {
    factor: 'You have too many accounts that were opened recently',
    explain:
      'Because too many of your accounts were opened recently, the history on those new accounts is not long enough to provide sufficient payment and account history to demonstrate responsible behavior on those accounts.  In addition, having multiple accounts that are relatively new is seen as a higher risk because of the possibility of becoming overextended, which can then lead to late payments or defaulting on the account.',
  },
  7: {
    factor: 'You have too many delinquent or derogatory accounts',
    explain:
      'You have had too many accounts with payments that are at least 30 days late and/or on which a lender has reported a derogatory status.  Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  8: {
    factor:
      'You have either very few loans or too many loans with recent delinquencies',
    explain:
      'You have had too many accounts with late payments or do not have enough loans to provide sufficient information about your credit behaviors. Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  9: {
    factor:
      'The worst payment status on your accounts is delinquent or derogatory',
    explain:
      'Your credit file is showing an account with a payment that was at least 30 days late and/or on which a lender has reported a derogatory status.  Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  10: {
    factor:
      'You have either very few loans or too many loans with delinquencies',
    explain:
      'You have had too many accounts with late payments or do not have enough loans to provide sufficient information about your credit behaviors. Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  11: {
    factor:
      'The total of your delinquent or derogatory account balances is too high',
    explain:
      "The total of the balances is high on your accounts with late payments or on which a lender has reported the account derogatory. Late payments are a proven indicator of risk.  When credit line balances approach credit limits, risk is further increased because you don't have much credit available should it be needed, creating a greater chance of becoming overextended.",
  },
  12: {
    factor: 'The date that you opened your oldest account is too recent',
    explain:
      'Your oldest account is still too recent.  A credit file containing older accounts will have a positive impact on your credit score because it demonstrates that you are experienced managing credit.',
  },
  13: {
    factor: 'Your most recently opened account is too new',
    explain:
      'The account that you opened most recently is still too new.  A credit file containing older accounts will have a positive impact on your credit score because it demonstrates that you are experienced managing credit.',
  },
  14: {
    factor: 'Lack of sufficient credit history',
    explain:
      'Your credit file does not contain enough information about your use of credit.  A credit file with older accounts and/or more accounts reflects more experience with handling credit and can have a positive impact on your credit score.',
  },
  15: {
    factor:
      'Newest delinquent or derogatory payment status on your accounts is too recent',
    explain:
      'You have an account that had a late payment or on which a lender has reported a derogatory status.  Your credit file shows that this event occurred too recently.  Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  16: {
    factor: 'The total of all balances on your open accounts is too high',
    explain:
      "The total that you owe on all your accounts is high.  Maintaining high balances has proven to be risky behavior because you don't have much credit available should it be needed, creating a greater chance of becoming overextended.  People with the highest credit scores keep their account balances low.",
  },
  17: {
    factor:
      'Balances on previously delinquent accounts are too high compared to loan amts',
    explain:
      "You've been late on at least one payment in the past, and the balances on the accounts that had late payments are high compared to the original loan amount or the credit limit on a revolving account.  Late payments are a proven indicator of risk.  When balances are close to the original loan amount or approach credit limits, risk is further increased because you don't have much credit available should it be needed, creating a greater chance of becoming overextended.  People with the best scores have no late payments and keep their balances low.",
  },
  18: {
    factor:
      'Total of balances on accounts never late is too high compared to loan amounts',
    explain:
      "You have some accounts in your credit file with balances that are high compared to the original loan amount or the limit on a revolving account.  The good news is that you've never been late on these accounts.  Maintaining high balances has proven to be risky behavior because you don't have much credit available should it be needed, creating a greater chance of becoming overextended.  People with the highest credit scores keep their account balances low.",
  },
  21: {
    factor: 'No open accounts in your credit file',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Because you have no open accounts, your credit file does not contain enough information about your use of credit.  A mix of different types of open and active credit accounts can have a positive impact on your credit score.',
  },
  22: {
    factor: 'No recently reported account information',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Because you have no accounts where a lender has reported recent activity, your credit file does not contain enough information about your use of credit.  A mix of different types of open and active credit accounts with can have a positive impact on your credit score.',
  },
  23: {
    factor: 'Lack of sufficient relevant account information',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Your credit file does not have enough credit behavior information about your loans.  A mix of different types of open and active credit accounts can have a positive impact on your credit score.',
  },
  29: {
    factor:
      'Too many of your open bankcard or revolving accounts have a balance',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  Carrying a balance on too many bankcards and revolving accounts is an indicator of increased risk.  People who carry balances on multiple bankcards or revolving accounts have reduced available credit to use if needed, creating a greater chance of becoming overextended.',
  },
  30: {
    factor:
      'Too few of your bankcard or other revolving accounts have high limits',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  Having too few accounts with high credit limits is an indicator that you lack available credit.  Having higher limits gives you access to credit without seeking new loans or becoming overextended _ which are triggers for higher risk.',
  },
  31: {
    factor:
      'Too many bankcard or other revolving accounts were opened recently',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  Opening multiple bankcards or other revolving accounts in a relatively short period of time is a proven indicator of increased risk because the history on those new accounts is not long enough to provide sufficient payment and account information to demonstrate responsible behavior on those accounts.  In addition, having multiple accounts that are relatively new is seen as a higher risk because of the possibility of becoming overextended, which can then lead to late payments or defaulting on the account.',
  },
  32: {
    factor:
      'Balances on bankcard or revolving accounts too high compared to credit limits',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  You have bankcard or revolving accounts in your credit file with balances that are high compared to the credit limit on the account, which is a proven indicator of increased risk.',
  },
  33: {
    factor:
      'Your worst bankcard or revolving account status is delinquent or derogatory',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  Your credit file is showing a bankcard or revolving account with a payment that was at least 30 days late and/or an account on which a lender has reported a derogatory status.  Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  34: {
    factor:
      'Total of all balances on bankcard or revolving accounts is too high',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  The total combined amount you owe on all of your bankcards and revolving accounts is high, a sign of increased risk. People who carry balances on multiple bankcards or other revolving accounts have reduced available credit to use if needed, creating a greater chance of becoming overextended.',
  },
  35: {
    factor: 'Your highest bankcard or revolving account balance is too high',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  You have a bankcard or revolving account in your credit file with a high balance, a sign of increased risk. People who carry balances on bankcards or other revolving accounts have reduced available credit to use if needed, creating a greater chance of becoming overextended.',
  },
  36: {
    factor:
      'Your largest credit limit on open bankcard or revolving accounts is too low',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  The largest credit limit among all the open bankcard or revolving accounts in your credit file is low.  Having higher limits gives you access to credit without seeking new loans or becoming overextended _ which are triggers for higher risk.',
  },
  39: {
    factor:
      'Available credit on your open bankcard or revolving accounts is too low',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  The amount of credit you have available to use on your open bankcards or revolving accounts is low.  Having higher limits gives you access to credit without seeking new loans or becoming overextended _ which are triggers for higher risk.',
  },
  40: {
    factor:
      'The date you opened your oldest bankcard or revolving account is too recent',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  Your oldest bankcard or revolving account is still too new.  A credit file containing older accounts will have a positive impact on your credit score because it demonstrates that you are experienced managing credit.',
  },
  42: {
    factor:
      'The date you opened your newest bankcard or revolving account is too recent',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  The bankcard or revolving account that you opened most recently is still too new.  A credit file containing older accounts will have a positive impact on your credit score because it demonstrates that you are experienced managing credit.',
  },
  43: {
    factor:
      'Lack of sufficient credit history on bankcard or revolving accounts',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  Your credit file does not contain enough information about your use of bankcards or revolving accounts.  A credit file with older accounts and/or more accounts reflects more experience with handling credit and can have a positive impact on your credit score.',
  },
  44: {
    factor:
      'Too many bankcard or revolving accounts with delinquent or derogatory status',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  You have had too many bankcard or revolving accounts with payments that are at least 30 days late and/or on which a lender has reported a derogatory status.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  45: {
    factor:
      'Total balances too high on delinquent/derogatory bankcard or revolving accts ',
    explain:
      "Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  The total of the balances is high on your bankcard or revolving accounts with late payments or on which a lender has reported a derogatory.  Late payments are a proven indicator of risk.  When credit line balances approach credit limits, risk is further increased because you don't have much credit available should it be needed, creating a greater chance of becoming overextended.",
  },
  47: {
    factor: 'No open bankcard or revolving accounts in your credit file',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.   The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Because you have no open bankcards or revolving accounts, your credit file does not contain enough information about your use of credit.  A mix of different types of open and active credit accounts, including bankcards and other revolving accounts, can have a positive impact on your credit score.',
  },
  48: {
    factor: 'No bankcard or revolving recently reported account information',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Because you have no bankcard or revolving accounts where a lender has reported recent activity, your credit file does not contain enough information about your use of this kind of credit.  A mix of different types of open and active credit accounts, including bankcards and other revolving accounts, can have a positive impact on your credit score.',
  },
  49: {
    factor:
      'Lack of sufficient relevant bankcard or revolving account information',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts.  Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance.  The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Your credit file does not have enough credit behavior information about your bankcard or revolving accounts. A mix of different types of open and active credit accounts, including bankcard and other revolving accounts, can have a positive impact on your credit score.',
  },
  53: {
    factor:
      'The worst status on your real estate accounts is delinquent or derogatory',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit.  Your credit file is showing a real estate account with a payment that was at least 30 days late and/or on which a lender has reported a derogatory status.  Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  54: {
    factor:
      'The amount of balance paid down on your open real estate accounts is too low',
    explain:
      "A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit.  The amount that has been paid down on your open real estate accounts is low.  People who haven't paid down much of their mortgage or other real estate loans are higher credit risks than people who have.",
  },
  55: {
    factor:
      'Open real estate account balances are too high compared to their loan amounts',
    explain:
      "A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit.  The outstanding balances on open real estate accounts remain high compared to the original loan amounts.  People who haven't paid down much of their mortgage or other real estate loans are higher credit risks than people who have.",
  },
  57: {
    factor:
      'Too many real estate accounts with delinquent or derogatory payment status',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit.  You have had too many real estate accounts with payments that are at least 30 days late and/or on which a lender has reported a derogatory status. Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  58: {
    factor:
      'The total of all balances on your open real estate accounts is too high',
    explain:
      "A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit.  The total that you owe on all of your open real estate accounts is high.  Maintaining high balances has proven to be risky behavior because you don't have much credit available should it be needed, creating a greater chance of becoming overextended.  People with the highest credit scores keep their account balances low.",
  },
  61: {
    factor: 'No open real estate accounts in your credit file',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit.  The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  A mix of different types of open and active credit accounts, including real estate accounts, can have a positive impact on your credit score.',
  },
  62: {
    factor: 'No recently reported real estate account information',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit.  The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Because you have no real estate accounts where a lender has reported recent activity, your credit file does not contain enough information about your use of credit.  A mix of different types of open and active credit accounts with can have a positive impact on your credit score.',
  },
  63: {
    factor: 'Lack of sufficient relevant real estate account information',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit.  The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Your credit file does not contain enough credit behavior information about your real estate accounts.  A mix of different types of open and active credit accounts, including real estate loans, can have a positive impact on your credit score.',
  },
  64: {
    factor: 'No open first mortgage accounts in your credit file',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  A mix of different types of open and active credit accounts, including first mortgage accounts, can have a positive impact on your credit score.',
  },
  65: {
    factor: 'Lack of sufficient relevant first mortgage account information',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Your credit file does not contain enough credit behavior information about your first mortgage accounts.  A mix of different types of open and active credit accounts, including first mortgage loans, can have a positive impact on your credit score.',
  },
  66: {
    factor:
      'Your open auto account balances are too high compared to their loan amounts',
    explain:
      'You have auto loans in your credit file with balances that are high compared to the original loan amounts on the account, which is a proven indicator of increased risk.',
  },
  68: {
    factor: 'No open auto accounts in your credit file',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  A mix of different types of open and active credit accounts, including auto loans, can have a positive impact on your credit score.',
  },
  69: {
    factor: 'Lack of sufficient relevant auto  account information',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Your credit file does not contain enough credit behavior information about your auto loans.  A mix of different types of open and active credit accounts, including auto loans, can have a positive impact on your credit score.',
  },
  71: {
    factor:
      'You have either very few installment loans or too many with delinquencies',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan.  Auto loans and student loans are common examples of installment loans.  You have had too many installment accounts with late payments or do not have enough installment loans to provide sufficient information about your credit behaviors.  Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  72: {
    factor:
      'Too many installment accounts with a delinquent or derogatory payment status',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan.  Auto loans and student loans are common examples of installment loans.  You have had too many installment accounts that are at least 30 days late and/or on which a lender has reported a derogatory status.  Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  73: {
    factor:
      'The worst status on your installment accounts is delinquent or derogatory',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan.  Auto loans and student loans are common examples of installment loans.  Your credit file is showing an installment account with a payment that was at least 30 days late and/or on which a lender has reported a derogatory status.    Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  74: {
    factor:
      'The balance amount paid down on your open installment accounts is too low',
    explain:
      "An installment account is one with a fixed monthly payment for the life of the loan.  Auto loans and student loans are common examples of installment loans.  The amount that has been paid down on your open installment accounts is low.  People who haven't paid down much of their installment account loan balances are higher credit risks than people who have.",
  },
  75: {
    factor: 'The installment account that you opened most recently is too new',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan.  Auto loans and student loans are common examples of installment loans.  The installment account that you opened most recently is still too new.  A credit file containing older accounts will have a positive impact on your credit score because it demonstrates that you are experienced managing credit.',
  },
  76: {
    factor: 'You have insufficient credit history on installment loans',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan.  Auto loans and student loans are common examples of installment loans.  Your credit file does not contain enough information about your use of installment loans.  A credit file with older accounts and/or more accounts reflects more experience with handling credit and can have a positive impact on your credit score.',
  },
  77: {
    factor:
      'Newest delinquent or derogatory status on installment accounts is too recent',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan.  Auto loans and student loans are common examples of installment loans.  You have an installment account that had a late payment or on which a lender has reported a derogatory status.  Your credit file shows that this event occurred too recently.  Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  78: {
    factor:
      'Balances on installment accounts are too high compared to their loan amounts',
    explain:
      "An installment account is one with a fixed monthly payment for the life of the loan.  Auto loans and student loans are common examples of installment loans.  The outstanding balances on open installment accounts remain high compared to the original loan amounts.  People who haven't paid down much of the original amount on installment loans are higher credit risks than people who have.",
  },
  79: {
    factor:
      'Too many of the delinquencies on your installment accounts are recent',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan.  Auto loans and student loans are common examples of installment loans.  A delinquency is a payment that was made 30 or more days late. Too many late payments occurred recently on your installment accounts.  Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  81: {
    factor: 'No open installment accounts in your credit file',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan.  Auto loans and student loans are common examples of installment loans.  The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  A mix of different types of open and active credit accounts, including installment accounts, can have a positive impact on your credit score.',
  },
  83: {
    factor: 'Lack of sufficient relevant installment account information',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan.  Auto loans and student loans are common examples of installment loans.  The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Your credit file does not contain enough information about your installment accounts.  A mix of different types of open and active credit accounts, including installment accounts, can have a positive impact on your credit score.',
  },
  84: {
    factor:
      'The number of inquiries was also a factor, but effect was not significant',
    explain:
      'If a lender runs a credit check when you apply for credit, an inquiry is reported to the credit bureaus.  This can lower your score a small amount, in this case the drop was not significant.  The VantageScore credit score model takes rate shopping, e.g., for a mortgage or car loan, into consideration.  All inquiries for mortgages, auto loans and major credit cards that appear in your credit file within a 14-day window are interpreted as a single inquiry.  Another time inquiries never count against your score is when you check your own credit or obtain your own credit score.',
  },
  85: {
    factor: 'You have too many inquiries on your credit report.',
    explain:
      'If a lender runs a credit check when you apply for credit, an inquiry is reported to the credit bureaus.  This can lower your score a small amount, typically by 10 to 20 points.  The VantageScore credit score model takes rate shopping, e.g., for a mortgage or car loan, into consideration.  All inquiries for mortgages, auto loans and major credit cards that appear in your credit file within a 14-day window are interpreted as a single inquiry.  Another time inquiries never count against your score is when you check your own credit or obtain your own credit score.',
  },
  86: {
    factor: 'Your credit report contains too many derogatory public records',
    explain:
      'Public records include information filed or recorded by local, state, federal or other government agencies that is available to the general public. The types of public records that can affect your credit score include legal judgments against you, or tax liens levied by a government authority.  Public records can have a significant negative impact on your credit score.',
  },
  87: {
    factor: 'Your credit report contains too many unsatisfied public records',
    explain:
      'Public records include information filed or recorded by local, state, federal or other government agencies that is available to the general public. The types of public records that can affect your credit score include legal judgments against you, or tax liens levied by a government authority.  Your credit file contains public records that remain unpaid.  All public records can have a significant negative impact on your credit score.',
  },
  88: {
    factor:
      'One or more derogatory public records in your credit file is too recent',
    explain:
      'Public records include information filed or recorded by local, state, federal or other government agencies that is available to the general public. The types of public records that can affect your credit score include legal judgments against you, or tax liens levied by a government authority.  Your credit file contains public records that are too recent.  All public records can have a significant negative impact on your credit score.',
  },
  90: {
    factor: 'Too few discharged bankruptcies',
    explain:
      'You have a bankruptcy on your credit report that has not been discharged.',
  },
  93: {
    factor:
      'The worst status on your student loan accounts is delinquent or derogatory',
    explain:
      'Your credit file is showing a student loan with a payment that was at least 30 days late and/or on which a lender has reported a derogatory status.  Late payments are a proven indicator of increased risk.  People with late payments are at risk of being overextended, putting existing credit with lenders at risk.',
  },
  94: {
    factor:
      'The balance amount paid down on your open student loan accounts is too low',
    explain:
      "The amount that has been paid down on your open student loan accounts is low.  People who haven't paid down much of their student loan account balances are higher credit risks than people who have.",
  },
  95: {
    factor: 'You have too many collection agency accounts that are unpaid',
    explain:
      'Some collection agencies report account information to credit bureaus just like lenders do.  Your credit file has too many accounts that have been sent to a collection agency and remain unpaid.  Unpaid collection accounts in your file can have a significant negative impact on your credit score.',
  },
  96: {
    factor: 'The total you owe on collection agency accounts is high',
    explain:
      'Some collection agencies report account information to credit bureaus just like lenders do.  Your credit file indicates the amount of money you owe to collection agencies is high.  Unpaid collection accounts in your file can have a significant negative impact on your credit score.',
  },
  97: {
    factor: 'You have too few credit accounts',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score.  Your credit file does not have enough credit behavior information about your loans.  A mix of different types of open and active credit accounts can have a positive impact on your credit score.',
  },
  98: {
    factor: 'There is a bankruptcy on your credit report',
    explain:
      'Bankruptcy is a proven indicator of risk with future payments and causes a significant drop to your credit score over an extended period of time.',
  },
  P04: {
    factor:
      'The balances on your accounts are not too high compared to loan limits',
    explain:
      'Your balances are not too high compared to the loan amounts, which causes your score to improve.',
  },
  P05: {
    factor: 'There are no or only a few recent delinquencies on your accounts',
    explain:
      'A delinquency is a payment that was made 30 or more days late. You have had no or very few delinquencies recently which has caused your score to improve.',
  },
  P06: {
    factor: 'You have few or no accounts that were opened recently ',
    explain:
      'None or only a few of your accounts were recently opened. As a result, your history of credit management performance is enough to demonstrate responsible behavior.',
  },
  P07: {
    factor: 'You have either no or few delinquent or derogatory accounts',
    explain:
      'Paying your bills on time improves your score. You have paid all or nearly all of your bills on time.',
  },
  P08: {
    factor: 'One or more of your accounts were paid on time in recent months',
    explain:
      'Paying your bills on time improves your score. Recently at least one of your accounts was paid on time.',
  },
  P09: {
    factor:
      'None of your accounts have a severe delinquency or a derogatory status',
    explain:
      'Paying your bills on time improves your score. You have paid all your accounts on time or no more than 30 days late.',
  },
  P10: {
    factor: 'One or more of your accounts have been paid on time',
    explain:
      'Paying your bills on time improves your score. At least one of your accounts has no late payments.',
  },
  P11: {
    factor:
      'You have few or no accounts with delinquent or derogatory balances',
    explain:
      'Paying your bills on time improves your score. At most, only a small amount of your balances is currently late.',
  },
  P12: {
    factor: 'The date that you opened your oldest account is not too recent',
    explain:
      'Your oldest account was not opened too recently. As a result, your history of credit management is enough to demonstrate responsible behavior which has improved your score.',
  },
  P13: {
    factor: 'Your most recently opened account is not too new',
    explain:
      'Your newest account was not opened too recently. As a result, your history of credit management is enough to demonstrate responsible behavior which has improved your score.',
  },
  P14: {
    factor:
      'Your credit file contains enough information about your use of credit',
    explain:
      'A credit file with sufficient accounts and credit history reflects more experience in handling credit. More experience can have a positive impact on your score.',
  },
  P15: {
    factor:
      'None of your accounts have a recent delinquency or a derogatory status',
    explain:
      'A delinquency or derogatory is a payment that was made at least 30 or more days late. Recently you have paid all of your accounts on time which has caused your score to improve.',
  },
  P16: {
    factor: 'The total of all balances on your open accounts is not too high',
    explain:
      'The total of your balances on your open accounts, compared to the loan amounts, is not too high which has caused your score to improve. ',
  },
  P17: {
    factor:
      'Balances on prior delinquent accounts not too high compared to loan amts',
    explain:
      'Keeping low balances and paying your accounts on time can improve your score. The balances on accounts, that were previously paid late, are now low compared to the loan amount or credit limit.',
  },
  P18: {
    factor:
      'Total of balances on accts never late not too high compared to loan amts',
    explain:
      'Your balances on loans that you have paid on time, compared to the loan amounts, are not too high which has caused your score to improve. ',
  },
  P21: {
    factor: 'No open accounts in your credit file',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. Because you have no open accounts, your credit file does not contain enough information about your use of credit. A mix of different types of open and active credit accounts can have a positive impact on your score.',
  },
  P22: {
    factor: 'No recently reported account information',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. Because you have no open accounts, your credit file does not contain enough information about your use of credit. A mix of different types of open and active credit accounts can have a positive impact on your score.',
  },
  P23: {
    factor: 'Lack of sufficient relevant account information',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. Because you have no open accounts, your credit file does not contain enough information about your use of credit. A mix of different types of open and active credit accounts can have a positive impact on your score.',
  },
  P29: {
    factor:
      'Few or none of your open bankcard or revolving accounts has a balance',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. Keeping low balances can improve your score. The balances on your bankcard and revolving accounts are low.',
  },
  P30: {
    factor:
      'One or more of your bankcard or revolving accounts has a high limit',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. Having bankcard or revolving accounts with high credit limits indicates that you have high availability of credit, improving your score.',
  },
  P31: {
    factor:
      'Few or none of your bankcard or revolving accounts was opened recently',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. None or only a few of your bankcard or revolving accounts were recently opened. As a result, your history of credit management is enough to demonstrate responsible behavior.',
  },
  P32: {
    factor:
      'Balances on bankcard or revolving accts not too high compared to limits',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. Your balances on bankcard or revolving accounts are not too high compared to the credit limit amounts, which causes your score to improve.',
  },
  P33: {
    factor:
      'No severe delinquency/derogatory status on bankcard or revolving accts',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. Paying your bills on time improves your score. You have paid all your bankcard or revolving accounts on time or no more than 30 days late.',
  },
  P34: {
    factor:
      'Total of all balances on bankcard or revolving accounts is not too high',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. The total of your balances on your open bankcard or revolving accounts, compared to the credit limit amounts, is not too high which has caused your score to improve.',
  },
  P35: {
    factor:
      'Your highest bankcard or revolving account balance is not too high',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. The highest of your balances on your open bankcard or revolving accounts is not too high which has caused your score to improve.',
  },
  P36: {
    factor:
      'Largest credit limit on open bankcard or revolving accts is not too low',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. Having bankcard or revolving accounts with high credit limits indicates that you have high availability of credit, improving your score.',
  },
  P39: {
    factor:
      'Available credit on open bankcard or revolving accounts is not too low',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. You have high available credit on bankcard or revolving accounts which has caused your score to improve.',
  },
  P40: {
    factor:
      'Date your oldest bankcard or revolving account opened is not too recent',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. Your oldest bankcard or revolving account was not opened too recently. As a result, your history of credit management is enough to demonstrate responsible behavior which has improved your score.',
  },
  P42: {
    factor:
      'Date your newest bankcard or revolving account opened is not too recent',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. Your newest bankcard or revolving account was not opened too recently. As a result, your history of credit management is enough to demonstrate responsible behavior which has improved your score.',
  },
  P43: {
    factor:
      'Credit file has enough history on your use of bankcard/revolving accts',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. A credit file with sufficient accounts and credit history, including bankcard or revolving accounts, reflects more experience in handling credit. More experience can have a positive impact on your score.',
  },
  P44: {
    factor:
      'Few or no bankcard or revolving accts with delinquent/derogatory status',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. Paying your bills on time improves your score. You have paid all your bankcard or revolving accounts on time or no more than 30 days late.',
  },
  P45: {
    factor:
      'Few or no bankcard/revolving accts with delinquent/derogatory balances',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. Paying your bills on time improves your score. At most, only a small amount of your bankcard or revolving balances are currently late.',
  },
  P47: {
    factor: 'No open bankcard or revolving accounts in your credit file',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. Because you have no open bankcard or revolving accounts, your credit file does not contain enough information about your use of credit. A mix of different types of open and active credit accounts can have a positive impact on your score.',
  },
  P48: {
    factor: 'No bankcard or revolving recently reported account information',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. Because you have no open bankcard or revolving accounts, your credit file does not contain enough information about your use of credit. A mix of different types of open and active credit accounts can have a positive impact on your score.',
  },
  P49: {
    factor:
      'Lack of sufficient relevant bankcard or revolving account information',
    explain:
      'Bankcard accounts include credit cards and charge cards from a bank and are frequently revolving accounts. Revolving accounts allow you to carry a balance and your monthly payment will vary, based on the amount of your balance. The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. Because you have no open bankcard or revolving accounts, your credit file does not contain enough information about your use of credit. A mix of different types of open and active credit accounts can have a positive impact on your score.',
  },
  P50: {
    factor: 'No open retail revolving accounts in your credit file',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including retail revolving accounts, can have a positive impact on your score.',
  },
  P52: {
    factor: 'No open home equity loans in your credit file ',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including home equity accounts, can have a positive impact on your score.',
  },
  P53: {
    factor:
      'No real estate accounts with severe delinquency or derogatory status',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit. Paying your bills on time improves your score. You have paid all your real estate accounts on time or no more than 30 days late.',
  },
  P54: {
    factor:
      'Amount of balance paid down on open real estate accounts is not too low',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit. The amount that has been paid down on your open real estate accounts is high. Paying down a high amount of your real estate loans increases your score.',
  },
  P55: {
    factor:
      'Open real estate account balances not too high compared to loan amounts',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit. Your balances on real estate accounts are not too high compared to the original loan amounts, which causes your score to improve.',
  },
  P57: {
    factor:
      'Few or no real estate accts with delinquent or derogatory payment status',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit. Paying your bills on time improves your score. You have paid all your real estate loans on time or no more than 30 days late.',
  },
  P58: {
    factor:
      'The total of all balances on open real estate accounts is not too high',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit. Your balances on real estate loans, compared to the original loan amounts, are not too high which has caused your score to improve.',
  },
  P61: {
    factor: 'No open real estate accounts in your credit file',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit. The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including real estate accounts, can have a positive impact on your score.',
  },
  P62: {
    factor: 'No recently reported real estate account information',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit. The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including real estate accounts, can have a positive impact on your score.',
  },
  P63: {
    factor: 'Lack of sufficient relevant real estate account information',
    explain:
      'A real estate loan can be a first mortgage, a home equity loan, or home equity line of credit. The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including real estate accounts, can have a positive impact on your score.',
  },
  P64: {
    factor: 'No open first mortgage accounts in your credit file',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including first mortgage accounts, can have a positive impact on your score.',
  },
  P65: {
    factor: 'Lack of sufficient relevant first mortgage account information',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including first mortgage accounts, can have a positive impact on your score.',
  },
  P66: {
    factor:
      'Balances on open auto accounts are not too high compared to loan amounts',
    explain:
      'Your balances on auto accounts are not too high compared to the original loan amounts, which causes your score to improve.',
  },
  P68: {
    factor: 'No open auto accounts in your credit file',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including auto accounts, can have a positive impact on your score.',
  },
  P69: {
    factor: 'Lack of sufficient relevant auto account information',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including auto accounts, can have a positive impact on your score.',
  },
  P70: {
    factor: 'No open personal installment loans in your credit file',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including personal installment accounts, can have a positive impact on your score.',
  },
  P71: {
    factor: 'One or more of your installment accounts has been paid on time',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan. Auto loans and student loans are common examples of installment loans. Paying your bills on time improves your score. At least one of your installment accounts was paid on time.',
  },
  P72: {
    factor:
      'Few or no installment accts with delinquent or derogatory payment status',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan. Auto loans and student loans are common examples of installment loans. Paying your bills on time improves your score. You have paid all your installment loans on time or no more than 30 days late.',
  },
  P73: {
    factor:
      'No installment accounts with a severe delinquency or derogatory status',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan. Auto loans and student loans are common examples of installment loans. Paying your bills on time improves your score. You have paid all your installment accounts on time or no more than 30 days late.',
  },
  P74: {
    factor:
      'Amount of balance paid down on open installment accounts is not too low',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan. Auto loans and student loans are common examples of installment loans. The amount that has been paid down on your open installment accounts is high. Paying down a high amount of your installment loans increases your score.',
  },
  P75: {
    factor: 'Your most recently opened installment account is not too new',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan. Auto loans and student loans are common examples of installment loans. Your newest installment account was not opened too recently. As a result, your history of credit management is enough to demonstrate responsible behavior which has improved your score.',
  },
  P76: {
    factor:
      'Your credit file has enough history on your use of installment loans',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan. Auto loans and student loans are common examples of installment loans. A credit file with sufficient accounts and credit history, including installment loans, reflects more experience in handling credit. More experience can have a positive impact on your score.',
  },
  P77: {
    factor:
      'Newest delinquent/derogatory status on installment accts not too recent',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan. Auto loans and student loans are common examples of installment loans. You have an installment account that had a late payment or on which a lender has reported a derogatory status. However, your credit file shows that this event did not occur too recently, having some positive impact on your score.',
  },
  P78: {
    factor:
      'Installment account balances not too high compared to loan amounts',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan. Auto loans and student loans are common examples of installment loans. Your balances on installment accounts are not too high compared to the original loan amounts, which causes your score to improve.',
  },
  P79: {
    factor:
      'You have few or no recent delinquencies on your installment accounts',
    explain:
      'An installment account is one with a fixed monthly payment for the life of the loan. Auto loans and student loans are common examples of installment loans. A delinquency is a payment that was made 30 or more days late. You have had no or very few delinquencies recently on your installment accounts, which has caused your score to improve.',
  },
  P81: {
    factor: 'No open installment accounts in your credit file',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including installment accounts, can have a positive impact on your score.',
  },
  P83: {
    factor: 'Lack of sufficient relevant installment account information',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including installment accounts, can have a positive impact on your score.',
  },
  P85: {
    factor: 'You have few or no inquiries on your credit report',
    explain:
      'If a lender runs a credit check when you apply for credit, an inquiry is reported to the credit bureaus. This can lower your score a small amount, typically by 10 to 20 points. The VantageScore credit score model takes rate shopping, e.g., for a mortgage or car loan, into consideration. All inquiries for mortgages, auto loans and major credit cards that appear in your credit file within a 14-day window are counted as a single inquiry. Another time inquiries never count against your score is when you check your own credit or obtain your own score. You have no inquiries or a low number of inquiries on your credit report, which increases your score.',
  },
  P86: {
    factor:
      'You have few or no derogatory public records on your credit report',
    explain:
      'Public records include information filed or recorded by local, state, federal or other government agencies that is available to the general public. The types of public records that can affect your score include legal judgments against you and tax liens levied by a government authority. You have few or no public records on your credit report, which increases your score.',
  },
  P87: {
    factor:
      'You have few or no unsatisfied public records on your credit report',
    explain:
      'Public records include information filed or recorded by local, state, federal or other government agencies that is available to the general public. The types of public records that can affect your score include legal judgments against you and tax liens levied by a government authority. You have few or no public records on your credit report, which increases your score.',
  },
  P88: {
    factor:
      'You have no recent derogatory public records on your credit report',
    explain:
      'Public records include information filed or recorded by local, state, federal or other government agencies that is available to the general public. The types of public records that can affect your score include legal judgments against you and tax liens levied by a government authority. You have few or no public records on your credit report, which increases your score.',
  },
  P90: {
    factor: 'You have one or more discharged bankruptcies',
    explain:
      'You have one or more bankruptcies on your credit report. However, they have been discharged.',
  },
  P92: {
    factor: 'No open student loan accounts in your credit file',
    explain:
      'The VantageScore credit score model relies on information in your credit files at the three national credit reporting companies (Equifax, Experian and TransUnion) to generate your score. A mix of different types of open and active credit accounts, including student loan accounts, can have a positive impact on your score.',
  },
  P93: {
    factor:
      'No student loan accounts with a severe delinquency or derogatory status',
    explain:
      'Paying your bills on time improves your score. You have paid all your student loans on time or no more than 30 days late.',
  },
  P94: {
    factor:
      'Amount of balance paid down on open student loan accounts is not too low',
    explain:
      'The amount that has been paid down on your open student loan accounts is high. Paying down a high amount of your student loans increases your score.',
  },
  P95: {
    factor: 'You have few or no unpaid collection agency accounts',
    explain:
      'Some collection agencies report account information to credit bureaus just like lenders do. Your credit file has no or few accounts that have been sent to a collection agency and remain unpaid, which increases your score.',
  },
  P96: {
    factor: 'You have few or no collection agency account balances',
    explain:
      'Some collection agencies report account information to credit bureaus just like lenders do. Your credit file has no or few accounts that have been sent to a collection agency and remain unpaid, which increases your score.',
  },
  P98: {
    factor: 'There is no bankruptcy on your credit report',
    explain:
      'You have no bankruptcy on your credit report, which increases your score.',
  },
};
