import { Box, Stack, styled } from '@ltvco/refresh-lib/theme';
import { constants } from 'appConstants';

export const StyledBorderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '12px',
  marginTop: theme.spacing(2.5),

  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(3),
  },
}));

export const StyledGradientContainer = styled(Stack)(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  padding: theme.spacing(2),
  width: '100%',
  background: 'linear-gradient(180deg, #FFFFFF 0%, #E2DFFF 100%)',

  [theme.breakpoints.up('sm')]: {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: `url(${constants.images.astrologyStandalone.imgOnboardingBg})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
    },
  },
}));

export const StyledImg = styled('img')(({ theme }) => ({
  marginBottom: '0.5rem',
  alignSelf: 'center',
  minWidth: '124px',
  maxWidth: '138px',
  width: '100%',
}));
