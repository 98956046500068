export const flagEmoji = (language?: number) => {
  if (!language) return '';
  const flagEmoji = {
    1: '🇫🇷',
    2: '🇩🇪',
    3: '🇮🇹',
    4: '🇧🇷',
    5: '🇪🇸 ',
    6: '🇬🇧',
  };

  return flagEmoji[language as keyof typeof flagEmoji];
};
