import { OxfordHelpers } from '../Helpers';
import { creditDataPath } from '../constants';

export class UseCreditData {
  public static async getCreditDataRequest(token: string) {
    return OxfordHelpers.poll({
      method: 'GET',
      url: creditDataPath,
      token,
      jsonRequired: true,
    });
  }
}
