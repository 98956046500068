import { Stack } from '@ltvco/refresh-lib/theme';
import ascendantImg from '../../../images/ast-ascendant.svg';
import {
  type CreateAscendantResponse,
  type UserPropsExtra,
  AstrologyAscendantOnboarding,
  AstrologyIcon,
} from 'components/Astrology';
import { AstrologyStandaloneSectionTextField } from '../components';

interface AstrologyStandaloneAscendantProps {
  date?: string;
  month?: string;
  year?: string;
  ascendantResult?: CreateAscendantResponse;
  ascendantSign?: string;
  setAstrologyUserCallback: (astrologyUser: UserPropsExtra | null) => void;
}

export const AstrologyStandaloneAscendant = ({
  date,
  month,
  year,
  ascendantResult,
  ascendantSign,
  setAstrologyUserCallback,
}: AstrologyStandaloneAscendantProps) => {
  const handleSubmit = (astrologyUser: UserPropsExtra | null) => {
    setAstrologyUserCallback(astrologyUser);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Stack
      direction={{ md: 'row' }}
      gap={2.5}
      p={2.5}
      sx={{ '.MuiTypography-h5': { display: ascendantSign ? '' : 'none' } }}
    >
      {ascendantSign ? (
        <AstrologyIcon sign={ascendantSign} />
      ) : (
        <AstrologyIcon image={ascendantImg} standaloneVersion />
      )}
      {!ascendantResult || Object.keys(ascendantResult).length === 0 ? (
        <AstrologyAscendantOnboarding
          date={date}
          month={month}
          year={year}
          onSubmitCallback={handleSubmit}
        />
      ) : (
        <AstrologyStandaloneSectionTextField
          content={ascendantResult.report}
          rows={9}
          fullWidth
        />
      )}
    </Stack>
  );
};
