import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { useEffect } from 'react';
import { useLimitedPlanInfo } from 'utils/useLimitedPlanInfo';
import { checkPlanVariations } from 'utils/checkPlanVariations';
import { Header, HeaderControl } from '@ltvco/refresh-lib/v2';

type HeaderParentProps = {
  handleSearchSuccess: any;
  changeSingleReportVariation: (variation: boolean) => void;
  changeOwnerSearchVariation: (variation: boolean) => void;
  changeIsInAstrologyStandaloneVariation: (variation: boolean) => void;
  changeInternationalLanguageTest: (variation: boolean) => void;
  changeInternationalVinTest: (variation: boolean) => void;
  changeIsInRenameSidebarItemsVariation: (variation: boolean) => void;
  changeSearchpalVariation: (variation: boolean) => void;
  changeStaypalChatbotVariation: (variation: boolean) => void;
};

export const HeaderParent = ({
  handleSearchSuccess,
  changeSingleReportVariation,
  changeOwnerSearchVariation,
  changeIsInAstrologyStandaloneVariation,
  changeInternationalLanguageTest,
  changeInternationalVinTest,
  changeIsInRenameSidebarItemsVariation,
  changeSearchpalVariation,
  changeStaypalChatbotVariation,
}: HeaderParentProps) => {
  const isInOwnerSearchVariation = useFeatureIsOn('prop-2561');
  const isInAstrologyStandaloneVariation = useFeatureIsOn('oar-1164');
  const isInRenameSidebarItemsVariation = useFeatureIsOn('RFRSH-2616');
  const isInSearchpalVariation = useFeatureIsOn('OAR-1371');
  const isInStaypalChatbotVariation = useFeatureIsOn('oar-1161');

  const { planName } = useLimitedPlanInfo();
  const isInReportChange = useFeatureIsOn('OAR-1183');

  const {
    isInSingleReportVariation,
    isInternationalVinTest,
    isInInternationalLanguageTest,
  } = checkPlanVariations(planName);

  useEffect(() => {
    changeSingleReportVariation(isInSingleReportVariation);
  }, [isInSingleReportVariation]);

  useEffect(() => {
    changeOwnerSearchVariation(isInOwnerSearchVariation);
  }, [isInOwnerSearchVariation]);

  useEffect(() => {
    changeIsInAstrologyStandaloneVariation(isInAstrologyStandaloneVariation);
  }, [isInAstrologyStandaloneVariation]);

  useEffect(() => {
    changeInternationalLanguageTest(isInInternationalLanguageTest);
  }, [isInInternationalLanguageTest]);

  useEffect(() => {
    changeInternationalVinTest(isInternationalVinTest);
  }, [isInternationalVinTest]);

  useEffect(() => {
    changeIsInRenameSidebarItemsVariation(isInRenameSidebarItemsVariation);
  }, [isInRenameSidebarItemsVariation]);

  useEffect(() => {
    changeSearchpalVariation(isInSearchpalVariation);
  }, [isInSearchpalVariation]);

  useEffect(() => {
    changeStaypalChatbotVariation(isInStaypalChatbotVariation);
  }, [isInStaypalChatbotVariation]);

  return isInReportChange ? (
    <Header onSearchSuccess={handleSearchSuccess} />
  ) : (
    <HeaderControl onSearchSuccess={handleSearchSuccess} />
  );
};
