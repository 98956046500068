import { useQuery } from '@ltvco/refresh-lib/vendors';
import { useServiceToken } from '@ltvco/refresh-lib/v1';
import { BumpeeRequests } from 'api';
import { useState } from 'react';

export function useBumpeeWordTranslation(
  chatId: number,
  messageId: number,
  index: number
) {
  const [shouldTranslate, setShouldTranslate] = useState(false);
  const [translatedWordData, setTranslatedWordData] = useState({
    content: {
      translation: '',
      definition: '',
      translated_sentence: '',
    },
    open: false,
  });

  const { data: tokenData } = useServiceToken('bumpee');

  const results = useQuery({
    queryKey: [`bumpeeGO-message-${messageId}-word-translation-${index}`],
    queryFn: () => {
      return BumpeeRequests.getWordTranslation(
        tokenData.token,
        chatId,
        messageId,
        index
      );
    },
    enabled: Boolean(tokenData?.token) && shouldTranslate,
    onSuccess: (data: any) => {
      setTranslatedWordData((prevState) => ({
        ...prevState,
        content: data,
      }));
    },
  });

  const handleTranslateWordClick = () => {
    setShouldTranslate(true);
    setTranslatedWordData((prevState) => ({
      ...prevState,
      open: !prevState.open,
    }));
  };

  const handleTranslateWordClose = () => {
    setTranslatedWordData((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  return {
    ...results,
    translatedWordData,
    handleTranslateWordClick,
    handleTranslateWordClose,
  };
}
