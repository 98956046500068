import { Box, Stack, Text, styled, TextProps } from '@ltvco/refresh-lib/theme';

export const FadingAnimationContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  animation: 'fade 1s infinite',
  '@keyframes fade': {
    '0%': { opacity: 0 },
    '50%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
}));

interface GradiantOverlayProps {
  standaloneVersion?: boolean;
}
export const GradiantOverlay = styled(Box)<GradiantOverlayProps>(
  ({ standaloneVersion }) => ({
    position: 'absolute',
    bottom: -16,
    left: 0,
    right: 0,
    height: '54%',
    background: standaloneVersion
      ? `linear-gradient(180deg, #ffffff70 9.66%, #FFFFFF 85.75%)`
      : `linear-gradient(180deg, #eceaf945 9.66%, #eceaf9b0 85.75%)`,
  })
);

export const AstrologyLoadingBulletText = ({
  children,
  ...props
}: TextProps) => {
  return (
    <Text component="span" {...props} fontSize="18px">
      {children}
    </Text>
  );
};

export const MainImg = styled('img')(({ theme }) => ({
  width: '168px',
  marginBottom: theme.spacing(2.5),
}));
