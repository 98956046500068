import { useContext, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Text,
} from '@ltvco/refresh-lib/theme';
import { languageName, translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { LessonsContainer } from './LessonsContainer/LessonsContainer';
import { LanguageDrawer } from './LanguageDrawer/LanguageDrawer';
import { QuizzesContainer } from './QuizzesContainer/QuizzesContainer';
import { StoriesContainer } from './StoriesContainer/StoriesContainer';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { ExpandMore } from '@mui/icons-material';

export const AssignmentsContainer = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [languageOptions, _] = useState([
    { value: '1', display: 'French' },
    { value: '2', display: 'German' },
    { value: '3', display: 'Italian' },
    { value: '4', display: 'Portuguese' },
    { value: '5', display: 'Spanish' },
    { value: '6', display: 'English' },
  ]);

  const { trackEventGA4 } = useContext(AppConfig);
  const { isMobile } = useScreenSize();
  const { language, lessonLearningLanguage, setLessonLearningLanguage } =
    useLanguageContext();

  const handleLanguageDropDown = (event: SelectChangeEvent) => {
    const language = languageOptions.find(
      (language) => language.value === event.target.value
    );

    if (language) {
      trackEventGA4({
        event_name: 'Language Tutor Settings Change',
        language: languageName(parseInt(language.value)),
      });

      setLessonLearningLanguage({
        display: language.display,
        value: language.value,
      });
    }
  };

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <Stack flexDirection="column" spacing={2}>
      <Divider flexItem sx={{ my: 4 }} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Text variant="h3" color="primary.main">
          {translations.dashboard.assignments.lesson.header[language]}
        </Text>
        {isMobile ? (
          <Button
            disableTouchRipple
            variant="outlined"
            onClick={toggleDrawer}
            endIcon={<ExpandMore />}
          >
            {lessonLearningLanguage.display}
          </Button>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Text variant="h5" color="primary.main" sx={{ marginBottom: 0 }}>
              {translations.dashboard.assignments.lesson.selectLabel[language]}
            </Text>
            <FormControl sx={{ m: 1, minWidth: 157 }} size="small">
              <Select
                id="lesson-select"
                value={lessonLearningLanguage.value}
                options={languageOptions}
                onChange={handleLanguageDropDown}
              >
                <MenuItem value={''}></MenuItem>
                {languageOptions.map((language) => (
                  <MenuItem key={language.value} value={language.value}>
                    {language.display}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      </Stack>
      <LessonsContainer />
      <QuizzesContainer />
      <StoriesContainer />
      {isMobile && (
        <Drawer anchor={'bottom'} open={showDrawer} onClose={toggleDrawer}>
          <LanguageDrawer
            {...{ handleLanguageDropDown, languageOptions, toggleDrawer }}
          />
        </Drawer>
      )}
    </Stack>
  );
};
