import { Grid, Stack, Text } from '@ltvco/refresh-lib/theme';
import { AssignmentIcon } from 'components/Language/AssignmentIcon/AssignmentIcon';

interface AssignmentCardProps {
  assignment: {
    id: number;
    title: string;
    status: string;
  };
  handleRedirectToAssignment: (assignmentId: number) => void;
}

export const AssignmentCard = ({
  assignment,
  handleRedirectToAssignment,
}: AssignmentCardProps) => {
  const redirectToLesson = () => {
    handleRedirectToAssignment(assignment.id);
  };

  return (
    <Grid item xs={12} sm={6} key={assignment.id}>
      <Stack
        direction="row"
        alignItems="center"
        border={'1px solid #E2E2E2'}
        maxHeight={'80px'}
        p={3}
        sx={{ cursor: 'pointer' }}
        data-custom-value={assignment.id}
        onClick={redirectToLesson}
      >
        <AssignmentIcon status={assignment.status} />
        <Text variant="body1" sx={{ ml: 2 }}>
          {assignment.title}
        </Text>
      </Stack>
    </Grid>
  );
};
