import { Stack, Button, styled } from '@ltvco/refresh-lib/theme';

export const StyledCard = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  border: '1px solid #E2E2E2',
  borderRadius: '4px',
  backgroundColor: '#F8F8F8',
  flexDirection: 'row',
  alignItems: 'flex-start',
  textAlign: 'left',
  width: '100%',
}));

export const StyledVerticalCard = styled(StyledCard)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  textAlign: 'left',
  width: '100%',
}));

export const ContainerStyled = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  alignItems: 'center',
  textAlign: 'center',
  h6: {
    marginBottom: 0,
  },
  [theme.breakpoints.up('lg')]: {
    display: 'grid',
    gridTemplateAreas: `
      "first-block second-block"
      "third-block fourth-block"
      "third-block fifth-block";
    `,
    '.first-block': {
      gridArea: 'first-block',
    },
    '.second-block': {
      gridArea: 'second-block',
    },
    '.third-block': {
      gridArea: 'third-block',
      height: '100%',
    },
    '.fourth-block': {
      gridArea: 'fourth-block',
    },
    '.fifth-block': {
      gridArea: 'fifth-block',
    },
  },
}));

export const StyledBtn = styled(Button)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    width: '172px',
    position: 'absolute',
    right: 0,
  },
}));
