import React from 'react';
import { Text } from '@ltvco/refresh-lib/theme';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { GetCompatibilityZodiacSigns } from '../constants';
import {
  CompatibilityZodiacSignProps,
  CompatibilityZodiacSignOptionProps,
} from '../interfaces';
import { AstrologyCompatibilityScale } from './AstrologyCompatibilityScale/AstrologyCompatibilityScale';

export interface AstrologyCompatibilityProps {
  zodiacSign: string;
  compatibilitySign: string;
  children?: React.ReactNode;
  // `content` is the prop that will be passed back to the children component
  content?: string;
}

export const AstrologyCompatibility = ({
  zodiacSign,
  compatibilitySign,
  children,
}: AstrologyCompatibilityProps) => {
  const isInEnhancedCompatibilityVariation = useFeatureIsOn('oar-1107');

  if (!zodiacSign || !compatibilitySign) {
    return null;
  }

  const zodiacKey =
    zodiacSign.toLowerCase() as keyof CompatibilityZodiacSignOptionProps;

  const CompatibilityZodiacSigns = GetCompatibilityZodiacSigns(
    isInEnhancedCompatibilityVariation
  );
  const activeZodiacSign = CompatibilityZodiacSigns[zodiacKey];
  const compatibilitySignData: CompatibilityZodiacSignProps | undefined =
    activeZodiacSign?.[compatibilitySign.toLowerCase()];

  return compatibilitySign !== 'default' ? (
    <>
      <AstrologyCompatibilityScale
        compatibilityNumber={compatibilitySignData?.compatibility}
      />
      {children ? (
        React.cloneElement(children as React.ReactElement, {
          content: compatibilitySignData?.summary,
        })
      ) : (
        <Text mt={3.75}>{compatibilitySignData?.summary}</Text>
      )}
    </>
  ) : (
    <Text textAlign={'center'} mt={3.75}>
      Please select an option from above.
    </Text>
  );
};

export const withAstrologyCompatibility = <
  P extends AstrologyCompatibilityProps
>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P & AstrologyCompatibilityProps) => (
    <AstrologyCompatibility
      zodiacSign={props.zodiacSign}
      compatibilitySign={props.compatibilitySign}
    >
      <WrappedComponent {...props} />
    </AstrologyCompatibility>
  );
};
