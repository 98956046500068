import type { ReportRouteProps } from './types';
import { useLocation } from 'react-router-dom';
import { useQueryParams } from 'utils/useQueryParams';
import imgUnclaimedMoney from '../../images/img_unclaimed_money.svg';
import imgRobotMonitor from '../../images/img-check-icon.svg';
import imgRobotLoading from '../../images/img_unclaimed_money_loading.svg';
import imgRobotNoResults from '../../images/img_unclaimed_money_no_results.svg';
import { useContext, useEffect, useState } from 'react';
import {
  ReportNullState,
  UnclaimedMoneyReport,
  UnclaimedMoneyReportLoading,
  UnclaimedMoneyResults,
  useReport,
} from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { isZeroed, useScrollToSectionOnNavigate } from '@ltvco/refresh-lib/utils';

interface UnclaimedMoneyReportProps extends ReportRouteProps {}

export function UnclaimedMoneyPage({ permalink }: UnclaimedMoneyReportProps) {
  const queryParams = useQueryParams();
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();
  const { trackEvent } = useContext(AppConfig);
  const [stateList, setStateList] = useState<string[]>([]);
  const report = new UnclaimedMoneyReport(queryResult?.data);
  const search_data = report.data.meta?.search_data;

  const firstName =
    queryParams.get('first_name') || search_data?.first_name || '';
  const lastName = queryParams.get('last_name') || search_data?.last_name || '';
  const state = queryParams.get('state') || search_data?.state || '';
  const states = queryParams.get('states')?.split(',') || [];
  const multiStateSearch = !!queryParams.get('multi_state_search');

  useEffect(() => {
    if (stateList.length) return;

    if (state) {
      setStateList([state]);
    } else if (states.length) {
      setStateList(states);
    }
  }, [state, states]);

  useScrollToSectionOnNavigate(hash, queryResult);
  trackEvent('view full report', 'view', 'unclaimed money search');

  if (queryResult.isLoading || queryResult.isError) {
    return (
      <UnclaimedMoneyReportLoading
        variation="query"
        topImage={imgUnclaimedMoney}
        firstName={firstName}
        lastName={lastName}
        stateList={stateList}
      />
    );
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  if (!report)
    return (
      <UnclaimedMoneyReportLoading
        variation="query"
        topImage={imgUnclaimedMoney}
        firstName={firstName}
        lastName={lastName}
        stateList={stateList}
      />
    );

  return (
    <>
      <UnclaimedMoneyResults
        permalink={permalink}
        isLoading={queryResult.isLoading}
        loadingImage={imgRobotLoading}
        noResultsImage={imgRobotNoResults}
        mainReport={report}
        topImage={imgUnclaimedMoney}
        multiStateSearch={multiStateSearch}
        firstName={firstName}
        lastName={lastName}
        state={state}
        modalImage={imgRobotMonitor}
        stateList={stateList}
      />
    </>
  );
}
