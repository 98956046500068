import { OxfordHelpers } from './Helpers';
import { XmlHandler } from './XmlHandler';
import { creditNavigatorPath } from './constants';

export type CreditNavigatorPostType = {
  score_change_value: string;
  original_score: number;
};

export class UseCreditNavigator {
  public static async postCreditNavigator(
    body: CreditNavigatorPostType,
    token: string
  ) {
    return OxfordHelpers.request({
      method: 'POST',
      url: creditNavigatorPath,
      body: UseCreditNavigator.transformedBody(body),
      token,
    });
  }

  public static async getCreditNavigator(token: string) {
    return OxfordHelpers.poll({
      method: 'GET',
      url: creditNavigatorPath,
      token,
      jsonRequired: true,
    });
  }

  static transformedBody(body: CreditNavigatorPostType) {
    return {
      score_change_value: body.score_change_value,
      original_score: body.original_score,
      score_change_name: 'TargetScore',
      time_frame: 12,
    };
  }

  static parseResponse(body: string) {
    const creditNavigatorXml = XmlHandler()
      .parseToXml(body)
      .querySelector('CreditCompassData');

    const canDoNodes = creditNavigatorXml?.children?.[1];
    const factorsNodes = creditNavigatorXml?.children?.[2];

    if (canDoNodes?.tagName !== 'GenericDataElement') {
      return;
    }
    if (factorsNodes?.tagName !== 'GenericDataGroup') {
      return;
    }

    let canDo: string[] = [];
    let factors: string[] = [];

    const genericDataGroupCanDoSelector =
      canDoNodes.querySelector('GenericDataGroup');
    genericDataGroupCanDoSelector
      ?.querySelectorAll('Value')
      .forEach((value) => {
        if (value.textContent) {
          canDo.push(value.textContent);
        }
      });

    const genericDataElementFactorsSelector =
      factorsNodes.querySelectorAll('GenericDataElement');
    genericDataElementFactorsSelector.forEach((genericDataElement) => {
      const factorKey = genericDataElement.querySelector('Key')?.textContent;
      const factorValue =
        genericDataElement.querySelector('Value')?.textContent;

      if (factorKey && factorValue) {
        factors.push(`${factorKey}: ${factorValue}`);
      }
    });

    return { factors, canDo };
  }
}
