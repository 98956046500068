import { Text, Box, Divider } from '@ltvco/refresh-lib/theme';
import { ReportRouteProps } from 'components/reports/types';
import { alpha } from '@mui/material';
import { getFraudSearchSubtitleText } from './utils';
import React from 'react';
import {
  SearchWrapper,
  SearchFormsContainer,
  DetailsContainer,
  ReportContainer,
} from './components';
import {
  DebugMenu,
  FraudIpAdditionalDetails,
  FraudIpOverview,
  FraudIpThreatDetection,
  FraudSearchContainer,
  IpFraudReport,
  ReportLoading,
  ReportNullState,
  SearchFormProps,
  useReport,
} from '@ltvco/refresh-lib/v1';
import { isZeroed } from '@ltvco/refresh-lib/utils';

interface ipFraudReportPageProps extends ReportRouteProps {
  /** Callback to handle successful search form submission */
  onSearchSuccess: SearchFormProps['onSuccess'];
  /** Callback to handle search form submission errors */
  onSearchError: SearchFormProps['onError'];
}

export function IpFraudReportPage({
  onSearchSuccess,
  onSearchError,
  permalink,
}: ipFraudReportPageProps) {
  // hooks
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);

  // state
  const [tabState, setTabState] = React.useState<string>('ip');

  // constants
  const urlParams = new URLSearchParams(window.location.search);

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={2} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }
  const report = new IpFraudReport(queryResult?.data);
  const ipAddress = urlParams.get('ip_address') || report.data?.ip;
  document.title = `${ipAddress} - BeenVerified`;

  if (!report) return <ReportLoading menuItems={2} />;
  if (report?.data?.isEmpty) {
    return <ReportNullState />;
  }

  const {
    isp,
    asn,
    host,
    isVpn,
    isTor,
    isProxy,
    isCrawler,
    isDynamicConnection,
    isSharedConnection,
  } = report.data;

  const {
    fraudScore,
    isRecentAbuse,
    isFrequentAbuser,
    isHighRiskAttack,
    abuseVelocity,
  } = report.data.security;

  const { state, country, city } = report.data?.address?.parsed || {};

  return (
    <>
      <SearchWrapper>
        <SearchFormsContainer>
          <Text data-cy="fraud-check-form-title" variant="h2" color="success">
            FraudScan
          </Text>
          <Text
            mb={(theme) => ({ xs: theme.spacing(1), md: theme.spacing(3) })}
            data-cy="fraud-check-form-subtitle"
            variant="body2"
          >
            {getFraudSearchSubtitleText(tabState)}
          </Text>
          <FraudSearchContainer
            initialTab={'ip'}
            onSearchSuccess={onSearchSuccess}
            onSearchError={onSearchError}
            onSearchChange={setTabState}
          />
        </SearchFormsContainer>
      </SearchWrapper>
      <ReportContainer
        sx={{ width: 'fit-content' }}
        container
        direction={'row'}
        columns={12}
      >
        <DetailsContainer mt={3}>
          <Text
            mb={1.5}
            data-cy="fraud-check-form-title"
            variant="h3"
            color="success"
          >
            IP Verification
          </Text>
          <Box
            padding={1}
            mb={4}
            sx={(theme) => ({
              backgroundColor: alpha(theme.palette.success.dark, 0.04),
            })}
          >
            <Text variant="h5" component="p" fontWeight={700} m={0}>
              Results for: {ipAddress}
            </Text>
          </Box>
          <FraudIpOverview
            isp={isp}
            city={city || 'Unknown'}
            region={state || 'Unknown'}
            country={country || 'Unknown'}
          />
          <Box mt={4} mb={4}>
            <FraudIpThreatDetection
              fraudScore={fraudScore || 0}
              isVpn={isVpn}
              isRecentAbuse={isRecentAbuse}
              isFrequentAbuser={isFrequentAbuser}
              isHighRiskAttack={isHighRiskAttack}
              abuseVelocity={abuseVelocity}
              isTor={isTor}
              isSuspectedProxy={isProxy}
            />
          </Box>
          <Divider />
          <Box mt={4} mb={4}>
            <FraudIpAdditionalDetails
              isDynamicConnection={isDynamicConnection}
              isSharedConnection={isSharedConnection}
              host={host}
              asn={asn}
              isCrawler={isCrawler}
            />
          </Box>
        </DetailsContainer>
      </ReportContainer>
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
