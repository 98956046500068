import { Box, Text } from '@ltvco/refresh-lib/theme';
import imgEmptyChats from '../../../images/img-empty-chats.svg';
import { ChatsListProps, NewChatButton, NewChatIcon } from './ChatsList';
import { translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';

export const EmptyChats = ({ handleOnClickAddNewChat }: ChatsListProps) => {
  const { language } = useLanguageContext();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <img src={imgEmptyChats} alt="No chats found" />
      <Text variant="h2" mt={1} textAlign="center">
        {translations.dashboard.chats.emptyState.title[language]}
      </Text>
      <Text variant="body1" mb={2}>
        {translations.dashboard.chats.emptyState.description[language]}
      </Text>
      <NewChatButton
        variant="outlined"
        color="primary"
        onClick={handleOnClickAddNewChat}
        sx={{ mb: 2 }}
      >
        <NewChatIcon fontSize="small" />
        {translations.buttons.create[language]}
      </NewChatButton>
    </Box>
  );
};
