import { Card, Box, Text,  Stack, Button } from '@ltvco/refresh-lib/theme';
import { Message } from 'api/requests/Bumpee/interfaces';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { translations } from 'components/Language/constants';
import { Markdown } from '../../Markdown/Markdown';
import { StickyNote2Outlined } from '@mui/icons-material';

interface AssignmentIntroProps {
  message: Message;
  beginAssignmentClicked: boolean;
  setBeginAssignmentClicked: (value: boolean) => void;
  isQuizChat?: boolean;
  isStoryChat?: boolean;
  assignmentTopic?: string;
}

export function AssignmentIntro({
  message,
  beginAssignmentClicked,
  setBeginAssignmentClicked,
  isQuizChat = false,
  isStoryChat = false,
  assignmentTopic,
}: AssignmentIntroProps) {
  const { language } = useLanguageContext();

  const quizChatIntroText = `${translations.chatUI.quiz.introduction[language]}: ${assignmentTopic}`;

  const handleClick = () => {
    setBeginAssignmentClicked(true);
  };

  const introText = () => {
    if (isQuizChat) {
      return quizChatIntroText;
    }

    if (isStoryChat) {
      return translations.chatUI.story.introduction[language];
    }

    return translations.chatUI.lessons.introduction[language];
  };

  return (
    <Card variant="outlined" sx={{ maxWidth: '70%', mt: 2 }}>
      <Box display="flex">
        <Stack mr={2}>
          <StickyNote2Outlined
            sx={{ color: '#8B88AA', fontSize: '2rem' }}
          />
        </Stack>
        <Stack>
          <Text variant="h3">{introText()}</Text>
          <Markdown content={message.content} />
          {!beginAssignmentClicked && (
            <Button
              variant="outlined"
              sx={{ my: 2, width: 'fit-content' }}
              onClick={handleClick}
            >
              {isQuizChat
                ? translations.buttons.beginQuiz[language]
                : translations.buttons.beginLesson[language]}
            </Button>
          )}
        </Stack>
      </Box>
    </Card>
  );
}
