export const defaultFalseDataSet = {
  credit_navigator_used: false,
  credit_simulator_used: false,
  identity_management: false,
  enhanced_alert_enabled: false,
  instant_alert_enabled: false,
  standard_alert_enabled: false,
};

export type SettingsTileProps = {
  email?: string;
  setIsSettingsTab: React.Dispatch<React.SetStateAction<number>>;
};
