import { Button,  Stack, Text } from '@ltvco/refresh-lib/theme';
import { StyledStack } from './Components';
import {
  SerializedReport,
  additionalCardText,
  displayName,
  maxMonitors,
  parsedDate,
} from './utils';
import {
  enqueueSnackbar,
  RawReportType,
  useReportMonitors,
} from '@ltvco/refresh-lib/v1';
import { Close } from '@mui/icons-material';

export const ClaimedReportMonitorCard: React.FC<{
  serializedReport: SerializedReport;
  onNavigate: (permalink: string) => void;
}> = ({ serializedReport, onNavigate }) => {
  const permalink = serializedReport.permalink;
  const rawReportType = serializedReport.reportType;

  const { toggleReportIsMonitored, currentMonitors } =
    useReportMonitors(permalink);

  const monitoringSuccessCallback = () => {
    const monitorsLength = currentMonitors.length || 0;
    const remainingMonitors = maxMonitors - monitorsLength;
    enqueueSnackbar(
      `Monitor was disabled. You have ${remainingMonitors} monitors remaining.`,
      {
        variant: 'info',
      }
    );
  };

  const handleDeleteMonitor = () => {
    toggleReportIsMonitored({
      deleteMonitor: true,
      successCallback: monitoringSuccessCallback,
    });
  };

  const cardSubText = () => {
    const createdAt = parsedDate(serializedReport.createdAt).toString();

    return (
      <>
        {additionalCardText[rawReportType](serializedReport)}
        <Text variant="body1" key="1">
          Added {createdAt}
        </Text>
      </>
    );
  };

  return (
    <Stack
      sx={{ cursor: 'initial' }}
      direction="row"
      alignItems="center"
      columnGap={1.5}
    >
      <StyledStack
        direction={['column', 'row']}
        sx={{ flex: 1 }}
        justifyContent="space-between"
      >
        <Stack direction="column">
          <Text variant="h4">
            {displayName[rawReportType as RawReportType](serializedReport)}
          </Text>
          <Stack
            direction={['column', 'row']}
            columnGap={1}
            mb={1.25}
            rowGap={0.5}
          >
            {cardSubText()}
          </Stack>
        </Stack>
        <Stack alignItems="flex-end" justifyContent="center">
          <Button
            onClick={() => onNavigate(permalink)}
            sx={{ textDecoration: 'underline' }}
          >
            View Report
          </Button>
        </Stack>
      </StyledStack>
      <Close
        onClick={handleDeleteMonitor}
        sx={{
          cursor: 'pointer',
        }}
      />
    </Stack>
  );
};
