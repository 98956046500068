import { BumpeeRequests, CreateMessageParams } from 'api';
import { useContext } from 'react';
import { useAchievementEventCoordinator } from './useAchievementEventCoordinator';
import { useLocation } from 'react-router-dom';
import { enqueueSnackbar, useServiceToken } from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQueryClient, useInfiniteQuery, useMutation } from '@ltvco/refresh-lib/vendors';

const baseQueryKey = 'bumpeeGO';

export function useBumpeeMessages(
  chatId: number,
  lessonChatId?: number,
  shouldCompleteLesson?: boolean,
  quizChatId?: number,
  storyChatId?: number
) {
  const location = useLocation();
  const { handleAchievementEvent, handleLessonAchievementEvent } =
    useAchievementEventCoordinator();
  const { data: tokenData } = useServiceToken('bumpee');
  const { logError } = useContext(AppConfig);
  const queryClient = useQueryClient();

  const results = useInfiniteQuery({
    queryKey: [`${baseQueryKey}-chat-${chatId}-messages`],
    queryFn: ({ pageParam = 1 }) => {
      return BumpeeRequests.getMessages(tokenData.token, chatId, pageParam);
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.page < lastPage.total_pages) {
        return lastPage.page + 1;
      }
      return false;
    },
    enabled: Boolean(tokenData?.token) && Boolean(chatId),
  });

  const createMessageMutation = useMutation({
    mutationFn: (params: CreateMessageParams) => {
      if (storyChatId) {
        return BumpeeRequests.createStoryChatMessage(
          tokenData.token,
          params,
          storyChatId
        );
      }
      if (quizChatId) {
        return BumpeeRequests.createQuizChatMessage(
          tokenData.token,
          params,
          quizChatId
        );
      }
      if (shouldCompleteLesson && lessonChatId) {
        return BumpeeRequests.completeLesson(
          tokenData.token,
          params,
          lessonChatId.toString()
        );
      }
      if (lessonChatId) {
        return BumpeeRequests.createLessonChatMessage(
          tokenData.token,
          params,
          lessonChatId
        );
      }
      return BumpeeRequests.createMessage(tokenData.token, params);
    },
    onMutate: async (params: CreateMessageParams) => {
      await queryClient.cancelQueries({
        queryKey: [`${baseQueryKey}-chat-${chatId}-messages`],
      });

      const previousMessages = queryClient.getQueryData([
        '${baseQueryKey}-chat-${chatId}-messages',
      ]);

      const newMessage = {
        ...params,
        role: 'user',
        created_at: new Date().toISOString(),
      };

      queryClient.setQueryData(
        [`${baseQueryKey}-chat-${chatId}-messages`],
        (old: any) => {
          old.pages[0].messages.unshift(newMessage);
        }
      );

      return { previousMessages };
    },
    onSuccess: (data, params) => {
      const typeViaCurrentPath = location.pathname.split('/')[3];

      if (
        typeViaCurrentPath === 'lesson' &&
        shouldCompleteLesson &&
        lessonChatId
      ) {
        handleLessonAchievementEvent(lessonChatId);
      } else {
        handleAchievementEvent({
          event: 'create',
          category: 'message',
        });
      }

      if (lessonChatId && shouldCompleteLesson) {
        queryClient.invalidateQueries([
          `${baseQueryKey}-lesson-chat-${lessonChatId}`,
        ]);
      }
      if (quizChatId) {
        queryClient.invalidateQueries([`${baseQueryKey}-quiz-chats`]);
        queryClient.invalidateQueries([
          `${baseQueryKey}-quiz-chat-${quizChatId}`,
        ]);
      }
      queryClient.invalidateQueries([
        `${baseQueryKey}-chat-${chatId}-messages`,
      ]);
    },
    onError: (error: Error, _newMessage, context) => {
      if (context?.previousMessages) {
        queryClient.setQueryData(
          [`${baseQueryKey}-chat-${chatId}-messages`],
          context?.previousMessages
        );
      }
      enqueueSnackbar('Failed to create message', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Failed to create message', error as Error);
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        `${baseQueryKey}-chat-${chatId}-messages`,
      ]);
      queryClient.invalidateQueries([
        `${baseQueryKey}-story-chat-${storyChatId}`,
      ]);
    },
  });

  const shouldFetchNextPage = async () => {
    const lastPage = results?.data?.pages[results.data.pages.length - 1];
    if (!lastPage) return;
    const nextPage = lastPage.page + 1;

    if (nextPage <= lastPage?.total_pages) {
      await results.fetchNextPage({ pageParam: nextPage });
    }
  };

  return { ...results, createMessageMutation, shouldFetchNextPage };
}
