import idMonitorAnalytics from '../../../images/id-monitor-analytics.svg';
import idMonitorCalculator from '../../../images/id-monitor-calculator.svg';
import idMonitorContactPage from '../../../images/id-monitor-contact-page.svg';
import idMonitorFeed from '../../../images/id-monitor-feed.svg';
import idMonitorNavigator from '../../../images/id-monitor-navigator.svg';
import idMonitorShield from '../../../images/id-monitor-shield.svg';

export interface GetStartedSummaryDetailsProps {
  img: string;
  title: string;
  summary: string;
}

export const summaries: GetStartedSummaryDetailsProps[] = [
  {
    img: idMonitorContactPage,
    title: 'Identity & Credit Monitoring',
    summary:
      'Safeguard your identity and credit with alerts monitoring changes in your TransUnion credit record',
  },
  {
    img: idMonitorShield,
    title: '$1M Identity Theft Insurance & Restoration',
    summary:
      'Recover certain out-of-pocket expenses and lost wages if your identity is stolen',
  },
  {
    img: idMonitorAnalytics,
    title: 'Score Tracker',
    summary:
      'Keep a monthly historical record of your credit score to monitor your progress',
  },
  {
    img: idMonitorCalculator,
    title: 'Score Simulator',
    summary:
      'See how your next financial decision may impact your credit profile',
  },
  {
    img: idMonitorFeed,
    title: 'Credit Summary',
    summary:
      "Don't have time to look over your entire credit report? Access the highlights with Quick Summary",
  },
  {
    img: idMonitorNavigator,
    title: 'Credit Navigator',
    summary:
      'Get analysis on factors and actions that may help accomplish your credit goals',
  },
];
