import { useEffect, useState } from 'react';
import { useCloudCannon } from './useCloudCannon';
import { DateUtil } from '@ltvco/refresh-lib/utils';
import { translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';
import { useUserAchievements } from './useUserAchievements';

type Achievement = {
  image: string;
  date: string | undefined;
  disabled: boolean;
  id: string;
  name: string;
  tooltip: string;
  title?: string;
  description?: string;
  awarded_at?: string | null;
};

export const useAchievementsData = () => {
  const { data: userAchievements } = useUserAchievements();
  const { language } = useLanguageContext();
  const { data: achievementImages } = useCloudCannon();

  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [recentlyEarnedAchievement, setRecentlyEarnedAchievement] =
    useState<Achievement | null>(null);

  const dateUtil = new DateUtil();

  useEffect(() => {
    if (!achievementImages) return;

    const badgesData = translations.badges[language].map((badge) => {
      const achievementImg = achievementImages.achievements.find(
        (ach: { title: string; }) => ach.title === badge.id
      );

      return {
        ...badge,
        title: badge.id,
        image: achievementImg?.image ?? '',
        awarded_at: null,
        date: undefined,
        disabled: true,
      };
    });

    setAchievements(badgesData);
  }, [achievementImages, language]);

  useEffect(() => {
    if (!achievementImages || !userAchievements) return;

    const badgesData = translations.badges[language].map((badge) => {
      const achievementImg = achievementImages.achievements.find(
        (ach: { title: string; }) => ach.title === badge.id
      );
      const userAchievement = userAchievements.achievements.find(
        (ach) => ach.title === badge.id
      );

      return userAchievement
        ? {
            ...badge,
            title: badge.id,
            image: achievementImg?.image ?? '',
            awarded_at: userAchievement.awarded_at,
            date: dateUtil.parseDateFromString(
              userAchievement.awarded_at,
              'M/d/y'
            ),
            disabled: false,
          }
        : {
            ...badge,
            title: badge.id,
            image: achievementImg?.image ?? '',
            awarded_at: null,
            date: undefined,
            disabled: true,
          };
    });

    setAchievements(badgesData);
  }, [achievementImages, userAchievements, language]);

  useEffect(() => {
    const recentlyEarnedAchievement = achievements
      .filter(
        (achievement) =>
          achievement.awarded_at !== null && !achievement.disabled
      )
      .sort(
        (a, b) =>
          new Date(b.awarded_at as string).getTime() -
          new Date(a.awarded_at as string).getTime()
      )[0];

    if (recentlyEarnedAchievement) {
      setRecentlyEarnedAchievement(recentlyEarnedAchievement);
    }
  }, [achievements, userAchievements]);

  return {
    recentlyEarnedAchievement,
    achievements,
  };
};
