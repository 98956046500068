import { Button} from '@ltvco/refresh-lib/theme';
import { LoadingDots } from '@ltvco/refresh-lib/v1';
import { NotificationsNone } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

type NotificationsAlertsBtnProps = {
  notificationsCount: number;
  isLoading: boolean;
  hasNewNotifications: boolean;
};

const textToRender = (
  hasNewNotifications: boolean,
  notificationsCount: number
) => {
  const notificationText = hasNewNotifications
    ? notificationsCount === 1
      ? 'New ID/Credit Alert'
      : `New ID/Credit Alerts (${notificationsCount})`
    : `Previous ID/Credit Alerts (${notificationsCount})`;

  return notificationText;
};

export const NotificationsAlertsBtn = ({
  isLoading,
  notificationsCount,
  hasNewNotifications,
}: NotificationsAlertsBtnProps) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="outlined"
      startIcon={!isLoading && <NotificationsNone color="inherit" />}
      onClick={() => navigate('/dashboard/id-monitor/alert-details')}
      sx={{
        color: 'text.primary',
        marginBottom: 2,
        width: ['100%', '270px'],
        height: '40px',
      }}
      disabled={isLoading}
    >
      {isLoading ? (
        <LoadingDots count={4} />
      ) : (
        textToRender(hasNewNotifications, notificationsCount)
      )}
    </Button>
  );
};
