import { useEffect, useState, useContext } from 'react';
import {
  Stack,
  Select,
  Text,
  Divider,
  CreditScoreCategoryChart,
  CreditScoreMeter,
} from '@ltvco/refresh-lib/theme';
import { getDefaultView, getAvailableOptions } from './utils';
import { ScoreDiffContent } from './ScoreDiffContent';
import { CreditScoreHistoryProps } from './constants';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

type DataContainerProps = {
  monthsAbbreviations: string[];
  creditScoreHistory: CreditScoreHistoryProps[];
  lastUpdatedDate: string;
  creditScore?: number;
};

const getGACategory = (selected: string) => {
  if (selected === '6 Months') return 'credit history 6mo';
  if (selected === '9 Months') return 'credit history 9mo';
  if (selected === '1 Year') return 'credit history 12mo';

  return 'credit history 3mo';
};

export const DataContainer = ({
  monthsAbbreviations,
  creditScoreHistory,
  lastUpdatedDate,
  creditScore,
}: DataContainerProps) => {
  const { trackEvent } = useContext(AppConfig);
  const defaultView = getDefaultView(monthsAbbreviations);
  const initialSelected = defaultView ? defaultView : '';

  const [selected, setSelected] = useState<
    string | { value: string; display: string }
  >(initialSelected);
  const [dataToDisplay, setDataToDisplay] = useState<any[]>(creditScoreHistory);
  const [monthsToDisplay, setMonthsToDisplay] =
    useState<any[]>(monthsAbbreviations);

  useEffect(() => {
    if (selected === '3 Months') {
      setDataToDisplay(creditScoreHistory.slice(0, 3));
      setMonthsToDisplay(monthsAbbreviations.slice(0, 3));
    } else if (selected === '6 Months') {
      setDataToDisplay(creditScoreHistory.slice(0, 6));
      setMonthsToDisplay(monthsAbbreviations.slice(0, 6));
    } else if (selected === '9 Months') {
      setDataToDisplay(creditScoreHistory.slice(0, 9));
      setMonthsToDisplay(monthsAbbreviations.slice(0, 9));
    } else if (selected === '1 Year') {
      setDataToDisplay(creditScoreHistory);
      setMonthsToDisplay(monthsAbbreviations);
    }
  }, [selected, creditScoreHistory, monthsAbbreviations]);

  return (
    <>
      <Stack direction={['column', 'row']} justifyContent="space-between">
        <Stack>
          <Text>
            <strong>As of:</strong> {lastUpdatedDate}
          </Text>
          <ScoreDiffContent creditScoreHistory={dataToDisplay} />
        </Stack>
        <Select
          id="credit-score-history-select"
          options={getAvailableOptions(monthsAbbreviations)}
          value={selected}
          onChange={(e) => {
            const gaCategory = getGACategory(e.target.value as string);
            trackEvent(gaCategory, 'engagement click', 'id monitor');
            setSelected(e.target.value as string);
          }}
          sx={{
            width: ['100%', '220px'],
            height: '48px',
            marginY: [2],
          }}
        />
      </Stack>

      <Divider />
      <>
        <Stack direction={['column', 'column', 'row']}>
          <Stack mt={2} ml={-2}>
            <CreditScoreMeter score={creditScore} />
          </Stack>
          <Stack mt={-5} mb={-2.5} sx={{ width: '100%' }}>
            <CreditScoreCategoryChart
              categories={monthsToDisplay}
              values={dataToDisplay.map(
                (item: { score: number }) => item.score
              )}
              tooltipData={dataToDisplay}
            />
          </Stack>
        </Stack>
        <Divider />
        <Text
          display="block"
          variant="caption"
          mt={2.5}
          textAlign={['left', 'right']}
        >
          Data source: VantageScore® credit score by TransUnion®
        </Text>
      </>
    </>
  );
};
