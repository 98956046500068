import { useContext } from 'react';
import { AppConstants, AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  BaseHoroscopeQueryKeys,
  CreateHoroscopesResponse,
  CredentialsParams,
  HoroscopesSigns,
} from './interfaces';
import { astrology } from './api';
import { useQuery } from '@ltvco/refresh-lib/vendors';

export const useHoroscope = (
  queryType: keyof typeof BaseHoroscopeQueryKeys,
  sign: (typeof HoroscopesSigns)[number] | string,
  isEnabled: boolean
) => {
  const baseQueryKey = BaseHoroscopeQueryKeys[queryType];
  const {
    keys: { astrologyUser, astrologyKey },
  } = useContext(AppConstants);

  const { logError, trackEvent } = useContext(AppConfig);
  const credentials = {
    user: astrologyUser,
    key: astrologyKey,
  } as CredentialsParams;
  const localSign = sign?.toLowerCase();

  const getHoroscopeLocalStorage = function (
    sign: (typeof HoroscopesSigns)[number] | string
  ) {
    const localData = localStorage.getItem(baseQueryKey);
    if (!localData || !sign) return false;

    const parsedData = JSON.parse(localData);

    return parsedData?.[sign];
  };

  const storeHoroscopeLocalStorage = function (
    horoscope: CreateHoroscopesResponse
  ) {
    const localData = localStorage.getItem(baseQueryKey);
    const parsedData = localData ? JSON.parse(localData) : {};

    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    horoscope.requested_date = `${month}-${day}-${year}`;

    parsedData[horoscope.sun_sign.toLowerCase()] = horoscope;
    localStorage.setItem(baseQueryKey, JSON.stringify(parsedData));
  };

  const getFormattedPredictionDate = function (
    horoscope: CreateHoroscopesResponse
  ) {
    // Day and month are returned in the wrong order for the common date format
    // These lines split the date string into its parts and change the order
    if (!horoscope?.prediction_date) return null;

    const dateParts = horoscope.prediction_date.split('-');
    const [day, month, year] = dateParts;
    const formattedDate = `${month}-${day}-${year}`;

    return new Date(formattedDate);
  };

  function isTodayExpiredData(horoscope: CreateHoroscopesResponse) {
    const predictionDate = getFormattedPredictionDate(horoscope);
    if (!predictionDate) return true;

    const today = new Date();
    const todayWithoutTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    return predictionDate.getTime() < todayWithoutTime.getTime();
  }

  function isYesterdayExpiredData(horoscope: CreateHoroscopesResponse) {
    const predictionDate = getFormattedPredictionDate(horoscope);
    if (!predictionDate) return true;

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayWithoutTime = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate()
    );

    return predictionDate.getTime() < yesterdayWithoutTime.getTime();
  }

  function isTomorrowExpiredData(horoscope: CreateHoroscopesResponse) {
    const predictionDate = getFormattedPredictionDate(horoscope);
    if (!predictionDate) return true;

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowWithoutTime = new Date(
      tomorrow.getFullYear(),
      tomorrow.getMonth(),
      tomorrow.getDate()
    );

    return predictionDate.getTime() < tomorrowWithoutTime.getTime();
  }

  function isMonthlyExpiredData(horoscope: CreateHoroscopesResponse) {
    if (!horoscope?.requested_date) return true;

    const predictionDate = new Date(horoscope.requested_date);
    const predictionMonth = predictionDate.getMonth() + 1;
    const predictionYear = predictionDate.getFullYear();

    const differentMonth = predictionMonth !== new Date().getMonth() + 1;
    const differentYear = predictionYear !== new Date().getFullYear();

    return differentMonth || differentYear;
  }

  const isDataExpired = function (horoscope: CreateHoroscopesResponse) {
    if (baseQueryKey === BaseHoroscopeQueryKeys.today) {
      return isTodayExpiredData(horoscope);
    }
    if (baseQueryKey === BaseHoroscopeQueryKeys.yesterday) {
      return isYesterdayExpiredData(horoscope);
    }
    if (baseQueryKey === BaseHoroscopeQueryKeys.tomorrow) {
      return isTomorrowExpiredData(horoscope);
    }
    if (baseQueryKey === BaseHoroscopeQueryKeys.monthly) {
      return isMonthlyExpiredData(horoscope);
    }
  };

  // Day and month are returned in the wrong order for the common date format
  const fetchHoroscope = async (): Promise<CreateHoroscopesResponse> => {
    if (!localSign) return Promise.resolve({} as CreateHoroscopesResponse);

    const signData = getHoroscopeLocalStorage(localSign);
    const isExpired = isDataExpired(signData);

    if (signData && !isExpired) {
      return Promise.resolve(signData);
    }

    const newHoroscope = await astrology.fetchAstrology({
      credentials,
      queryType,
      params: { sign: localSign as (typeof HoroscopesSigns)[number] },
    });

    storeHoroscopeLocalStorage(newHoroscope as CreateHoroscopesResponse);
    return Promise.resolve(newHoroscope as CreateHoroscopesResponse);
  };

  const isLocalEnabled = isEnabled && !!localSign;

  const queryResult = useQuery({
    queryKey: [baseQueryKey],
    queryFn: fetchHoroscope,
    enabled: isLocalEnabled,
    cacheTime: 0,
    onError: (error: Error) => {
      logError(`${baseQueryKey} generation failed`, error as Error);
    },
  });

  // useQuery would keep the initial isLoading state which is true if the query is enabled = false since the beggining
  // We return isLoading false if the query is not enabled but if enabled we return the real value
  const isLoading = isLocalEnabled ? queryResult.isLoading : false;

  return {
    ...queryResult,
    isLoading,
  };
};
