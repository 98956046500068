import { Box,  styled } from '@ltvco/refresh-lib/theme';
import { ResetButton } from '../components';
import { Settings } from '@mui/icons-material';

export const StyledBox = styled(Box)`
  background: linear-gradient(to top, #e2dfff66 40%, white 100%);
`;

export const StyledContentBox = styled(Box)`
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px #0000001f;
  border: 1px solid #e2e2e2;
  background-color: white;
  width: 100%;
  overflow-x: auto;
`;

export const ResetAstrologyUserButton = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  return (
    <ResetButton
      startIcon={<Settings />}
      onClick={onClick}
      sx={(theme) => ({
        zIndex: '1',
        justifyContent: 'flex-start',
        color: theme.palette.primary.contrastText,
      })}
    >
      Reset
    </ResetButton>
  );
};
