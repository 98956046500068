import { useState, useEffect, useContext } from 'react';
import { ChatHeader } from '../ChatContainer/ChatHeader/ChatHeader';
import { CurrentChat } from '../ChatContainer/CurrentChat/CurrentChat';
import { useParams, useNavigate } from 'react-router-dom';
import { useBumpeeLessonChat } from '../hooks/useBumpeeLessonChat';
import { LessonSidebar } from './LessonSidebar/LessonSidebar';
import { languageID, translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';
import { useBumpeeLessonsChats } from '../hooks/useBumpeeLessonsChats';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';

export const LessonChatContainer = () => {
  const [shouldCompleteLesson, setShouldCompleteLesson] = useState(false);
  const [lessons, setLessons] = useState<
    {
      id: number;
      title: string;
      status: string;
      lessonChatId?: number;
      assignmentType: string;
    }[]
  >([]);

  const navigate = useNavigate();
  const { trackEventGA4 } = useContext(AppConfig);
  const { isMobile } = useScreenSize();
  const { lessonChatId } = useParams<{ lessonChatId: string }>();
  const { data: lessonChatData } = useBumpeeLessonChat(lessonChatId);
  const { data: userLessonsChats, createChatLessonMutation } =
    useBumpeeLessonsChats();
  const { language, lessonLearningLanguage } = useLanguageContext();

  const chatId = lessonChatData?.lesson_chat?.chat_id;
  const languageLessonName =
    lessonChatData?.lesson_chat?.chat?.learning_language?.toLowerCase();
  const activeLessonId = lessonChatData?.lesson_chat?.lesson_id;

  useEffect(() => {
    const lessons =
      translations.dashboard.assignments.lesson.lessonList[language];
    const modifiedLessons = lessons.map((lesson) => {
      const lessonChat = userLessonsChats?.lesson_chats.find(
        (userLesson) =>
          userLesson.lesson_id === lesson.id &&
          userLesson.learning_language_id ===
            languageID(languageLessonName ?? '')
      );

      return {
        ...lesson,
        assignmentType: 'lesson',
        status: lessonChat?.completed
          ? 'completed'
          : lessonChat
          ? 'incomplete'
          : 'new',
        lessonChatId: lessonChat?.id,
      };
    });

    setLessons(modifiedLessons);
  }, [language, lessonChatData, userLessonsChats, lessonChatId]);

  useEffect(() => {
    if (lessonChatData?.lesson_chat?.completed) {
      setShouldCompleteLesson(false);
    }
  }, [lessonChatId]);

  const redirectToLesson = (lessonId: number) => {
    const lesson = lessons.find((lesson) => {
      return lesson.id === lessonId;
    });
    if (!lesson) return;

    if (lesson.lessonChatId) {
      navigate(`/dashboard/language/lesson/${lesson.lessonChatId}`);
      return;
    }

    const lessonChatParams = {
      lesson_id: lesson.id,
      learning_language_id: parseInt(lessonLearningLanguage.value),
    };
    createChatLessonMutation.mutate(lessonChatParams);

    trackEventGA4({
      event_name: 'Language Tutor Engagement',
      type: 'lesson',
      language: languageLessonName,
      action: 'start',
    });
  };

  const completeLesson = () => {
    if (shouldCompleteLesson) return;

    setShouldCompleteLesson(true);
    trackEventGA4({
      event_name: 'Language Tutor Engagement',
      type: 'lesson',
      language: languageLessonName,
      action: 'complete',
    });
  };

  return (
    <Box
      display="flex"
      width="100%"
      height={isMobile ? '90vh' : '80vh'}
      ml={0}
      pt={{ xs: 0, md: 1 }}
      px={{ xs: 0, md: 1.5 }}
    >
      {!isMobile && (
        <LessonSidebar
          assignments={lessons}
          activeAssignmentId={activeLessonId}
          redirectToAssignment={redirectToLesson}
        />
      )}

      <Stack width="100%">
        <ChatHeader
          assignmentTopic={
            translations.dashboard.assignments.lesson.lessonList[language].find(
              (lesson) => lesson.id === lessonChatData?.lesson_chat.lesson_id
            )?.title
          }
          assignments={lessons}
          activeAssignmentId={activeLessonId}
          redirectToAssignment={redirectToLesson}
          isLessonChat={true}
        />
        <CurrentChat
          externalChatId={chatId}
          isLessonChat
          shouldCompleteLesson={shouldCompleteLesson}
          lessonCompleted={lessonChatData?.lesson_chat?.completed}
          completeLesson={completeLesson}
          lessonChatId={Number(lessonChatId)}
        />
      </Stack>
    </Box>
  );
};
