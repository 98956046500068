const aquarius = {
  aquarius:
    'Aquarius-Aquarius compatibility is marked by a strong bond of shared intellect and vision, creating a stimulating and progressive partnership.',
  aries:
    'Aquarius and Aries exhibit moderate compatibility, blending independence with camaraderie, needing mutual respect for individuality.',
  cancer:
    'Aquarius and Cancer share moderate compatibility, balancing emotion with intellect, fostering a dynamic and evolving bond.',
  capricorn:
    "Aquarius and Capricorn share good compatibility, balancing Capricorn's tradition with Aquarius' innovation, fostering growth through shared values and vision.",
  gemini:
    'Aquarius and Gemini exhibit strong compatibility, sharing intellect and innovation, creating an exciting and progressive relationship.',
  leo: "Aquarius and Leo exhibit moderate compatibility, balancing Leo's individuality with Aquarius' unity, requiring mutual appreciation for uniqueness.",
  libra:
    'Aquarius and Libra share very compatible traits, with social grace and ideals, fostering a harmonious and progressive partnership.',
  pisces:
    "Aquarius and Pisces exhibit fair compatibility, balancing Aquarius' idealism with Pisces' empathy, fostering understanding and mutual growth.",
  sagittarius:
    "Aquarius and Sagittarius exhibit a strong partnership, balancing Sagittarius' independence with Aquarius' unity, creating a progressive and harmonious bond.",
  scorpio:
    "Aquarius and Scorpio share fair compatibility, balancing Scorpio's depth with Aquarius' innovation, requiring mutual respect and appreciation.",
  taurus:
    'Aquarius and Taurus share moderate compatibility, balancing tradition with innovation, needing mutual appreciation for different approaches.',
  virgo:
    'Aquarius and Virgo form a good match, offering intellectual stimulation and adaptability, blending practicality with innovation.',
};

const aquariusEnhanced = {
  aquarius:
    'Aquarius-Aquarius compatibility is marked by a strong bond of shared intellect and vision, creating a stimulating and progressive partnership. When two Aquarians come together, they form a unique and dynamic union built on mutual understanding and an insatiable curiosity about the world. Both partners share a deep appreciation for individuality and freedom, allowing each other the space to explore their passions and interests. This relationship is characterized by lively discussions, innovative ideas, and a shared desire to make the world a better place. Their bond is strengthened by their forward-thinking nature and a love for social causes, often leading them to collaborate on projects that aim to bring about positive change. While their unconventional approach to life may sometimes pose challenges, their mutual respect and open-mindedness help them overcome any obstacles that come their way. Together, they create a harmonious and exciting partnership that thrives on creativity, friendship, and a shared vision for the future.',
  aries:
    "Aquarius and Aries compatibility is characterized by an exciting and adventurous partnership, fueled by their shared love for innovation and independence. When these two dynamic signs come together, they create an energetic and spirited relationship where spontaneity and passion are at the forefront. Both Aquarius and Aries are forward-thinkers who enjoy pushing boundaries and challenging the status quo. Their mutual enthusiasm for new experiences and ideas leads them to embark on exciting adventures and projects together. While Aries brings a fiery determination and drive, Aquarius offers a visionary perspective, making them a powerful team. However, their strong-willed natures can sometimes lead to clashes, as both prefer to take the lead. Yet, their mutual respect for each other's individuality helps them navigate any conflicts that arise. Together, they form a partnership that is never dull, full of excitement and the pursuit of shared dreams.",
  cancer:
    'Aquarius and Cancer compatibility presents an interesting blend of emotions and intellect, creating a partnership that can be both nurturing and challenging. While Aquarius is known for its logical and independent nature, Cancer is deeply emotional and values security and connection. This pairing requires effort and understanding from both sides, as they often approach life from different perspectives. Aquarius is drawn to Cancer’s nurturing and empathetic qualities, while Cancer admires Aquarius’s innovative ideas and unique vision. Despite their differences, they can complement each other well, with Aquarius bringing a fresh perspective to Cancer’s emotional world and Cancer offering warmth and support to Aquarius’s endeavors. However, their contrasting needs for freedom and closeness can sometimes lead to tension. Aquarius must learn to appreciate Cancer’s need for emotional connection, while Cancer should respect Aquarius’s desire for independence. With patience and open communication, they can create a partnership that balances emotional depth with intellectual stimulation.',
  capricorn:
    'Aquarius and Capricorn compatibility is an intriguing blend of innovation and practicality, creating a partnership that is both ambitious and progressive. Aquarius’s visionary ideas and love for change often challenge Capricorn’s preference for stability and tradition. Despite their differences, these two signs can complement each other well, with Aquarius bringing fresh perspectives and Capricorn offering structure and discipline. Aquarius admires Capricorn’s determination and work ethic, while Capricorn appreciates Aquarius’s originality and forward-thinking nature. Their shared desire for success and achievement often leads them to collaborate on projects that aim to bring about positive change. However, their contrasting approaches to life can sometimes lead to misunderstandings, with Aquarius seeking freedom and Capricorn valuing structure. By respecting each other’s strengths and differences, they can create a balanced partnership that combines creativity with practicality. Together, Aquarius and Capricorn can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  gemini:
    'Aquarius and Gemini compatibility is marked by a lively and intellectual connection, creating a partnership filled with curiosity, communication, and shared interests. Both signs are air elements, naturally drawn to each other’s wit, intelligence, and love for socializing. Aquarius and Gemini thrive in a relationship where mental stimulation is abundant, engaging in deep conversations and exchanging ideas on a wide range of topics. They share a mutual appreciation for freedom and independence, allowing each other the space to pursue their individual interests while still maintaining a strong bond. This relationship is characterized by its adaptability and open-mindedness, as both partners are flexible and willing to embrace change. While their tendency to overthink or get lost in their thoughts can sometimes lead to misunderstandings, their excellent communication skills enable them to resolve any issues quickly. Together, Aquarius and Gemini form a dynamic duo, constantly inspiring and challenging each other to grow and evolve.',
  leo: 'Aquarius and Leo compatibility is a powerful combination of creativity and charisma, creating a partnership that is both dynamic and inspiring. When these two signs come together, they form a vibrant and energetic relationship characterized by mutual admiration and respect. Aquarius is captivated by Leo’s confidence and leadership qualities, while Leo appreciates Aquarius’s originality and visionary ideas. Both signs are strong-willed and enjoy being in the spotlight, which can sometimes lead to power struggles. However, their shared desire for self-expression and adventure helps them overcome any challenges that arise. Together, they create a relationship that is full of excitement and passion, with each partner encouraging the other to pursue their dreams. Their mutual love for socializing and connecting with others often leads them to collaborate on creative projects or causes they are passionate about. In this partnership, Aquarius and Leo inspire each other to shine brightly, creating a lasting bond built on creativity, enthusiasm, and mutual respect.',
  libra:
    'Aquarius and Libra compatibility is a harmonious and intellectually stimulating partnership, characterized by a deep mutual understanding and appreciation for each other’s qualities. As both signs belong to the air element, they share a love for communication, socializing, and exploring new ideas. Aquarius and Libra thrive in a relationship where mental stimulation and open-mindedness are abundant, engaging in lively discussions and sharing their unique perspectives on various topics. Aquarius admires Libra’s charm and diplomacy, while Libra appreciates Aquarius’s originality and visionary ideas. This partnership is characterized by its balance and harmony, with each partner respecting the other’s need for independence and freedom. While they may sometimes struggle with indecisiveness or overthinking, their strong communication skills help them resolve any issues quickly. Together, Aquarius and Libra create a relationship that is both intellectually enriching and emotionally satisfying, built on mutual respect, friendship, and a shared vision for the future.',
  pisces:
    'Aquarius and Pisces compatibility is a blend of creativity and compassion, creating a partnership that is both imaginative and nurturing. Aquarius’s innovative ideas and logical thinking often complement Pisces’s emotional depth and artistic sensibilities. Despite their differences, these two signs can form a harmonious and enriching relationship, with Aquarius bringing fresh perspectives and Pisces offering empathy and understanding. Aquarius admires Pisces’s intuition and compassion, while Pisces appreciates Aquarius’s originality and visionary ideas. Their shared love for creativity and exploration often leads them to collaborate on projects that aim to bring about positive change and inspire others. However, their contrasting approaches to life can sometimes lead to misunderstandings, with Aquarius seeking independence and Pisces valuing emotional connection. By respecting each other’s strengths and differences, they can create a balanced partnership that combines creativity with empathy. Together, Aquarius and Pisces can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  sagittarius:
    'Aquarius and Sagittarius compatibility is a vibrant and adventurous partnership, characterized by a shared love for exploration, freedom, and intellectual pursuits. When these two spirited signs come together, they form an energetic and dynamic relationship where spontaneity and curiosity are at the forefront. Aquarius and Sagittarius share a mutual appreciation for new experiences and ideas, often embarking on exciting adventures and projects together. Aquarius is drawn to Sagittarius’s optimism and enthusiasm, while Sagittarius admires Aquarius’s originality and visionary thinking. Their relationship is characterized by its open-mindedness and adaptability, with both partners respecting each other’s need for independence and growth. While their tendency to be overly independent can sometimes lead to misunderstandings, their excellent communication skills help them resolve any issues quickly. Together, Aquarius and Sagittarius create a partnership that is never dull, full of excitement, inspiration, and the pursuit of shared dreams.',
  scorpio:
    'Aquarius and Scorpio compatibility is a complex and intriguing blend of intellect and intensity, creating a partnership that is both challenging and rewarding. Aquarius’s logical and independent nature often contrasts with Scorpio’s emotional depth and passion, leading to a dynamic relationship that requires effort and understanding from both sides. Despite their differences, Aquarius and Scorpio are drawn to each other’s strength and determination, forming a bond that is both fascinating and transformative. Aquarius admires Scorpio’s intensity and loyalty, while Scorpio appreciates Aquarius’s originality and visionary ideas. Their shared desire for change and growth often leads them to collaborate on projects that aim to bring about positive transformation. However, their contrasting needs for freedom and intimacy can sometimes lead to tension, with Scorpio seeking emotional connection and Aquarius valuing independence. By respecting each other’s boundaries and communicating openly, they can create a partnership that balances emotional depth with intellectual stimulation, offering opportunities for growth and transformation.',
  taurus:
    'Aquarius and Taurus compatibility is a blend of contrasting qualities, bringing together the visionary and the practical in a unique and intriguing partnership. Aquarius, with its innovative and unconventional mindset, often challenges Taurus’s preference for stability and tradition. This pairing can be both stimulating and challenging, as each partner brings a different perspective to the table. Taurus admires Aquarius’s intellect and progressive ideas, while Aquarius appreciates Taurus’s steadfastness and reliability. While Taurus seeks security and routine, Aquarius thrives on change and spontaneity, leading to potential differences in their approach to life. However, this combination also allows them to learn and grow from one another. Taurus can help ground Aquarius’s lofty ideas, providing practical solutions and support, while Aquarius can encourage Taurus to embrace change and explore new possibilities. Together, they create a partnership that balances innovation with practicality, offering opportunities for growth and transformation.',
  virgo:
    'Aquarius and Virgo compatibility is a blend of intellect and practicality, creating a partnership that is both analytical and innovative. Aquarius’s visionary ideas and love for innovation often challenge Virgo’s methodical and detail-oriented approach to life. Despite their differences, these two signs can complement each other well, with Aquarius bringing fresh perspectives and Virgo offering practical solutions. Aquarius admires Virgo’s intelligence and dedication, while Virgo appreciates Aquarius’s originality and open-mindedness. Their shared love for knowledge and learning often leads them to engage in thought-provoking conversations and collaborative projects. However, their contrasting approaches can sometimes lead to misunderstandings, as Virgo’s need for order may clash with Aquarius’s preference for spontaneity. By respecting each other’s strengths and differences, they can create a balanced partnership that combines creativity with practicality. Together, Aquarius and Virgo can achieve great things, using their unique skills to bring about positive change and growth in their lives.',
};

const aries = {
  aquarius:
    'Aries and Aquarius exhibit moderate compatibility, blending independence with camaraderie, needing mutual respect for individuality.',
  aries:
    'Aries-Aries compatibility is marked by a powerful bond of shared energy and similar traits, leading to a dynamic and passionate relationship.',
  cancer:
    'Aries and Cancer experience decent harmony, blending action-oriented Aries with emotionally deep Cancer for a blend of energy and sensitivity.',
  capricorn:
    'Aries and Capricorn face challenges due to differences in impulsiveness and practicality, requiring patience and understanding.',
  gemini:
    'The Aries-Gemini connection is characterized by lively communication and intellectual stimulation, creating a dynamic and engaging relationship.',
  leo: 'Aries and Leo form a strong partnership, combining passion with vitality, resulting in a fiery and enthusiastic connection.',
  libra:
    "Aries-Libra compatibility is moderate, balancing Aries' assertiveness with Libra's diplomacy, needing mutual respect and compromise.",
  pisces:
    "Aries-Pisces connection is moderate, combining Aries' drive with Pisces' dreaminess, requiring understanding of each other's realities.",
  sagittarius:
    'Aries and Sagittarius share a very harmonious bond, blending adventurous traits for an optimistic and enthusiastic partnership.',
  scorpio:
    "Aries-Scorpio connection exhibits moderate compatibility, balancing Aries' passion with Scorpio's intensity, requiring trust and depth.",
  taurus:
    "Aries and Taurus exhibit moderate compatibility, with Aries' assertiveness needing to find harmony with Taurus' stability for a balanced partnership.",
  virgo:
    "Aries and Virgo share fair harmony, with Aries' confidence complementing Virgo's practicality, requiring balance and understanding.",
};

const ariesEnhanced = {
  aquarius:
    'Aries and Aquarius compatibility is characterized by an exciting and adventurous partnership, fueled by their shared love for innovation and independence. When these two dynamic signs come together, they create an energetic and spirited relationship where spontaneity and passion are at the forefront. Both Aries and Aquarius are forward-thinkers who enjoy pushing boundaries and challenging the status quo. Their mutual enthusiasm for new experiences and ideas leads them to embark on exciting adventures and projects together. While Aries brings a fiery determination and drive, Aquarius offers a visionary perspective, making them a powerful team. However, their strong-willed natures can sometimes lead to clashes, as both prefer to take the lead. Yet, their mutual respect for each other’s individuality helps them navigate any conflicts that arise. Together, they form a partnership that is never dull, full of excitement and the pursuit of shared dreams.',
  aries:
    'Aries and Aries compatibility is a dynamic and fiery partnership, characterized by mutual passion and a zest for life. When two Aries come together, they form an exciting and energetic relationship filled with enthusiasm, adventure, and a shared love for challenges. Both partners are driven by a strong sense of ambition and a desire to be the best, which can lead to a competitive yet exhilarating connection. Their shared enthusiasm for life often results in spontaneous adventures and bold endeavors. While their similar temperaments can lead to clashes of egos and power struggles, their mutual respect and understanding allow them to resolve conflicts quickly. The key to their successful partnership lies in channeling their fiery energy into collaborative efforts and shared goals. Together, Aries and Aries create a relationship that is full of excitement, passion, and a relentless pursuit of their dreams.',
  cancer:
    'Aries and Cancer compatibility presents a complex and intriguing blend of passion and emotion, creating a partnership that can be both nurturing and challenging. Aries, with its fiery and independent nature, often contrasts with Cancer’s emotional depth and sensitivity. This pairing requires effort and understanding from both sides, as they often approach life from different perspectives. Aries is drawn to Cancer’s nurturing and empathetic qualities, while Cancer admires Aries’s courage and determination. Despite their differences, they can complement each other well, with Aries bringing excitement and energy to Cancer’s emotional world and Cancer offering warmth and support to Aries’s endeavors. However, their contrasting needs for freedom and closeness can sometimes lead to tension. Aries must learn to appreciate Cancer’s need for emotional connection, while Cancer should respect Aries’s desire for independence. With patience and open communication, they can create a partnership that balances passion with compassion, offering opportunities for growth and transformation.',
  capricorn:
    'Aries and Capricorn compatibility is an intriguing blend of ambition and practicality, creating a partnership that is both dynamic and grounded. Aries’s fiery and adventurous spirit often challenges Capricorn’s preference for stability and tradition. Despite their differences, these two signs can complement each other well, with Aries bringing excitement and energy and Capricorn offering structure and discipline. Aries admires Capricorn’s determination and work ethic, while Capricorn appreciates Aries’s enthusiasm and drive. Their shared desire for success and achievement often leads them to collaborate on projects that aim to bring about positive change. However, their contrasting approaches to life can sometimes lead to misunderstandings, with Aries seeking excitement and Capricorn valuing stability. By respecting each other’s strengths and differences, they can create a balanced partnership that combines ambition with practicality. Together, Aries and Capricorn can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  gemini:
    'Aries and Gemini compatibility is marked by a lively and adventurous partnership, characterized by mutual curiosity and a love for exploration. When these two dynamic signs come together, they form an energetic and spirited relationship filled with excitement and intellectual stimulation. Aries and Gemini share a mutual appreciation for new experiences and ideas, often embarking on thrilling adventures and projects together. Aries admires Gemini’s wit and adaptability, while Gemini appreciates Aries’s enthusiasm and drive. Their relationship is characterized by its open-mindedness and flexibility, with both partners respecting each other’s need for independence and growth. While their tendency to be overly impulsive can sometimes lead to misunderstandings, their excellent communication skills help them resolve any issues quickly. Together, Aries and Gemini create a partnership that is never dull, full of excitement, inspiration, and the pursuit of shared dreams.',
  leo: 'Aries and Leo compatibility is a powerful combination of energy and charisma, creating a partnership that is both dynamic and inspiring. When these two fiery signs come together, they form a vibrant and energetic relationship characterized by mutual admiration and respect. Aries is captivated by Leo’s confidence and leadership qualities, while Leo appreciates Aries’s enthusiasm and adventurous spirit. Both signs are strong-willed and enjoy being in the spotlight, which can sometimes lead to power struggles. However, their shared desire for self-expression and adventure helps them overcome any challenges that arise. Together, they create a relationship that is full of excitement and passion, with each partner encouraging the other to pursue their dreams. Their mutual love for socializing and connecting with others often leads them to collaborate on creative projects or causes they are passionate about. In this partnership, Aries and Leo inspire each other to shine brightly, creating a lasting bond built on creativity, enthusiasm, and mutual respect.',
  libra:
    'Aries and Libra compatibility is a harmonious and balanced partnership, characterized by mutual admiration and a blend of passion and diplomacy. Aries, with its fiery and adventurous spirit, often contrasts with Libra’s charm and grace, creating a dynamic and intriguing relationship. Aries admires Libra’s elegance and social skills, while Libra appreciates Aries’s enthusiasm and courage. This pairing can be both challenging and rewarding, as each partner brings unique qualities to the relationship. Aries’s boldness and determination often complement Libra’s ability to find balance and harmony. However, their contrasting approaches to life can sometimes lead to misunderstandings, with Aries seeking excitement and Libra valuing peace and harmony. By respecting each other’s strengths and finding common ground, they can create a partnership that combines passion with diplomacy. Together, Aries and Libra can achieve great things, using their unique skills to build a lasting and fulfilling relationship.',
  pisces:
    'Aries and Pisces compatibility is a blend of energy and compassion, creating a partnership that is both dynamic and nurturing. Aries’s fiery and adventurous spirit often contrasts with Pisces’s emotional depth and sensitivity, leading to a relationship that requires effort and understanding from both sides. Despite their differences, these two signs can complement each other well, with Aries bringing excitement and energy and Pisces offering empathy and understanding. Aries admires Pisces’s intuition and compassion, while Pisces appreciates Aries’s enthusiasm and drive. Their shared love for creativity and exploration often leads them to collaborate on projects that aim to bring about positive change and inspire others. However, their contrasting approaches to life can sometimes lead to misunderstandings, with Aries seeking independence and Pisces valuing emotional connection. By respecting each other’s strengths and differences, they can create a balanced partnership that combines energy with compassion. Together, Aries and Pisces can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  sagittarius:
    'Aries and Sagittarius compatibility is a vibrant and adventurous partnership, characterized by a shared love for exploration, freedom, and new experiences. When these two spirited signs come together, they form an energetic and dynamic relationship where spontaneity and curiosity are at the forefront. Aries and Sagittarius share a mutual appreciation for excitement and adventure, often embarking on thrilling journeys and projects together. Aries admires Sagittarius’s optimism and enthusiasm, while Sagittarius appreciates Aries’s passion and drive. Their relationship is characterized by its open-mindedness and adaptability, with both partners respecting each other’s need for independence and growth. While their tendency to be overly impulsive can sometimes lead to misunderstandings, their excellent communication skills help them resolve any issues quickly. Together, Aries and Sagittarius create a partnership that is never dull, full of excitement, inspiration, and the pursuit of shared dreams.',
  scorpio:
    'Aries and Scorpio compatibility is a powerful and intense partnership, characterized by mutual passion and a deep emotional connection. When these two strong-willed signs come together, they form a dynamic and intriguing relationship that is both challenging and rewarding. Aries and Scorpio share a mutual admiration for each other’s strength and determination, often forming a bond that is both fascinating and transformative. Aries admires Scorpio’s intensity and loyalty, while Scorpio appreciates Aries’s courage and drive. Their relationship is characterized by its passion and intensity, with both partners pushing each other to reach new heights. However, their strong personalities can sometimes lead to power struggles and conflicts. By respecting each other’s boundaries and communicating openly, they can create a partnership that balances passion with emotional depth. Together, Aries and Scorpio can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  taurus:
    'Aries and Taurus compatibility is a blend of energy and stability, creating a partnership that is both dynamic and grounded. Aries, with its fiery and adventurous spirit, often contrasts with Taurus’s practical and steady nature. This pairing can be both challenging and rewarding, as each partner brings unique qualities to the relationship. Aries admires Taurus’s reliability and strength, while Taurus appreciates Aries’s passion and drive. Their differences can lead to a complementary balance, with Taurus providing stability and support to Aries’s ambitious endeavors. However, their contrasting approaches to life can sometimes lead to misunderstandings, with Aries seeking excitement and Taurus valuing routine. By respecting each other’s strengths and finding common ground, they can create a harmonious partnership that combines energy with stability. Together, Aries and Taurus can achieve great things, using their unique skills to build a lasting and fulfilling relationship.',
  virgo:
    'Aries and Virgo compatibility is a blend of energy and intellect, creating a partnership that is both dynamic and analytical. Aries’s fiery and adventurous spirit often challenges Virgo’s methodical and detail-oriented approach to life. Despite their differences, these two signs can complement each other well, with Aries bringing excitement and energy and Virgo offering practical solutions and support. Aries admires Virgo’s intelligence and dedication, while Virgo appreciates Aries’s enthusiasm and drive. Their shared love for knowledge and learning often leads them to engage in thought-provoking conversations and collaborative projects. However, their contrasting approaches can sometimes lead to misunderstandings, as Virgo’s need for order may clash with Aries’s preference for spontaneity. By respecting each other’s strengths and differences, they can create a balanced partnership that combines energy with intellect. Together, Aries and Virgo can achieve great things, using their unique skills to bring about positive change and growth in their lives.',
};

const cancer = {
  aquarius:
    'Cancer and Aquarius share moderate compatibility, balancing emotion with intellect, fostering a dynamic and evolving bond.',
  aries:
    'Cancer and Aries experience decent harmony, blending action-oriented Aries with emotionally deep Cancer for a blend of energy and sensitivity.',
  cancer:
    'Cancer-Cancer compatibility is marked by a strong bond of deep emotional understanding, fostering a compassionate and nurturing bond.',
  capricorn:
    "Cancer and Capricorn face moderate compatibility, balancing emotion with ambition, requiring understanding of each other's priorities.",
  gemini:
    "Gemini and Cancer exhibit fair harmony, needing to balance different perspectives, appreciating each other's strengths for growth.",
  leo: 'Cancer and Leo exhibit fair harmony, balancing sensitivity with assertiveness, needing mutual support and appreciation.',
  libra:
    'Cancer-Libra compatibility is challenging, balancing emotions with diplomacy, requiring understanding and communication.',
  pisces:
    'Cancer and Pisces share a strong connection, with a nurturing and intuitive bond, creating a compassionate and spiritually fulfilling relationship.',
  sagittarius:
    'Cancer and Sagittarius exhibit moderate compatibility, blending emotion with optimism, needing balance between depth and lightness.',
  scorpio:
    'Cancer and Scorpio share a very compatible bond, with emotional depth and intuition, fostering a profound and spiritually fulfilling connection.',
  taurus:
    'The Taurus-Cancer connection is good, offering emotional stability and shared values, creating a nurturing and supportive relationship.',
  virgo:
    'Cancer and Virgo share fair harmony, balancing emotions with practicality, creating a stable and supportive relationship.',
};

const cancerEnhanced = {
  aquarius:
    'Cancer and Aquarius compatibility presents an interesting blend of emotion and intellect, creating a partnership that is both nurturing and stimulating. When these two signs come together, they form a dynamic relationship characterized by a mix of emotional depth and innovative thinking. Cancer, with its nurturing and empathetic nature, often contrasts with Aquarius’s intellectual and forward-thinking approach to life. Despite their differences, these two signs can complement each other well, with Cancer offering emotional support and understanding, and Aquarius bringing excitement and new perspectives. Cancer admires Aquarius’s originality and vision, while Aquarius appreciates Cancer’s warmth and caring nature. Their relationship is characterized by its adaptability and open-mindedness, with both partners learning and growing from each other’s unique perspectives. However, their contrasting needs for stability and independence can sometimes lead to misunderstandings. Cancer must learn to appreciate Aquarius’s need for freedom and exploration, while Aquarius should respect Cancer’s desire for security and emotional connection. By respecting each other’s strengths and differences, they can create a balanced partnership that combines emotion with intellect. Together, Cancer and Aquarius can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  aries:
    'Cancer and Aries compatibility is a blend of passion and emotion, creating a partnership that is both dynamic and nurturing. When these two signs come together, they form a relationship characterized by a unique mix of fiery energy and emotional depth. Aries, with its adventurous spirit and boldness, often contrasts with Cancer’s sensitivity and nurturing nature. This pairing requires effort and understanding from both sides, as they often approach life from different perspectives. Aries is drawn to Cancer’s caring and empathetic qualities, while Cancer admires Aries’s courage and determination. Despite their differences, they can complement each other well, with Aries bringing excitement and energy to Cancer’s emotional world and Cancer offering warmth and support to Aries’s endeavors. However, their contrasting needs for freedom and closeness can sometimes lead to tension. Aries must learn to appreciate Cancer’s need for emotional connection, while Cancer should respect Aries’s desire for independence. With patience and open communication, they can create a partnership that balances passion with compassion, offering opportunities for growth and transformation.',
  cancer:
    'Cancer and Cancer compatibility is a deeply emotional and nurturing partnership, characterized by mutual understanding and a strong sense of empathy. When two Cancers come together, they form a relationship built on shared values and a desire for emotional connection and security. Both partners are highly sensitive and intuitive, often forming a deep and meaningful bond that is both supportive and enduring. Their relationship is characterized by its warmth and nurturing nature, with both partners providing each other with a sense of comfort and understanding. Cancer admires their partner’s empathy and caring nature, while their partner appreciates Cancer’s intuition and emotional depth. While their similar temperaments can sometimes lead to moodiness and emotional intensity, their mutual respect and understanding help them navigate any challenges that arise. Together, two Cancers create a harmonious and fulfilling partnership, built on love, trust, and a shared desire for a stable and happy life.',
  capricorn:
    'Cancer and Capricorn compatibility is a harmonious and balanced partnership, characterized by mutual understanding and a deep appreciation for stability and practicality. When these two signs come together, they form a strong and lasting bond built on shared values and a desire for security. Cancer, with its emotional depth and nurturing nature, complements Capricorn’s practical and disciplined approach to life. Both signs value loyalty and commitment, often forming a partnership that is both supportive and enduring. Cancer admires Capricorn’s strength and determination, while Capricorn appreciates Cancer’s empathy and caring nature. Their relationship is characterized by its warmth and stability, with both partners providing each other with a sense of comfort and security. While their similar temperaments can sometimes lead to stubbornness, their mutual respect and understanding help them navigate any challenges that arise. Together, Cancer and Capricorn create a harmonious and fulfilling partnership, built on love, trust, and a shared desire for a stable and happy life.',
  gemini:
    'Cancer and Gemini compatibility presents an interesting blend of emotion and intellect, creating a partnership that is both stimulating and nurturing. When these two signs come together, they form a dynamic relationship characterized by a mix of emotional depth and lively communication. Cancer, with its nurturing and empathetic nature, often contrasts with Gemini’s intellectual and adaptable approach to life. Despite their differences, these two signs can complement each other well, with Cancer offering emotional support and understanding, and Gemini bringing excitement and mental stimulation. Cancer admires Gemini’s wit and versatility, while Gemini appreciates Cancer’s warmth and caring nature. Their relationship is characterized by its adaptability and open-mindedness, with both partners learning and growing from each other’s unique perspectives. However, their contrasting needs for stability and variety can sometimes lead to misunderstandings. Cancer must learn to appreciate Gemini’s need for change and exploration, while Gemini should respect Cancer’s desire for security and emotional connection. By respecting each other’s strengths and differences, they can create a balanced partnership that combines emotion with intellect. Together, Cancer and Gemini can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  leo: 'Cancer and Leo compatibility is a blend of emotion and charisma, creating a partnership that is both dynamic and nurturing. When these two signs come together, they form a vibrant relationship characterized by a mix of emotional depth and creative energy. Cancer, with its nurturing and empathetic nature, complements Leo’s confidence and leadership qualities. Both signs value loyalty and commitment, often forming a partnership that is both supportive and enduring. Cancer admires Leo’s strength and charisma, while Leo appreciates Cancer’s warmth and caring nature. Their relationship is characterized by its passion and creativity, with both partners inspiring each other to pursue their dreams. While their contrasting needs for attention and emotional connection can sometimes lead to misunderstandings, their mutual respect and understanding help them navigate any challenges that arise. Together, Cancer and Leo create a harmonious and fulfilling partnership, built on love, trust, and a shared desire for a stable and happy life.',
  libra:
    'Cancer and Libra compatibility is a blend of emotion and diplomacy, creating a partnership that is both nurturing and harmonious. When these two signs come together, they form a relationship characterized by a mix of emotional depth and social grace. Cancer, with its nurturing and empathetic nature, complements Libra’s charm and diplomatic approach to life. Both signs value love and harmony, often forming a partnership that is both supportive and enduring. Cancer admires Libra’s elegance and social skills, while Libra appreciates Cancer’s warmth and caring nature. Their relationship is characterized by its balance and harmony, with both partners inspiring each other to pursue their dreams. While their contrasting needs for security and social interaction can sometimes lead to misunderstandings, their mutual respect and understanding help them navigate any challenges that arise. Together, Cancer and Libra create a harmonious and fulfilling partnership, built on love, trust, and a shared desire for a stable and happy life.',
  pisces:
    'Cancer and Pisces compatibility is a deeply emotional and harmonious partnership, characterized by mutual understanding and a strong sense of empathy. When these two water signs come together, they form a relationship built on shared values and a desire for emotional connection and creativity. Cancer and Pisces share a mutual admiration for each other’s intuition and sensitivity, often forming a deep and meaningful bond that is both supportive and enduring. Their relationship is characterized by its warmth and nurturing nature, with both partners providing each other with a sense of comfort and understanding. Cancer admires Pisces’s artistic and compassionate nature, while Pisces appreciates Cancer’s empathy and caring nature. While their similar temperaments can sometimes lead to emotional intensity, their mutual respect and understanding help them navigate any challenges that arise. Together, Cancer and Pisces create a harmonious and fulfilling partnership, built on love, trust, and a shared desire for a stable and happy life.',
  sagittarius:
    'Cancer and Sagittarius compatibility is a blend of emotion and adventure, creating a partnership that is both dynamic and nurturing. When these two signs come together, they form a relationship characterized by a mix of emotional depth and a love for exploration. Cancer, with its nurturing and empathetic nature, often contrasts with Sagittarius’s adventurous and free-spirited approach to life. Despite their differences, these two signs can complement each other well, with Cancer offering emotional support and understanding, and Sagittarius bringing excitement and inspiration. Cancer admires Sagittarius’s optimism and enthusiasm, while Sagittarius appreciates Cancer’s warmth and caring nature. Their relationship is characterized by its adaptability and open-mindedness, with both partners learning and growing from each other’s unique perspectives. However, their contrasting needs for stability and variety can sometimes lead to misunderstandings. Cancer must learn to appreciate Sagittarius’s need for change and exploration, while Sagittarius should respect Cancer’s desire for security and emotional connection. By respecting each other’s strengths and differences, they can create a balanced partnership that combines emotion with adventure. Together, Cancer and Sagittarius can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  scorpio:
    'Cancer and Scorpio compatibility is a powerful and intense partnership, characterized by mutual passion and a deep emotional connection. When these two water signs come together, they form a dynamic and intriguing relationship that is both challenging and rewarding. Cancer and Scorpio share a mutual admiration for each other’s emotional depth and intuition, often forming a bond that is both fascinating and transformative. Cancer admires Scorpio’s intensity and loyalty, while Scorpio appreciates Cancer’s empathy and caring nature. Their relationship is characterized by its passion and intensity, with both partners pushing each other to reach new heights. However, their strong personalities can sometimes lead to power struggles and conflicts. By respecting each other’s boundaries and communicating openly, they can create a partnership that balances passion with emotional depth. Together, Cancer and Scorpio can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  taurus:
    'Cancer and Taurus compatibility is a harmonious and nurturing partnership, characterized by mutual understanding and a deep appreciation for stability and comfort. When these two signs come together, they form a strong and lasting bond built on shared values and a desire for security. Cancer, with its emotional depth and nurturing nature, complements Taurus’s practical and reliable approach to life. Both signs value loyalty and commitment, often forming a partnership that is both supportive and enduring. Cancer admires Taurus’s strength and dependability, while Taurus appreciates Cancer’s empathy and caring nature. Their relationship is characterized by its warmth and stability, with both partners providing each other with a sense of comfort and security. While their similar temperaments can sometimes lead to stubbornness, their mutual respect and understanding help them navigate any challenges that arise. Together, Cancer and Taurus create a harmonious and fulfilling partnership, built on love, trust, and a shared desire for a stable and happy life.',
  virgo:
    'Cancer and Virgo compatibility is a harmonious and supportive partnership, characterized by mutual understanding and a deep appreciation for stability and practicality. When these two signs come together, they form a strong and lasting bond built on shared values and a desire for security. Cancer, with its emotional depth and nurturing nature, complements Virgo’s practical and detail-oriented approach to life. Both signs value loyalty and commitment, often forming a partnership that is both supportive and enduring. Cancer admires Virgo’s intelligence and dedication, while Virgo appreciates Cancer’s empathy and caring nature. Their relationship is characterized by its warmth and stability, with both partners providing each other with a sense of comfort and security. While their similar temperaments can sometimes lead to perfectionism and overthinking, their mutual respect and understanding help them navigate any challenges that arise. Together, Cancer and Virgo create a harmonious and fulfilling partnership, built on love, trust, and a shared desire for a stable and happy life.',
};

const capricorn = {
  aquarius:
    "Capricorn and Aquarius share good compatibility, balancing Capricorn's tradition with Aquarius' innovation, fostering growth through shared values and vision.",
  aries:
    'Capricorn and Aries face challenges due to differences in impulsiveness and practicality, requiring patience and understanding.',
  cancer:
    "Cancer and Capricorn face moderate compatibility, balancing emotion with ambition, requiring understanding of each other's priorities.",
  capricorn:
    'Capricorn-Capricorn compatibility is marked by a strong bond of shared ambition and determination, creating a stable and successful partnership.',
  gemini:
    'Capricorn and Gemini form a good match, offering intellectual stimulation and practicality, blending innovation with groundedness.',
  leo: "Capricorn and Leo form a good match, balancing Leo's ambition with Capricorn's stability, creating a supportive and ambitious partnership.",
  libra:
    "Capricorn and Libra exhibit fair harmony, balancing Libra's diplomacy with Capricorn's ambition, requiring mutual respect and compromise.",
  pisces:
    'Capricorn and Pisces face challenges due to differences in practicality and empathy, requiring understanding and compromise.',
  sagittarius:
    "Sagittarius and Capricorn share good compatibility, balancing Sagittarius' freedom with Capricorn's ambition, fostering growth through balance and support.",
  scorpio:
    "Scorpio and Capricorn exhibit moderate compatibility, balancing Scorpio's emotion with Capricorn's ambition, fostering growth through trust and dedication.",
  taurus:
    "Taurus and Capricorn form a strong partnership, grounded and ambitious together, supporting each other's goals and creating stability.",
  virgo:
    'Virgo and Capricorn share very compatible traits, with practicality and diligence, creating a stable and successful partnership.',
};

const capricornEnhanced = {
  aquarius:
    'Capricorn and Aquarius compatibility is an intriguing blend of practicality and innovation, creating a partnership that is both dynamic and stimulating. When these two signs come together, they form a relationship characterized by a mix of ambition and visionary thinking. Capricorn, with its disciplined and goal-oriented nature, often contrasts with Aquarius’s free-spirited and unconventional approach to life. Despite their differences, these two signs can complement each other well, with Capricorn providing structure and stability to Aquarius’s innovative endeavors, and Aquarius bringing excitement and new perspectives to Capricorn’s ambitions. Capricorn admires Aquarius’s originality and intellect, while Aquarius appreciates Capricorn’s determination and work ethic. Their relationship is characterized by its adaptability and potential for growth, with both partners learning and growing from each other’s unique perspectives. However, their contrasting needs for security and freedom can sometimes lead to misunderstandings. Capricorn must learn to appreciate Aquarius’s need for independence and exploration, while Aquarius should respect Capricorn’s desire for security and order. By respecting each other’s strengths and differences, they can create a balanced partnership that combines ambition with innovation. Together, Capricorn and Aquarius can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  aries:
    'Capricorn and Aries compatibility is an intriguing blend of ambition and energy, creating a partnership that is both dynamic and challenging. When these two signs come together, they form a relationship characterized by a mix of practicality and enthusiasm. Capricorn, with its disciplined and goal-oriented nature, often contrasts with Aries’s adventurous and impulsive approach to life. Despite their differences, these two signs can complement each other well, with Capricorn providing stability and structure to Aries’s enthusiastic endeavors, and Aries bringing excitement and inspiration to Capricorn’s ambitions. Capricorn admires Aries’s courage and drive, while Aries appreciates Capricorn’s determination and work ethic. Their relationship is characterized by its ambition and potential for growth, with both partners pushing each other to reach new heights. However, their contrasting approaches to life can sometimes lead to misunderstandings, as Capricorn’s need for order may clash with Aries’s preference for spontaneity. By respecting each other’s strengths and differences, they can create a balanced partnership that combines ambition with energy. Together, Capricorn and Aries can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  cancer:
    'Capricorn and Cancer compatibility is a harmonious and balanced partnership, characterized by mutual understanding and a deep appreciation for stability and emotion. When these two signs come together, they form a strong and lasting bond built on shared values and a desire for security. Capricorn, with its disciplined and goal-oriented nature, complements Cancer’s nurturing and empathetic approach to life. Both signs value loyalty and commitment, often forming a partnership that is both supportive and enduring. Capricorn admires Cancer’s empathy and caring nature, while Cancer appreciates Capricorn’s determination and work ethic. Their relationship is characterized by its warmth and stability, with both partners providing each other with a sense of comfort and security. While their similar temperaments can sometimes lead to moodiness and emotional intensity, their mutual respect and understanding help them navigate any challenges that arise. Together, Capricorn and Cancer create a harmonious and fulfilling partnership, built on love, trust, and a shared desire for a stable and happy life.',
  capricorn:
    'Capricorn and Capricorn compatibility is a partnership of ambition and mutual respect, characterized by a deep understanding of each other’s goals and values. When these two disciplined and goal-oriented signs come together, they form a relationship that is both stable and focused. Both partners value hard work, loyalty, and long-term success, creating a bond that is both supportive and enduring. Their shared drive and determination make them a powerful team, capable of achieving great things together. However, their similar temperaments can sometimes lead to stubbornness and a lack of emotional expression. By learning to communicate openly and share their feelings, two Capricorns can build a relationship that is both deeply satisfying and long-lasting.',
  gemini:
    'Capricorn and Gemini compatibility presents an interesting blend of intellect and practicality, creating a partnership that is both dynamic and stimulating. When these two signs come together, they form a relationship characterized by a mix of logic and ambition. Capricorn, with its disciplined and goal-oriented nature, often contrasts with Gemini’s adaptable and curious approach to life. Despite their differences, these two signs can complement each other well, with Capricorn providing structure and stability to Gemini’s versatile endeavors, and Gemini bringing excitement and new perspectives to Capricorn’s ambitions. Capricorn admires Gemini’s wit and versatility, while Gemini appreciates Capricorn’s determination and work ethic. Their relationship is characterized by its adaptability and potential for growth, with both partners learning and growing from each other’s unique perspectives. However, their contrasting needs for stability and variety can sometimes lead to misunderstandings. Capricorn must learn to appreciate Gemini’s need for change and exploration, while Gemini should respect Capricorn’s desire for security and order. By respecting each other’s strengths and differences, they can create a balanced partnership that combines intellect with practicality. Together, Capricorn and Gemini can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  leo: 'Capricorn and Leo compatibility is an intriguing blend of ambition and charisma, creating a partnership that is both dynamic and inspiring. When these two signs come together, they form a relationship characterized by a mix of practicality and creativity. Capricorn, with its disciplined and goal-oriented nature, often contrasts with Leo’s confident and expressive approach to life. Despite their differences, these two signs can complement each other well, with Capricorn providing structure and stability to Leo’s creative endeavors, and Leo bringing excitement and inspiration to Capricorn’s ambitions. Capricorn admires Leo’s strength and charisma, while Leo appreciates Capricorn’s determination and work ethic. Their relationship is characterized by its ambition and potential for growth, with both partners pushing each other to reach new heights. However, their contrasting needs for attention and stability can sometimes lead to misunderstandings. Capricorn must learn to appreciate Leo’s need for self-expression and recognition, while Leo should respect Capricorn’s desire for security and order. By respecting each other’s strengths and differences, they can create a balanced partnership that combines ambition with charisma. Together, Capricorn and Leo can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  libra:
    'Capricorn and Libra compatibility is a blend of ambition and diplomacy, creating a partnership that is both balanced and inspiring. When these two signs come together, they form a relationship characterized by a mix of practicality and charm. Capricorn, with its disciplined and goal-oriented nature, often contrasts with Libra’s diplomatic and social approach to life. Despite their differences, these two signs can complement each other well, with Capricorn providing structure and stability to Libra’s social endeavors, and Libra bringing excitement and new perspectives to Capricorn’s ambitions. Capricorn admires Libra’s elegance and social skills, while Libra appreciates Capricorn’s determination and work ethic. Their relationship is characterized by its adaptability and potential for growth, with both partners learning and growing from each other’s unique perspectives. However, their contrasting needs for security and social interaction can sometimes lead to misunderstandings. Capricorn must learn to appreciate Libra’s need for balance and harmony, while Libra should respect Capricorn’s desire for security and order. By respecting each other’s strengths and differences, they can create a balanced partnership that combines ambition with diplomacy. Together, Capricorn and Libra can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  pisces:
    'Capricorn and Pisces compatibility is a harmonious and supportive partnership, characterized by mutual understanding and a deep appreciation for stability and compassion. When these two signs come together, they form a strong and lasting bond built on shared values and a desire for emotional security. Capricorn, with its disciplined and goal-oriented nature, complements Pisces’s empathetic and intuitive approach to life. Both signs value loyalty and commitment, often forming a partnership that is both supportive and enduring. Capricorn admires Pisces’s creativity and sensitivity, while Pisces appreciates Capricorn’s determination and work ethic. Their relationship is characterized by its warmth and emotional depth, with both partners providing each other with a sense of comfort and security. While their different approaches to life can sometimes lead to misunderstandings, their mutual respect and understanding help them navigate any challenges that arise. Together, Capricorn and Pisces create a harmonious and fulfilling partnership, built on love, trust, and a shared desire for a stable and happy life.',
  sagittarius:
    'Capricorn and Sagittarius compatibility is a blend of ambition and adventure, creating a partnership that is both dynamic and inspiring. When these two signs come together, they form a relationship characterized by a mix of practicality and a love for exploration. Capricorn, with its disciplined and goal-oriented nature, often contrasts with Sagittarius’s adventurous and free-spirited approach to life. Despite their differences, these two signs can complement each other well, with Capricorn providing structure and stability to Sagittarius’s adventurous endeavors, and Sagittarius bringing excitement and inspiration to Capricorn’s ambitions. Capricorn admires Sagittarius’s optimism and enthusiasm, while Sagittarius appreciates Capricorn’s determination and work ethic. Their relationship is characterized by its adaptability and potential for growth, with both partners learning and growing from each other’s unique perspectives. However, their contrasting needs for stability and variety can sometimes lead to misunderstandings. Capricorn must learn to appreciate Sagittarius’s need for change and exploration, while Sagittarius should respect Capricorn’s desire for security and order. By respecting each other’s strengths and differences, they can create a balanced partnership that combines ambition with adventure. Together, Capricorn and Sagittarius can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  scorpio:
    'Capricorn and Scorpio compatibility is a powerful and intense partnership, characterized by mutual ambition and a deep emotional connection. When these two strong-willed signs come together, they form a dynamic and intriguing relationship that is both challenging and rewarding. Capricorn and Scorpio share a mutual admiration for each other’s determination and intuition, often forming a bond that is both fascinating and transformative. Capricorn admires Scorpio’s intensity and loyalty, while Scorpio appreciates Capricorn’s discipline and work ethic. Their relationship is characterized by its passion and potential for growth, with both partners pushing each other to reach new heights. However, their strong personalities can sometimes lead to power struggles and conflicts. By respecting each other’s boundaries and communicating openly, they can create a partnership that balances ambition with emotional depth. Together, Capricorn and Scorpio can achieve great things, using their unique skills to bring about growth and transformation in their lives.',
  taurus:
    'Capricorn and Taurus compatibility is a harmonious and stable partnership, characterized by mutual understanding and a deep appreciation for security and practicality. When these two earth signs come together, they form a strong and lasting bond built on shared values and a desire for stability. Capricorn, with its disciplined and goal-oriented nature, complements Taurus’s reliable and practical approach to life. Both signs value loyalty and commitment, often forming a partnership that is both supportive and enduring. Capricorn admires Taurus’s strength and dependability, while Taurus appreciates Capricorn’s determination and work ethic. Their relationship is characterized by its warmth and stability, with both partners providing each other with a sense of comfort and security. While their similar temperaments can sometimes lead to stubbornness, their mutual respect and understanding help them navigate any challenges that arise. Together, Capricorn and Taurus create a harmonious and fulfilling partnership, built on love, trust, and a shared desire for a stable and happy life.',
  virgo:
    'Capricorn and Virgo compatibility is a harmonious and supportive partnership, characterized by mutual understanding and a deep appreciation for stability and practicality. When these two earth signs come together, they form a strong and lasting bond built on shared values and a desire for security. Capricorn, with its disciplined and goal-oriented nature, complements Virgo’s practical and detail-oriented approach to life. Both signs value loyalty and commitment, often forming a partnership that is both supportive and enduring. Capricorn admires Virgo’s intelligence and dedication, while Virgo appreciates Capricorn’s determination and work ethic. Their relationship is characterized by its warmth and stability, with both partners providing each other with a sense of comfort and security. While their similar temperaments can sometimes lead to perfectionism and overthinking, their mutual respect and understanding help them navigate any challenges that arise. Together, Capricorn and Virgo create a harmonious and fulfilling partnership, built on love, trust, and a shared desire for a stable and happy life.',
};

const gemini = {
  aquarius:
    'Aquarius and Gemini exhibit strong compatibility, sharing intellect and innovation, creating an exciting and progressive relationship.',
  aries:
    'The Aries-Gemini connection is characterized by lively communication and intellectual stimulation, creating a dynamic and engaging relationship.',
  cancer:
    "Gemini and Cancer exhibit fair harmony, needing to balance different perspectives, appreciating each other's strengths for growth.",
  capricorn:
    'Capricorn and Gemini form a good match, offering intellectual stimulation and practicality, blending innovation with groundedness.',
  gemini:
    'Gemini-Gemini compatibility is marked by a strong bond of shared intellectual stimulation and curiosity, fostering a dynamic and engaging relationship.',
  leo: 'Gemini and Leo face challenges balancing independence with togetherness, needing mutual respect for space and closeness.',
  libra:
    'Gemini and Libra share very compatible traits, with lively communication and harmony, creating a balanced and harmonious relationship.',
  pisces:
    'Gemini and Pisces face challenges due to differences in practicality and imagination, requiring compromise and creative solutions.',
  sagittarius:
    'Gemini and Sagittarius share a strong connection, with intellectual and social synergy, creating a stimulating and fulfilling partnership.',
  scorpio:
    "Gemini-Scorpio connection exhibits moderate compatibility, balancing emotions with logic, requiring understanding of each other's depths.",
  taurus:
    'Gemini and Taurus share moderate compatibility, blending adaptability with stability, needing mutual understanding and compromise for a balanced partnership.',
  virgo:
    'Gemini and Virgo share fair harmony, needing to balance detail-oriented and spontaneous traits, fostering mutual understanding.',
};

const geminiEnhanced = {
  aquarius:
    'Gemini and Aquarius form an intellectually stimulating and unconventional partnership, both being air signs with a love for ideas and innovation. They share a mutual appreciation for freedom, independence, and intellectual exploration, making their relationship full of lively discussions and shared interests. Gemini is fascinated by Aquarius’s visionary ideas and humanitarian outlook, while Aquarius enjoys Gemini’s quick wit and adaptability. Together, they enjoy a relationship that is free-spirited and forward-thinking, with both partners encouraging each other to think outside the box and explore new horizons. However, their shared love for freedom can sometimes lead to a lack of emotional depth and commitment. Gemini’s tendency to be indecisive might clash with Aquarius’s strong opinions, and Aquarius’s aloofness can sometimes make Gemini feel insecure. For a successful relationship, both must work on deepening their emotional connection and finding a balance between independence and intimacy. When they do, Gemini and Aquarius can create a dynamic and intellectually fulfilling partnership that is ahead of its time.',
  aries:
    "The pairing of Gemini and Aries is like a whirlwind of curiosity and action. Gemini's intellectual agility meets Aries's bold dynamism, creating a partnership brimming with excitement and spontaneity. Gemini, with their love for communication and exploration, finds Aries’s assertive and adventurous spirit irresistible. Aries, in turn, is captivated by Gemini’s quick wit and playful nature. Together, they enjoy a life full of stimulating conversations and thrilling adventures. However, their fast-paced lifestyle can sometimes lead to a lack of focus, with Gemini's tendency to overthink contrasting with Aries's impulsive decisions. For this duo to thrive, they need to balance Gemini’s need for mental stimulation with Aries’s desire for action. If they can find common ground, Gemini and Aries can create an exhilarating and ever-changing partnership that keeps them both on their toes.",
  cancer:
    'Gemini and Cancer have a fascinating relationship dynamic, combining the intellectual curiosity of Gemini with the emotional depth of Cancer. Gemini is intrigued by Cancer’s nurturing nature and intuitive understanding of emotions, while Cancer is fascinated by Gemini’s wit and versatility. Gemini brings lightness and fun into Cancer’s life, encouraging them to step out of their shell and explore the world. Meanwhile, Cancer offers Gemini a safe harbor where they can explore their feelings and vulnerabilities. However, challenges can arise from their different emotional needs, Gemini’s rational approach can sometimes clash with Cancer’s emotional sensitivity. Cancer may feel insecure if they perceive Gemini as distant or uncommitted, while Gemini might feel overwhelmed by Cancer’s emotional demands. For their relationship to succeed, both must learn to communicate openly and respect each other’s emotional landscapes. Together, they can create a balance of heart and mind, blending Cancer’s empathy with Gemini’s intellect.',
  capricorn:
    'Gemini and Capricorn bring together two very different energies, creating a relationship that is both challenging and enriching. Gemini’s light-hearted and spontaneous nature contrasts with Capricorn’s serious and disciplined approach to life. Gemini is intrigued by Capricorn’s ambition and practicality, while Capricorn admires Gemini’s intellect and versatility. This pairing can be highly complementary, with Capricorn providing the stability and structure that Gemini sometimes lacks, and Gemini bringing a sense of fun and adventure into Capricorn’s life. However, their differences can also lead to misunderstandings, Gemini may find Capricorn’s cautious and conservative nature restrictive, while Capricorn might view Gemini’s carefree attitude as irresponsible. For their relationship to succeed, both must learn to appreciate each other’s strengths and find common ground. If they can respect each other’s differences and work together, Gemini and Capricorn can create a balanced and fulfilling partnership that combines practicality with a sense of wonder.',
  gemini:
    'When two Geminis come together, the result is a relationship full of lively conversation, intellectual stimulation, and endless curiosity. This pairing is like two butterflies fluttering around a garden, always seeking new experiences and ideas. They understand each other’s need for variety and change, making their time together dynamic and never dull. With their shared love for communication, they can spend hours debating, discussing, and exploring different perspectives. However, the challenge for this couple lies in their mutual tendency to be inconsistent and superficial. Without grounding influences, they may struggle with commitment and stability. To thrive, two Geminis must focus on deepening their emotional connection and finding ways to bring their ideas into reality. If they can harness their shared energy and curiosity, they can create a relationship that is both intellectually satisfying and emotionally enriching.',
  leo: 'The combination of Gemini and Leo is a vibrant and playful mix of air and fire. Gemini’s quick wit and Leo’s radiant charisma create a relationship that is full of fun, laughter, and excitement. Leo is drawn to Gemini’s cleverness and ability to keep them entertained, while Gemini is captivated by Leo’s confidence and generous spirit. This duo thrives on social interaction, enjoying a lively social life and stimulating conversations. Leo’s passion adds warmth and enthusiasm to the relationship, while Gemini’s versatility keeps things fresh and exciting. However, potential challenges arise from their different approaches to attention, Leo’s desire for admiration might clash with Gemini’s need for freedom and variety. Gemini may find Leo’s need for constant validation demanding, while Leo might feel neglected if Gemini’s attention wanes. For a harmonious relationship, both partners must balance Leo’s need for recognition with Gemini’s need for independence. Together, they can create a dynamic and joyful partnership that shines brightly.',
  libra:
    'Gemini and Libra make a harmonious and intellectually compatible pair, both being air signs that thrive on communication and social interaction. Gemini’s quick mind and witty banter are perfectly matched by Libra’s charm and diplomacy. They enjoy a relationship filled with lively discussions, cultural activities, and a mutual appreciation for beauty and art. Libra’s sense of balance and fairness complements Gemini’s adaptability and open-mindedness, creating a partnership that is both dynamic and peaceful. However, their shared love for variety and indecision can sometimes lead to a lack of direction, with both partners avoiding deeper emotional issues. Gemini may find Libra’s need for harmony a bit stifling, while Libra might view Gemini’s restlessness as unsettling. For a successful relationship, both must work on grounding their connection and making decisions together. When they do, Gemini and Libra can create a beautiful and harmonious partnership that is intellectually and aesthetically fulfilling.',
  pisces:
    'Gemini and Pisces create a whimsical and imaginative pairing, blending Gemini’s curiosity with Pisces’s dreamy nature. Both signs are mutable, which means they are adaptable and open to change, making their relationship fluid and ever-evolving. Gemini is enchanted by Pisces’s creativity and compassion, while Pisces is fascinated by Gemini’s intellect and versatility. Together, they can explore the realms of imagination and fantasy, enjoying a relationship that is both intellectually and emotionally enriching. However, their differences can also pose challenges, Gemini’s logical approach can sometimes clash with Pisces’s emotional sensitivity, and Pisces’s need for deep emotional connection may feel overwhelming to the more light-hearted Gemini. Gemini might find Pisces’s dreamy nature impractical, while Pisces could view Gemini’s analytical mindset as lacking in empathy. For their relationship to thrive, both must learn to communicate openly and respect each other’s emotional needs. If they can find a balance, Gemini and Pisces can create a magical and soulful partnership that is full of creativity and compassion.',
  sagittarius:
    'Gemini and Sagittarius create a vibrant and adventurous pairing, both being mutable signs with a love for exploration and discovery. Gemini’s intellectual curiosity matches Sagittarius’s philosophical outlook, making their relationship full of lively debates and shared adventures. They are both free spirits who value independence and variety, which allows them to give each other plenty of space while still enjoying a strong connection. Gemini is captivated by Sagittarius’s optimism and enthusiasm, while Sagittarius is drawn to Gemini’s cleverness and adaptability. However, their mutual love for freedom can sometimes lead to a lack of commitment and stability. Gemini’s tendency to overthink may clash with Sagittarius’s straightforwardness, and Sagittarius’s blunt honesty can sometimes hurt Gemini’s feelings. For a successful partnership, both must learn to balance their need for freedom with the need for a deeper emotional connection. When they do, Gemini and Sagittarius can create a relationship full of excitement, growth, and endless possibilities.',
  scorpio:
    'Gemini and Scorpio form a complex and intriguing partnership, blending Gemini’s light-hearted curiosity with Scorpio’s intense depth. Gemini is fascinated by Scorpio’s mysterious and passionate nature, while Scorpio is intrigued by Gemini’s quick wit and versatility. This relationship is a study in contrasts, with Gemini’s playful approach often clashing with Scorpio’s serious and intense demeanor. Gemini loves to explore new ideas and experiences, while Scorpio seeks deep emotional connections and transformative experiences. The challenge for this pair lies in their differing communication styles, Gemini’s light and breezy manner can sometimes feel shallow to the more intense Scorpio, who craves depth and authenticity. Scorpio may find Gemini’s need for freedom and variety unsettling, while Gemini might feel overwhelmed by Scorpio’s emotional intensity. For their relationship to work, both must learn to respect each other’s differences and find common ground. Together, Gemini and Scorpio can explore the depths of the human experience, balancing light-hearted curiosity with profound emotional insight.',
  taurus:
    'Gemini and Taurus make an intriguing pair, blending the airy, changeable nature of Gemini with the earthy, steady energy of Taurus. Gemini is captivated by Taurus’s sensuality and groundedness, while Taurus is intrigued by Gemini’s intellectual curiosity and adaptability. Taurus provides a stable anchor for Gemini’s restless energy, offering a sense of security that Gemini secretly craves. Meanwhile, Gemini brings a breath of fresh air into Taurus’s life, encouraging them to explore new ideas and experiences. However, conflicts may arise from Taurus’s need for consistency and Gemini’s love of variety. Taurus might find Gemini’s unpredictability unsettling, while Gemini could feel stifled by Taurus’s insistence on routine. For their relationship to flourish, both must embrace each other’s differences, with Taurus learning to be more flexible and Gemini appreciating the beauty of stability.',
  virgo:
    'Gemini and Virgo form an intellectually stimulating pair, combining Gemini’s curiosity and Virgo’s analytical nature. Both are ruled by Mercury, the planet of communication, which makes their conversations rich and insightful. Gemini’s playful and spontaneous nature complements Virgo’s meticulous and thoughtful approach. Virgo appreciates Gemini’s ability to see the bigger picture, while Gemini values Virgo’s attention to detail. However, their differences can also lead to misunderstandings, Virgo’s need for order and precision might clash with Gemini’s more carefree and scattered ways. Gemini may find Virgo’s criticism overly harsh, while Virgo might view Gemini’s lack of consistency as irresponsible. For their relationship to thrive, both must practice patience and understanding, with Gemini learning to appreciate Virgo’s practicality and Virgo embracing Gemini’s spontaneity. When they find balance, Gemini and Virgo can create a harmonious blend of creativity and logic.',
};

const leo = {
  aquarius:
    "Aquarius and Leo exhibit moderate compatibility, balancing Leos individuality with Aquarius' unity, requiring mutual appreciation for uniqueness.",
  aries:
    'Aries and Leo form a strong partnership, combining passion with vitality, resulting in a fiery and enthusiastic connection.',
  cancer:
    'Cancer and Leo exhibit fair harmony, balancing sensitivity with assertiveness, needing mutual support and appreciation.',
  capricorn:
    "Capricorn and Leo form a good match, balancing Leo's ambition with Capricorn's stability, creating a supportive and ambitious partnership.",
  gemini:
    "Capricorn and Leo form a good match, balancing Leo's ambition with Capricorn's stability, creating a supportive and ambitious partnership.",
  leo: 'Leo-Leo compatibility is marked by a strong bond of shared passion and vitality, leading to a dynamic and exciting partnership.',
  libra:
    'Leo and Libra share good understanding, with harmony and shared values, creating a balanced and harmonious relationship.',
  pisces:
    "Leo and Pisces share fair harmony, balancing Leo's creativity with Pisces' intuition, fostering a creative and spiritually fulfilling relationship.",
  sagittarius:
    'Leo and Sagittarius share a very compatible bond, with enthusiasm and optimism, fostering a dynamic and adventurous connection.',
  scorpio:
    "Leo-Scorpio connection exhibits moderate compatibility, balancing Leo's passion with Scorpio's depth, requiring trust and emotional connection.",
  taurus:
    "Taurus and Leo face challenges balancing practicality with passion, needing understanding of each other's approaches for harmony.",
  virgo:
    'Leo and Virgo exhibit fair harmony, balancing confidence with practicality, requiring mutual respect and support.',
};

const leoEnhanced = {
  aquarius:
    "Leo and Aquarius form an exciting and unconventional partnership, combining Leo's fiery passion with Aquarius's innovative and independent nature. Both signs are fixed, which means they are determined and often set in their ways, making their relationship full of strong-willed energy. Leo is fascinated by Aquarius's unique perspective and intellectual curiosity, while Aquarius is drawn to Leo's warmth and charisma. Together, they can create a dynamic and stimulating relationship, full of creativity and new ideas. However, their differences can sometimes lead to tension, Leo's desire for attention and admiration might clash with Aquarius's need for freedom and detachment. Aquarius's unconventional approach can sometimes be too much for Leo's more traditional and expressive nature. For a successful relationship, both must learn to respect each other's differences and embrace each other's uniqueness. When they do, Leo and Aquarius can create a powerful and innovative partnership that is full of excitement and mutual growth.",
  aries:
    "The combination of Leo and Aries is a fiery and dynamic duo, full of passion, energy, and enthusiasm. Both signs are natural leaders and thrive on excitement and adventure. Leo's charisma and warmth complement Aries's boldness and courage, creating a relationship that is full of mutual admiration and respect. They inspire each other to take on challenges and pursue their goals with vigor. Leo loves the spontaneity and daring spirit of Aries, while Aries appreciates Leo’s generosity and flair for life. However, their strong personalities can sometimes lead to power struggles, as both signs like to take the lead. Leo's need for admiration may clash with Aries's independent streak, and their occasional stubbornness can lead to fiery disagreements. For their relationship to succeed, they must learn to share the spotlight and support each other's ambitions. Together, Leo and Aries can create an exhilarating partnership that is both passionate and inspiring.",
  cancer:
    "Leo and Cancer create a warm and nurturing partnership, combining Leo's fiery enthusiasm with Cancer's gentle and caring nature. Leo is drawn to Cancer’s sensitivity and emotional depth, while Cancer is captivated by Leo's confidence and warmth. Together, they can create a loving and supportive relationship, with Leo providing the excitement and passion, and Cancer offering emotional security and comfort. Leo enjoys being the protector and provider, while Cancer nurtures and cares for their partner. However, their differences can sometimes lead to misunderstandings, Leo’s outgoing and sometimes flamboyant nature might clash with Cancer’s more reserved and private approach. Cancer may feel overwhelmed by Leo's need for attention, while Leo could find Cancer's moodiness challenging. For a harmonious relationship, both must learn to respect each other’s emotional landscapes and communicate openly. When they do, Leo and Cancer can create a deep and fulfilling partnership that is full of love and mutual support.",
  capricorn:
    "Leo and Capricorn create an intriguing and powerful partnership, blending Leo's vibrant energy with Capricorn's disciplined and ambitious nature. Leo is attracted to Capricorn's strength and determination, while Capricorn admires Leo's confidence and warmth. Together, they can create a successful and fulfilling relationship, with both partners supporting each other's goals and ambitions. Leo brings a sense of fun and creativity to Capricorn's life, while Capricorn provides the structure and stability that Leo sometimes lacks. However, their different approaches to life can lead to challenges, Leo's need for recognition and admiration might clash with Capricorn's more reserved and practical nature. Capricorn's focus on work and success can sometimes leave Leo feeling neglected, while Leo's desire for spontaneity might disrupt Capricorn's carefully planned routines. For their relationship to thrive, both must learn to appreciate each other's strengths and find a balance between work and play. When they do, Leo and Capricorn can create a powerful and successful partnership that is full of love and mutual respect.",
  gemini:
    "Leo and Gemini form a lively and playful partnership, full of fun, creativity, and excitement. Both signs are social and love to engage in stimulating conversations and activities. Leo's charismatic and outgoing nature complements Gemini's quick wit and versatility. They enjoy a vibrant social life together, with Leo providing warmth and enthusiasm, while Gemini keeps things interesting with their endless curiosity and humor. However, their different needs can sometimes cause friction, Leo's desire for loyalty and admiration may clash with Gemini's love for variety and independence. Gemini might find Leo's need for constant attention overwhelming, while Leo could see Gemini as too inconsistent. For their relationship to thrive, both must work on communication and understanding each other's needs. When they find balance, Leo and Gemini can create a dynamic and joyful partnership that is full of laughter and adventure.",
  leo: 'When two Leos come together, the result is a dazzling and passionate partnership, full of excitement, creativity, and mutual admiration. This pair shares a deep understanding of each other’s needs for attention, appreciation, and recognition. They enjoy being the center of attention and love to celebrate life in grand style. Together, they can create a relationship that is full of warmth, loyalty, and shared interests. However, their strong personalities can sometimes lead to competition and power struggles, as both Leos enjoy being in control. Their need for admiration might also lead to conflicts if they feel neglected or unappreciated. For their relationship to succeed, both must learn to share the spotlight and support each other’s goals and ambitions. When they find a balance, two Leos can create a powerful and vibrant partnership that is full of love, joy, and a shared zest for life.',
  libra:
    "Leo and Libra form a harmonious and charming pair, full of romance, beauty, and social grace. Both signs are ruled by the elements of fire and air, creating a natural chemistry and a mutual appreciation for the finer things in life. Leo's charisma and passion complement Libra's elegance and diplomacy, making them a dynamic and attractive couple. They enjoy a rich social life together, with Leo taking the lead and Libra providing balance and harmony. However, their different needs can sometimes lead to tension, Leo's desire for attention and admiration might clash with Libra's need for peace and harmony. Libra's indecisiveness can frustrate Leo, while Leo's dominance can sometimes overwhelm Libra. For their relationship to thrive, both must work on communication and compromise, respecting each other's needs and boundaries. When they do, Leo and Libra can create a beautiful and loving partnership that is full of romance and mutual respect.",
  pisces:
    "Leo and Pisces create a magical and romantic partnership, blending Leo's vibrant energy with Pisces's dreamy and compassionate nature. Leo is captivated by Pisces's sensitivity and artistic soul, while Pisces is drawn to Leo's confidence and warmth. Together, they can create a loving and supportive relationship, with Leo providing the passion and Pisces offering emotional depth and intuition. Leo enjoys being the protector and hero, while Pisces appreciates the love and admiration they receive from their partner. However, their differences can sometimes lead to challenges, Leo's need for recognition and drama might clash with Pisces's more introspective and gentle nature. Pisces's emotional sensitivity can sometimes feel overwhelming for Leo, while Leo's boldness can be too much for Pisces's delicate sensibilities. For their relationship to thrive, both must learn to communicate openly and respect each other's emotional needs. When they do, Leo and Pisces can create a beautiful and harmonious partnership that is full of love, creativity, and mutual understanding.",
  sagittarius:
    "Leo and Sagittarius create a vibrant and adventurous pairing, full of excitement, passion, and mutual enthusiasm. Both signs are fire elements, making their relationship full of warmth, energy, and a love for life. Leo's charisma and Sagittarius's adventurous spirit complement each other perfectly, creating a dynamic and fun-loving partnership. They enjoy exploring new experiences together, with Leo providing the stability and Sagittarius bringing the excitement. However, their shared love for freedom can sometimes lead to conflicts, as both signs value their independence. Leo's need for loyalty and attention might clash with Sagittarius's desire for freedom and exploration. Sagittarius's blunt honesty can sometimes hurt Leo's pride, while Leo's desire for drama might overwhelm Sagittarius. For a successful relationship, both must learn to respect each other's need for space and independence. When they do, Leo and Sagittarius can create a joyful and adventurous partnership that is full of love and shared experiences.",
  scorpio:
    "Leo and Scorpio create an intense and magnetic partnership, combining Leo's fiery passion with Scorpio's deep emotional intensity. Both signs are strong-willed and determined, making their relationship full of power and intrigue. Leo is captivated by Scorpio's mysterious and alluring nature, while Scorpio is drawn to Leo's confidence and warmth. Together, they can create a passionate and transformative relationship, with both partners pushing each other to new heights. However, their strong personalities can also lead to power struggles and jealousy, as both Leo and Scorpio have a desire for control and loyalty. Leo's need for admiration might clash with Scorpio's possessiveness, and Scorpio's intense emotions can sometimes be overwhelming for Leo. For their relationship to succeed, both must learn to trust each other and communicate openly. When they find a balance, Leo and Scorpio can create a powerful and passionate partnership that is full of depth and mutual respect.",
  taurus:
    "Leo and Taurus create a luxurious and sensual pairing, blending Leo's love for grandeur and drama with Taurus's appreciation for beauty and comfort. Leo is drawn to Taurus's sensuality and reliability, while Taurus admires Leo’s confidence and zest for life. They both enjoy the finer things in life and can build a stable and enjoyable partnership. Leo brings excitement and a touch of glamour to the relationship, while Taurus provides a steady and grounding influence. However, their differences can lead to challenges, Leo’s desire for attention and admiration might clash with Taurus’s more reserved nature, and Taurus’s stubbornness can conflict with Leo’s need for adoration. Leo may find Taurus's pace too slow, while Taurus could see Leo as overly dramatic. For a successful relationship, they must learn to appreciate each other's strengths and compromise. When they do, Leo and Taurus can create a harmonious and indulgent partnership that is full of love and affection.",
  virgo:
    "Leo and Virgo form a complementary and balanced partnership, blending Leo's vibrant energy with Virgo's practical and analytical nature. Leo is attracted to Virgo’s intelligence and attention to detail, while Virgo admires Leo’s confidence and warmth. Together, they can create a harmonious relationship where Leo inspires Virgo to be more expressive and adventurous, and Virgo provides Leo with the grounding and organization they sometimes lack. However, their different approaches to life can lead to challenges, Leo’s need for recognition and affection might clash with Virgo’s more reserved and modest nature. Virgo’s critical nature can sometimes hurt Leo’s pride, while Leo’s flamboyance may overwhelm the more understated Virgo. For a successful relationship, both must learn to appreciate each other’s differences and find common ground. When they do, Leo and Virgo can create a stable and enriching partnership that balances creativity with practicality.",
};

const libra = {
  aquarius:
    'Aquarius and Libra share very compatible traits, with social grace and ideals, fostering a harmonious and progressive partnership.',
  aries:
    "Aries-Libra compatibility is moderate, balancing Aries' assertiveness with Libra's diplomacy, needing mutual respect and compromise.",
  cancer:
    'Cancer-Libra compatibility is challenging, balancing emotions with diplomacy, requiring understanding and communication.',
  capricorn:
    "Capricorn and Libra exhibit fair harmony, balancing Libra's diplomacy with Capricorn's ambition, requiring mutual respect and compromise.",
  gemini:
    'Gemini and Libra share very compatible traits, with lively communication and harmony, creating a balanced and harmonious relationship.',
  leo: 'Leo and Libra share good understanding, with harmony and shared values, creating a balanced and harmonious relationship.',
  libra:
    'Libra-Libra compatibility is marked by a strong bond of shared social grace and harmony, creating a balanced and sociable relationship.',
  pisces:
    'Libra and Pisces face challenges balancing emotion with empathy, requiring understanding and empathy for a fulfilling bond.',
  sagittarius:
    'Libra and Sagittarius share a strong partnership, with shared social ideals and optimism, creating an enthusiastic and supportive bond.',
  scorpio:
    'Libra and Scorpio exhibit moderate connection, balancing trust with diplomacy, fostering growth through understanding and communication.',
  taurus:
    'Taurus and Libra share moderate compatibility, blending tradition with novelty, needing balance and appreciation of uniqueness.',
  virgo:
    "Virgo and Libra exhibit moderate compatibility, balancing Virgo's order with Libra's harmony, requiring mutual understanding and compromise.",
};

const libraEnhanced = {
  aquarius:
    "Libra and Aquarius form an intellectually stimulating and socially dynamic pair, both being air signs with a love for communication, ideas, and social interaction. Libra's charm and diplomacy complement Aquarius's innovative and independent nature, making their relationship full of lively discussions and shared interests. They enjoy a rich social life together, with Libra appreciating Aquarius's unique perspective and humanitarian outlook, and Aquarius admiring Libra's grace and ability to bring people together. However, their shared love for variety and independence can sometimes lead to a lack of emotional depth and commitment. Libra's desire for harmony and partnership might clash with Aquarius's need for freedom and detachment. For a successful relationship, both must work on deepening their emotional connection and finding a balance between independence and intimacy. When they do, Libra and Aquarius can create a dynamic and intellectually fulfilling partnership that is full of love, mutual respect, and shared ideals.",
  aries:
    "Libra and Aries make an exciting and dynamic pair, blending Libra's charm and diplomacy with Aries's boldness and passion. As opposite signs, they are naturally attracted to each other, with Libra being drawn to Aries's confidence and Aries appreciating Libra's grace and social skills. Together, they create a balanced partnership, with Libra's tact smoothing out Aries's impulsive edges and Aries bringing excitement and initiative to Libra's life. However, their differences can also lead to friction, Libra's indecisiveness might frustrate the action-oriented Aries, while Aries's directness can sometimes be too harsh for the peace-loving Libra. For their relationship to thrive, they must learn to communicate openly and respect each other's differing approaches. When they do, Libra and Aries can create a harmonious and dynamic partnership that combines diplomacy with action.",
  cancer:
    "Libra and Cancer create a warm and nurturing partnership, blending Libra's social grace with Cancer's emotional depth. Libra is drawn to Cancer's sensitivity and caring nature, while Cancer appreciates Libra's diplomacy and charm. Together, they can create a harmonious and loving relationship, with Libra providing a sense of balance and Cancer offering emotional security. Libra enjoys the comfort and nurturing environment that Cancer creates, while Cancer appreciates Libra's ability to bring harmony and peace to their life. However, their differences can sometimes lead to misunderstandings, Libra's need for social interaction may clash with Cancer's desire for a more private and intimate connection. Cancer's moodiness can be challenging for Libra, who seeks harmony and balance. For their relationship to succeed, they must learn to communicate openly and respect each other's emotional needs. When they do, Libra and Cancer can create a deep and fulfilling partnership that is full of love and mutual understanding.",
  capricorn:
    "Libra and Capricorn create a refined and ambitious partnership, blending Libra's charm and social grace with Capricorn's discipline and determination. Libra is attracted to Capricorn's strength and reliability, while Capricorn admires Libra's elegance and ability to connect with others. Together, they can create a successful and harmonious relationship, with Libra bringing a touch of beauty and diplomacy to Capricorn's life, and Capricorn providing structure and stability. However, their different approaches to life can sometimes lead to challenges, Libra's love for socializing and indecisiveness might clash with Capricorn's focus on work and practicality. Capricorn's seriousness can sometimes dampen Libra's light-heartedness, while Libra's desire for a luxurious lifestyle may conflict with Capricorn's more conservative approach to finances. For their relationship to thrive, both must learn to appreciate each other's strengths and find common ground. When they do, Libra and Capricorn can create a balanced and fulfilling partnership that combines beauty with ambition.",
  gemini:
    "Libra and Gemini make an intellectually stimulating and socially dynamic pair, both being air signs with a love for communication and exploration. Libra's diplomatic nature and Gemini's quick wit create a relationship full of lively conversations and shared interests. They enjoy a rich social life together, attending cultural events and engaging in intellectual discussions. Libra appreciates Gemini's versatility and humor, while Gemini admires Libra's grace and charm. However, their shared love for variety and socializing can sometimes lead to a lack of depth and commitment. Libra's desire for harmony may clash with Gemini's love for debate, and Gemini's unpredictability can unsettle Libra's need for balance. For their relationship to thrive, they must work on deepening their emotional connection and finding common ground. When they do, Libra and Gemini can create a vibrant and intellectually fulfilling partnership that is full of laughter and shared experiences.",
  leo: "Libra and Leo form a glamorous and charismatic pair, full of passion, creativity, and mutual admiration. Both signs enjoy being in the spotlight and have a natural flair for drama and elegance. Libra's charm and diplomacy complement Leo's confidence and warmth, making them a socially dynamic and attractive couple. They enjoy a rich social life together, attending parties, cultural events, and enjoying the finer things in life. Libra appreciates Leo's generosity and enthusiasm, while Leo admires Libra's grace and intelligence. However, their strong personalities can sometimes lead to power struggles, Leo's need for admiration might clash with Libra's desire for equality and fairness. Libra's indecisiveness can frustrate Leo, who prefers decisive action. For their relationship to thrive, both must learn to share the spotlight and support each other's strengths. When they do, Libra and Leo can create a vibrant and loving partnership that is full of romance and mutual respect.",
  libra:
    "When two Libras come together, the result is a harmonious and balanced relationship, full of beauty, grace, and mutual respect. Both partners share a love for art, culture, and socializing, making their time together full of stimulating conversations and enjoyable experiences. They understand each other's need for harmony and are skilled at navigating conflicts with diplomacy and tact. This pairing enjoys a peaceful and aesthetically pleasing relationship, with both partners appreciating each other's charm and elegance. However, their shared tendency to avoid conflict can sometimes lead to issues being swept under the rug, causing tension to build up over time. Additionally, their indecisiveness can make it challenging to make decisions, as both partners may defer to the other. For their relationship to thrive, they must work on being more assertive and addressing issues as they arise. When they do, two Libras can create a beautiful and loving partnership that is full of mutual admiration and respect.",
  pisces:
    "Libra and Pisces create a dreamy and romantic partnership, blending Libra's charm and social grace with Pisces's sensitivity and compassion. Libra is captivated by Pisces's artistic and intuitive nature, while Pisces appreciates Libra's elegance and diplomacy. Together, they can create a loving and harmonious relationship, with Libra providing balance and structure, and Pisces offering emotional depth and creativity. However, their differences can sometimes lead to challenges, Libra's need for social interaction and balance might clash with Pisces's introspective and emotional nature. Pisces's tendency to escape into fantasy can sometimes be confusing for Libra, who seeks harmony and clarity. For their relationship to thrive, both must learn to communicate openly and respect each other's emotional needs. When they do, Libra and Pisces can create a beautiful and compassionate partnership that is full of love, creativity, and mutual understanding.",
  sagittarius:
    "Libra and Sagittarius form a lively and adventurous pair, full of excitement, optimism, and a love for exploration. Libra's social grace and diplomacy complement Sagittarius's free-spirited and adventurous nature, making their relationship full of fun and new experiences. They enjoy a rich social life together, with Libra appreciating Sagittarius's honesty and enthusiasm, and Sagittarius admiring Libra's charm and ability to navigate social situations. However, their different needs can sometimes lead to conflicts, Libra's desire for harmony and partnership might clash with Sagittarius's need for independence and freedom. Sagittarius's bluntness can sometimes hurt Libra's sensitive feelings, while Libra's indecisiveness can frustrate the straightforward Sagittarius. For a successful relationship, both must learn to respect each other's need for space and independence while also nurturing their connection. When they do, Libra and Sagittarius can create a joyful and adventurous partnership that is full of love, growth, and shared experiences.",
  scorpio:
    "Libra and Scorpio create a complex and intriguing partnership, blending Libra's charm and diplomacy with Scorpio's intensity and depth. Libra is fascinated by Scorpio's mysterious and passionate nature, while Scorpio is drawn to Libra's grace and social ease. Together, they can create a powerful and magnetic relationship, with Libra providing balance and harmony, and Scorpio offering emotional depth and transformation. However, their differences can sometimes lead to challenges, Libra's desire for peace and avoidance of conflict might clash with Scorpio's intense emotions and need for honesty. Scorpio's possessiveness can feel stifling to Libra, who values freedom and social interaction. For their relationship to succeed, both must learn to communicate openly and respect each other's emotional landscapes. When they find a balance, Libra and Scorpio can create a transformative and deeply fulfilling partnership that combines beauty with intensity.",
  taurus:
    "Libra and Taurus form a harmonious and aesthetically pleasing pairing, both ruled by Venus, the planet of love and beauty. This connection creates a shared appreciation for the finer things in life, such as art, music, and luxurious surroundings. Libra's social grace and charm complement Taurus's steady and reliable nature, making for a stable and loving relationship. Taurus provides Libra with a sense of security and grounding, while Libra brings a touch of elegance and social flair to Taurus's life. However, challenges may arise from their differing pace and decision-making styles, Taurus's stubbornness can clash with Libra's indecisiveness, and Libra's need for social interaction might feel overwhelming to the more introverted Taurus. For a successful partnership, they must find a balance between Taurus's need for stability and Libra's love for variety. Together, Libra and Taurus can create a beautiful and harmonious relationship filled with love and mutual appreciation.",
  virgo:
    "Libra and Virgo create a thoughtful and balanced partnership, blending Libra's love for harmony with Virgo's attention to detail and practicality. Libra is drawn to Virgo's intelligence and reliability, while Virgo appreciates Libra's charm and social grace. Together, they can create a harmonious and stable relationship, with Libra bringing a sense of beauty and elegance to Virgo's life, and Virgo providing structure and order. However, their differing approaches to life can sometimes lead to challenges, Libra's indecisiveness and desire for social interaction might clash with Virgo's need for precision and routine. Virgo's critical nature can sometimes hurt Libra's sensitive feelings, while Libra's tendency to avoid conflict can frustrate the more straightforward Virgo. For a successful relationship, both must learn to communicate openly and appreciate each other's strengths. When they do, Libra and Virgo can create a balanced and enriching partnership that combines practicality with beauty.",
};

const pisces = {
  aquarius:
    "Aquarius and Pisces exhibit fair compatibility, balancing Aquarius' idealism with Pisces' empathy, fostering understanding and mutual growth.",
  aries:
    "Aries-Pisces connection is moderate, combining Aries' drive with Pisces' dreaminess, requiring understanding of each other's realities.",
  cancer:
    'Cancer and Pisces share a strong connection, with a nurturing and intuitive bond, creating a compassionate and spiritually fulfilling relationship.',
  capricorn:
    'Capricorn and Pisces face challenges due to differences in practicality and empathy, requiring understanding and compromise.',
  gemini:
    'Gemini and Pisces face challenges due to differences in practicality and imagination, requiring compromise and creative solutions.',
  leo: "Leo and Pisces share fair harmony, balancing Leo's creativity with Pisces' intuition, fostering a creative and spiritually fulfilling relationship.",
  libra:
    'Libra and Pisces face challenges balancing emotion with empathy, requiring understanding and empathy for a fulfilling bond.',
  pisces:
    'Pisces-Pisces compatibility is marked by a strong bond of shared sensitivity and imagination, fostering a deep and spiritually fulfilling connection.',
  sagittarius:
    "Sagittarius and Pisces exhibit moderate compatibility, blending Sagittarius' exploration with Pisces' sensitivity, requiring mutual understanding and adaptation.",
  scorpio:
    'Scorpio and Pisces share a very compatible bond, with emotional depth and intuition, fostering a profound and spiritually fulfilling connection.',
  taurus:
    'Taurus and Pisces face challenges due to differences in practicality and sensitivity, requiring understanding and compromise.',
  virgo:
    'Virgo and Pisces exhibit moderate connection, balancing pragmatism with sensitivity, requiring mutual understanding and empathy.',
};

const piscesEnhanced = {
  aquarius:
    'Pisces and Aquarius create an imaginative and unconventional pair, blending Pisces’s dreamy and compassionate nature with Aquarius’s innovative and independent spirit. Both signs are open-minded and enjoy exploring new ideas and possibilities, making their relationship full of creativity and excitement. Pisces is fascinated by Aquarius’s unique perspective and humanitarian ideals, while Aquarius appreciates Pisces’s empathy and artistic sensibilities. Together, they can create a visionary and inspiring partnership, with Aquarius encouraging Pisces to think outside the box and Pisces helping Aquarius connect with their emotions. However, their differences can sometimes lead to challenges, Pisces’s need for emotional connection may clash with Aquarius’s desire for freedom and detachment. Aquarius’s intellectual approach can sometimes feel distant to Pisces, who craves emotional depth and intimacy. For their relationship to succeed, both must learn to balance their need for independence with their desire for connection. When they do, Pisces and Aquarius can create a magical and forward-thinking partnership that is full of love, creativity, and mutual respect.',
  aries:
    "Pisces and Aries create a unique blend of water and fire, combining Pisces’s dreamy and empathetic nature with Aries’s bold and energetic personality. Pisces is drawn to Aries's confidence and passion, while Aries is intrigued by Pisces’s creativity and compassion. Together, they can inspire each other in unexpected ways, with Aries pushing Pisces to take bold actions and Pisces encouraging Aries to explore their emotional depth. However, their differences can also lead to challenges, Pisces's sensitivity may clash with Aries's bluntness, and Aries’s impulsive nature might overwhelm Pisces's need for emotional security. Pisces may find Aries too aggressive, while Aries could view Pisces as too passive. For their relationship to thrive, both must learn to balance Aries's fiery enthusiasm with Pisces’s gentle intuition. When they do, Pisces and Aries can create a dynamic and imaginative partnership that is full of adventure and emotional growth.",
  cancer:
    'Pisces and Cancer create a deeply emotional and intuitive partnership, blending Pisces’s compassionate nature with Cancer’s nurturing and protective instincts. Both signs are highly empathetic and value emotional connection, making their relationship full of understanding and support. Pisces and Cancer share a natural affinity for each other, with Pisces appreciating Cancer’s loyalty and care, and Cancer finding comfort in Pisces’s kindness and sensitivity. Together, they can create a loving and harmonious home, filled with warmth and creativity. However, their shared emotional depth can sometimes lead to mood swings and over-sensitivity, causing them to retreat into their own worlds. Pisces may struggle with Cancer’s occasional clinginess, while Cancer could find Pisces’s escapist tendencies frustrating. For a successful relationship, both must learn to communicate openly and support each other’s emotional needs. When they do, Pisces and Cancer can create a beautiful and soulful partnership that is full of love, compassion, and mutual understanding.',
  capricorn:
    'Pisces and Capricorn form an intriguing and balanced partnership, blending Pisces’s intuitive and imaginative nature with Capricorn’s disciplined and practical approach. Despite their differences, they can create a stable and nurturing relationship, with Capricorn providing structure and security, and Pisces offering emotional insight and creativity. Pisces is inspired by Capricorn’s ambition and dedication, while Capricorn appreciates Pisces’s compassion and artistic talents. Together, they can create a harmonious and supportive bond, with Capricorn helping Pisces bring their dreams to life and Pisces encouraging Capricorn to embrace their emotional side. However, their differing approaches to life can sometimes lead to challenges, Pisces’s dreamy and idealistic nature may clash with Capricorn’s more grounded and realistic outlook. Capricorn’s focus on work and success can sometimes leave Pisces feeling neglected, while Pisces’s emotional depth can be overwhelming for Capricorn. For their relationship to thrive, both must learn to communicate openly and appreciate each other’s strengths. When they do, Pisces and Capricorn can create a beautiful and balanced partnership that combines creativity with practicality.',
  gemini:
    "Pisces and Gemini make an intriguing and ever-changing pair, combining Pisces’s intuitive and emotional nature with Gemini's intellectual and adaptable personality. Both signs are curious and enjoy exploring new ideas and experiences, making their relationship full of excitement and variety. Pisces is fascinated by Gemini's wit and versatility, while Gemini appreciates Pisces’s creativity and depth. Together, they can inspire each other to see the world from different perspectives. However, their differences can also lead to challenges, Pisces's need for emotional connection may clash with Gemini's love for mental stimulation, and Gemini's inconsistency can leave Pisces feeling insecure. Pisces may find Gemini too superficial, while Gemini could see Pisces as too emotional. For their relationship to thrive, both must work on communication and understanding each other's needs. When they do, Pisces and Gemini can create a magical and dynamic partnership that is full of imagination and intellectual exploration.",
  leo: "Pisces and Leo create a captivating and romantic pairing, blending Pisces’s dreamy and compassionate nature with Leo's vibrant and charismatic personality. Pisces is enchanted by Leo’s warmth and generosity, while Leo is fascinated by Pisces’s creativity and emotional depth. Together, they can create a magical and theatrical relationship, with Leo providing the spotlight and Pisces offering a sense of mystery and fantasy. However, their differences can sometimes lead to challenges, Pisces's introspective and sensitive nature may clash with Leo’s outgoing and confident demeanor. Leo’s need for attention might overwhelm Pisces, who prefers a quieter and more introspective approach to life. Pisces may find Leo too demanding, while Leo could see Pisces as too elusive. For their relationship to thrive, both must learn to respect each other’s needs and find a balance between Leo’s need for recognition and Pisces’s need for emotional connection. When they do, Pisces and Leo can create a beautiful and enchanting partnership that is full of love and artistic expression.",
  libra:
    'Pisces and Libra create a charming and romantic pairing, blending Pisces’s dreamy and compassionate nature with Libra’s grace and diplomacy. Both signs value harmony, beauty, and emotional connection, making their relationship full of romance and mutual appreciation. Pisces is captivated by Libra’s charm and elegance, while Libra is enchanted by Pisces’s creativity and sensitivity. Together, they can create a loving and aesthetically pleasing relationship, filled with art, music, and cultural experiences. However, their differences can sometimes lead to challenges, Pisces’s emotional depth may clash with Libra’s more rational and balanced approach, and Libra’s indecisiveness can frustrate the more intuitive Pisces. Pisces may find Libra too superficial, while Libra could see Pisces as too emotional. For their relationship to thrive, both must learn to communicate openly and respect each other’s emotional needs. When they do, Pisces and Libra can create a beautiful and harmonious partnership that is full of love, creativity, and mutual understanding.',
  pisces:
    'When two Pisces come together, the result is a deeply empathetic and intuitive partnership, full of emotional depth and understanding. Both partners share a natural affinity for each other, valuing compassion, creativity, and spirituality. Together, they create a nurturing and dreamy relationship, with both partners providing emotional support and inspiration. Pisces and Pisces enjoy exploring their shared interests in art, music, and the mystical aspects of life, creating a magical and imaginative world together. However, their shared sensitivity and emotional depth can sometimes lead to challenges, both partners may struggle with setting boundaries and dealing with practical matters. Their tendency to avoid conflict can result in issues being ignored, causing tension to build up over time. For their relationship to thrive, both must learn to communicate openly and face challenges together. When they do, two Pisces can create a beautiful and compassionate partnership that is full of love, creativity, and mutual understanding.',
  sagittarius:
    'Pisces and Sagittarius create a whimsical and adventurous pair, blending Pisces’s dreamy and compassionate nature with Sagittarius’s free-spirited and optimistic personality. Both signs love to explore new ideas and experiences, making their relationship full of excitement and discovery. Pisces is inspired by Sagittarius’s enthusiasm and love for life, while Sagittarius appreciates Pisces’s creativity and emotional depth. Together, they can create a vibrant and imaginative relationship, full of shared dreams and adventures. However, their differences can sometimes lead to challenges, Pisces’s need for emotional connection may clash with Sagittarius’s love for independence and freedom. Sagittarius’s blunt honesty can sometimes hurt Pisces’s sensitive feelings, while Pisces’s introspective nature can feel limiting to Sagittarius. For a successful relationship, both must learn to respect each other’s need for space and emotional depth. When they do, Pisces and Sagittarius can create a joyful and adventurous partnership that is full of love, growth, and mutual exploration.',
  scorpio:
    'Pisces and Scorpio create a deeply passionate and transformative partnership, blending Pisces’s empathetic and intuitive nature with Scorpio’s intense and mysterious personality. Both signs are highly emotional and value deep connections, making their relationship full of depth and intensity. Pisces is drawn to Scorpio’s strength and magnetism, while Scorpio is captivated by Pisces’s compassion and sensitivity. Together, they can create a powerful and soulful bond, with Scorpio providing protection and Pisces offering emotional healing. However, their shared emotional intensity can sometimes lead to power struggles and misunderstandings. Pisces’s need for peace and escapism may clash with Scorpio’s need for control and honesty. Scorpio’s possessiveness can feel overwhelming to Pisces, who values freedom and compassion. For their relationship to succeed, both must learn to trust each other and communicate openly. When they do, Pisces and Scorpio can create a profound and passionate partnership that is full of love, transformation, and mutual understanding.',
  taurus:
    "Pisces and Taurus form a harmonious and romantic partnership, blending Pisces’s emotional depth with Taurus's sensual and grounded nature. Both signs value comfort, beauty, and emotional connection, creating a relationship full of warmth and affection. Pisces appreciates Taurus's stability and loyalty, while Taurus is enchanted by Pisces’s creativity and compassion. Together, they create a nurturing and supportive environment, with Taurus providing security and Pisces offering emotional insight. However, their differences can sometimes cause friction, Taurus's practicality might clash with Pisces’s dreamy nature, and Pisces's fluctuating emotions can be unsettling for the steady Taurus. Taurus may find Pisces too idealistic, while Pisces could see Taurus as too stubborn. For a successful relationship, both must learn to appreciate each other's strengths and find a balance between reality and fantasy. When they do, Pisces and Taurus can create a beautiful and loving partnership that is full of shared dreams and stability.",
  virgo:
    'Pisces and Virgo form a complementary and balanced partnership, blending Pisces’s intuitive and imaginative nature with Virgo’s practical and analytical approach. Despite being opposite signs, they share a deep connection and can learn much from each other. Pisces is inspired by Virgo’s attention to detail and dedication, while Virgo appreciates Pisces’s creativity and emotional sensitivity. Together, they can create a harmonious and supportive relationship, with Virgo providing structure and Pisces offering emotional insight. However, their differences can also lead to challenges, Pisces’s dreamy nature may clash with Virgo’s need for order and precision, and Virgo’s critical approach can sometimes hurt Pisces’s sensitive feelings. Pisces may find Virgo too rigid, while Virgo could see Pisces as too chaotic. For a successful relationship, both must learn to communicate openly and appreciate each other’s strengths. When they do, Pisces and Virgo can create a nurturing and balanced partnership that combines imagination with practicality.',
};

const sagittarius = {
  aquarius:
    "Aquarius and Sagittarius exhibit a strong partnership, balancing Sagittarius' independence with Aquarius' unity, creating a progressive and harmonious bond.",
  aries:
    'Aries and Sagittarius share a very harmonious bond, blending adventurous traits for an optimistic and enthusiastic partnership.',
  cancer:
    'Cancer and Sagittarius exhibit moderate compatibility, blending emotion with optimism, needing balance between depth and lightness.',
  capricorn:
    "Sagittarius and Capricorn share good compatibility, balancing Sagittarius' freedom with Capricorn's ambition, fostering growth through balance and support.",
  gemini:
    'Gemini and Sagittarius share a strong connection, with intellectual and social synergy, creating a stimulating and fulfilling partnership.',
  leo: 'Leo and Sagittarius share a very compatible bond, with enthusiasm and optimism, fostering a dynamic and adventurous connection.',
  libra:
    'Libra and Sagittarius share a strong partnership, with shared social ideals and optimism, creating an enthusiastic and supportive bond.',
  pisces:
    "Sagittarius and Pisces exhibit moderate compatibility, blending Sagittarius' exploration with Pisces' sensitivity, requiring mutual understanding and adaptation.",
  sagittarius:
    'Sagittarius-Sagittarius compatibility is marked by a strong connection, sharing enthusiasm and an adventurous spirit, creating an exciting and dynamic relationship.',
  scorpio:
    'Scorpio and Sagittarius face challenges balancing intensity with freedom, needing compromise and understanding.',
  taurus:
    'Taurus and Sagittarius exhibit moderate connection, blending security with exploration, needing balance between stability and adventure.',
  virgo:
    'Virgo and Sagittarius face challenges balancing detail with spontaneity, needing compromise and adaptability for growth.',
};

const sagittariusEnhanced = {
  aquarius:
    "Sagittarius and Aquarius form an intellectually stimulating and free-spirited pair, both being signs that value independence, exploration, and a love for new ideas. Sagittarius's adventurous nature complements Aquarius's innovative and unconventional approach, making their relationship full of excitement and intellectual growth. They enjoy exploring new concepts, cultures, and experiences together, always seeking to expand their horizons. Sagittarius appreciates Aquarius's unique perspective and humanitarian ideals, while Aquarius admires Sagittarius's optimism and sense of humor. However, their shared love for freedom and independence can sometimes lead to a lack of emotional depth and commitment. Sagittarius's blunt honesty can sometimes clash with Aquarius's more detached and analytical nature. For their relationship to thrive, both must learn to communicate openly and respect each other's need for space and individuality. When they do, Sagittarius and Aquarius can create a vibrant and intellectually fulfilling partnership that is full of love, creativity, and mutual respect.",
  aries:
    "Sagittarius and Aries create an adventurous and fiery partnership, blending Sagittarius's love for exploration and philosophy with Aries's boldness and enthusiasm. Both signs share a passion for life and a desire for excitement, making their relationship dynamic and full of energy. Sagittarius appreciates Aries's courage and spontaneity, while Aries admires Sagittarius's optimism and sense of humor. Together, they can embark on thrilling adventures and enjoy a vibrant social life. However, their impulsive natures can sometimes lead to conflicts, as both tend to act without considering the consequences. Sagittarius's love for freedom may clash with Aries's occasional possessiveness, and Aries's impatience can sometimes overwhelm Sagittarius. For their relationship to thrive, both must learn to balance their fiery impulses with a bit of patience and understanding. When they do, Sagittarius and Aries can create a passionate and exhilarating partnership that is full of growth and discovery.",
  cancer:
    "Sagittarius and Cancer create an interesting and sometimes challenging partnership, blending Sagittarius's adventurous spirit with Cancer's nurturing and emotional nature. Sagittarius is attracted to Cancer's depth and sensitivity, while Cancer is intrigued by Sagittarius's optimism and zest for life. Together, they can learn much from each other, with Sagittarius introducing Cancer to new experiences and Cancer offering emotional support and stability. However, their differences can sometimes lead to misunderstandings, Sagittarius's need for freedom and exploration can clash with Cancer's desire for security and intimacy. Cancer may find Sagittarius too detached and insensitive, while Sagittarius could see Cancer as too clingy and emotional. For a successful relationship, both must learn to communicate openly and respect each other's needs. When they do, Sagittarius and Cancer can create a balanced and enriching partnership that combines adventure with emotional depth.",
  capricorn:
    "Sagittarius and Capricorn form an intriguing and complementary pair, blending Sagittarius's adventurous and optimistic nature with Capricorn's disciplined and practical approach. Sagittarius is inspired by Capricorn's ambition and determination, while Capricorn appreciates Sagittarius's enthusiasm and love for life. Together, they can create a balanced and successful relationship, with Sagittarius encouraging Capricorn to embrace new experiences and Capricorn providing structure and stability. However, their differences can sometimes lead to challenges, Sagittarius's love for freedom and spontaneity may clash with Capricorn's need for order and planning. Capricorn may find Sagittarius too reckless and irresponsible, while Sagittarius could see Capricorn as too serious and restrictive. For a successful relationship, both must learn to appreciate each other's strengths and find a balance between adventure and responsibility. When they do, Sagittarius and Capricorn can create a dynamic and enriching partnership that combines excitement with practicality.",
  gemini:
    "Sagittarius and Gemini make a lively and intellectually stimulating pair, both being mutable signs that thrive on variety and change. Sagittarius's love for adventure and philosophical exploration complements Gemini's curiosity and love for communication. Together, they can enjoy a relationship full of lively discussions, shared interests, and spontaneous adventures. Sagittarius appreciates Gemini's wit and versatility, while Gemini admires Sagittarius's optimism and sense of humor. However, their shared love for freedom and variety can sometimes lead to a lack of stability and commitment. Sagittarius's blunt honesty can sometimes hurt Gemini's sensitive feelings, while Gemini's inconsistency can frustrate Sagittarius. For their relationship to thrive, both must work on deepening their emotional connection and finding ways to ground their dynamic energy. When they do, Sagittarius and Gemini can create a vibrant and exciting partnership that is full of intellectual growth and shared experiences.",
  leo: "Sagittarius and Leo form a vibrant and passionate pair, both being fire signs that thrive on excitement, adventure, and a love for life. Sagittarius's adventurous nature and philosophical outlook complement Leo's confidence and charisma, making their relationship full of passion, creativity, and mutual admiration. They enjoy a rich social life together, attending parties, exploring new places, and engaging in lively discussions. Sagittarius appreciates Leo's warmth and generosity, while Leo admires Sagittarius's optimism and humor. However, their strong personalities can sometimes lead to power struggles and competition. Sagittarius's blunt honesty can sometimes hurt Leo's pride, while Leo's need for attention and recognition can feel overwhelming to Sagittarius. For their relationship to thrive, both must learn to share the spotlight and appreciate each other's strengths. When they do, Sagittarius and Leo can create a dynamic and exciting partnership that is full of love, creativity, and shared adventures.",
  libra:
    "Sagittarius and Libra create a harmonious and intellectually stimulating pair, blending Sagittarius's adventurous spirit with Libra's love for beauty and harmony. Both signs enjoy socializing, exploring new ideas, and engaging in lively discussions, making their relationship full of excitement and mutual admiration. Sagittarius is drawn to Libra's charm and diplomacy, while Libra appreciates Sagittarius's optimism and sense of humor. Together, they can create a vibrant and balanced relationship, with Sagittarius introducing Libra to new adventures and Libra providing a sense of balance and harmony. However, their differences can sometimes lead to challenges, Sagittarius's blunt honesty may clash with Libra's desire for peace and harmony, and Libra's indecisiveness can frustrate the more straightforward Sagittarius. For their relationship to thrive, both must learn to communicate openly and respect each other's needs for adventure and balance. When they do, Sagittarius and Libra can create a beautiful and intellectually fulfilling partnership that is full of love, creativity, and mutual respect.",
  pisces:
    "Sagittarius and Pisces create a whimsical and adventurous partnership, blending Sagittarius's love for exploration and philosophical nature with Pisces's dreamy and compassionate personality. Both signs are open-minded and enjoy exploring new ideas, making their relationship full of creativity and shared dreams. Sagittarius is inspired by Pisces's empathy and artistic sensibilities, while Pisces appreciates Sagittarius's optimism and zest for life. Together, they can create a magical and imaginative relationship, with Sagittarius encouraging Pisces to embrace new experiences and Pisces helping Sagittarius connect with their emotions. However, their differences can sometimes lead to challenges, Sagittarius's blunt honesty may clash with Pisces's sensitivity, and Pisces's introspective nature can feel limiting to Sagittarius's need for freedom. For a successful relationship, both must learn to communicate openly and respect each other's emotional needs. When they do, Sagittarius and Pisces can create a joyful and adventurous partnership that is full of love, growth, and mutual exploration.",
  sagittarius:
    "When two Sagittarians come together, the result is an adventurous and free-spirited partnership, full of excitement, optimism, and a love for exploration. Both partners share a passion for travel, new experiences, and philosophical discussions, making their relationship dynamic and ever-changing. They enjoy a vibrant social life together, exploring new cultures, engaging in stimulating conversations, and laughing at life's absurdities. However, their shared love for freedom and independence can sometimes lead to a lack of commitment and stability. Both partners may struggle with routine and responsibility, preferring to focus on the next adventure rather than the practical aspects of life. For their relationship to thrive, they must learn to balance their need for freedom with a sense of commitment and mutual support. When they do, two Sagittarians can create a joyful and adventurous partnership that is full of love, growth, and endless possibilities.",
  scorpio:
    "Sagittarius and Scorpio create a powerful and intense partnership, blending Sagittarius's adventurous and philosophical nature with Scorpio's passionate and mysterious personality. Sagittarius is fascinated by Scorpio's depth and intensity, while Scorpio is intrigued by Sagittarius's optimism and love for exploration. Together, they can create a transformative and exciting relationship, with Sagittarius encouraging Scorpio to embrace new experiences and Scorpio helping Sagittarius explore their emotional depth. However, their differences can sometimes lead to challenges, Sagittarius's love for freedom and independence may clash with Scorpio's need for control and emotional intimacy. Scorpio's possessiveness can feel stifling to Sagittarius, who values freedom and adventure. For a successful relationship, both must learn to trust each other and communicate openly. When they do, Sagittarius and Scorpio can create a powerful and passionate partnership that is full of growth, transformation, and mutual understanding.",
  taurus:
    "Sagittarius and Taurus form an intriguing and contrasting pair, blending Sagittarius's love for adventure and change with Taurus's love for stability and comfort. Sagittarius is drawn to Taurus's grounded and sensual nature, while Taurus is intrigued by Sagittarius's optimism and zest for life. Together, they can create a relationship that balances excitement with security, with Sagittarius introducing Taurus to new experiences and Taurus providing a sense of stability and loyalty. However, their differences can sometimes lead to friction, Sagittarius's need for freedom and exploration can clash with Taurus's desire for routine and predictability. Taurus may find Sagittarius too restless and inconsistent, while Sagittarius could see Taurus as too possessive and resistant to change. For a successful relationship, both must learn to appreciate each other's strengths and find common ground. When they do, Sagittarius and Taurus can create a dynamic and complementary partnership that combines adventure with comfort.",
  virgo:
    "Sagittarius and Virgo create an intriguing and contrasting partnership, blending Sagittarius's adventurous and philosophical nature with Virgo's practical and analytical approach. Sagittarius is inspired by Virgo's intelligence and attention to detail, while Virgo appreciates Sagittarius's optimism and sense of humor. Together, they can learn much from each other, with Sagittarius encouraging Virgo to embrace spontaneity and Virgo helping Sagittarius focus on the practical aspects of life. However, their differences can sometimes lead to challenges, Sagittarius's love for freedom and exploration may clash with Virgo's need for order and routine. Virgo may find Sagittarius too reckless and inconsistent, while Sagittarius could see Virgo as too critical and rigid. For a successful relationship, both must learn to communicate openly and find a balance between adventure and practicality. When they do, Sagittarius and Virgo can create a complementary and enriching partnership that combines excitement with groundedness.",
};

const scorpio = {
  aquarius:
    "Scorpio and Aquarius share fair compatibility, balancing Scorpio's depth with Aquarius' innovation, requiring mutual respect and appreciation.",
  aries:
    "Aries-Scorpio connection exhibits moderate compatibility, balancing Aries' passion with Scorpio's intensity, requiring trust and depth.",
  cancer:
    'Cancer and Scorpio share a very compatible bond, with emotional depth and intuition, fostering a profound and spiritually fulfilling connection.',
  capricorn:
    "Scorpio and Capricorn exhibit moderate compatibility, balancing Scorpio's emotion with Capricorn's ambition, fostering growth through trust and dedication.",
  gemini:
    "Gemini-Scorpio connection exhibits moderate compatibility, balancing emotions with logic, requiring understanding of each other's depths.",
  leo: "Leo-Scorpio connection exhibits moderate compatibility, balancing Leo's passion with Scorpio's depth, requiring trust and emotional connection.",
  libra:
    'Libra and Scorpio exhibit moderate connection, balancing trust with diplomacy, fostering growth through understanding and communication.',
  pisces:
    'Scorpio and Pisces share a very compatible bond, with emotional depth and intuition, fostering a profound and spiritually fulfilling connection.',
  sagittarius:
    'Scorpio and Sagittarius face challenges balancing intensity with freedom, needing compromise and understanding.',
  scorpio:
    'Scorpio-Scorpio compatibility is marked by a strong connection, sharing intensity and loyalty, fostering a deep and passionate relationship.',
  taurus:
    'Taurus and Scorpio share fair compatibility, balancing practicality with intensity, requiring mutual understanding and compromise.',
  virgo:
    "Virgo and Scorpio share fair compatibility, balancing Virgo's logic with Scorpio's intensity, creating a dynamic and insightful bond.",
};

const scorpioEnhanced = {
  aquarius:
    'Scorpio and Aquarius compatibility is an intriguing blend of intensity and independence. When these two signs come together, they create a relationship that is both dynamic and complex. Scorpio’s deep emotions and desire for connection can sometimes clash with Aquarius’s free-spirited and independent nature. However, their differences can also create a stimulating and transformative partnership. Scorpio is intrigued by Aquarius’s originality and visionary thinking, while Aquarius is fascinated by Scorpio’s depth and intensity. Their relationship is characterized by its intellectual stimulation and emotional complexity. By embracing each other’s uniqueness and finding a balance between connection and independence, Scorpio and Aquarius can create a captivating and evolving partnership.',
  aries:
    'Scorpio and Aries compatibility is an intense and fiery combination, filled with passion and determination. When these two strong-willed signs come together, they create a relationship that is both magnetic and challenging. Scorpio’s deep emotions and mysterious nature intrigue Aries, while Aries’s boldness and enthusiasm captivate Scorpio. Their relationship is marked by a powerful connection and a mutual respect for each other’s strength. However, their strong personalities can also lead to power struggles and conflicts. By embracing their shared passion and learning to navigate their differences, Scorpio and Aries can form a dynamic and transformative partnership.',
  cancer:
    'Scorpio and Cancer compatibility is a deeply emotional and intuitive connection. These two water signs share a natural understanding and empathy for each other’s feelings, creating a relationship that is both nurturing and transformative. Scorpio’s passion and intensity resonate with Cancer’s sensitivity and caring nature. Together, they create a bond that is deeply rooted in emotional security and mutual support. Scorpio admires Cancer’s loyalty and nurturing spirit, while Cancer appreciates Scorpio’s strength and protectiveness. Their relationship is characterized by its emotional depth and unwavering support. However, their intense emotions can sometimes lead to moodiness and misunderstandings. By valuing each other’s emotional needs and communicating openly, Scorpio and Cancer can build a loving and resilient partnership.',
  capricorn:
    'Scorpio and Capricorn compatibility is a powerful and ambitious blend. When these two determined signs come together, they create a relationship that is both focused and dynamic. Scorpio’s passion and intensity are complemented by Capricorn’s discipline and ambition. Together, they form a partnership that is both strong and enduring. Scorpio admires Capricorn’s determination and reliability, while Capricorn appreciates Scorpio’s depth and commitment. Their relationship is characterized by its mutual respect and shared goals. However, their different approaches to life can sometimes lead to tension and misunderstandings. By valuing each other’s strengths and finding common ground, Scorpio and Capricorn can build a successful and lasting partnership.',
  gemini:
    'Scorpio and Gemini compatibility is a fascinating blend of mystery and curiosity. When these two signs come together, they create a relationship that is both intriguing and complex. Scorpio’s intense emotions and desire for deep connection can sometimes clash with Gemini’s light-hearted and sociable nature. However, their differences can also create a dynamic and stimulating partnership. Scorpio is intrigued by Gemini’s quick wit and adaptability, while Gemini is fascinated by Scorpio’s depth and intensity. Their relationship is characterized by its intellectual stimulation and emotional complexity. By embracing each other’s uniqueness and finding a balance between depth and lightness, Scorpio and Gemini can create a captivating and evolving partnership.',
  leo: 'Scorpio and Leo compatibility is a powerful and dramatic combination. When these two strong-willed signs come together, they create a relationship that is both passionate and dynamic. Scorpio’s intensity and determination are matched by Leo’s confidence and charisma. Together, they form a partnership that is full of excitement and mutual admiration. Scorpio is drawn to Leo’s warmth and generosity, while Leo is fascinated by Scorpio’s depth and mystery. Their relationship is characterized by its fiery passion and unwavering loyalty. However, their strong personalities can also lead to power struggles and clashes of will. By respecting each other’s strengths and finding a balance between dominance and compromise, Scorpio and Leo can create a vibrant and enduring partnership.',
  libra:
    'Scorpio and Libra compatibility is a blend of intensity and harmony. When these two signs come together, they create a relationship that is both passionate and balanced. Scorpio’s deep emotions and desire for connection are complemented by Libra’s charm and diplomacy. Together, they form a partnership that is both magnetic and harmonious. Scorpio is drawn to Libra’s grace and social skills, while Libra is intrigued by Scorpio’s depth and intensity. Their relationship is characterized by its dynamic energy and mutual respect. However, their different approaches to life can sometimes lead to conflict and misunderstandings. By appreciating each other’s strengths and finding a balance between passion and harmony, Scorpio and Libra can create a beautiful and fulfilling partnership.',
  pisces:
    'Scorpio and Pisces compatibility is a deeply emotional and intuitive connection. These two water signs share a natural understanding and empathy for each other’s feelings, creating a relationship that is both nurturing and transformative. Scorpio’s intensity and passion resonate with Pisces’s sensitivity and compassion. Together, they create a bond that is deeply rooted in emotional security and mutual support. Scorpio admires Pisces’s creativity and empathy, while Pisces appreciates Scorpio’s strength and protectiveness. Their relationship is characterized by its emotional depth and unwavering support. However, their intense emotions can sometimes lead to moodiness and misunderstandings. By valuing each other’s emotional needs and communicating openly, Scorpio and Pisces can build a loving and resilient partnership.',
  sagittarius:
    'Scorpio and Sagittarius compatibility is a dynamic and adventurous combination. When these two signs come together, they create a relationship that is both exciting and challenging. Scorpio’s intensity and desire for deep connection can sometimes clash with Sagittarius’s free-spirited and adventurous nature. However, their differences can also create a stimulating and passionate partnership. Scorpio is intrigued by Sagittarius’s optimism and love for adventure, while Sagittarius is fascinated by Scorpio’s depth and intensity. Their relationship is characterized by its passion and dynamic energy. By embracing each other’s uniqueness and finding a balance between stability and spontaneity, Scorpio and Sagittarius can create a thrilling and evolving partnership.',
  scorpio:
    'Scorpio and Scorpio compatibility is an intense and powerful connection. When two Scorpios come together, they create a relationship that is deeply passionate and transformative. Their shared intensity and emotional depth create a strong bond that is both magnetic and unbreakable. Scorpio admires and understands the other Scorpio’s complexity and desire for connection. Their relationship is characterized by its profound emotional connection and mutual respect. However, their intense personalities can also lead to power struggles and emotional turmoil. By embracing their shared depth and learning to navigate their differences, two Scorpios can create a relationship that is both deeply fulfilling and transformative.',
  taurus:
    'Scorpio and Taurus compatibility is a harmonious blend of intensity and stability. These two signs are opposite each other on the zodiac wheel, creating a magnetic attraction and a balanced partnership. Scorpio’s depth and passion are complemented by Taurus’s steadfastness and sensuality. Together, they create a relationship that is both emotionally rich and grounded. Scorpio appreciates Taurus’s loyalty and reliability, while Taurus admires Scorpio’s intensity and commitment. Their relationship is characterized by its strong foundation and enduring bond. However, their fixed natures can lead to stubbornness and resistance to change. By valuing each other’s strengths and finding common ground, Scorpio and Taurus can build a lasting and fulfilling relationship.',
  virgo:
    'Scorpio and Virgo compatibility is a harmonious blend of intensity and practicality. When these two signs come together, they create a relationship that is both deep and grounded. Scorpio’s passion and emotional depth are complemented by Virgo’s analytical and detail-oriented nature. Together, they form a partnership that is both intellectually stimulating and emotionally fulfilling. Scorpio appreciates Virgo’s dedication and reliability, while Virgo admires Scorpio’s intensity and commitment. Their relationship is characterized by its strong foundation and mutual respect. However, their different approaches to life can sometimes lead to misunderstandings and tension. By valuing each other’s strengths and finding common ground, Scorpio and Virgo can build a stable and enriching partnership.',
};

const taurus = {
  aquarius:
    'Aquarius and Taurus share moderate compatibility, balancing tradition with innovation, needing mutual appreciation for different approaches.',
  aries:
    "Aries and Taurus exhibit moderate compatibility, with Aries' assertiveness needing to find harmony with Taurus' stability for a balanced partnership.",
  cancer:
    'The Taurus-Cancer connection is good, offering emotional stability and shared values, creating a nurturing and supportive relationship.',
  capricorn:
    "Taurus and Capricorn form a strong partnership, grounded and ambitious together, supporting each other's goals and creating stability.",
  gemini:
    'Gemini and Taurus share moderate compatibility, blending adaptability with stability, needing mutual understanding and compromise for a balanced partnership.',
  leo: "Taurus and Leo face challenges balancing practicality with passion, needing understanding of each other's approaches for harmony.",
  libra:
    'Taurus and Libra share moderate compatibility, blending tradition with novelty, needing balance and appreciation of uniqueness.',
  pisces:
    'Taurus and Pisces face challenges due to differences in practicality and sensitivity, requiring understanding and compromise.',
  sagittarius:
    'Taurus and Sagittarius exhibit moderate connection, blending security with exploration, needing balance between stability and adventure.',
  scorpio:
    'Taurus and Scorpio share fair compatibility, balancing practicality with intensity, requiring mutual understanding and compromise.',
  taurus:
    'Taurus-Taurus compatibility is marked by a strong bond of shared values and stability, creating a reliable and enduring partnership.',
  virgo:
    'Taurus and Virgo share good compatibility, with practicality and attention to detail, creating a harmonious and efficient partnership.',
};

const taurusEnhanced = {
  aquarius:
    'Taurus and Aquarius compatibility is an intriguing blend of stability and innovation. When these two signs come together, they create a relationship that is both dynamic and challenging. Taurus’s steady and grounded nature contrasts with Aquarius’s free-spirited and unconventional personality. However, their differences can also create a stimulating and enriching partnership. Taurus is fascinated by Aquarius’s originality and visionary thinking, while Aquarius appreciates Taurus’s reliability and sense of security. Their relationship is characterized by its balance of stability and excitement. By embracing each other’s uniqueness and finding common ground, Taurus and Aquarius can create a fulfilling and evolving partnership.',
  aries:
    'Taurus and Aries compatibility is a fascinating blend of steadiness and spontaneity. When these two signs come together, they create a relationship that is both dynamic and challenging. Taurus’s calm and patient nature contrasts with Aries’s energetic and impulsive approach to life. However, their differences can complement each other well. Taurus provides stability and grounding for Aries’s adventurous spirit, while Aries brings excitement and inspiration to Taurus’s life. Their relationship is characterized by a mix of security and passion, with both partners learning to appreciate each other’s strengths. By finding a balance between stability and adventure, Taurus and Aries can create a fulfilling and harmonious partnership.',
  cancer:
    'Taurus and Cancer compatibility is a nurturing and harmonious connection. When these two signs come together, they create a relationship that is both emotionally fulfilling and secure. Taurus’s steadfastness and practicality complement Cancer’s sensitivity and nurturing nature. Together, they form a bond that is deeply rooted in emotional security and mutual support. Taurus appreciates Cancer’s loyalty and caring spirit, while Cancer admires Taurus’s strength and reliability. Their relationship is characterized by its warmth and emotional depth. By valuing each other’s emotional needs and creating a safe and loving environment, Taurus and Cancer can build a lasting and fulfilling partnership.',
  capricorn:
    'Taurus and Capricorn compatibility is a harmonious and ambitious connection. When these two earth signs come together, they create a relationship that is both stable and focused. Taurus’s steadfastness and practicality complement Capricorn’s discipline and ambition. Together, they form a bond that is deeply rooted in mutual respect and shared goals. Taurus appreciates Capricorn’s dedication and reliability, while Capricorn admires Taurus’s strength and consistency. Their relationship is characterized by its stability and mutual support. By working towards common goals and valuing each other’s strengths, Taurus and Capricorn can build a lasting and successful partnership.',
  gemini:
    'Taurus and Gemini compatibility is an intriguing blend of stability and versatility. When these two signs come together, they create a relationship that is both challenging and stimulating. Taurus’s steady and grounded nature can sometimes clash with Gemini’s restless and changeable personality. However, their differences can also create a dynamic and interesting partnership. Taurus is fascinated by Gemini’s wit and adaptability, while Gemini appreciates Taurus’s reliability and sense of security. Their relationship is characterized by its balance of stability and excitement. By embracing each other’s uniqueness and finding common ground, Taurus and Gemini can create a partnership that is both enriching and evolving.',
  leo: 'Taurus and Leo compatibility is a dynamic and passionate blend. When these two signs come together, they create a relationship that is both exciting and challenging. Taurus’s steady and grounded nature contrasts with Leo’s bold and flamboyant personality. However, their differences can also create a vibrant and stimulating partnership. Taurus is drawn to Leo’s confidence and warmth, while Leo appreciates Taurus’s reliability and sensuality. Their relationship is characterized by its passion and mutual admiration. By respecting each other’s strengths and finding a balance between stability and excitement, Taurus and Leo can create a fulfilling and enduring partnership.',
  libra:
    'Taurus and Libra compatibility is a blend of beauty and harmony. When these two signs come together, they create a relationship that is both aesthetically pleasing and balanced. Taurus’s love for comfort and luxury complements Libra’s appreciation for beauty and harmony. Together, they form a bond that is marked by mutual admiration and a shared love for the finer things in life. Taurus appreciates Libra’s charm and diplomacy, while Libra admires Taurus’s strength and sensuality. Their relationship is characterized by its balance and mutual respect. By finding common ground and appreciating each other’s strengths, Taurus and Libra can create a harmonious and fulfilling partnership.',
  pisces:
    'Taurus and Pisces compatibility is a deeply emotional and harmonious connection. When these two signs come together, they create a relationship that is both nurturing and fulfilling. Taurus’s steadfastness and practicality complement Pisces’s sensitivity and compassion. Together, they form a bond that is deeply rooted in emotional security and mutual support. Taurus appreciates Pisces’s creativity and empathy, while Pisces admires Taurus’s strength and reliability. Their relationship is characterized by its warmth and emotional depth. By valuing each other’s emotional needs and creating a safe and loving environment, Taurus and Pisces can build a lasting and fulfilling partnership.',
  sagittarius:
    'Taurus and Sagittarius compatibility is an intriguing blend of stability and adventure. When these two signs come together, they create a relationship that is both dynamic and challenging. Taurus’s steady and grounded nature contrasts with Sagittarius’s free-spirited and adventurous personality. However, their differences can also create a stimulating and enriching partnership. Taurus is fascinated by Sagittarius’s optimism and love for adventure, while Sagittarius appreciates Taurus’s reliability and sense of security. Their relationship is characterized by its balance of stability and excitement. By embracing each other’s uniqueness and finding common ground, Taurus and Sagittarius can create a fulfilling and evolving partnership.',
  scorpio:
    'Taurus and Scorpio compatibility is a powerful and magnetic connection. When these two signs come together, they create a relationship that is both intense and transformative. Taurus’s steadfastness and practicality contrast with Scorpio’s depth and intensity. However, their differences can also create a dynamic and passionate partnership. Taurus is drawn to Scorpio’s mysterious and passionate nature, while Scorpio appreciates Taurus’s strength and reliability. Their relationship is characterized by its strong bond and mutual respect. By valuing each other’s strengths and finding a balance between stability and intensity, Taurus and Scorpio can create a powerful and enduring partnership.',
  taurus:
    'Taurus and Taurus compatibility is a harmonious and grounded connection. When two Taureans come together, they create a relationship that is marked by mutual understanding and shared values. Their similar approach to life ensures a stable and enduring partnership. Both value security, loyalty, and comfort, making their bond strong and resilient. They enjoy the finer things in life and share a love for beauty and sensuality. However, their stubborn natures can sometimes lead to conflicts. By learning to compromise and communicate openly, two Taureans can build a relationship that is both deeply satisfying and long-lasting.',
  virgo:
    'Taurus and Virgo compatibility is a harmonious and practical connection. When these two earth signs come together, they create a relationship that is both stable and nurturing. Taurus’s steadfastness and reliability complement Virgo’s analytical and detail-oriented nature. Together, they form a bond that is deeply rooted in mutual respect and shared values. Taurus appreciates Virgo’s dedication and practicality, while Virgo admires Taurus’s strength and reliability. Their relationship is characterized by its stability and mutual support. By valuing each other’s strengths and working towards common goals, Taurus and Virgo can build a lasting and fulfilling partnership.',
};

const virgo = {
  aquarius:
    'Virgo and Aquarius form a good match, offering intellectual stimulation and adaptability, blending practicality with innovation.',
  aries:
    "Aries and Virgo share fair harmony, with Aries' confidence complementing Virgo's practicality, requiring balance and understanding.",
  cancer:
    'Cancer and Virgo share fair harmony, balancing emotions with practicality, creating a stable and supportive relationship.',
  capricorn:
    'Virgo and Capricorn share very compatible traits, with practicality and diligence, creating a stable and successful partnership.',
  gemini:
    'Gemini and Virgo share fair harmony, needing to balance detail-oriented and spontaneous traits, fostering mutual understanding.',
  leo: 'Leo and Virgo exhibit fair harmony, balancing confidence with practicality, requiring mutual respect and support.',
  libra:
    "Virgo and Libra exhibit moderate compatibility, balancing Virgo's order with Libra's harmony, requiring mutual understanding and compromise.",
  pisces:
    'Virgo and Pisces exhibit moderate connection, balancing pragmatism with sensitivity, requiring mutual understanding and empathy.',
  sagittarius:
    'Virgo and Sagittarius face challenges balancing detail with spontaneity, needing compromise and adaptability for growth.',
  scorpio:
    "Virgo and Scorpio share fair compatibility, balancing Virgo's logic with Scorpio's intensity, creating a dynamic and insightful bond.",
  taurus:
    'Taurus and Virgo share good compatibility, with practicality and attention to detail, creating a harmonious and efficient partnership.',
  virgo:
    'Virgo-Virgo compatibility is marked by a strong bond of shared practicality and attention to detail, creating a harmonious and efficient partnership.',
};

const virgoEnhanced = {
  aquarius:
    'Virgo and Aquarius compatibility is an intriguing blend of practicality and innovation. When these two signs come together, they create a relationship that is both dynamic and challenging. Virgo’s analytical and detail-oriented nature contrasts with Aquarius’s free-spirited and unconventional personality. However, their differences can also create a stimulating and enriching partnership. Virgo is fascinated by Aquarius’s originality and visionary thinking, while Aquarius appreciates Virgo’s intelligence and practicality. Their relationship is characterized by its balance of stability and excitement. By embracing each other’s uniqueness and finding common ground, Virgo and Aquarius can create a fulfilling and evolving partnership.',
  aries:
    'Virgo and Aries compatibility is a fascinating blend of practicality and passion. When these two signs come together, they create a relationship that is both dynamic and challenging. Virgo’s analytical and detail-oriented nature contrasts with Aries’s energetic and impulsive approach to life. However, their differences can also complement each other well. Virgo provides stability and structure to Aries’s enthusiastic endeavors, while Aries brings excitement and inspiration to Virgo’s organized world. Their relationship is characterized by a mix of practicality and passion, with both partners learning to appreciate each other’s strengths. By finding a balance between order and spontaneity, Virgo and Aries can create a fulfilling and harmonious partnership.',
  cancer:
    'Virgo and Cancer compatibility is a nurturing and harmonious connection. When these two signs come together, they create a relationship that is both emotionally fulfilling and secure. Virgo’s analytical and detail-oriented nature complements Cancer’s sensitivity and nurturing spirit. Together, they form a bond that is deeply rooted in emotional security and mutual support. Virgo appreciates Cancer’s loyalty and caring nature, while Cancer admires Virgo’s dedication and reliability. Their relationship is characterized by its warmth and emotional depth. By valuing each other’s emotional needs and creating a safe and loving environment, Virgo and Cancer can build a lasting and fulfilling partnership.',
  capricorn:
    'Virgo and Capricorn compatibility is a harmonious and ambitious connection. When these two earth signs come together, they create a relationship that is both stable and focused. Virgo’s analytical and detail-oriented nature complements Capricorn’s discipline and ambition. Together, they form a bond that is deeply rooted in mutual respect and shared goals. Virgo appreciates Capricorn’s dedication and reliability, while Capricorn admires Virgo’s intelligence and practicality. Their relationship is characterized by its stability and mutual support. By working towards common goals and valuing each other’s strengths, Virgo and Capricorn can build a lasting and successful partnership.',
  gemini:
    'Virgo and Gemini compatibility is an intriguing blend of intellect and adaptability. When these two signs come together, they create a relationship that is both stimulating and complex. Virgo’s analytical and detail-oriented nature can sometimes clash with Gemini’s restless and changeable personality. However, their differences can also create a dynamic and interesting partnership. Virgo is fascinated by Gemini’s quick wit and versatility, while Gemini appreciates Virgo’s intelligence and practicality. Their relationship is characterized by its intellectual stimulation and mutual respect. By embracing each other’s uniqueness and finding common ground, Virgo and Gemini can create a partnership that is both enriching and evolving.',
  leo: 'Virgo and Leo compatibility is a dynamic and balanced blend. When these two signs come together, they create a relationship that is both exciting and challenging. Virgo’s analytical and detail-oriented nature contrasts with Leo’s bold and charismatic personality. However, their differences can also create a vibrant and stimulating partnership. Virgo is drawn to Leo’s confidence and warmth, while Leo appreciates Virgo’s intelligence and practicality. Their relationship is characterized by its passion and mutual admiration. By respecting each other’s strengths and finding a balance between order and spontaneity, Virgo and Leo can create a fulfilling and enduring partnership.',
  libra:
    'Virgo and Libra compatibility is a blend of intellect and harmony. When these two signs come together, they create a relationship that is both balanced and stimulating. Virgo’s analytical and detail-oriented nature complements Libra’s charm and diplomacy. Together, they form a bond that is marked by mutual respect and shared values. Virgo appreciates Libra’s grace and social skills, while Libra admires Virgo’s intelligence and practicality. Their relationship is characterized by its balance and mutual admiration. By finding common ground and appreciating each other’s strengths, Virgo and Libra can create a harmonious and fulfilling partnership.',
  pisces:
    'Virgo and Pisces compatibility is a deeply emotional and harmonious connection. When these two signs come together, they create a relationship that is both nurturing and fulfilling. Virgo’s analytical and detail-oriented nature contrasts with Pisces’s sensitivity and compassion. However, their differences can also create a dynamic and enriching partnership. Virgo is fascinated by Pisces’s creativity and empathy, while Pisces appreciates Virgo’s intelligence and practicality. Their relationship is characterized by its warmth and emotional depth. By valuing each other’s emotional needs and creating a safe and loving environment, Virgo and Pisces can build a lasting and fulfilling partnership.',
  sagittarius:
    'Virgo and Sagittarius compatibility is an intriguing blend of practicality and adventure. When these two signs come together, they create a relationship that is both dynamic and challenging. Virgo’s analytical and detail-oriented nature contrasts with Sagittarius’s free-spirited and adventurous personality. However, their differences can also create a stimulating and enriching partnership. Virgo is fascinated by Sagittarius’s optimism and love for adventure, while Sagittarius appreciates Virgo’s intelligence and practicality. Their relationship is characterized by its balance of stability and excitement. By embracing each other’s uniqueness and finding common ground, Virgo and Sagittarius can create a fulfilling and evolving partnership.',
  scorpio:
    'Virgo and Scorpio compatibility is a powerful and intense connection. When these two signs come together, they create a relationship that is both deep and transformative. Virgo’s analytical and detail-oriented nature contrasts with Scorpio’s depth and intensity. However, their differences can also create a dynamic and passionate partnership. Virgo is drawn to Scorpio’s mysterious and passionate nature, while Scorpio appreciates Virgo’s intelligence and practicality. Their relationship is characterized by its strong bond and mutual respect. By valuing each other’s strengths and finding a balance between stability and intensity, Virgo and Scorpio can create a powerful and enduring partnership.',
  taurus:
    'Virgo and Taurus compatibility is a harmonious and practical connection. When these two earth signs come together, they create a relationship that is both stable and nurturing. Virgo’s analytical and detail-oriented nature complements Taurus’s steadfastness and reliability. Together, they form a bond that is deeply rooted in mutual respect and shared values. Virgo appreciates Taurus’s dedication and practicality, while Taurus admires Virgo’s precision and work ethic. Their relationship is characterized by its stability and mutual support. By working towards common goals and valuing each other’s strengths, Virgo and Taurus can build a lasting and successful partnership.',
  virgo:
    'Virgo and Virgo compatibility is a harmonious and precise connection. When two Virgos come together, they create a relationship that is marked by mutual understanding and shared values. Their similar approach to life ensures a stable and enduring partnership. Both value practicality, organization, and dedication, making their bond strong and resilient. They appreciate each other’s intelligence and attention to detail. However, their critical natures can sometimes lead to conflicts. By learning to compromise and communicate openly, two Virgos can build a relationship that is both deeply satisfying and long-lasting.',
};

export const compatibilitySummaries = {
  aquarius,
  aries,
  cancer,
  capricorn,
  gemini,
  leo,
  libra,
  pisces,
  sagittarius,
  scorpio,
  taurus,
  virgo,
};

export const enhancedCompatibilitySummaries = {
  aquarius: aquariusEnhanced,
  aries: ariesEnhanced,
  cancer: cancerEnhanced,
  capricorn: capricornEnhanced,
  gemini: geminiEnhanced,
  leo: leoEnhanced,
  libra: libraEnhanced,
  pisces: piscesEnhanced,
  sagittarius: sagittariusEnhanced,
  scorpio: scorpioEnhanced,
  taurus: taurusEnhanced,
  virgo: virgoEnhanced,
};
