import { DateTime } from 'luxon';
import { XmlHandler } from '../XmlHandler';

export type CreditData = {
  credit_report_summary: string;
  credit_score_history: string | null;
  last_updated_date: string;
  vantage_score: string;
  vantage_score_details: string | null;
};

type ScoreHistoryItem = {
  score: string | null;
  date: string;
};

export const parseDate = (date: string | null) => {
  if (!date) return '';

  // We need to normalize the date string because the date string returned from the API
  // is not a valid ISO string. It is missing the timezone offset.
  // and even if we add it, it keeps failing on some browsers.
  const normalizedDate = date.substring(0, 10);
  const parsedDate = DateTime.fromISO(normalizedDate);

  if (!parsedDate.isValid) return '';

  return parsedDate.toFormat('MM/dd/yyyy');
};

const parseCreditScoreHistory = (creditScoreHistory: string | null) => {
  if (!creditScoreHistory) return;

  const scoreHistory: ScoreHistoryItem[] = [];
  const parsedCreditScoreHistory = XmlHandler().parseToXml(creditScoreHistory);

  const scoreNodes = parsedCreditScoreHistory.querySelectorAll('Score');
  const dateNodes = parsedCreditScoreHistory.querySelectorAll('Date');

  for (let i = 0; i < scoreNodes.length; i++) {
    const score = scoreNodes[i].textContent;
    const date = parseDate(dateNodes[i].textContent);
    scoreHistory.push({ score, date });
  }

  return scoreHistory;
};

const parseCreditScoreSummary = (creditScoreSummary: string) => {
  const xmlDoc = XmlHandler().parseToXml(creditScoreSummary);

  const creditSummary: Record<string, string> = {};
  const childrenNodes = xmlDoc.documentElement.children;

  for (let i = 0; i < childrenNodes.length; i++) {
    const tagName = childrenNodes[i].tagName;
    const textContent = childrenNodes[i].textContent;

    if (tagName && textContent) {
      creditSummary[tagName] = textContent;
    }
  }

  return creditSummary;
};

export const parseCreditData = (data: CreditData) => {
  const {
    credit_report_summary,
    credit_score_history,
    last_updated_date,
    vantage_score,
    vantage_score_details,
  } = data;

  return {
    creditReportSummary: parseCreditScoreSummary(credit_report_summary),
    creditScoreHistory: parseCreditScoreHistory(credit_score_history),
    lastUpdatedDate: parseDate(last_updated_date),
    vantageScore: Number(vantage_score),
    vantageScoreDetails: vantage_score_details,
  };
};
