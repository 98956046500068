import { useBumpeeWordTranslation } from 'components/Language/hooks/useBumpeeWordTranslation';
import {
  Text,
  Modal,
  Box,
  Stack,
  styled,
  IconButton,
} from '@ltvco/refresh-lib/theme';
import { ChatTextLoading } from '@ltvco/refresh-lib/v1';
import { Close } from '@mui/icons-material';
import { capitalizeString } from '@ltvco/refresh-lib/utils';

export const StyledText = styled(Text)({
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

interface ClickableWordProps {
  word: string;
  index: number;
  chatId: number;
  messageId: number;
  isLessonChat?: boolean;
}

export function ClickableWord({
  word,
  index,
  chatId,
  messageId,
  isLessonChat = false,
}: ClickableWordProps) {
  const {
    translatedWordData,
    handleTranslateWordClick,
    handleTranslateWordClose,
    isLoading,
  } = useBumpeeWordTranslation(chatId, messageId, index);

  const modifiedText = word.replace(/\/a/g, '');
  const strippedWord = modifiedText.replace(/[?.!,]/, '');

  return (
    <>
      {word.split('').some((char) => char.match(/[0-9a-zA-Z]/)) ? (
        isLessonChat ? (
          <Text key={`word-${index}`}>{word}&nbsp;</Text>
        ) : (
          <StyledText key={`word-${index}`} onClick={handleTranslateWordClick}>
            {modifiedText}
            &nbsp;
          </StyledText>
        )
      ) : (
        <Text key={`word-${index}`}>{word}&nbsp;</Text>
      )}
      <Modal
        open={translatedWordData.open}
        onClose={handleTranslateWordClose}
        fullWidth
      >
        <Stack>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <IconButton onClick={handleTranslateWordClose}>
              <Close />
            </IconButton>
          </Box>
          <Box
            m={3}
            mt={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text variant="h3" mr={0.5} m={0}>
              {capitalizeString(strippedWord)}:&nbsp;
            </Text>

            {isLoading ? (
              <Box display="flex" justifyContent="center" ml={1}>
                <ChatTextLoading />
              </Box>
            ) : (
              <Text variant="h3" fontWeight="500" mb={0}>
                {capitalizeString(translatedWordData.content.translation)}
              </Text>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {isLoading ? (
              <ChatTextLoading />
            ) : (
              <>
                <Text variant="h4" textAlign="center">
                  {capitalizeString(translatedWordData.content.definition)}
                </Text>
                <Text
                  variant="h4"
                  fontWeight={500}
                  sx={{ color: '#00000070' }}
                  textAlign="center"
                >
                  "
                  {capitalizeString(
                    translatedWordData.content.translated_sentence
                  )}
                  "
                </Text>
              </>
            )}
          </Box>
        </Stack>
      </Modal>
    </>
  );
}
