import { useState, useEffect, useContext } from 'react';
import {
  Stack,
  Text,
  Button,
  useTheme,
  Box,
} from '@ltvco/refresh-lib/theme';
import AdditionalInformationLogo from 'images/id-monitor-additional-information-logo.svg';
import { StyledVerticalCard, ContainerStyled, StyledBtn } from './components';
import { NotificationSettings } from './NotificationSettings';
import useNotificationSettings from '../Oxford/useNotificationSettings';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { ArrowCircleRight } from '@mui/icons-material';

interface DataContainerProps {
  setRenderBenefitsModal: (value: boolean) => void;
  setRenderDisclosureModal: (value: boolean) => void;
  setRenderAboutIDTheftInsuranceModal: (value: boolean) => void;
  notificationSettings: {
    standard_alert_enabled: boolean;
    enhanced_alert_enabled: boolean;
    instant_alert_enabled: boolean;
  };
  idManagement: {
    enrolled: boolean;
  };
}

const DataContainer = ({
  setRenderBenefitsModal,
  setRenderDisclosureModal,
  setRenderAboutIDTheftInsuranceModal,
  notificationSettings,
  idManagement,
}: DataContainerProps) => {
  const [readyForNotificationsUpdate, setReadyForNotificationsUpdate] =
    useState(false);
  const [standardAlert, setStandardAlert] = useState(false);
  const [enhancedAlert, setEnhancedAlert] = useState(false);
  const [instantInquiryAlert, setInstantInquiryAlert] = useState(false);
  const [identityManagementAlert, setIdentityManagementAlert] = useState(false);
  const { trackEvent } = useContext(AppConfig);
  const { updateIdentityManagement, updateSettings, isLoading } =
    useNotificationSettings();

  const theme = useTheme();

  useEffect(() => {
    const didNotificationChangeFromOrigin = () => {
      return (
        enhancedAlert !== notificationSettings?.enhanced_alert_enabled ||
        standardAlert !== notificationSettings?.standard_alert_enabled ||
        instantInquiryAlert !== notificationSettings?.instant_alert_enabled ||
        identityManagementAlert !== idManagement?.enrolled
      );
    };

    setReadyForNotificationsUpdate(didNotificationChangeFromOrigin());
  }, [
    standardAlert,
    enhancedAlert,
    instantInquiryAlert,
    identityManagementAlert,
    notificationSettings,
    idManagement,
  ]);

  useEffect(() => {
    if (notificationSettings && idManagement) {
      setStandardAlert(notificationSettings.standard_alert_enabled);
      setEnhancedAlert(notificationSettings.enhanced_alert_enabled);
      setInstantInquiryAlert(notificationSettings.instant_alert_enabled);
      setIdentityManagementAlert(idManagement.enrolled);
    }
  }, [notificationSettings, idManagement]);

  const notificationSettingsChanged = () => {
    return (
      enhancedAlert !== notificationSettings?.enhanced_alert_enabled ||
      standardAlert !== notificationSettings?.standard_alert_enabled ||
      instantInquiryAlert !== notificationSettings?.instant_alert_enabled
    );
  };

  const idManagementChanged = () =>
    identityManagementAlert !== idManagement?.enrolled;

  const onUpdateSettings = () => {
    if (!readyForNotificationsUpdate) return;

    trackEvent('id protection tools', 'engagement click', 'id monitor');

    if (idManagementChanged()) updateIdentityManagement();

    if (notificationSettingsChanged()) {
      updateSettings({
        standard_alert_enabled: standardAlert,
        enhanced_alert_enabled: enhancedAlert,
        instant_alert_enabled: instantInquiryAlert,
      });
    }
    setReadyForNotificationsUpdate(false);
  };

  return (
    <Box
      sx={{ backgroundColor: theme.palette.background.paper }}
      padding={[2, 2.5]}
    >
      <Stack
        textAlign="left"
        sx={{
          position: 'relative',
          width: '100%',
          gap: 2,
        }}
      >
        <Text variant="h3" fontSize={24}>
          Protection Tools
        </Text>
        <StyledBtn
          disabled={!readyForNotificationsUpdate || isLoading}
          variant="contained"
          color="primary"
          onClick={() => onUpdateSettings()}
        >
          Save Changes
        </StyledBtn>
        <Text
          variant="body1"
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: '70%',
            marginBottom: 2,
          }}
        >
          Select your identity theft and credit protection tools to ensure your
          identity and credit are being monitored and safeguarded.
        </Text>
      </Stack>
      <ContainerStyled>
        <>
          <NotificationSettings
            standardAlert={standardAlert}
            setStandardAlert={setStandardAlert}
            enhancedAlert={enhancedAlert}
            setEnhancedAlert={setEnhancedAlert}
            instantInquiryAlert={instantInquiryAlert}
            setInstantInquiryAlert={setInstantInquiryAlert}
            identityManagementAlert={identityManagementAlert}
            setIdentityManagementAlert={setIdentityManagementAlert}
            openDisclosureModal={() => setRenderDisclosureModal(true)}
          />
          <StyledVerticalCard gap={2} className="third-block">
            <Stack direction="row" alignItems="center" gap={1}>
              <img
                src={AdditionalInformationLogo}
                width={35}
                height={35}
                alt="Instant Inquiry Alerts"
              />
              <Text variant="h6">
                Additional <br /> Information
              </Text>
            </Stack>
            <Button
              endIcon={<ArrowCircleRight color="inherit" />}
              fullWidth
              size="large"
              sx={{
                textAlign: 'left',
                textDecoration: 'underline',
                lineHeight: '19.2px',
                padding: 0,
                width: 'fit-content',
              }}
              onClick={() => setRenderAboutIDTheftInsuranceModal(true)}
            >
              About Identity Theft Insurance
            </Button>
            <Button
              endIcon={<ArrowCircleRight color="inherit" />}
              size="large"
              sx={{
                textAlign: 'left',
                textDecoration: 'underline',
                lineHeight: '19.2px',
                padding: 0,
                width: 'fit-content',
              }}
              onClick={() => setRenderBenefitsModal(true)}
            >
              Identity Theft Expenses Insurance - Summary of Benefits
            </Button>
          </StyledVerticalCard>
        </>
      </ContainerStyled>
    </Box>
  );
};

export default DataContainer;
