import {  Stack, Text } from '@ltvco/refresh-lib/theme';
import { constants } from 'appConstants';
import {
  AstrologyLoadingBulletText,
  FadingAnimationContainer,
  GradiantOverlay,
  MainImg,
} from './components';
import { LoadingDots } from '@ltvco/refresh-lib/v1';
import { Check } from '@mui/icons-material';

interface AstrologyLoadingProps {
  standaloneVersion?: boolean;
}

export const AstrologyLoading = ({
  standaloneVersion,
}: AstrologyLoadingProps) => {
  return (
    <Stack
      bgcolor={(theme) =>
        standaloneVersion
          ? theme.palette.background.paper
          : theme.palette.secondary.main
      }
      width="100%"
      height="100%"
      minHeight="inherit"
      alignItems="center"
      gap={2.5}
      p={{ xs: 5, lg: 2 }}
    >
      <MainImg
        src={constants.images.astrology.imgAstrologyNumerologyLoading}
        alt="Astrology Numerology Insights Loading"
      />
      <Stack alignItems="center" mb={1}>
        <Text variant="h2" mb={0}>
          Gathering
        </Text>
        <Text variant="h2" mb={0}>
          Astro-Numerical
        </Text>
        <Text variant="h2" mb={0}>
          Insights..
        </Text>
      </Stack>
      <LoadingDots count={3} />
      <Stack gap={1} alignItems="start" position="relative">
        <FadingAnimationContainer>
          <Check fontSize="small" sx={{ color: '#0052FF'}} />
          <AstrologyLoadingBulletText>Horoscope</AstrologyLoadingBulletText>
        </FadingAnimationContainer>
        <FadingAnimationContainer>
          <Check fontSize="small" sx={{ color: '#0052FF'}} />
          <AstrologyLoadingBulletText>Compatibility</AstrologyLoadingBulletText>
        </FadingAnimationContainer>
        <FadingAnimationContainer>
          <Check fontSize="small" sx={{ color: '#0052FF'}} />
          <AstrologyLoadingBulletText>
            Numerology Lifepath
          </AstrologyLoadingBulletText>
        </FadingAnimationContainer>
        <FadingAnimationContainer>
          <Check fontSize="small" sx={{ color: '#0052FF'}} />
          <AstrologyLoadingBulletText>
            Numerology Personality Trait
          </AstrologyLoadingBulletText>
        </FadingAnimationContainer>
        <GradiantOverlay standaloneVersion={standaloneVersion} />
      </Stack>
    </Stack>
  );
};
