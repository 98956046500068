import { Stack, Text } from '@ltvco/refresh-lib/theme';
import { useOxford } from './Oxford/Oxford';
import { NotificationsAlertsBtn } from './NotificationsAlertsSummaries';
import idMonitorColorLogo from 'images/id-monitor-color-logo.svg';
import { checkPlanVariations } from 'utils/checkPlanVariations';
import { useSession } from '@ltvco/refresh-lib/v1';

export const MainHeader = () => {
  const { data, isLoading } = useOxford().useNotifications();

  const hasNewNotifications = data?.notifications.notificationsCount !== null;
  const notificationsCount =
    data?.notifications.notificationsCount ||
    data?.notifications.notifications.length;

  const {
    session: { account },
  } = useSession();

  const isEnrolled =
    account?.account.user_settings.credit_hub_enrollment_completed;

  const planName =
    account?.account?.subscription_info?.subscription_plan_unique_name ?? '';
  const { isInInternationalLanguageTest, isInternationalVinTest } =
    checkPlanVariations(planName);

  return (
    <Stack
      direction={['column', 'row']}
      justifyContent="space-between"
      rowGap={2}
    >
      <Stack direction="row" alignItems="center">
        <img
          src={idMonitorColorLogo}
          alt="id-monitor-color-logo"
          style={{
            paddingRight: '8px',
            paddingBottom: '12px',
          }}
          height="32px"
        />

        <Text variant="h3">ID Monitor</Text>
      </Stack>

      {(!!data || isLoading) && Boolean(isEnrolled) && (
        <NotificationsAlertsBtn
          hasNewNotifications={hasNewNotifications}
          notificationsCount={notificationsCount}
          isLoading={isLoading}
        />
      )}
    </Stack>
  );
};
