import { BrandThemeProvider } from '@ltvco/refresh-lib/theme';
import { BackdropLoading } from '@ltvco/refresh-lib/v2';
import { Text } from '@ltvco/refresh-lib/theme';
import { theme } from '../theme';
import { useEffect, useState } from 'react';
import { isUserLoggedIn } from '@ltvco/refresh-lib/v1';

export function ThemeWrapper({
  children,
  isGbReady,
}: {
  children: JSX.Element;
  isGbReady: boolean;
}) {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (!isUserLoggedIn()) {
      setShowLoading(false);
      return;
    }
    if (!isGbReady) {
      setShowLoading(true);

      setTimeout(() => {
        setShowLoading(false);
      }, 10000);

      return;
    }

    setShowLoading(false);
  }, [isGbReady]);

  const isTestingEnv = import.meta.env.VITE_IS_TEST;
  return (
    <BrandThemeProvider theme={theme}>
      {showLoading && !isTestingEnv ? (
        <BackdropLoading
          message={<Text variant="h5">Fetching account</Text>}
          sx={{ backgroundColor: 'white' }}
        />
      ) : (
        <>{children}</>
      )}
    </BrandThemeProvider>
  );
}
