import { Button, Box, Stack, Text, styled } from '@ltvco/refresh-lib/theme';
import { QuickreplyOutlined, WorkspacePremiumOutlined, RouteSharp } from '@mui/icons-material';
import imgLanguageSplashPage from 'images/img-language-splash-page.png';

const benefits = [
  {
    title: 'Effortless Communication',
    description:
      'Get real-time translation and other helpful tools across various languages.',
    icon: (
      <QuickreplyOutlined
        sx={{ alignSelf: 'baseline', color: '#81B44C', fontSize: '2.25rem' }}
      />
    ),
  },
  {
    title: 'Get Practical Language Reps',
    description:
      'Explore different topics to prepare for real-life conversations and boost language confidence.',
    icon: (
      <WorkspacePremiumOutlined
        sx={{ alignSelf: 'baseline', color: '#81B44C', fontSize: '2.25rem' }}
      />
    ),
  },
  {
    title: 'Learn At Your Own Pace',
    description:
      'Our AI adapts to your skill level, offering personalized conversations.',
    icon: (
      <RouteSharp
        sx={{ alignSelf: 'baseline', color: '#81B44C', fontSize: '2.25rem' }}
      />
    ),
  },
];

export const CardImage = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: '429px',
  height: '465px',

  [theme.breakpoints.down('md')]: {
    width: '335px',
    height: '363px',
  },
}));

interface SplashPageProps {
  handleGetStarted: () => void;
}

export const SplashPage = ({ handleGetStarted }: SplashPageProps) => {
  return (
    <Stack flexDirection={{ xs: 'column', md: 'row' }} mt={4}>
      <Stack>
        <Text variant="h1">
          Practice a new language with confidence with AI
        </Text>
        <Text variant="body1" mb={2} sx={{ color: 'text.main' }}>
          Beverly, our AI chatbot, is equipped with tools to help you practice
          and improve your grammar and language skills in a relaxed environment.
        </Text>
        <Box>
          <Button
            variant="contained"
            className="language-continue"
            onClick={handleGetStarted}
          >
            Get Started
          </Button>
        </Box>
        <Stack mt={4}>
          {benefits.map((benefit, index) => (
            <Stack key={index} mb={2}>
              <Stack flexDirection="row" alignItems="center">
                {benefit.icon}
                <Stack ml={1}>
                  <Text variant="h5" mb={0.5}>
                    {benefit.title}
                  </Text>
                  <Text
                    variant="body1"
                    sx={{
                      color: 'text.main',
                    }}
                  >
                    {benefit.description}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Box mt={{ xs: 3, md: 0 }}>
        <CardImage src={imgLanguageSplashPage} alt="Language Splash Page" />
      </Box>
    </Stack>
  );
};
