import { Select, Text, styled } from '@ltvco/refresh-lib/theme';

export const StyledImage = styled('img')(({ theme }) => ({
  width: '85px',
  height: '85px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '100%',
  padding: theme.spacing(0.625),
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2)',
}));

export const IconText = styled(Text)`
  margin-bottom: 0;
  color: rgb(0, 0, 0);
  font-weight: 400;
  display: flex;
  align-items: center;
  min-height: 33px;
`;

export const ZodiacSignSelect = styled(Select)`
  background-color: transparent;
  border: none;
  min-width: 122px;
  height: 33px;

  &:hover {
    box-shadow: none;
  }

  &.Mui-focused,
  &:hover {
    border: none;
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
