import { useContext, useState } from 'react';
import {
  Modal,
  Input,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@ltvco/refresh-lib/theme';
import { Text } from '@ltvco/refresh-lib/theme';
import { constants } from 'appConstants';
import { useNavigate } from 'react-router-dom';
import { useSubmitUpgradeLead } from './useSubmitUpgradeLead';
import { reportsPerMonthOptions, useCases } from './options';
import { useForm, Controller, useWatch } from 'react-hook-form';
import IndividualFields from './IndividualFields';
import CompanyFields from './CompanyFields';
import UseCaseFields from './UseCaseFields';
import {
  StyledForm,
  StyledButton,
  StyledImage,
  StyledInputLabel,
  StyledModal,
  UnderlinedLink,
} from './components';
import { Plan, CurrentPlan } from '@ltvco/refresh-lib/payments';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

interface UpgradeLeadFormProps {
  currentPlan: CurrentPlan;
  plans: Plan[];
  onSelectPlan: (plan: Plan) => void;
  isOpen: boolean;
  onClose: () => void;
}

interface FormData {
  type: 'individual' | 'company';
  name: string;
  email?: string;
  businessEmail?: string;
  companyName?: string;
  companyUrl?: string;
  reportsPerMonth?: string;
  useCase?: string;
  otherUseCase?: string;
}

const UpgradeLeadForm = ({
  isOpen,
  onClose,
  onSelectPlan,
  plans,
  currentPlan,
}: UpgradeLeadFormProps) => {
  const navigate = useNavigate();
  const { mutate: submitLeadForm } = useSubmitUpgradeLead();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      type: 'individual',
      name: '',
      email: '',
      businessEmail: '',
      companyName: '',
      companyUrl: 'https://',
      reportsPerMonth: '',
      useCase: '',
      otherUseCase: '',
    },
  });

  const selectedType = useWatch({
    control,
    name: 'type',
  });

  const selectedUseCase = useWatch({
    control,
    name: 'useCase',
  });

  const reportsPerMonth = useWatch({
    control,
    name: 'reportsPerMonth',
  });

  const { trackEventGA4 } = useContext(AppConfig);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const filteredReportsPerMonthOptions = reportsPerMonthOptions.filter(
    (option) => {
      const optionValue =
        option.value === '800+' ? Infinity : Number(option.value);
      const limitValue = Number(currentPlan.limit);
      return optionValue > limitValue;
    }
  );

  const getFormDataByType = (data: FormData) => ({
    is_company: data.type === 'company',
    name: data.name,
    email: data.type === 'individual' ? data.email : data.businessEmail,
    ...(data.type === 'individual'
      ? {
          requested_report_limit:
            data.reportsPerMonth === '800+'
              ? 9999
              : Number(data.reportsPerMonth),
        }
      : {
          company_name: data.companyName,
          company_url: data.companyUrl,
        }),
    ...(data.useCase && { use_case: data.useCase }),
    ...(data.otherUseCase && { use_case_description: data.otherUseCase }),
  });

  const onSubmit = (data: FormData) => {
    const leadType = data.type;
    trackEventGA4({
      event_name: 'upsell_selected',
      type:
        leadType === 'individual'
          ? `${data.reportsPerMonth}_plan`
          : 'enterprise_lead',
    });

    const leadData = {
      custom_plan_lead: getFormDataByType(data),
    };

    submitLeadForm({ formData: leadData });

    if (data.reportsPerMonth === '800+' || data.type === 'company') {
      setIsConfirmationModalOpen(true);
    } else if (data.type === 'individual') {
      const selectedPlan = plans.find(
        (plan) => plan.limit === Number(data.reportsPerMonth)
      );
      if (selectedPlan) {
        onSelectPlan(selectedPlan);
      }
    }
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationModalOpen(false);
    navigate('/dashboard');
  };

  return (
    <>
      <StyledModal open={isOpen} onClose={onClose} hasCloseIcon>
        <Stack alignItems="center" p={3} bgcolor={'primary.main'}>
          <StyledImage
            src={constants.images.imgUpgradeLead}
            alt="Upgrade Lead"
          />
          <Stack
            alignItems="center"
            color="white"
            textAlign="center"
            width="80%"
          >
            <Text variant="h2">Interested in Upgrading? Let's Talk!</Text>
            <Text variant="body2">
              We'd love to help you find the perfect plan for your needs. Share{' '}
              <br />
              your details, and our team will be in touch to assist you with the
              upgrade process.
            </Text>
          </Stack>
        </Stack>

        <Stack alignItems="center">
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Text variant="h5" mt={2} mb={0}>
              Are you an individual or a company?
            </Text>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="individual"
                    control={<Radio />}
                    label="Individual"
                  />
                  <FormControlLabel
                    value="company"
                    control={<Radio />}
                    label="Company"
                  />
                </RadioGroup>
              )}
            />

            <Stack
              position="relative"
              display="flex"
              flexDirection="column"
              gap={1.2}
              marginBottom="15px"
            >
              <StyledInputLabel htmlFor="name">Name*</StyledInputLabel>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    fullWidth
                    id="name"
                    type="text"
                    inputProps={{
                      pattern: '[A-Za-zÀ-ÿ\\s]+',
                      title: 'Enter letters only',
                    }}
                    placeholder="Your Full Name"
                    {...field}
                  />
                )}
              />

              {selectedType === 'company' ? (
                <CompanyFields control={control} errors={errors} />
              ) : (
                <IndividualFields
                  control={control}
                  errors={errors}
                  reportsPerMonthOptions={filteredReportsPerMonthOptions}
                  reportsPerMonth={reportsPerMonth || ''}
                />
              )}

              <UseCaseFields
                control={control}
                useCases={useCases}
                selectedUseCase={selectedUseCase || ''}
              />
            </Stack>

            <Button
              id="submit"
              variant="contained"
              color="success"
              type="submit"
              disabled={!isValid}
              fullWidth
            >
              Continue
            </Button>
          </StyledForm>

          <Text variant="body2" textAlign="center" m={2}>
            By submitting this form, you agree to our{' '}
            <UnderlinedLink target="_blank" href={constants.links.privacyLink}>
              Privacy Policy
            </UnderlinedLink>{' '}
            and{' '}
            <UnderlinedLink target="_blank" href={constants.links.tosLink}>
              Terms and Conditions
            </UnderlinedLink>
            .
          </Text>
        </Stack>
      </StyledModal>

      <Modal
        open={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
      >
        <Stack spacing={2} direction="column" alignItems="center">
          <StyledImage
            src={constants.images.imgPremiumData.imgCheckIcon}
            alt="Check Icon"
          />
          <Text variant="h2">Success!</Text>
          <Text>
            Thanks for showing your interest in upgrading. <br />A team member
            will reach out to discuss pricing
          </Text>
          <StyledButton
            id="go-back-dashboard"
            variant="contained"
            color="success"
            onClick={handleCloseConfirmation}
          >
            Go Back to Dashboard
          </StyledButton>
        </Stack>
      </Modal>
    </>
  );
};

export default UpgradeLeadForm;
