import { Box} from '@ltvco/refresh-lib/theme';
import { CheckCircle, LibraryAddCheckOutlined, TimelapseOutlined } from '@mui/icons-material';

interface IconBoxProps {
  status: string;
}

export const AssignmentIcon = ({ status }: IconBoxProps) => {
  return (
    <Box
      height="28px"
      fontSize="1.75rem"
      color={status === 'new' ? 'primary.main' : '#81B44C'}
    >
      {status === 'new' && <LibraryAddCheckOutlined color="inherit" />}
      {status === 'incomplete' && <TimelapseOutlined color="inherit" />}
      {status === 'completed' && <CheckCircle color="inherit" />}
    </Box>
  );
};
