import {
  Text,
  Stack,
  useTheme,
  Box,
} from '@ltvco/refresh-lib/theme';
import { useNavigate } from 'react-router-dom';
import { buildSettings, Setting } from './utils';
import { defaultFalseDataSet, SettingsTileProps } from './constants';
import { StyledSettingsCard, StyledButton, StyledStack } from './components';
import { useContext } from 'react';
import {
  useDarkWebReport,
  useTuxedo,
  useSession,
  LoadingDots,
} from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { CheckCircle } from '@mui/icons-material';

export const SettingsTiles = ({
  email = '',
  setIsSettingsTab,
}: SettingsTileProps) => {
  const navigate = useNavigate();
  const { homeAdditions } = useTuxedo();
  const { trackEvent } = useContext(AppConfig);

  const {
    session: { account },
  } = useSession();

  const {
    data: homeAdditionsData,
    isFetching: isHomeAdditionsLoading,
    isError: isHomeAdditionsError,
  } = homeAdditions(null);

  const { data: darkWebData, isLoading } = useDarkWebReport({
    email: account?.account.user_info?.email as string,
  });

  const theme = useTheme();

  if (isLoading || isHomeAdditionsLoading)
    return (
      <>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: [2.5],
          }}
        >
          <Text variant="h3">Identity Protection Features & Services</Text>
          <Text variant="body1" mb={2.5}>
            Take advantage of identity and credit features and services that can
            help empower and protect you against fraudulent activity.
          </Text>
          <LoadingDots count={4} />
        </Box>
      </>
    );

  let settings: Setting[] = [];
  const isEnrolled =
    account?.account.user_settings.credit_hub_enrollment_completed;
  const darkWebUpdatedAt = darkWebData?.data?.rawData?.meta?.updated_at;
  const hasDataSets = homeAdditionsData?.user_data_sets;
  const hasCreditHubActionDataSet =
    hasDataSets?.user_credit_hub_action_data_set;
  if (hasCreditHubActionDataSet && darkWebUpdatedAt) {
    const dataSet =
      homeAdditionsData.user_data_sets.user_credit_hub_action_data_set;
    settings = buildSettings(dataSet, darkWebUpdatedAt, !!isEnrolled);
  }

  if (
    (isHomeAdditionsError || (hasDataSets && !hasCreditHubActionDataSet)) &&
    darkWebUpdatedAt
  ) {
    settings = buildSettings(
      defaultFalseDataSet,
      darkWebUpdatedAt,
      !!isEnrolled
    );
  }

  const displaySettingsRow = (settings: Setting[]) => {
    return settings.map((setting) => (
      <StyledSettingsCard>
        <Stack gap={1.5}>
          <Stack direction="row" justifyContent={'space-between'}>
            <img src={setting.icon} alt={setting.altIcon} />
            {setting.enrolled !== undefined && (
              <Stack direction="row">
                {setting.enrolled && (
                  <CheckCircle
                    sx={{
                      color: '#81B44C',
                      width: '13px',
                      height: '13px',
                      marginRight: 0.5,
                    }}
                  />
                )}
                <Text variant="body2">
                  {setting.enrolled
                    ? setting.enrolledText
                    : setting.notEnrolledText}
                </Text>
              </Stack>
            )}
          </Stack>
          <Stack gap={1}>
            <Text variant="h6" mb={0}>
              {setting.title}
            </Text>
            <Text variant="body1">
              {setting.enrolled !== undefined && setting.enrolled
                ? setting.enrolledDescription
                : setting.description}
            </Text>
          </Stack>
        </Stack>
        <StyledButton
          theme={theme}
          variant={setting.enrolled ? 'outlined' : 'contained'}
          color="success"
          fullWidth
          onClick={() => {
            trackEvent(
              setting.gaCategory,
              'navigation click',
              'id monitor settings'
            );
            if (setting.activateMonitorTab) {
              setIsSettingsTab(1);
            } else if (setting.navigateTo) {
              navigate(setting.navigateTo);
            }
          }}
        >
          {setting.enrolled ? setting.enrolledBtnText : setting.btnText}
        </StyledButton>
      </StyledSettingsCard>
    ));
  };

  return (
    <Box
      sx={{ backgroundColor: theme.palette.background.paper, padding: [2.5] }}
    >
      <Text variant="h3">Identity Protection Features & Services</Text>
      <Text variant="body1">
        Take advantage of identity and credit features and services that can
        help empower and protect you against fraudulent activity.
      </Text>
      <StyledStack direction={'row'}>
        {displaySettingsRow(settings)}
      </StyledStack>
    </Box>
  );
};
