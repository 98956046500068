import { Box, Stack, Text, styled } from '@ltvco/refresh-lib/theme';
import { ArrowDropUp } from '@mui/icons-material';

const StyledLine = styled(Box)`
  height: 10px;
  width: 100%;

  &.first {
    background-color: #d1cde8;
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
  }
  &.second {
    background-color: #9d95c6;
  }
  &.third {
    background-color: #6a5da4;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
  }
`;

export const AstrologyCompatibilityScale = ({
  compatibilityNumber,
}: {
  compatibilityNumber?: number;
}) => {
  function getCssMeterPosition(number: number) {
    if (number <= 45) {
      return 'start';
    } else if (number >= 75) {
      return 'end';
    } else {
      return 'center';
    }
  }

  return (
    <Stack direction="column" mb={2.5}>
      <Stack direction="row" justifyContent="space-between" mb={0.5}>
        <Text fontSize="0.75rem">LOW</Text>
        <Text fontSize="0.75rem">HIGH</Text>
      </Stack>
      <Stack direction="row" pb={1} position="relative">
        <StyledLine className="line first" mr={0.5} />
        <StyledLine className="line second" mr={0.5} />
        <StyledLine className="line third" />
        <Box
          display="flex"
          position="absolute"
          width="100%"
          justifyContent={getCssMeterPosition(compatibilityNumber || 50)}
        >
          <ArrowDropUp
            sx={{ fontSize: '2rem', color: 'rgba(159, 141, 225, 1)' }}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
