import React, { useContext, useState } from 'react';
import { Button, IconButton, Stack, Text } from '@ltvco/refresh-lib/theme';
import { useFeatureIsOn } from '@ltvco/refresh-lib/vendors';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { Close } from '@mui/icons-material';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

type MobileDownloadGlobalBannerProps = {};

export const MobileDownloadGlobalBanner: React.FC<
  MobileDownloadGlobalBannerProps
> = ({}) => {
  const SessionStorageID = 'hasDismissedMobileDownloadGlobalBanner';

  const isInMobileDownloadGlobalBannerVariation = useFeatureIsOn('OAR-1331');
  const { isMobile } = useScreenSize();

  const { trackEventGA4 } = useContext(AppConfig);

  const [open, setOpen] = useState(
    isInMobileDownloadGlobalBannerVariation &&
      !sessionStorage.getItem(SessionStorageID) &&
      isMobile
  );

  if (!open) {
    return null;
  }

  const openLink = () => {
    trackEventGA4({
      event_name: 'navigation click',
      section_name: 'app_banner',
      type: 'app_banner_download',
      interacted_from: 'beenverified_mobile',
    });

    const oneLink = 'https://beenverified.onelink.me/5mdJ/kbh4s7u9';

    window.location.href = oneLink;
  };

  const hideBanner = () => {
    trackEventGA4({
      event_name: 'modal_closed',
      section_name: 'app_banner',
      type: 'app_banner_close',
      interacted_from: 'beenverified_mobile',
    });
    setOpen(false);
    sessionStorage.setItem(SessionStorageID, 'true');
  };

  return (
    <Stack
      position="fixed"
      width="100%"
      height="50px"
      bgcolor="background.paper"
      bottom={0}
      paddingX={1}
      paddingY={2}
      zIndex={1000}
      direction="row"
      alignItems="center"
      sx={(theme) => ({
        border: `1px solid ${theme.palette.divider}`,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
      })}
    >
      <IconButton
        sx={{ backgroundColor: 'divider', width: 24, height: 24 }}
        onClick={hideBanner}
      >
        <Close sx={{ fontSize: '1rem' }} />
      </IconButton>
      <Text
        fontSize="0.875rem"
        fontWeight="bold"
        margin={0}
        marginLeft={2}
        lineHeight={1}
      >
        View in the BeenVerified App
      </Text>
      <Button
        variant="contained"
        sx={{
          marginLeft: 'auto',
          width: 100,
          height: 40,
          fontSize: '0.875rem',
        }}
        onClick={openLink}
      >
        Download
      </Button>
    </Stack>
  );
};
