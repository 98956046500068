import { Text } from '@ltvco/refresh-lib/theme';
import { constants } from 'appConstants';
import {
  ViewAstrologyButton,
  StyledImg,
  StyledHeaderlighContainer,
  StyledDescription,
} from './components';

interface AstrologyHeaderLightProps {
  enableViewCTA?: boolean;
  onCtaClick?: () => void;
}

export const AstrologyHeaderLight = ({
  enableViewCTA,
  onCtaClick,
}: AstrologyHeaderLightProps) => {
  const newDesignDescriptionStyles = enableViewCTA
    ? {
        width: { xs: '100%', sm: '50%' },
      }
    : {};

  return (
    <StyledHeaderlighContainer
      className="ast-header-light"
      sx={{
        alignItems: enableViewCTA ? 'center' : 'unset',
        justifyContent: 'space-between',
      }}
    >
      <StyledImg
        className="image"
        src={constants.images.astrology.imgAstrologyNumerology}
        alt="Astrology Insights"
        width="106px"
      />
      <StyledDescription
        className="description"
        sx={newDesignDescriptionStyles}
      >
        <Text variant="h3" mb={1}>
          Astrological Insights
        </Text>
        <Text mb={2.5}>
          Get personalized daily horoscopes for guidance in work, love, and
          personal growth.
        </Text>
      </StyledDescription>
      {enableViewCTA && (
        <ViewAstrologyButton
          variant="contained"
          type="submit"
          color="success"
          onClick={onCtaClick}
        >
          View Insights
        </ViewAstrologyButton>
      )}
    </StyledHeaderlighContainer>
  );
};
