import { useContext, useEffect, useState } from 'react';
import { Box, Grid, Text } from '@ltvco/refresh-lib/theme';
import { languageName, translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { useNavigate } from 'react-router-dom';
import { AssignmentCard } from '../AssignmentCard/AssignmentCard';
import { useBumpeeQuizChats } from 'components/Language/hooks/useBumpeeQuizChats';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

interface Quiz {
  id: number;
  title: string;
  status: string;
  quizChatId: number | undefined;
}

export const QuizzesContainer = () => {
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);

  const redirect = useNavigate();
  const { trackEventGA4 } = useContext(AppConfig);
  const { language, lessonLearningLanguage } = useLanguageContext();
  const { data: userQuizChats, createQuizChatMutation } = useBumpeeQuizChats();

  useEffect(() => {
    const quizzes = translations.dashboard.assignments.quiz.quizList[language];
    const modifiedLessons = quizzes?.map((quiz, index) => {
      const userQuiz = userQuizChats?.quiz_chats.find(
        (userQuiz: { lesson_id: number; learning_language_id: number; }) =>
          userQuiz.lesson_id === quiz.id &&
          userQuiz.learning_language_id ===
            parseInt(lessonLearningLanguage.value)
      );

      return {
        ...quiz,
        title: `Quiz ${index + 1}: ${quiz.title}`,
        status: userQuiz?.status ?? 'new',
        quizChatId: userQuiz?.id,
      };
    });

    setQuizzes(modifiedLessons);
  }, [language, userQuizChats, lessonLearningLanguage.value]);

  const redirectToQuiz = (quizId: number) => {
    const quiz = quizzes.find((quiz) => quiz.id === quizId);
    if (!quiz) return;

    if (quiz.quizChatId) {
      return redirect(`/dashboard/language/quiz/${quiz.quizChatId}`);
    }

    const quizChatParams = {
      lesson_id: quiz.id,
      learning_language_id: parseInt(lessonLearningLanguage.value),
    };

    createQuizChatMutation.mutate(quizChatParams);

    trackEventGA4({
      event_name: 'Language Tutor Engagement',
      type: 'quiz',
      language: languageName(parseInt(lessonLearningLanguage.value)),
      action: 'start',
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Text variant="h3" color="primary.main">
        {translations.dashboard.assignments.quiz.header[language]}
      </Text>
      <Grid container rowSpacing={2} columnSpacing={{ sm: 3 }}>
        {quizzes?.map((quiz) => (
          <AssignmentCard
            key={quiz.id}
            assignment={quiz}
            handleRedirectToAssignment={redirectToQuiz}
          />
        ))}
      </Grid>
    </Box>
  );
};
