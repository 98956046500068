import nameMonitoringIcon from 'images/id-monitor-name-monitoring-icon.svg';
import emailMonitoringIcon from 'images/id-monitor-email-monitoring-icon.svg';
import phoneMonitoringIcon from 'images/id-monitor-phone-monitoring-icon.svg';
import vehicleMonitoringIcon from 'images/id-monitor-vehicle-monitoring-icon.svg';
import propertyMonitoringIcon from 'images/id-monitor-property-monitoring-icon.svg';
import socialMediaMonitoringIcon from 'images/id-monitor-social-media-monitoring-icon.svg';
import neighborhoodWatchMonitoringIcon from 'images/id-monitor-neighborhood-watch-monitoring-icon.svg';
import { Text, Divider } from '@ltvco/refresh-lib/theme';
import { RawReportType } from '@ltvco/refresh-lib/v1';
import { capitalizeString, DateUtil } from '@ltvco/refresh-lib/utils';

export type SerializedReport = {
  location?: string;
  updatedAt: string;
  createdAt: string;
  permalink: string;
  name: string;
  reportType: RawReportType;
};

// *****************************************************
// * TODO: Fix contact report support
// * Placeholder code added to enable build
// *****************************************************

export const displayName: Record<
  RawReportType,
  (serializedReportMonitor: SerializedReport) => string
> = {
  social_network_report: (serializedReportMonitor: SerializedReport) => {
    return serializedReportMonitor?.name;
  },
  detailed_person_report: (serializedReportMonitor: SerializedReport) => {
    return capitalizeString(serializedReportMonitor?.name || '');
  },
  username_report: (serializedReportMonitor: SerializedReport) => {
    return serializedReportMonitor?.name;
  },
  reverse_phone_report: (serializedReportMonitor: SerializedReport) => {
    return serializedReportMonitor?.name;
  },
  property_report: (serializedReportMonitor: SerializedReport) => {
    return serializedReportMonitor?.name;
  },
  vehicle_report: (serializedReportMonitor: SerializedReport) => {
    const vehicleName = serializedReportMonitor?.name;

    if (vehicleName.includes(' ')) {
      return vehicleName;
    }

    return serializedReportMonitor?.name?.toUpperCase();
  },
  sex_offender_report: (serializedReportMonitor: SerializedReport) => {
    return '';
  },
  detailed_business_report: (serializedReportMonitor: SerializedReport) => {
    return '';
  },
  contact_report: (serializedReportMonitor: SerializedReport) => {
    return '';
  },
  ancestry_report: (serializedReportMonitor: SerializedReport) => {
    return '';
  },
};

export const containerIcon: Record<RawReportType, string> = {
  social_network_report: emailMonitoringIcon,
  detailed_person_report: nameMonitoringIcon,
  username_report: socialMediaMonitoringIcon,
  reverse_phone_report: phoneMonitoringIcon,
  sex_offender_report: neighborhoodWatchMonitoringIcon,
  property_report: propertyMonitoringIcon,
  vehicle_report: vehicleMonitoringIcon,
  detailed_business_report: '',
  contact_report: '',
  ancestry_report: '',
};

const containerButtonMapping: Record<RawReportType, string> = {
  detailed_person_report: 'name',
  username_report: 'account',
  social_network_report: 'email',
  reverse_phone_report: 'phone',
  sex_offender_report: 'area',
  property_report: 'property',
  vehicle_report: 'vehicle',
  detailed_business_report: '',
  contact_report: '',
  ancestry_report: '',
};

export const addMonitorText = (rawReportType: RawReportType) => {
  return `Add ${containerButtonMapping[rawReportType]}`;
};

export const claimMonitorText = (rawReportType: RawReportType) => {
  return `Claim ${containerButtonMapping[rawReportType]}`;
};

export const addFormText: Record<RawReportType, string> = {
  username_report: 'social media profile',
  social_network_report: 'email',
  reverse_phone_report: 'phone number',
  property_report: 'property',
  vehicle_report: 'vehicle',
  sex_offender_report: '',
  detailed_person_report: '',
  detailed_business_report: '',
  contact_report: '',
  ancestry_report: '',
};

export const formPlaceHolder: Record<RawReportType, string> = {
  username_report: 'Username or profile link (Ex. facebook/com/johnsmith)',
  social_network_report: 'E.g. johnsmith@gmail.com',
  reverse_phone_report: '(___) ___-____',
  property_report: 'property',
  vehicle_report: 'vehicle',
  sex_offender_report: '',
  detailed_person_report: '',
  detailed_business_report: '',
  contact_report: '',
  ancestry_report: '',
};

const containerTitle: Record<RawReportType, string> = {
  social_network_report: 'Email',
  detailed_person_report: 'Name',
  username_report: 'Social Media',
  reverse_phone_report: 'Phone',
  property_report: 'Property',
  vehicle_report: 'Vehicle',
  sex_offender_report: 'Neighborhood',
  detailed_business_report: '',
  contact_report: '',
  ancestry_report: '',
};

export const containerTitleText = (
  rawReportType: RawReportType,
  newLineRequired: boolean
) => {
  return `${containerTitle[rawReportType]}${newLineRequired ? '\n' : ' '}${
    rawReportType === 'sex_offender_report' ? 'Watch' : 'Monitoring'
  }`;
};

const dateParser = new DateUtil();

export const parsedDate = (date = '') => {
  return dateParser.parseDateFromString(date, 'LL/dd/yyyy') || '--/--/----';
};

export const additionalCardText: Record<
  RawReportType,
  (serializedReportMonitor: SerializedReport) => JSX.Element | void
> = {
  detailed_person_report: (serializedReportMonitor: SerializedReport) => {
    const updatedAt = parsedDate(serializedReportMonitor?.updatedAt).toString();

    return (
      <>
        <Text variant="body1" key="2">
          {serializedReportMonitor?.location}
        </Text>
        <Divider orientation={'vertical'} flexItem />
        <Text variant="body1" key="3">
          Last updated {updatedAt}
        </Text>
        <Divider orientation={'vertical'} flexItem />
      </>
    );
  },
  property_report: (serializedReportMonitor: SerializedReport) => {
    return (
      <>
        <Text variant="body1" key="2">
          {serializedReportMonitor?.location}
        </Text>
        <Divider orientation={'vertical'} flexItem />
      </>
    );
  },
  username_report: (serializedReportMonitor: SerializedReport) => {},
  reverse_phone_report: (serializedReportMonitor: SerializedReport) => {},
  vehicle_report: (serializedReportMonitor: SerializedReport) => {},
  social_network_report: (serializedReportMonitor: SerializedReport) => {},
  sex_offender_report: (serializedReportMonitor: SerializedReport) => {},
  detailed_business_report: (serializedReportMonitor: SerializedReport) => {},
  contact_report: (serializedReportMonitor: SerializedReport) => {},
  ancestry_report: (serializedReportMonitor: SerializedReport) => {},
};

const mainSocialMediaNetworks = [
  'Facebook',
  'Instagram',
  '𝕏',
  'LinkedIn',
  'Youtube',
  'Pinterest',
  'eBay',
];

const otherSocialMediaNetworks = [
  'Aboutme',
  'Badoo',
  'Bebo',
  'Classmates',
  'CPF',
  'Cyworld',
  'Delicious',
  'Deviantart',
  'Diggtagged',
  'Douban',
  'Flavorsme',
  'Flickr',
  'Flixster',
  'Foursquare',
  'Freelancer',
  'Friendster',
  'Gaia',
  'Github',
  'Goodreads',
  'Google',
  'Gravatar',
  'Habbo',
  'Hi5',
  'Hyves',
  'Imgur',
  'Lastfm',
  'Livejournal',
  'Meetup',
  'Myheritage',
  'Mylife',
  'Myspace',
  'Myyearbook',
  'Netlog',
  'Ning',
  'Odnoklassniki',
  'Orkut',
  'Quora',
  'Qzone',
  'Renren',
  'Sonico',
  'Soundcloud',
  'Stumbleupon',
  'Tumblr',
  'Viadeo',
  'Vkontakte',
  'Weeworld',
  'Xanga',
  'Xing',
  'Yelp',
];

export const socialMediaNetworkOptions = () => {
  let socialMediaSelectOptions: {
    display: string | JSX.Element | boolean;
    value: string;
    limiter?: boolean;
  }[] = [];

  const pushNetworkToOptions = (
    networks: string[],
    socialMediaSelectOptions: Record<string, string | JSX.Element | boolean>[]
  ) => {
    networks.forEach((network) => {
      socialMediaSelectOptions.push({
        display: network,
        value: network.toLowerCase(),
      });
    });
  };

  socialMediaSelectOptions.push({ display: 'All', value: 'all' });

  socialMediaSelectOptions.push({
    display: <Divider orientation={'horizontal'} flexItem />,
    value: 'limiter1',
    limiter: true,
  });

  pushNetworkToOptions(mainSocialMediaNetworks, socialMediaSelectOptions);

  socialMediaSelectOptions.push({
    display: <Divider orientation={'horizontal'} flexItem />,
    value: 'limiter2',
    limiter: true,
  });

  pushNetworkToOptions(otherSocialMediaNetworks, socialMediaSelectOptions);

  return socialMediaSelectOptions;
};

export const maxMonitors = 10;
