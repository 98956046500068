import { Stack, Button, Box, Text, styled } from '@ltvco/refresh-lib/theme';
import { Message, StoryChatConversation } from 'api/requests/Bumpee/interfaces';
import { LeftBubble } from '../ChatBubble/components';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { useEffect, useState } from 'react';
import { Markdown } from '../Markdown/Markdown';
import { translations } from 'components/Language/constants';
import { useAudioPlayer } from 'components/Language/hooks/useAudioPlayer';
import { PauseCircleOutlineOutlined, ReplayOutlined, PlayCircleFilled, FeedOutlined } from '@mui/icons-material';

const PlayPauseIconContainer = styled(Stack)(({ theme }) => ({
  height: '32px',
  width: '32px',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '1rem',
  fontSize: '2rem',
}));

const ChatBubbleInvertedBackgroundContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '4px',
}));

const ActionButtonsContainer = styled(Stack)({
  button: {
    minWidth: '18px',
    padding: '6px',
  },
});

interface ConversationAudioBubbleProps {
  message: Message;
  storyChatId?: number;
  segmentIndex: number;
  conversation: StoryChatConversation;
  playingAudioId: number | null;
  setPlayingAudioId: (segmentIndex: number | null) => void;
}

export function ConversationAudioBubble({
  message,
  storyChatId,
  segmentIndex,
  conversation,
  playingAudioId,
  setPlayingAudioId,
}: ConversationAudioBubbleProps) {
  const {
    sourceRef,
    playAudio,
    audioRef,
    handlePause,
    audioIsPlaying,
    handleEnded,
    audioHasEnded,
  } = useAudioPlayer(
    storyChatId,
    segmentIndex,
    playingAudioId,
    setPlayingAudioId
  );
  const { language } = useLanguageContext();
  const [showTranscript, setShowTranscript] = useState<boolean>(false);
  const [showQuestion, setShowQuestion] = useState<boolean>(false);

  const transcript = conversation.segments[segmentIndex].dialogue
    .map((dialogue) => `**${dialogue.character_name}**: ${dialogue.text}`)
    .join('\n\n');

  const handleTranscriptClick = () => {
    setShowTranscript(!showTranscript);
  };

  useEffect(() => {
    if (audioHasEnded || showTranscript) {
      setShowQuestion(true);
    }
  }, [audioHasEnded, showTranscript]);

  if (segmentIndex < 0) return;

  return (
    <>
      <LeftBubble>
        <Stack width="100%">
          <ChatBubbleInvertedBackgroundContainer>
            <Box display="flex" flexWrap="wrap">
              <Button onClick={playAudio}>
                <Stack flexDirection="row" alignItems="center">
                  <PlayPauseIconContainer>
                    {audioIsPlaying ? (
                      <PauseCircleOutlineOutlined />
                    ) : audioHasEnded &&
                      audioRef &&
                      audioRef.current &&
                      audioRef.current.currentTime ===
                        audioRef?.current.duration ? (
                      <ReplayOutlined />
                    ) : (
                      <PlayCircleFilled />
                    )}
                  </PlayPauseIconContainer>
                  <Text fontWeight="bold">
                    {translations.buttons.playConversationSegment[language]}
                  </Text>
                </Stack>
                <audio
                  ref={audioRef}
                  onEnded={handleEnded}
                  onPause={handlePause}
                >
                  <source ref={sourceRef} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </Button>
            </Box>
          </ChatBubbleInvertedBackgroundContainer>
          {showTranscript && (
            <Box py={1}>
              <Markdown content={transcript} />
            </Box>
          )}
          <ActionButtonsContainer
            flexDirection={'row'}
            justifyContent={'flex-end'}
          >
            <Button onClick={handleTranscriptClick}>
              <FeedOutlined
                fontSize="small"
                sx={{ color: 'primary.main' }}
              />
            </Button>
          </ActionButtonsContainer>
        </Stack>
      </LeftBubble>
      {showQuestion && (
        <LeftBubble>
          <Stack width="100%">
            <Box mb={1} display="flex" flexWrap="wrap">
              <Markdown content={message.content} />
            </Box>
          </Stack>
        </LeftBubble>
      )}
    </>
  );
}
