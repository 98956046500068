import {
  Button,
  Stack,
  Text,
  Card,
  useTheme,
} from '@ltvco/refresh-lib/theme';
import { StyledStack } from '../components';
import { StyledImg } from '../../components';
import { useAchievementContext } from 'components/Language/AchievementContext';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { translations } from 'components/Language/constants';
import { capitalizeString } from '@ltvco/refresh-lib/utils';
import { WorkspacePremiumRounded } from '@mui/icons-material';

interface AchievementsInfoProps {
  handleToggleAchievementsModal: () => void;
}

export const AchievementsInfo = ({
  handleToggleAchievementsModal,
}: AchievementsInfoProps) => {
  const theme = useTheme();
  const { recentlyEarnedAchievement } = useAchievementContext();
  const { language } = useLanguageContext();

  const titleToDisplay = recentlyEarnedAchievement ? 'latest' : 'first';
  const translatedTitle =
    translations.achievements.dashboardCard.title[language][titleToDisplay];

  return (
    <Card variant="outlined">
      <StyledStack
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          display="flex"
          flexDirection="row"
          width="100%"
          alignItems="center"
          mt={{ xs: 0, md: 1.5 }}
        >
          {recentlyEarnedAchievement ? (
            <StyledImg
              src={recentlyEarnedAchievement?.image}
              alt={recentlyEarnedAchievement?.name}
              height="60px"
              width="60px"
              sx={{ mr: 2, border: 'none', boxShadow: 'none' }}
            />
          ) : (
            <WorkspacePremiumRounded
              sx={{ fontSize: '3.75rem', mb: 2, mr: 1, opacity: 0.5, color: theme.palette.primary.main }}
            />
          )}
          <Stack>
            <Text fontSize="0.875rem" sx={{ color: 'secondary.contrastText' }}>
              {translatedTitle}
            </Text>
            <Text
              variant="h3"
              mt={0.5}
              sx={{ color: 'secondary.contrastText' }}
            >
              {recentlyEarnedAchievement
                ? recentlyEarnedAchievement?.name
                : `${capitalizeString(
                    translations.general.achievement[language]
                  )}!`}
            </Text>
          </Stack>
        </Stack>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleToggleAchievementsModal}
        >
          {translations.buttons.viewAchievements[language]}
        </Button>
      </StyledStack>
    </Card>
  );
};
