import {
  formatPhone,
  unformatPhone,
  useStates,
} from '@ltvco/refresh-lib/utils';
import React from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { SignUpFormValues } from './types';
import { Grid, InputLabel, Select, Input } from '@ltvco/refresh-lib/theme';

export const FormStepOne: React.FC<{
  register: UseFormRegister<SignUpFormValues>;
  errors: FieldErrors<SignUpFormValues>;
  setValue: UseFormSetValue<SignUpFormValues>;
}> = ({ register, errors, setValue }) => {
  const states = useStates().map((state) => {
    return {
      value: state.abbr === 'All' ? '' : state.abbr,
      display: state.abbr,
    };
  });

  return (
    <>
      <Grid item xs={12} sm={5.85}>
        <InputLabel
          htmlFor="first_name"
          sx={{ fontWeight: 'bold', marginBottom: [1] }}
        >
          First name
        </InputLabel>
        <Input
          {...register('first_name', { required: true })}
          id="first_name"
          key="first_name"
          fullWidth
          placeholder="First Name"
          error={Boolean(errors.first_name)}
        />
      </Grid>
      <Grid item xs={0} sm={0.3} />
      <Grid item xs={12} sm={5.85}>
        <InputLabel
          htmlFor="last_name"
          sx={{ fontWeight: 'bold', marginBottom: [1] }}
        >
          Last name
        </InputLabel>
        <Input
          {...register('last_name', { required: true })}
          id="last_name"
          key="last_name"
          fullWidth
          placeholder="Last Name"
          error={Boolean(errors.last_name)}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        <InputLabel
          htmlFor="address"
          sx={{ fontWeight: 'bold', marginBottom: [1] }}
        >
          Address
        </InputLabel>
        <Input
          {...register('address', { required: true })}
          id="address"
          key="address"
          fullWidth
          placeholder="Address"
          error={Boolean(errors.address)}
        />
      </Grid>
      <Grid item xs={0} sm={0.36}></Grid>
      <Grid item xs={12} sm={1.64}>
        <InputLabel
          htmlFor="apt"
          sx={{ fontWeight: 'bold', marginBottom: [1] }}
        >
          Apt.
        </InputLabel>
        <Input
          {...register('apt', { required: true })}
          id="apt"
          key="apt"
          fullWidth
          placeholder="Apartment"
          error={Boolean(errors.apt)}
        />
      </Grid>
      <Grid item xs={12} sm={8.16}>
        <InputLabel
          htmlFor="city"
          sx={{ fontWeight: 'bold', marginBottom: [1] }}
        >
          City
        </InputLabel>
        <Input
          {...register('city', { required: true })}
          id="city"
          key="city"
          fullWidth
          placeholder="City"
          error={Boolean(errors.city)}
        />
      </Grid>
      <Grid item xs={0} sm={0.3}></Grid>
      <Grid item xs={12} sm={1.62}>
        <InputLabel
          htmlFor="State"
          sx={{ fontWeight: 'bold', marginBottom: [1] }}
        >
          State
        </InputLabel>
        <Select
          {...register('state', {
            onChange: (e) => setValue('state', e.target.value),
          })}
          options={states}
          id="state"
          key="state"
          fullWidth
          error={Boolean(errors.state)}
        />
      </Grid>
      <Grid item xs={0} sm={0.3}></Grid>
      <Grid item xs={12} sm={1.62}>
        <InputLabel
          htmlFor="zip"
          sx={{ fontWeight: 'bold', marginBottom: [1] }}
        >
          Zip code
        </InputLabel>
        <Input
          {...register('zip', {
            required: true,
            onChange: (e) => {
              let zip = unformatPhone(e.target.value);
              zip = zip.substring(0, 5);

              setValue('zip', zip);
            },
          })}
          id="zip"
          key="zip"
          type="tel"
          fullWidth
          placeholder="Zip code"
          error={Boolean(errors.zip)}
        />
      </Grid>
      <Grid item xs={12} sm={3.79}>
        <InputLabel
          htmlFor="phone_number"
          sx={{ fontWeight: 'bold', marginBottom: [1] }}
        >
          Phone number
        </InputLabel>
        <Input
          {...register('phone_number', {
            required: true,
            onChange: (e) => {
              let phone = unformatPhone(e.target.value);
              if (phone[0] === '1') {
                phone = phone.substring(0, 11);
              } else {
                phone = phone.substring(0, 10);
              }

              setValue('phone_number', formatPhone(phone));
            },
          })}
          id="phone_number"
          key="phone_number"
          type="tel"
          name="phone_number"
          placeholder="(123) 456-7890"
          error={Boolean(errors.phone_number?.message)}
          helperText={errors.phone_number?.message}
          sx={{ fontSize: '1rem' }}
          fullWidth
        />
      </Grid>
    </>
  );
};
