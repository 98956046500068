import { useContext, useEffect, useState } from 'react';
import { OxfordHelpers } from './Helpers';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { createContext } from 'react';

const otpScriptUrl =
  'https://cdnx.beenverified.com/assets/integrations/otp_loader.js';
const _delayRequest = 3000;

interface CustomWindow extends Window {
  IGLOO?: {
    getBlackbox: () => {
      finished: boolean;
      blackbox: string;
    };
  };
}

interface OneTimePasscodeContextType {
  blackBoxId: string | undefined;
}

export const OneTimePasscodeContext = createContext<OneTimePasscodeContextType>(
  { blackBoxId: '' }
);

export const useOneTimePasscodeContext = () => {
  const context = useContext(OneTimePasscodeContext);

  if (context === undefined) {
    throw new Error(
      'useOneTimePasscodeContext must be used within a OneTimePasscodeProvider'
    );
  }

  return context;
};

export const OneTimePasscodeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [blackBoxId, setBlackBoxId] = useState<string | undefined>();
  const [isLoaded, setIsLoaded] = useState(false);

  const { logError } = useContext(AppConfig);

  const loadScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = otpScriptUrl;
      document.head.appendChild(script);

      script.addEventListener('load', () => {
        setIsLoaded(true);
        resolve(script);
      });

      script.addEventListener('error', () => {
        reject(new Error(`${otpScriptUrl} failed to load.`));
      });
    });
  };

  const load = async () => {
    if (!isLoaded) {
      try {
        await loadScript();
      } catch (e: unknown) {
        if (e instanceof Error) {
          logError('Error loading one time password script', e);
        }
      }
    }
  };

  const getBlackBoxId = async () => {
    let attempts = 0;
    const maxAttempts = 2;

    while (attempts < maxAttempts) {
      try {
        const bb = (window as CustomWindow)?.IGLOO?.getBlackbox();

        if (bb?.finished) {
          return bb.blackbox as string;
        } else {
          attempts += 1;
          await OxfordHelpers.wait(_delayRequest);
        }
      } catch (e) {
        attempts += 1;
        await OxfordHelpers.wait(_delayRequest);
      }
    }

    return '';
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    async function storeBlackBoxId() {
      const bb = await getBlackBoxId();
      setBlackBoxId(bb);
    }

    storeBlackBoxId();
  }, [isLoaded]);

  return (
    <OneTimePasscodeContext.Provider value={{ blackBoxId }}>
      {children}
    </OneTimePasscodeContext.Provider>
  );
};
