import {
  Checkbox,
  Divider,
  FormControlLabel,
  Input,
  Text,
} from '@ltvco/refresh-lib/theme';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Stack } from '@mui/system';
import { Grid, InputLabel } from '@mui/material';
import { SignUpFormValues } from './types';
import { unformatPhone } from '@ltvco/refresh-lib/utils';

export const FormStepTwo: React.FC<{
  register: UseFormRegister<SignUpFormValues>;
  errors: FieldErrors<SignUpFormValues>;
  setValue: UseFormSetValue<SignUpFormValues>;
  control: Control<SignUpFormValues>;
}> = ({ register, errors, setValue, control }) => {
  return (
    <>
      <Grid item xs={12} sm={5.85}>
        <InputLabel
          htmlFor="tokenized_ssn"
          sx={{ fontWeight: 'bold', marginBottom: [1] }}
        >
          Social security number
        </InputLabel>
        <Input
          {...register('tokenized_ssn', {
            required: true,
            onChange: (e) => {
              let tokenized_ssn = unformatPhone(e.target.value);
              return setValue('tokenized_ssn', tokenized_ssn.substring(0, 9));
            },
          })}
          id="tokenized_ssn"
          key="tokenized_ssn"
          type="password"
          fullWidth
          placeholder="Social Security number"
          error={Boolean(errors.tokenized_ssn)}
        />
      </Grid>
      <Grid item xs={0} sm={0.3} />
      <Grid item xs={12} sm={5.85}>
        <InputLabel
          htmlFor="tokenized_ssn_confirmation"
          sx={{ fontWeight: 'bold', marginBottom: [1] }}
        >
          Re-enter social security number
        </InputLabel>
        <Input
          {...register('tokenized_ssn_confirmation', {
            required: true,
            onChange: (e) => {
              let tokenized_ssn = unformatPhone(e.target.value);
              return setValue(
                'tokenized_ssn_confirmation',
                tokenized_ssn.substring(0, 9)
              );
            },
          })}
          id="tokenized_ssn_confirmation"
          key="tokenized_ssn_confirmation"
          type="password"
          fullWidth
          placeholder="Re-enter social security number"
          error={Boolean(errors.tokenized_ssn_confirmation)}
          helperText={errors.tokenized_ssn_confirmation?.message}
        />
      </Grid>
      <Grid item xs={12} sm={5.85}>
        <InputLabel
          htmlFor="tokenized_dob"
          sx={{ fontWeight: 'bold', marginBottom: [1] }}
        >
          Date of birth
        </InputLabel>
        <Controller
          name="tokenized_dob"
          control={control}
          render={({
            field: { onChange, ref, name },
            fieldState: { error },
          }) => (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DateField
                onChange={(value: DateTime | null) => {
                  return onChange(value?.toFormat('yyyy-LL-dd'));
                }}
                sx={{ width: ['100%', 'initial'] }}
                inputRef={ref}
                name={name}
                id="date-picker"
                slotProps={{
                  textField: { error: !!error, helperText: error?.message },
                }}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>

      <Divider
        sx={{ width: '100%', marginTop: [2.5, 4.5], marginBottom: [2, 2] }}
      />

      <Stack flexDirection="row">
        <FormControlLabel
          sx={{ display: 'block' }}
          control={
            <Checkbox
              {...register('terms_and_conditions', { required: true })}
              sx={{
                color: Boolean(errors?.terms_and_conditions) ? 'red' : 'black',
              }}
            />
          }
          label={<></>}
        />
        <Text variant="body2">
          By checking this box and clicking on the “Authorize” button below, you
          agree to the <b>terms and conditions</b>, acknowledge receipt of our{' '}
          <b>privacy policy</b> and agree to its terms, and confirm your
          authorization for BeenVerified, LLC to obtain your credit profile from
          any consumer reporting agency to display your credit information to
          you, to confirm your identity to help avoid fraudulent transactions in
          your name, and to enable any consumer reporting agencies to monitor
          your credit for changes.
        </Text>
      </Stack>
    </>
  );
};
