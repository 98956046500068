import {
  Button,
  Divider,
  Grid,
  Stack,
  Text,
} from '@ltvco/refresh-lib/theme';
import { summaries, GetStartedSummaryDetailsProps } from './constants';
import idMonitorColorLogo from 'images/id-monitor-color-logo.svg';
import { useContext } from 'react';
import { useSessionEscalation } from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

const GetStartedSummaryDetails: React.FC<GetStartedSummaryDetailsProps> = ({
  img,
  title,
  summary,
}) => {
  return (
    <Grid item xs={12} sm={5.9}>
      <Stack direction="row" alignItems="flex-start" textAlign="left">
        <img src={img} width="35px" height="35px" alt="id-monitor-summary" />
        <Stack direction="column" sx={{ marginLeft: '12px' }}>
          <Text variant="h1" fontSize="16px" sx={{ marginBottom: '4px' }}>
            {title}
          </Text>
          <Text variant="body1">{summary}</Text>
        </Stack>
      </Stack>
    </Grid>
  );
};

export const Summaries = () => {
  const { trackEvent, trackEventGA4 } = useContext(AppConfig);

  const {
    checkSessionEscalation: { refetch: getSessionEscalation },
  } = useSessionEscalation();

  const handleClick = () => {
    trackEvent('id monitor start', 'navigation click', 'id monitor enrollment');
    trackEventGA4({
      event_name: 'id_monitor_enrollment',
      step: 'id_monitor_start',
    });
    getSessionEscalation();
  };

  return (
    <>
      <img src={idMonitorColorLogo} alt="id-monitor-color-logo" />
      <Text variant="h2" fontSize="30px" fontWeight="bold">
        ID Monitor
      </Text>
      <Text variant="h2" fontSize="20px" textAlign="center">
        Maximize your credit and identity management tools by connecting your
        credit record from TransUnion®
      </Text>
      <Grid
        container
        marginTop={4}
        sx={{
          marginBottom: [2, 2.5],
          border: '1px solid #E2E2E2',
          borderRadius: '4px',
          rowGap: [2, 2.5],
          padding: [2, 2.5],
          justifyContent: ['default', 'space-between'],
        }}
      >
        {summaries.map((summary) => (
          <GetStartedSummaryDetails
            key={summary.title}
            img={summary.img}
            title={summary.title}
            summary={summary.summary}
          />
        ))}
      </Grid>
      <Button
        onClick={() => handleClick()}
        variant="contained"
        sx={{
          width: ['100%', '172px'],
        }}
      >
        Get Started
      </Button>
      <Divider sx={{ marginY: [2, 2.5], width: '100%' }} />
      <Text marginBottom={[2, 2.5]} color="#757575">
        *Please answer required personal information to verify your identity and
        allow us to perform a soft pull on your TransUnion credit record. A soft
        pull does NOT affect your credit score.
      </Text>
    </>
  );
};
