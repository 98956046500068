import { Box } from '@ltvco/refresh-lib/theme';
import { SelectChangeEvent } from '@mui/material';

interface DrawerItemProps {
  handleLanguageDropDown: (event: SelectChangeEvent) => void;
  language: { value: string; display: string };
  toggleDrawer: () => void;
}

export const DrawerItem = ({
  handleLanguageDropDown,
  language,
  toggleDrawer,
}: DrawerItemProps) => {
  const handleLanguageSwitch = () => {
    handleLanguageDropDown({
      target: { value: language.value },
    } as SelectChangeEvent);
    toggleDrawer();
  };
  return (
    <Box p={2} key={language.value} onClick={handleLanguageSwitch}>
      {language.display}
    </Box>
  );
};
