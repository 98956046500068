import { BumpeeRequests, CreateStoryChatParams } from 'api';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguageContext } from '../LanguageContext';
import { enqueueSnackbar, useServiceToken } from '@ltvco/refresh-lib/v1';
import { useMutation, useQuery, useQueryClient } from '@ltvco/refresh-lib/vendors';
import { AppConfig } from '@ltvco/refresh-lib/ctx';

const baseQueryKey = 'bumpeeGO';

export const useBumpeeStoryChats = () => {
  const redirect = useNavigate();
  const queryClient = useQueryClient();
  const { data: tokenData } = useServiceToken('bumpee');
  const { logError } = useContext(AppConfig);
  const { toggleLoadingLessonModal } = useLanguageContext();

  const results = useQuery({
    queryKey: [`${baseQueryKey}-story-chats`],
    queryFn: () => BumpeeRequests.getStoryChats(tokenData?.token),
    enabled: Boolean(tokenData?.token),
  });

  const createStoryChatMutation = useMutation({
    mutationFn: (params: CreateStoryChatParams) => {
      return BumpeeRequests.createStoryChat(tokenData.token, params);
    },
    onMutate: () => toggleLoadingLessonModal(),
    onSuccess: (data) => {
      queryClient.invalidateQueries([`${baseQueryKey}-story-chats`]);
      redirect(`/dashboard/language/story/${data.id}`);
    },
    onError: (error: Error) => {
      enqueueSnackbar('Failed to create a story', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Failed to create story', error as Error);
    },
    onSettled: () => toggleLoadingLessonModal(),
  });

  return { ...results, createStoryChatMutation };
};
