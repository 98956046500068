import { useQuery } from '@ltvco/refresh-lib/vendors';
import { enqueueSnackbar, useServiceToken } from '@ltvco/refresh-lib/v1';
import { BumpeeRequests } from 'api';

const baseQueryKey = 'bumpeeGO';

export const useBumpeeLessonChat = (lessonChatId: string | undefined) => {
  const { data: tokenData } = useServiceToken('bumpee');

  const results = useQuery({
    queryKey: [`${baseQueryKey}-lesson-chat-${lessonChatId}`],
    queryFn: () => BumpeeRequests.getLessonChat(tokenData.token, lessonChatId!),
    enabled: Boolean(tokenData) && Boolean(lessonChatId),
    onError: (error) => {
      enqueueSnackbar('Failed to get chat', { variant: 'error' });
    },
  });

  return { ...results };
};
