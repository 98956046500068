import { Box, Button, Stack, Text } from '@ltvco/refresh-lib/theme';
import { StyledModal } from './components';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { languageName, translations } from 'components/Language/constants';

interface TransitionChatModalProps {
  setNewTransitionModalOpen: () => void;
  learningLanguage?: number;
  nativeLanguage: string;
}

export const TransitionChatModal = ({
  setNewTransitionModalOpen,
  learningLanguage,
  nativeLanguage,
}: TransitionChatModalProps) => {
  return (
    <StyledModal open hasCloseIcon onClose={setNewTransitionModalOpen}>
      <Stack display="flex" alignItems="center" justifyContent="center">
        <Box>
          <img
            src={translations.avatar[nativeLanguage.toLowerCase()]}
            alt="Beverly"
          />
        </Box>
        <Text variant="h3" mb={1}>
          {
            translations.transitionChatModal.greeting[
              nativeLanguage.toLowerCase()
            ]
          }
        </Text>
        <Text variant="body1" color="primary.light" mb={3}>
          {
            translations.transitionChatModal.description[
              nativeLanguage.toLowerCase()
            ].start
          }{' '}
          {translations.languages[nativeLanguage.toLowerCase()]
            .find((language) => language.id === learningLanguage)
            ?.name.toLowerCase()}{' '}
          {
            translations.transitionChatModal.description[
              nativeLanguage.toLowerCase()
            ].end
          }
        </Text>
        <Button
          className="language-continue"
          variant="contained"
          onClick={setNewTransitionModalOpen}
        >
          {translations.buttons.begin[nativeLanguage.toLowerCase()]}
        </Button>
      </Stack>
    </StyledModal>
  );
};
