import { Box, Button, styled } from '@ltvco/refresh-lib/theme';

export const StyledButton = styled(Button)(({ theme }) => ({
  alignItems: 'center',
  height: '30px',
  width: 'auto',
  fontSize: '0.875rem',
  borderRadius: '18px',
  flexDirection: 'row',
  justifyContent: 'center',
  marginTop: theme.spacing(0.5),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
}));

export const StepCircle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '14px',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  padding: '1px',

  '&.current': {
    backgroundColor: theme.palette.listingPins?.contrastText,
  },

  '&.completed': {
    backgroundColor: '#208732',
  },
}));

export const StyledUnderlineButton = styled(Button)(({ theme }) => ({
  textDecoration: 'underline',
  padding: 0,

  '&:hover': {
    opacity: '1',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
  },
}));
