import { useQuery } from '@ltvco/refresh-lib/vendors';
import { useServiceToken } from '@ltvco/refresh-lib/v1';
import { BumpeeRequests } from 'api';
import { useEffect, useState } from 'react';

interface AudioContentInterface {
  audioized: boolean;
  content: string;
  open: boolean;
  shouldAudioize: boolean;
}

export const useBumpeeHintAudio = (chatId: number, text: string) => {
  const [audioContent, setAudioContent] = useState<AudioContentInterface>({
    audioized: false,
    content: '',
    open: false,
    shouldAudioize: false,
  });

  const { data: tokenData } = useServiceToken('bumpee');

  const results = useQuery({
    queryKey: [`bumpeeGO-message-${text}-audio`],
    queryFn: () => {
      return BumpeeRequests.getHintAudio(tokenData.token, chatId, text);
    },
    enabled: audioContent.shouldAudioize && Boolean(tokenData?.token),
    onSuccess: (data: any) => {
      setAudioContent((prevState) => ({
        ...prevState,
        audioized: true,
        content: data,
        shouldAudioize: true,
      }));
    },
  });

  const handleAudioClick = () => {
    setAudioContent((prevState) => ({
      ...prevState,
      shouldAudioize: true,
      open: !prevState.open,
    }));
  };

  useEffect(() => {
    if (results.isLoading) {
      setAudioContent((prevState) => ({
        ...prevState,
        content: '',
      }));
    }
  }, [results.isLoading]);

  return { ...results, audioContent, handleAudioClick };
};
