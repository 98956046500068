import { useContext, useCallback, useEffect, useState } from 'react';
import { Box, Input, Text, Stack } from '@ltvco/refresh-lib/theme';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ascendantFormSchema } from 'utils/schemaForms';
import { getAstrologyUser, storeExtraAstrologyUserData } from '../../utils';
import { type UserPropsExtra } from '../../interfaces';
import {
  DisclaimerText,
  StyledErrorText,
  StyledForm,
  StyledInputLabel,
  StyledSubmit,
} from './components';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useLLMTimezoneAndCoordinates } from '@ltvco/refresh-lib/hooks';
import { Warning } from '@mui/icons-material';

type FormFields = {
  birthHour: number;
  birthMinute: number;
  birthLocation: string;
};

type InputBirthLocation = {
  cityState: string;
  country: string;
};

interface AstrologyAscendantOnboardingProps {
  date?: string;
  month?: string;
  year?: string;
  onSubmitCallback: (astrologyUser: UserPropsExtra | null) => void;
}

export const AstrologyAscendantOnboarding: React.FC<
  AstrologyAscendantOnboardingProps
> = ({ date, month, year, onSubmitCallback }) => {
  const [inputBirthLocation, setInputBirthLocation] = useState(
    {} as InputBirthLocation
  );
  const { logError } = useContext(AppConfig);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormFields>({
    mode: 'onSubmit',
    resolver: yupResolver(ascendantFormSchema),
  });

  const isInputBirthLocationEmpty = Boolean(
    !Object.values(inputBirthLocation).length
  );

  const {
    data: llmTimezoneAndCoordinatesResponse,
    error: llmTimezoneAndCoordinatesError,
    isSuccess: isLLMTimezoneAndCoordinatesSuccess,
    isLoading: isLLMTimezoneAndCoordinatesLoading,
  } = useLLMTimezoneAndCoordinates({
    enabled: !isInputBirthLocationEmpty,
    data: {
      cityState: inputBirthLocation.cityState,
      country: inputBirthLocation.country,
      date,
      month,
      year,
    },
    shouldSuspendExecution: isInputBirthLocationEmpty,
  });

  const saveUserData = useCallback(async () => {
    if (
      !llmTimezoneAndCoordinatesResponse?.data ||
      llmTimezoneAndCoordinatesError
    ) {
      return;
    }

    const { lat, lon, offset } = llmTimezoneAndCoordinatesResponse.data;

    storeExtraAstrologyUserData({
      hour: getValues('birthHour'),
      minute: getValues('birthMinute'),
      city_state: inputBirthLocation.cityState,
      country: inputBirthLocation.country,
      lat,
      lon,
      timezone_offset: offset,
    });

    const astrologyUser = getAstrologyUser(logError);
    onSubmitCallback(astrologyUser);
  }, [llmTimezoneAndCoordinatesResponse, isLLMTimezoneAndCoordinatesLoading]);

  useEffect(() => {
    if (
      llmTimezoneAndCoordinatesResponse &&
      isLLMTimezoneAndCoordinatesSuccess &&
      !llmTimezoneAndCoordinatesError &&
      !isLLMTimezoneAndCoordinatesLoading
    ) {
      saveUserData();
    }
  }, [llmTimezoneAndCoordinatesResponse, isLLMTimezoneAndCoordinatesLoading]);

  const formatBirthLocation = (birthLocation: string) => {
    if (!birthLocation) return { cityState: '', country: '' };

    const [cityState, country] = birthLocation
      .split(',')
      .map((part) => part.trim());

    return { cityState, country };
  };

  const triggerTimezoneAndCoordinatesLLM = (
    birthLocation: FormFields['birthLocation']
  ) => {
    const { cityState, country } = formatBirthLocation(birthLocation);
    setInputBirthLocation({ cityState, country });
  };

  const onSubmit: SubmitHandler<FormFields> = (data) => {
    const { birthLocation } = data;
    triggerTimezoneAndCoordinatesLLM(birthLocation);
  };

  return (
    <Box>
      <Text mb={2.5}>Please fill in the datapoints below:</Text>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={1.5} direction="row">
          <Stack flexBasis="100%">
            <StyledInputLabel htmlFor="birth-hour">Birth Hour</StyledInputLabel>
            <Input
              id="birth-hour"
              type="number"
              placeholder="e.g. 12"
              {...register('birthHour')}
              error={Boolean(errors.birthHour)}
            />
          </Stack>
          <Stack flexBasis="100%">
            <StyledInputLabel htmlFor="birth-min">
              Birth Minute
            </StyledInputLabel>
            <Input
              id="birth-min"
              type="number"
              placeholder="e.g. 34"
              {...register('birthMinute')}
              error={Boolean(errors.birthMinute)}
            />
          </Stack>
        </Stack>
        <Stack flexBasis="100%">
          <StyledInputLabel htmlFor="birth-location">Location</StyledInputLabel>
          <Input
            id="birth-location"
            placeholder="City, Country"
            {...register('birthLocation')}
            error={Boolean(errors.birthLocation)}
          />
        </Stack>
        <StyledSubmit variant="contained" type="submit" color="success">
          Submit
        </StyledSubmit>
      </StyledForm>
      {errors.birthHour && (
        <StyledErrorText>
          <Warning fontSize="inherit" />
          {errors.birthHour.message}
        </StyledErrorText>
      )}
      {errors.birthMinute && (
        <StyledErrorText>
          <Warning fontSize="inherit" />
          {errors.birthMinute.message}
        </StyledErrorText>
      )}
      {errors.birthLocation && (
        <StyledErrorText>
          <Warning fontSize="inherit" />
          {errors.birthLocation.message}
        </StyledErrorText>
      )}
      <DisclaimerText>
        Please try to provide as accurate responses as possible to improve the
        accuracy of the ascendant report generated*
      </DisclaimerText>
    </Box>
  );
};
