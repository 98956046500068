import { Grid, Box, useTheme } from '@ltvco/refresh-lib/theme';
import { ReportRouteProps } from './types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { getClaimedReports } from 'utils/getClaimedReports';
import { formatFamilyTreeDataFromAncestorReport } from '../../utils/formatFamilyTreeData';
import {
  AncestorFacts,
  AncestorOverview,
  AncestorRelationships,
  type commonTypes,
  nameHasSpecialCharacters,
  peopleTypes,
  type Relative,
  ReportFactory,
  ReportLoading,
  ReportNullState,
  useReport,
  useReportMonitors,
} from '@ltvco/refresh-lib/v1';
import {
  ReportMenuItem,
  ReportNavigationMenu,
  ReportNavigationMobileMenu,
} from '@ltvco/refresh-lib/v2';
import { useAppConstantsContext, AppConfig } from '@ltvco/refresh-lib/ctx';
import { useAncestryTree } from '@ltvco/refresh-lib/fmt';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
} from '@ltvco/refresh-lib/utils';
import { AccountCircle, Lightbulb, FamilyRestroom } from '@mui/icons-material';

interface AncestorReportProps extends ReportRouteProps {}

const FILTER_FACTS_TYPES = ['Death', 'Birth', 'Burial'];
const REPORT_TYPE = 'detailed_person_report';

export const AncestorReportPage = ({ permalink }: AncestorReportProps) => {
  const theme = useTheme();
  const {
    data: { brandName },
  } = useAppConstantsContext();
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();
  const [claimedPersonPermalink, setClaimedPersonPermalink] =
    useState<string>('');
  const { currentMonitors, reportMonitorIsLoading } = useReportMonitors();
  const navigate = useNavigate();
  const { trackEventGA4 } = useContext(AppConfig);

  const { data: familyTreeData } = useAncestryTree({
    permalink: claimedPersonPermalink,
    enabledOnStart: true,
    logNotFoundErrorEnabled: false,
  });

  useScrollToSectionOnNavigate(hash, queryResult);

  useEffect(() => {
    if (currentMonitors) {
      const claimedReports = getClaimedReports(currentMonitors, REPORT_TYPE);

      if (claimedReports.length > 0) {
        setClaimedPersonPermalink(claimedReports[0].permalink);
      }
    }
  }, [currentMonitors]);

  if (queryResult.isLoading || queryResult.isError || reportMonitorIsLoading) {
    return <ReportLoading menuItems={2} />;
  }

  const report = ReportFactory.create(queryResult?.data, 'person');
  const person = report?.data?.people?.[0];

  if (zeroed || !person) {
    return <ReportNullState />;
  }

  const familySearchId = person?.identity?.fs_ids![0];

  const relatives = familyTreeData?.indis;
  const reportInFamilyTree = relatives?.some((relative) => {
    if (relative.placeholder) return false;
    return relative.reports?.some(
      (report) =>
        'family_search_id' in report &&
        report.family_search_id === familySearchId
    );
  });

  const hasFamilyTreeCreated = !!familyTreeData?.indis?.length;

  const fullName = person?.identity?.names?.[0]?.full;

  const otherNames = person?.identity?.names
    ?.slice(1)
    ?.map((name: commonTypes.Name) => name.full)
    .join(', ');

  const dateOfBirth = person?.identity?.dobs?.[0]?.date;
  const dateOfDeath = person?.identity?.dods?.[0]?.date;
  const birthAddress = person?.facts?.find(
    (fact: commonTypes.Fact) => fact?.type?.toLowerCase() == 'birth'
  )?.place?.full;
  const deathAddress = person?.facts?.find(
    (fact: commonTypes.Fact) => fact?.type?.toLowerCase() == 'death'
  )?.place?.full;
  const burialData = person?.facts?.find(
    (fact: commonTypes.Fact) => fact?.type?.toLowerCase() == 'burial'
  );

  const dateOfBurial = burialData?.date;
  const burialAddress = burialData?.place?.full;
  const photos = person?.images || [];

  const facts = Array.isArray(person?.facts)
    ? person?.facts?.filter(
        (fact: commonTypes.Fact) =>
          !FILTER_FACTS_TYPES.includes(fact?.type || '')
      )
    : [];

  document.title = `${fullName ?? 'Ancestor Report'} - ${brandName}`;

  const handleAddingRelativeToFamilyTree = () => {
    trackEventGA4({
      event_name: 'report_element_engagement',
      report_type: 'ancestor',
      type: 'add_family',
      interacted_from: 'overview',
    });

    localStorage.setItem(
      'familyTreeRelativeToAdd',
      JSON.stringify(
        formatFamilyTreeDataFromAncestorReport(
          person,
          permalink,
          birthAddress || deathAddress || burialAddress || ''
        )
      )
    );

    navigate('/family-tree');
  };

  const personRelatives =
    person?.rawData?.connections?.relatives?.filter(
      (relative: peopleTypes.RelativeConnection) =>
        !nameHasSpecialCharacters(relative?.identity?.names?.[0]?.full || '')
    ) || [];

  const getAncestorNavLinkData = ({
    factsCount,
    relationshipsCount,
  }: {
    factsCount: number;
    relationshipsCount: number;
  }): ReportMenuItem[] => {
    return [
      {
        href: '#ancestor-overview',
        labelText: 'Overview',
        reportItemsCount: null,
        icons: [
          <AccountCircle
            sx={{ color: theme.palette.primary.main }}
            fontSize="large"
          />,
        ],
      },
      {
        href: '#ancestor-facts',
        labelText: 'Facts',
        reportItemsCount: factsCount,
        icons: [
          <Lightbulb
            sx={{ color: theme.palette.primary.main }}
            fontSize="small"
          />,
        ],
      },
      {
        href: '#ancestor-relationships',
        labelText: 'Relationships',
        reportItemsCount: relationshipsCount,
        icons: [
          <FamilyRestroom
            sx={{ color: theme.palette.primary.main }}
            fontSize="small"
          />,
        ],
      },
    ];
  };

  const ancestorNavLinkData = getAncestorNavLinkData({
    factsCount: facts.length,
    relationshipsCount: personRelatives?.length || 0,
  });

  return (
    <Box>
      <Grid container direction={'row'} columns={12} spacing={7}>
        <Grid
          item
          sm={12}
          md={4}
          lg={4}
          sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}
        >
          <ReportNavigationMenu
            reportType="Ancestor Report"
            headerTitle={fullName || ''}
            menuItems={ancestorNavLinkData}
          />
        </Grid>
        <Grid item sm={12} md={8} lg={8} marginTop={3} width="100%">
          <AncestorOverview
            familySearchId={familySearchId || ''}
            permalink={permalink}
            fullName={fullName || ''}
            otherNames={otherNames || ''}
            dateOfBirth={dateOfBirth}
            birthAddress={birthAddress || ''}
            dateOfDeath={dateOfDeath}
            deathAddress={deathAddress || ''}
            dateOfBurial={dateOfBurial}
            burialAddress={burialAddress || ''}
            reportInFamilyTree={reportInFamilyTree}
            addRelativeToFamilyTree={handleAddingRelativeToFamilyTree}
            hasFamilyTreeCreated={hasFamilyTreeCreated}
            photos={photos}
          />
          <AncestorFacts facts={facts} />
          <AncestorRelationships
            relatives={personRelatives as unknown as Relative[]}
          />
        </Grid>
      </Grid>
      <ReportNavigationMobileMenu
        reportType="Ancestor Report"
        headerTitle={fullName || ''}
        menuItems={ancestorNavLinkData}
      />
    </Box>
  );
};
