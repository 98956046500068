export const getFraudSearchSubtitleText = (searchType: string) => {
  switch (searchType) {
    case 'phone':
      return 'Verify whether a phone number is in use, its Do Not Call list status, and a variety of threat signals.';
    case 'ip':
      return 'Discover details about an IP address including its location, risk signals, VPN usage, and other signs the user may be masking their identity.';
    case 'url':
      return 'Scan a URL for threats, suspicious activity, adult content, and redirects that could lead you to a risky destination.';
    case 'email':
      return 'Verify that an email address is valid and in use, or check for risk signals associated with it.';
    default:
      return 'Our all-in-one solution helps you verify contact details, identify fraudulent information, and protect against online threats.';
  }
};
