import { CreditNavigator } from './CreditNavigator';
import { ScoreSimulator } from './ScoreSimulator';

export const ScoreSimulatorConstants = {
  description:
    'Simulate your credit score by changing the factors that impact your current score below. Choose from the actions below to see how they may affect your VantageScore credit score and make informed financial decisions.',
  factors_and_actions_hint:
    'The simulations are hypothetical; they do not predict or guarantee that your actual score will rise or drop to the simulated score, nor do they guarantee that you will be approved or rejected for credit by any financial institution. Actual results may differ. Entering data into the simulator does not affect your actual credit score. The simulated impact is based upon the VantageScore 3.0®.',
};

export const CreditNavigatorConstants = {
  description:
    'Credit Navigator helps you point your score in the right direction, empowering you to consider how to reach your credit goals. Created by TransUnion® in partnership with VantageScore®, its personalized recommendations are based on the paths millions of others actually took to reach their credit goals. Depending on your desired score goal, personal credit profile and proactive credit behavior, achieving your credit goal may take as little as a few months or as long as several years. These recommendations are based on the VantageScore® 3.0 mode.',
  recommendation_body:
    'Recommendations to get to your desired credit score and track your progress will appear below after the credit score simulator is adjusted.',
  result_caption:
    "Credit Navigator derives a difficulty level by looking at the percentage of users with similar credit behavior as you in the VantageScore study that actually reached the goal score you selected within a given timeframe. An easy path means that the goal you're trying to reach was achieved by a relatively high number of consumers with credit behavior similar to yours. A harder path means that this goal was achieved by a relatively low number of consumers with credit behavior similar to yours.",
};

export const CreditAnalysisTabs = () => [
  {
    content: <ScoreSimulator />,
    label: 'Simulator',
  },
  {
    content: <CreditNavigator />,
    label: 'Credit Navigator',
  },
];

export const ScoreSimulatorDefaultState = {
  IncreaseCreditBalance: '',
  IncreaseCreditCardLimit: '',
  DecreaseCreditBalance: '',
  PayOffAllCreditCards: false,
  OnTimePayment: '',
  AllAccountsPastDue: '',
  OneAccountPastDue: '',
  CloseOldestCreditCard: false,
  ObtainCreditCard: '',
  TransferCreditBalances: '',
  ObtainCreditCardAsAuthorizedUser: '',
  ApplyForCreditCard: '',
  ObtainAutoLoan: '',
  ObtainPersonalLoan: '',
  ObtainMortgage: '',
};
