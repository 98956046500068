import { Divider,  Stack, Text } from '@ltvco/refresh-lib/theme';
import { SerializedReport, containerTitleText } from './utils';
import { EditMonitorModal } from './Components';
import { AddReportMonitorForm } from './AddReportMonitorForm';
import { PersonCTACard } from './PersonCTACard';
import { ClaimedReportMonitorCard } from './ClaimedReportMonitorCard';
import { RawReportType } from '@ltvco/refresh-lib/v1';
import { Close } from '@mui/icons-material';

export const ManageReportMonitorsModal: React.FC<{
  serializedReports: SerializedReport[];
  rawReportType: RawReportType;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  navigateToReport: (permalink: string) => void;
}> = ({
  serializedReports,
  rawReportType,
  openModal,
  setOpenModal,
  navigateToReport,
}) => {
  const isPropertyReport = rawReportType === 'property_report';
  const isDetailedPersonReport = rawReportType === 'detailed_person_report';
  const serializedReportsLength = serializedReports.length;

  const onNavigate = (permalink: string) => {
    navigateToReport(permalink);
  };

  const monitoredCards = () => {
    return serializedReports.map((serializedReport, index) => (
      <ClaimedReportMonitorCard
        key={`${rawReportType}+card+${index}`}
        serializedReport={serializedReport}
        onNavigate={onNavigate}
      />
    ));
  };

  const canDisplayAddForm =
    !isDetailedPersonReport &&
    (serializedReportsLength < 2 || !isPropertyReport);

  const modalContent = () => {
    if (isDetailedPersonReport && serializedReportsLength === 0) {
      return <PersonCTACard />;
    } else {
      return (
        <Stack direction="column" gap={2.5}>
          {monitoredCards()}
          {canDisplayAddForm && (
            <AddReportMonitorForm
              rawReportType={rawReportType}
              defaultOpen={serializedReportsLength === 0}
              navigateToReport={navigateToReport}
            />
          )}
        </Stack>
      );
    }
  };

  return (
    <EditMonitorModal
      open={openModal}
      onClose={() => setOpenModal(false)}
      classes={{ paper: 'edit-modal' }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <Text
          variant="h2"
          sx={{
            marginBottom: '0',
          }}
        >
          {containerTitleText(rawReportType, false)}
        </Text>
        <Close
          onClick={() => setOpenModal(false)}
          sx={{
            cursor: 'pointer',
          }}
        />
      </Stack>
      <Divider sx={{ my: [2.5] }} />
      {modalContent()}
    </EditMonitorModal>
  );
};
