import { InputLabel, styled } from '@ltvco/refresh-lib/theme';
import { DatePicker, PickersDay } from '@mui/x-date-pickers';

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  mb: 1,
}));

export const StyledForm = styled('form')(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: '100%',
  padding: '1rem',
  borderRadius: '4px',
}));

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  background: theme.palette.background.paper,
  width: '100%',
  height: '100%',
  maxHeight: '40px',
  '.MuiInputBase-root': { maxHeight: '40px' },
  '.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '.MuiButtonBase-root': { color: theme.palette.primary.main },
}));

export const StyledPickersDay = styled(PickersDay)(({ theme }) => ({
  '&.MuiButtonBase-root.MuiPickersDay-root': {
    fontSize: theme.typography.body2.fontSize,
  },
  '&.MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
    backgroundColor: theme.palette.success.dark,
  },
}));
