import { useState, useEffect, useContext } from 'react';
import { ChatHeader } from '../ChatContainer/ChatHeader/ChatHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { languageID, translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';
import { LessonSidebar } from '../LessonChatContainer/LessonSidebar/LessonSidebar';
import { useBumpeeQuizChat } from '../hooks/useBumpeeQuizChat';
import { useBumpeeQuizChats } from '../hooks/useBumpeeQuizChats';
import { CurrentChat } from '../ChatContainer/CurrentChat/CurrentChat';
import { useAchievementEventWatcher } from '../hooks/useAchievementEventWatcher';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';

export const QuizChatContainer = () => {
  useAchievementEventWatcher();
  const [currentQuestionId, setCurrentQuestionId] = useState(0);

  const navigate = useNavigate();
  const { trackEventGA4 } = useContext(AppConfig);
  const { language, lessonLearningLanguage } = useLanguageContext();
  const { isMobile } = useScreenSize();
  const { quizChatId } = useParams();
  const { data: quizChatData } = useBumpeeQuizChat(quizChatId ?? '');
  const { data: userQuizChats, createQuizChatMutation } = useBumpeeQuizChats();

  const chatId = quizChatData?.chat_id;
  const languageLessonName =
    quizChatData?.chat?.learning_language?.toLowerCase();
  const activeQuizId = quizChatData?.lesson_id;
  const assignmentTopic = translations.dashboard.assignments.quiz.quizList[
    language
  ].find((quiz) => quiz.id === quizChatData?.lesson_id)?.title;

  const translatedQuizzes =
    translations.dashboard.assignments.quiz.quizList[language];
  const quizzes = translatedQuizzes?.map((quiz, index) => {
    const quizChat = userQuizChats?.quiz_chats.find(
      (userQuiz) =>
        userQuiz.lesson_id === quiz.id &&
        userQuiz.learning_language_id === languageID(languageLessonName ?? '')
    );

    return {
      ...quiz,
      assignmentType: 'quiz',
      title: `Quiz ${index + 1}: ${quiz.title}`,
      quizChatId: quizChat?.id,
      status: quizChat?.status ?? 'new',
    };
  });

  useEffect(() => {
    if (!quizChatData) return;
    const questions = Object.entries(quizChatData?.questions);

    for (let i = 0; i < questions.length; i++) {
      if (questions[i][1].correct === null) {
        setCurrentQuestionId(parseInt(questions[i][0]));
        break;
      }
    }
  }, [quizChatData]);

  useEffect(() => {
    if (!quizChatData) return;
    const questions = Object.entries(quizChatData?.questions);

    for (let i = 0; i < questions.length; i++) {
      if (questions[i][1].correct === null) {
        setCurrentQuestionId(parseInt(questions[i][0]));
        break;
      }
    }
  }, [quizChatData]);

  const redirectToQuiz = (quizId: number) => {
    const quiz = quizzes.find((quiz) => quiz.id === quizId);
    if (!quiz) return;

    if (quiz.quizChatId) {
      navigate(`/dashboard/language/quiz/${quiz.quizChatId}`);
      return;
    }

    const quizChatParams = {
      lesson_id: quiz.id,
      learning_language_id: parseInt(lessonLearningLanguage.value),
    };

    createQuizChatMutation.mutate(quizChatParams);

    trackEventGA4({
      event_name: 'Language Tutor Engagement',
      type: 'quiz',
      language: languageLessonName,
      action: 'start',
    });
  };

  return (
    <Box
      display="flex"
      width="100%"
      height={isMobile ? '90vh' : '80vh'}
      ml={0}
      pt={{ xs: 0, md: 1 }}
      px={{ xs: 0, md: 1.5 }}
    >
      {!isMobile && (
        <LessonSidebar
          assignments={quizzes}
          activeAssignmentId={activeQuizId}
          redirectToAssignment={redirectToQuiz}
        />
      )}

      <Stack width="100%">
        <ChatHeader
          assignmentTopic={assignmentTopic}
          assignments={quizzes}
          activeAssignmentId={activeQuizId}
          redirectToAssignment={redirectToQuiz}
          isQuizChat={true}
        />
        <CurrentChat
          isQuizChat={true}
          externalChatId={chatId}
          assignmentTopic={assignmentTopic}
          quizChatId={Number(quizChatId)}
          currentQuestionId={currentQuestionId}
          quizCompleted={quizChatData?.status === 'completed'}
        />
      </Stack>
    </Box>
  );
};
