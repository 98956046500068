import { useContext } from 'react';
import { Text } from '@ltvco/refresh-lib/theme';
import { ZodiacSignProps } from '../interfaces';
import { StyledButton, IconBox, StyledStack } from './components';
import { MenuSelectOptionProps } from '../interfaces';
import { getAvailableMenuConfig } from '../utils';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { ChevronRight } from '@mui/icons-material';

export interface AstrologyInitialMenuProps {
  options: { [key: string]: MenuSelectOptionProps };
  zodiacSignData: ZodiacSignProps | undefined;
  onOptionClick: (option: MenuSelectOptionProps) => void;
  lifepathNumber: string;
  personalityNumber: string;
}

export const AstrologySummary = ({
  options,
  zodiacSignData,
  onOptionClick,
  lifepathNumber,
  personalityNumber,
}: AstrologyInitialMenuProps) => {
  const { trackEvent } = useContext(AppConfig);

  function onOptionClickCallback(option: MenuSelectOptionProps) {
    trackEvent(
      'initial selection',
      `engamenent click ${option.value}`,
      'astrology'
    );
    onOptionClick(option);
  }

  // Remove summary option, it shouldn't be available on the Summary component
  const localOptions: { [key: string]: MenuSelectOptionProps } =
    getAvailableMenuConfig(['summary'], options);

  function getIconContent(option: MenuSelectOptionProps) {
    const url = option.startIconUrl || zodiacSignData?.smallImage;

    if (url && option.value !== 'lifepath' && option.value !== 'personality') {
      return (
        <img
          src={url}
          alt="Summary horoscope icon"
          style={{ maxHeight: '23px', maxWidth: '23px' }}
        />
      );
    }

    return (
      <Text fontWeight="bold">
        {option.value === 'lifepath' ? lifepathNumber : personalityNumber}
      </Text>
    );
  }

  return (
    <>
      {zodiacSignData && <Text mb={2}>{zodiacSignData.summary}</Text>}
      <StyledStack>
        {Object.entries(localOptions).map(([_, option]) => (
          <StyledButton
            key={`option-${option.value}`}
            variant="outlined"
            startIcon={<IconBox>{getIconContent(option)}</IconBox>}
            endIcon={<ChevronRight />}
            onClick={() => onOptionClickCallback(option)}
            sx={{ order: option.order }}
          >
            {option.summaryDisplay}
          </StyledButton>
        ))}
      </StyledStack>
    </>
  );
};
