import { BumpeeRequests, CreateChatParams } from 'api';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAchievementEventCoordinator } from './useAchievementEventCoordinator';
import { enqueueSnackbar, useServiceToken } from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQueryClient, useQuery, useMutation } from '@ltvco/refresh-lib/vendors';

const baseQueryKey = 'bumpeeGO';

export function useBumpeeChat(
  chatId?: number,
  redirectOnCreate = false,
  invalidateUser = false
) {
  const { handleAchievementEvent } = useAchievementEventCoordinator();
  const { data: tokenData } = useServiceToken('bumpee');
  const { logError } = useContext(AppConfig);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const results = useQuery({
    queryKey: [`${baseQueryKey}-chat-${chatId}`],
    queryFn: () => {
      return BumpeeRequests.getChat(tokenData?.token, chatId!);
    },
    enabled: Boolean(tokenData?.token) && Boolean(chatId),
  });

  const createChatMutation = useMutation({
    mutationFn: (params: CreateChatParams) => {
      return BumpeeRequests.createChat(tokenData.token, params);
    },
    onSuccess: (data, params) => {
      queryClient.invalidateQueries([`${baseQueryKey}-chats`]);
      handleAchievementEvent({
        event: 'create',
        category: 'chat',
        metaData: {
          level: params.level.toLocaleLowerCase(),
          topicId: params.topic_id,
        },
      });

      if (invalidateUser) {
        queryClient.invalidateQueries([`${baseQueryKey}-user`]);
      }

      if (redirectOnCreate) {
        navigate(`/dashboard/language/chat/${data.chat_id}`);
      }
    },
    onError: (error: Error) => {
      enqueueSnackbar('Failed to create chat', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Failed to create chat', error as Error);
    },
  });

  const deleteChatMutation = useMutation({
    mutationFn: (chatId: number) => {
      return BumpeeRequests.deleteChat(tokenData.token, chatId);
    },
    onError: (error: Error) => {
      enqueueSnackbar('Failed to delete chat', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Failed to delete chat', error as Error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`${baseQueryKey}-chats`]);
    },
  });

  return { ...results, createChatMutation, deleteChatMutation };
}
