import { Text } from '@ltvco/refresh-lib/theme';

export type HeaderType =
  | 'genericMCQ'
  | 'oneTimePasscodeMCQ'
  | 'oneTimePasscodeUserInput'
  | 'repeatOneTimePasscodeUserInput';

const HeaderConst: Record<HeaderType, { title: string; body: string }> = {
  genericMCQ: {
    title: 'Identity Quiz',
    body: 'As a security measure, please answer questions based on your TransUnion® credit profile. Once you are confirmed, you can activate identity protection tools and services, as well as access your credit data',
  },
  oneTimePasscodeMCQ: {
    title: 'One-Time Passcode Authentication',
    body: 'Your phone number matches what appears on your TransUnion® credit record. As a security measure, please select a preferred method of authentication. Once you are authenticated with TransUnion, ID Monitor may provide insight and tools for credit and identity protection.',
  },
  oneTimePasscodeUserInput: {
    title: 'One-Time Passcode Authentication',
    body: 'Please enter the 5-digit passcode or PIN you received on your phone.',
  },
  repeatOneTimePasscodeUserInput: {
    title: 'One-Time Passcode Authentication',
    body: "You've entered the wrong passcode. Please check your phone and try again.",
  },
};

export const Header: React.FC<{ type: HeaderType }> = ({ type }) => {
  const selectedHeader = HeaderConst[type as keyof typeof HeaderConst];

  return (
    <>
      <Text variant="h1">{selectedHeader.title}</Text>
      <Text variant="body1">{selectedHeader.body}</Text>
    </>
  );
};
