import {
  Box,
  Button,
  Stack,
  Text,

  useTheme,
  styled,
} from '@ltvco/refresh-lib/theme';
import { ChatCard } from './ChatCard';
import { Chat } from 'api/requests/Bumpee/interfaces';
import { Spinner } from '../components';
import { EmptyChats } from './EmptyChats';
import { translations } from '../constants';
import { useLanguageContext } from '../LanguageContext';
import { AddRounded } from '@mui/icons-material';

export const NewChatButton = styled(Button)({
  height: 30,
  fontSize: 12,
  padding: '0 10px 0 6px',
});

export const NewChatIcon = styled(AddRounded)({
  marginRight: 6,
});

const ChatsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  height: 'auto',

  '& > *': {
    width: '100%',
    maxWidth: '100%',

    [theme.breakpoints.up('md')]: {
      width: 'calc(calc(100% - 72px) / 4)',
      maxWidth: 'calc(calc(100% - 72px) / 4)',
    },
  },

  '& > *:not(:nth-of-type(4n))': {
    [theme.breakpoints.up('md')]: {
      marginRight: '24px',
    },
  },
}));

export interface ChatsListProps {
  handleOnClickAddNewChat: () => void;
  chatsList?: Chat[];
  isLoading?: boolean;
}

export const ChatsList = ({
  handleOnClickAddNewChat,
  chatsList,
  isLoading,
}: ChatsListProps) => {
  const theme = useTheme();
  const { language } = useLanguageContext();

  const handleNewChatEmptyState = () => {
    handleOnClickAddNewChat();
  };

  const handleNewChatNonEmptyState = () => {
    handleOnClickAddNewChat();
  };

  return (
    <Stack width={'100%'}>
      {isLoading ? (
        <Stack flex={1} justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Stack>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            pb={2}
            pt={3}
          >
            <Text
              variant="h3"
              my={0}
              mr={2.5}
              color={theme.palette.primary.main}
            >
              {translations.dashboard.chats.title[language]}
            </Text>
            <NewChatButton
              variant="outlined"
              color="primary"
              onClick={handleNewChatNonEmptyState}
            >
              <NewChatIcon fontSize="small" />
              {translations.buttons.create[language]}
            </NewChatButton>
          </Box>
          {!Boolean(chatsList?.length) ? (
            <EmptyChats handleOnClickAddNewChat={handleNewChatEmptyState} />
          ) : (
            <>
              <ChatsContainer flexWrap={'wrap'} justifyContent={'flex-start'}>
                {chatsList &&
                  chatsList.map((chat: Chat) => (
                    <ChatCard
                      key={chat.chat_id}
                      learningLanguage={chat.learning_language}
                      level={chat.level}
                      topic={chat.topic}
                      id={chat.chat_id}
                    />
                  ))}
              </ChatsContainer>
            </>
          )}
        </>
      )}
    </Stack>
  );
};
