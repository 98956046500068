import { levelName } from '../constants';
import { StyledButton } from './components';
import { SelectedOptions } from './OnBoarding';

interface CustomOption {
  id: number;
  name: string;
}
interface StepOptionButtonProps {
  option: CustomOption;
  responseKey: string;
  setSelectedOptions: (prevState: any) => void;
  selectedOptions?: SelectedOptions;
}

export const StepOptionButton = ({
  option,
  responseKey,
  selectedOptions,
  setSelectedOptions,
}: StepOptionButtonProps) => {
  const handleOnClick = () => {
    if (responseKey === 'level') {
      setSelectedOptions((prevState: any) => ({
        ...prevState,
        [responseKey]: levelName(option.id),
      }));
    } else {
      setSelectedOptions((prevState: any) => ({
        ...prevState,
        [responseKey]: option.id,
      }));
    }
  };

  return (
    <StyledButton
      onClick={handleOnClick}
      variant="contained"
      color={
        (selectedOptions &&
          selectedOptions[responseKey as keyof SelectedOptions] ===
            option.id) ||
        (selectedOptions &&
          selectedOptions[responseKey as keyof SelectedOptions] ===
            levelName(option.id))
          ? 'primary'
          : 'secondary'
      }
    >
      {option.name}
    </StyledButton>
  );
};
