import { request } from '@ltvco/refresh-lib/utils';
import {
  type AstrologyInterface,
  type CreateAscendantParams,
  type CreateHoroscopeParams,
  type CreateNumerologyParams,
  type CredentialsParams,
  type FetchAstrologyProps,
} from './interfaces';

const astrologyUrl = 'https://json.astrologyapi.com/v1/';
enum AstrologyEndpoints {
  today = 'sun_sign_consolidated/daily/',
  yesterday = 'sun_sign_prediction/daily/previous/',
  tomorrow = 'sun_sign_prediction/daily/next/',
  monthly = 'horoscope_prediction/monthly/',
  lifepath = 'lifepath_number',
  personality = 'personality_number',
  ascendant = 'general_ascendant_report/tropical',
}

const getRequestInit = (
  credentials: CredentialsParams,
  params: CreateHoroscopeParams | CreateNumerologyParams | CreateAscendantParams
) => ({
  headers: {
    'content-type': 'application/json',
    Authorization: `Basic ${btoa(credentials.user + ':' + credentials.key)}`,
  },
  method: 'POST',
  dataType: 'json',
  body: JSON.stringify(params),
});

const fetchAstrology = async ({
  credentials,
  queryType,
  params,
}: FetchAstrologyProps) => {
  let extraUrlParam = '';
  if (
    queryType === 'today' ||
    queryType === 'yesterday' ||
    queryType === 'tomorrow' ||
    queryType === 'monthly'
  ) {
    extraUrlParam = (params as CreateHoroscopeParams).sign || '';
  }

  return await request(
    `${astrologyUrl}${
      AstrologyEndpoints[queryType] as AstrologyEndpoints
    }${extraUrlParam}`,
    getRequestInit(credentials, params)
  );
};

export const astrology: AstrologyInterface = {
  fetchAstrology,
};
