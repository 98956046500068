export const XmlHandler = () => {
  const _unescapeXml = (escapedXML: string) => {
    return escapedXML
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>')
      .replaceAll('&#xD;\n', '')
      .replaceAll('d3p1:', '')
      .replaceAll('&amp;', '&');
  };

  const parseToXml = (string: string) => {
    const parser = new DOMParser();
    return parser.parseFromString(_unescapeXml(string), 'text/xml');
  };

  return {
    parseToXml,
  };
};
