import { BumpeeRequests, UserParams } from 'api';
import { useContext } from 'react';
import { useAchievementEventCoordinator } from './useAchievementEventCoordinator';
import { enqueueSnackbar, useServiceToken } from '@ltvco/refresh-lib/v1';
import { AppConfig } from '@ltvco/refresh-lib/ctx';
import { useQueryClient, useQuery, useMutation } from '@ltvco/refresh-lib/vendors';

const baseQueryKey = 'bumpeeGO';

export function useBumpeeUsers() {
  const { handleAchievementEvent } = useAchievementEventCoordinator();
  const { data: tokenData } = useServiceToken('bumpee');
  const { logError } = useContext(AppConfig);
  const queryClient = useQueryClient();

  const results = useQuery({
    queryKey: [`${baseQueryKey}-user`],
    queryFn: () => BumpeeRequests.getUser(tokenData?.token),
    enabled: Boolean(tokenData?.token),
  });

  const updateUserMutation = useMutation({
    mutationFn: (params: UserParams) => {
      return BumpeeRequests.updateUser(tokenData?.token, params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`${baseQueryKey}-user`]);
      handleAchievementEvent({
        event: 'update',
        category: 'settings',
        metaData: { setting: 'native_language' },
      });
      enqueueSnackbar('Successful updated the native language', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: (error: Error) => {
      enqueueSnackbar('Failed to update the native language', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Failed to update the native language', error as Error);
    },
  });

  const createUserMutation = useMutation({
    mutationFn: (params: UserParams) => {
      return BumpeeRequests.createUser(tokenData?.token, params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`${baseQueryKey}-user`]);
    },
    onError: (error: Error) => {
      enqueueSnackbar('Failed to create user', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      logError('Failed to create user', error as Error);
    },
  });

  return { ...results, createUserMutation, updateUserMutation };
}
