import { Modal, Stack, Text } from '@ltvco/refresh-lib/theme';
import {
  StyledCloseButton,
  StyledStackGradient,
} from 'components/Language/NewChatModal/components';
import { StyledStack } from '../components';
import { StyledDrawer } from 'components/Language/LanguageHeader/LanguageSettings/LanguageSettings';
import { useState } from 'react';
import { AchievementsTrackerModal } from '../AchievementsTrackerModal/AchievementsTrackerModal';
import { AchievementCard } from '../AchievementCard/AchievementCard';
import {
  Achievement,
  useAchievementContext,
} from 'components/Language/AchievementContext';
import { translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import { Close, WorkspacePremiumRounded } from '@mui/icons-material';

interface AchievementsModalProps {
  open: boolean;
  handleOnClose: () => void;
}

export const AchievementsModal = ({
  open,
  handleOnClose,
}: AchievementsModalProps) => {
  const [isStakedModalOpen, setIsStakedModalOpen] = useState(false);
  const [achievementSelected, setAchievementSelected] = useState({
    name: '',
    date: '',
    image: '',
    tooltip: '',
    disabled: false,
    id: '',
  } as Achievement);

  const { isMobile } = useScreenSize();
  const { language } = useLanguageContext();
  const { achievements } = useAchievementContext();
  const { title, subtitle } = translations.achievements.modal;

  const AchievementsContainer = isMobile ? StyledDrawer : Modal;

  const handleToggleStakedModal = () => {
    setIsStakedModalOpen(!isStakedModalOpen);
  };

  return (
    <AchievementsContainer
      open={open}
      onClose={handleOnClose}
      {...(isMobile && { anchor: 'bottom' })}
      {...(!isMobile && { hasCloseIcon: true })}
      sx={{
        '.MuiPaper-root': {
          paddingTop: 0,
          maxWidth: '955px',
        },
        '.MuiDialogContent-root': {
          padding: 0,
        },
      }}
    >
      <Stack>
        {isMobile && (
          <StyledCloseButton onClick={handleOnClose}>
            <Close />
          </StyledCloseButton>
        )}
        <StyledStackGradient direction="column" alignItems="center" p={2}>
          <WorkspacePremiumRounded fontSize="large" sx={{ color: '#0052FF'}} />
          <Text variant="h3">{title[language]}</Text>
          <Text textAlign="center">{subtitle[language]}</Text>
        </StyledStackGradient>
        <Stack px={{ xs: 2, md: 3 }} pb={{ xs: 0, md: 1 }}>
          <StyledStack flexWrap="wrap" justifyContent="flex-start">
            {achievements &&
              achievements.map((badge) => (
                <AchievementCard
                  key={badge.id}
                  badge={badge}
                  setAchievementSelected={setAchievementSelected}
                  handleToggleStakedModal={handleToggleStakedModal}
                />
              ))}
          </StyledStack>
        </Stack>
      </Stack>
      {isStakedModalOpen && (
        <AchievementsTrackerModal
          items={[
            {
              title: achievementSelected.name,
              description: achievementSelected.tooltip,
              image: achievementSelected.image,
              additionalInfo: achievementSelected.date,
              disabled: achievementSelected.disabled,
            },
          ]}
          handleOnClose={handleToggleStakedModal}
        />
      )}
    </AchievementsContainer>
  );
};
