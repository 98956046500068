import { Box, Card, Text,  Stack, Button } from '@ltvco/refresh-lib/theme';
import { useBumpeeLessonChat } from 'components/Language/hooks/useBumpeeLessonChat';
import { useBumpeeStoryChat } from 'components/Language/hooks/useBumpeeStoryChat';
import { useBumpeeLessonsChats } from 'components/Language/hooks/useBumpeeLessonsChats';
import { useBumpeeStoryChats } from 'components/Language/hooks/useBumpeeStoryChats';
import { useParams } from 'react-router-dom';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { translations } from 'components/Language/constants';
import { CheckCircle } from '@mui/icons-material';

export default function LessonCompleted() {
  const { lessonChatId, storyChatId } = useParams<{
    lessonChatId: string;
    storyChatId: string;
  }>();
  const { language } = useLanguageContext();
  const { data: lessonChatData } = useBumpeeLessonChat(lessonChatId);
  const { data: storyChatData } = useBumpeeStoryChat(storyChatId);
  const { createChatLessonMutation } = useBumpeeLessonsChats();
  const { createStoryChatMutation } = useBumpeeStoryChats();

  const createNewLesson = () => {
    const lessonChatParams = {
      lesson_id: lessonChatData?.lesson_chat?.lesson_id || 0,
      learning_language_id:
        lessonChatData?.lesson_chat?.learning_language_id || 0,
    };
    createChatLessonMutation.mutate(lessonChatParams);
  };

  const createNewStory = () => {
    const storyChatParams = {
      lesson_id: storyChatData?.story_id || 0,
      learning_language_id: storyChatData?.learning_language_id || 0,
    };
    createStoryChatMutation.mutate(storyChatParams);
  };

  const createNewAssignment = () => {
    if (lessonChatId) {
      createNewLesson();
    } else if (storyChatId) {
      createNewStory();
    }
  };

  const { heading, subHeading, buttonText } =
    translations.chatUI.lessons.completedCard;

  return (
    <Card variant="outlined" sx={{ maxWidth: '70%', mt: 3 }}>
      <Box display="flex">
        <CheckCircle sx={{ color: '#81B44C', mx: 2 }} />
        <Stack spacing={2}>
          <Text variant="h3">{heading[language]}</Text>
          <Text>{subHeading[language]}</Text>
          <Button
            disabled={createChatLessonMutation.isLoading}
            onClick={createNewAssignment}
            variant="outlined"
            sx={{ width: 'fit-content' }}
          >
            {buttonText[language]}
          </Button>
        </Stack>
      </Box>
    </Card>
  );
}
