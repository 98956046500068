import { Stack, Text } from '@ltvco/refresh-lib/theme';
import IdMonitorLoading from '../../images/id-monitor-loading.svg';

export const Loading = () => {
  return (
    <Stack
      alignItems="center"
      textAlign="center"
      paddingY={2.5}
      justifyContent={'center'}
      sx={{ height: ['100vh', '494px'] }}
    >
      <img
        src={IdMonitorLoading}
        height="12px"
        width="108px"
        alt="id-monitor-loading"
      />
      <Text variant="h1" marginTop={2.5} width={['default', '458px']}>
        Please wait while we fetch your credit data from our partner,
        TransUnion®
      </Text>
    </Stack>
  );
};
