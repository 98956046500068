import { commaFormatCurrency } from '@ltvco/refresh-lib/utils';
import { monthsCategories, selectOptions } from './constants';

type CreditScoreHistoryItem = {
  date: string;
};

export const getMonthsAbbreviations = (
  creditScoreHistory: CreditScoreHistoryItem[]
) => {
  if (!creditScoreHistory) return [];

  const monthIndexMap: { [month: string]: number } = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const presentMonths = new Set();

  creditScoreHistory.forEach((data) => {
    const date = new Date(data.date);
    const month = date.getMonth();
    const monthAbbreviation = monthsCategories[month];
    if (monthAbbreviation) {
      presentMonths.add(monthAbbreviation);
    }
  });

  const sortedMonths = Array.from(presentMonths).sort(
    (a, b) =>
      monthIndexMap[a as keyof typeof monthIndexMap] -
      monthIndexMap[b as keyof typeof monthIndexMap]
  );

  return sortedMonths;
};

export const getDefaultView = (monthsArr: string[]) => {
  if (!monthsArr.length) return null;
  if (monthsArr.length <= 3) return selectOptions[0].value;
  if (monthsArr.length >= 4 && monthsArr.length <= 6)
    return selectOptions[1].value;
  if (monthsArr.length >= 7 && monthsArr.length <= 9)
    return selectOptions[2].value;

  return selectOptions[3].value;
};

export const getAvailableOptions = (monthsArr: string[]) => {
  if (!monthsArr.length) return [];
  if (monthsArr.length <= 3) return selectOptions.slice(0, 1);
  if (monthsArr.length >= 4 && monthsArr.length <= 6)
    return selectOptions.slice(0, 2);
  if (monthsArr.length >= 7 && monthsArr.length <= 9)
    return selectOptions.slice(0, 3);

  return selectOptions;
};

export const CreditSummaryConstants = {
  title:
    'The table below is a summary of your full credit report through the TransUnion QuickView report, which contains aggregated data from your credit report although it may not contain all data elements and/or fields.',
  disclaimer:
    'The TransUnion QuickView report is a summary of your full report and does not contain all data elements and/or fields.',
};

const getPercentage = (value?: string) => {
  const percentage = `${Number(value).toFixed(2)}`;

  if (percentage !== 'Nan') {
    return `${percentage}%`;
  }
};

const getCurrency = (value?: string) => {
  const currency = commaFormatCurrency(Number(value), true);

  if (currency !== '$0') {
    return currency;
  }
};

export const leftSummaryItems = (
  creditReportSummary: Record<string, string>
) => [
  {
    text: 'Open Accounts',
    value: creditReportSummary.OpenAccounts,
  },
  {
    text: 'On-time Payment History',
    value: creditReportSummary.OnTimePaymentPercentage,
  },
  {
    text: 'Late Payment History',
    value: creditReportSummary.LatePaymentPercentage,
  },
  {
    text: 'Total Revolving Account Balance',
    value: getCurrency(creditReportSummary.BalanceOpenRevolvingAccounts),
  },
  {
    text: 'Utilization',
    value: getPercentage(creditReportSummary.Utilization),
  },
  {
    text: 'Age of Credit',
    value:
      creditReportSummary.AgeOfCredit &&
      `${creditReportSummary.AgeOfCredit} years`,
  },
  {
    text: 'Available Credit',
    value: getCurrency(creditReportSummary.AvailableCredit),
  },
  {
    text: 'Total Monthly Payments',
    value: getCurrency(creditReportSummary.TotalMonthlyPayments),
  },
];

export const rightSummaryItems = (
  creditReportSummary: Record<string, string>
) => [
  {
    text: 'Delinquent Accounts',
    value: creditReportSummary.DelinquentAccounts,
  },
  {
    text: 'Inquiries',
    value: creditReportSummary.NumberOfInquiries,
  },
  {
    text: 'Public Record Accounts',
    value: creditReportSummary.totalPublicRecords,
  },
  {
    text: 'Open Installment Accounts',
    value: creditReportSummary.TotalOpenInstallmentAccounts,
  },
  {
    text: 'Open Revolving Accounts',
    value: creditReportSummary.TotalOpenRevolvingAccounts,
  },
  {
    text: 'Open Collection Accounts',
    value: creditReportSummary.TotalOpenCollectionAccounts,
  },
  {
    text: 'Other Open Accounts',
    value: creditReportSummary.OpenAccounts,
  },
];
