import { Box, Text } from '@ltvco/refresh-lib/theme';
import { constants } from 'appConstants';
import { AstrologyOnboardingForm, type UserProps } from 'components/Astrology';
import {
  StyledImg,
  StyledGradientContainer,
  StyledBorderContainer,
} from './components';
import { AstrologyStandaloneHeader } from '../components';

interface AstrologyOnboardingProps {
  onSubmitCallback: (astrologyUser: UserProps | null) => void;
}

export const AstrologyStandaloneOnboarding = ({
  onSubmitCallback,
}: AstrologyOnboardingProps) => {
  return (
    <>
      <AstrologyStandaloneHeader />
      <StyledBorderContainer>
        <StyledGradientContainer
          alignItems="center"
          direction="column"
          width="100%"
        >
          <StyledImg
            src={constants.images.astrology.imgAstrologyNumerology}
            alt="Astrology Insights"
          />
          <Text variant="h3" mb={1}>
            Astrological Insights
          </Text>
          <Text mb={2.5} maxWidth={376} textAlign="center">
            Get personalized daily horoscopes for guidance in work, love, and
            personal growth.
          </Text>
          <Box maxWidth={532} width="100%">
            <AstrologyOnboardingForm
              onSubmitCallback={onSubmitCallback}
              standaloneVersion
            />
          </Box>
        </StyledGradientContainer>
      </StyledBorderContainer>
    </>
  );
};
