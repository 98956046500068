import { Card, Stack, styled, CardProps } from '@ltvco/refresh-lib/theme';

interface StyledCardProps extends CardProps {
  disabled: boolean;
}

interface StyledImgProps {
  height: string;
  width: string;
}

export const StyledCard = styled(({ disabled, ...props }: StyledCardProps) => (
  <Card {...props} />
))(({ disabled }) => ({
  maxHeight: '148px',
  padding: 0,
  opacity: disabled ? 0.5 : 1,
  backgroundColor: disabled ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
  boxShadow: disabled ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
  position: 'relative',

  '&.MuiPaper-root': {
    borderRadius: '4px',
  },
}));

export const StyledImg = styled('img')<StyledImgProps>(({ height, width }) => ({
  height,
  width,
  display: 'block',
  position: 'relative',
  zIndex: 2,
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  height: 'auto',

  '& > *': {
    width: '100%',
    maxWidth: '100%',

    [theme.breakpoints.up('md')]: {
      width: 'calc(calc(100% - 110px) / 5)',
      maxWidth: 'calc(calc(100% - 110px) / 5)',
    },

    [theme.breakpoints.down('md')]: {
      width: 'calc(calc(100% - 22px) / 3)',
      maxWidth: 'calc(calc(100% - 22px) / 3)',
    },
  },

  '& > *:not(:nth-of-type(5n))': {
    [theme.breakpoints.up('md')]: {
      marginRight: '27px',
    },
  },

  '& > *:not(:nth-of-type(3n))': {
    [theme.breakpoints.down('md')]: {
      marginRight: '10px',
    },
  },
}));

export const StyledCarousel = styled(Stack)(({ theme }) => ({
  width: '100%',
  '.swiper': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));
