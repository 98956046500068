import { createContext, useContext, useEffect, useState } from 'react';
import { useBumpeeUsers } from './hooks/useBumpeeUsers';
import { Outlet } from 'react-router-dom';
import { LoadingLessonModal } from './Dashboard/AssignmentsContainer/LessonsContainer/LoadingLessonModal/LoadingLessonModal';
import { AchievementProvider } from './AchievementContext';
import { useCloudCannon } from './hooks/useCloudCannon';

type LanguageContextState = {
  language: string;
  currentAudioRef: React.MutableRefObject<HTMLAudioElement | null> | null;
  setCurrentAudioRef: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<HTMLAudioElement | null> | null>
  >;
  lessonLearningLanguage: LessonLearningLanguage;
  setLessonLearningLanguage: React.Dispatch<
    React.SetStateAction<LessonLearningLanguage>
  >;
  loadingLessonModalOpen: boolean;
  toggleLoadingLessonModal: () => void;
};
interface LessonLearningLanguage {
  value: string;
  display: string;
}

const LanguageContext = createContext({
  language: 'english',
  currentAudioRef: null,
  setCurrentAudioRef: () => {},
  lessonLearningLanguage: { value: '6', display: 'English' },
  setLessonLearningLanguage: () => {},
  loadingLessonModalOpen: false,
  toggleLoadingLessonModal: () => {},
} as LanguageContextState);

export const useLanguageContext = () => useContext(LanguageContext);

export const LanguageProvider = () => {
  // Fetching the images asap
  useCloudCannon();

  const { data: user } = useBumpeeUsers();
  const [language, setLanguage] = useState<string>('english');
  const [loadingLessonModalOpen, setLoadingLessonOpenModal] = useState(false);
  const [lessonLearningLanguage, setLessonLearningLanguage] =
    useState<LessonLearningLanguage>({
      value: '6',
      display: 'English',
    });

  useEffect(() => {
    if (user?.last_known_learning_language) {
      setLessonLearningLanguage({
        value: user?.last_known_learning_language?.id.toString(),
        display: user?.last_known_learning_language?.name,
      });
    }
  }, [user?.last_known_learning_language]);
  const [currentAudioRef, setCurrentAudioRef] =
    useState<React.MutableRefObject<HTMLAudioElement | null> | null>(null);

  useEffect(() => {
    if (user?.user_native_language) {
      setLanguage(user.user_native_language.toLowerCase());
    }
  }, [user]);

  const toggleLoadingLessonModal = () => {
    setLoadingLessonOpenModal((prevState) => !prevState);
  };

  const values = {
    language,
    currentAudioRef,
    setCurrentAudioRef,
    lessonLearningLanguage,
    setLessonLearningLanguage,
    loadingLessonModalOpen,
    toggleLoadingLessonModal,
  };

  return (
    <LanguageContext.Provider value={values}>
      <AchievementProvider>
        <Outlet />
      </AchievementProvider>
      <LoadingLessonModal />
    </LanguageContext.Provider>
  );
};
