import { Text, Card, styled, Stack, Link } from '@ltvco/refresh-lib/theme';
import imgMobileDownloadCTA from '../../images/img_mobile_download_cta.svg';
import imgAppleStoreBadge from '../../images/img_apple_app_store_badge.svg';
import imgGooglePlayBadge from '../../images/img_badge_google_play.svg';

const CardStyled = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 50%)',
}));

const ImgStyled = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '496px',
  margin: `0 auto ${theme.spacing(2)}`,
}));

export function MobileDownloadCTA() {
  const appsFlyerLink = 'https://beenverified.onelink.me/5mdJ/5q6b0w52';

  return (
    <CardStyled>
      <Text fontSize="1.875rem" fontWeight="bold" mb={3}>
        Try Our Mobile Apps
      </Text>
      <ImgStyled src={imgMobileDownloadCTA} alt="Mobile App" />
      <Text fontSize="1.5rem" fontWeight="bold" mb={1}>
        Millions of Records on the go!
      </Text>
      <Text color={(theme) => theme.palette.text.secondary} mb={3}>
        Access BeenVerified with one-tap on iPhone, iPad, and Android
      </Text>
      <Stack direction="row" spacing={2} justifyContent="center">
        <Link target="_blank" href={appsFlyerLink} rel="noopener noreferrer">
          <img src={imgAppleStoreBadge} alt="Download on the app store" />
        </Link>
        <Link target="_blank" href={appsFlyerLink} rel="noopener noreferrer">
          <img src={imgGooglePlayBadge} alt="Get it on Google Play" />
        </Link>
      </Stack>
    </CardStyled>
  );
}
