import { Text, Stack, Box } from '@ltvco/refresh-lib/theme';
import { AstrologyHeaderLight } from '../AstrologyHeaders/AstrologyHeaderLight';
import { AstrologyErrorContainer } from './components';

export const AstrologyError = () => {
  return (
    <AstrologyErrorContainer className="ast-error-container">
      <AstrologyHeaderLight />
      <Text variant="h2" mb={1.75} mt={2.5} color="error">
        Oops! Something Went Awry..
      </Text>
      <Text variant="h5" fontWeight="normal">
        Please refresh the page or try again later.
      </Text>
    </AstrologyErrorContainer>
  );
};
