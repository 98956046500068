import { Divider, Stack, Text } from '@ltvco/refresh-lib/theme';
import { translations } from 'components/Language/constants';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { LessonSideCard } from './LessonSideCard/LessonSideCard';

interface LessonSidebarProps {
  assignments: {
    id: number;
    assignmentType: string;
    title: string;
    completed?: boolean;
    started?: boolean;
    lessonChatId?: number;
    quizChatId?: number;
    storyChatId?: number;
    status?: string;
  }[];
  activeAssignmentId?: number;
  redirectToAssignment: (assignmentId: number) => void;
}

export const LessonSidebar = ({
  assignments,
  activeAssignmentId,
  redirectToAssignment,
}: LessonSidebarProps) => {
  const { language } = useLanguageContext();

  const possibleHeaders: { [key: string]: string } = {
    lesson: translations.dashboard.assignments.lesson.header[language],
    quiz: translations.dashboard.assignments.quiz.header[language],
    story: translations.dashboard.assignments.story.header[language],
  };

  return (
    <Stack
      maxWidth="213px"
      px={1.5}
      borderRight="1px solid #E2E2E2"
      direction="column"
    >
      <Text sx={{ textWrap: 'nowrap', mt: 2.25 }} textAlign="center">
        {assignments[0]?.assignmentType &&
          possibleHeaders[assignments[0]?.assignmentType]}
      </Text>
      <Divider sx={{ my: 2 }} />
      <Stack direction="column" spacing={1}>
        {assignments.map((assignment) => (
          <LessonSideCard
            key={assignment.id}
            assignment={assignment}
            activeAssignment={activeAssignmentId === assignment.id}
            redirectToAssignment={redirectToAssignment}
          />
        ))}
      </Stack>
    </Stack>
  );
};
