import { Box, Stack,  Text } from '@ltvco/refresh-lib/theme';
import { StyledHintCard } from '../components';
import { HintCard } from './HintCard/HintCard';
import { useLanguageContext } from 'components/Language/LanguageContext';
import { translations } from 'components/Language/constants';
import { Hint } from 'api';
import { ChatTextLoading } from '@ltvco/refresh-lib/v1';
import { LightbulbOutlined } from '@mui/icons-material';

interface HintCardProps {
  hintData: {
    hints: Hint[];
    isLoading: boolean;
  };
  handleHintClick: (hintText: string) => void;
  chatId: number;
}

export function HintsCard({
  hintData,
  handleHintClick,
  chatId,
}: HintCardProps) {
  const { language } = useLanguageContext();

  return (
    <Box m={1} mr={0}>
      <StyledHintCard variant="outlined">
        <Stack>
          <Box display="flex" alignItems="center" mb={2}>
            <LightbulbOutlined
              fontSize="small"
              sx={{ color: '#FFB400' }}
            />
            <Text ml={0.8}>{translations.chatUI.hint[language]}</Text>
          </Box>
          {hintData.isLoading && (
            <Box paddingBottom={2}>
              <ChatTextLoading />
            </Box>
          )}
          {!hintData.isLoading &&
            hintData.hints.map((hint, index) => (
              <HintCard
                hint={hint}
                index={index}
                onClick={handleHintClick}
                key={index}
                chatId={chatId}
              />
            ))}
        </Stack>
      </StyledHintCard>
    </Box>
  );
}
