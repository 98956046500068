import { Input, styled } from '@ltvco/refresh-lib/theme';

const StyledOneTimePasscodeInput = styled(Input)`
  div {
    height: 80px;
    max-width: 54px;
    min-width: 54px;
  }
  input {
    text-align: center;
    caret-color: transparent;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const OneTimePasscode: React.FC<{
  userPasscode: (string | number)[];
  handleOneTimePasscodeInput: (index: number, value: string | number) => void;
}> = ({ userPasscode, handleOneTimePasscodeInput }) => {
  const handleInput = (
    index: number,
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (['Backspace', 'Delete'].includes(event.key)) {
      handleOneTimePasscodeInput(index, '');
      return;
    }

    if (event.key.match(/^[0-9]/) === null) {
      return;
    }

    handleOneTimePasscodeInput(index, event.key);

    if (index < 4) {
      const input = document.getElementById(`otp-${index + 1}`);
      input?.focus();
    }
  };

  const displayOneTimePasscodeUserInput = () => {
    return userPasscode.map((passcode, index) => {
      return (
        <StyledOneTimePasscodeInput
          id={`otp-${index}`}
          key={`otp-${index}`}
          type="number"
          value={passcode}
          onKeyDown={(event) => handleInput(index, event)}
        />
      );
    });
  };

  return <>{displayOneTimePasscodeUserInput()}</>;
};
