export type CreditScoreHistoryProps = {
  score: number;
  date: string;
};

export const triangleUpStyle = {
  width: '0',
  height: '0',
  clipPath: 'polygon(50% 0, 0 100%, 100% 100%)',
  backgroundColor: '#81B44C',
  borderLeft: '8px solid transparent',
  borderRight: '8px solid transparent',
  borderBottom: '16px solid #81B44C',
  marginRight: '8px',
};

export const triangleDownStyle = {
  width: '0',
  height: '0',
  clipPath: 'polygon(50% 100%, 0 0, 100% 0)',
  backgroundColor: '#81B44C',
  borderLeft: '8px solid transparent',
  borderRight: '8px solid transparent',
  borderTop: '16px solid #81B44C',
  marginRight: '8px',
};

export const selectOptions = [
  { value: '3 Months', display: '3 Months' },
  { value: '6 Months', display: '6 Months' },
  { value: '9 Months', display: '9 Months' },
  { value: '1 Year', display: '1 Year' },
];

export const monthsCategories = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
