import { OxfordHelpers } from '../Helpers';
import { scoreSimulatorPath } from '../constants';
import { scoreModifiersAttributesIds } from './constants';

export type ScoreSimulatorType = {
  PayOffAllCreditCards: boolean;
  CloseOldestCreditCard: boolean;
  IncreaseCreditBalance?: string;
  IncreaseCreditCardLimit?: string;
  DecreaseCreditBalance?: string;
  OnTimePayment?: string;
  AllAccountsPastDue?: string;
  OneAccountPastDue?: string;
  ObtainCreditCard?: string;
  TransferCreditBalances?: string;
  ObtainCreditCardAsAuthorizedUser?: string;
  ApplyForCreditCard?: string;
  ObtainAutoLoan?: string;
  ObtainPersonalLoan?: string;
  ObtainMortgage?: string;
};

type PostScoreSimulatorType = {
  modifier_id: string;
  is_boolean?: boolean;
  attr_value?: string;
  attr_id?: string;
};

export class useScoreSimulator {
  public static async postScoreSimulatorRequest(
    body: ScoreSimulatorType,
    token: string
  ) {
    return OxfordHelpers.request({
      method: 'POST',
      url: scoreSimulatorPath,
      body: useScoreSimulator.transformScoreSimulatorData(body),
      token,
    });
  }

  public static async getScoreSimulatorRequest(token: string) {
    return OxfordHelpers.poll({
      method: 'GET',
      url: scoreSimulatorPath,
      token,
      jsonRequired: true,
    });
  }

  static transformScoreSimulatorData(data: ScoreSimulatorType) {
    let transformedData: PostScoreSimulatorType[] = [];

    ['PayOffAllCreditCards', 'CloseOldestCreditCard'].forEach((key) => {
      if (data[key as keyof ScoreSimulatorType]) {
        transformedData.push({
          modifier_id: key,
          is_boolean: true,
        });
      }

      delete data[key as keyof ScoreSimulatorType];
    });

    Object.keys(data).forEach((key) => {
      if (
        [undefined, '', '$0', false].includes(
          data[key as keyof ScoreSimulatorType]
        )
      )
        return;

      let attr_id = scoreModifiersAttributesIds[key as string];

      let attr_value = data[key as keyof ScoreSimulatorType] as string;
      attr_value = attr_value.replace(/[$,]/g, '');

      transformedData.push({
        modifier_id: key,
        attr_value,
        attr_id,
      });
    });

    return { score_modifiers: transformedData };
  }
}
