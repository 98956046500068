import {
  Stack,
  Tab,
  TabsNavigation,
  styled,
} from '@ltvco/refresh-lib/theme';
import { useScreenSize } from '@ltvco/refresh-lib/utils';
import {
  type ClaimingSearchData,
  LicensePlateSearchForm,
  VINSearchForm,
  YMMSearchForm,
} from '@ltvco/refresh-lib/v1';
import { AddCircle } from '@mui/icons-material';
import { useState } from 'react';

const StyledTabsNavigation = styled(TabsNavigation)(({ theme }) => ({
  color: theme.palette.secondary.light,
  borderBottom: 'solid',
  marginBottom: theme.spacing(2.5),
}));

export const VehicleReportTabsForm: React.FC<{
  closeIcon: (props: { margins?: boolean }) => JSX.Element | boolean;
  onReportSuccess: (data: ClaimingSearchData) => Promise<void>;
}> = ({ closeIcon, onReportSuccess }) => {
  const [activeTab, setActiveTab] = useState(0);

  const { isMobile } = useScreenSize();

  return (
    <Stack width="100%">
      <StyledTabsNavigation
        value={activeTab}
        onTabChanged={(currentTab: number) => setActiveTab(currentTab)}
        tab={Tab}
        tabs={[
          {
            content: (
              <Stack
                direction="row"
                width="100%"
                mb={[0, 1]}
                sx={{ alignItems: ['center', 'initial'], minHeight: '58px' }}
              >
                <YMMSearchForm
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onSuccess={(data: ClaimingSearchData) =>
                    onReportSuccess(data as ClaimingSearchData)
                  }
                  searchButtonText="Add"
                  searchButtonSize="16%"
                  optionalProps={{
                    yearLabelHidden: true,
                    yearWidth: 2.5,
                    makeLabelHidden: true,
                    makeWidth: 2.5,
                    modelLabelHidden: true,
                    modelWidth: 2.5,
                    trimLabelHidden: true,
                    trimWidth: 2.5,
                    selectHeight: '58px',
                    searchStartIcon: <AddCircle />,
                    formStyle: { width: '100%' },
                  }}
                />
                {closeIcon({ margins: true })}
              </Stack>
            ),
            label: 'Year, Make, Model',
          },
          {
            content: (
              <Stack
                direction="row"
                width="100%"
                mb={[0, 1]}
                sx={{ alignItems: ['center', 'initial'] }}
              >
                <VINSearchForm
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onSuccess={(data: ClaimingSearchData) =>
                    onReportSuccess(data)
                  }
                  searchButtonText="Add"
                  searchButtonSize="16%"
                  optionalProps={{
                    vinLabelHidden: true,
                    vinWidth: '84%',
                    selectHeight: '58px',
                    searchStartIcon: <AddCircle />,
                    formStyle: { width: '100%' },
                  }}
                />
                {closeIcon({ margins: true })}
              </Stack>
            ),
            label: 'VIN',
          },
          {
            content: (
              <Stack
                direction="row"
                width="100%"
                sx={{ alignItems: ['center', 'initial'] }}
              >
                <LicensePlateSearchForm
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onSuccess={(data: ClaimingSearchData) =>
                    onReportSuccess(data)
                  }
                  searchButtonSize="16%"
                  searchButtonText="Add"
                  optionalProps={{
                    licensePlateComponentsWidth: 10.08,
                    licensePlateLabelHidden: true,
                    licensePlateWidth: '50%',
                    stateLabelHidden: true,
                    stateWidth: '20%',
                    selectHeight: isMobile ? '60px' : '0px',
                    searchStartIcon: <AddCircle />,
                    formStyle: { width: '100%' },
                    alignItems: 'flex-start',
                  }}
                />
                {closeIcon({ margins: true })}
              </Stack>
            ),
            label: 'License Plate',
          },
        ]}
      />
    </Stack>
  );
};
