import { XmlHandler } from '../XmlHandler';
import {
  creditNodeNames,
  NodeName,
  ALERT_DETAILS,
  vantageScoreCodeToFactors,
  Notification,
  FactorInfo,
  ParsedAlertDetail,
} from './constants';

const capitalizeAddress = (address: string): string => {
  // If this is true, it's because it's a State, so we return it as it is.
  if (address.length === 2) return address;

  return address
    .split(' ')
    .map(
      (word: string) =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' ');
};

export const alertDetails = (alertType: string) => {
  if (!alertType) return '';

  return ALERT_DETAILS[alertType];
};

export const nodeNamesParser = (nodeName: NodeName) => {
  return nodeName in creditNodeNames
    ? creditNodeNames[nodeName]
    : nodeName.replace(/[A-Z]/g, ' $&').trim();
};

export const parseFormatDate = (date: string) => {
  if (!date) return '';
  const dateArray = date.split('-');
  const year = dateArray[0];
  const month = dateArray[1];
  const day = dateArray[2];

  return `${month}/${day}/${year}`;
};

export const parseFormatDateWithFullNumber = (date: string) => {
  if (!date) return '';
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);

  return `${month}/${day}/${year}`;
};

export const parseNotification = (notificationData: any) => {
  let notification;

  if (notificationData?.notifications) {
    notification = XmlHandler().parseToXml(notificationData?.notifications);
  } else {
    return {
      notifications: [],
      notificationsCount: null,
    };
  }

  const notificationsList = notification.querySelectorAll('Notification');

  // Setting notifications alert count to alert the user.
  const notificationsCount =
    notificationData.new_alert_count > 0
      ? notificationData.new_alert_count
      : null;

  // Will store the final parsed notifications
  const notifications: Notification[] = [];

  // Variables for each notification
  let notificationType = null;
  let notificationDetails = null;
  let notificationDisplayStatus = null;
  let notificationDate = null;

  notificationsList.forEach((notification) => {
    const notificationValues = [];
    const isEnhancedAlert = notification.querySelector('GenericAlert');
    notificationDisplayStatus = notification.querySelectorAll('Displayed');
    notificationDate = notification.querySelectorAll('ProcessedDate');

    notificationValues.push({
      title: 'Alert Date',
      value: parseFormatDate(notificationDate[0]?.innerHTML),
    });

    if (isEnhancedAlert) {
      notificationType = notification.querySelectorAll('GAType');
      notificationDetails = notification.querySelectorAll('GAElement');

      //Parse enhanced alerts.
      handleEnhancedAlerts(notificationDetails, notificationValues);
    } else {
      notificationType = notification.querySelectorAll('AlertType');
      notificationDetails =
        notification.querySelectorAll('TUCAlertsDetail')[0]?.children[0];

      // Parse standard/instant alerts
      handleStandardAndInstantAlerts(notificationDetails, notificationValues);
    }

    notifications.push({
      alertType: nodeNamesParser(notificationType[0]?.textContent as NodeName),
      alertDescription: alertDetails(notificationType[0]?.textContent ?? ''),
      alertDetails: notificationValues,
      displayed: notificationDisplayStatus[0]?.textContent === 'true',
    });
  });

  return {
    notifications,
    notificationsCount,
  };
};

const handleEnhancedAlerts = (
  notificationDetails: NodeListOf<Element> | null,
  notificationValues: ParsedAlertDetail[]
) => {
  if (!notificationDetails) return;

  // Go through each alert detail node and store it on notificationValues
  notificationDetails.forEach((detail) => {
    const nodeName = nodeNamesParser(
      detail?.children[0]?.textContent as NodeName
    );
    const content = detail?.children[1]?.textContent;
    if (!content) return;
    if (nodeName === 'Artifact Type') return;

    if (nodeName.includes('Date')) {
      notificationValues.push({
        title: nodeName,
        value: parseFormatDateWithFullNumber(content),
      });
    } else if (
      nodeName.includes('Amount') ||
      nodeName.includes('Balance') ||
      nodeName.includes('Credit Limit')
    ) {
      notificationValues.push({
        title: nodeName,
        value: content
          .replace(/0+/, '$')
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'),
      });
    } else if (nodeName.includes('Positive') || nodeName.includes('Negative')) {
      if (content in vantageScoreCodeToFactors) {
        const factorInfo: FactorInfo = vantageScoreCodeToFactors[content];
        notificationValues.push({
          title: nodeName,
          vantageScoreCodeToFactors: [
            {
              factor: factorInfo.factor,
              explain: factorInfo.explain,
            },
          ],
        });
      } else {
        notificationValues.push({ title: nodeName, value: content });
      }
    } else {
      notificationValues.push({ title: nodeName, value: content });
    }
  });
};

export const handleStandardAndInstantAlerts = (
  notificationDetails: Element | null,
  notificationValues: ParsedAlertDetail[]
) => {
  if (!notificationDetails) return;
  if (notificationDetails.children.length === 0) return;

  Array.from(notificationDetails.children).forEach((detail: Element) => {
    const nodeName = nodeNamesParser(detail.nodeName as NodeName);
    const content = detail.textContent || '';

    if (nodeName.includes('Phone') || nodeName === 'Inquiree PhoneNumber') {
      const phoneNumber = detail.children[0]?.textContent || '';
      const formattedPhoneNumber = phoneNumber.replace(
        /(\d{3})(\d{4})/,
        '($1) $2'
      );
      notificationValues.push({ title: nodeName, value: formattedPhoneNumber });
    } else if (nodeName.includes('Address')) {
      const addressLines = Array.from(detail.children)
        .slice(0, 5)
        .map((child) => child.textContent || '')
        .map((line) => capitalizeAddress(line));

      const addressLine2 = addressLines[1] ? ` <br/> ${addressLines[1]}` : '';
      const combinedValue = `${addressLines[2]}, ${addressLines[4]} ${addressLines[3]}`;
      notificationValues.push({
        title: nodeName,
        value: `${addressLines[0]} ${addressLine2} <br/> ${combinedValue}`,
      });
    } else if (nodeName.includes('Date') && content.length > 0) {
      notificationValues.push({
        title: nodeName,
        value: parseFormatDate(content),
      });
    } else if (nodeName.includes('Amount') || nodeName.includes('Balance')) {
      const formattedAmount = `$${content.replace(
        /(\d)(?=(\d\d\d)+(?!\d))/g,
        '$1,'
      )}`;
      notificationValues.push({ title: nodeName, value: formattedAmount });
    } else {
      notificationValues.push({ title: nodeName, value: content });
    }
  });
};
